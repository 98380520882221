import { Fab, FabProps } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import CreateIcon from '@material-ui/icons/Create'
import React from 'react'
interface Props extends FabProps {
  onClick: (event: React.MouseEvent<HTMLButtonElement>) => void
  footer?: boolean
  floatTotalAmt?: boolean
  isCreate?: boolean
}
export default function FloatButton(props) {
  const { onClick, footer, floatTotalAmt, isCreate, ...rest } = props
  return (
    <Fab
      {...rest}
      aria-label="add"
      style={{ bottom: floatTotalAmt ? `100px` : '' }}
      className={`float-btn ${footer ? 'with-footer' : ''}`}
      onClick={onClick}
    >
      {isCreate ? <CreateIcon /> : <Add />}
    </Fab>
  )
}
