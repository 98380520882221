import {
  IconButton,
  InputBase,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  useMediaQuery,
} from '@material-ui/core'
import { Close, Search } from '@material-ui/icons'
import theme from '@ifca-root/react-component/src/assets/theme'
import AppContext from 'containers/App/Store/AppContext'
import React, { useContext, useState } from 'react'
interface ISearchHeaderProps {
  title: string
  value?: string
  onClick?: any
  input?: any
  option?: {
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
    icon?: any
  }
  search?: boolean
  onChangeAction?: (event: React.ChangeEvent<HTMLInputElement>) => void
  onCloseAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
  dynamicInfo?: boolean
  multiDynamicInfo?: boolean
  multiDynamicSubInfo?: boolean
  fixed?: boolean
  clickText?: boolean
  singleInfo?: boolean
  dropdownInfo?: boolean
  defaultValue?: string
  isDefaultValue?: boolean
  datePickerSubInfo?: boolean
}
export const SearchHeader = (props: ISearchHeaderProps) => {
  const {
    title,
    value,
    search,
    option,
    input,
    onChangeAction,
    onCloseAction,
    dynamicInfo,
    multiDynamicInfo,
    multiDynamicSubInfo,
    fixed,
    singleInfo,
    dropdownInfo,
    clickText,
    defaultValue,
    isDefaultValue,
    datePickerSubInfo,
  } = props

  const [removeDefault, setRemoveDefault] = useState(
    isDefaultValue ? false : true
  )

  // const [searchBar, setSearch] = useState(false)
  let getCategoryLocal = JSON.parse(localStorage.getItem('searchFilter'))

  const [Empty, setEmpty] = useState(false)
  const handleSearchToggle = boolean => {
    // setSearch(!searchBar)
    // if (getCategoryLocal) {
    //   localStorage.setItem(
    //     'searchFilter',
    //     JSON.stringify({
    //       searchString: '',
    //       category: getCategoryLocal?.category,
    //     })
    //   )
    // }
    setRemoveDefault(boolean)
  }

  const { globalState } = useContext(AppContext as any)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })

  return (
    <List
      id="search-header"
      className={`search-header 
      ${
        fixed && globalState.drawerOpen
          ? 'fixed-search-header-fw'
          : fixed && 'fixed-search-header'
      }
      ${singleInfo && 'fixed-search-header single-info'}
      ${dropdownInfo && ' fixed-search-header with-dropdown'}
      ${dynamicInfo && ' fixed-search-header with-dynamic'}
      ${multiDynamicInfo && 'fixed-search-header with-dynamic-multiInfo'}
      ${multiDynamicSubInfo && 'fixed-search-header with-dynamic-multiSubInfo'}
      ${datePickerSubInfo && 'fixed-search-header with-date-picker'}
      `}
    >
      <ListItem>
        <ListItemText
          disableTypography
          onClick={() => {
            if (clickText) {
              handleSearchToggle(false)
              setEmpty(false)
            }
          }}
          primary={
            <>
              <span className="xsTitle flex-space">
                {title}{' '}
                {value && (
                  <>
                    (<span className="highlight-text">{value}</span>)
                  </>
                )}
              </span>
            </>
          }
        />
        <ListItemSecondaryAction>
          {search ? (
            <IconButton
              edge="end"
              aria-label="search"
              // onClick={el.onClick}
              onClick={() => {
                handleSearchToggle(false)
                setEmpty(false)
              }}
            >
              <Search />
            </IconButton>
          ) : null}
          {option && (
            <IconButton
              edge="end"
              aria-label="delete"
              onClick={option?.onClick}
            >
              {option?.icon}
            </IconButton>
          )}
        </ListItemSecondaryAction>
      </ListItem>

      <ListItem className={`search-field ${!removeDefault ? null : 'hide'}`}>
        <ListItemText
          disableTypography
          primary={
            <>
              <InputBase
                placeholder="Search here.."
                inputProps={{ 'aria-label': 'search' }}
                onChange={onChangeAction}
                defaultValue={defaultValue}
                inputRef={input => {
                  // input && removeDefault && input.focus()
                  input && Empty && (input.value = '')
                }}
              />
            </>
          }
        />
        <ListItemSecondaryAction>
          <IconButton
            edge="end"
            aria-label="close"
            onClick={e => {
              handleSearchToggle(true)
              onCloseAction(e)
              setEmpty(true)
            }}
            className={`close-search ${!removeDefault ? null : 'hide'}`}
          >
            <Close />
          </IconButton>
        </ListItemSecondaryAction>
      </ListItem>
    </List>
  )
}
