import React from 'react';
import { TableForm } from './TableForm';
import { TableList } from './TableList';

const tableRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/table`,
		},
		component: <TableList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/table/add`,
		},
		component: <TableForm />,
	},
];

export default tableRoutes;
