import ErrorBackground from '@ifca-root/react-component/src/assets/images/error-404-dark.jpg';
import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout';
import { Button } from '@material-ui/core';
import React from 'react';
import { useHistory } from 'react-router';
import Logo from '../../../src/assets/images/MenuX-Logo.svg';
import useStyles from './useStyles';

export const NotFound = props => {
	const classes = useStyles({});
	let history = useHistory();
	return (
		<AuthLayout logo={Logo} image={ErrorBackground} className="margin-override">
			<br />
			<div className={classes.root}>
				<div style={{ textAlign: 'center' }}>
					<div className="m-b-20">
						<h1 className={classes.h1}>Whoops!</h1>
						{/* <br />
            <h4 className={classes.h2}>Page Not Found!</h4> */}
						<span className={classes.h3}>
							We couldn't find the page that you are looking for
						</span>
					</div>

					<Button
						variant="contained"
						color="primary"
						className=""
						onClick={() => history.goBack()}
					>
						Back to home
					</Button>
				</div>
			</div>
		</AuthLayout>
	);
};
