import StandardCardContent from '@ifca-root/react-component/src/components/CardList/StandardCardContent';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { Beenhere } from '@material-ui/icons';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import TreeItem from '@material-ui/lab/TreeItem';
import TreeView from '@material-ui/lab/TreeView';
import { useGetRoleQuery } from 'generated/graphql';
import { IAction } from 'helpers/model';
import React, { Reducer, useEffect, useReducer } from 'react';
import { useHistory, useParams } from 'react-router';
import { useLocation } from 'react-router-dom';
import {
	RestaurantPerm,
	KitchenPerm,
	// BusinessInsightPerm,
	// // CustomerProfilePerm,
	// GeneralSettingPerm,
	// OutletSettingPerm,
	RenderPermissionTree,
	PrinterServerPerm,
	// ReservationPerm,
} from './RolesTree';

interface Props {
	selected: any;
	restaurantTree: RenderPermissionTree;
	kitchenTree: RenderPermissionTree;
	printerServerTree: RenderPermissionTree;
	// //homeTableTree: RenderPermissionTree;
	// generalSettingTree: RenderPermissionTree;
	// outletSettingTree: RenderPermissionTree;
	// reservationTree: RenderPermissionTree;
	// //customerProfileTree: RenderPermissionTree;
	// businessInsightTree: RenderPermissionTree;
}
export const RolesDetail = () => {
	let history = useHistory();
	let location = useLocation();
	const editData = location?.state as any;
	const { id }: any = useParams();
	const {
		data: { getRole } = { getRole: [] },
		loading,
		error,
	} = useGetRoleQuery({
		variables: {
			ID: editData?.ID,
		},
	});

	const initialState: Props = {
		//homeTableTree: HomeTablePerm,
		// generalSettingTree: GeneralSettingPerm,
		// outletSettingTree: OutletSettingPerm,
		// reservationTree: ReservationPerm,
		// //customerProfileTree: CustomerProfilePerm,
		// businessInsightTree: BusinessInsightPerm,
		restaurantTree: RestaurantPerm,
		kitchenTree: KitchenPerm,
		printerServerTree: PrinterServerPerm,
		selected: [],
	};

	const countAccessible = [
		{ restaurantTree: RestaurantPerm },
		{ kitchenTree: KitchenPerm },
		{ printerServerTree: PrinterServerPerm },
		// { generalSettingTree: GeneralSettingPerm },
		// { outletSettingTree: OutletSettingPerm },
		// { reservationTree: ReservationPerm },
		// { businessInsightTree: BusinessInsightPerm },
	];

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const selectedCount = (children, selected) => {
		let count = 0;
		children?.map(x => {
			if (selected?.includes(x?.id)) count++;
		});
		return count;
	};

	useEffect(() => {
		if (getRole) {
			dispatch({
				type: 'selected',
				payload: getRole[0]?.rolePerm?.map(x => x.permName),
			});
		}
	}, [getRole]);

	const [expanded, setExpanded] = React.useState<string[]>([]);
	const renderTree = (nodes: RenderPermissionTree, modules: string) => (
		<TreeItem
			key={nodes.id}
			nodeId={`${nodes.id}`}
			label={getRole?.map((el, index) => (
				<List className="" disablePadding>
					<ListItem>
						<ListItemIcon style={{ minWidth: '46px' }}>
							{state.selected?.some(item => item === nodes.id) ? (
								<Beenhere style={{ color: '#ff9800' }} />
							) : (
								// <img
								// 	//src={DisableBadge}
								// 	alt="disable"
								// 	style={{ width: '1em', height: '1em', fontSize: '1.5rem' }}
								// 	loading="lazy"
								// />
								<Beenhere style={{ color: '#dfe0e3' }} />
							)}
						</ListItemIcon>

						<ListItemText
							primary={
								<span
									className={`xsTitle ${
										state.selected?.some(item => item === nodes.id)
											? 'color-primary-orange'
											: 'color-grey'
									} flex-space`}
								>
									{nodes.label}
								</span>
							}
							secondary={
								<>
									{nodes.children?.length > 0 && (
										<span className="desc flex-space">
											{/* {
												el.rolePerm?.filter(function(rolePerm) {
													return (
														rolePerm.permName !==
															'GENERAL_SETTING_ACCESS_SECURITY_USERS______SHOW' &&
														rolePerm.permName !==
															'OUTLET_SETTING_OUTLET_________SHOW' &&
														rolePerm.permName !==
															'RESERVATION_LISTING_________SHOW' &&
														rolePerm.permName !==
															'BIZ_INSIGHT_OSC_ANALYSIS_________SHOW'
													);
												}).length
											}{' '} */}
											{selectedCount(nodes.children, state.selected)}
											<span>/</span>
											{nodes.children?.length} Accessible
										</span>
									)}
								</>
							}
						/>
					</ListItem>
				</List>
			))}
		>
			{Array.isArray(nodes.children)
				? nodes.children.map(node => renderTree(node, modules))
				: null}
		</TreeItem>
	);
	const handleToggle = (event: React.ChangeEvent<{}>, nodeIds: string[]) => {
		setExpanded(nodeIds);
	};
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	return (
		<>
			{loading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/general-setting/user-access/role`,
					})
				}
				mainBtn="back"
				smTitle={'Outlet App'}
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Settings' },
					{ name: 'Access Security' },
					{ name: 'Roles', current: true },
				]}
				rightRouteSegments={[{ name: 'Details', current: true }]}
			/>
			{/* SUBHEADER */}
			{/* <List className="core-list subheader single">
        <ListItem>
          <ListItemText
            primary={
              <>
                <span className="xsTitle c-darkblue">{getRole[0]?.name}</span>
              </>
            }
          />
        </ListItem>
      </List> */}

			<ContentWrapper>
				{/* <CardContents
          section={{
            header: {
              title: getRole[0]?.name,
            },
          }}
        > */}
				<StandardCardContent
					section={{
						header: {
							title: getRole?.map((el, index) => (
								<ListItemText
									primary={
										<>
											<span style={{ display: 'flex', width: '100%' }}>
												<span className="smTitle color-primary-orange flex-space">
													{getRole[0]?.name}
												</span>
											</span>
										</>
									}
									secondary={
										<div className="desc">
											<span className="fw-medium">
												{/* {`${
													el.rolePerm?.filter(function(rolePerm) {
														return (
															rolePerm.permName !==
																'GENERAL_SETTING_ACCESS_SECURITY_USERS______CREATE' &&
															rolePerm.permName !==
																'GENERAL_SETTING_ACCESS_SECURITY_USERS______VIEW' &&
															rolePerm.permName !==
																'GENERAL_SETTING_ACCESS_SECURITY_USERS______UPDATE'
														);
													}).length
												}
                        /${countAccessible.length} Accessible`} */}
												{`${el?.rolePerm?.length}/131 Accessible`}
											</span>
										</div>
									}
								/>
							)),
							// onClickAction: () => alert('EDIT'),
						},
					}}
				>
					<TreeView
						expanded={expanded}
						onNodeToggle={handleToggle}
						className="tree-view-list"
						defaultExpandIcon={<ExpandMoreIcon />}
						defaultCollapseIcon={<ExpandLessIcon />}
						multiSelect
					>
						{renderTree(state.restaurantTree, 'restaurant')}
						{renderTree(state.kitchenTree, 'kitchen')}
						{renderTree(state.printerServerTree, 'printerServer')}
						{/* {renderTree(state.homeTableTree, 'homeTable')} */}
						{/* {renderTree(state.generalSettingTree, 'generalSetting')}
						{renderTree(state.outletSettingTree, 'outletSetting')}
						{renderTree(state.reservationTree, 'reservation')}
						{/* {renderTree(state.customerProfileTree, 'customerProfile')} */}
						{/* {renderTree(state.businessInsightTree, 'businessInsight')} */}
					</TreeView>
				</StandardCardContent>
				{/* </CardContents> */}
			</ContentWrapper>
		</>
	);
};
