import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	TextField,
	Switch,
	List,
	Grid,
	ListItem,
	ListItemText,
} from '@material-ui/core';
import {
	useGetTaxListingDropdownQuery,
	useGetTaxListingQuery,
} from 'generated/graphql';
import { dateFormat, formatDate } from 'helpers/formatDate';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router';

export const TaxSchemeDetail = () => {
	let history = useHistory();
	let location = useLocation();
	const { taxSchemeID } = useParams<any>();

	const state: any = location.state;
	const detailData = state?.state;
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		data: { getTaxListingDropdown } = { getTaxListingDropdown: [] },
		loading: TaxListingDropdownLoading,
	} = useGetTaxListingDropdownQuery({
		fetchPolicy: 'network-only',
		variables: {
			accountID: user?.accountID,
			softwareCode: 'HotelX',
		},
	});

	const sortedData = detailData?.taxSchemeDetails.sort(
		(a, b) =>
			Number(new Date(b?.effectiveDate)) - Number(new Date(a?.effectiveDate)),
	);

	return (
		<>
			{TaxListingDropdownLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push(`/menu/outlet-app/common-setting/tax-scheme-policy`)
				}
				mainBtn="back"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: '' },
					{ name: '' },
					{ name: 'Tax Scheme Policy Detail', current: true },
				]}
			/>

			<ContentWrapper>
				<CardContents>
					<TextField
						label="Name"
						fullWidth
						InputProps={{
							disableUnderline: true,
							readOnly: true,
							style: {
								fontSize: '13px',
							},
						}}
						value={detailData?.name}
						margin="normal"
					/>

					<TextField
						label="Description"
						fullWidth
						InputProps={{
							disableUnderline: true,
							readOnly: true,
							style: {
								fontSize: '13px',
							},
						}}
						value={detailData?.description}
						margin="normal"
					/>

					<div
						className="content-wrap full"
						style={{
							justifyContent: 'space-between',
							display: 'flex',
							fontSize: '13px',
						}}
					>
						<span className="flex-space">Inclusive</span>
						<Switch
							name="Claimable"
							value={detailData?.isExclusive === false ? true : false}
							checked={detailData?.isExclusive === false ? true : false}
							color="primary"
						/>
					</div>
				</CardContents>

				<CardContents
					section={{
						header: {
							title: 'Tax Scheme Detail',
						},
					}}
					contentStyle={{
						padding: '0px',
					}}
				>
					<div className="flex-space">
						<List className="core-list" style={{ paddingBottom: '0px' }}>
							{sortedData?.map((el, index1) => {
								return (
									<>
										<ListItem key={index1}>
											<ListItemText
												primary={
													<span
														className="xsTitle"
														style={{ marginRight: '10px' }}
													>
														{formatDate(el?.effectiveDate)}
													</span>
												}
												secondary={
													<>
														<div className="flex-space">
															<List className="core-list">
																{el?.taxSchemeDetailItems?.map((x, index2) => {
																	return (
																		<>
																			<Grid
																				container
																				item
																				xs={12}
																				style={{
																					borderBottom: '1px solid #ccc',
																					paddingBottom: '10px',
																				}}
																			>
																				<Grid container item xs={12}>
																					<div
																						style={{
																							paddingLeft: '18px',
																							paddingTop: '10px',
																						}}
																						className="desc"
																					>
																						{index2 + 1}.
																					</div>
																					<div
																						style={{
																							paddingLeft: '2px',
																							paddingTop: '10px',
																						}}
																						className=" desc"
																					>
																						{
																							getTaxListingDropdown?.find(
																								y => y?.tax_id === x?.taxId,
																							)?.tax_type
																						}
																					</div>
																					<div
																						style={{
																							paddingLeft: '10px',
																							paddingTop: '10px',
																						}}
																						className=" desc flex-space"
																					>
																						{`${
																							getTaxListingDropdown?.find(
																								y => y?.tax_id === x?.taxId,
																							)?.rate
																						}%`}
																					</div>
																					<div
																						style={{
																							paddingRight: '18px',
																							paddingTop: '10px',
																						}}
																						className=" desc"
																					>
																						{
																							getTaxListingDropdown?.find(
																								y => y?.tax_id === x?.taxId,
																							)?.tax_type
																						}
																					</div>
																				</Grid>
																				<Grid container item xs={12}>
																					<div
																						style={{ paddingLeft: '18px' }}
																						className=" desc flex-space"
																					>
																						{x?.computeMethod}
																					</div>
																					<div
																						style={{ paddingRight: '18px' }}
																						className=" desc"
																					>
																						{
																							getTaxListingDropdown?.find(
																								y => y?.tax_id === x?.taxId,
																							)?.class_type
																						}
																					</div>
																				</Grid>
																			</Grid>
																		</>
																	);
																})}
															</List>
														</div>
													</>
												}
											/>
										</ListItem>
									</>
								);
							})}
						</List>
					</div>
				</CardContents>
			</ContentWrapper>
		</>
	);
};
