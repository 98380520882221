import {
	Checkbox,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import PickupIcon from 'assets/icons/pickup_grey.svg';
import BigNumber from 'bignumber.js';
import { OrderItemDiscountStatus, PosPermission } from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';
import NumberFormat from 'react-number-format';

interface OrderItemListProps {
	outletID: string;
	itemArray: any;
	negOrderItem?: any;
	leftIcon?: 'checkbox';
	leftAction?: any;
	rightIcon?: 'void' | 'checkbox' | 'quantity';
	rightAction?: any;
	selectedObj?: any; // only used to display amount field for item transfer, later might use for partial payment
	setSelectedObj?: any; // function to change selectedObj
	quantityFunc?: {
		onButtonClick?: any;
		onValueChange?: any;
	};
	page?:
		| 'bill-item-discount' // done
		| 'bill-settlement' // done
		| 'confirm-order' // done
		| 'bill-listing-detail' // done
		| 'item-transfer-dialog' // done
		| 'kitchen-home'; // no need not the same
	currencyCode?: string;
	hasOrderDiscount?: boolean;
	isInclusive?: boolean;
}

export const OrderItemList = (props: OrderItemListProps) => {
	const {
		outletID,
		itemArray,
		negOrderItem,
		leftIcon,
		leftAction,
		rightIcon,
		rightAction,
		selectedObj,
		setSelectedObj,
		quantityFunc,
		page,
		currencyCode = '',
		hasOrderDiscount = false,
		isInclusive,
	} = props;
	const { handlePermDisabled } = usePermissionChecker();

	return (
		<>
			<List
				style={{
					backgroundColor: 'white',
					color: 'black',
					paddingBottom: `${page === 'bill-item-discount' ? '12px' : '8px'}`,
				}}
			>
				{page === 'item-transfer-dialog' && (
					<span className="smTitle">{`Bill Items (${itemArray?.length ??
						0})`}</span>
				)}

				{itemArray?.map((oi, index) => {
					const hasItemDisc =
						page === 'bill-item-discount' &&
						(oi?.orderItemDiscount?.filter(
							disc => disc?.status === OrderItemDiscountStatus.Active,
						)?.length !== 0 ||
							(oi?.orderDiscountItem && oi?.orderDiscountItem?.amount !== 0) ||
							(oi?.orderDiscountItem?.baseAmount > 0 &&
								oi?.orderDiscountItem?.amount === 0));

					const baseAmountT = oi?.taxAmount;
					// const baseAmountSC = oi?.serviceCharge;
					const baseAmount = oi?.amount + baseAmountT;

					//Order Item Sum Up
					let totalTax = 0;
					oi?.orderItemTax?.map(x => {
						totalTax += x?.taxAmount;
					});

					const baseItemAmountBill = oi?.amount + totalTax;

					const negBaseItemAmountBill =
						(negOrderItem?.[oi?.ID]?.amount ?? 0) +
						(negOrderItem?.[oi?.ID]?.taxAmount ?? 0);

					const negBaseAmountT = negOrderItem?.[oi?.ID]?.taxAmount ?? 0;
					// const baseAmountSC = oi?.serviceCharge;
					const negBaseAmount =
						isInclusive === true
							? (negOrderItem?.[oi?.ID]?.amount ?? 0) + (negBaseAmountT ?? 0)
							: negOrderItem?.[oi?.ID]?.amount ?? 0;
					// negBaseAmount = NaN

					const activeOID = oi?.orderItemDiscount?.filter(
						el => el?.status === OrderItemDiscountStatus.Active,
					)[0];

					let totalOIDTax = 0;
					activeOID?.orderItemDiscountTax?.map(x => {
						totalOIDTax += x?.taxAmount;
					});

					const oidBA = activeOID?.baseAmount;
					const oidTx = totalOIDTax;

					const oid = oidBA + oidTx;

					let totalODITax = 0;
					oi?.orderDiscountItemTax?.map(x => {
						totalODITax += x?.taxAmount;
					});

					const odiTx = totalODITax;
					const odi = oi?.orderDiscountItem?.baseAmount + odiTx;

					const negItemDisc =
						(negOrderItem?.[oi?.ID]?.orderItemDiscBaseAmount ?? 0) +
						(negOrderItem?.[oi?.ID]?.orderItemDiscTaxAmount ?? 0);

					const negOrderDisc =
						(negOrderItem?.[oi?.ID]?.orderDiscountItemBaseAmount ?? 0) +
						(negOrderItem?.[oi?.ID]?.orderDiscountItemTaxAmount ?? 0);

					const discount = oid ? oid - negItemDisc : odi - negOrderDisc;

					return (
						<ListItem
							style={{
								paddingLeft: page === 'item-transfer-dialog' ? '0px' : null,
								paddingRight:
									rightIcon === 'quantity' &&
									selectedObj[oi?.ID]?.showQuantity === true
										? '130px'
										: null,
							}}
						>
							{leftIcon === 'checkbox' && (
								<Checkbox
									onChange={e => {
										leftAction(
											e,
											oi?.ID,
											oi?.quantity - negOrderItem[oi?.ID] || oi?.quantity,
										);
									}}
									name="orderItemID"
									color="primary"
									style={{
										padding: '8px 5px',
										alignSelf: 'start',
									}}
									checked={selectedObj[oi?.ID]?.showQuantity}
								/>
							)}

							<ListItemText
								primary={
									<>
										<Grid container>
											<Grid item xs={9}>
												{page !== 'item-transfer-dialog' && (
													<span
														className="mdDesc"
														style={{ paddingRight: '16px' }}
													>
														{index + 1 + '.'}
													</span>
												)}

												<span className="xsTitle text-noflow">
													{oi?.openItemName ?? oi.menuItem?.name}&nbsp; &nbsp;
													{'x'}
													{oi?.quantity -
														(negOrderItem?.[oi?.ID]?.quantity ?? 0)}
													{page === 'confirm-order' && oi?.isTakeaway && (
														<img
															className="list-takeaway-logo"
															src={PickupIcon}
														/>
													)}
												</span>
											</Grid>
											<Grid item xs={3}>
												{hasItemDisc && (
													<span
														className="mdDesc xsTitle right-info"
														style={{
															textDecoration: 'line-through ',
															padding: '7px 0px 4px 4px',
															// marginTop: '4px',
															// textAlign: 'right',
														}}
													>
														{`${currencyCode}${amtStr(
															new BigNumber(baseItemAmountBill)
																.minus(negBaseItemAmountBill ?? 0)
																.toNumber(),
														)}`}
													</span>
												)}
												<span
													className="highlight-text flex-space xsTitle right-info"
													style={{
														padding: '7px 0px 4px 4px',
													}}
												>
													{hasItemDisc
														? `${currencyCode}${amtStr(
																new BigNumber(baseItemAmountBill)
																	.minus(negBaseItemAmountBill ?? 0)
																	.minus(discount ?? 0)
																	.toNumber(),
														  )}`
														: page === 'bill-listing-detail' ||
														  page === 'bill-settlement' ||
														  page === 'confirm-order' ||
														  page === 'bill-item-discount'
														? `${currencyCode}${amtStr(
																new BigNumber(baseItemAmountBill)
																	.minus(negBaseItemAmountBill ?? 0)

																	.toNumber(),
														  )}`
														: `${currencyCode}${amtStr(
																new BigNumber(baseItemAmountBill)
																	.minus(negBaseItemAmountBill ?? 0)
																	// .minus(discount ?? 0)
																	.toNumber(),
														  )}`}
												</span>
											</Grid>
										</Grid>
									</>
								}
								secondary={
									<>
										<Grid container>
											{oi?.orderItemOption?.length
												? oi?.orderItemOption?.map(oiopt => (
														<>
															{oiopt?.orderItemOptionItem?.map(oioptitem => {
																//Option Item Sum Up

																let totalTax = 0;
																oioptitem?.orderItemOptionItemTax?.map(x => {
																	totalTax += x?.taxAmount;
																});

																const baseOptItemAmountBill =
																	oioptitem?.basePrice + totalTax;

																const negOptBaseItemAmountBill =
																	(negOrderItem?.[
																		oi?.ID
																	]?.orderItemOptions?.filter(
																		noi => noi.optionID === oiopt?.optionID,
																	)[0]?.orderItemOptionItem?.basePrice ?? 0) +
																	(negOrderItem?.[
																		oi?.ID
																	]?.orderItemOptions?.filter(
																		noi => noi.optionID === oiopt?.optionID,
																	)[0]?.orderItemOptionItem?.taxAmount ?? 0);

																let totalOptItemOIDTax = 0;
																oioptitem?.orderItemOptionItemDiscountTax?.map(
																	v => {
																		totalOptItemOIDTax += v?.taxAmount;
																	},
																);

																let totalOptItemODITax = 0;
																oioptitem?.orderDiscountItemOptionTax?.map(
																	v => {
																		totalOptItemODITax += v?.taxAmount;
																	},
																);

																const discOptItemAmountBill =
																	oioptitem?.discountBaseAmount +
																	totalOptItemOIDTax +
																	totalOptItemODITax;

																const negDiscOptBaseItemAmountBill =
																	(negOrderItem?.[
																		oi?.ID
																	]?.orderItemOptions?.filter(
																		noi => noi.optionID === oiopt?.optionID,
																	)[0]?.orderItemOptionItem
																		?.discountBaseAmount ?? 0) +
																	(negOrderItem?.[
																		oi?.ID
																	]?.orderItemOptions?.filter(
																		noi => noi.optionID === oiopt?.optionID,
																	)[0]?.orderItemOptionItem
																		?.itemDiscountTaxAmount ?? 0) +
																	(negOrderItem?.[
																		oi?.ID
																	]?.orderItemOptions?.filter(
																		noi => noi.optionID === oiopt?.optionID,
																	)[0]?.orderItemOptionItem
																		?.discountItemTaxAmount ?? 0);

																const optItemDiscount =
																	discOptItemAmountBill -
																	negDiscOptBaseItemAmountBill;
																return (
																	<>
																		<Grid item xs={8}>
																			<span
																				className="mdDesc"
																				style={{
																					paddingLeft:
																						page !== 'item-transfer-dialog'
																							? '26px'
																							: null,
																				}}
																			>
																				{oioptitem?.optionItem?.name}
																			</span>
																		</Grid>
																		{
																			<Grid item xs={4}>
																				<span
																					className="mdDesc right-info"
																					style={{
																						marginTop: '4px',
																					}}
																				>
																					{page === 'bill-item-discount'
																						? `${currencyCode}${amtStr(
																								new BigNumber(
																									baseOptItemAmountBill,
																								)
																									.minus(
																										negOptBaseItemAmountBill ??
																											0,
																									)
																									.minus(optItemDiscount ?? 0)
																									.toNumber(),
																						  )}`
																						: `${currencyCode}${amtStr(
																								new BigNumber(
																									baseOptItemAmountBill,
																								)
																									.minus(
																										negOptBaseItemAmountBill ??
																											0,
																									)
																									// .minus(optItemDiscount ?? 0)
																									.toNumber(),
																						  )}`}
																				</span>
																			</Grid>
																		}
																	</>
																);
															})}
														</>
												  ))
												: oi?.orderItemSetMenuOption?.length
												? oi?.orderItemSetMenuOption?.map(v => (
														<>
															{v?.orderItemSetMenuOptionItem?.map(
																oisetmenuoptitem => (
																	<Grid item xs={8}>
																		<span
																			className="mdDesc"
																			style={{
																				paddingLeft:
																					page !== 'item-transfer-dialog'
																						? '26px'
																						: null,
																			}}
																		>
																			{
																				oisetmenuoptitem?.setMenuOptionItem
																					?.menuItem?.name
																			}
																		</span>
																	</Grid>
																),
															)}
														</>
												  ))
												: null}
											{/* {hasItemDisc && (
												<Grid
													item
													xs={hasItem ? 4 : 12}
													style={{ justifyContent: 'right' }}
												>
													<span
														className="mdDesc flex-space right-info"
														style={{
															textDecoration: 'line-through ',
															// marginTop: '4px',
															// textAlign: 'right',
														}}
													>
														{`$${amtStr(
															new BigNumber(
																oi?.totalInfo?.totalBaseAmount,
															)
																.minus(negOrderItem?.[oi?.ID]?.amount ?? 0)
																.toNumber(),
														)}`}
													</span>
												</Grid>
											)} */}
										</Grid>
									</>
								}
							/>

							{rightIcon === 'checkbox' && (
								<ListItemSecondaryAction
									style={{ top: '7px', transform: 'inherit' }}
								>
									<Checkbox
										edge="end"
										value={oi?.ID}
										disabled={
											handlePermDisabled({
												outletID: outletID,
												permEnum:
													PosPermission.HomeTableLayoutBillItemDiscountCreateOrderItemDiscountCreate,
											}) ||
											!oi?.menuItem?.isDiscountable ||
											hasOrderDiscount
										}
										checked={
											oi?.orderItemDiscount?.filter(
												disc => disc?.status === OrderItemDiscountStatus.Active,
											)?.length !== 0
										}
										onChange={(e: any) => {
											if (oi?.menuItem?.isDiscountable) {
												rightAction(e, index, oi);
											}
										}}
										color="primary"
										inputProps={{ 'aria-label': 'secondary checkbox' }}
									/>
								</ListItemSecondaryAction>
							)}

							{rightIcon === 'void' && (
								<ListItemSecondaryAction
									style={{ top: '7px', transform: 'inherit' }}
								>
									<IconButton
										edge="end"
										aria-label="more"
										aria-controls="menu-list"
										aria-haspopup="true"
										onClick={e => {
											rightAction(e, oi?.ID, index, oi);
										}}
										disabled={handlePermDisabled({
											outletID: outletID,
											permEnum:
												PosPermission.HomeTableLayoutAddEditOrderItemVoidOrderItemVoid,
										})}
									>
										<DeleteIcon fontSize="small" />
									</IconButton>
								</ListItemSecondaryAction>
							)}

							{rightIcon === 'quantity' &&
								selectedObj[oi?.ID]?.showQuantity === true && (
									<ListItemSecondaryAction
										style={{
											textAlign: 'right',
											top: '-5px',
											transform: 'inherit',
										}}
									>
										<>
											<IndeterminateCheckBoxIcon
												style={{ fontSize: '25px' }}
												className="order-minus"
												onClick={() =>
													quantityFunc?.onButtonClick(
														'reduce',
														oi?.ID,
														oi?.quantity,
													)
												}
											/>
											<NumberFormat
												customInput={TextField}
												autoComplete="off"
												isNumericString
												variant="standard"
												fullWidth={false}
												margin="dense"
												className="item-transfer-input"
												onValueChange={(value: any) => {
													const { formattedValue, floatValue } = value;

													return (
														formattedValue === '' ||
														floatValue ===
															setSelectedObj({
																...selectedObj,
																[oi?.ID]: {
																	showQuantity: true,
																	selectedQuantity: value?.floatValue,
																	maxQuantity: selectedObj[oi?.ID]?.maxQuantity,
																},
															})
													);
												}}
												isAllowed={values => {
													const { formattedValue, floatValue } = values;
													return (
														formattedValue === '' ||
														floatValue <= selectedObj[oi?.ID]?.maxQuantity
													);
												}}
												InputProps={{
													disableUnderline: true,
												}}
												style={{
													width: '20%',
													borderRadius: '8px',
													border: 'solid',
													borderColor: 'orange',
													textAlign: 'center',
													marginTop: '17px',
													marginBottom: '4px',
												}}
												value={selectedObj[oi?.ID]?.selectedQuantity}
											/>

											<AddBox
												style={{ fontSize: '25px', color: '#ff9800' }}
												className="order-add"
												onClick={() =>
													quantityFunc?.onButtonClick(
														'add',
														oi?.ID,
														oi?.quantity - negOrderItem[oi?.ID]?.quantity ||
															oi?.quantity,
													)
												}
											/>
										</>
									</ListItemSecondaryAction>
								)}
						</ListItem>
					);
				})}
			</List>
		</>
	);
};
