import theme from '@ifca-root/react-component/src/assets/theme';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import {
	DraggableItem,
	DragNDropList,
	DropableItem,
} from '@ifca-root/react-component/src/components/CardList/DragAndDropList';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { StyledMenu } from '@ifca-root/react-component/src/components/others/Menu';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Grid,
	IconButton,
	InputAdornment,
	List,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetMenuItemDocument,
	GetMenuItemForSetMenuOptionItemDocument,
	GetSetMenuOptionDocument,
	useDeleteSetMenuOptionItemMutation,
	useGetMenuItemForSetMenuQuery,
	useGetMenuItemQuery,
	useGetOutletQuery,
	useGetSetMenuOptionQuery,
	useUpdateSetMenuOptionItemMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

interface DialogFormFields {
	name: string;
	StartDate: string;
	EndDate: string;
	description: string;
	taxType: string;
	PromotionValue: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
	StartDate: '',
	EndDate: '',
	description: '',
	taxType: '',
	PromotionValue: '',
};

export const SetMenuStandardItemList = (props: any) => {
	useEffect(() => {
		console.log('SetMenuStandardItemList');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	const { [`outletID`]: outletID, setMenuID } = useParams<
		Record<string, any>
	>();

	const { register, control, handleSubmit } = useForm();

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const {
		anchorEl: editAnchorEl,
		menu: menuEdit,
		handleClick: editHandleClick,
		handleClose: editHandleClose,
	} = useMenuOption();

	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [openEditDialog, setEditDialog] = useState(false);

	let [initialData, setInitialData] = useState<any>();
	const [rerender, setRerender] = useState<any>(null);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	//QUERY & MUTATION
	//QUERY & MUTATION
	//QUERY & MUTATION

	const {
		data: { getMenuItem } = { getMenuItem: [] },
		loading: menuItemLoading,
	} = useGetMenuItemQuery({
		variables: {
			isSetMenu: true,
			ID: setMenuID,
		},
	});

	const {
		data: getAllSetMenuItem,
		loading: getAllSetMenuItemLoading,
	} = useGetMenuItemForSetMenuQuery({
		variables: {
			isSetMenu: true,
			outletID: outletID,
		},
	});

	const {
		data: { getSetMenuOption } = { getSetMenuOption: [] },
		loading: setMenuOptionLoading,
	} = useGetSetMenuOptionQuery({
		fetchPolicy: 'network-only',
		variables: {
			maxSelections: 0,
			menuItemID: setMenuID,
			outletID: outletID,
		},
	});

	const [
		updateSetMenuOptionItem,
		{ loading: updateSetMenuOptionItemLoading },
	] = useUpdateSetMenuOptionItemMutation({
		onError: err => {
			console.log(err);
		},
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setEditDialog(false);
		},
	});

	const [
		deleteSetMenuOptionItem,
		{ loading: deleteSetMenuOptionItemLoading },
	] = useDeleteSetMenuOptionItemMutation({
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
		onError: error => {
			// console.log(error, 'error');

			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
	});

	useEffect(() => {
		setInitialData(getSetMenuOption[0]?.setMenuOptionItem);
	}, [getSetMenuOption]);

	const onDragEnd = ({ source, destination }) => {
		if (destination === undefined || destination === null) return null;

		if (
			source.droppableId === destination.droppableId &&
			destination.index === source.index
		)
			return null;

		const start = source.droppableId;
		const end = destination.droppableId;

		if (start === end) {
			let testArray = initialData.filter((_, idx) => idx !== source.index);

			testArray.splice(destination.index, 0, initialData[source.index]);

			setInitialData([...testArray]);

			return null;
		}
	};

	// const onDragEnd = (result: any) => {
	// 	const { destination, source, draggableId } = result;

	// 	const currentDraggedItem = initialData?.filter(
	// 		(v: any) => v.ID === draggableId,
	// 	)[0];

	// 	if (!destination) {
	// 		return;
	// 	}

	// 	if (
	// 		destination.droppableId === source.droppableId &&
	// 		destination.index === source.index
	// 	) {
	// 		return;
	// 	}

	// 	const newData = initialData;

	// 	newData.splice(source.index, 1);
	// 	newData.splice(destination.index, 0, currentDraggedItem);

	// 	setInitialData([...newData]);
	// };

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const onSubmit = async data => {
		updateSetMenuOptionItem({
			variables: {
				input: {
					ID: menuEdit?.ID,
					menuItemID: menuEdit?.obj?.menuItemID,
					quantity: parseFloat(data?.quantity),
					outletID: outletID,
					setMenuOptionID: menuEdit?.obj?.setMenuOptionID,
				},
			},
			refetchQueries: [
				{
					query: GetMenuItemDocument,
					variables: { isSetMenu: true, ID: setMenuID },
				},
				{
					query: GetSetMenuOptionDocument,
					variables: {
						maxSelections: 0,
						menuItemID: setMenuID,
						outletID: outletID,
					},
				},
			],
		});
	};

	return (
		<>
			{menuItemLoading && <Loading />}
			{setMenuOptionLoading && <Loading />}
			{outletLoading && <Loading />}
			{updateSetMenuOptionItemLoading && <Loading />}
			{deleteSetMenuOptionItemLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Outlet Settings' },
					{ name: 'Standard Item', current: true },
				]}
			/>
			<DynamicSubHeader
				title={getMenuItem[0]?.name} // title text
				// style={{
				// 	width:
				// 		isDesktop && globalState.drawerOpen
				// 			? 'calc(100% - 288px)'
				// 			: isDesktop && !globalState.drawerOpen
				// 			? `calc(100% - 48px)`
				// 			: null,
				// }}
				handleClick={e => handleClick(e, setMenuID, 0)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						{getAllSetMenuItem?.getMenuItem?.map((x, i) => (
							<MenuItem
								key={i}
								value={x.ID}
								defaultValue={x.ID}
								onClick={e => {
									history.push({
										pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu/${x.ID}/set-menu-standard-item`,
									});
								}}
							>
								{x.name}
							</MenuItem>
						))}
					</StyledMenu>
				}
			/>

			<ContentWrapper float style={{ marginTop: isDesktop ? '93px' : '30px' }}>
				<MsgAlert
					//   icon={<InfoOutlined />}
					message={'Drag & drop to arrange sequence. '}
				/>

				<DragNDropList onDragEnd={onDragEnd}>
					<DropableItem droppableId={'menu_item_list'}>
						<List style={{ paddingTop: '0px' }}>
							{!menuItemLoading &&
								!setMenuOptionLoading &&
								!outletLoading &&
								!updateSetMenuOptionItemLoading &&
								!deleteSetMenuOptionItemLoading &&
								(initialData?.length === 0 ? (
									<EmptyList
										title={`No Standard Item in ${getMenuItem[0]?.name} Set`}
										subtitle={`Please create a new standard item`}
									/>
								) : (
									initialData?.map((el: any, index: number) => (
										<DraggableItem
											// style={{ padding: 0 }}
											id={el.ID}
											index={index}
											draggableId={el.ID}
										>
											<div style={{ paddingRight: '6px' }}>
												<img
													src={el?.menuItem?.attachments?.fileURL}
													style={{
														display: 'flex',
														marginTop: '2px',
														marginBottom: '2px',
														marginRight: '4px',
														border: '1px solid #eaeaea',
														width: '55px',
														height: '55px',
														justifyItems: 'center',
														boxSizing: 'border-box',
														borderRadius: '5px',
													}}
													loading="lazy"
												/>
											</div>
											<ListItemText
												primary={
													<>
														<span className="xsTitle flex-space">
															{`${index + 1}. ${el?.menuItem?.name} x${
																el?.quantity
															}`}
														</span>
													</>
												}
												secondary={
													<>
														<span className="desc text-noflow">
															{el?.menuItem?.description}
														</span>
													</>
												}
											/>
											<ListItemSecondaryAction>
												<IconButton
													edge="end"
													aria-label="more"
													aria-controls="menu-list"
													aria-haspopup="true"
													onClick={e => {
														editHandleClick(e, el.ID, index, el);
													}}
												>
													<MoreVert />
												</IconButton>
											</ListItemSecondaryAction>
										</DraggableItem>
									))
								))}
						</List>
					</DropableItem>
				</DragNDropList>

				<Menu
					disableScrollLock
					id="menu-list"
					anchorEl={editAnchorEl}
					keepMounted
					open={Boolean(editAnchorEl)}
					onClose={editHandleClose}
					onClick={editHandleClose}
				>
					<MenuItem onClick={() => setEditDialog(true)}>
						<span>Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span>Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<CommonDialog
				fullWidth={true}
				open={openEditDialog}
				onClose={() => setEditDialog(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div className="title c-darkblue flex-space">
												{getMenuItem[0]?.name}
											</div>
										</div>
									</div>
								</div>
								<div className="infoline-content c-black">
									<div
										className="xsTitle flex-space infoline"
										style={{ color: 'black' }}
									>
										Standard Item
									</div>
									<div
										className=" xsTitle rightText infoline"
										style={{ color: 'black' }}
									>
										Edit
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<Grid container direction="row" alignItems="center">
								<Grid
									item
									xs={9}
									className="left"
									style={{
										paddingTop: '5px',
										paddingRight: '10px',
										paddingBottom: '12px',
									}}
								>
									<div>
										<div
											className="xsTitle item-name"
											style={{
												textDecoration: 'underline',
												fontWeight: 90000, //sdsds
											}}
										>
											{menuEdit?.obj?.menuItem?.name}
										</div>
										<div
											className="desc text-noflow"
											style={{ paddingTop: '8px' }}
										>
											{menuEdit?.obj?.menuItem?.description}
										</div>
									</div>
									{/* <div className="dialog-span" style={{ paddingTop: '5px' }}> */}
								</Grid>
								<Grid item xs={3}>
									<Controller
										as={
											<NumberFormat
												customInput={TextField}
												allowNegative={false}
												fullWidth={false}
												autoComplete="off"
												isNumericString
												variant="outlined"
												// color="primary"
												ref={register}
												// value={menuEdit?.obj?.menuItemID}
												className="custom-add align-text"
												defaultValue={menuEdit?.obj?.quantity}
												InputProps={{
													startAdornment: (
														<InputAdornment
															className="highlight-text"
															position="start"
														>
															<div className="highlight-text xsTitle">Qty</div>
														</InputAdornment>
													),
												}}
											/>
										}
										style={{ margin: '0px', padding: '0px' }}
										variant="outlined"
										size="small"
										fullWidth
										margin="dense"
										control={control}
										ref={register}
										name="quantity"
										// defaultValue={v.price}
									/>
								</Grid>
								{/* </div> */}
								{/* <div style={{ paddingTop: '12px' }}>
								<Controller
									as={TextField}
									label="Menu Description"
									defaultValue={menuEdit?.obj?.menuItem?.description}
									name="description"
									fullWidth={true}
									variant="standard"
									size="small"
									multiline={true}
									ref={register}
									control={control}
								/>
							</div> */}
							</Grid>
						</>
					),

					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setEditDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: () => {
										handleSubmit(onSubmit)();
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{
					maxSelections: 0,
					menuItemID: setMenuID,
					outletID: outletID,
				}}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.menuItem?.name}
				deleteMutation={deleteSetMenuOptionItem}
				refetchDocument={GetSetMenuOptionDocument}
				refetchQueries={[
					{
						query: GetSetMenuOptionDocument,
						variables: {
							maxSelections: 0,
							menuItemID: setMenuID,
							outletID: outletID,
						},
					},
					{
						query: GetMenuItemForSetMenuOptionItemDocument,
						variables: {
							setMenuID: setMenuID,
							outletID: outletID,
							isStandard: true,
						},
					},
				]}
			/>

			<FloatButton
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu/${setMenuID}/set-menu-standard-item/add`,
						state: getMenuItem,
					})
				}
			/>
		</>
	);
};
