import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import { CardContents } from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
	Checkbox,
	FormControlLabel,
	Grid,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetMenuItemDocument,
	useAttachmentListingLazyQuery,
	useCreateMenuItemMutation,
	useGetMenuItemLazyQuery,
	useGetOutletQuery,
	useUpdateMenuItemMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

interface SetMenuForm {
	name: String;
	nativeName: String;
	description: String;
	isChefRecommended: Boolean;
	isServiceChargeable: Boolean;
	isTaxable: Boolean;
	isDiscountable: Boolean;
	isSetMenu: Boolean;
}

export const SetMenuForm = props => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { mode } = props;
	let location = useLocation();
	const editData = location?.state as any;
	const [openExitDialog, setOpenExitDialog] = useState(false);

	const { [`outletID`]: outletID, setMenuID } = useParams<
		Record<string, any>
	>();

	const { globalState, dispatch } = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const [
		getMI,
		{
			called: MenuItemCalled,
			loading: MenuItemLoading,
			data: { getMenuItem } = { getMenuItem: [] },
		},
	] = useGetMenuItemLazyQuery({
		onCompleted: ({ getMenuItem }) => {},
	});

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [
		updateMenuItem,
		{
			loading: updateMenuItemLoading,
			called: updateMenuItemCalled,
			error: updateMenuItemError,
		},
	] = useUpdateMenuItemMutation({
		onCompleted: data => {
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu`,
				});
			}, 1000);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const {
		files,
		handleUploadChange,
		previewFiles,
		remove: removeFile,
		handleEditUpload,
	} = useUploadAttachment();

	const [
		loadAttachment,
		{ loading: attachmentLoad, error: attachmentError, data: attachmentData },
	] = useAttachmentListingLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: data => {
			handleEditUpload(data?.attachmentListing);
			previewFiles.push(...data?.attachmentListing?.map(x => x?.fileURL));
		},
	});

	useEffect(() => {
		if (mode == 'edit') {
			loadAttachment({ variables: { refID: editData?.ID } });
		}
	}, [mode, editData]);

	useEffect(() => {
		getMI({
			variables: {
				ID: setMenuID,
			},
		});
	}, [MenuItemCalled]);

	const yupSchema = yup.object().shape({
		name: CommonYupValidation.requireField(SystemMsgs.name()).trim(),
		nativeName: CommonYupValidation.requireField(
			SystemMsgs.nativeName(),
		).trim(),
		description: CommonYupValidation.requireField(
			SystemMsgs.description(),
		).trim(),
	});

	const { register, control, handleSubmit, errors } = useForm<SetMenuForm>({
		defaultValues: {
			name: editData ? getMenuItem[0]?.name || editData?.name : '',
			nativeName: editData
				? getMenuItem[0]?.nativeName || editData?.nativeName
				: '',
			description: editData
				? getMenuItem[0]?.description || editData?.description
				: '',
			isChefRecommended: editData
				? getMenuItem[0]?.isChefRecommended || editData?.isChefRecommended
				: '',
			isServiceChargeable: editData
				? getMenuItem[0]?.isServiceChargeable || editData?.isServiceChargeable
				: '',
			isTaxable: editData
				? getMenuItem[0]?.isTaxable || editData?.isTaxable
				: '',
			isDiscountable: editData
				? getMenuItem[0]?.isDiscountable || editData?.isDiscountable
				: '',
			isSetMenu: editData
				? getMenuItem[0]?.isSetMenu || editData?.isSetMenu
				: '',
		},
		resolver: yupResolver(yupSchema),
		mode: 'onSubmit',
	});

	const [
		createMenuItem,
		{
			data: createdMealPeriodData,
			loading: createMenuItemLoading,
			called: createMenuItemCalled,
			error: createMenuItemError,
		},
	] = useCreateMenuItemMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu`,
					state: { success: true, msgMode: 'create' },
				});
			}, 1000);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const onSubmit = (data: any) => {
		if (mode == 'add') {
			createMenuItem({
				variables: {
					input: {
						name: data?.name,
						nativeName: data?.nativeName,
						description: data?.description,
						outletID: outletID,
						isChefRecommended: data?.isChefRecommended,
						isServiceChargeable: data?.isServiceChargeable,
						defaultQuantity: 10,
						isTaxable: data?.isTaxable,
						isDiscountable: data?.isDiscountable,
						isSetMenu: true,
						file: files,
					},
				},
				refetchQueries: [
					{
						query: GetMenuItemDocument,
						variables: { outletID },
					},
				],
			});
		} else {
			updateMenuItem({
				variables: {
					input: {
						ID: setMenuID,
						name: data?.name,
						nativeName: data?.nativeName,
						description: data?.description,
						outletID: outletID,
						isChefRecommended: data?.isChefRecommended,
						defaultQuantity: 10,
						isTaxable: data?.isTaxable,
						isDiscountable: data?.isDiscountable,
						isSetMenu: true,
						file: files,
					},
				},
				refetchQueries: [
					{
						query: GetMenuItemDocument,
						variables: { outletID },
					},
				],
			});
		}
	};

	const createUpdateCalled = editData
		? updateMenuItemCalled
		: createMenuItemCalled;

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	return (
		<>
			{MenuItemLoading && <Loading />}
			{attachmentLoad && <Loading />}
			{outletLoading && <Loading />}
			{updateMenuItemLoading && <Loading />}
			{createMenuItemLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Set Menu', current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>
			<ContentWrapper footer>
				<form>
					<CardContents devFullWidth={true}>
						<Controller
							as={TextField}
							name="name"
							label="Set Name"
							fullWidth
							autoComplete="off"
							multiline={true}
							margin="normal"
							defaultValue={editData?.name || getMenuItem[0]?.name || ''}
							ref={register}
							control={control}
							required
							helperText={(errors?.name as any)?.message}
							error={errors?.name ? true : false}
						></Controller>
						<Controller
							as={TextField}
							name="nativeName"
							label="Set Native Name"
							fullWidth
							autoComplete="off"
							multiline={true}
							margin="normal"
							defaultValue={
								editData?.nativeName || getMenuItem[0]?.nativeName || ''
							}
							ref={register}
							control={control}
							required
							helperText={(errors?.nativeName as any)?.message}
							error={errors?.name ? true : false}
						></Controller>
						<Controller
							as={TextField}
							name="description"
							label="Description"
							fullWidth
							autoComplete="off"
							multiline={true}
							margin="normal"
							defaultValue={
								editData?.description || getMenuItem[0]?.description || ''
							}
							ref={register}
							control={control}
							required
							helperText={(errors?.description as any)?.message}
							error={errors?.name ? true : false}
						></Controller>

						<FileUploadInput
							label="Upload Attachment"
							files={files}
							onHandleUploadChange={handleUploadChange}
							multiple
							style={{ marginTop: '16px' }}
							imagePreview={
								<>
									{previewFiles?.map((v, i) => (
										<UploadPreview
											remove
											key={v}
											src={v}
											onClick={() => removeFile(i)}
											mediaType={
												files[i]?.type ??
												attachmentData?.attachmentListing[i]?.mediaType
											}
										/>
									))}
								</>
							}
						/>
						<Grid container>
							<Grid item xs={7}>
								<FormControlLabel
									style={{ fontWeight: 'bold' }}
									control={
										<Checkbox
											name="isServiceChargeable"
											color="primary"
											className="left"
											inputRef={register({})}
											defaultChecked={
												getMenuItem[0]?.isServiceChargeable! ||
												editData?.isServiceChargeable ||
												false
											}
										/>
									}
									label="Service Charge"
								/>
							</Grid>
							<Grid item xs={5}>
								<FormControlLabel
									style={{ fontWeight: 'bold' }}
									control={
										<Checkbox
											name="isTaxable"
											color="primary"
											className="left"
											inputRef={register({})}
											defaultChecked={
												getMenuItem[0]?.isTaxable! ||
												editData?.isTaxable ||
												false
											}
										/>
									}
									label="Tax"
								/>
							</Grid>
							<Grid item xs={7}>
								<FormControlLabel
									style={{ fontWeight: 'bold' }}
									control={
										<Checkbox
											name="isChefRecommended"
											color="primary"
											className="left"
											inputRef={register({})}
											defaultChecked={
												getMenuItem[0]?.isChefRecommended! ||
												editData?.isChefRecommended ||
												false
											}
										/>
									}
									label="Chef Recommended"
								/>
							</Grid>
							<Grid item xs={5}>
								<FormControlLabel
									style={{ fontWeight: 'bold' }}
									control={
										<Checkbox
											name="isDiscountable"
											color="primary"
											className="left"
											inputRef={register({})}
											defaultChecked={
												getMenuItem[0]?.isDiscountable! ||
												editData?.isDiscountable ||
												false
											}
										/>
									}
									label="Discountable"
								/>
							</Grid>
						</Grid>
					</CardContents>
				</form>
				<DynamicFooter
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					options={[
						{
							name: 'Save',
							// onClick: () => {
							// 	handleSubmit(onSubmit)();
							// },
							color: 'primary',
							onClick: () => {
								handleSubmit(data => !createUpdateCalled && onSubmit(data))();
							},
						},
					]}
				/>
				<ExitConfirmationDialog
					openExitDialog={openExitDialog}
					setOpenExitDialog={setOpenExitDialog}
					backPath={`/menu/outlet-app/outlet-setting/${outletID}/set-menu`}
				/>
				{/* <CommonDialog
					fullWidth={true}
					open={openExitDialog}
					onClose={() => {
						setOpenExitDialog(false);
					}}
					sections={{
						header: {
							dynamic: (
								<div>
									<div className="dialogo-dynamic-content">
										<span
											className="title c-orange flex-space"
											style={{
												fontSize: '13px',
												fontWeight: 'bold',
											}}
										>
											Exit Confirmation
										</span>
									</div>
								</div>
							),
						},
						body: () => (
							<CardContents>
								<span className="">
									Are you sure you want to exit? Your changes will not be saved.
								</span>
							</CardContents>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
										},
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
											if (mode === 'edit') {
												history.push({
													pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu`,
												});
											} else {
												history.push({
													pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu`,
												});
											}
										},
										variant: 'contained',
										color: 'primary',
									},
								},
							],
						},
					}}
				/> */}
			</ContentWrapper>
		</>
	);
};
