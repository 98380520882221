import React from 'react';
import { KitchenHome } from './KitchenHome';
import { KitchenLogin } from './KitchenLogin';

const kitchenRoutes = [
	{
		props: {
			exact: true,
			path: `/kitchen/home/:outletID/printer/:kitchenPrinterID`,
		},
		component: <KitchenHome />,
	},
	{
		props: {
			exact: true,
			path: `/kitchen/login`,
		},
		component: <KitchenLogin />,
	},
];

export default kitchenRoutes;
