import React from 'react';
import { RoleAssignmentList } from './RoleAssignmentList';

const roleAssignmentRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/outlet-role-assignment`,
		},
		component: <RoleAssignmentList />,
	},
];

export default roleAssignmentRoutes;
