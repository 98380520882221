import React from 'react'
import empty from '../../assets/icons/empty.svg'
interface IEmptyListProps {
  title?: string
  subtitle?: string
  errorMessage?: boolean
}
const EmptyList = (props: IEmptyListProps) => {
  const { title, subtitle, errorMessage } = props
  return (
    <div className="empty-listing">
      {/* <img src={empty} alt="empty-listing" width={300} /> */}

      <div
        className="smTitle"
        style={{ ...(errorMessage ? { color: 'red' } : null) }}
      >
        {title}
      </div>

      <div className="desc">{subtitle}</div>
    </div>
  )
}
export default EmptyList
