import * as yup from 'yup';
import { SystemMsgs } from './SystemMsg';

// Please refer to https://github.com/jquense/yup for more information.
export const CommonYupValidation = {
	email: () => {
		return yup
			.string()
			.email()
			.required(SystemMsgs.email());
	},
	password: () => {
		return yup
			.string()
			.required('New password is required')
			.min(8, 'Password must be at least 8 characters')
			.matches(
				/(?=.*[A-Z])/,
				'Password must contain at least one uppercase character',
			)
			.matches(
				/(?=.*[a-z])/,
				'Password must contain at least one lowercase character',
			)
			.matches(/(?=.*\d)/, 'Password must contain at least one number')
			.matches(
				/(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})/,
				'Password must contain at least one symbol',
			);
	},
	confirmPassword: () => {
		return yup
			.string()
			.required()
			.oneOf([yup.ref('password'), null], 'Confirm password does not match');
	},
	contactNo: () => {
		return yup
			.string()
			.required(SystemMsgs.contactNo())
			.matches(/^[+]6?0\d{8}(?:\d{1})?$/, 'Contact No is not valid');
	},
	mobileNo: () => {
		return yup
			.string()
			.required(SystemMsgs.mobileNo())
			.matches(/^[+]6?01\d{8}(?:\d{1})?$/, 'Mobile No is not valid');
	},
	requireNumberField: (msg?: string) => {
		return yup.number() && yup.string().required(msg);
	},
	requireField: (msg?: string) => {
		return yup.string().required(msg);
	},
};
