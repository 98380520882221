import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

interface Props {
  space?: string
  font?: string
  children: any
  icon: any
  rightText?: boolean
}

const IconText = props => {
  let { children, font, space, icon, rightText } = props

  //Assing Space to Container
  const useStyles = makeStyles({
    containerSpace: {
      width: space,
      justifyContent: rightText ? 'right' : null,
      paddingRight: rightText ? '10px' : null,
    },
  })
  const classes = useStyles(props)

  //Default Font
  if (font == null) font = 'xsTitle'

  return (
    <>
      <span className={`${classes.containerSpace} icon-text`}>
        {icon}
        <span className={font}>{children}</span>
      </span>
    </>
  )
}

export default IconText
