import { MenuItem } from '@material-ui/core'
import React from 'react'

const MenuOptions = ({ mounted, disabled, onClick, name }) => {
  if (mounted) {
    return (
      <MenuItem disabled={disabled} onClick={onClick}>
        <span>{name}</span>
      </MenuItem>
    )
  } else return null
}

export default MenuOptions
