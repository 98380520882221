import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { ItemTransferConfirmationDialog } from 'containers/OutletModule/Home/TableSubmenu/ItemTransfer/ItemTransferConfirmationDialog';
import React from 'react';

interface TableDialogProps {
	open: boolean;
	setOpen: any;
	title: string;
	subTitle?: string;
	secSubTitle?: string;
	simpleDescription?: string;
	isForm?: boolean;
	handleSubmit: any;
	onSubmit: any;
	formStructure: any;
	infoLine?: string;
	infoLineRight?: string;
	infoLineMiddle?: any;
	disabled?: boolean;
	updateConfirm?: boolean;
	setUpdateConfirm?: any;
	targetPatron?: string;
	currPatron?: string;
	discount?: any;
}

export const TableDialog = (props: TableDialogProps) => {
	const {
		open,
		setOpen,
		title,
		subTitle,
		secSubTitle,
		simpleDescription,
		isForm,
		handleSubmit,
		onSubmit,
		formStructure,
		infoLine,
		infoLineRight,
		infoLineMiddle,
		disabled,
		updateConfirm,
		setUpdateConfirm,
		targetPatron,
		currPatron,
		discount,
	} = props;

	console?.log(targetPatron, currPatron, discount, 'test');

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={open}
				onClose={() => setOpen(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{title}</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle flex-space infoline">{infoLine}</div>
									<div className="xsTitle infoline rightText">
										{infoLineRight}
									</div>
								</div>
								{/* <div className="infoline-content ">
									{subTitle}&nbsp;
									<div className="xsTitle flex-space infoline">
										{secSubTitle}
									</div>
								</div> */}
							</div>
						),
						// children: (
						// 	<>
						// 		{/* <ListItem
						// 			style={{ backgroundColor: '#c7d6ff' }}
						// 			className="remove-padding"
						// 		> */}
						// 		<ListItemText
						// 			primary={
						// 				<>
						// 					<div className="">
						// 						<div className="dialog-dynamic-content">
						// 							<div className="session">
						// 								<div className="flex session">
						// 									<div
						// 										className="title highlight-text"
						// 										style={{ color: '#ff9800' }}
						// 									>
						// 										<span
						// 										// style={{ marginRight: '15px' }}
						// 										>
						// 											{title}
						// 										</span>
						// 										{/* {secSubTitle && <span>{secSubTitle}</span>} */}
						// 									</div>
						// 								</div>
						// 							</div>
						// 						</div>
						// 						<div className="session">
						// 							<div className="flex session">
						// 								<div
						// 									className="xsTitle flex-space infoline-content"
						// 									style={{ color: 'black' }}
						// 								>
						// 									{infoLine}&nbsp;
						// 									<div className="flex-space">{infoLineMiddle}</div>
						// 									<div className="xsTitle rightText">
						// 										{infoLineRight}
						// 									</div>
						// 								</div>
						// 							</div>
						// 						</div>
						// 					</div>
						// 				</>
						// 			}
						// 			secondary={
						// 				<>
						// 					<span className="highlight-text flex-space">
						// 						{subTitle}
						// 					</span>
						// 					{secSubTitle && (
						// 						<span className="highlight-text">{secSubTitle}</span>
						// 					)}
						// 				</>
						// 			}
						// 		/>
						// 		{/* </ListItem> */}
						// 		{/* <hr className="line-subheader" /> */}
						// 	</>
						// ),
					},
					body: () => (
						<>
							<div
								style={{
									padding: simpleDescription ? '18px' : isForm ? '0px' : '5px',
								}}
							>
								{simpleDescription && (
									<span className="smTitle">{simpleDescription}</span>
								)}
								{isForm && <form>{formStructure()}</form>}
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => setOpen(false),
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										// if (isForm) {
										// 	handleSubmit(onSubmit)();
										// } else {
										// 	alert('confirm');
										// 	handleSubmit(onSubmit)();
										// }

										if (currPatron === targetPatron && discount === 0) {
											handleSubmit(onSubmit)();
										} else if (currPatron !== targetPatron) {
											setUpdateConfirm(true);
										} else if (discount !== 0) {
											setUpdateConfirm(true);
										} else {
											setUpdateConfirm(true);
										}
									},
									variant: 'contained',
									color: disabled === false ? 'primary' : 'inherit',
									disabled: disabled,
								},
							},
						],
					},
				}}
			/>

			{updateConfirm && (
				<ItemTransferConfirmationDialog
					updateConfirm={updateConfirm}
					setUpdateConfirm={setUpdateConfirm}
					handleSubmit={handleSubmit}
					onSubmit={onSubmit}
					targetPatron={targetPatron}
					currPatron={currPatron}
					discount={discount}
				/>
			)}
		</>
	);
};
