import React, { ReactNode } from 'react'
import Slider from 'react-slick'
import { makeStyles } from '@material-ui/core'

interface Props {
  children: ReactNode
}
export default function MultipleItemsCarousel(props) {
  const { children } = props
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    initialSlide: 0,
  }

  return (
    <>
      <Slider {...settings} className="image-carousell">
        {/* <div className="img-container"> */}
        {/* <img src={image} alt="" /> */}
        {children}
        {/* </div> */}
      </Slider>
    </>
  )
}
