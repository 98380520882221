import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetReasonDocument,
	useCreateReasonMutation,
	useDeleteReasonMutation,
	useGetOutletQuery,
	useGetReasonQuery,
	useUpdateReasonMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

interface ReasonProps {
	description: string;
}

export const ReasonSetupList = (props: any) => {
	const history = useHistory();
	const { outletID }: any = useParams();
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();

	const [deleteDialog, setDeleteDialog] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [double, setDouble] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const yupSchema = yup.object().shape({
		description: CommonYupValidation?.requireField(
			SystemMsgs.description(),
		).trim(),
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const {
		called: reasonCalled,
		loading: reasonLoading,
		error: reasonErrror,
		data: { getReason } = { getReason: [] },
	} = useGetReasonQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	const [
		deleteReason,
		{ loading: deleteReasonLoading },
	] = useDeleteReasonMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
			setDeleteDialog(false);
		},
	});

	const [
		createReason,
		{ data: createReasonData, loading: createReasonLoading },
	] = useCreateReasonMutation({
		onError: error => {
			console.log(error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setOpenDialog(false);
		},
	});

	const [
		updateReason,
		{ loading: updateReasonLoading },
	] = useUpdateReasonMutation({
		onError: error => {
			console.log(error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setOpenDialog(false);
		},
	});

	const outletArray: any[] = getOutlet;

	const {
		register,
		control,
		setError,
		handleSubmit,
		errors,
		clearErrors,
		watch,
		setValue,
	} = useForm<ReasonProps>({
		defaultValues: {
			description: menu?.obj?.description,
		},
		resolver: yupResolver(yupSchema),
		mode: 'onSubmit',
	});

	const onSubmit = data => {
		isEdit === false
			? createReason({
					variables: {
						input: {
							outletID: outletID,
							name: data?.description,
							description: data?.description,
						},
					},
					refetchQueries: [
						{
							query: GetReasonDocument,
							variables: {
								outletID: outletID,
							},
						},
					],
			  })
			: updateReason({
					variables: {
						input: {
							ID: menu?.ID,
							outletID: outletID,
							name: data?.description,
							description: data?.description,
						},
					},
					refetchQueries: [
						{
							query: GetReasonDocument,
							variables: {
								outletID: outletID,
							},
						},
					],
			  });
	};

	const checkDuplicate = reason => {
		const data = getReason?.map(x => x?.description);

		if (data.includes(reason)) {
			return true;
		} else {
			return false;
		}
	};

	useEffect(() => {
		if (checkDuplicate(watch('description')) === false) {
			clearErrors('description');
			setOpenSnackBar(false);
		}
	}, [watch('description')]);

	return (
		<>
			{reasonLoading && <Loading />}
			{outletLoading && <Loading />}
			{deleteReasonLoading && <Loading />}
			{createReasonLoading && <Loading />}
			{updateReasonLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/outlet-policy`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Reason Setup', current: true },
				]}
			/>

			<ContentWrapper float>
				<List className="core-list">
					{!outletLoading &&
						!reasonLoading &&
						(getReason?.length > 0 ? (
							getReason?.map((x, index) => {
								return (
									<ListItem key={index}>
										<ListItemText
											primary={
												<>
													<span className="xsTitle flex-space">{x?.name}</span>
													<span className="xxTitle highlight-text text-separator"></span>
													<span className="xxTitle highlight-text"></span>
												</>
											}
											secondary={
												<>
													<span className="desc">
														<span className="desc ">
															Last Updated on {formatDate(x?.createdTs)}
														</span>
													</span>
												</>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="more"
												aria-controls="menu-list"
												aria-haspopup="true"
												onClick={e => handleClick(e, x.ID, index, x)}
											>
												<MoreVert />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								);
							})
						) : (
							<EmptyList
								title="No reason found"
								subtitle="Please create a reason to continue"
							/>
						))}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() => {
							setOpenDialog(true);
							setIsEdit(true);
							setDouble(false);
						}}
					>
						<span>Edit</span>
					</MenuItem>
					<MenuItem onClick={() => setDeleteDialog(true)}>
						<span>Delete</span>
					</MenuItem>
				</Menu>
				<FloatButton
					onClick={() => {
						setOpenDialog(true);
						setIsEdit(false);
					}}
				/>
			</ContentWrapper>

			{/*Create Dialog*/}
			{/*Create Dialog*/}
			{/*Create Dialog*/}

			<CommonDialog
				fullWidth={true}
				open={openDialog}
				onClose={() => {
					setOpenDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<>
								{isEdit ? (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												<div className="flex session">
													<div className="title flex-space">Reason Setup</div>
												</div>
											</div>
										</div>
										<div className="infoline-content">
											<div className="xsTitle flex-space infoline">
												{menu?.obj?.name}
											</div>
											<div className="xsTitle rightText infoline">Edit</div>
										</div>
									</div>
								) : (
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												<div className="title ">Reason Setup</div>
												<div className="flex-space"></div>
												<div className="title ">New</div>
											</div>
										</div>
									</div>
								)}
							</>
						),
					},
					body: () => (
						<>
							{/* <div className="content-container"> */}
							<span
								onChange={(e: any) => {
									if (e.target.value) {
										if (checkDuplicate(e.target.value) === true) {
											setOpenSnackBar(true);
											setSnackBarMsg('Duplicate reason');
											setError('description', {
												type: 'manual',
												message: 'Duplicate reason',
											});
										}
									} else if (!e.target.value) {
										clearErrors('description');
										setOpenSnackBar(false);
									}
								}}
							>
								<Controller
									as={TextField}
									name="description"
									label="Description"
									autoComplete="off"
									multiline={true}
									required
									fullWidth
									ref={register}
									control={control}
									defaultValue={isEdit ? menu?.obj?.description : null}
									helperText={errors?.description?.message}
									error={errors?.description ? true : false}
									onInput={() => {
										setDouble(false);
									}}
								/>
							</span>
							{/* </div> */}
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									variant: 'contained',
									color: 'primary',
									onClick: () => {
										setOpenDialog(false);
									},
								},
							},
							{
								displayText: 'Confirm',
								props: {
									variant: 'contained',
									color:
										watch('description') === '' ||
										watch('description') === null ||
										watch('description') === undefined ||
										checkDuplicate(watch('description')) === true
											? 'default'
											: 'primary',

									disabled:
										watch('description') === '' ||
										watch('description') === null ||
										watch('description') === undefined ||
										checkDuplicate(watch('description')) === true,
									// double ||

									onClick: () => {
										if (errors?.description ? false : true) {
											handleSubmit(onSubmit)();
											setDouble(true);
										}
									},
								},
							},
						],
					},
				}}
			></CommonDialog>

			{/* Delete Dialog */}
			{/* Delete Dialog */}
			{/* Delete Dialog */}

			<GeneralDeleteDialog
				ID={menu?.ID}
				openDialog={deleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				refetchID={{ outletID: outletID }}
				deleteMutation={deleteReason}
				refetchDocument={GetReasonDocument}
			></GeneralDeleteDialog>
		</>
	);
};
