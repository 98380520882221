import React from 'react';
import { SetMenuForm } from './SetMenuForm';
import { SetMenuList } from './SetMenuList';
import { SetMenuOptionList } from './SetMenuOptionList';
import { SetMenuStandardItemForm } from './SetMenuStandardItemForm';
import { SetMenuStandardItemList } from './SetMenuStandardItemList';

const setMenuRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/set-menu`,
		},
		component: <SetMenuList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/set-menu/:setMenuID/set-menu-standard-item`,
		},
		component: <SetMenuStandardItemList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/set-menu/:setMenuID/set-menu-option-item`,
		},
		component: <SetMenuOptionList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/set-menu/:setMenuID/set-menu-standard-item/add`,
		},
		component: <SetMenuStandardItemForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/set-menu/add`,
		},
		component: <SetMenuForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/set-menu/:setMenuID/edit`,
		},
		component: <SetMenuForm mode="edit" />,
	},
];

export default setMenuRoutes;
