import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MenuOptions from '@ifca-root/react-component/src/components/CardList/MenuOptions';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar';
import {
	Checkbox,
	FormControlLabel,
	IconButton,
	InputAdornment,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	TextField,
	Typography,
} from '@material-ui/core';
import { MoreVert, Visibility, VisibilityOff } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetUsersByAccountAndSoftwareDocument,
	PosPermission,
	useChangePasswordSuMutation,
	useGetOutletQuery,
	useGetUsersByAccountAndSoftwareQuery,
	useResendInvitationMutation,
	UserSelection,
	useUnblockUserMutation,
} from 'generated/graphql';
import { encryptMessage } from 'helpers/Crypto/JSEncrypt';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { IAction } from 'helpers/model';
import { CommonYupValidation } from 'helpers/yup';
import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import SuperUser from '../../../../../assets/icons/super-user.svg';
import InactiveUser from '../../../../../assets/icons/superuser-inactive.svg';
import { InactiveDialog } from '../../GenSetGenericComp/InactiveDialog';
import AppContext from 'containers/App/Store/AppContext';
import { SystemMsgs } from 'helpers/SystemMsg';

interface DialogFormFields {
	name: string;
}

interface unblockUserForm {
	password: string;
	confirmPassword: string;
}
interface unblockUserProps {
	showNewPassword: boolean;
	showConfirmPassword: boolean;
	message: string;
	dialog: boolean;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};

export const UsersList = () => {
	const history = useHistory();
	const location = useLocation();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const [inactiveDialog, setInactiveDialog] = useState(false);
	const [openDialog, setOpenDialog] = useState(false);
	const [mode, setMode] = useState('');
	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const outletID = localStorage.getItem('latestOutlet');

	const { handlePermDisabled } = usePermissionChecker();

	const [rerender, setRender] = useState(false);

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const reducer: Reducer<unblockUserProps, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const initialState: unblockUserProps = {
		showNewPassword: false,
		showConfirmPassword: false,
		message: '',
		dialog: false,
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const yupSchema = yup.object().shape({
		password: CommonYupValidation.password(),
		confirmPassword: yup
			.string()
			.required()
			.oneOf([yup.ref('password'), null], 'Passwords must match'),
	});

	const { handleSubmit, register, errors, control, getValues } = useForm<
		unblockUserForm
	>({
		defaultValues: {
			password: '',
			confirmPassword: '',
		},
		resolver: yupResolver(yupSchema),
	});

	/////////////////////////////////////
	/////// QUERIES AND MUTATIONS ///////
	/////////////////////////////////////

	const {
		called,
		loading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getUsersByAccountAndSoftware }) => {},
	});

	const [unblockUser, { loading: unblockLoading }] = useUnblockUserMutation({
		onError: error => {
			console.log(error);
		},
		onCompleted: data => {
			setOpenDialog(false);
			setOpenSnackBar(true);
			setSnackBarMsg('Record Unblocked');
		},
	});

	const [
		changePassword,
		{ loading: changePasswordLoading },
	] = useChangePasswordSuMutation({
		onError: error => {
			console.log(error);
		},
		onCompleted: data => {
			setOpenDialog(false);
			setOpenSnackBar(true);
			setSnackBarMsg('Password changed successfully');
		},
	});

	const [
		resendInvitation,
		{ loading: resendInvitationLoading, error: resendInvitationError },
	] = useResendInvitationMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.reinvite());
		},
	});

	useEffect(() => {
		if (
			getUsersByAccountAndSoftware &&
			getUsersByAccountAndSoftware?.length > 0
		) {
			setOriginalListing(getUsersByAccountAndSoftware);
		}
	}, [getUsersByAccountAndSoftware]);

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const userSearch = JSON.parse(localStorage.getItem('searchFilter'));

	const {
		data: { getOutlet } = { getOutlet: [] },
		loading: outletLoading,
		called: outletCalled,
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const userMenuOption = [
		{
			mounted: true,
			name: 'View',
			onClick: () => {
				history.push(
					`/menu/outlet-app/general-setting/user-access/user/${menu?.ID}/detail`,
				);
			},
			disabled: false,
		},
		{
			mounted: true,
			name: 'Edit',
			onClick: () => {
				history.push({
					pathname: `/menu/outlet-app/general-setting/user-access/user/${menu?.ID}/edit`,
					state: menu.obj,
				});
			},
			disabled: handlePermDisabled({
				outletID: outletID,
				permEnum: PosPermission.GeneralSettingAccessSecurityUsersUpdate,
			}),
		},
		{
			mounted: menu?.obj?.commonStatus === 'BLOCK',
			name: 'Unblock',
			onClick: () => {
				setOpenDialog(true);
				setMode('Unblock User');
			},
			disabled: false,
		},
		{
			mounted: menu?.obj?.confirmed === false,
			name: 'Resend Invitation',
			onClick: () => {
				resendInvitation({ variables: { userID: menu?.obj?.ID } });
			},
			disabled: false,
		},
		{
			mounted:
				menu?.obj?.commonStatus === 'ACTIVE' &&
				(user?.superUser || user.ID === menu?.ID),
			name: 'Change Password',
			onClick: () => {
				setOpenDialog(true);
				setMode('Change Password');
			},
			disabled: false,
		},
	];

	const [checkSuperuser, setCheckSuperuser] = useState(false);
	const [filterUserStatus, setFilterUserStatus] = useState('NONE');

	const onSubmit = data => {
		// if (mode === 'Unblock User') {
		// 	unblockUser({
		// 		variables: {
		// 			input: {
		// 				ID: menu.obj.ID,
		// 				password: encryptMessage(data?.password),
		// 				confirmedPassword: encryptMessage(data?.confirmPassword),
		// 			},
		// 		},
		// 		refetchQueries: [
		// 			{
		// 				query: GetUsersByAccountAndSoftwareDocument,
		// 				variables: {
		// 					superUserBool: UserSelection.All,
		// 				},
		// 			},
		// 		],
		// 	});
		// } else {
		changePassword({
			variables: {
				input: {
					ID: menu.obj.ID,
					password: encryptMessage(data?.password),
					confirmedPassword: encryptMessage(data?.confirmPassword),
				},
			},
			refetchQueries: [
				{
					query: GetUsersByAccountAndSoftwareDocument,
					variables: {
						superUserBool: UserSelection.All,
					},
				},
			],
		});
		// }
	};

	useEffect(() => {
		if (
			!loading &&
			getUsersByAccountAndSoftware?.length > 0 &&
			!!userSearch?.value
		) {
			handleSearch(`'"${userSearch?.value ?? ''}"`, [
				'name',
				'email',
				'commonStatus',
				'superuser',
			]);
			setRender(true);
		}
	}, [loading, getUsersByAccountAndSoftware, rerender]);

	const { globalState } = useContext(AppContext as any);

	return (
		<>
			{loading && <Loading />}
			{unblockLoading && <Loading />}

			<MainHeader
				onClick={() => {
					localStorage.removeItem('searchFilter');
					history.push({
						pathname: `/menu/outlet-app/general-setting/user-access`,
					});
				}}
				mainBtn="back"
				smTitle={'Outlet App'}
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Settings' },
					{ name: 'Access Security' },
					{ name: 'Users', current: true },
				]}
			/>
			<div
				className={
					globalState.drawerOpen
						? 'search-filter with-dropdown-filter-general-settings'
						: 'search-filter with-dropdown-filter-general-settings-fw'
				}
			>
				<SearchHeader
					title="User Listing "
					value={`${
						filteredList?.filter(e => {
							if (!checkSuperuser && filterUserStatus === 'NONE') {
								return e;
							} else if (checkSuperuser && filterUserStatus === 'NONE') {
								return e?.superUser === true;
							} else if (checkSuperuser && filterUserStatus !== 'NONE') {
								return e?.superUser && e?.commonStatus === filterUserStatus;
							} else {
								return e?.commonStatus === filterUserStatus;
							}
						})?.length
					}`}
					// value={`${filteredList?.length ?? filterUserStatus?.length ?? 0}`}
					onChangeAction={e => {
						handleSearch(`'"${e?.target?.value}"`, [
							'name',
							'email',
							'commonStatus',
							'superuser',
						]);
						localStorage.setItem(
							`searchFilter`,
							JSON.stringify({
								value: e?.target?.value,
							}),
						);
					}}
					search
					isDefaultValue={userSearch?.value ?? false}
					defaultValue={userSearch?.value ?? ''}
					onCloseAction={() => handleSearch('', [])}
				/>
				<DropdownBar
					options={[
						{
							name: 'All',
							ID: 'NONE',
						},
						{
							name: 'Active ',
							ID: 'ACTIVE',
						},
						{
							name: 'Inactive',
							ID: 'INACTIVE',
						},
						{
							name: 'Blocked',
							ID: 'BLOCK',
						},
						{
							name: (
								<FormControlLabel
									control={
										<Checkbox
											checked={checkSuperuser}
											onChange={event => {
												setCheckSuperuser(event.target.checked);
											}}
										/>
									}
									label="Super User Only"
								/>
							),
							ID: 'superUser',
							unclickable: true,
						},
					]}
					setOptionID={setFilterUserStatus}
					borderRadius="4px"
				/>
			</div>

			<ContentWrapper searchBarDropdown>
				<List className="core-list">
					{!loading &&
					!unblockLoading &&
					(getUsersByAccountAndSoftware === undefined ||
						getUsersByAccountAndSoftware?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add user to continue"
						/>
					) : (
						filteredList
							?.filter(e => {
								if (!checkSuperuser && filterUserStatus === 'NONE') {
									return e;
								} else if (checkSuperuser && filterUserStatus === 'NONE') {
									return e?.superUser === true;
								} else if (checkSuperuser && filterUserStatus !== 'NONE') {
									return e?.superUser && e?.commonStatus === filterUserStatus;
								} else {
									return e?.commonStatus === filterUserStatus;
								}
							})
							.map((el, index) => (
								<ListItem
									// selected={el.commonStatus === 'BLOCK'}
									disabled={el.commonStatus === 'INACTIVE'}
								>
									<ListItemText
										primary={
											<>
												<span className="xsTitle">{el.name}</span>
												<span className="xxTitle highlight-text text-separator">
													{el?.superUser && (
														<ListItemIcon>
															<img
																className="icon-svg"
																src={
																	el.commonStatus === 'INACTIVE'
																		? InactiveUser
																		: SuperUser
																}
																alt="drawer-icon"
																style={{ height: '65%', width: '65%' }}
															/>
														</ListItemIcon>
													)}
												</span>
											</>
										}
										secondary={
											<>
												<span className="desc flex-space">{el.email}</span>
												<span className="desc ">
													{el.commonStatus === 'BLOCK' ? (
														<ListItemIcon>
															<Typography
																variant="caption"
																style={{
																	fontSize: '10px',
																	color: 'red',
																}}
															>
																Blocked
															</Typography>
														</ListItemIcon>
													) : null}
												</span>
											</>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="more"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, el.ID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					{userMenuOption?.map(x => (
						<MenuOptions
							mounted={x?.mounted}
							name={x?.name}
							onClick={x?.onClick}
							disabled={x?.disabled}
						/>
					))}
				</Menu>

				<InactiveDialog
					openDialog={inactiveDialog}
					setOpenDialog={setInactiveDialog}
					personType="officer"
					name="Ken Yong"
				/>

				{/* {getOutlet?.length < user?.maxOutlet && ( */}
				<FloatButton
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum: PosPermission.GeneralSettingAccessSecurityUsersCreate,
					})}
					onClick={() =>
						history.push(
							`/menu/outlet-app/general-setting/user-access/user/add`,
						)
					}
				/>
				{/* )} */}
			</ContentWrapper>

			<CommonDialog
				fullWidth={true}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				sections={{
					header: {
						// title: mode,
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{mode}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => {
						return (
							<>
								<div className="content-container">
									<Controller
										as={TextField}
										name="password"
										label="New Password *"
										autoComplete="off"
										fullWidth
										ref={register}
										control={control}
										helperText={errors?.password?.message}
										error={errors?.password ? true : false}
										type={state.showNewPassword ? 'text' : 'password'}
										value={getValues('password')}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="Password"
														onClick={() =>
															dispatch({
																type: 'showNewPassword',
																payload: !state.showNewPassword,
															})
														}
													>
														{state.showNewPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>

									<Controller
										as={TextField}
										name="confirmPassword"
										label="Confirm Password *"
										autoComplete="off"
										fullWidth
										ref={register}
										control={control}
										helperText={errors?.confirmPassword?.message}
										error={errors?.confirmPassword ? true : false}
										type={state.showNewPassword ? 'text' : 'password'}
										value={getValues('password')}
										InputProps={{
											endAdornment: (
												<InputAdornment position="end">
													<IconButton
														aria-label="Password"
														onClick={() =>
															dispatch({
																type: 'showNewPassword',
																payload: !state.showNewPassword,
															})
														}
													>
														{state.showNewPassword ? (
															<Visibility />
														) : (
															<VisibilityOff />
														)}
													</IconButton>
												</InputAdornment>
											),
										}}
									/>
								</div>
							</>
						);
					},
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => setOpenDialog(false),
									variant: 'contained',
									color: 'primary',
									type: 'submit',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										handleSubmit(onSubmit)();
									},
									variant: 'contained',
									color: 'primary',
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
