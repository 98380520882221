import React from 'react';
import { MenuItemForm } from './MenuItemForm';
import { MenuItemList } from './MenuItemList';

const menuItemRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/menu-item`,
		},
		component: <MenuItemList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/menu-item/add`,
		},
		component: <MenuItemForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/menu-item/:menuItemID/edit`,
		},
		component: <MenuItemForm mode="edit" />,
	},
];

export default menuItemRoutes;
