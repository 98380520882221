import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Button, MenuItem, TextField } from '@material-ui/core';
import {
	useGetAssignedOutletByUserQuery,
	useGetKitchenPrinterQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import Background from '../../../src/assets/images/HR-Background.jpg';
import Logo from '../../../src/assets/images/MenuX-Logo.svg';
import { CommonYupValidation } from 'helpers/yup';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers';

interface IPrinterServerLoginForm {
	outletID: string;
}

export const PrinterServerLogin = () => {
	let history = useHistory();

	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {},
	});

	const yupSchema = yup.object().shape({
		outletID: CommonYupValidation.requireField('Outlet is required'),
	});

	const { control, register, watch, errors, handleSubmit } = useForm<
		IPrinterServerLoginForm
	>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const onSubmit = () => {
		history.push(`/printer-server/home/${watch('outletID')}`);
	};

	return (
		<>
			{getAssignedOutletByUserLoading && <Loading />}
			{/* {allMealPeriodLoading && <Loading />} */}
			<AuthLayout logo={Logo} image={Background} className="margin-override">
				<div className="form-box">
					<Controller
						as={
							<TextField select>
								{getAssignedOutletByUser?.map((el: any, index: number) => (
									<MenuItem key={index} value={el?.ID}>
										{el?.name}
									</MenuItem>
								))}
							</TextField>
						}
						label="Outlet"
						name="outletID"
						autoComplete="off"
						control={control}
						multiline={true}
						fullWidth
						margin="normal"
						required={true}
						ref={register}
						helperText={errors?.outletID?.message}
						error={errors?.outletID ? true : false}
					/>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						className="login-btn"
						onClick={() => {
							handleSubmit(onSubmit)();
						}}
						data-qa="loginButton"
					>
						Confirm
					</Button>
				</div>
			</AuthLayout>
		</>
	);
};
