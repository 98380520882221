import { yupResolver } from '@hookform/resolvers';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import {
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { AddCircle, MoreVert } from '@material-ui/icons';
import { useGetOptionQuery } from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import * as yup from 'yup';
import React from 'react';

interface OptionItemForm {
	optionID: string;
	menuItemOptionItem: [];
	maxSelections: number;
}

export const MenuItemOptionsForm = (props: any) => {
	const {
		outletID,
		setOptionID,
		setOptionItemList,
		setOpenDialog,
		setOpenEditDialog,
		openEditDialog,
		openDialog,
		optionItemList,
		selection,
		setSelection,
		menuWatch,
		menuRegister,
		menuSetValue,
	} = props;

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const { data: getOptionData } = useGetOptionQuery({
		variables: { outletID },
	});

	const yupSchema = yup.object().shape({
		// maxSelections: yup
		// 	.number()
		// 	// .min(0)
		// 	// .integer()
		// 	// .min(1, 'Max selections cannot be 0')
		// 	.max(
		// 		selection,
		// 		'Max selections cannot be more than the selected option items',
		// 	)
		// 	.required(),
	});

	const {
		register,
		control,
		handleSubmit,
		setValue,
		getValues,
		watch,
		reset,
		errors,
		setError,
		clearErrors,
	} = useForm<OptionItemForm>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const handleSelectMenu = (x: any) => {
		setOptionItemList(
			getOptionData?.getOption?.filter(y => y.ID === x)[0].optionItem,
		);
		register('optionID');
		setValue(`optionID`, x);
	};

	const handleSelectMenuEdit = () => {
		setOptionItemList(
			getOptionData?.getOption?.filter(
				(v, index) => v?.ID === menu?.obj?.optionID,
			)[0]?.optionItem,
		);
	};

	const handleCheckBoxChange = (e: any, index: number) => {
		register(`menuItemOptionItem[${index}].optionItemID`);
		if (!e.target.checked) {
			setValue(`menuItemOptionItem[${index}].optionItemID`, null);
			setSelection(selection - 1);
		} else {
			setValue(`menuItemOptionItem[${index}].optionItemID`, e.target.value);
			setSelection(selection + 1);
		}
	};

	const optionOnSubmit = (data: any) => {
		let optionItems = data.menuItemOptionItem.filter(v => {
			v.outletID = outletID;
			v.price = v.price
				? typeof v.price === 'string'
					? parseFloat(v.price ?? 0)
					: v.price ?? 0
				: 0;
			return v.optionItemID;
			// && v.optionItemName; idk dont think we needed this
			// price is not here because it can be free (0 === false)
		});
		data.outletID = outletID;
		data.maxSelections = data.maxSelections
			? typeof data.maxSelections === 'string'
				? parseFloat(data.maxSelections ?? 0)
				: data.maxSelections ?? 0
			: 0;
		data.menuItemOptionItem = optionItems;
		let currentData: any[] = menuWatch('option') ?? [];

		if (
			data?.maxSelections >
			data?.menuItemOptionItem?.filter(x => x?.optionItemID !== undefined)
				?.length
		) {
			setError('maxSelections', {
				message: 'Max selections cannot be more than the selected option items',
			});
		} else {
			menuRegister('option');
			menuSetValue('option', [...currentData, data]);
			clearErrors();
			resetValues();
			setOpenDialog(false);
		}
	};

	const optionOnEdit = (data: any) => {
		const currentOptions = menuWatch('option')?.filter(
			v => v.optionID !== menu?.obj?.optionID,
		);

		let optionsToAdd = [];

		let optionItems = data.menuItemOptionItem.filter(v => {
			v.outletID = outletID;
			v.price = v.price
				? typeof v.price === 'string'
					? parseFloat(v.price ?? 0)
					: v.price ?? 0
				: 0;
			return v.optionItemID;
			// && v.optionItemName; idk dont think we needed this
			// price is not here because it can be free (0 === false)
		});

		if (
			data?.maxSelections >
			data?.menuItemOptionItem?.filter(x => x?.optionItemID !== undefined)
				?.length
		) {
			setError('maxSelections', {
				message: 'Max selections cannot be more than the selected option items',
			});
		} else {
			if (optionItems?.length > 0) {
				optionsToAdd['maxSelections'] = data.maxSelections
					? typeof data.maxSelections === 'string'
						? parseFloat(data.maxSelections ?? 0)
						: data.maxSelections ?? 0
					: 0;
				optionsToAdd['optionID'] = menu?.obj?.optionID;
				optionsToAdd['outletID'] = menu?.obj?.outletID;
				optionsToAdd['menuItemOptionItem'] = optionItems;
				menuSetValue('option', [...[...currentOptions, optionsToAdd]]);
			} else {
				menuSetValue('option', [...currentOptions]);
			}
			clearErrors();
			resetValues();
			setOpenEditDialog(false);
		}
	};

	const resetValues = () => {
		setOptionItemList([]);
		setSelection(0);
	};

	return (
		<>
			<CardContents
				section={{
					header: {
						title: 'Selection Listing(s)',

						icon: (
							<AddCircle
								onClick={e => {
									setOpenDialog(true);
								}}
								style={{ color: '#ff9800' }}
							/>
						),
					},
				}}
			>
				{/** MAIN LIST FOR OPTIONS */}
				{menuWatch('option')?.length > 0 ? (
					<List style={{ width: '100%' }}>
						{menuWatch('option')?.map((el: any, index) => (
							<>
								<ListItem>
									<div style={{ paddingRight: '6px' }}></div>
									<ListItemText
										primary={
											<>
												<div className="xsTitle flex-space">
													{
														getOptionData?.getOption?.filter(
															(v: any) => v?.ID === el?.optionID,
														)[0]?.name
													}
												</div>
												<div className="mdDesc flex-space">{`Types: ${el?.menuItemOptionItem?.length}`}</div>
											</>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="more"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, el?.optionID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
								{menuWatch('option')?.length > 1 &&
								index !== menuWatch('option')?.length - 1 ? (
									<hr style={{ width: '95%' }}></hr>
								) : null}
							</>
						))}
					</List>
				) : null}
			</CardContents>

			<Menu
				id="menu-list"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem
					onClick={() => {
						setSelection(menu?.obj?.maxSelections);
						setOpenEditDialog(true);
						handleSelectMenuEdit();
						handleClose();
					}}
				>
					<span className="">Edit</span>
				</MenuItem>

				<MenuItem
					onClick={() => {
						let temp = menuWatch('option').filter(
							(v, index) => index !== menu.index,
						);
						menuSetValue('option', [...temp]);
						handleClose();
					}}
				>
					<span className="">Delete</span>
				</MenuItem>
			</Menu>

			{/** ADDING SELECTIONS */}
			{/* <Box mt={1}> */}
			<CommonDialog
				fullWidth={true}
				open={openDialog}
				onClose={() => {
					resetValues();
					setOpenDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Add Selection</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle infoline">
										{menu?.obj?.prefix}
										{menu?.obj?.tableNumber}
										{/* {selectedTable?.prefix}
													{selectedTable?.tableNumber} */}
									</div>
								</div>
							</div>
							// <Grid container>
							// 	<Grid item xs={12} className="left">
							// 		<div
							// 			className="title flex-space"
							// 			style={{ color: 'black' }}
							// 		>
							// 			Add Selection
							// 		</div>
							// 	</Grid>

							// 	<Divider
							// 		style={{
							// 			display: 'flex',
							// 			width: '100%',
							// 			backgroundColor: 'white',
							// 		}}
							// 	/>
							// </Grid>
						),
					},
					body: () => (
						<>
							<TextField
								name="optionID"
								label="Options"
								autoComplete="off"
								multiline={true}
								fullWidth
								select
								margin="normal"
								ref={register}
								onChange={e => handleSelectMenu(e?.target?.value)}
							>
								{getOptionData?.getOption
									?.filter(
										(v: any) =>
											!JSON.stringify(menuWatch('option'))?.includes(v.ID),
									)
									.map(x => (
										<MenuItem key={x.ID} value={x.ID}>
											{x.name}
										</MenuItem>
									))}
							</TextField>

							<Grid container direction="row">
								{optionItemList
									? [...optionItemList]
											?.sort((a, b) => {
												return a?.priority - b?.priority;
											})
											?.map((el, index) => {
												return (
													<>
														<Grid item xs={7} style={{ paddingTop: '8px' }}>
															<div>
																<Controller
																	as={
																		<FormControlLabel
																			className="desc"
																			control={
																				<Checkbox
																					name={`menuItemOptionItem[${index}].optionItemID`}
																					value={el.ID}
																					color="primary"
																					style={{ height: '1vh' }}
																					onChange={e => {
																						handleCheckBoxChange(e, index);
																					}}
																					ref={register}
																				/>
																			}
																			label={el.name}
																		/>
																	}
																	name={`menuItemOptionItem[${index}].optionItemID`}
																	ref={register()}
																	control={control}
																/>
															</div>
														</Grid>

														<Grid item xs={5}>
															<Controller
																as={
																	<NumberFormat
																		style={{ justifyContent: 'right' }}
																		customInput={TextField}
																		fullWidth
																		autoComplete="off"
																		isNumericString
																		decimalScale={2}
																		fixedDecimalScale
																		defaultValue={0}
																		variant="outlined"
																		margin="dense"
																		ref={register}
																		className="custom-add-menu-item-option"
																		onChange={e => {
																			register(
																				`menuItemOptionItem[${index}].price`,
																			);
																			setValue(
																				`menuItemOptionItem[${index}].price`,
																				e.target.value,
																			);
																		}}
																	/>
																}
																name={`menuItemOptionItem[${index}].price`}
																control={control}
																ref={register}
																className="desc custom-add-menu-item-option"
															/>
														</Grid>
													</>
												);
											})
									: null}
								<Grid item xs={12}>
									<Controller
										as={
											<NumberFormat
												allowNegative={false}
												customInput={TextField}
												autoComplete="off"
												multiline={true}
												fullWidth
												style={{ margin: '0px' }}
												disabled={
													!watch('optionID') &&
													!(optionItemList?.length > 0 === true)
														? true
														: !watch('optionID')
														? true
														: false
												}
												inputProps={{
													inputmode: 'numeric',
													pattern: '[0-9]*',
												}}
												helperText={errors?.maxSelections?.message}
												error={errors?.maxSelections ? true : false}
											/>
										}
										name="maxSelections"
										label="No. of Selection"
										control={control}
										ref={register}
									/>
								</Grid>
							</Grid>
							{/* <div style={{ fontSize: '12px' }}>Choose at least 1 option</div> */}
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										resetValues();
										setOpenDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										handleSubmit(optionOnSubmit)();
									},
									variant: 'contained',
									color: 'primary',
									form: 'submit-form',
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
			{/* </Box> */}

			{/** EDIT OPTION'S OPTIONS */}
			<CommonDialog
				fullWidth={true}
				open={openEditDialog}
				onClose={() => {
					resetValues();
					setOpenEditDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">
											{`Edit ${
												getOptionData?.getOption?.filter(
													v => v?.ID === menu?.obj?.optionID,
												)[0]?.name
											} Option`}
										</div>
									</div>
								</div>
							</div>
							// <Grid container>
							// 	<Grid item xs={12} className="left">
							// 		<div className="title flex-space" style={{ color: 'black' }}>
							// 			{`Edit ${
							// 				getOptionData?.getOption?.filter(
							// 					v => v?.ID === menu?.obj?.optionID,
							// 				)[0]?.name
							// 			} Option`}
							// 		</div>
							// 	</Grid>

							// 	<Divider
							// 		style={{
							// 			display: 'flex',
							// 			width: '100%',
							// 			backgroundColor: 'white',
							// 		}}
							// 	/>
							// </Grid>
						),
					},
					body: () => (
						<>
							<Grid container direction="row">
								{menuWatch('option')?.length > 0 &&
									// getOptionData?.getOption?.filter(
									// 	(v, index) => v?.ID === menu?.obj?.optionID,
									// )[0]?.optionItem,
									[...optionItemList]
										?.sort((a, b) => {
											return a?.priority - b?.priority;
										})
										.map((x, index) => {
											let currData = menuWatch('option')
												?.filter(v => v.optionID === menu?.obj?.optionID)[0]
												?.menuItemOptionItem?.filter(
													v => x.ID === v.optionItemID,
												);

											return (
												<>
													<Grid item xs={7} style={{ paddingTop: '8px' }}>
														<div>
															<Controller
																as={
																	<FormControlLabel
																		className="desc"
																		control={
																			<Checkbox
																				name={`menuItemOptionItem[${index}].optionItemID`}
																				value={x.ID}
																				defaultChecked={currData?.length > 0}
																				defaultValue={
																					currData
																						? currData[0]?.optionItemID
																						: null
																				}
																				color="primary"
																				style={{ height: '1vh' }}
																				onChange={e =>
																					handleCheckBoxChange(e, index)
																				}
																				ref={register}
																			/>
																		}
																		label={x.name}
																	/>
																}
																name={`menuItemOptionItem[${index}].optionItemID`}
																ref={register()}
																defaultValue={
																	currData ? currData[0]?.optionItemID : null
																}
																control={control}
															/>
														</div>
													</Grid>

													<Grid item xs={5}>
														<Controller
															as={
																<NumberFormat
																	customInput={TextField}
																	fullWidth
																	autoComplete="off"
																	isNumericString
																	decimalScale={2}
																	fixedDecimalScale
																	variant="outlined"
																	margin="dense"
																	ref={register}
																	className="custom-add"
																	onChange={e => {
																		register(
																			`menuItemOptionItem[${index}].price`,
																		);
																		setValue(
																			`menuItemOptionItem[${index}].price`,
																			e.target.value,
																		);
																	}}
																	defaultValue={
																		currData ? currData[0]?.price : null
																	}
																/>
															}
															name={`menuItemOptionItem[${index}].price`}
															control={control}
															ref={register}
															className="desc custom-add-menu-item-option"
															defaultValue={
																currData ? currData[0]?.price : null
															}
														/>
													</Grid>
												</>
											);
										})}
								<Grid item xs={12}>
									<Controller
										as={
											<NumberFormat
												allowNegative={false}
												customInput={TextField}
												autoComplete="off"
												multiline={true}
												fullWidth
												style={{ margin: '0px' }}
												// value={selection}
												defaultValue={
													menuWatch('option')?.filter(
														v => v.optionID === menu?.obj?.optionID,
													)[0]?.maxSelections
												}
												inputProps={{
													inputmode: 'numeric',
													pattern: '[0-9]*',
												}}
												helperText={errors?.maxSelections?.message}
												error={errors?.maxSelections ? true : false}
											/>
										}
										name="maxSelections"
										label="No. of Selection"
										control={control}
										ref={register}
									/>
								</Grid>
							</Grid>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenEditDialog(false);
										resetValues();
									},

									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										handleSubmit(optionOnEdit)();
									},
									variant: 'contained',
									color: 'primary',
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
