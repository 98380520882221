import { AppBar } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';
import React from 'react';

interface IHeader {
	onDrawerOpen: any;
}

export const Header = (props: IHeader) => {
	const { onDrawerOpen, ...rest } = props;
	// const { onDrawerOpen } = props;
	return (
		<AppBar
			{...rest}
			position="fixed"
			className="desktop-header"
			style={{ backgroundColor: '#ff9800' }}
		>
			<Toolbar>
				<IconButton
					color="inherit"
					aria-label="open drawer"
					edge="start"
					onClick={onDrawerOpen}
				>
					<MenuIcon />
				</IconButton>
				<span className="title">MenuX</span>
			</Toolbar>
		</AppBar>
	);
};
export default Header;
