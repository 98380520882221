import { Button } from '@material-ui/core'
import React from 'react'
interface IFooterProps {
  options?: any
  chipLabel?: string
  value?: string | number
  single?: Boolean
  isHidden?: boolean
  totalAmt?: boolean
  extraNote?: string
  overlap?: boolean
  style?: React.CSSProperties
}
export const Footer = (props: IFooterProps) => {
  const {
    chipLabel,
    options,
    value,
    isHidden,
    totalAmt,
    extraNote,
    overlap,
    style,
  } = props
  let single: boolean = false
  let multiple: boolean = true

  if (options?.length > 2) {
    multiple = true
  }

  if (options?.length === 1) {
    single = true
  }

  return (
    <div
      className="footer"
      id="footer"
      style={{ zIndex: overlap ? 60 : null, ...style }}
    >
      {chipLabel && (
        <div className="footer-label">
          <div className="chip-label">{chipLabel}</div>

          <span className="label-value">{value}</span>
        </div>
      )}
      {totalAmt && (
        <div
          className="footer-label"
          style={{
            background: '#faf2e8',
          }}
        >
          <div className="xsTitle">
            Total: <span className="color-primary-orange">{value}</span>
          </div>
        </div>
      )}
      {extraNote && (
        <div
          className="footer-label"
          style={{
            background: '#faf2e8',
          }}
        >
          <div className="desc text-noflow" style={{ textAlign: 'center' }}>
            <span style={{ color: 'red' }}>{extraNote}</span>
          </div>
        </div>
      )}
      {isHidden ? null : (
        <div className="footer-btn">
          {options.map((el, index) => (
            <Button
              startIcon={el?.startIcon}
              key={index}
              type="submit"
              color={el.color}
              style={{
                padding: '5px',
                minWidth:
                  options.length > 2 ? (multiple ? '85px' : '50px') : '126px',
              }}
              // style={{ minWidth: options.length > 2 ? '50px' : '126px' }}
              variant="contained"
              className={single ? 'single' : multiple ? 'multiple' : ''}
              onClick={el.onClick}
              disabled={el.disabled}
              {...el.props}
            >
              {el.name}
            </Button>
          ))}
        </div>
      )}
    </div>
  )
}
