import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { FooterWithTotal } from '@ifca-root/react-component/src/components/Footer/FooterWithTotal';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { useMultiPurposeFileUpload } from '@ifca-root/react-component/src/utils/hooks/useMultiPurposeFileUpload';
import {
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { NoImage } from 'assets';
import PickupIcon from 'assets/icons/pickup_grey.svg';
import BigNumber from 'bignumber.js';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { ColumnSortOrder } from 'devexpress-reporting/scopes/reporting-viewer-internal';
import {
	GetBillDiscountOrderDocument,
	GetOrderDocument,
	GetOrderItemDocument,
	GetOrderSummaryDocument,
	OrderItemStatus,
	OrderStatus,
	useConfirmOrderItemMutation,
	useGetCurrentMealPeriodLazyQuery,
	useGetOrderQuery,
	useGetOrderSummaryQuery,
	useGetOutletNameQuery,
	useGetUomQuery,
	usePostPrintDataMutation,
	useUpdateOrderItemWithOptionsMutation,
	useUpdateOrderItemWithSetMenuOptionsMutation,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import * as htmlToImage from 'html-to-image';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import {
	printReceipt,
	printReceiptText,
} from '../BillSettlement/BillReceipt/BillReceiptFunctions';
import {
	MenuDialogSummary,
	SetMenuDialogSummary,
} from './MenuDialog/OrderSummaryMenuDialog';
import './order.scss';
import { OrderItemReceipt } from './OrderReceipt/OrderItemReceipt';
import { Overlay } from '@ifca-root/react-component/src/components/Overlay/Overlay';

interface OptionItem {
	remark: string;
	name: string;
	openItem: String;
	openPrice: number;
	specialRequest: String;
	option: any[];
	qty: number;
}

export const OrderSummary = (props: any) => {
	useEffect(() => {
		console.log('OrderSummary Outlet App');
	}, []);
	const history = useHistory();
	const { mode } = props;
	const { tableID, outletID, orderID, menuCategoryID } = useParams<
		Record<string, any>
	>();

	const searchParameter = JSON.parse(localStorage.getItem('menuItemSearch'));

	const { globalState }: any = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { anchorEl, handleClick, handleClose } = useMenuOption();
	const [selectedMenu, setSelectedMenu] = useState(null);
	const [quantity, setQuantity] = useState<number>();
	const [editDialog, setEditDialog] = useState(false);
	const [editSetMenuDialog, setEditSetMenuDialog] = useState(false);
	const [takeAwayStatus, setTakeAwayStatus] = useState(false);
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	const [text, setText] = useState();
	const [record, setRecord] = useState(false);
	const [audioData, setAudioData] = useState(null);
	const [audioInput, setAudioInput] = useState('');
	const [isSubmitting, setIsSubmitting] = useState(false);

	const { files, handleSingleRemark } = useMultiPurposeFileUpload();

	useEffect(() => {
		if (selectedMenu?.specialRequest) {
			setValue('specialRequest', selectedMenu?.specialRequest);
		}
	}, [selectedMenu]);

	const yupSchema = yup.object().shape({
		openItem: selectedMenu?.isOpenItem
			? yup
					.string()
					.required('Open item name is required')
					.trim()
			: null,
		openPrice: selectedMenu?.isOpenItem
			? yup.number().required('Open item price is required')
			: null,
	});

	const {
		handleSubmit,
		control,
		register,
		watch,
		setValue,
		unregister,
		reset,
		clearErrors,
		errors,
	} = useForm<OptionItem>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	//default values for individual menu item
	const crossCheckOptionItem = ID => {
		const defaultOrderItemOptionItems = selectedMenu?.orderItemOption
			?.map((orderItemOption, optionIndex) =>
				orderItemOption?.orderItemOptionItem?.map(
					optionItem => optionItem?.optionItemID,
				),
			)
			.flat();
		return defaultOrderItemOptionItems?.includes(ID);
	};

	useEffect(() => {
		if (editDialog) {
			selectedMenu?.menuItem?.menuItemOptions?.map(
				(menuItemOption, optionIndex) => {
					let maxSelections = menuItemOption?.maxSelections;
					menuItemOption?.menuItemOptionItem?.map(
						(optionItem, optionItemIndex) => {
							if (crossCheckOptionItem(optionItem?.optionItemID)) {
								if (maxSelections === 1) {
									register(
										`option[${optionIndex}].${menuItemOption?.optionID}[${0}]`,
									);
									setValue(
										`option[${optionIndex}].${menuItemOption?.optionID}[${0}]`,
										JSON.stringify({
											itemID: optionItem?.optionItemID,
											price: optionItem?.price,
										}),
									);
								} else {
									register(
										`option[${optionIndex}].${menuItemOption?.optionID}[${optionItem?.optionItemID}]`,
									);
									setValue(
										`option[${optionIndex}].${menuItemOption?.optionID}[${optionItem?.optionItemID}]`,
										optionItem?.price,
									);
								}
							}
						},
					);
				},
			);
		} else {
			unregister(`option`);
			reset();
		}
	}, [editDialog]);

	//default values for set menu item
	const crossCheckSetMenuOptionItem = ID => {
		const defaultOrderItemSetMenuOptionItems = selectedMenu?.orderItemSetMenuOption
			?.map((orderItemSetMenuOption, orderItemSetMenuOptionIndex) =>
				orderItemSetMenuOption?.orderItemSetMenuOptionItem?.map(
					orderItemSetMenuOptionItem =>
						orderItemSetMenuOptionItem?.setMenuOptionItemID,
				),
			)
			.flat();
		return defaultOrderItemSetMenuOptionItems?.includes(ID);
	};

	useEffect(() => {
		if (editSetMenuDialog) {
			selectedMenu?.menuItem?.setMenuOption?.map(
				(setMenuOption, setMenuOptionIndex) => {
					let maxSelections = setMenuOption?.maxSelections;
					setMenuOption?.setMenuOptionItem?.map(setMenuOptionItem => {
						if (crossCheckSetMenuOptionItem(setMenuOptionItem?.ID)) {
							if (maxSelections === 0) {
								register(
									`option[0].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
								);
								setValue(
									`option[0].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
									{
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									},
								);
							} else if (maxSelections === 1) {
								register(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${0}]`,
								);
								setValue(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${0}]`,
									JSON.stringify({
										itemID: setMenuOptionItem?.ID,
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									}),
								);
							} else if (maxSelections > 1) {
								register(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
								);
								setValue(
									`option[${setMenuOptionIndex}].${setMenuOption?.ID}[${setMenuOptionItem?.ID}]`,
									{
										quantity: setMenuOptionItem?.quantity || 1,
										menuItemID: setMenuOptionItem?.menuItem?.ID,
									},
								);
							}
						}
					});
				},
			);
		} else {
			unregister(`option`);
			reset();
		}
	}, [editSetMenuDialog]);

	const changeOrderQuantity = mode => {
		if (mode === 'add') {
			if (
				quantity >=
				selectedMenu?.menuItem?.dailyAvailability?.outstandingQty +
					selectedMenu?.quantity
			) {
				setQuantity(quantity);
				setValue('qty', quantity);
			} else {
				setQuantity(quantity + 1);
				setValue('qty', quantity + 1);
			}
		} else {
			if (quantity === 0) {
				setQuantity(0);
				setValue('qty', quantity);
			} else {
				setQuantity(quantity - 1);
				setValue('qty', quantity - 1);
			}
		}
	};

	const {
		data: { getOrderItem } = { getOrderItem: [] },
		loading: orderItemLoading,
	} = useGetOrderSummaryQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: orderID,
			status: OrderItemStatus.Pending,
		},
		onCompleted: () => {
			generateReceiptData();
		},
	});
	const [optionErr, setOptionErr] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		updateOrderItemWithOptions,
		{ loading: orderItemWithOptionsLoading },
	] = useUpdateOrderItemWithOptionsMutation({
		onError: error => {
			console.log('Suberror', error);
			setOptionErr(true);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			if (error.message.includes('This table')) {
				history.push(`/menu/outlet-app/${outletID}/table/grid`);
			} else {
				history.push(`/menu/outlet-app/${outletID}/table/grid/${tableID}`);
			}
		},
		onCompleted: ({ updateOrderItemWithOptions }) => {
			setOptionErr(false);
			setEditDialog(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setIsSubmitting(false);
		},
	});

	const [
		updateOrderItemWithSetMenuOptions,
		{ loading: orderItemWithSetMenuOptionsLoading },
	] = useUpdateOrderItemWithSetMenuOptionsMutation({
		onError: error => {
			console.log('Suberror', error);
			setOptionErr(true);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			if (error.message.includes('This table')) {
				history.push(`/menu/outlet-app/${outletID}/table/grid`);
			} else {
				history.push(`/menu/outlet-app/${outletID}/table/grid/${tableID}`);
			}
		},
		onCompleted: ({ updateOrderItemWithSetMenuOptions }) => {
			setEditSetMenuDialog(false);
			setOptionErr(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setIsSubmitting(false);
		},
	});

	const [
		confirmOrderItem,
		{ loading: confirmOrderLoading },
	] = useConfirmOrderItemMutation({
		onError: error => {
			console.log('Suberror', error);
			setOptionErr(true);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			if (error.message.includes('This table')) {
				history.push(`/menu/outlet-app/${outletID}/table/grid`);
			} else {
				history.push(`/menu/outlet-app/${outletID}/table/grid/${tableID}`);
			}
		},
		onCompleted: data => {
			if (mode === 'dineIn') {
				history.push(
					`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/menu-category/${menuCategoryID}/confirm-order`,
				);
			} else if (mode === 'takeAway') {
				history.push(
					`/menu/outlet-app/${outletID}/order/${orderID}/takeaway/confirm-order`,
				);
			}
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.confirmRecord());
			setIsSubmitting(false);
		},
	});

	const [
		postPrintData,
		{ loading: getpostPrintLoading },
	] = usePostPrintDataMutation({
		fetchPolicy: 'network-only',
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	//GETUOM
	//GETUOM
	//GETUOM

	const {
		data: { getUOM } = { getUOM: [] },
		loading: uomLoading,
	} = useGetUomQuery({
		fetchPolicy: 'network-only',
		// variables: {
		// 	outletID: outletID,
		// },
	});

	//DYNAMIC SUB HEADER
	//DYNAMIC SUB HEADER
	//DYNAMIC SUB HEADER

	const {
		data: { getOrder } = { getOrder: [] },
		loading: orderLoading,
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
			status: OrderStatus.Open,
			outletID,
		},
	});

	const [selectedOrder, setSelectedOrder] = useState(null);

	useEffect(() => {
		setSelectedOrder(getOrder?.filter(order => order?.ID === orderID)[0]);
	}, [getOrder, orderID]);

	const orderStatus = (data: any) => {
		confirmOrderItem({
			variables: {
				orderItemIDs: getOrderItem?.map(e => {
					return e?.ID;
				}),
				orderID: orderID,
			},
			refetchQueries: [
				{
					query: GetOrderSummaryDocument,
					variables: {
						orderID: orderID,
						status: OrderItemStatus.Confirmed,
						outletID,
					},
				},
				{
					query: GetOrderItemDocument,
					variables: { orderID, status: OrderItemStatus.Confirmed, outletID },
				},
				{
					query: GetBillDiscountOrderDocument,
					variables: {
						...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});
	};
	const handleChangeOrder = (e, obj) => {
		if (obj?.ID !== orderID) {
			history.push({
				pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${obj?.ID}/menu-category/${menuCategoryID}/summary`,
			});
		}
	};

	const [weightName, setWeightName] = useState(null);
	let totalAmountFooter = amtStr(selectedOrder?.outstandingAmount);

	useEffect(() => {
		setWeightName(
			getUOM?.filter(id => id?.ID === selectedMenu?.menuItem?.uomID)[0],
		);
	}, [selectedMenu]);
	let amount = 0;
	const updateItem = (data: any) => {
		if (data?.getWeight) {
			amount = parseFloat(data?.getWeight) * selectedMenu?.unitPrice;
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice);
		} else {
			amount = selectedMenu?.unitPrice * quantity;
		}

		updateOrderItemWithOptions({
			variables: {
				orderItemInput: {
					ID: selectedMenu?.ID,
					outletID: outletID,
					amount: amount,
					baseAmount: amount,
					quantity: data?.getWeight ? 1 : quantity,
					unitPrice: parseFloat(data?.openPrice) || selectedMenu?.unitPrice,
					menuItemID: selectedMenu?.menuItemID,
					orderID: orderID,
					mealPeriodID: currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: takeAwayStatus,
					openItemName: data?.openItem || selectedMenu?.openItemName,
					weight: parseFloat(data?.getWeight) || selectedMenu?.weight,
				},
				orderItemOptionInput: data?.option?.map(orderItemOption => {
					let optionID = Object.keys(orderItemOption)[0];
					return {
						outletID,
						optionID: optionID,
						orderItemOptionItems:
							typeof orderItemOption[optionID][0] === 'string'
								? [
										{
											outletID,
											optionItemID: JSON.parse(orderItemOption[optionID][0])
												?.itemID,
											price:
												JSON.parse(orderItemOption[optionID][0])?.price *
												quantity,
											basePrice:
												JSON.parse(orderItemOption[optionID][0])?.price *
												quantity,
										},
								  ]
								: Object.keys(orderItemOption[optionID])
										?.filter(item => item !== null)
										?.map(optionItem => {
											return {
												outletID,
												optionItemID: optionItem,
												price: orderItemOption[optionID][optionItem] * quantity,
												basePrice:
													orderItemOption[optionID][optionItem] * quantity,
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetOrderSummaryDocument,
					variables: {
						orderID: orderID,
						status: OrderItemStatus.Pending,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});
		setQuantity(selectedMenu?.quantity);
		setTakeAwayStatus(false);
	};

	const updateSetMenuItem = (data: any) => {
		if (data?.getWeight) {
			amount = parseFloat(data?.getWeight) * selectedMenu?.unitPrice;
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice);
		} else {
			amount = selectedMenu?.unitPrice * quantity;
		}

		updateOrderItemWithSetMenuOptions({
			variables: {
				orderItemInput: {
					ID: selectedMenu?.ID,
					outletID: outletID,
					amount: amount,
					baseAmount: amount,
					quantity: data?.getWeight ? 1 : quantity,
					unitPrice: parseFloat(data?.openPrice) || selectedMenu?.unitPrice,
					menuItemID: selectedMenu?.menuItemID,
					orderID: orderID,
					mealPeriodID: currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: takeAwayStatus,
					openItemName: data?.openItem || selectedMenu?.openItemName,
					weight: parseFloat(data?.getWeight) || selectedMenu?.weight,
				},
				orderItemSetMenuOptionInput: data?.option?.map(setMenuOption => {
					let setMenuOptionID = Object.keys(setMenuOption)[0];
					return {
						outletID,
						setMenuOptionID: setMenuOptionID,
						orderItemSetMenuOptionItemInput:
							typeof setMenuOption[setMenuOptionID][0] === 'string'
								? [
										{
											outletID,
											menuItemID: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.menuItemID,
											setMenuOptionItemID: JSON.parse(
												setMenuOption[setMenuOptionID][0],
											)?.itemID,
											quantity: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.quantity,
										},
								  ]
								: Object.keys(setMenuOption[setMenuOptionID])
										?.filter(setMenuOptionItemID => setMenuOptionItemID != null)
										?.map(setMenuOptionItemID => {
											return {
												outletID,
												setMenuOptionItemID: setMenuOptionItemID,
												menuItemID:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														.menuItemID,
												quantity:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														?.quantity,
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetOrderSummaryDocument,
					variables: {
						orderID: orderID,
						status: OrderItemStatus.Pending,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});
		setQuantity(selectedMenu?.quantity);
		setTakeAwayStatus(false);
	};

	const checkItem = item => {
		if (mode === 'takeAway') {
			setTakeAwayStatus(true);
		}
		if (item?.menuItem?.isSetMenu && item?.status === 'PENDING') {
			setEditSetMenuDialog(true);
		} else if (!item?.menuItem?.isSetMenu && item?.status === 'PENDING') {
			setEditDialog(true);
		}
		setAudioInput(item?.specialRequest);
		setValue('specialRequest', item?.specialRequest);
	};

	const checkMenuOptionError = () => {
		const boolArr = selectedMenu?.menuItem?.menuItemOptions?.map(
			menuItemOption => {
				const maxSelections = menuItemOption?.maxSelections;
				const currSelectedOption = watch(`option`).filter(
					selectedOption =>
						Object.keys(selectedOption)[0] === menuItemOption?.optionID,
				)[0];

				if (
					(maxSelections !== 1 && currSelectedOption === undefined) ||
					(maxSelections === 1 &&
						currSelectedOption[menuItemOption?.optionID][0] === undefined)
				) {
					return false;
				} else if (
					maxSelections === 1 &&
					typeof currSelectedOption[menuItemOption?.optionID][0] === 'string'
				) {
					return true;
				} else if (maxSelections === 0) {
					return true;
				} else if (
					maxSelections > 1 &&
					Object.keys(currSelectedOption[menuItemOption?.optionID]).length <
						maxSelections
				) {
					return true;
				} else {
					return false;
				}
			},
		);

		return boolArr;
	};

	//Kitchen Printer stuff
	//Kitchen Printer stuff
	//Kitchen Printer stuff

	const [uniqueKpArray, setUniqueKpArray] = useState([]);
	const [kpObj, setKpObject] = useState({});
	const [printerIndex, setPrinterIndex] = useState(null);
	const [dataURL, setDataURL] = useState(null);
	const [printerName, setPrinterName] = useState(null);
	const [kitchenPrinterObj, setKitchenPrinterObj] = useState([]);
	// Rerender receipt data after update
	useEffect(() => {
		if (!orderLoading && getOrderItem?.length > 0) {
			generateReceiptData();
		}
	}, [getOrderItem]);

	const generateReceiptData = () => {
		let uniqueprinterName = new Set(
			getOrderItem?.filter(
				oi => oi?.menuItem?.kitchenPrinterID !== (null && undefined),
			),
		);
		setUniqueKpArray([...uniqueprinterName!]);

		let temporaryObject = {};

		getOrderItem?.map(oi => {
			if (!(oi?.menuItem?.kitchenPrinter?.name in temporaryObject)) {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name] = [oi];
			} else {
				temporaryObject[oi?.menuItem?.kitchenPrinter?.name].push(oi);
			}
		});
		setKpObject(temporaryObject);
	};

	const handlePrinting = async () => {
		if (uniqueKpArray?.length > 0) {
			await uniqueKpArray?.map(async (el, index) => {
				console.log('apa', el?.menuItem?.kitchenPrinter?.name);
				let receiptElement = document?.getElementById(
					el?.menuItem?.kitchenPrinter?.name,
				);

				setKitchenPrinterObj(kpObj[el?.menuItem?.kitchenPrinter?.name]);

				console.log('receiptElement', receiptElement);

				await htmlToImage
					.toPng(receiptElement, { style: { background: '#ffffff' } })
					.then(async x => {
						console.log('print', x);
						setDataURL(x);
						setPrinterIndex(index);
						setPrinterName(el?.menuItem?.kitchenPrinter?.name);
					});
				setPrinterName(el?.menuItem?.kitchenPrinter?.name);
			});
		} else {
			!isSubmitting && handleSubmit(orderStatus)();
		}
		return true;
	};

	useEffect(() => {
		const orderItemIDs = getOrderItem
			?.filter(oi => oi?.menuItem?.kitchenPrinterID !== (null && undefined))
			?.map(x => x?.ID);
		if (kitchenPrinterObj.length > 0) {
			handleSubmit(orderStatus)();

			console?.log(dataURL, 'dataURL');

			postPrintData({
				variables: {
					printData: {
						dataURL: JSON.stringify(dataURL),
						outletID,
						printerName: printerName,
						outletName: getOutlet[0]?.name,
						orderID: selectedOrder?.ID,
						orderItemIDs: orderItemIDs,
						orderMode: 'confirm',
					},
				},
			});
		}
	}, [uniqueKpArray?.length, printerName]);

	const getRoundedQty = qty1 => {
		let outstandingQuantity =
			selectedMenu?.menuItem?.dailyAvailability?.outstandingQty +
			selectedMenu?.quantity;
		let roundedQty =
			qty1 > outstandingQuantity ? outstandingQuantity : qty1 < 1 ? 0 : qty1;
		return roundedQty;
	};

	return (
		<>
			{orderItemLoading && <Loading />}
			{outletLoading && <Loading />}
			{uomLoading && <Loading />}
			{orderLoading && <Loading />}
			{orderItemWithOptionsLoading && <Loading />}
			{orderItemWithSetMenuOptionsLoading && <Loading />}
			{confirmOrderLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			<MainHeader
				onClick={() => {
					if (mode === 'dineIn') {
						history.push({
							pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/menu-category/${menuCategoryID}`,
						});
					} else if (mode === 'takeAway') {
						history.push({
							pathname: `/menu/outlet-app/${outletID}/order/${orderID}/menu-category/${menuCategoryID}/takeaway`,
						});
					}
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Order Summary', current: true },
				]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${currentMealPeriod?.name} ${formatTime(
									currentMealPeriod?.startTime,
							  )} - ${formatTime(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>
			<OrderSubheader
				mode={mode}
				getOrderLoading={orderLoading}
				getOrder={getOrder}
				selectedOrder={selectedOrder}
				orderID={selectedOrder?.ID}
				handleChangeOrder={handleChangeOrder}
				outletID={outletID}
			/>
			<ContentWrapper
				multiLineSubheader={mode === 'takeAway' ? true : false}
				footer
				float
				style={{ marginTop: isDesktop ? '125px' : '55px' }}
			>
				<List className="core-list" style={{ zIndex: 40 }}>
					{!orderItemLoading &&
						!outletLoading &&
						!uomLoading &&
						!orderLoading &&
						!orderItemWithOptionsLoading &&
						!getCurrentMealPeriodLoading &&
						!orderItemWithSetMenuOptionsLoading &&
						(getOrderItem === undefined || getOrderItem?.length === 0 ? (
							<EmptyList
								title="There are no orders found"
								subtitle="Please make a new order in the Menu "
							/>
						) : (
							getOrderItem?.map((v, i) => (
								<ListItem
									onClick={() => {
										// v?.status === 'PENDING'
										// 	? setEditDialog(true)
										// 	: setEditDialog(false);
										// setSelectedMenu({ ...v });
										// setQuantity(v?.quantity);
										// setTakeAwayStatus(v?.isTakeaway);
									}}
									style={{ padding: '0px 10px 7px' }}
								>
									{/* <div style={{ paddingRight: '6px' }}>
										<img
											src={v?.menuItem?.attachments?.fileURL}
											className="img-owned"
											loading="lazy"
											// alt={v.image}
										/>
									</div> */}
									<ListItemIcon>
										{v?.menuItem?.attachments?.fileURL ? (
											<img
												className="img-owned"
												src={v?.menuItem?.attachments?.fileURL}
											/>
										) : (
											<img
												className="img-owned"
												style={{ border: 'none' }}
												src={NoImage}
											/>
										)}
										{v?.isTakeaway && (
											<img className="img-takeaway-logo" src={PickupIcon} />
										)}
									</ListItemIcon>
									<ListItemText
										primary={
											<>
												<span className="xsTitle flex-space">
													{v?.menuItem?.name} x{v.quantity}
												</span>
												<span className="xsTitle highlight-text">
													{`${getOutlet[0]?.currencyCode ?? ''}${amtStr(
														new BigNumber(v?.totalInfo?.totalBaseAmount),
													)}`}
												</span>
											</>
										}
										secondary={
											<>
												<span className="flex-space desc">
													<div>{v?.menuItem?.description}</div>
													<div>
														{// v?.orderItemOption[0]?.orderItemOptionItem[0]
														// 	?.optionItem?.name
														v?.orderItemOption?.map(x =>
															x?.orderItemOptionItem?.map(x => (
																<div>{x?.optionItem?.name}</div>
															)),
														)}
													</div>
													<div>{v.specialRequest}</div>
												</span>

												<EditIcon
													style={
														v?.status === 'PENDING'
															? {
																	fontSize: '23px',
																	color: 'white',
																	marginTop: '15px',
																	backgroundColor: '#ff9800',
																	borderRadius: '3px',
															  }
															: {
																	fontSize: '23px',
																	color: 'white',
																	marginTop: '15px',
																	backgroundColor: 'grey',
																	borderRadius: '3px',
															  }
													}
													onClick={() => {
														// v?.status === 'PENDING'
														// 	? setEditDialog(true)
														// 	: setEditDialog(false);
														checkItem(v);
														setSelectedMenu({ ...v });
														setQuantity(v?.quantity);
														setTakeAwayStatus(v?.isTakeaway);
													}}
												/>
											</>
										}
									/>
									<div></div>
								</ListItem>
							))
						))}
				</List>
				<Overlay />

				{uniqueKpArray?.map((x, i) => {
					return (
						<OrderItemReceipt
							outletName={getOutlet[0]?.name}
							data={selectedOrder}
							orderItemData={kpObj[x?.menuItem?.kitchenPrinter?.name]}
							printerName={x?.menuItem?.kitchenPrinter?.name}
							hidden
						/>
					);
				})}
			</ContentWrapper>
			<div style={{ paddingTop: '60px' }}></div>
			<MenuDialogSummary
				checkMenuOptionError={checkMenuOptionError}
				changeOrderQuantity={changeOrderQuantity}
				control={control}
				editDialog={editDialog}
				handleSubmit={handleSubmit}
				optionErr={optionErr}
				quantity={quantity}
				register={register}
				selectedMenu={selectedMenu}
				selectedOrder={selectedOrder}
				setEditDialog={setEditDialog}
				setOptionErr={setOptionErr}
				setQuantity={setQuantity}
				setTakeAwayStatus={setTakeAwayStatus}
				setValue={setValue}
				updateItem={updateItem}
				unregister={unregister}
				watch={watch}
				weightName={weightName}
				audioInput={audioInput}
				setAudioInput={setAudioInput}
				record={record}
				setRecord={setRecord}
				setText={setText}
				handleSingleRemark={handleSingleRemark}
				clearErrors={clearErrors}
				errors={errors}
				mode={mode}
				isSubmitting={isSubmitting}
				setIsSubmitting={setIsSubmitting}
				isDesktop={isDesktop}
				getRoundedQty={getRoundedQty}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				orderItemWithOptionsLoading={orderItemWithOptionsLoading}
			/>
			<SetMenuDialogSummary
				changeOrderQuantity={changeOrderQuantity}
				control={control}
				editSetMenuDialog={editSetMenuDialog}
				handleSubmit={handleSubmit}
				quantity={quantity}
				register={register}
				selectedMenu={selectedMenu}
				selectedOrder={selectedOrder}
				setEditSetMenuDialog={setEditSetMenuDialog}
				setOptionErr={setOptionErr}
				setQuantity={setQuantity}
				setTakeAwayStatus={setTakeAwayStatus}
				setValue={setValue}
				unregister={unregister}
				updateSetMenuItem={updateSetMenuItem}
				watch={watch}
				clearErrors={clearErrors}
				errors={errors}
				mode={mode}
				isSubmitting={isSubmitting}
				setIsSubmitting={setIsSubmitting}
				isDesktop={isDesktop}
				getRoundedQty={getRoundedQty}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				orderItemWithSetMenuOptionsLoading={orderItemWithSetMenuOptionsLoading}
			/>
			<FooterWithTotal
				label="Total"
				value={`${getOutlet[0]?.currencyCode ?? ''}${totalAmountFooter || 0}`}
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				option={{
					name: getOrderItem?.length > 0 ? 'Confirm Order' : 'Add Item',
					onClick: e => {
						setIsSubmitting(true);
						if (getOrderItem?.length > 0) {
							// handlePrinting();
							!isSubmitting && handlePrinting();
						} else if (getOrderItem?.length === 0) {
							if (mode === 'dineIn') {
								history.push({
									pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/menu-category/${menuCategoryID}`,
								});
							} else {
								history.push({
									pathname: `/menu/outlet-app/${outletID}/order/${orderID}/menu-category/${menuCategoryID}/takeAway`,
								});
							}
						}
						e.stopPropagation();
					},
					color: confirmOrderLoading === false ? 'primary' : 'inherit',
					disabled: confirmOrderLoading === false ? false : true,
				}}
			/>
		</>
	);
};
