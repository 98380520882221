import React from 'react';
import { DiscountTypeForm } from './DiscountTypeForm';
import { DiscountTypeList } from './DiscountTypeList';

const discountTypeRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/discount-type`,
		},
		component: <DiscountTypeList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/discount-type/add`,
		},
		component: <DiscountTypeForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/discount-type/:discountID/edit`,
		},
		component: <DiscountTypeForm mode="edit" />,
	},
];

export default discountTypeRoutes;
