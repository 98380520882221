import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Auditor } from 'assets';
import {
	useGetGlListingQuery,
	useGetOutletQuery,
	useGetUsersByAccountQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

export const GeneralLedgerJournalList = () => {
	useEffect(() => {
		console.log('GeneralLedgerJournalList');
	}, []);

	const outletID = localStorage.getItem('latestOutlet');
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const history = useHistory();
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const createdByName = ID => {
		return getUsersByAccount?.find(x => x.ID === ID)?.name || '';
	};

	const {
		data: { getUsersByAccount } = { getUsersByAccount: [] },
	} = useGetUsersByAccountQuery({
		variables: {
			accountID: user?.accountID,
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: { getExportedGLListing } = { getExportedGLListing: [] },
		loading: listLoading,
	} = useGetGlListingQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
		onError: error => {
			console.log('error');
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	useEffect(() => {
		if (
			getExportedGLListing &&
			getExportedGLListing?.length > 0 &&
			!listLoading
		) {
			setOriginalListing(getExportedGLListing);
		}
	}, [getExportedGLListing, listLoading]);

	return (
		<>
			{outletLoading && <Loading />}
			{listLoading && <Loading />}
			<MainHeader
				mainBtn="back"
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/account-journals`,
					});
				}}
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[{ name: '../' }, { name: 'GL Journal', current: true }]}
			/>

			<SearchHeader
				title="GL Journal Listing"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, []);
				}}
				fixed
				// defaultValue={billListingSearch?.value ?? ''}
				// isDefaultValue={!!billListingSearch?.value ?? false}
				search
				onCloseAction={() => handleSearch('', [])}
			/>

			<ContentWrapper float search>
				<List className="core-list">
					{getExportedGLListing?.length === 0 ? (
						<EmptyList title="No Record Found" />
					) : (
						filteredList?.map((x, i) => (
							<>
								<ListItem key={i}>
									<ListItemText
										primary={
											<>
												<span className="xsTitle">
													{formatDate(x.startDate)} - {formatDate(x.endDate)}
												</span>
											</>
										}
										secondary={
											<>
												<span className="desc icon-text">
													<span style={{ color: '#ff9800' }}>
														{x.transactionCnt}
													</span>
													&nbsp;transactions <TextSeparator />
													<img
														src={Auditor}
														alt="ifca"
														width={12}
														loading="lazy"
														style={{ paddingRight: '4px' }}
													/>
													<span style={{ color: '#ff9800' }}>
														{formatDate(x.glDate)} {createdByName(x.createdBy)}
													</span>
												</span>
											</>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete">
											<KeyboardArrowRight
												onClick={() => {
													history.push({
														pathname: `/menu/outlet-app/account-journals/gl-journal/detail`,
														state: x,
													});
												}}
											/>
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</>
						))
					)}
				</List>

				<FloatButton
					onClick={() =>
						history.push(`/menu/outlet-app/account-journals/gl-journal/add`)
					}
				/>
			</ContentWrapper>
		</>
	);
};
