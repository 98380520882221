import { yupResolver } from '@hookform/resolvers';
import { AddressAutoCompleteComponent } from '@ifca-root/react-component/src/components/Address/AddressAutoCompleteComponent';
import { UploadPreview } from '@ifca-root/react-component/src/components/Avatar/UploadPreview';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { FileUploadInput } from '@ifca-root/react-component/src/components/Input/FileUploadInput';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import useUploadAttachment from '@ifca-root/react-component/src/utils/hooks/useUploadAttachment';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormLabel,
	Grid,
	InputAdornment,
	makeStyles,
	Switch,
	TextField,
} from '@material-ui/core';
import { createFilterOptions } from '@material-ui/lab';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetAttachmentDocument,
	GetOutletDocument,
	RecurrenceDay,
	useAttachmentListingLazyQuery,
	useCreateOutletMutation,
	useGetHotelBySubscriptionQuery,
	useGetOutletQuery,
	useUpdateOutletMutation,
} from 'generated/graphql';
import { useAddressAutoComplete } from 'helpers/hooks/useAddressAutoComplete';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { countryAlphaCodes } from 'utils/countryAlphaCodes';
import { countryCurrencyCodes } from 'utils/countryCurrencyCodes';
import { currencyCodeSymbols } from 'utils/currencyCodeSymbols';

const useStyles = makeStyles({
	root: {
		['& *']: {
			boxSizing: 'border-box',
		},
	},
	form: {
		['& > *']: {
			marginBottom: '0.5rem',
		},
	},
	halfWidths: {
		display: 'flex',
		flexDirection: 'row',
		flexWrap: 'wrap',

		['& > *']: {
			flex: '0 0 auto',
			width: '50%',
			marginBottom: '0.5rem',
		},
	},
});

export interface OutletProfileProps {
	name: string;
	companyName: string;
	address: string;
	registrationNo: string;
	taxRegistrationNo: string;
	contactNo: string;
	email: string;
	govTax: number;
	serviceCharge: number;
	openingTime: string;
	closingTime: string;
	autoDayEndTime: string;
	country: string;
	state: string;
	city: string;
	postcode: string;
	isActive: boolean;
	hotelID: string;
	outletCode: string;
	integrateCityLedger: boolean;
	capacity: number;
}

export const OutletProfileForm = (props: any) => {
	const [outletData, setOutletData] = useState({});
	const location = useLocation();
	const editData = location?.state as any;
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const [openExitDialog, setOpenExitDialog] = useState(false);

	const { mode } = props;
	const {
		fileMap,
		previewMap,
		removeMappedFiles,
		handleUploadMapping,
		handleSingleUploadMapping,
		handleEditUploadMap,
	} = useUploadAttachment();

	const {
		getCountry, // country
		getStatebyCountry, // State
		getCitybyState, // City
		getPostcodebyCity, // Postcode
		handleCountryOnChange,
		handleStateOnChange,
		handleCityOnChange,
		handlePostCodeOnChange,
		handleGoogleOnChange,
		query,
		autoCompleteRef,
		state,
		setQuery,
		dispatch,
		initializeEditValue,
	} = useAddressAutoComplete();

	useEffect(() => {
		if (getCountry?.length > 0 && mode === 'edit') {
			let currCountry = getCountry?.find(v => {
				return (
					v?.name?.toLowerCase() === editData?.address?.country?.toLowerCase()
				);
			});

			handleCountryOnChange(currCountry);
		}
	}, [getCountry, mode]);

	const [ogAddr, setOGAddr] = useState(null);
	const [addrValue, setAddress] = useState(null);
	const [isActive, setIsActive] = useState(
		// true,
		editData?.isActive != null ? editData?.isActive : true,
	);
	const [country, setCountry] = useState('');
	const [newState, setState] = useState('');
	const [city, setCity] = useState('');
	const [newPostcode, setPostcode] = useState('');
	const [countryCode, setCountryCode] = useState('my');
	const [currencyCode, setCurrencyCode] = useState('RM');

	useEffect(() => {
		const regex = new RegExp(`\\b(${country})\\b`, 'g');
		const countryCodes = Object.keys(countryAlphaCodes);
		if (country !== '') {
			countryCodes.forEach(code => {
				if (regex.test(countryAlphaCodes?.[code])) {
					setCountryCode(code.toLowerCase() ?? 'my');
				}
			});
			// find currency code first
			let alphaCode;
			const currencyCodes = Object.keys(countryCurrencyCodes);
			currencyCodes.forEach(code => {
				if (regex.test(countryCurrencyCodes?.[code])) {
					alphaCode = code;
				}
			});
			// find symbol of currency code
			if (alphaCode in currencyCodeSymbols) {
				setCurrencyCode(currencyCodeSymbols[alphaCode] ?? '');
			} else {
				setCurrencyCode(alphaCode ?? '');
			}
		}
	}, [country]);

	useEffect(() => {
		if (mode === 'edit' && editData) {
			let array_of_address = [
				editData?.address?.address,
				editData?.address?.city,
				editData?.address?.state,
				editData?.address?.country,
			];
			setQuery(array_of_address.join(', '));
			setOGAddr(array_of_address.join(', ')); //to compare if there is any changes
			setAddress(array_of_address.slice(0));
			initializeEditValue({
				address: editData?.address?.address,
				country: editData?.address?.country,
				state: editData?.address?.state,
				city: editData?.address?.city,
				postCode: editData?.address?.postCode,
			});
		}
	}, [mode, editData]);

	const yupSchema = yup.object().shape({
		name: CommonYupValidation.requireField(SystemMsgs.name()),
		companyName: yup
			.string()
			.required(SystemMsgs.companyName())
			.typeError(SystemMsgs.companyName()),
		registrationNo: CommonYupValidation.requireField(SystemMsgs.regNo()),
		taxRegistrationNo: CommonYupValidation.requireField(SystemMsgs.taxRegNo()),
		contactNo: CommonYupValidation.requireField(SystemMsgs.contactNo()),
		email: CommonYupValidation.email(),
		openingTime: yup
			.string()
			.required(SystemMsgs.openingTime())
			.typeError(SystemMsgs.openingTime()),
		closingTime: yup
			.string()
			.required(SystemMsgs.closingTime())
			.typeError(SystemMsgs.closingTime()),
		autoDayEndTime: yup
			.string()
			.required(SystemMsgs.autoDayEndTime())
			.typeError(SystemMsgs.autoDayEndTime()),

		// openingTime: CommonYupValidation.requireField(SystemMsgs.openingTime()),
		// closingTime: CommonYupValidation.requireField(SystemMsgs.closingTime()),
		// autoDayEndTime: CommonYupValidation.requireField(
		// 	SystemMsgs.autoDayEndTime(),
		// ),
		// hotelID: CommonYupValidation.requireField(SystemMsgs.hotel()),
	});

	const {
		register,
		control,
		errors,
		handleSubmit,
		formState: { isSubmitting, isSubmitted, dirtyFields, isDirty },
	} = useForm<OutletProfileProps>({
		defaultValues: {
			name: mode === 'add' ? '' : editData?.name,
			companyName: mode === 'add' ? '' : editData?.companyName,
			registrationNo: mode === 'add' ? '' : editData?.registrationNo,
			taxRegistrationNo: mode === 'add' ? '' : editData?.taxRegistrationNo,
			contactNo: mode === 'add' ? '' : editData?.contactNo,
			email: mode === 'add' ? '' : editData?.email,
			govTax: mode === 'add' ? '' : editData?.govTax,
			serviceCharge: mode === 'add' ? '' : editData?.serviceCharge,
			address: mode === 'add' ? '' : editData?.address.address,
			country: mode === 'add' ? '' : editData?.address.country,
			state: mode === 'add' ? '' : editData?.address.state,
			city: mode === 'add' ? '' : editData?.address.city,
			postcode: mode === 'add' ? '' : editData?.address.postCode,
			isActive: mode === 'add' ? '' : editData?.isActive,
			openingTime: mode === 'add' ? '' : editData?.openingTime,
			closingTime: mode === 'add' ? '' : editData?.closingTime,
			// hotelID: mode === 'add' ? null : editData?.hotelID,
			outletCode: mode === 'add' ? '' : editData?.outletCode,
			autoDayEndTime: mode === 'add' ? '' : editData?.autoDayEndTime,
			capacity: mode === 'add' ? '' : editData?.capacity,
		},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const handleIsActiveCheckboxChange = (
		event: ChangeEvent<HTMLInputElement>,
	) => {
		setIsActive(!isActive);
	};

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const {
		loading: hotelBySubscriptionLoading,
		data: { getHotelBySubscription } = { getHotelBySubscription: [] },
	} = useGetHotelBySubscriptionQuery({
		fetchPolicy: 'network-only',
	});

	const hotelList = getHotelBySubscription?.data?.MenuXHotelListing;

	const [
		submitOutlet,
		{ loading: createOutletLoading, called: createOutletCalled },
	] = useCreateOutletMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconID}/vo`);

			// setTimeout(() => {
			history.push({
				pathname: `/menu/outlet-app/outlet-setting`,
				state: { success: true, msgMode: 'create' },
			});
			//history.goBack();
			// }, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		updateOutlet,
		{ loading: updateOutletLoading, called: updateOutletCalled },
	] = useUpdateOutletMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconId}/vo`);

			// setTimeout(() => {
			history.push({
				pathname: `/menu/outlet-app/outlet-setting/${outletID}/outlet-profile`,
				state: { success: true, msgMode: 'update' },
			});
			// }, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const [
		loadAttachment,
		{ loading: attachmentLoad, error: attachmentError, data: attachmentData },
	] = useAttachmentListingLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: data => {
			handlingFiles('outlet-logo', data?.attachmentListing);
			handlingFiles('outlet-gallery', data.attachmentListing);
		},
	});

	const handlingFiles = (description: string, data: any) => {
		handleEditUploadMap(
			data?.filter(v => v?.description === description),
			description,
		);
		previewMap[description] = [];
		previewMap[description]?.push(
			...data?.filter(v => v?.description === description).map(x => x?.fileURL),
		);
	};

	useEffect(() => {
		if (mode == 'edit') {
			loadAttachment({ variables: { refID: editData?.ID } });
		}
	}, [mode, editData]);

	const classes = useStyles();
	const history = useHistory();
	const { [`outletID`]: outletID } = useParams<Record<string, any>>();
	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const [integrateCityLedgerInput, setIntegrateHotelXInput] = useState({
		integrateCityLedger: getOutlet[0]?.integrateCityLedger,
	});

	const onChangeIntegrateHotelX = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setIntegrateHotelXInput({
			...integrateCityLedgerInput,
			[event.target.name]: event.target.checked,
		});
	};

	const outletArray: any[] = getOutlet;

	const createUpdateMutation = mode === 'edit' ? updateOutlet : submitOutlet;

	const onSubmit = (data: any) => {
		const finalFiles_outlet_gallery = addingDescToFiles(
			'outlet-gallery',
			fileMap['outlet-gallery'],
		);
		const finalFiles_outlet_logo = addingDescToFiles(
			'outlet-logo',
			fileMap['outlet-logo'],
		);

		createUpdateMutation({
			variables: {
				opHourInput: [
					{
						openingTime: data.openingTime,
						closingTime: data.closingTime,
						day: RecurrenceDay.Monday,
					},
				],
				outletInput: {
					...(mode === 'edit' ? { ID: editData?.ID } : null),
					name: data.name,
					companyName: data.companyName,
					outletCode: data.outletCode,
					currencyCode: `${currencyCode} `,
					// hotelID: data.hotelID,
					companyID: data.registrationNo,
					registrationNo: data.registrationNo,
					taxRegistrationNo: data.taxRegistrationNo,
					contactNo: data.contactNo,
					email: data.email,
					closingTime: data.closingTime,
					openingTime: data.openingTime,
					autoDayEndTime: data.autoDayEndTime,
					isActive: isActive,
					integrateCityLedger: integrateCityLedgerInput.integrateCityLedger,
					capacity: parseFloat(data.capacity),
					address: {
						address: state.address,
						state: state.state.name,
						city: state.city.name,
						country: state.country.name,
						postCode: state.postcode.postcode,
					},
					coordinate: {
						latitude: state.coordinate.latitude,
						longitude: state.coordinate.longitude,
					},
					galleryFiles: finalFiles_outlet_gallery,
					logoFiles: finalFiles_outlet_logo,
				},
			},
			refetchQueries: [
				{
					query: GetOutletDocument,
					variables: {
						accountID: user?.accountID,
						...(outletID ? { ID: outletID } : null),
					},
				},
				{
					query: GetAttachmentDocument,
					variables: { refID: editData?.ID },
				},
			],
		});
	};

	const addingDescToFiles = (description: string, file: any) => {
		let temp = file;
		for (const v in temp) {
			temp[v].description = description;
		}

		return temp;
	};

	const filterOptions = createFilterOptions({
		matchFrom: 'start',
		stringify: (option: any) => option.name,
	});

	return (
		<>
			{hotelBySubscriptionLoading && <Loading />}
			{attachmentLoad && <Loading />}
			{outletLoading && <Loading />}
			{createOutletLoading && <Loading />}
			{updateOutletLoading && <Loading />}

			<MainHeader
				onClick={() => {
					setOpenExitDialog(true);
				}}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={editData?.name || user?.companyName}
				// title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Outlet Profile', current: true },
				]}
				rightRouteSegments={[{ name: mode === 'edit' ? 'Edit' : 'Add' }]}
			/>

			{/* <form onSubmit={handleSubmit(onSubmit)}> */}
			<ContentWrapper float footer>
				<CardContents devFullWidth={true}>
					{mode === 'edit' ? (
						<Grid container style={{ height: '0px' }}>
							<Grid item xs={9} className="left"></Grid>
							<Grid item xs={3} className="right">
								<FormControl component="fieldset">
									<Controller
										as={
											<FormControlLabel
												control={
													<Checkbox
														checked={isActive}
														onChange={handleIsActiveCheckboxChange}
														name="isActive"
														color="primary"
													/>
												}
												label="Active"
											/>
										}
										name="isActive"
										control={control}
									/>
								</FormControl>
							</Grid>
						</Grid>
					) : null}

					<Controller
						as={TextField}
						name="name"
						label="Outlet Name"
						autoComplete="off"
						multiline={true}
						fullWidth
						ref={register}
						control={control}
						margin="dense"
						required
						helperText={errors?.name?.message}
						error={errors?.name ? true : false}
					></Controller>

					<Controller
						as={TextField}
						name="outletCode"
						label="Outlet Code"
						disabled={
							mode === 'edit' && getOutlet[0]?.generatedBillNo > 0
								? true
								: false
						}
						autoComplete="off"
						multiline={true}
						fullWidth
						ref={register}
						control={control}
						margin="dense"
						required
						helperText={errors?.outletCode?.message}
						error={errors?.outletCode ? true : false}
					></Controller>

					<AddressAutoCompleteComponent
						getCountry={getCountry}
						getStatebyCountry={getStatebyCountry}
						getCitybyState={getCitybyState}
						getPostcodebyCity={getPostcodebyCity}
						handleCountryOnChange={handleCountryOnChange}
						handleStateOnChange={handleStateOnChange}
						handleCityOnChange={handleCityOnChange}
						handlePostCodeOnChange={handlePostCodeOnChange}
						handleGoogleOnChange={handleGoogleOnChange}
						autoCompleteRef={autoCompleteRef}
						state={state}
						country={country}
						setCountry={setCountry}
						setState={setState}
						setCity={setCity}
						newState={newState}
						newPostcode={newPostcode}
						setPostcode={setPostcode}
						city={city}
						formType={mode}
						isSubmitted={isSubmitted}
						editData={editData}
					/>

					<Controller
						as={TextField}
						name="companyName"
						label="Company Name."
						autoComplete="off"
						multiline={true}
						required
						fullWidth
						ref={register}
						control={control}
						margin="dense"
						helperText={errors?.companyName?.message}
						error={errors?.companyName ? true : false}
					></Controller>

					<Controller
						as={TextField}
						name="registrationNo"
						label="Company Registration No."
						autoComplete="off"
						multiline={true}
						required
						fullWidth
						ref={register}
						control={control}
						margin="dense"
						helperText={errors?.registrationNo?.message}
						error={errors?.registrationNo ? true : false}
					></Controller>

					<Controller
						as={TextField}
						name="taxRegistrationNo"
						label="SST Regstration No."
						autoComplete="off"
						multiline={true}
						required
						fullWidth
						ref={register}
						control={control}
						margin="dense"
						helperText={errors?.taxRegistrationNo?.message}
						error={errors?.taxRegistrationNo ? true : false}
					></Controller>

					{/* <div style={{ margin: '12px' }}></div> */}
					<Controller
						as={
							<PhoneNumInput
								country={countryCode ?? 'MY'}
								specialLabel="Phone No."
								fullWidth
								currency={currencyCode}
								// disableDropdown={true}
							/>
						}
						name="contactNo"
						// required
						fullWidth
						marginTop
						marginBottom
						margin="dense"
						// id="standard-basic"
						type="tel"
						// autoComplete="off"
						control={control}
						ref={register}
						helperText={errors?.contactNo?.message}
						error={errors?.contactNo ? true : false}
					/>
					{/* {errors?.contactNo && (
						<div
							style={{
								color: '#E53935',
								fontWeight: 400,
								margin: '5px 0px 20px',
							}}
							className="mdDesc"
						>
							{errors?.contactNo?.message}
						</div>
					)} */}
					<Controller
						as={TextField}
						name="email"
						label="Email Address"
						autoComplete="off"
						multiline={true}
						required
						fullWidth
						ref={register}
						control={control}
						margin="dense"
						helperText={errors?.email?.message}
						error={errors?.email ? true : false}
					></Controller>

					<FormControl component="fieldset" margin="dense" fullWidth>
						<FormLabel
							//component="legend"
							style={{
								fontSize: '12px',
								color: 'black',
								//fontWeight: 'bold',
							}}
						>
							Operating Hours
						</FormLabel>
					</FormControl>

					<Controller
						as={TextField}
						name="openingTime"
						label="Open Hours"
						type="time" //defaultValue="14:00"
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300, // 5 min
						}}
						required
						className="left"
						fullWidth
						ref={register}
						control={control}
						margin="dense"
						autoComplete="off"
						helperText={errors?.openingTime?.message}
						error={errors?.openingTime ? true : false}
					></Controller>

					<Controller
						as={TextField}
						name="closingTime"
						label="Close Hours"
						type="time" //defaultValue="14:00"
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300, // 5 min
						}}
						required
						className="right"
						fullWidth
						ref={register}
						control={control}
						margin="dense"
						autoComplete="off"
						helperText={errors?.closingTime?.message}
						error={errors?.closingTime ? true : false}
					></Controller>

					<Controller
						as={TextField}
						name="autoDayEndTime"
						label="Auto Day End Time"
						type="time" //defaultValue="14:00"
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300, // 5 min
						}}
						required
						className="left"
						fullWidth
						ref={register}
						control={control}
						margin="dense"
						autoComplete="off"
						helperText={errors?.openingTime?.message}
						error={errors?.openingTime ? true : false}
					></Controller>

					<Controller
						as={
							<NumberFormat
								customInput={TextField}
								allowNegative={false}
								fullWidth
								autoComplete="off"
								isNumericString
								fixedDecimalScale
								required
								// className="right"
								margin="dense"
								onValueChange={(value: any) => {
									const { floatValue } = value;
								}}
							/>
						}
						name="capacity"
						className="right"
						label="Capacity (Pax)"
						control={control}
						ref={register}
					/>

					{getOutlet[0]?.outletChannel !== null &&
						getOutlet[0]?.outletChannel !== undefined && (
							<div
								className="full"
								style={{
									justifyContent: 'space-between',
									display: 'flex',
								}}
							>
								<span className="flex-space" style={{ alignSelf: 'center' }}>
									Integrate HotelX - City Ledger
								</span>

								<Switch
									name="integrateCityLedger"
									checked={integrateCityLedgerInput?.integrateCityLedger}
									defaultChecked={getOutlet[0]?.integrateCityLedger}
									onChange={e => {
										onChangeIntegrateHotelX(e);
									}}
									color="primary"
								/>
							</div>
						)}

					<FileUploadInput
						label="Outlet's Logo"
						files={fileMap['outlet-logo']}
						margin="dense"
						onHandleUploadChange={e =>
							handleSingleUploadMapping(e, 'outlet-logo')
						}
						multiple={false}
						mapData={'outlet-logo'}
						imagePreview={
							<>
								{previewMap['outlet-logo']?.map((v, i) => (
									<UploadPreview
										remove
										key={v}
										src={v}
										onClick={() => removeMappedFiles('outlet-logo', i)}
										mediaType={
											fileMap['outlet-logo']?.length > 0
												? fileMap['outlet-logo'][i]?.type
												: attachmentData?.attachmentListing?.filter(
														v => v?.description === 'outlet-logo',
												  )[i]?.mediaType
										}
									/>
								))}
							</>
						}
					/>

					<FileUploadInput
						label="Outlet Gallery"
						files={fileMap['outlet-gallery']}
						margin="dense"
						onHandleUploadChange={e => handleUploadMapping(e, 'outlet-gallery')}
						multiple
						mapData={'outlet-gallery'}
						imagePreview={
							<>
								{previewMap['outlet-gallery']?.map((v, i) => (
									<UploadPreview
										remove
										key={v}
										src={v}
										onClick={() => removeMappedFiles('outlet-gallery', i)}
										mediaType={
											fileMap['outlet-gallery']?.length > 0
												? fileMap['outlet-gallery'][i]?.type
												: attachmentData?.attachmentListing?.filter(
														v => v?.description === 'outlet-gallery',
												  )[i]?.mediaType
										}
									/>
								))}
							</>
						}
					/>
				</CardContents>
			</ContentWrapper>
			{/* </form> */}

			{(createOutletLoading || updateOutletLoading) && <Loading />}
			<DynamicFooter
				options={[
					{
						name: 'Save',
						onClick: () => {
							handleSubmit(onSubmit)();
						},
						color:
							createOutletLoading || updateOutletLoading
								? 'inherit'
								: 'primary',
						disabled: createOutletLoading || updateOutletLoading ? true : false,
						props: {
							type: 'submit',
						},
					},
				]}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={
					mode === 'edit'
						? `/menu/outlet-app/outlet-setting/${outletID}/outlet-profile`
						: `/menu/outlet-app/outlet-setting`
				}
			/>
		</>
	);
};
