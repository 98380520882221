import { Reducer, useEffect, useReducer, useRef, useState } from 'react';
import { IAction } from 'helpers/model';
import { useCustomCountryQuery } from './useCustomCountryQuery';

// Load Google APi key and callback function
let autoComplete: any;
const loadScript = (url: any, callback: any) => {
	let script: any = document.createElement('script');
	script.type = 'text/javascript';

	if (script.readyState) {
		script.onreadystatechange = () => {
			if (script.readyState === 'loaded' || script.readyState === 'complete') {
				script.onreadystatechange = null;
				callback();
			}
		};
	} else {
		script.onload = () => callback();
	}
	script.src = url;
	document.getElementsByTagName('head')[0].appendChild(script);
};

interface AddressAutoCompleteProps {
	address: string;
	country: any;
	state: any;
	city: any;
	postcode: any;
	coordinate: any;
}
export const useAddressAutoComplete = () => {
	const [editVal, setEditVal] = useState(null);
	const [query, setQuery] = useState<String>('');
	const {
		getCountry, // country
		getStatebyCountry, // State
		getCitybyState, // City
		getPostcodebyCity, // Postcode
		handleGetStateByCountry, // variables : CountryID
		handleGetCityByState, // variables : StateID
		handleGetPostcodeByCity, // variables : StateID
	} = useCustomCountryQuery();
	//autocomplete Ref for input field
	const autoCompleteRef = useRef(null);
	const initialState: AddressAutoCompleteProps = {
		address: '',
		country: {},
		state: {},
		city: {},
		postcode: {},
		coordinate: {},
	};
	const reducer: Reducer<AddressAutoCompleteProps, IAction> = (
		state,
		action,
	) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};
	const [state, dispatch] = useReducer(reducer, initialState);
	const APIKEY = 'AIzaSyDzG4brvBRqg2wvH3jPA6v794rWCRz3adk';
	const handlePlaceSelect = async (updateQuery: any) => {
		// Address object {} getPlace  //https://developers.google.com/maps/documentation/javascript/reference/places-widget
		const addressObject = autoComplete.getPlace();
		const addressName = addressObject.name;
		let query: any;

		if (addressObject) {
			if (addressObject?.formatted_address.includes(addressName)) {
				query = addressObject?.formatted_address;
			} else {
				query = addressName + ', ' + addressObject.formatted_address;
			}
		}

		updateQuery(query);
		const addressComponent: Array<any> = addressObject['address_components'];
		const addressLocation: any = addressObject['geometry'];
		let PostCode = {
				postcode: '',
			},
			City = {
				name: '',
			},
			State = {
				name: '',
			},
			Country = {
				name: '',
			},
			Coordinate = {
				latitude: 0,
				longitude: 0,
			};

		// Address component for State , City , Post Code value
		addressComponent.forEach(x => {
			if (x.types.includes('locality')) {
				City.name = x.long_name;
			} else if (x.types.includes('administrative_area_level_1')) {
				State.name = x.long_name;
			} else if (x.types.includes('country')) {
				Country.name = x.long_name;
			} else if (x.types.includes('postal_code')) {
				PostCode.postcode = x.long_name;
			}
		});
		Coordinate.latitude = addressLocation?.location?.lat();
		Coordinate.longitude = addressLocation?.location?.lng();
		// join 4 object short_name
		let addressResult: Array<any> = [{ name: addressName }];

		addressComponent.forEach(x =>
			x.types.filter(y => {
				if (
					(y === 'premise' ||
						y === 'street_number' ||
						y === 'subpremise' ||
						y === 'route' ||
						y === 'sublocality_level_1') &&
					!x.long_name.match(addressName) &&
					y !== 'administrative_area_level_1' &&
					y !== 'country' &&
					y !== 'postal_code'
				)
					return addressResult.push({ name: x.long_name });
				else return null;
			}),
		);

		dispatch({
			type: 'address',
			payload: addressResult.map(x => x.name).join(', '),
		});
		dispatch({
			type: 'country',
			payload: Country,
		});
		dispatch({
			type: 'state',
			payload: State,
		});
		dispatch({
			type: 'city',
			payload: City,
		});
		dispatch({
			type: 'postcode',
			payload: PostCode,
		});
		dispatch({
			type: 'coordinate',
			payload: Coordinate,
		});
	};

	const handleScriptLoad = (updateQuery: any, autoCompleteRef: any) => {
		autoComplete = new (window as any).google.maps.places.Autocomplete(
			autoCompleteRef.current,
			{},
		);
		autoComplete.setFields([
			'address_components',
			'formatted_address',
			'name',
			'geometry',
		]);
		autoComplete.addListener('place_changed', () => {
			handlePlaceSelect(updateQuery);
		});
	};
	useEffect(() => {
		loadScript(
			`https://maps.googleapis.com/maps/api/js?key=${APIKEY}&libraries=places`,
			() => handleScriptLoad(setQuery, autoCompleteRef),
		);
	}, []);
	// useEffect(() => {
	//   if (state.country?.name === 'Malaysia') {
	//     handleGetStateByCountry({
	//       variables: {
	//         countryID: getCountry
	//           .filter(v => v.name === 'Malaysia')
	//           .map(x => x.ID)[0] as any,
	//       },
	//     });
	//   }
	// }, [state.country?.name]);

	const handleGoogleOnChange = e => {
		setQuery(e);
		dispatch({
			type: 'address',
			payload: e,
		});
	};
	const handleCountryOnChange = (newValue: any) => {
		dispatch({
			type: 'country',
			// payload: newValue?.name,
			payload: { name: newValue?.name },
		});
		if (newValue?.ID) {
			handleGetStateByCountry({
				variables: {
					countryID: newValue?.ID,
				},
			});
		}
	};

	const handleStateOnChange = (newValue: any) => {
		dispatch({
			type: 'state',
			payload: newValue,
		});
		if (newValue?.ID) {
			handleGetCityByState({
				variables: {
					stateID: newValue?.ID,
				},
			});
		}
	};

	const handleCityOnChange = (newValue: any) => {
		dispatch({
			type: 'city',
			payload: newValue,
		});
		if (newValue?.ID) {
			handleGetPostcodeByCity({
				variables: {
					cityID: newValue?.ID,
				},
			});
		}
	};
	const handlePostCodeOnChange = (newValue: any) => {
		dispatch({
			type: 'postcode',
			payload: newValue,
		});
	};

	const initializeEditValue = (props: any) => {
		dispatch({
			type: 'address',
			payload: props.address,
		});
		dispatch({
			type: 'country',
			payload: { name: props.country },
		});
		dispatch({
			type: 'state',
			payload: { name: props.state },
		});
		dispatch({
			type: 'city',
			payload: { name: props.city },
		});
		dispatch({
			type: 'postcode',
			payload: { postcode: props.postCode },
		});
	};

	return {
		state,
		autoCompleteRef,
		query,
		setQuery,
		setEditVal,
		handleCountryOnChange,
		handleStateOnChange,
		handleCityOnChange,
		handlePostCodeOnChange,
		handleGoogleOnChange,
		getCountry, // country
		getStatebyCountry, // State
		getCitybyState, // City
		getPostcodebyCity, // Postcode
		dispatch,
		initializeEditValue,
	};
};
