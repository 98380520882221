import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	useGetOutletQuery,
	useGetQrQuery,
	useGetTableGridQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import QrReader from 'react-qr-reader';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export const NewOrderQR = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { mode } = props;
	useEffect(() => {
		console.log(`NewOrderQR ${mode}`);
	}, []);
	const { outletID, tableID } = useParams<any>();

	const [selectedTable, setSelectedTable] = useState(null);
	const [selfOrder, setSelfOrder] = useState(false);
	const [selfPayment, setSelfPayment] = useState(false);

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const {
		called: qrCalled,
		loading: qrLoading,
		error: qrError,
		data: { getQR } = { getQR: [] },
	} = useGetQrQuery({
		fetchPolicy: 'network-only',
		// variables: { outletID: outletID },
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			setSelfOrder(getOutlet[0]?.customerPolicy?.selfOrder);
			setSelfPayment(getOutlet[0]?.customerPolicy?.selfPayment);
		},
	});
	const outletArray: any[] = getOutlet;

	const {
		called: tableCalled,
		loading: tableLoading,
		error: tableError,
		data: { getTable } = { getTable: [] },
	} = useGetTableGridQuery({
		fetchPolicy: 'network-only',
		variables: { ID: tableID },
		onCompleted: ({ getTable }) => {
			setSelectedTable(getTable[0]);
		},
	});

	// const [
	// 	loadOpenOrderQRChecking,
	// 	{
	// 		called: openOrderQRCheckingCalled,
	// 		loading: openOrderQRCheckingLoading,
	// 		error: openOrderQRCheckingError,
	// 		data: { openOrderQRChecking } = { openOrderQRChecking: [] },
	// 	},
	// ] = useOpenOrderQrCheckingLazyQuery({
	// 	onCompleted: ({ openOrderQRChecking }) => {
	// 		if (openOrderQRChecking?.ID) {
	// 			history.push({
	// 				pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/qr-code/${openOrderQRChecking?.ID}`,
	// 				state: selectedTable,
	// 			});
	// 		}
	// 	},
	// 	onError: (error) => {
	// 		console.log('QR does not exist', error);
	// 	},
	// });

	const handleScan = qrUrl => {
		//check if the qr comes from the same outlet
		const qrID = qrUrl?.slice(-73, -37);
		const scannedQR = getQR?.filter(qr => qr?.ID === qrID)[0];
		if (scannedQR?.ID && scannedQR?.outletID === outletID) {
			// if qr never used
			if (Object.keys(scannedQR?.orderInfo).length === 0) {
				history.push({
					pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/qr-code/${qrID}`,
					state: selectedTable,
				});
			} else {
				// if qr is used
				if (qrUrl?.includes('patron-app')) {
					setOpenSnackBar(true);
					setSnackBarMsg(SystemMsgs.qrInUse());
				}
			}
		} else if (qrUrl && !qrID) {
			// qr not from menux
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.qrInvalid());
		} else if (scannedQR?.ID && scannedQR?.outletID !== outletID) {
			// qr not from this outlet
			setOpenSnackBar(true);
			setSnackBarMsg('Cannot use QR from another outlet');
		}
	};

	const [openQRInUseDialog, setOpenQRInUseDialog] = useState(false);
	const [scannedQRInfo, setScannedQRInfo] = useState({
		code: null,
		message: '',
	});

	return (
		<>
			{qrLoading && <Loading />}
			{outletLoading && <Loading />}
			{tableLoading && <Loading />}

			<MainHeader
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/${outletID}/table/grid`,
					});
					// if (mode === 'dineIn') {
					// 	history.push({
					// 		pathname: `/menu/outlet-app/${outletID}/table/grid`,
					// 	});
					// } else if (mode === 'takeAway') {
					// 	history.push({
					// 		pathname: `/menu/outlet-app/${outletID}/takeaway`,
					// 	});
					// }
				}}
				mainBtn="close"
				smTitle="Outlet App"
				title={outletArray[0]?.name}
				// currency={
				// 	<span className="xxTitle flex-space" style={{ color: 'white' }}>
				// 		Table
				// 		<span className="title flex-space" style={{ color: 'orange' }}>
				// 			{' ' + selectedTable?.tableNumber}
				// 		</span>
				// 	</span>
				// }
				routeSegments={[
					{ name: 'Table Layout' },
					{
						name:
							mode === 'dineIn'
								? 'Table ' +
								  `${selectedTable?.prefix}${selectedTable?.tableNumber}`
								: 'Take Away',
						current: true,
					},
				]}
				// rightRouteSegments={[{ name: '$ 0.00 (HARDCODE)' }]}
			/>

			<ContentWrapper>
				<QrReader
					delay={300}
					onError={() => alert('error')}
					onScan={e => handleScan(e)}
					className="qr-container"
				/>
			</ContentWrapper>

			{/* {qr_in_use_dialog()} */}
		</>
	);
};
