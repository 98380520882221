import theme from '@ifca-root/react-component/src/assets/theme';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { StyledMenu } from '@ifca-root/react-component/src/components/others/Menu';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Checkbox,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { IndeterminateCheckBox } from '@material-ui/icons';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetMenuItemForSetMenuOptionItemDocument,
	GetSetMenuOptionOptionDocument,
	useCreateSetMenuOptionMutation,
	useCreateSetMenuOptionOptionItemMutation,
	useDeleteSetMenuOptionItemMutation,
	useDeleteSetMenuOptionMutation,
	useGetMenuItemForSetMenuOptionItemQuery,
	useGetMenuItemForSetMenuQuery,
	useGetOutletQuery,
	useGetSetMenuOptionOptionQuery,
	useUpdateSetMenuOptionMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useParams } from 'react-router-dom';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
import './setMenu.scss';

interface OptionItemProps {
	name: string;
	selection: number;
}

export const SetMenuOptionList = (props: any) => {
	useEffect(() => {
		console.log('SetMenuOptionList Outlet App');
	}, []);

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const history = useHistory();
	const [onChecked, setOnChecked] = useState(false);
	const [dialogMode, setDialogMode] = useState<string>();
	const [openOptionDialog, setOpenOptionDialog] = useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
	const [addOption, setAddOption] = useState(false);
	const [setMenuOptionInfo, setSetMenuOptionInfo] = useState(null);
	const [noOfSelection, setNoOfSelection] = useState(null);
	let [checkedID, setCheckedID] = useState([]);
	const [double, setDouble] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { [`outletID`]: outletID, setMenuID }: any = useParams<
		Record<string, any>
	>();
	const {
		register,
		control,
		handleSubmit,
		setValue,
		watch,
		formState: { isSubmitting },
	} = useForm<OptionItemProps>({
		defaultValues: {
			// selection: setMenuOptionInfo ? setMenuOptionInfo?.maxSelections : 0,
			// selection: setMenuOptionInfo ? setMenuOptionInfo?.maxSelections : 0,
		},
	});

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	useEffect(() => {
		setValue('name', setMenuOptionInfo?.name);
		setValue('selection', setMenuOptionInfo?.maxSelections);
	}, [setMenuOptionInfo]);

	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	//to get the name of the combo
	const {
		data: { getMenuItem } = { getMenuItem: [] },
		loading: menuItemForSetMenuLoading,
	} = useGetMenuItemForSetMenuQuery({
		fetchPolicy: 'network-only',
		variables: {
			isSetMenu: true,
			outletID: outletID,
			ID: setMenuID,
		},
	});

	//to get list of set menu option
	const {
		called: getSetMenuOptionOptionCalled,
		loading: getSetMenuOptionOptionLoading,
		data: { getSetMenuOptionOption } = { getSetMenuOptionOption: [] },
	} = useGetSetMenuOptionOptionQuery({
		fetchPolicy: 'network-only',
		variables: {
			menuItemID: setMenuID,
			outletID: outletID,
		},
		onCompleted: data => {
			setOpenOptionDialog(false);
		},
	});

	// Get other set menu

	const {
		data: getAllSetMenuItem,
		loading: getAllSetMenuItemLoading,
	} = useGetMenuItemForSetMenuQuery({
		variables: {
			isSetMenu: true,
			outletID: outletID,
		},
	});

	//to list selection when adding new menu item into option
	const {
		called: menuItemCalled,
		loading: menuItemForSetMenuOptionItemLoading,
		data: { getMenuItemForSetMenuOptionItem } = {
			getMenuItemForSetMenuOptionItem: [],
		},
	} = useGetMenuItemForSetMenuOptionItemQuery({
		fetchPolicy: 'network-only',
		variables: {
			setMenuID: setMenuID,
			outletID: outletID,
		},
		onCompleted: data => {
			setAddOption(false);
		},
	});

	const [
		deleteSetMenuOptionItem,
		{ loading: deleteSetMenuOptionItemLoading },
	] = useDeleteSetMenuOptionItemMutation({
		onError: error => {
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
			setAddOption(false);
		},
	});

	const [
		deleteSetMenuOption,
		{ loading: deleteSetMenuOptionLoading },
	] = useDeleteSetMenuOptionMutation({
		onError: error => {
			console.log('none', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const [
		createSetMenuOptionOptionItem,
		{ loading: createSetMenuOptionOptionItemLoading },
	] = useCreateSetMenuOptionOptionItemMutation({
		onError: error => {
			console.log('eoror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setAddOption(false);
		},
	});

	const [
		createSetMenuOption,
		{ data: createdMealPeriodData, loading: createSetMenuOptionLoading },
	] = useCreateSetMenuOptionMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setOpenOptionDialog(false);
		},
	});

	const [
		updateSetMenuOption,
		{ loading: subUpdateLoading, error: subUpdateError },
	] = useUpdateSetMenuOptionMutation({
		onError: error => {
			console.log('error', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu/${setMenuID}/set-menu-option-item`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
			setOpenOptionDialog(false);
		},
	});

	const {
		filteredList,
		handleSearch,
		setOriginalListing,
		reset,
	} = useFuseSearch();

	useEffect(() => {
		if (
			getMenuItemForSetMenuOptionItem &&
			getMenuItemForSetMenuOptionItem.length > 0
		) {
			setOriginalListing(getMenuItemForSetMenuOptionItem);
		}
	}, [getMenuItemForSetMenuOptionItem]);

	const handleCheckBox = (e: any, index: number) => {
		let temp: any = checkedID;
		if (e.target.checked) {
			temp.push({
				menuItemID: e.target.value,
				setMenuOptionID: setMenuOptionInfo?.ID,
			});
			setCheckedID(temp);
		} else {
			temp = checkedID.filter((v: any) => v?.menuItemID !== e.target.value);
			setCheckedID(temp);
		}
	};

	//ON SUBMIT
	//ON SUBMIT
	//ON SUBMIT

	const onSubmitDialog = (data: any) => {
		dialogMode === 'add'
			? createSetMenuOption({
					variables: {
						input: {
							name: data?.name,
							description: data?.name,
							nativeName: data?.name,
							maxSelections: parseFloat(data.selection),
							menuItemID: setMenuID,
							outletID,
						},
					},
					refetchQueries: [
						{
							query: GetSetMenuOptionOptionDocument,
							variables: { menuItemID: setMenuID, outletID: outletID },
						},
					],
			  })
			: updateSetMenuOption({
					variables: {
						input: {
							ID: setMenuOptionInfo?.ID,
							name: data?.name,
							description: data?.name,
							nativeName: data?.name,
							maxSelections: parseFloat(data.selection),
							menuItemID: setMenuID,
							outletID: outletID,
						},
					},
					refetchQueries: [
						{
							query: GetSetMenuOptionOptionDocument,
							variables: {
								menuItemID: setMenuID,
								outletID: outletID,
							},
						},
					],
			  });
		setOpenOptionDialog(false);
	};

	const onSubmitOptionItem = async (data: any) => {
		createSetMenuOptionOptionItem({
			variables: {
				setMenuOptionItemInput: checkedID?.map((check: any, index) => {
					return {
						menuItemID: check?.menuItemID,
						setMenuOptionID: check?.setMenuOptionID,
						outletID: outletID,
					};
				}),
			},
			refetchQueries: [
				{
					query: GetSetMenuOptionOptionDocument,
					variables: { menuItemID: setMenuID, outletID: outletID },
				},
				{
					query: GetMenuItemForSetMenuOptionItemDocument,
					variables: {
						setMenuID: setMenuID,
						outletID: outletID,
					},
				},
			],
		});
	};

	const disableButton =
		watch('name') === undefined ||
		watch('name') === null ||
		watch('name') === '' ||
		watch('selection') === undefined ||
		watch('selection') === null ||
		Number(watch('selection')) === 0;

	return (
		<>
			{outletLoading && <Loading />}
			{menuItemForSetMenuLoading && <Loading />}
			{getSetMenuOptionOptionLoading && <Loading />}
			{menuItemForSetMenuOptionItemLoading && <Loading />}
			{deleteSetMenuOptionItemLoading && <Loading />}
			{deleteSetMenuOptionLoading && <Loading />}
			{createSetMenuOptionOptionItemLoading && <Loading />}
			{createSetMenuOptionLoading && <Loading />}
			{subUpdateLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Outlet Settings' },
					{ name: 'Option Item', current: true },
				]}
			/>

			<DynamicSubHeader
				// style={{
				// 	width:
				// 		isDesktop && globalState.drawerOpen
				// 			? 'calc(100% - 288px)'
				// 			: isDesktop && !globalState.drawerOpen
				// 			? `calc(100% - 48px)`
				// 			: null,
				// }}
				title={getMenuItem[0]?.name}
				handleClick={e => handleClick(e, setMenuID, 0)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						{getAllSetMenuItem?.getMenuItem?.map((x, i) => (
							<MenuItem
								key={i}
								value={x.ID}
								defaultValue={x.ID}
								onClick={e => {
									history.push({
										pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu/${x.ID}/set-menu-option-item`,
									});
								}}
							>
								{x.name}
							</MenuItem>
						))}
					</StyledMenu>
				}
			/>

			<ContentWrapper float style={{ marginTop: isDesktop ? '93px' : '30px' }}>
				<MsgAlert
					//   icon={<InfoOutlined />}
					message={'Drag & drop to arrange sequence. '}
				/>
				{/* set menu option name */}
				{/* set menu option name */}
				{/* set menu option name */}
				{/* set menu option name */}

				{getSetMenuOptionOption?.length === 0 ? (
					<EmptyList
						title={`No Option Item in ${getMenuItem[0]?.name} Set`}
						subtitle={`Please create a new option`}
					/>
				) : (
					!getSetMenuOptionOptionLoading &&
					getSetMenuOptionOption?.map((v: any, i) => (
						<CardContents
							section={{
								header: {
									title: `${v?.name}`,
									subtitle: `Choose at least: ${v?.maxSelections}`,
									icon: (
										<div className="option-item-icons">
											<AddIcon
												style={{
													fontSize: '15px',
													color: 'white',
													backgroundColor: '#ff9800',
													borderRadius: '5px',
													margin: '10px 8px 0px',
												}}
												onClick={() => {
													setSetMenuOptionInfo(v); //getting array from set menu option
													setAddOption(true);
													// loadMenuItem({
													// 	variables: {
													// 		outletID: outletID,
													// 		setMenuID: setMenuID,
													// 	},
													// });
												}}
											/>
											<EditIcon
												style={{
													fontSize: '15px',
													color: 'white',
													backgroundColor: '#ff9800',
													borderRadius: '5px',
													margin: '10px 8px 0px',
												}}
												onClick={() => {
													setSetMenuOptionInfo(v);
													setDialogMode('edit');
													setOpenOptionDialog(true);
												}}
											/>
										</div>
									),
								},
							}}
						>
							<List
								style={{
									paddingBottom: '30px',
									width: '100%',
								}}
							>
								{v?.setMenuOptionItem?.map((elem: any, index) => (
									<>
										<ListItem style={{ padding: '5px', width: '100%' }}>
											<img
												style={{
													display: 'flex',
													marginTop: '2px',
													marginBottom: '2px',
													marginRight: '4px',
													border: '1px solid #eaeaea',
													width: '55px',
													height: '55px',
													justifyItems: 'center',
													boxSizing: 'border-box',
													borderRadius: '5px',
												}}
												src={elem?.menuItem?.attachments?.fileURL}
												className="img-owned"
											/>

											<ListItemText
												primary={
													<>
														<span
															style={{ paddingLeft: '5px' }}
															className="xsTitle flex-space"
														>
															{elem.menuItem.name}
														</span>
														{/* <span className="xxTitle highlight-text text-separator"></span> */}
													</>
												}
												// above is for setmenu option item list display name
											/>
											<ListItemSecondaryAction>
												<IconButton
													edge="end"
													aria-controls="menu-list"
													aria-label="more"
													className="delete-icon "
												>
													<IndeterminateCheckBox
														style={{ color: 'red' }}
														onClick={() => {
															{
																setSetMenuOptionInfo(elem);
																setOpenDeleteDialog(true);
															}
														}}
													/>
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>

										{index !== v?.setMenuOptionItem?.length - 1 ? (
											<Divider style={{ display: 'flex', width: '100%' }} />
										) : null}
									</>
								))}
							</List>
						</CardContents>
					))
				)}
			</ContentWrapper>

			{/* {DELETE OPTION ITEM} */}
			{/* {DELETE OPTION ITEM} */}
			{/* {DELETE OPTION ITEM} */}
			<GeneralDeleteDialog
				ID={setMenuOptionInfo?.ID}
				openDialog={openDeleteDialog}
				setOpenDialog={setOpenDeleteDialog}
				dialogName={setMenuOptionInfo?.menuItem?.name}
				deleteMutation={deleteSetMenuOptionItem}
				refetchQueries={[
					{
						query: GetSetMenuOptionOptionDocument,
						variables: {
							menuItemID: setMenuID,
							outletID: outletID,
						},
					},
					{
						query: GetMenuItemForSetMenuOptionItemDocument,
						variables: {
							setMenuID: setMenuID,
							outletID: outletID,
						},
					},
				]}
			/>

			{/* {'ADD OR EDIT OPTION'} */}
			{/* {'ADD OR EDIT OPTION'} */}
			{/* {'ADD OR EDIT OPTION'} */}
			{/* {'ADD OR EDIT OPTION'} */}
			{/* {'ADD OR EDIT OPTION'} */}
			<CommonDialog
				fullWidth={true}
				open={openOptionDialog}
				onClose={() => setOpenOptionDialog(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session title">
										{dialogMode === 'add' ? 'New Option' : 'Edit Option'}
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle infoline">
										{dialogMode === 'add' ? null : setMenuOptionInfo?.name}
										{/* {setMenuOptionInfo?.name} */}
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="content-container">
								<Controller
									as={TextField}
									name="name"
									label={'Option Name'}
									defaultValue={
										dialogMode === 'add' ? '' : setMenuOptionInfo?.name
									}
									autoComplete="off"
									multiline={true}
									// required
									fullWidth
									ref={register}
									control={control}
									// onInput={() => {
									// 	setDouble(false);
									// }}
								></Controller>

								<Controller
									as={
										<NumberFormat
											customInput={TextField}
											style={{ paddingBottom: '4px' }}
											allowNegative={false}
											fullWidth
											multiline={true}
											autoComplete="off"
											isNumericString
											fixedDecimalScale
											ref={register}
											control={control}
											defaultValue={
												dialogMode === 'add'
													? null
													: setMenuOptionInfo?.maxSelections
											}
											onValueChange={(value: any) => {
												const { floatValue } = value;
												setValue('selection', floatValue);
											}}
											// onInput={() => {
											// 	setDouble(false);
											// }}
										/>
									}
									name="selection"
									defaultValue={
										dialogMode === 'add'
											? null
											: setMenuOptionInfo?.maxSelections
									}
									label="No. of Selection"
									ref={register}
									control={control}
								></Controller>
								<div className="desc">
									{dialogMode !== 'add'
										? `Choose at least ${setMenuOptionInfo?.maxSelections} option`
										: 'Must be bigger than 0'}
								</div>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: dialogMode === 'add' ? 'Cancel' : 'Delete',
								props: {
									onClick: () => {
										if (dialogMode === 'add') {
											setOpenOptionDialog(false);
										} else if (
											dialogMode !== 'add' &&
											setMenuOptionInfo?.setMenuOptionItem?.length === 0
										) {
											setOpenOptionDialog(false);
											deleteSetMenuOption({
												variables: {
													ID: setMenuOptionInfo?.ID,
												},
												refetchQueries: [
													{
														query: GetSetMenuOptionOptionDocument,
														variables: {
															menuItemID: setMenuID,
															outletID: outletID,
														},
													},
												],
											});
										}
									},
									variant: 'contained',
									color:
										dialogMode !== 'add' &&
										setMenuOptionInfo?.setMenuOptionItem?.length !== 0
											? 'inherit'
											: 'secondary',
									disabled:
										dialogMode !== 'add' &&
										setMenuOptionInfo?.setMenuOptionItem?.length !== 0,
								},
							},

							{
								displayText: dialogMode === 'add' ? 'Add Option' : 'Save',
								props: {
									// onClick: () => handleSubmit(onSubmitDialog)(),
									variant: 'contained',
									color: disableButton ? 'inherit' : 'primary',
									type: 'submit',
									disabled: disableButton,

									onClick: () => {
										!isSubmitting && handleSubmit(onSubmitDialog)();
										// dialogMode === 'add' ? setDouble(true) : setDouble(false);
									},
								},
							},
						],
					},
				}}
			/>
			{/* {'ADD OPTION ITEM'} */}
			{/* {'ADD OPTION ITEM'} */}
			{/* {'ADD OPTION ITEM'} */}
			{/* {'ADD OPTION ITEM'} */}
			{/* {'ADD OPTION ITEM'} */}
			<CommonDialog
				fullWidth={true}
				open={addOption}
				onClose={() => {
					setAddOption(false);
					setCheckedID([]);
					reset();
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title flex-space">
											{setMenuOptionInfo?.name}
										</div>
									</div>
								</div>
								<div className="infoline-content">
									<div
										className="xsTitle flex-space infoline"
										style={{ color: 'black' }}
									>
										Add Option Item
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="content-container">
								<TextField
									onChange={e => {
										handleSearch(`'"${e?.target?.value}"`, [`name`]);
									}}
									label="Menu Item *"
									variant="standard"
									autoComplete="off"
									multiline={true}
									fullWidth
									ref={register}
								></TextField>

								{!getSetMenuOptionOptionLoading &&
									filteredList?.map((el, index) => (
										<Grid container>
											<Grid item xs={1}>
												<Checkbox
													value={el?.ID}
													onChange={event => {
														handleCheckBox(event, index);
														// setOnChecked(true);
													}}
													name={`item[${index}].optionItem`}
													color="primary"
													inputProps={{ 'aria-label': 'secondary checkbox' }}
												/>
											</Grid>
											<Grid item xs={11}>
												<ListItem key={index} style={{ paddingTop: '10px' }}>
													<ListItemText
														primary={
															<>
																<div className="flex-space mdDesc">
																	{el?.name}
																</div>
															</>
														}
													/>
												</ListItem>
											</Grid>
										</Grid>
									))}
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setAddOption(false);
										reset();
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										handleSubmit(onSubmitOptionItem)();
										setCheckedID([]);
									},

									variant: 'contained',
									color: 'primary',
									type: 'submit',
								},
							},
						],
					},
				}}
			/>

			<FloatButton
				// floatTotalAmt onClick={() => handleClickStatus('create')}
				onClick={() => {
					setOpenOptionDialog(true);
					setDialogMode('add');
					setDouble(false);
				}}
			/>
		</>
	);
};
