import React from 'react'
import TableCell from '@material-ui/core/TableCell'
import { TextField } from '@material-ui/core'
import TableRow from '@material-ui/core/TableRow'
export const Charges = ({ state, dispatch }) => {
  console.log(state, "statess")
  return (
    <>
      <TableRow className="mapping-tr">
        <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
          <span className="xsTitle ">Closed Bill / Officer Check Bill / Entertainment Bill</span>
        </TableCell>
        <TableCell align="left">
          <span className="xsTitle title-row">-</span>
        </TableCell>
        <TableCell align="left">
          <span className="xsTitle  title-row">-</span>{' '}
        </TableCell>
        <TableCell align="left">
          <span className="xsTitle title-row">-</span>{' '}
        </TableCell>
        <TableCell align="left">
          <span className="xsTitle title-row">-</span>
        </TableCell>
        <TableCell align="left">
          <span className="xsTitle title-row">-</span>
        </TableCell>
      </TableRow>

      {/* GST */}

      {state?.TaxGSTMappingCharges?.length > 0 ?
        <TableRow className="mapping-tr">
          <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
            <span className="xsTitle ">GST</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle  title-row">-</span>{' '}
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>{' '}
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
        </TableRow>
        : null}

      {state?.TaxGSTMappingCharges?.map((y, i) => (
        <TableRow className="mapping-tr" key={i}>
          <TableCell scope="row"style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
            {y.Description}
          </TableCell>

          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueDiv || 'Balance Sheet Division'
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxGSTMappingCharges[i]?.revenueDiv ||
                  state?.TaxGSTMappingCharges[i]?.outletTaxGLAcct?.revenueDiv
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueDiv || 'Balance Sheet Division'
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxGSTMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueDiv = event.target.value

                  dispatch({
                    type: 'TaxGSTMappingCharges',
                    payload: newArr,
                  })

                }}
              />
            )}
          </TableCell>
          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueDept || 'Balance Sheet Department'
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxGSTMappingCharges[i]?.revenueDept ||
                  state?.TaxGSTMappingCharges[i]?.outletTaxGLAcct?.revenueDept
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueDept || 'Balance Sheet Department'
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxGSTMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueDept = event.target.value

                  dispatch({
                    type: 'TaxGSTMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
          <TableCell align="left">GL</TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueCredit
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxGSTMappingCharges[i]?.revenueCredit ||
                  state?.TaxGSTMappingCharges[i]?.outletTaxGLAcct?.revenueCredit
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueCredit
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxGSTMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueCredit = event.target.value
                  dispatch({
                    type: 'TaxGSTMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
        </TableRow>
      ))}

      {/* Service Charge */}

      {state?.TaxSCMappingCharges?.length > 0 ?
        <TableRow className="mapping-tr">
          <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
            <span className="xsTitle ">Service Charge</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle  title-row">-</span>{' '}
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>{' '}
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
        </TableRow>
        : null}

      {state?.TaxSCMappingCharges?.map((y, i) => (
        <TableRow className="mapping-tr" key={i}>
          <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
            {y.Description}
          </TableCell>

          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueDiv || 'Balance Sheet Division'
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxSCMappingCharges[i]?.revenueDiv ||
                  state?.TaxSCMappingCharges[i]?.outletTaxGLAcct?.revenueDiv
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueDiv || 'Balance Sheet Division'
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxSCMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueDiv = event.target.value

                  dispatch({
                    type: 'TaxSCMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueDept || 'Balance Sheet Department'
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxSCMappingCharges[i]?.revenueDept ||
                  state?.TaxSCMappingCharges[i]?.outletTaxGLAcct?.revenueDept
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueDept || 'Balance Sheet Department'
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxSCMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueDept = event.target.value

                  dispatch({
                    type: 'TaxSCMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
          <TableCell align="left">GL</TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueCredit
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxSCMappingCharges[i]?.revenueCredit ||
                  state?.TaxSCMappingCharges[i]?.outletTaxGLAcct?.revenueCredit
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueCredit
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxSCMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueCredit = event.target.value

                  dispatch({
                    type: 'TaxSCMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
        </TableRow>
      ))}

      {/* SST */}

      {state?.TaxSSTMappingCharges?.length > 0 ?
        <TableRow className="mapping-tr">
          <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
            <span className="xsTitle ">Service Tax</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle  title-row">-</span>{' '}
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>{' '}
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
        </TableRow>
        : null}

      {state?.TaxSSTMappingCharges?.map((y, i) => (
        <TableRow className="mapping-tr" key={i}>
          <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
            {y.Description}
          </TableCell>

          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueDiv || 'Balance Sheet Division'
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxSSTMappingCharges[i]?.revenueDiv ||
                  state?.TaxSSTMappingCharges[i]?.outletTaxGLAcct?.revenueDiv
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueDiv || 'Balance Sheet Division'
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxSSTMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueDiv = event.target.value

                  dispatch({
                    type: 'TaxSSTMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueDept || 'Balance Sheet Department'
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxSSTMappingCharges[i]?.revenueDept ||
                  state?.TaxSSTMappingCharges[i]?.outletTaxGLAcct?.revenueDept
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueDept || 'Balance Sheet Department'
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxSSTMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueDept = event.target.value

                  dispatch({
                    type: 'TaxSSTMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
          <TableCell align="left">GL</TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueCredit
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxSSTMappingCharges[i]?.revenueCredit ||
                  state?.TaxSSTMappingCharges[i]?.outletTaxGLAcct?.revenueCredit
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueCredit
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxSSTMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueCredit = event.target.value

                  dispatch({
                    type: 'TaxSSTMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
        </TableRow>
      ))}

      {/* VAT */}

      {state?.TaxVATMappingCharges?.length > 0 ?
        <TableRow className="mapping-tr">
          <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
            <span className="xsTitle ">VAT</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle  title-row">-</span>{' '}
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>{' '}
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
        </TableRow>
        : null}

      {state?.TaxVATMappingCharges?.map((y, i) => (
        <TableRow className="mapping-tr" key={i}>
          <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
            {y.Description}
          </TableCell>

          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueDiv || 'Balance Sheet Division'
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxVATMappingCharges[i]?.revenueDiv ||
                  state?.TaxVATMappingCharges[i]?.outletTaxGLAcct?.revenueDiv
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueDiv || 'Balance Sheet Division'
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxVATMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueDiv = event.target.value

                  dispatch({
                    type: 'TaxVATMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueDept || 'Balance Sheet Department'
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxVATMappingCharges[i]?.revenueDept ||
                  state?.TaxVATMappingCharges[i]?.outletTaxGLAcct?.revenueDept
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueDept || 'Balance Sheet Department'
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxVATMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueDept = event.target.value

                  dispatch({
                    type: 'TaxVATMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
          <TableCell align="left">GL</TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            {state.mode === 'edit' ? (
              y.outletTaxGLAcct?.revenueCredit
            ) : (
              <TextField
                id="outlined-basic"
                value={
                  state?.TaxVATMappingCharges[i]?.revenueCredit ||
                  state?.TaxVATMappingCharges[i]?.outletTaxGLAcct?.revenueCredit
                }
                placeholder={
                  y.outletTaxGLAcct?.revenueCredit
                }
                className="mapping-textfield"
                variant="outlined"
                onChange={event => {
                  const newArr: any = state.TaxVATMappingCharges
                  let selected = newArr[i]?.outletTaxGLAcct
                  selected.revenueCredit = event.target.value

                  dispatch({
                    type: 'TaxVATMappingCharges',
                    payload: newArr,
                  })
                }}
              />
            )}
          </TableCell>
        </TableRow>
      ))}
    </>
  )
}