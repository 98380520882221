import React from 'react';
import { TaxSetupListing } from './TaxSetupListing';
import { TaxSetupForm } from './TaxSetupForm';
import { TaxSetupDetail } from './TaxSetupDetail';

const taxSetupRoutes = [
	{
		props: {
			exact: true,
			path: '/menu/outlet-app/common-setting/tax-policy',
		},
		component: <TaxSetupListing />,
	},

	{
		props: {
			exact: true,
			path: '/menu/outlet-app/common-setting/tax-policy/view/:taxId',
		},
		component: <TaxSetupDetail />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/common-setting/tax-policy/add`,
		},
		component: <TaxSetupForm mode="Add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/common-setting/tax-policy/:taxsubId/edit`,
		},
		component: <TaxSetupForm mode="Edit" />,
	},
];

export default taxSetupRoutes;
