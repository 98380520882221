import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Overlay } from '@ifca-root/react-component/src/components/Overlay/Overlay';
import {
	Grid,
	List,
	ListItem,
	ListItemText,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import IndeterminateCheckBoxIcon from '@material-ui/icons/IndeterminateCheckBox';
import { BillFooter } from 'components/Footer/BillFooter';
import { OrderItemList } from 'components/Order/OrderItemList';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CommonStatus,
	GetAllOrderItemDocument,
	OrderItemStatus,
	OrderStatus,
	PaymentClass,
	useGetCurrentMealPeriodLazyQuery,
	useGetDefaultPaymentQuery,
	useGetOrderQuery,
	useGetOutletNameQuery,
	useGetReasonLazyQuery,
	useGetAllOrderItemLazyQuery,
	useGetUsersByAccountAndSoftwareQuery,
	useVoidOrderItemWithOptionsMutation,
	useVoidOrderItemWithSetMenuOptionsMutation,
	usePostPrintDataMutation,
	OrderDiscountStatus,
	OrderItemDiscountStatus,
	GetOrderDocument,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { SystemMsgs } from 'helpers/SystemMsg';
import * as htmlToImage from 'html-to-image';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { AuditDialog } from '../AuditLog/AuditDialog';
import {
	printReceipt,
	printReceiptText,
} from '../BillSettlement/BillReceipt/BillReceiptFunctions';
import { OrderItemReceipt } from './OrderReceipt/OrderItemReceipt';

export interface PaymentItemProps {
	key?: typeof String;
	receivedAmount: any;
	cardType: String;
	cardNo: Number;
	qty: number;
}

interface OrderItemTax {
	taxAmount: number;
	taxSchemeType: string;
}

interface OrderItemOptionItemTax {
	taxAmount: number;
}

interface OrderItemDiscountTax {
	taxAmount: number;
}

interface OrderItemOptionItemDiscountTax {
	taxAmount: number;
}

interface OrderDiscountItemTax {
	taxAmount: number;
}
interface orderDiscountItemOptionTax {
	taxAmount: number;
}

interface OrderDiscountItem {
	orderItemID?: string;
	baseAmount: number;
	orderDiscountItemTax?: OrderDiscountItemTax[];
}

interface OrderItemDiscount {
	orderItemID?: string;
	baseAmount: number;
	status: string;
	orderItemDiscountTax: OrderItemDiscountTax[];
}

interface OrderItemOptionItem {
	optionItemID: string;
	price: number;
	basePrice: number;
	unitPrice: number;
	orderItemOptionItemTax: OrderItemOptionItemTax[];
	discountBaseAmount: number;
	orderItemOptionItemDiscountTax: OrderItemOptionItemDiscountTax[];
	orderDiscountItemOptionTax: orderDiscountItemOptionTax[];
	// Include other properties if needed
}

interface OrderItemOption {
	optionID: string;
	orderItemOptionItem: OrderItemOptionItem[];
	// Include other properties if needed
}
interface Item {
	ID: string;
	amount: number;
	orderItemTax: OrderItemTax[];
	orderItemDiscount?: OrderItemDiscount;
	orderDiscountItem?: OrderDiscountItem;
	orderItemOption?: OrderItemOption[];
}

export const ConfirmOrder = (props: any) => {
	useEffect(() => {
		console.log('ConfirmOrder Outlet App');
	}, []);
	const history = useHistory();
	const { mode } = props;
	const { tableID, orderID, outletID, menuCategoryID } = useParams<
		Record<string, any>
	>();

	const { globalState }: any = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { anchorEl, handleClick, handleClose } = useMenuOption();

	const [deleteDialog, setDeleteDialog] = useState(false);
	const [forDelete, setForDelete] = useState(null);
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);
	const [openDialog, setOpenDialog] = useState(false);
	const [isSubmitting, setIsSubmitting] = useState(false);

	const [openAuditDialog, setOpenAuditDialog] = useState(false);

	const { handlePermDisabled } = usePermissionChecker();

	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	const {
		anchorEl: anchorElDialog,
		menu,
		handleClick: handleClickDialog,
		handleClose: handleCloseDialog,
	} = useMenuOption();

	const handleChangeOrder = (e, obj) => {
		if (obj?.ID !== orderID) {
			history.push({
				pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${obj?.ID}/menu-category/${menuCategoryID}/confirm-order`,
			});
			loadOrderItem({
				variables: {
					orderID: obj?.ID,
				},
			});
		}
	};

	const [deductionQuantity, setDeductionQuantity] = useState(1);
	// const [quantity, setQuantity] = useState(1);

	const clickButton = (mode: string) => {
		if (mode === 'add') {
			if (
				deductionQuantity ===
				menu?.obj?.quantity - (negOrderItem?.[menu?.obj?.ID]?.quantity ?? 0)
			) {
				setDeductionQuantity(menu?.obj?.quantity);
				setValue('qty', menu?.obj?.quantity);
				// setQuantity(menu?.obj?.quantity);
			} else {
				setDeductionQuantity(deductionQuantity + 1);
				setValue('qty', deductionQuantity + 1);

				// setQuantity(deductionQuantity + 1);
			}
		} else {
			if (deductionQuantity === 1) {
				setDeductionQuantity(1);
				setValue('qty', 1);
			} else {
				setDeductionQuantity(deductionQuantity - 1);
				setValue('qty', deductionQuantity - 1);
			}
		}
	};
	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		voidOrderItemWithOptions,
		{
			data: voidOrderItemWithOptionsData,
			loading: voidOrderItemWithOptionsLoading,
		},
	] = useVoidOrderItemWithOptionsMutation({
		onError: error => {
			console.log('Suberror', error);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			if (error.message.includes('This table')) {
				history.push(`/menu/outlet-app/${outletID}/table/grid`);
			} else {
				history.push(`/menu/outlet-app/${outletID}/table/grid/${tableID}`);
			}
		},
		onCompleted: data => {
			loadOrderItem({
				variables: {
					orderID: orderID,
				},
			});
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecord());
			setIsSubmitting(false);
		},
	});

	const [
		voidOrderItemWithSetMenuOptions,
		{
			data: voidOrderItemWithSetMenuOptionsData,
			loading: voidOrderItemWithSetMenuOptionsLoading,
		},
	] = useVoidOrderItemWithSetMenuOptionsMutation({
		onError: error => {
			console.log('Suberror', error);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			if (error.message.includes('This table')) {
				history.push(`/menu/outlet-app/${outletID}/table/grid`);
			} else {
				history.push(`/menu/outlet-app/${outletID}/table/grid/${tableID}`);
			}
		},
		onCompleted: data => {
			loadOrderItem({
				variables: {
					orderID: orderID,
				},
			});
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecord());
			setIsSubmitting(false);
		},
	});

	const onSubmit = (data, blob) => {
		if (menu?.obj?.menuItem?.isSetMenu === false) {
			voidOrderItemWithOptions({
				variables: {
					input: {
						orderItemID: menu?.ID,
						deductionQuantity: deductionQuantity,
						reasonCode: data?.reasonCode,
						remark: data?.remark,
					},
					file: blob,
				},
				refetchQueries: [
					{
						query: GetAllOrderItemDocument,
						variables: { orderID },
					},
					{
						query: GetOrderDocument,
						variables: {
							...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
							status: OrderStatus.Open,
							outletID,
						},
					},
				],
			});
		} else if (menu?.obj?.menuItem?.isSetMenu === true) {
			voidOrderItemWithSetMenuOptions({
				variables: {
					input: {
						orderItemID: menu?.ID,
						deductionQuantity: deductionQuantity,
						reasonCode: data?.reasonCode,
						remark: data?.remark,
					},
				},
				refetchQueries: [
					{
						query: GetAllOrderItemDocument,
						variables: { orderID },
					},
					{
						query: GetOrderDocument,
						variables: {
							...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
							status: OrderStatus.Open,
							outletID,
						},
					},
				],
			});
		}
		setOpenDialog(false);
	};

	const [negOrderItem, setNegOrderItem] = useState({});
	const [itemArray, setItemArray] = useState([]);
	const [
		loadOrderItem,

		{
			loading: useGetAllOrderItemLoading,
			called: useGetAllOrderItemCalled,
			data: { getAllOrderItem } = {
				getAllOrderItem: [],
			},
			fetchMore,
		},
	] = useGetAllOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getAllOrderItem }) => {
			let unpaidOrderItem = getAllOrderItem?.filter(
				item => item?.billID === null,
			);
			let temp = {};
			unpaidOrderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				activeOID?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItem?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};

						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;

							let optionData;

							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});
			setNegOrderItem({ ...temp });
			setItemArray(
				unpaidOrderItem
					?.map(el => {
						if (
							el?.quantity - temp[el?.ID]?.quantity === 0 ||
							el?.quantity <= 0
						) {
							return { ...el, void: true };
						} else {
							return { ...el, void: false };
						}
					})
					?.filter(list => list.void === false),
				// ?.filter(list2 => list2.quantity > 0), // CAUSE HERE WE WANNA SEE THE SLASH2 ONE
			);
		},
	});

	const [auditArray, setAuditArray] = useState([]);

	useEffect(() => {
		setAuditArray(
			getAllOrderItem?.filter(
				x =>
					x?.status === OrderItemStatus?.Voided ||
					x?.status === OrderItemStatus?.TransferIn ||
					x?.status === OrderItemStatus?.TransferOut,
			),
		);
	}, [getAllOrderItem]);

	const {
		called: orderCalled,
		loading: orderLoading,
		error: orderError,
		data: { getOrder } = { getOrder: [] },
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
			status: OrderStatus.Open,
			outletID,
		},
	});

	const [selectedOrder, setSelectOrder] = useState(
		getOrder?.filter(order => order?.ID === orderID)[0],
	);

	useEffect(() => {
		setSelectOrder(getOrder?.filter(order => order?.ID === orderID)[0]);
	}, [getOrder, orderID]);

	const orderItems = getOrder[0]?.orderItem
		?.map(el => {
			if (
				el?.quantity - negOrderItem?.[el?.ID]?.quantity === 0 ||
				el?.quantity < 0
			) {
				return { ...el, void: true };
			} else {
				return { ...el, void: false };
			}
		})
		?.filter(list => list.void === false);

	let ttl,
		subtotal,
		finalTax,
		total,
		discount,
		totalOptItm,
		discOptItm,
		optTaxAmount,
		optServiceCharge,
		discOptTaxAmount,
		discOptServiceCharge,
		orderDiscount;
	if (orderItems?.length > 0) {
		const itemArray = getAllOrderItem;

		//convert and fetch important data

		const filterItemArray: {
			ID: string;
			amount: number;
			orderItemTax: OrderItemTax[];
			orderItemDiscount?: OrderItemDiscount;
			orderDiscountItem?: OrderDiscountItem;
			orderItemOption?: OrderItemOption[];
		}[] = itemArray.map(
			({
				ID,
				amount,
				orderItemTax,
				orderItemDiscount,
				orderDiscountItem,
				orderItemOption,
			}) => {
				const activeOID = orderItemDiscount?.filter(
					x => x?.status === OrderItemDiscountStatus.Active,
				)[0];

				return {
					ID,
					amount,
					orderItemTax: orderItemTax?.map(({ taxAmount, taxSchemeType }) => ({
						taxAmount,
						taxSchemeType,
					})),
					orderItemDiscount: {
						baseAmount: activeOID?.baseAmount ?? 0,
						status: activeOID?.status ?? '',
						orderItemID: ID,
						orderItemDiscountTax: activeOID
							? activeOID?.orderItemDiscountTax?.map(({ taxAmount }) => ({
									taxAmount,
							  }))
							: [{ taxAmount: 0 }],
					},
					orderDiscountItem: {
						baseAmount: orderDiscountItem?.baseAmount ?? 0,
						orderItemID: ID,
						orderDiscountItemTax: orderDiscountItem?.orderDiscountItemTax
							? orderDiscountItem?.orderDiscountItemTax?.map(
									({ taxAmount }) => ({
										taxAmount,
									}),
							  )
							: [{ taxAmount: 0 }],
					},
					orderItemOption: orderItemOption?.map(
						({ optionID, orderItemOptionItem }) => ({
							optionID,
							orderItemOptionItem: orderItemOptionItem.map(
								({
									optionItemID,
									price,
									basePrice,
									unitPrice,
									orderItemOptionItemTax,
									discountBaseAmount,
									orderItemOptionItemDiscountTax,
									orderDiscountItemOptionTax,
								}) => ({
									optionItemID,
									price,
									basePrice,
									unitPrice,
									orderItemOptionItemTax: orderItemOptionItemTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									discountBaseAmount,
									orderItemOptionItemDiscountTax: orderItemOptionItemDiscountTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									orderDiscountItemOptionTax: orderDiscountItemOptionTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									// Include other properties if needed
								}),
							),
							// Include other properties from OrderItemOption if needed
						}),
					),
				};
			},
		);

		//subtotal amount
		const sumAmount = (items: Item[]): number => {
			let totalBaseAmount = 0;
			for (const item of items) {
				totalBaseAmount += item.amount;
			}
			return totalBaseAmount;
		};

		const totalAmount = sumAmount(filterItemArray);

		//subtotal tax amount

		const sumTaxAmount = (items: Item[]): number => {
			let totalTax = 0;
			for (const item of items) {
				for (const tax of item?.orderItemTax) {
					totalTax += tax?.taxAmount;
				}
			}
			return totalTax;
		};

		const totalTaxAmount = sumTaxAmount(filterItemArray);
		const roundedTotalTaxAmount = parseFloat(totalTaxAmount.toFixed(2));

		//subtotal order discount item base amount

		const sumODIBaseAmount = (items: Item[]): number => {
			let totalODIBaseAmt = 0;
			for (const item of items) {
				totalODIBaseAmt += item?.orderDiscountItem?.baseAmount;
			}
			return totalODIBaseAmt;
		};

		const totalODIBaseAmount = sumODIBaseAmount(filterItemArray);

		//subtotal order discount item tax amount

		const sumODITaxAmount = (items: Item[]): number => {
			let totalTax = 0;
			for (const item of items) {
				for (const tax of item?.orderDiscountItem?.orderDiscountItemTax)
					totalTax += tax?.taxAmount;
			}
			return totalTax;
		};

		const totalODITaxAmount = sumODITaxAmount(filterItemArray);
		const roundedTotalODITaxAmount = parseFloat(totalODITaxAmount.toFixed(2));

		//subtotal order item discount base amount

		const sumOIDBaseAmount = (items: Item[]): number => {
			let totalOIDBaseAmt = 0;
			for (const item of items) {
				totalOIDBaseAmt += item?.orderItemDiscount?.baseAmount;
			}
			return totalOIDBaseAmt;
		};

		const totalOIDBaseAmount = sumOIDBaseAmount(filterItemArray);

		//subtotal order item discount tax amount

		const sumOIDTaxAmount = (items: Item[]): number => {
			let totalOIDTax = 0;
			for (const item of items) {
				for (const tax of item?.orderItemDiscount?.orderItemDiscountTax)
					totalOIDTax += tax?.taxAmount;
			}
			return totalOIDTax;
		};

		const totalOIDTaxAmount = sumOIDTaxAmount(filterItemArray);
		const roundedTotalOIDTaxAmount = parseFloat(totalOIDTaxAmount.toFixed(2));

		//overall option item important field to sum up
		const sumOptionItemOverallField = (
			items: Item[],
		): {
			basePrice: number;
			taxAmount: number;
			discountBaseAmount: number;
			itemDiscountTaxAmount: number;
			discountItemTaxAmount: number;
		} => {
			let totalBasePriceOpt = 0;
			let totalTaxAmountOpt = 0;
			let totalDiscBaseAmountOpt = 0;
			let totalItemDiscTaxAmountOpt = 0;
			let totalDiscItemTaxAmountOpt = 0;
			for (const item of items) {
				if (item.orderItemOption) {
					for (const option of item.orderItemOption) {
						if (option.orderItemOptionItem) {
							for (const optionItem of option.orderItemOptionItem) {
								let totalTax = 0;
								optionItem?.orderItemOptionItemTax?.map(x => {
									totalTax += x?.taxAmount;
								});

								let totalOptItemOIDTax = 0;
								optionItem?.orderItemOptionItemDiscountTax?.map(x => {
									totalOptItemOIDTax += x?.taxAmount;
								});

								let totalOptItemODITax = 0;
								optionItem?.orderDiscountItemOptionTax?.map(v => {
									totalOptItemODITax += v?.taxAmount;
								});

								totalBasePriceOpt += optionItem.basePrice;
								totalTaxAmountOpt += totalTax;
								totalDiscBaseAmountOpt += optionItem.discountBaseAmount;
								totalItemDiscTaxAmountOpt += totalOptItemOIDTax;
								totalDiscItemTaxAmountOpt += totalOptItemODITax;
							}
						}
					}
				}
			}
			return {
				basePrice: totalBasePriceOpt,
				taxAmount: parseFloat(totalTaxAmountOpt.toFixed(2)),
				discountBaseAmount: totalDiscBaseAmountOpt,
				itemDiscountTaxAmount: parseFloat(totalItemDiscTaxAmountOpt.toFixed(2)),
				discountItemTaxAmount: parseFloat(totalDiscItemTaxAmountOpt.toFixed(2)),
			};
		};

		const totalOptItmOverallAmount = sumOptionItemOverallField(filterItemArray);

		optTaxAmount = totalOptItmOverallAmount?.taxAmount;

		discOptTaxAmount =
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		totalOptItm =
			totalOptItmOverallAmount?.basePrice + totalOptItmOverallAmount?.taxAmount;

		discOptItm =
			totalOptItmOverallAmount?.discountBaseAmount +
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		//tax
		finalTax =
			roundedTotalTaxAmount +
			optTaxAmount -
			roundedTotalODITaxAmount -
			roundedTotalOIDTaxAmount -
			discOptTaxAmount;

		//subtotal
		subtotal = totalAmount + roundedTotalTaxAmount + totalOptItm;

		//discount
		let ODI = totalODIBaseAmount + roundedTotalODITaxAmount;

		let OID = totalOIDBaseAmount + roundedTotalOIDTaxAmount;

		discount = ODI + OID + discOptItm;

		//total
		total = subtotal - discount;
	}

	//total
	// const total = subtotal + finalServiceCharge + finalTax;

	const [fs, setFS] = useState(null);
	const [ta, setTa] = useState(null);
	const [fd, setFD] = useState(null);
	const [to, setTo] = useState(null);

	useEffect(() => {
		setFS(subtotal);
		setTa(finalTax);
		setFD(discount);
		setTo(total);
	}, [subtotal, finalTax, discount, total]);

	useEffect(() => {
		if (!useGetAllOrderItemCalled) {
			loadOrderItem({
				variables: {
					orderID: orderID,
				},
			});
		}
	}, [useGetAllOrderItemCalled]);

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const [paymentDialog, setPaymentDialog] = useState(false);
	const [signature, setSignature] = useState<boolean>(false);
	const [cardDropdown, setCardDropdown] = useState(false);
	const [radioError, setRadioError] = useState(false);
	const [values, setValues] = useState();
	const [cashTextField, setCashTextField] = useState(false);

	const yupSchema = yup.object().shape({
		// receivedAmount: yup
		// 	.number()
		// 	// .integer()
		// 	.min(to, `Amount cannot be less than bill amount`)
		// 	.typeError('Amount cannot be empty')
		// 	.nullable(),
		// reasonCode: yup
		// 	.string()
		// 	.required('Reason code is required')
		// 	.trim(),
		// remark: yup
		// 	.string()
		// 	.required('Remark is required')
		// 	.trim(),
	});

	const {
		register,
		control,
		handleSubmit,
		errors,
		clearErrors,
		watch,
		setValue,
	} = useForm<PaymentItemProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const handleRadio = e => {
		setValue('defaultPaymentID', e.target.value);
	};

	const {
		called: paymentCalled,
		loading: paymentLoading,
		error: paymentError,
		data: { getDefaultPayment } = { getDefaultPayment: [] },
	} = useGetDefaultPaymentQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
	});

	const memberHotelRadioID = watch => {
		if (
			watch ===
			getDefaultPayment?.filter(x => x?.paymentClass === PaymentClass.Member)[0]
				?.ID
		) {
			return true;
		} else if (
			watch ===
			getDefaultPayment?.filter(x => x?.paymentClass === PaymentClass.Room)[0]
				?.ID
		) {
			return true;
		} else if (
			watch ===
			getDefaultPayment?.filter(
				x => x?.paymentClass === PaymentClass.NonGuest,
			)[0]?.ID
		) {
			return true;
		} else if (
			watch ===
			getDefaultPayment?.filter(
				x => x?.paymentClass === PaymentClass.SuspendFolio,
			)[0]?.ID
		) {
			return true;
		} else return false;
	};

	let cardType: any = watch('cardType');
	let cardNo: any = watch('cardReference');
	let receivedAmount: any = parseFloat(watch('receivedAmount') || to);

	//Kitchen Printer stuff
	//Kitchen Printer stuff
	//Kitchen Printer stuff

	const [
		loadReason,
		{
			loading: reasonLoading,
			error: reasonError,
			called: reasonCalled,
			data: { getReason } = { getReason: [] },
		},
	] = useGetReasonLazyQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	const [postPrintData] = usePostPrintDataMutation({
		fetchPolicy: 'network-only',
	});

	const [remark, setRemark] = useState(null);
	const [reasonCode, setReasonCode] = useState(null);

	const handlePrinting = async () => {
		let printerName = menu?.obj?.menuItem?.kitchenPrinter?.name;
		let orderItemIDs = menu?.ID;

		if (printerName) {
			await handleSubmit(onSubmit)().then(async result => {
				postPrintData({
					variables: {
						printData: {
							outletID,
							printerName,
							outletName: getOutlet[0]?.name,
							orderID: selectedOrder?.ID,
							orderItemIDs: [orderItemIDs],
							orderMode: 'void',
							voidQuantity: deductionQuantity,
							reasonCode: reasonCode,
							remark: remark,
						},
					},
				});
			});
		} else {
			await handleSubmit(onSubmit)();
		}
	};

	const getRoundedQty = qty1 => {
		let outstandingQuantity;
		if (negOrderItem[menu?.obj?.ID]?.quantity === undefined) {
			outstandingQuantity = menu?.obj?.quantity;
		} else {
			outstandingQuantity =
				menu?.obj?.quantity - negOrderItem[menu?.obj?.ID]?.quantity;
		}
		// =
		// 	menu?.obj?.quantity - negOrderItem[menu?.obj?.ID]?.quantity;
		let roundedQty =
			qty1 > outstandingQuantity ? outstandingQuantity : qty1 < 1 ? 1 : qty1;
		return roundedQty;
	};

	const onClickVoidIcon = (e, orderItemID, index, orderItem) => {
		setOpenDialog(true);
		loadReason({
			variables: {
				outletID: outletID,
			},
		});
		handleClickDialog(e, orderItemID, index, orderItem);
	};

	const listItemDisplayFn = (el, index) => {
		return (
			<ListItem>
				<ListItemText
					primary={
						<>
							<Grid container>
								{/* <Grid item xs={1}>
									<span className="mdDesc" style={{ paddingRight: '12px' }}>
										{index + 1 + '.'}
									</span>
								</Grid> */}
								<Grid
									item
									xs={4}
									// xs={3}
								>
									<span className="xsTitle flex-space">
										{el?.openItemName ?? el.menuItem?.name}&nbsp; &nbsp;
										{'x'}
										{el?.quantity * -1}
									</span>
								</Grid>
								<Grid item xs={8}>
									<span className="xsTitle right-info m-t-6">{el?.status}</span>
								</Grid>
							</Grid>
						</>
					}
					secondary={
						<>
							<Grid container>
								<Grid item xs={4}>
									<span
										className="desc"
										// style={{ marginLeft: '20px' }}
										// style={{ marginLeft: '39px' }}
									>
										{el?.reasonCode}
									</span>
								</Grid>
								<Grid item xs={8}>
									<span
										className="desc right-info"
										style={{
											padding: '5px 0px 0px 4px',
										}}
									>
										{
											getUsersByAccountAndSoftware?.filter(
												x => x?.ID === el?.modBy || x?.ID === el?.createdBy,
											)[0]?.name
										}
									</span>
								</Grid>
							</Grid>
						</>
					}
				/>
			</ListItem>
		);
	};

	const color = (fields, loading) => {
		let color: any = 'primary';

		if (fields === true && loading === false) {
			color = 'inherit';
		} else if (fields === false && loading === false) {
			color = 'primary';
		} else if (fields === false && loading === true) {
			color = 'inherit';
		}

		return color;
	};

	const disable = (fields, loading) => {
		let disable: any = false;

		if (fields === true && loading === false) {
			disable = true;
		} else if (fields === false && loading === false) {
			disable = false;
		} else if (fields === false && loading === true) {
			disable = true;
		}

		return disable;
	};

	return (
		<>
			{useGetAllOrderItemLoading && <Loading />}
			{outletLoading && <Loading />}
			{orderLoading && <Loading />}
			{paymentLoading && <Loading />}
			{voidOrderItemWithOptionsLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{voidOrderItemWithSetMenuOptionsLoading && <Loading />}

			<MainHeader
				onClick={() => {
					if (
						mode === 'dineIn' &&
						(getOrder[0]?.orderItem.length <= 0 ||
							getOrder[0]?.orderItem.length === null)
					) {
						history.push({
							pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/menu-category/${menuCategoryID}/summary`,
						});
					} else if (mode === 'dineIn') {
						history.push(`/menu/outlet-app/${outletID}/table/grid/${tableID}`);
					} else if (mode === 'takeAway') {
						history.push(`/menu/outlet-app/${outletID}/takeaway`);
					}
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				// currency={
				// 	<span className="xxTitle flex-space" style={{ color: 'white' }}>
				// 		Table
				// 		<span className="title flex-space" style={{ color: 'orange' }}>
				// 			{' ' + selectedOrder?.table?.tableNumber}
				// 		</span>
				// 	</span>
				// }
				//"Table 03 (HARDCODE)"
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Confirm Order', current: true },
				]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${currentMealPeriod?.name} ${formatTime(
									currentMealPeriod?.startTime,
							  )} - ${formatTime(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<OrderSubheader
				mode={mode}
				getOrderLoading={orderLoading}
				getOrder={getOrder}
				selectedOrder={selectedOrder}
				orderID={selectedOrder?.ID}
				handleChangeOrder={handleChangeOrder}
				outletID={outletID}
				// isAuditDialog={true}
				// setOpenAuditDialog={setOpenAuditDialog}
			/>

			<ContentWrapper
				singleDynamicInfo
				multiLineSubheader={mode === 'takeAway' ? true : false}
			>
				{!useGetAllOrderItemLoading &&
				!outletLoading &&
				!orderLoading &&
				!paymentLoading &&
				!getCurrentMealPeriodLoading &&
				(getAllOrderItem === undefined || itemArray?.length === 0) ? (
					<EmptyList
						title="There are no orders found"
						subtitle="Please make a new order in the Menu "
					/>
				) : (
					<>
						<List
							style={{ backgroundColor: 'white', color: 'black', zIndex: 40 }}
						>
							<OrderItemList
								outletID={outletID}
								itemArray={itemArray}
								negOrderItem={negOrderItem}
								rightIcon="void"
								rightAction={onClickVoidIcon}
								page="confirm-order"
								currencyCode={getOutlet[0]?.currencyCode ?? ''}
							/>
							<ListItem style={{ paddingRight: '0px' }}>
								<BillFooter
									style={{
										width:
											// isDesktop && globalState.drawerOpen
											// 	? 'calc(100% - 310px)'
											// 	: isDesktop && !globalState.drawerOpen
											// 	? 'calc(100% - 75px)'
											// 	: '95%',
											'95%',
										paddingLeft: '1%',
										// left: !isDesktop && !globalState.drawerOpen ? 0 : null,
										// zIndex: 40,
									}}
									page="confirm-order"
									isConfirmOrder
									subtotal={fs}
									tax={ta}
									discount={fd}
									total={to}
									currencyCode={getOutlet[0]?.currencyCode ?? ''}
									outletID={outletID}
									taxSchemeInfo={selectedOrder?.taxSchemeInfo}
									taxSchemeFooterInfo={selectedOrder?.taxSchemeFooterInfo}
									orderID={selectedOrder?.ID}
								/>
							</ListItem>
						</List>
					</>
				)}
			</ContentWrapper>
			<div style={{ marginBottom: '100px' }}></div>

			<AuditDialog
				openAuditDialog={openAuditDialog}
				setOpenAuditDialog={setOpenAuditDialog}
				auditArray={auditArray}
				listItemDisplayFn={listItemDisplayFn}
			/>

			{mode === 'dineIn' && (
				<FloatButton
					onClick={() => {
						localStorage.removeItem('searchFilter');

						history.push(
							`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}`,
						);
					}}
				/>
			)}

			<CommonDialog
				fullWidth={true}
				open={openDialog}
				onClose={() => {
					setOpenDialog(false);
					setDeductionQuantity(1);
					setReasonCode(null);
					setRemark(null);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Void Item</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="mdDesc">
								<Grid container className="mdDesc" style={{ marginTop: '5px' }}>
									<Grid item xs={6} className="flex-space">
										<span style={{ fontWeight: 'bold' }}>
											{`${menu?.obj?.menuItem?.name} x${
												deductionQuantity === undefined ? 0 : deductionQuantity
											}`}
										</span>
										{menu?.obj?.orderItemOption
											? menu?.obj?.orderItemOption?.map(v => (
													<>
														{v?.orderItemOptionItem?.map(e => (
															<div
																className="desc"
																style={{ paddingTop: '1px' }}
															>
																{e?.optionItem?.name}
															</div>
														))}
													</>
											  ))
											: menu?.obj?.orderItemSetMenuOption?.map(v => (
													<>
														{v?.orderItemSetMenuOptionItem?.map(e => (
															<div
																className="desc"
																style={{ paddingTop: '1px' }}
															>
																{e?.setMenuOptionItem?.menuItem?.name}
															</div>
														))}
													</>
											  ))}
									</Grid>
									<Grid
										item
										xs={6}
										style={{
											textAlign: 'right',
											paddingRight: '10px',
											fontSize: '15px',
										}}
									>
										<IndeterminateCheckBoxIcon
											style={{
												fontSize: '29px',
												paddingInline: '10px',
											}}
											className="order-minus"
											onClick={() => {
												clickButton('reduce');
											}}
										/>
										{/* <span
											className="order-quantity"
											style={{
												fontSize: '25px',
												position: 'relative',
												bottom: '5px',
											}}
										>
											{deductionQuantity}
										</span> */}

										<Controller
											render={({ onChange, onBlur, value }) => (
												<NumberFormat
													customInput={TextField}
													autoComplete="off"
													isNumericString
													variant="standard"
													thousandSeparator
													fullWidth={false}
													//margin="dense"
													className="void-qty"
													InputProps={{
														disableUnderline: true,
													}}
													onValueChange={(value: any) => {
														setDeductionQuantity(
															getRoundedQty(value?.floatValue),
														);
														setValue('qty', getRoundedQty(value?.floatValue));
													}}
													style={{
														width: isDesktop ? '25%' : '30%',
														borderRadius: '8px',
														border: 'solid',
														borderColor: 'orange',
														marginTop: '16px',
													}}
													// variant="outlined"
													defaultValue={deductionQuantity}
													value={watch('qty')}
												/>
											)}
											name="qty"
											control={control}
											ref={register}
										/>
										<AddBox
											style={{
												fontSize: '29px',
												color: '#ff9800',
												paddingInline: '10px',
											}}
											className="order-add"
											onClick={() => {
												clickButton('add');
											}}
										/>
									</Grid>
									<Grid item xs={12}>
										<Controller
											as={
												<TextField
													select
													// size="small"
													label="Reason"
													// InputLabelProps={{ style: { fontSize: 12 } }}
												>
													{getReason?.map((x, index) => (
														<MenuItem
															key={index}
															value={x?.name}
															onClick={e => {
																setReasonCode(x?.name);
															}}
														>
															{x?.name}
														</MenuItem>
													))}
												</TextField>
											}
											name="reasonCode"
											autoComplete="off"
											control={control}
											multiline
											fullWidth
											required
											margin="normal"
										></Controller>
									</Grid>
									<Grid item xs={12}>
										<Controller
											render={({ onChange, value }) => (
												<TextField
													name="remark"
													label="Remark"
													autoComplete="off"
													onChange={e => setRemark(e.target.value)}
													multiline
													fullWidth
													ref={register}
													margin="normal"
													required
												/>
											)}
											name="remark"
											control={control}
										/>
									</Grid>
								</Grid>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenDialog(false);
										setDeductionQuantity(1);
										setReasonCode(null);
										setRemark(null);
									},
									variant: 'contained',
									color: 'primary',
								},
							},

							{
								displayText: 'Confirm',
								props: {
									onClick: async e => {
										setIsSubmitting(true);
										!isSubmitting && (await handlePrinting());
										// handleSubmit(onSubmit)();
										setDeductionQuantity(1);
										e.stopPropagation();
									},
									variant: 'contained',
									color: color(
										(reasonCode === null && remark === null) ||
											reasonCode === null ||
											remark === null,
										voidOrderItemWithOptionsLoading ||
											voidOrderItemWithSetMenuOptionsLoading,
									),
									// deductionQuantity < 1 &&
									// reasonCode === null &&
									// remark === null &&
									// remark === '' &&
									// voidOrderItemWithOptionsLoading === false &&
									// voidOrderItemWithSetMenuOptionsLoading === false
									// 	? //   ||
									// 	  // voidOrderItemWithOptionsLoading ||
									// 	  // voidOrderItemWithSetMenuOptionsLoading
									// 	  'inherit'
									// 	: 'primary',
									disabled: disable(
										(reasonCode === null && remark === null) ||
											reasonCode === null ||
											remark === null,
										voidOrderItemWithOptionsLoading ||
											voidOrderItemWithSetMenuOptionsLoading,
									),

									// isSubmitting ||
									// deductionQuantity < 1 ||
									// deductionQuantity === undefined ||
									// reasonCode === null ||
									// remark === null ||
									// remark === '' ||
									// voidOrderItemWithOptionsLoading ||
									// voidOrderItemWithSetMenuOptionsLoading,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
