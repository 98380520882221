import React from 'react';
import { ConfirmOrder } from './ConfirmOrder';
import { AddMenuList } from './MenuList';
import { Order } from './Order';
import { OrderSummary } from './OrderSummary';

const addEditOrderRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID`,
		},
		component: <AddMenuList mode="dineIn" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/order/:orderID/takeaway`,
		},
		component: <AddMenuList mode="takeAway" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/menu-category/:menuCategoryID`,
		},
		component: <Order mode="dineIn" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/order/:orderID/menu-category/:menuCategoryID/takeaway`,
		},
		component: <Order mode="takeAway" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/menu-category/:menuCategoryID/summary`,
		},
		component: <OrderSummary mode="dineIn" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/order/:orderID/menu-category/:menuCategoryID/summary/takeaway`,
		},
		component: <OrderSummary mode="takeAway" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/menu-category/:menuCategoryID/confirm-order`,
		},
		component: <ConfirmOrder mode="dineIn" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/confirm-order`,
		},
		component: <ConfirmOrder mode="dineIn" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/order/:orderID/confirm-order/takeaway`,
		},
		component: <ConfirmOrder mode="takeAway" />,
	},
];

export default addEditOrderRoutes;
