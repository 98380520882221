import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	CircularProgress,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import { convertDate } from 'helpers/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { Filter } from 'helpers/searchFilter';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

interface AuditLogListingProps {
	queryData: any;
	queryLoading?: any;
	dropdownOptions: any[];
	dropdownValue?: {
		displayValue: string;
		tableName: string;
		fieldID: string;
		subDisplayValue: any;
	};
	setDropdownValue?: any;
	infiniteScroll: {
		next: any;
	};
	filterVariables?: string[];
	hasSubheader?: boolean;
}

const AuditLogListing = (props: AuditLogListingProps) => {
	const {
		queryData,
		queryLoading,
		dropdownOptions,
		dropdownValue,
		setDropdownValue,
		infiniteScroll,
		filterVariables,
		hasSubheader,
	} = props;

	const [auditDialog, setAuditDialog] = useState(false);

	const [filterSearch, setFilterSearch] = useState<string>(
		localStorage.getItem('filterValue') || '',
	);

	window.onbeforeunload = event => {
		localStorage.removeItem('filterValue');
	};

	const newQueryData = queryData?.filter(
		i => i?.oldValue !== null || i?.newValue !== null,
	);

	const { menu, handleClick }: any = useMenuOption();

	return (
		<>
			<List
				id="dropdown-subheader"
				className="dropdown-subheader"
				style={{ borderRadius: '4px' }}
			>
				<ListItem>
					<Grid container>
						<Grid item xs={12} component="span">
							<Autocomplete
								options={[...dropdownOptions]}
								fullWidth
								value={dropdownValue}
								getOptionLabel={option => option?.displayValue ?? ''}
								renderOption={option => {
									return (
										<div className="fs-14 display-flex flex-space m-t-4 m-b-4">
											<span className="flxe-space">{option.displayValue}</span>
											<span>{option?.subDisplayValue}</span>
										</div>
									);
								}}
								onChange={(_, value: any) => {
									setDropdownValue(value);
								}}
								renderInput={params => (
									<TextField
										{...params}
										fullWidth
										style={{ width: '100%' }}
										required
										label="Option"
										margin="normal"
									/>
								)}
							/>
						</Grid>
					</Grid>
				</ListItem>
			</List>

			<ContentWrapper auditLogSubheaderSearch={hasSubheader ? true : false}>
				<SearchHeader
					title="Search here"
					value={`${newQueryData?.length ?? 0}`}
					onChangeAction={e => {
						setFilterSearch(e.target.value);
						localStorage.setItem('filterValue', e.target.value);
					}}
					search
					onCloseAction={e => {
						setFilterSearch('');
						localStorage.remove('filterValue');
					}}
				/>

				{newQueryData?.length === 0 ? (
					<EmptyList title="No Records Found" />
				) : (
					<List className="core-list rm-padding-infinitescroll">
						<InfiniteScroll
							dataLength={newQueryData?.length}
							next={infiniteScroll?.next}
							hasMore={true}
							style={{ padding: '8px 6px' }}
							loader={
								queryLoading && (
									<div style={{ textAlign: 'center' }}>
										<CircularProgress />
									</div>
								)
							}
							endMessage={
								<p style={{ textAlign: 'center' }}>
									<b>-</b>
								</p>
							}
						>
							{Filter(newQueryData, filterSearch, [...filterVariables])?.map(
								(el, index) => {
									return (
										<ListItem>
											<ListItemText
												primary={
													<>
														<span className="xsTitle flex-space">
															{el?.fieldName}
														</span>
														<span className="xsTitle right-info">
															{convertDate(el?.actionDate)}
														</span>
													</>
												}
												secondary={
													<>
														<span className="extra-row">
															<span className="icon-text desc text-overflow flex-space">
																{el?.modByUserName || el?.createdByUserName}
															</span>
															<span className="icon-text desc text-overflow right-info">
																{el?.action}
															</span>
														</span>
														<span className="extra-row">
															<span
																style={{ fontWeight: 'bold' }}
																className="desc"
															>
																{'Old value:'}
															</span>
															<span className="icon-text desc text-overflow">
																&nbsp;
																{el?.oldValue}
															</span>
														</span>
														<span className="extra-row">
															<span
																style={{ fontWeight: 'bold' }}
																className="desc"
															>
																{'New value:'}
															</span>
															<span className="icon-text desc text-overflow">
																&nbsp;
																{el?.newValue}
															</span>
														</span>
													</>
												}
											/>
											<ListItemSecondaryAction style={{ top: '30px' }}>
												<IconButton
													edge="end"
													onClick={e => {
														setAuditDialog(true);
														handleClick(e, el?.ID, index, el);
													}}
												>
													<KeyboardArrowRight />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									);
								},
							)}
						</InfiniteScroll>
					</List>
				)}
			</ContentWrapper>

			<CommonDialog
				fullWidth={true}
				open={auditDialog}
				onClose={() => setAuditDialog(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{`Audit Log Details`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="content-wrap left">
								<div className="desc">User Name</div>
								<div className="xxTitle">
									{menu?.obj?.modByUserName || menu?.obj?.createdByUserName}
								</div>
							</div>
							<Grid container>
								<Grid item xs={6}>
									<div className="content-wrap right">
										<div className="desc">Change Date</div>
										<div className="xxTitle">
											{convertDate(menu?.obj?.actionDate)}
										</div>
									</div>
								</Grid>
								<Grid item xs={6}>
									<div className="content-wrap left">
										<div className="desc">Action</div>
										<div className="xxTitle">{menu?.obj?.action}</div>
									</div>
								</Grid>
							</Grid>

							<div className="content-wrap right">
								<div className="desc">Field Name</div>
								<div className="xxTitle">{menu?.obj?.fieldName}</div>
							</div>
							<Grid container>
								<Grid item xs={6}>
									<div className="content-wrap left">
										<div className="desc">Old Value</div>
										{/* check balik */}
										<div className="xxTitle">{menu?.obj?.oldValue}</div>
									</div>
								</Grid>
								<Grid item xs={6}>
									<div className="content-wrap right">
										<div className="desc">New Value</div>
										{/* check balik */}
										<div className="xxTitle">{menu?.obj?.newValue}</div>
									</div>
								</Grid>
							</Grid>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => setAuditDialog(false),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};

export default AuditLogListing;
