import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import React, { useEffect, useState } from 'react';

export const GeneralDeleteDialog = (props: any) => {
	const {
		ID,
		menu,
		deleteID,
		outletID,
		openDialog,
		setOpenDialog,
		dialogName,
		deleteMutation,
		refetchID, // pass only if refetchDocument is passed in
		refetchDocument, // use only if only need one refetch query
		refetchQueries, // use if require multiple refetch queries
	} = props;

	const [confirm, isConfirm] = useState(false);
	useEffect(() => {
		if (confirm) {
			deleteMutation({
				variables: !deleteID
					? {
							ID: ID,
					  }
					: {
							...deleteID,
					  },
				...(refetchQueries || refetchID // only refetch if passed either one
					? {
							refetchQueries: refetchQueries
								? refetchQueries
								: refetchID
								? [{ query: refetchDocument, variables: { ...refetchID } }]
								: [{ query: refetchDocument, variables: { outletID } }],
					  }
					: null),
			});
			isConfirm(false);
		}
	}, [confirm]);

	return (
		<>
			<CommonDialogV2
				fullWidth
				// style={{ textTransform: 'capitalize' }}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title color-primary-orange">{`Delete ${dialogName}`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<div>
							<span className="mdDesc">Confirm deletion?</span>
						</div>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenDialog(false);
										isConfirm(true);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
