import accountRoutes from 'containers/AccountModule/AccountRoutes';
import homeRoutes from 'containers/HomeModule/HomeRoutes';
import kitchenRoutes from 'containers/KitchenModule/KitchenRoutes';
import outletRoutes from 'containers/OutletModule/OutletRoutes';
import printerServerRoutes from 'containers/PrinterServerModule/PrinterServerRoutes';
import React from 'react';
import { Redirect } from 'react-router-dom';

const redirectRoute = [
	{
		props: { exact: true, path: '/' },
		component: <Redirect to="/" />,
	},
];

const errorRoute = [
	{
		// props: { exact: true, path: '/' },
		component: <Redirect to="/authentication/404" />,
	},
];

//error route always at the bottom//
const routes = [
	...accountRoutes,
	...homeRoutes,
	...kitchenRoutes,
	...printerServerRoutes,
	...outletRoutes,
	...redirectRoute,
	...errorRoute,
];

export default routes;
