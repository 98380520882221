import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Card,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetMealPeriodDocument,
	useDeleteMealPeriodMutation,
	useGetMealPeriodQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

// interface DialogFormFields {
// 	name: string;
// }

// const dialogFormFieldsInitialState: DialogFormFields = {
// 	name: '',
// };

export const MealPeriodList = () => {
	const history = useHistory();

	const { pathname } = useLocation();

	const { outletID } = useParams<any>();

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const [openDialog, setOpenDialog] = useState(false);
	const [openDeleteDialog, setDeleteDialog] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const {
		data: { getMealPeriod } = { getMealPeriod: [] },
		called: mealCalled,
		loading: mealLoading,
	} = useGetMealPeriodQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const [deleteMealPeriod] = useDeleteMealPeriodMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	return (
		<>
			{mealLoading && <Loading />}
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Meal Period', current: true },
				]}
			/>
			<ContentWrapper float>
				<List className="core-list">
					{!mealLoading &&
					(getMealPeriod === undefined || getMealPeriod?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add meal period to continue"
						/>
					) : (
						getMealPeriod
							?.slice()
							?.sort(function(a, b) {
								if (a.startTime < b.startTime) {
									return -1;
								}
								if (a.startTime > b.startTime) {
									return 1;
								}
								return 0;
							})
							?.map((el, index) => (
								<ListItem>
									<ListItemText
										primary={
											<>
												<span className="xsTitle flex-space">{el?.name}</span>
												{/* <span className="desc">{el?.isActive}</span> */}
												<span className="xxTitle highlight-text text-separator"></span>

												<span className="xxTitle highlight-text text-separator">
													{el.isActive ? (
														<Grid
															component={Card}
															style={{
																background: '#ff9800',
																padding: '.2rem .5rem .2rem .5rem ',
																boxShadow: 'none',
															}}
														>
															<Typography
																variant="caption"
																style={{
																	fontSize: '10px',
																	color: 'white',
																}}
															>
																Active
															</Typography>
														</Grid>
													) : null}
												</span>
											</>
										}
										secondary={
											<>
												<span className="desc">
													<span className="desc">
														{formatTime(el?.startTime)} -{' '}
														{formatTime(el?.endTime)}
													</span>
												</span>
											</>
										}
									/>

									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="more"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, el.ID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() =>
							history.push({
								pathname: `/menu/outlet-app/outlet-setting/${outletID}/meal-period/${menu.ID}/edit`,
								state: menu.obj,
							})
						}
					>
						<span className="">Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<FloatButton
				onClick={() =>
					history.push(
						`/menu/outlet-app/outlet-setting/${outletID}/meal-period/add`,
					)
				}
			/>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteMealPeriod}
				refetchDocument={GetMealPeriodDocument}
			/>
		</>
	);
};
