import DateFnsUtils from '@date-io/date-fns';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import {
	DragNDropList,
	DraggableItem,
	DropableItem,
} from '@ifca-root/react-component/src/components/CardList/DragAndDropList';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import { Grid, IconButton, TextField } from '@material-ui/core';
import { AddBox, Delete, InfoOutlined } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ComputeMethod } from 'generated/graphql';
import React from 'react';
import { v4 as uuidv4 } from 'uuid';

interface Props {
	state: any;
	dispatch: any;
	fields: any;
	append: any;
	remove: any;
	menu: any;
	fieldLength: any;
	setFieldLength: any;
	isSequenceTouched: any;
	setIsSequenceTouched: any;
	getTaxListingDropdown: any;
}

export const TaxSchemeFormDialog = (props: Props) => {
	const {
		state,
		dispatch,
		fields,
		append,
		remove,
		menu,
		fieldLength,
		setFieldLength,
		isSequenceTouched,
		setIsSequenceTouched,
		getTaxListingDropdown,
	} = props;

	let ComputeMethodTypeEnum: string[] = Object.values(ComputeMethod)?.map(x => {
		return x == ComputeMethod.TaxOnGross
			? 'Tax On Gross'
			: 'Tax On Taxed Price';
	});

	const itemSave = () => {
		dispatch({ type: 'isConfirm', payload: true });

		const existItem = state?.taxListing?.find(el => el?.id === menu?.ID);
		let newArr = state?.taxListing || [];

		if (existItem) {
			newArr = newArr?.filter(el => el?.id !== menu?.ID);
			const newList = {
				id: menu?.ID,
				effectiveDate: state?.effectiveDate,
				taxInfo: state?.taxArray,
			};
			newArr.push(newList);
			dispatch({ type: 'effectiveDate', payload: menu?.obj?.effectiveDate });
			fields?.forEach((x, index) => remove(index));
		} else {
			const newList = {
				effectiveDate: state?.effectiveDate,
				taxInfo: state?.taxArray,
			};
			newArr.push(newList);
		}

		dispatch({ type: 'taxListing', payload: newArr });
		dispatch({ type: 'addDialog', payload: false });
		dispatch({ type: 'isTouched', payload: true });
		fields?.forEach((x, index) => remove(index));
		dispatch({ type: 'isConfirm', payload: false });
		setFieldLength(0);
	};

	const onDragEnd = (result: any) => {
		const { destination, source, draggableId } = result;

		if (
			!destination ||
			(destination?.droppableId === source?.droppableId &&
				destination?.index === source?.index)
		) {
			return;
		}

		let taxOnSeq = state?.taxArray?.reduce((result, item) => {
			return result.concat(item?.sequenceNo);
		}, []);
		const isSourceExist = taxOnSeq?.find(x => Number(x) === source.index + 1);
		const isDestExist = taxOnSeq?.find(
			x => Number(x) === destination.index + 1,
		);
		const isPreviousExist = taxOnSeq?.find(
			x => Number(x) - 1 === source.index + 1,
		);

		let existArr: any = state?.taxArray;

		if (isSourceExist || isDestExist || isPreviousExist) {
			existArr = state?.taxArray
				?.map(x => {
					return {
						...x,
						computeMethod: '',
						sequenceNo: [],
					};
				})
				.sort((a, b) => a.oriIndex - b.oriIndex);
		} else {
			var itemToMove = existArr?.splice(source.index, 1)[0];
			existArr?.splice(destination.index, 0, itemToMove);
		}

		let newArr = state?.taxListing || [];

		const selected = state?.taxListing?.filter(x => x?.id === menu?.ID)[0];

		if (selected !== undefined) {
			const arr = {
				id: selected?.id,
				effectiveDate: selected?.effectiveDate,
				taxSchemeId: selected?.taxSchemeId,
				taxInfo: existArr,
			};
			newArr.push(arr);
		} else {
			const arr = {
				effectiveDate: state?.effectiveDate,
				taxInfo: existArr,
			};
			newArr.push(arr);
		}

		dispatch({ type: 'taxListing', payload: newArr });
	};

	const taxGroupBy = getTaxListingDropdown
		?.filter(x => x?.tax_type !== 'WHT')
		?.map(el => {
			const taxDetail = {
				ID: el?.tax_id,
				code: el?.code,
				taxType:
					el?.tax_type === 'ServiceCharge' ? 'Service Charge' : el?.tax_type,
				classType: el?.class_type,
			};

			return taxDetail;
		});

	console.log('apa', getTaxListingDropdown);

	return (
		<>
			<CommonDialog
				fullWidth={true}
				open={state.addDialog}
				onClose={() => {
					fields?.forEach((x, index) => remove(index));
					dispatch({ type: 'addDialog', payload: false });
					dispatch({
						type: 'taxArray',
						payload: [{ taxCode: '', computeMethod: '' }],
					});
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title flex-space">{state?.type} Tax</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<ContentWrapper>
								<MuiPickersUtilsProvider utils={DateFnsUtils}>
									<KeyboardDatePicker
										value={state?.effectiveDate || new Date()}
										margin="normal"
										label="Effective Date"
										className="left"
										required
										fullWidth
										onChange={date => {
											dispatch({ type: 'effectiveDate', payload: date });
											setIsSequenceTouched(true);
										}}
										// minDate={
										//   isBefore(new Date(todayDate), new Date(NATime))
										//     ? previousDate
										//     : todayDate
										// }
										format="dd/MM/yyyy"
										style={{ paddingBottom: '20px' }}
									/>
								</MuiPickersUtilsProvider>

								<CardContents
									section={{
										header: {
											onClickAction: () => {
												append({
													sequence: fields.length,
													ID: uuidv4(),
												});
											},
											icon: <AddBox color="primary" />,
											title: 'Tax Detail Item',
										},
									}}
								>
									{fields?.length <= 0 ? (
										<div
											className="flex-space"
											style={{ justifyContent: 'center', alignItems: 'center' }}
										>
											<EmptyList
												title="No Record found"
												subtitle="Click the add button to create a new record."
											/>
										</div>
									) : (
										<div className="flex-space">
											<Grid
												className="flex-space"
												xs={12}
												style={{ paddingTop: '10px' }}
											>
												<MsgAlert
													icon={<InfoOutlined />}
													message={'Drag and drop the item to re-sequence.'}
												/>
											</Grid>

											<DragNDropList onDragEnd={onDragEnd}>
												<DropableItem droppableId={'menu_item_list'}>
													{fields?.map((x, index) => {
														const formatField = `taxArray[${index}]`;
														return (
															<>
																<DraggableItem
																	id={x.id}
																	index={index}
																	draggableId={x.id}
																>
																	<div
																		className="flex-space"
																		style={{ display: 'flex' }}
																	>
																		<div
																			style={{
																				paddingTop: '20px',
																				marginRight: '10px',
																			}}
																		>
																			{index + 1}.
																		</div>

																		<div
																			style={{
																				width: '50%',
																				marginRight: '10px',
																			}}
																		>
																			<Autocomplete
																				options={taxGroupBy}
																				groupBy={option => option.taxType || ''}
																				getOptionLabel={option => option.code}
																				fullWidth
																				value={
																					state?.taxArray[index]?.taxCode || ''
																				}
																				onChange={(e, value) => {
																					const newArr: any = state.taxArray;
																					let selected = newArr[index];

																					if (selected) {
																						selected.taxCode =
																							value === undefined ? '' : value;
																					} else {
																						newArr.push({
																							taxCode: value,
																							computeMethod:
																								newArr[index]?.computeMethod ||
																								'',
																							sequenceNo:
																								newArr[index]?.sequenceNo || [],
																							oriIndex: index,
																						});
																					}

																					dispatch({
																						type: 'taxArray',
																						payload: newArr,
																					});
																				}}
																				renderInput={params => (
																					<TextField
																						{...params}
																						label="Tax"
																						name="taxCode"
																						variant="standard"
																					/>
																				)}
																			/>
																		</div>

																		<div style={{ width: '50%' }}>
																			<Autocomplete
																				options={
																					index === 0
																						? ComputeMethodTypeEnum?.filter(
																								x => x === 'Tax On Gross',
																						  )
																						: ComputeMethodTypeEnum
																				}
																				fullWidth
																				value={
																					state?.taxArray[index]
																						?.computeMethod || ''
																				}
																				onChange={(e, value) => {
																					const newArr: any = state.taxArray;
																					let selected = newArr[index];
																					let setSequence;

																					if (value === 'Tax On Gross') {
																						setSequence = [];
																						setIsSequenceTouched(true);
																					} else {
																						setSequence =
																							newArr[index]?.sequenceNo || [];
																						setIsSequenceTouched(false);
																					}

																					if (selected) {
																						selected.computeMethod =
																							value === undefined ? '' : value;
																						selected.sequenceNo = setSequence;
																						selected.oriIndex = index;
																					} else {
																						newArr.push({
																							taxCode:
																								newArr[index]?.taxCode || '',
																							computeMethod: value || '',
																							sequenceNo: setSequence,
																							oriIndex: index,
																						});
																					}

																					dispatch({
																						type: 'taxArray',
																						payload: newArr,
																					});
																				}}
																				renderInput={params => (
																					<TextField
																						{...params}
																						label="Compute Method"
																						variant="standard"
																						name="computeMethod"
																					/>
																				)}
																			/>
																		</div>

																		{state?.taxArray[index]?.computeMethod ===
																		'Tax On Taxed Price' ? (
																			<div
																				style={{
																					width: '50%',
																					marginLeft: '10px',
																				}}
																			>
																				<Autocomplete
																					multiple
																					disableCloseOnSelect
																					fullWidth
																					options={Array.from(
																						{ length: index },
																						(_, i) => (i + 1).toString(),
																					)}
																					value={
																						state?.taxArray[index]?.sequenceNo
																					}
																					onChange={(e, value) => {
																						if (value.length <= 0) {
																							setIsSequenceTouched(false);
																						} else {
																							setIsSequenceTouched(true);
																						}

																						const newArr = state.taxArray;
																						let selected = newArr[index];

																						if (selected) {
																							selected.sequenceNo =
																								value === undefined
																									? []
																									: value;
																						} else {
																							newArr.push({
																								taxCode:
																									newArr[index]?.taxCode || '',
																								computeMethod:
																									newArr[index]
																										?.computeMethod || '',
																								sequenceNo: value || [],
																								oriIndex: index,
																							});
																						}
																						dispatch({
																							type: 'TaxArray',
																							payload: newArr,
																						});
																					}}
																					renderInput={params => (
																						<TextField
																							{...params}
																							label="Tax On Seq*"
																							variant="standard"
																							name="sequenceNo"
																							style={{ width: '100%' }}
																						/>
																					)}
																				/>
																			</div>
																		) : null}

																		<div>
																			<IconButton
																				className="delete-icon"
																				onClick={() => {
																					const newLength = fieldLength - 1;
																					setFieldLength(newLength);
																					remove(index);

																					const newArr: any = state.taxArray.map(
																						(el, i) => {
																							if (i !== index) {
																								return el;
																							}
																						},
																					);
																					dispatch({
																						type: 'taxArray',
																						payload: newArr.filter(
																							x => x !== undefined,
																						),
																					});
																				}}
																				style={{
																					transform: 'scale(0.7)',
																					marginTop: '5px',
																				}}
																				question-uid={`${formatField}.uid`}
																			>
																				<Delete
																					style={{
																						backgroundColor: '#FF1B40',
																						borderRadius: '4px',
																						width: '25px',
																						height: '25px',
																						color: 'white',
																						fontSize: '10px',
																						paddingBottom: '2px',
																					}}
																					fontSize="large"
																				/>
																			</IconButton>
																		</div>
																	</div>
																</DraggableItem>
															</>
														);
													})}
												</DropableItem>
											</DragNDropList>
										</div>
									)}
								</CardContents>
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										dispatch({
											type: 'taxArray',
											payload: [{ taxCode: '', computeMethod: '' }],
										});
										dispatch({ type: 'addDialog', payload: false });
										fields?.forEach((x, index) => remove(index));
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: () => {
										itemSave();
									},
									variant: 'contained',
									color: 'primary',
									disabled:
										state?.taxArray[0]?.taxCode === '' ||
										state?.taxArray[0]?.computeMethod === '' ||
										state?.taxArray?.length === 0 ||
										state?.isConfirm === true ||
										isSequenceTouched === false,
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
