import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	PosPermission,
	useGetOutletQuery,
	useGetOutletSettingSummaryLazyQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
	DailyMenuAvailabilityIcon,
	DigitalMenuPricingIcon,
	DiscountTypeIcon,
	KitchenPrinterIcon,
	MajorFamilyIcon,
	MealPeriodIcon,
	MenuItemsIcon,
	MenuSchedulingIcon,
	OutletPolicyIcon,
	OutletProfileIcon,
	OutletRoleAssignmentIcon,
	PaymentTypeIcon,
	PromoCodeIcon,
	PromoMenuPricingIcon,
	QRCodeIcon,
	SetMenuIcon,
	StandardMenuOptionIcon,
	StopSalesItemIcon,
	TableIcon,
	ThirdPartyInterfaceSetupIcon,
} from '../../../assets';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
// import OutletProfile from 'assets/icons/outlet_profile.svg';

export const OutletSettingSubmenu = () => {
	useEffect(() => {
		console.log('OutletSettingSubmenu');
	}, []);
	const history = useHistory();

	const { [`outletID`]: outletID } = useParams<Record<string, any>>();

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
	const [rerender, setRender] = useState(false);

	const outletSubmenuSearch = JSON.parse(localStorage.getItem('searchFilter'));

	const { handlePermHidden } = usePermissionChecker();

	const [newList, setNewList] = useState(false);

	let today = new Date();

	const [
		loadOutletSettingSummary,
		{
			called: outletSettingSummaryCalled,
			loading: outletSettingSummaryLoading,
			data: outletSettingSummaryData,
		},
	] = useGetOutletSettingSummaryLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ outletSettingSummaryData }) => {
			setNewList(outletSettingSummaryData);
		},
	});

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: () => {
			loadOutletSettingSummary({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const outletArray: any[] = getOutlet;

	const outletProfileDate = outletSettingSummaryData?.outletProfileDate;
	const outletPolicyDate = outletSettingSummaryData?.outletPolicyDate;
	const tableCount = outletSettingSummaryData?.tableCount;
	const qrCount = outletSettingSummaryData?.qrCount;
	const kitchenPrinterCount = outletSettingSummaryData?.kitchenPrinterCount;
	const receiptPrinterCount = outletSettingSummaryData?.receiptPrinterCount;
	const mealPeriodCount = outletSettingSummaryData?.mealPeriodCount;
	const standardMenuOptionCount =
		outletSettingSummaryData?.standardMenuOptionCount;
	const menuItemsCount = outletSettingSummaryData?.menuItemsCount;
	const setMenuCount = outletSettingSummaryData?.setMenuCount;
	const digitalMenuPricingCount =
		outletSettingSummaryData?.digitalMenuPricingCount;
	const menuSchedulingCount = outletSettingSummaryData?.menuSchedulingCount;
	const promotionalMenuPricingCount =
		outletSettingSummaryData?.promotionalMenuPricingCount;
	const dailyMenuItemAvailabilityCount =
		outletSettingSummaryData?.dailyMenuItemAvailabilityCount;
	const promoCodeCount = outletSettingSummaryData?.promoCodeCount;
	const discountTypeCount = outletSettingSummaryData?.discountTypeCount;
	const stopSalesItemCount = outletSettingSummaryData?.stopSalesItemCount;
	const paymentTypeDate = outletSettingSummaryData?.paymentTypeDate;
	const majorCount = outletSettingSummaryData?.majorFamilyCount.majorCount;
	const familyCount = outletSettingSummaryData?.majorFamilyCount.familyCount;
	const outletPolicyDateNew = outletSettingSummaryData?.outletPolicyDateNew;
	// ?.outletPoilicyDate
	// 	?.slice()
	// 	?.sort(
	// 		(a, b) =>
	// 			new Date(null, null, ...a.to_char.split('-').reverse()).getTime() -
	// 			new Date(null, null, ...b.to_char.split('-').reverse()).getTime(),
	// 	)
	// 	?.map(el => el?.to_char);

	// const parsedTime = parseISO(
	// 	outletPolicyDateNew[2]?.outletPoilicyDate?.to_char,
	// );

	let outletList = [
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/outlet-profile`,

			icon: OutletProfileIcon,
			title: `Outlet Profile`,
			label1: `Last updated on `,
			data1: formatDate(outletProfileDate),
			permission: PosPermission.OutletSettingOutletProfileUpdate,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/outlet-policy`,

			icon: OutletPolicyIcon,
			title: `Outlet Policy`,
			label1: `Last updated on `,
			data1: formatDate(outletPolicyDateNew),
			permission: PosPermission.OutletSettingOutletPolicyShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/table`,
			icon: TableIcon,
			title: `Table`,
			label1: `Table: `,
			data1: tableCount,
			permission: PosPermission.OutletSettingTableShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/qr-code`,

			icon: QRCodeIcon,
			title: `QR Code`,
			label1: `QR Code: `,
			data1: qrCount,
			permission: PosPermission.OutletSettingQrCodeShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/kitchen-printer`,

			icon: KitchenPrinterIcon,
			title: `Kitchen Printer`,
			label1: `Kitchen Printer: `,
			data1: kitchenPrinterCount,
			permission: PosPermission.OutletSettingKitchenPrinterShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/receipt-printer`,

			icon: KitchenPrinterIcon,
			title: `Receipt Printer`,
			label1: `Receipt Printer: `,
			data1: receiptPrinterCount,
			permission: PosPermission.OutletSettingReceiptPrinterShow,
		},
		// {
		// 	action:
		// 		history.push(
		// 			`/menu/outlet-app/outlet-setting/${outletID}/receipt-printer`,
		// 		),
		// 	icon: KitchenPrinterIcon,
		// 	title: `Receipt Printer`,
		// 	label1: `Receipt Printer: `,
		// 	data1: receiptPrinterCount,
		// },
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/meal-period`,

			icon: MealPeriodIcon,
			title: `Meal Period`,
			label1: `Meal Period: `,
			data1: mealPeriodCount,
			permission: PosPermission.OutletSettingMealPeriodShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/major`,
			icon: MajorFamilyIcon,
			title: `Major & Family`,
			label1: `Total Major: `,
			label2: `Total Family: `,
			data1: majorCount,
			data2: familyCount,
			permission: PosPermission.OutletSettingMajorFamilyShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/standard-menu-option`,

			icon: StandardMenuOptionIcon,
			title: `Standard Menu Option`,
			label1: `Total Standard Menu Option: `,
			data1: standardMenuOptionCount,
			permission: PosPermission.OutletSettingStandardMenuOptionShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/menu-item`,

			icon: MenuItemsIcon,
			title: `Menu Items`,
			label1: `Total Menu Item: `,
			data1: menuItemsCount,
			permission: PosPermission.OutletSettingMenuItemsShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/set-menu`,

			icon: SetMenuIcon,
			title: `Set Menu`,
			label1: `Total Set Menu: `,
			data1: setMenuCount,
			permission: PosPermission.OutletSettingSetMenuShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/digital-menu`,

			icon: DigitalMenuPricingIcon,
			title: `Digital Menu & Pricing`,
			label1: `Total No. of Customer Menu: `,
			data1: digitalMenuPricingCount,
			permission: PosPermission.OutletSettingDigitalMenuPricingShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/menu-scheduling`,

			icon: MenuSchedulingIcon,
			title: `Menu Scheduling`,
			label1: `Total No. of Common Menu: `,
			data1: menuSchedulingCount,
			permission: PosPermission.OutletSettingMenuSchedulingShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/promotional-menu`,

			icon: PromoMenuPricingIcon,
			title: `Promotional Menu & Pricing`,
			label1: `Total No. of Common Menu: `,
			data1: promotionalMenuPricingCount,
			permission: PosPermission.OutletSettingPromotionalMenuPricingShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/daily-menu-item-availability`,

			icon: DailyMenuAvailabilityIcon,
			title: `Daily Menu Item Availability`,
			label1: `Menu Item: `,
			data1: dailyMenuItemAvailabilityCount,
			permission: PosPermission.OutletSettingDailyMenuItemAvailabilityShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/promo-code`,

			icon: PromoCodeIcon,
			title: `Promo Code`,
			label1: `Promo Code: `,
			data1: promoCodeCount,
			permission: PosPermission.OutletSettingPromoCodeShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/discount-type`,

			icon: DiscountTypeIcon,
			title: `Discount Type`,
			label1: `Discount Type: `,
			data1: discountTypeCount,
			permission: PosPermission.OutletSettingDiscountTypeShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/stop-sales-item`,

			icon: StopSalesItemIcon,
			title: `Stop Sales Items`,
			label1: `Out of Stock: `,
			data1: stopSalesItemCount,
			permission: PosPermission.OutletSettingStopSalesItemsShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/outlet-role-assignment`,

			icon: OutletRoleAssignmentIcon,
			title: `Outlet Role Assignment`,
			label1: `Last updated on `,
			data1: formatDate(today.toISOString()),
			permission: PosPermission.OutletSettingOutletRoleAssignmentShow,
		},
		{
			action: `/menu/outlet-app/outlet-setting/${outletID}/payment-type`,

			icon: PaymentTypeIcon,
			title: `Payment Type`,
			label1: `Last updated on `,
			data1: formatDate(paymentTypeDate),
			permission: PosPermission.OutletSettingPaymentTypeShow,
		},
		// {
		// 	action: `/menu/outlet-app/outlet-setting/${outletID}/third-party-interface-setup`,

		// 	icon: ThirdPartyInterfaceSetupIcon,
		// 	title: `3rd Party Interface Setup`,
		// 	label1: `Last updated on `,
		// 	data1: formatDate(today.toISOString()),
		// 	permission: PosPermission.OutletSettingThirdPartyInterfaceSetupShow,
		// },
	];

	// const [name, setName] = useLocalStorage("name", "Bob");

	// useEffect(() => {
	//   setName(yourState)
	// }, [location])

	// const newList = JSON.parse(localStorage.getItem('outletSubmenu'));

	useEffect(() => {
		// if (
		// 	outletList &&
		// 	outletList?.length > 0 &&
		// 	getOutlet &&
		// 	outletSettingSummaryData
		// ) {
		// 	// if (outletSubmenuSearch?.outletSubmenu !== '') {
		// 	// 	let temp = newList;
		// 	// 	setOriginalListing(temp);
		// 	// } else {
		// 	// 	setOriginalListing(outletList);
		// 	// }
		// 	setOriginalListing(outletList);
		// }
		if (outletList?.length > 0) {
			setOriginalListing(outletList);
		}
	}, [
		outletSettingSummaryData,
		// , getOutlet
	]);

	useEffect(() => {
		if (
			!!outletSubmenuSearch?.outletSubmenu &&
			!outletSettingSummaryLoading &&
			!outletLoading
		) {
			setRender(true);
			handleSearch(`'"${outletSubmenuSearch?.outletSubmenu ?? ''}"`, ['title']);
		}
	}, [outletSettingSummaryLoading, rerender, outletLoading]);

	const hasAnyPerm = filteredList.some(el =>
		handlePermHidden({
			outletID: outletID,
			permEnum: el.permission,
		}),
	);

	return (
		<>
			{outletSettingSummaryLoading && <Loading />}
			{outletLoading && <Loading />}
			{/* {syncOutletDailyMenuLoading && <Loading />} */}

			<MainHeader
				onClick={() => {
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							outletList: outletSubmenuSearch?.outletList ?? '',
							outletSubmenu: '',
							menuItemSearch: outletSubmenuSearch?.menuItemSearch ?? '',
						}),
					);
					history.push({ pathname: `/menu/outlet-app/outlet-setting` });
				}}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[{ name: 'Outlet Settings', current: true }]}
			/>
			<SearchHeader
				title="Outlet Settings"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['title']);
					localStorage.setItem(
						'searchFilter',
						JSON.stringify({
							outletSubmenu: e?.target?.value ?? '',
							menuItemSearch: outletSubmenuSearch?.menuItemSearch ?? '',
							outletList: outletSubmenuSearch?.outletList ?? '',
						}),
					);
				}}
				fixed
				isDefaultValue={!!outletSubmenuSearch?.outletSubmenu ?? false}
				defaultValue={outletSubmenuSearch?.outletSubmenu ?? ''}
				search
				onCloseAction={() => {
					// setOriginalListing(outletList);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							outletSubmenu: '',
							outletList: outletSubmenuSearch?.outletList ?? '',
							menuItemSearch: outletSubmenuSearch?.menuItemSearch ?? '',
						}),
					);
					handleSearch('', []);
				}}
				clickText
			/>
			<ContentWrapper search>
				<List className="core-list">
					{!outletLoading &&
						!outletSettingSummaryLoading &&
						(filteredList?.length > 0 ? (
							!hasAnyPerm ? (
								<EmptyList title="User does not have any permission to access this outlet"></EmptyList>
							) : (
								filteredList.map((el, index) => {
									if (
										handlePermHidden({
											outletID: outletID,
											permEnum: el.permission,
										})
									) {
										return (
											<ListItem
												key={index}
												onClick={() => {
													history.push(el?.action);
												}}
											>
												<ListItemIcon>
													<img
														className="icon-svg"
														style={{ paddingRight: '10px' }}
														src={el?.icon}
														alt="drawer-icon"
													/>
												</ListItemIcon>
												<ListItemText
													primary={
														<>
															<span className="desc">
																<span className="xsTitle">{el?.title}</span>
															</span>
														</>
													}
													secondary={
														<span className="desc">
															{el?.data2 ? (
																<>
																	<span className="desc">{el?.label1}</span>
																	<span className="highlight-text">
																		{el?.data1}
																	</span>
																	<span className="desc"> | {el?.label2}</span>
																	<span className="highlight-text">
																		{el?.data2}
																	</span>
																</>
															) : (
																<>
																	<span className="desc">{el?.label1}</span>
																	<span className="highlight-text">
																		{el?.data1}
																	</span>
																</>
															)}
														</span>
													}
												/>
												<ListItemSecondaryAction>
													<IconButton edge="end" aria-label="delete">
														<KeyboardArrowRight
															onClick={() => {
																history.push(el?.action);
																// localStorage.setItem(
																// 	'outletSubmenu',
																// 	JSON.stringify(filteredList),
																// );
															}}
														/>
													</IconButton>
												</ListItemSecondaryAction>
											</ListItem>
										);
									}
								})
							)
						) : (
							<EmptyList title="No Record found" />
						))}
				</List>
			</ContentWrapper>
			<div style={{ height: 100 }}></div>
		</>
	);
};
