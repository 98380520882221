import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import { Box, Button, Checkbox, Divider, Grid } from '@material-ui/core';
import OrangeDollarIcon from 'assets/icons/Money/dollar-orange.svg';
import {
	CreditCardType,
	PaymentClass,
	useGetBillListingQuery,
	useGetMenuXDebtorListLazyQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerLazyQuery,
	useGetStaffLazyQuery,
} from 'generated/graphql';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { amtStr } from 'helpers/numFormatter';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import '../../assets/styles/BillListingPaymentFooter.scss';
import AuditIcon from 'assets/icons/audit.svg';
import { boolean } from 'yup';

// interface BillListingProps {
// 	isAuditDialog?: boolean;
// 	setOpenAuditDialog?: any;
// }

export const BillListingPaymentFooter = (props: any) => {
	const {
		title,
		style,
		hotelID,
		data,
		paymentClassArr,
		currencyCode,
		isAuditDialog,
		setOpenAuditDialog,
		isChangePayment,
		setIsChangePayment,
		checkedID,
		setCheckedID,
	} = props;

	const { outletID, billID } = useParams<any>();
	const {
		loading: billLoading,
		error: billError,
		data: { getBill } = { getBill: [] },
	} = useGetBillListingQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
			ID: billID,
		},
	});

	const [
		loadMenuXInhouse,
		{
			loading: MenuXInhouseListLoading,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
	});
	const hotelGuest = getMenuXInhouseList?.data?.MenuXInhouseList;

	const [
		loadMenuXDebtor,
		{
			loading: MenuXDebtorListLoading,
			data: { getMenuXDebtorList } = { getMenuXDebtorList: [] },
		},
	] = useGetMenuXDebtorListLazyQuery({
		fetchPolicy: 'network-only',
	});
	const hotelDebtor = getMenuXDebtorList?.data?.MenuXDebtorList;

	const [
		loadOfficer,
		{
			loading: getOfficerLoading,
			called: getOfficerCalled,
			data: { getOfficer } = { getOfficer: [] },
		},
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadStaff,
		{
			loading: getStaffLoading,
			called: getStaffCalled,
			data: { getStaff } = { getStaff: [] },
		},
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
	});

	const creditClass = creditClass => {
		let credClass;

		if (creditClass === CreditCardType.AmericanExpressCard) {
			credClass = 'American Express Card';
		} else if (creditClass === CreditCardType.DinersCard) {
			credClass = 'Diners Card';
		} else if (creditClass === CreditCardType.JcbCard) {
			credClass = 'JCB Card';
		} else if (creditClass === CreditCardType.Mastercard) {
			credClass = 'Master Card';
		} else if (creditClass === CreditCardType.UnionpayCard) {
			credClass = 'UNION PAY';
		} else if (creditClass === CreditCardType.VisaCard) {
			credClass = 'Visa Card';
		} else if (creditClass === CreditCardType.DebitCard) {
			credClass = 'Debit Card';
		} else if (creditClass === CreditCardType.Ewallet) {
			credClass = 'E-Wallet';
		}
		return credClass;
	};

	const payments = getBill[0]?.payments;

	const getGuestInfo = payment => {
		return hotelGuest?.filter(
			guest => guest?.RegistrationID === payment?.registrationID,
		)[0];
	};
	const getDebtorInfo = payment => {
		return hotelDebtor?.filter(
			guest => guest?.RegistrationID === payment?.registrationID,
		)[0];
	};
	const getOfficerInfo = payment => {
		return getOfficer?.filter(
			officer => officer?.ID === payment?.patronAccountID,
		)[0];
	};
	const getStaffInfo = payment => {
		return getStaff?.filter(staff => staff?.ID === payment?.patronAccountID)[0];
	};

	const paymentInfoLine = (paymentClass, payment) => {
		if (paymentClass === PaymentClass.Credit) {
			return `${creditClass(
				payment?.creditCardType,
			)} | ****${(payment?.cardNo).slice(-4)}`;
		} else if (paymentClass === PaymentClass.Room) {
			return `${
				payment?.hotelGuestInfo?.RoomNo
					? `${payment?.hotelGuestInfo?.RoomNo} |`
					: ''
			} ${payment?.hotelGuestInfo?.FullName}`;
		} else if (
			paymentClass === PaymentClass.NonGuest ||
			paymentClass === PaymentClass.SuspendFolio
		) {
			return `${payment?.hotelGuestInfo?.FullName}`;
		} else if (paymentClass === PaymentClass.Debtor) {
			return `${payment?.hotelGuestInfo?.GuestType} | ${payment?.hotelGuestInfo?.FullName}`;
		} else if (paymentClass === PaymentClass.Officer) {
			return `${getOfficerInfo(payment)?.designation} | ${
				getOfficerInfo(payment)?.name
			}`;
		} else if (paymentClass === PaymentClass.Staff) {
			return `${getStaffInfo(payment)?.designation} | ${
				getStaffInfo(payment)?.name
			}`;
		} else if (
			paymentClass === PaymentClass.Cash &&
			Math.abs(payment?.changeAmount) !== 0
		) {
			return `Change amount: `;
		} else if (paymentClass === PaymentClass.Voucher) {
			return `Code : ${payment?.referenceNo}`;
		} else if (
			paymentClass === PaymentClass.BankTt ||
			paymentClass === PaymentClass.Cheque ||
			paymentClass === PaymentClass.Online ||
			paymentClass === PaymentClass.Skipper ||
			paymentClass === PaymentClass.Member
		) {
			return `Remark : ${payment?.remark}`;
		} else {
			return ``;
		}
	};

	useEffect(() => {
		if (paymentClassArr?.includes(PaymentClass.Officer)) {
			loadOfficer({
				variables: {
					isActive: true,
				},
			});
		}
		if (paymentClassArr?.includes(PaymentClass.Staff)) {
			loadStaff({
				variables: {
					isActive: true,
				},
			});
		}
		if (
			(paymentClassArr?.includes(PaymentClass.Room) ||
				paymentClassArr?.includes(PaymentClass.NonGuest) ||
				paymentClassArr?.includes(PaymentClass.SuspendFolio)) &&
			hotelID
		) {
			loadMenuXInhouse({
				variables: {
					hotelID: hotelID,
					outletID: outletID,
				},
			});
		}
		if (paymentClassArr?.includes(PaymentClass.Debtor) && hotelID) {
			loadMenuXDebtor({
				variables: {
					hotelID: hotelID,
					outletID: outletID,
				},
			});
		}
	}, [paymentClassArr, hotelID]);

	const handleCheckBoxChange = (e: any) => {
		let temp: any = checkedID;
		if (e.target.checked) {
			temp.push({ paymentID: e.target.value });
			setCheckedID(temp);
		} else {
			temp = checkedID.filter((v: any) => v?.paymentID !== e.target.value);
			setCheckedID(temp);
		}
	};

	return (
		<>
			{MenuXInhouseListLoading && <Loading />}
			{MenuXDebtorListLoading && <Loading />}
			{getOfficerLoading && <Loading />}
			{getStaffLoading && <Loading />}
			<div className="billListing-dynamic-header" style={{ ...style }}>
				<div style={{ fontSize: '14px', paddingBottom: '5px' }}>
					{title}

					<span style={{ float: 'right' }}>
						{isAuditDialog ? (
							<span className="" onClick={() => setOpenAuditDialog(true)}>
								<img
									src={AuditIcon}
									alt="ifca"
									width={12}
									loading="lazy"
									style={{ paddingRight: '4px', paddingLeft: '4px' }}
								/>
							</span>
						) : null}
					</span>

					<Divider
						orientation="horizontal"
						variant="fullWidth"
						style={{
							color: 'white',
							backgroundColor: 'white',
							position: 'relative',
							top: '10px',
						}}
					/>
				</div>
				{data
					// ?.filter(x => x?.amount > 0)
					?.map((el: any, i: any) => {
						return (
							<>
								<Grid container>
									{isChangePayment === true && (
										<Grid item xs={1}>
											<div style={{ marginLeft: '-11px' }}>
												<Checkbox
													value={el?.ID}
													onChange={e => handleCheckBoxChange(e)}
													color="primary"
													disabled={el?.status === 'VOID'}
												/>
											</div>
										</Grid>
									)}
									<Grid item xs={isChangePayment === true ? 7 : 8}>
										<div
											style={
												el?.status === 'VOID'
													? isChangePayment === true
														? {
																textDecoration: 'line-through',
																fontSize: '14px',
																marginTop: '10px',
																marginLeft: '-7px',
														  }
														: {
																textDecoration: 'line-through',
																fontSize: '14px',
																marginTop: '10px',
														  }
													: isChangePayment === false
													? {
															fontSize: '14px',
															marginTop: '10px',
													  }
													: {
															fontSize: '14px',
															marginTop: '10px',
															marginLeft: '-7px',
													  }
											}
										>
											{el?.defaultPayment?.paymentClass ===
											PaymentClass.UnsettledBill
												? 'Unsettled Bill'
												: el?.defaultPayment?.paymentClass ===
												  PaymentClass.NonGuest
												? 'Non Guest'
												: el?.defaultPayment?.paymentClass ===
												  PaymentClass.SuspendFolio
												? 'Suspend Folio'
												: el?.defaultPayment?.paymentClass ===
												  PaymentClass.BankTt
												? 'Bank TT'
												: toProperCase(el?.defaultPayment?.paymentClass)}
										</div>
									</Grid>
									<Grid
										item
										xs={4}
										style={{
											float: 'right',
											paddingLeft: '36px',
											textAlign: 'right',
										}}
									>
										<div className="highlight-text xsTitle">
											{/* <IconText
											rightText
											icon={
												<img
													src={OrangeDollarIcon}
													style={{
														width: '17px',
														paddingRight: '2px',
														marginTop: '14px',
													}}
												/>
											}
										> */}
											<div
												style={
													el?.status === 'VOID'
														? {
																// paddingRight: '24px',
																textDecoration: 'line-through',
																marginTop: '14px',
														  }
														: {
																// paddingRight: '24px',
																marginTop: '14px',
														  }
												}
											>
												{`${currencyCode}${amtStr(
													Math.abs(
														el?.receivedAmount !== 0 &&
															el?.receivedAmount !== null &&
															el?.receivedAmount !== undefined
															? el?.receivedAmount
															: el?.amount,
													),
												)}`}
											</div>
											{/* </IconText> */}
										</div>
									</Grid>
									{isChangePayment === true && (
										<Grid item xs={1}>
											<div style={{ marginLeft: '-11px' }}></div>
										</Grid>
									)}
									<Grid item xs={isChangePayment === true ? 7 : 8}>
										<div
											className="flex-space"
											style={
												el?.status === 'VOID'
													? isChangePayment === true
														? {
																textDecoration: 'line-through',
																paddingTop: '5px',
																marginLeft: '-7px',
														  }
														: {
																textDecoration: 'line-through',
																paddingTop: '5px',
														  }
													: isChangePayment === false
													? { paddingTop: '5px' }
													: { paddingTop: '5px', marginLeft: '-7px' }
											}
										>
											{paymentInfoLine(el?.defaultPayment?.paymentClass, el)}
										</div>
									</Grid>
									{el?.paymentClass === PaymentClass.Cash &&
										Math.abs(el?.changeAmount) !== 0 && (
											<Grid
												item
												xs={4}
												style={{
													float: 'right',
													paddingLeft: '36px',
													textAlign: 'right',
												}}
											>
												<div className="highlight-text xsTitle">
													<div
														style={
															el?.status === 'VOID'
																? {
																		// paddingRight: '24px',
																		textDecoration: 'line-through',
																		// marginTop: '14px',
																  }
																: {
																		// paddingRight: '24px',
																		// marginTop: '14px',
																  }
														}
													>
														{`${currencyCode}${amtStr(
															Math.abs(el?.changeAmount),
														)}`}
													</div>
												</div>
											</Grid>
										)}
								</Grid>
							</>
						);
					})}
			</div>
		</>
	);
};

export const ChangePaymentFooter = (props: any) => {
	const { label, option, style } = props;

	return (
		<>
			<div className="footer" style={{ ...style, paddingBottom: '2px' }}>
				<div style={{ float: 'left', marginTop: '6px', marginLeft: '15px' }}>
					<span
						className="footer-order-label xsTitle"
						style={{ padding: '18px 0px' }}
					>
						{label}
					</span>
				</div>
				{option && (
					<div className="footer-order-btn">
						<Button
							type="submit"
							color={option.color}
							style={{ minWidth: '126px', backgroundColor: '#ff9800' }}
							variant="contained"
							onClick={option.onClick}
							disabled={option.disabled}
						>
							<span className="smTitle">{option.name}</span>
						</Button>
					</div>
				)}
			</div>
		</>
	);
};
