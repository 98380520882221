import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	PosPermission,
	useGetOutletBillChargesQuery,
	useGetOutletBillEntChargesQuery,
	useGetOutletBillOcChargesQuery,
	useGetOutletQuery,
	useSubmitOutletGlInterfaceMutation,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { IAction } from 'helpers/model';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { Reducer, useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';
import { ClosedBill } from './components/ClosedBill';
import { EntBill } from './components/EntBill';
import { OcBill } from './components/OcBill';
import { VoidedBill } from './components/VoidedBill';
import { renderTypePaymentDefaultValue } from './components/Common';
import './glInterface.scss';

type Mode = string | 'edit' | 'save';
interface props {
	mode?: Mode;
	charges: any;
	ocCharges: any;
	entCharges: any;
}
export const OutletGLInterface = () => {
	let history = useHistory();
	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);
	const { dispatch: GlobalDispatch } = useContext(AppContext as any);

	const { handlePermDisabled } = usePermissionChecker();
	const outletID = localStorage.getItem('latestOutlet');

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: localStorage.getItem('latestOutlet') },
	});

	const {
		loading: chargesLoading,
		data: { getOutletBillCharges } = { getOutletBillCharges: [] },
		refetch: chargesRefetch,
	} = useGetOutletBillChargesQuery({
		variables: {
			outletID: localStorage.getItem('latestOutlet'),
		},
		fetchPolicy: 'no-cache',
	});

	const {
		loading: ocChargesLoading,
		data: { getOutletBillOCCharges } = { getOutletBillOCCharges: [] },
		refetch: ocChargesRefetch,
	} = useGetOutletBillOcChargesQuery({
		variables: {
			outletID: localStorage.getItem('latestOutlet'),
		},
		fetchPolicy: 'no-cache',
	});

	const {
		loading: entChargesLoading,
		data: { getOutletBillEntCharges } = { getOutletBillEntCharges: [] },
		refetch: entChargesRefetch,
	} = useGetOutletBillEntChargesQuery({
		variables: {
			outletID: localStorage.getItem('latestOutlet'),
		},
		fetchPolicy: 'no-cache',
	});

	const [
		submitOutletGLInterface,
		{ loading: insertLoading, error: insertError },
	] = useSubmitOutletGlInterfaceMutation({
		onError: () => {
			setOpenSnackBar(true);
			setSnackBarMsg('Update record unsuccessful!');
		},
	});
	const initialState: props = {
		mode: 'edit',
		charges: [],
		ocCharges: [],
		entCharges: [],
	};

	const reducer: Reducer<props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};
	const [state, dispatch] = useReducer(reducer, initialState);

	const handleReturnArr = array => {
		let tempList = [] as any;
		array?.map(x => {
			if (x?.descriptionType === 'Rounding') {
				tempList.push({
					...x,
					outletGLAccount: {},
					outletOCGLAccount: {},
					outletENTGLAccount: {},
					outletRoundingGLAccount: {
						revenueDiv: x.outletRoundingGLAccount?.revenueDiv || '',
						revenueDept: x.outletRoundingGLAccount?.revenueDept || '',
						revenueDebit: x.outletRoundingGLAccount?.revenueDebit || '',
						revenueCredit: x.outletRoundingGLAccount?.revenueCredit || '',
						reverseDiv: x.outletRoundingGLAccount?.reverseDiv || '',
						reverseDept: x.outletRoundingGLAccount?.reverseDept || '',
						reverseDebit: x.outletRoundingGLAccount?.reverseDebit || '',
						reverseCredit: x.outletRoundingGLAccount?.reverseCredit || '',
						type: x.outletRoundingGLAccount?.type || 'GL',
					},
					outletDiscountGLAcct: {},
					outletOCDiscountGLAcct: {},
					outletENTDiscountGLAcct: {},
				});
			} else {
				tempList.push({
					...x,
					outletGLAccount: {
						revenueDiv: x.outletGLAccount?.revenueDiv || '',
						revenueDept: x.outletGLAccount?.revenueDept || '',
						revenueDebit: x.outletGLAccount?.revenueDebit || '',
						revenueCredit: x.outletGLAccount?.revenueCredit || '',
						reverseDiv: x.outletGLAccount?.reverseDiv || '',
						reverseDept: x.outletGLAccount?.reverseDept || '',
						reverseDebit: x.outletGLAccount?.reverseDebit || '',
						reverseCredit: x.outletGLAccount?.reverseCredit || '',
						type: x.outletGLAccount?.type
							? x.outletGLAccount?.type
							: renderTypePaymentDefaultValue(x?.description) || 'GL',
					},
					outletOCGLAccount: {
						revenueDiv: x.outletOCGLAccount?.revenueDiv || '',
						revenueDept: x.outletOCGLAccount?.revenueDept || '',
						revenueDebit: x.outletOCGLAccount?.revenueDebit || '',
						revenueCredit: x.outletOCGLAccount?.revenueCredit || '',
						reverseDiv: x.outletOCGLAccount?.reverseDiv || '',
						reverseDept: x.outletOCGLAccount?.reverseDept || '',
						reverseDebit: x.outletOCGLAccount?.reverseDebit || '',
						reverseCredit: x.outletOCGLAccount?.reverseCredit || '',
						type: x.outletOCGLAccount?.type || 'GL',
					},
					outletENTGLAccount: {
						revenueDiv: x.outletENTGLAccount?.revenueDiv || '',
						revenueDept: x.outletENTGLAccount?.revenueDept || '',
						revenueDebit: x.outletENTGLAccount?.revenueDebit || '',
						revenueCredit: x.outletENTGLAccount?.revenueCredit || '',
						reverseDiv: x.outletENTGLAccount?.reverseDiv || '',
						reverseDept: x.outletENTGLAccount?.reverseDept || '',
						reverseDebit: x.outletENTGLAccount?.reverseDebit || '',
						reverseCredit: x.outletENTGLAccount?.reverseCredit || '',
						type: x.outletENTGLAccount?.type || 'GL',
					},
					outletRoundingGLAccount: {},
					outletDiscountGLAcct: {
						revenueDiv: x.outletDiscountGLAcct?.revenueDiv || '',
						revenueDept: x.outletDiscountGLAcct?.revenueDept || '',
						revenueDebit: x.outletDiscountGLAcct?.revenueDebit || '',
						revenueCredit: x.outletDiscountGLAcct?.revenueCredit || '',
						reverseDiv: x.outletDiscountGLAcct?.reverseDiv || '',
						reverseDept: x.outletDiscountGLAcct?.reverseDept || '',
						reverseDebit: x.outletDiscountGLAcct?.reverseDebit || '',
						reverseCredit: x.outletDiscountGLAcct?.reverseCredit || '',
						type: x.outletDiscountGLAcct?.type || 'GL',
					},
					outletOCDiscountGLAcct: {
						revenueDiv: x.outletOCDiscountGLAcct?.revenueDiv || '',
						revenueDept: x.outletOCDiscountGLAcct?.revenueDept || '',
						revenueDebit: x.outletOCDiscountGLAcct?.revenueDebit || '',
						revenueCredit: x.outletOCDiscountGLAcct?.revenueCredit || '',
						reverseDiv: x.outletOCDiscountGLAcct?.reverseDiv || '',
						reverseDept: x.outletOCDiscountGLAcct?.reverseDept || '',
						reverseDebit: x.outletOCDiscountGLAcct?.reverseDebit || '',
						reverseCredit: x.outletOCDiscountGLAcct?.reverseCredit || '',
						type: x.outletOCDiscountGLAcct?.type || 'GL',
					},
					outletENTDiscountGLAcct: {
						revenueDiv: x.outletENTDiscountGLAcct?.revenueDiv || '',
						revenueDept: x.outletENTDiscountGLAcct?.revenueDept || '',
						revenueDebit: x.outletENTDiscountGLAcct?.revenueDebit || '',
						revenueCredit: x.outletENTDiscountGLAcct?.revenueCredit || '',
						reverseDiv: x.outletENTDiscountGLAcct?.reverseDiv || '',
						reverseDept: x.outletENTDiscountGLAcct?.reverseDept || '',
						reverseDebit: x.outletENTDiscountGLAcct?.reverseDebit || '',
						reverseCredit: x.outletENTDiscountGLAcct?.reverseCredit || '',
						type: x.outletENTDiscountGLAcct?.type || 'GL',
					},
				});
			}
		});
		return tempList;
	};

	// console.log("state", state)
	useEffect(() => {
		// Charges Closed Bill
		if (getOutletBillCharges?.length > 0) {
			dispatch({
				type: 'charges',
				payload: [...handleReturnArr(getOutletBillCharges)],
			});
		}
	}, [getOutletBillCharges]);

	useEffect(() => {
		// Charges Officer Check Bill
		if (getOutletBillOCCharges?.length > 0) {
			dispatch({
				type: 'ocCharges',
				payload: [...handleReturnArr(getOutletBillOCCharges)],
			});
		}
	}, [getOutletBillOCCharges]);

	useEffect(() => {
		// Charges Entertainment Bill
		if (getOutletBillEntCharges?.length > 0) {
			dispatch({
				type: 'entCharges',
				payload: [...handleReturnArr(getOutletBillEntCharges)],
			});
		}
	}, [getOutletBillEntCharges]);

	const useStyles = makeStyles({
		table: {
			minWidth: 650,
		},
	});
	const classes = useStyles();

	const handleGLInputs = array => {
		return array?.map(x => ({
			description: x.description,
			descriptionType: x.descriptionType,
			ID: x.ID,
			outletGLAccount: {
				revenueDiv: x.outletGLAccount.revenueDiv,
				revenueDept: x.outletGLAccount.revenueDept,
				revenueDebit: x.outletGLAccount.revenueDebit,
				revenueCredit: x.outletGLAccount.revenueCredit,
				reverseDiv: x.outletGLAccount.reverseDiv,
				reverseDept: x.outletGLAccount.reverseDept,
				reverseDebit: x.outletGLAccount.reverseDebit,
				reverseCredit: x.outletGLAccount.reverseCredit,
				type: x.outletGLAccount.type,
			},
			outletOCGLAccount: state.ocCharges?.find(c => c.ID === x.ID)
				?.outletOCGLAccount,
			outletENTGLAccount: state.entCharges?.find(c => c.ID === x.ID)
				?.outletENTGLAccount,
			outletRoundingGLAccount: {
				revenueDiv: x.outletRoundingGLAccount.revenueDiv,
				revenueDept: x.outletRoundingGLAccount.revenueDept,
				revenueDebit: x.outletRoundingGLAccount.revenueDebit,
				revenueCredit: x.outletRoundingGLAccount.revenueCredit,
				reverseDiv: x.outletRoundingGLAccount.reverseDiv,
				reverseDept: x.outletRoundingGLAccount.reverseDept,
				reverseDebit: x.outletRoundingGLAccount.reverseDebit,
				reverseCredit: x.outletRoundingGLAccount.reverseCredit,
				type: x.outletRoundingGLAccount.type,
			},
			outletDiscountGLAcct: {
				revenueDiv: x.outletDiscountGLAcct.revenueDiv,
				revenueDept: x.outletDiscountGLAcct.revenueDept,
				revenueDebit: x.outletDiscountGLAcct.revenueDebit,
				revenueCredit: x.outletDiscountGLAcct.revenueCredit,
				reverseDiv: x.outletDiscountGLAcct.reverseDiv,
				reverseDept: x.outletDiscountGLAcct.reverseDept,
				reverseDebit: x.outletDiscountGLAcct.reverseDebit,
				reverseCredit: x.outletDiscountGLAcct.reverseCredit,
				type: x.outletDiscountGLAcct.type,
			},
			outletOCDiscountGLAcct: state.ocCharges?.find(
				c => c.ID === x.ID && c.descriptionType === x.descriptionType,
			)?.outletOCDiscountGLAcct,
			outletENTDiscountGLAcct: state.entCharges?.find(
				c => c.ID === x.ID && c.descriptionType === x.descriptionType,
			)?.outletENTDiscountGLAcct,
		}));
	};
	// console.log(handleGLInputs([
	// 	...state.charges,
	// 	...state.ocCharges.filter(x => x.ID !== state?.charges?.find(t => t.ID === x.ID)?.ID),
	// 	...state.entCharges.filter(x => x.ID !== state?.charges?.find(t => t.ID === x.ID)?.ID)
	// ]),'test23')
	const handleSubmit = () => {
		submitOutletGLInterface({
			variables: {
				glInput: {
					bill: handleGLInputs([
						...state.charges,
						...state.ocCharges.filter(
							x => x.ID !== state?.charges?.find(t => t.ID === x.ID)?.ID,
						),
						...state.entCharges.filter(
							x => x.ID !== state?.charges?.find(t => t.ID === x.ID)?.ID,
						),
					]),
				},
				outletID: localStorage.getItem('latestOutlet'),
			},
		}).then(i => {
			if (i.data.submitOutletGLInterface) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.updateRecord());
				chargesRefetch();
				ocChargesRefetch();
				entChargesRefetch();
			} else {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.createNewRecord());
			}
		});
	};

	return (
		<>
			{(chargesLoading ||
				ocChargesLoading ||
				entChargesLoading ||
				insertLoading) && <Loading />}
			<MainHeader
				onClick={() =>
					history.push('/menu/outlet-app/account-journals/account-mapping')
				}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Account Mapping' },
					{ name: 'Outlet GL Interface', current: true },
				]}
			/>
			<ContentWrapper footer>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableBody className="mapping-table">
							<TableRow className="mapping-tr">
								<TableCell className="header-bg table-row-sticky">
									Type of Transaction
								</TableCell>
								<TableCell className="header-bg" align="center">
									Division
								</TableCell>
								<TableCell className="header-bg" align="center">
									Department
								</TableCell>
								<TableCell className="header-bg" align="center">
									Type
								</TableCell>
								<TableCell className="header-bg" align="center">
									Debit
								</TableCell>
								<TableCell className="header-bg" align="center">
									Credit
								</TableCell>
							</TableRow>

							<ClosedBill
								state={state}
								dispatch={dispatch}
								outlet={getOutlet[0]}
							/>
							{/* <VoidedBill
								state={state}
								outlet={getOutlet[0]}
								dispatch={dispatch}
							/> */}
							<OcBill state={state} dispatch={dispatch} outlet={getOutlet[0]} />
							<EntBill
								state={state}
								dispatch={dispatch}
								outlet={getOutlet[0]}
							/>
						</TableBody>
					</Table>
				</TableContainer>
			</ContentWrapper>
			<DynamicFooter
				options={[
					{
						name: state.mode === 'edit' ? 'Edit' : 'Save',
						onClick: () => {
							dispatch({
								type: 'mode',
								payload: state.mode === 'edit' ? 'save' : 'edit',
							});
							state.mode === 'save' && handleSubmit();
						},

						color: 'primary',
						disabled: handlePermDisabled({
							outletID: outletID,
							permEnum:
								PosPermission.AccountJournalsAccountsMappingOutletGlInterfaceUpdate,
						}),
					},
				]}
			/>
		</>
	);
};
