import { CardContents } from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { StyledMenu } from '@ifca-root/react-component/src/components/others/Menu';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Checkbox,
	FormControlLabel,
	Grid,
	InputAdornment,
	TextField,
} from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetMenuItemForSetMenuOptionItemDocument,
	GetSetMenuOptionDocument,
	useCreateSetMenuStandardOptionItemMutation,
	useGetMenuItemForSetMenuOptionItemQuery,
	useGetMenuItemQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { useMediaQuery } from 'helpers/mediaSize';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './setMenu.scss';

export interface OutletStandardItemProps {
	key?: typeof String;
}

export const SetMenuStandardItemForm = props => {
	useEffect(() => {
		console.log('SetMenuStandardItemForm Outlet App');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	let stopper = 1;
	const { mode } = props;
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const { [`outletID`]: outletID, setMenuID } = useParams<
		Record<string, any>
	>();

	const [openExitDialog, setOpenExitDialog] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const {
		data: { getMenuItem } = { getMenuItem: [] },
		loading: menuItemLoading,
	} = useGetMenuItemQuery({
		variables: {
			isSetMenu: true,
			ID: setMenuID,
		},
	});

	const {
		data: { getMenuItemForSetMenuOptionItem } = {
			getMenuItemForSetMenuOptionItem: [],
		},
		called: setMenuStandardOptionItemCalled,
		loading: setMenuStandardOptionItemLoad,
	} = useGetMenuItemForSetMenuOptionItemQuery({
		variables: {
			// isSetMenu: false,
			setMenuID: setMenuID,
			outletID: outletID,
			isStandard: true,
		},
	});

	// const [
	// 	loadMenuItemForSetMenuOptionItem,

	// 	{
	// 		data: getMenuItemForSetMenuOptionItem = {
	// 			getMenuItemForSetMenuOptionItem: [],
	// 		},
	// 		called: setMenuStandardOptionItemCalled,
	// 		loading: setMenuStandardOptionItemLoad,
	// 	},
	// ] = useGetMenuItemForSetMenuOptionItemLazyQuery({
	// 	fetchPolicy: 'network-only',
	// });

	const [enabler, setEnabler] = useState([]);
	useEffect(() => {
		// loadMenuItemForSetMenuOptionItem({
		// 	variables: {
		// 		// isSetMenu: false,
		// 		setMenuID: setMenuID,
		// 		outletID: outletID,
		// 		isStandard: true,
		// 	},
		// });

		getMenuItemForSetMenuOptionItem?.map((el, index) => {
			enabler.push(true);
		});
		// enabler.push(true);
	}, [setMenuStandardOptionItemLoad]);

	const [screenWidth] = useMediaQuery();
	let style;
	if (screenWidth < 769) {
		style = {
			styles: {
				paddingTop: '40px',
			},
		};
	} else {
		style = {
			styles: {
				paddingTop: '100px',
				// position: 'absolute',
			},
		};
	}

	const { register, control, handleSubmit, errors, watch, setValue } = useForm<
		OutletStandardItemProps
	>({
		defaultValues: {},
		mode: 'onSubmit',
	});

	const [
		createSetMenuStandardOptionItem,
		{
			data: createdMealPeriodData,
			loading: createMealPeriodLoading,
			called: createMealPeriodCalled,
			error: createMealPeriodError,
		},
	] = useCreateSetMenuStandardOptionItemMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu/${setMenuID}/set-menu-standard-item`,
					state: { success: true, msgMode: 'create' },
				});
				//history.goBack();
			}, 500);
		},
	});

	const createUpdateCalled = createMealPeriodCalled;

	const handleCheckBoxChange = (e: any, index: number) => {
		let temp = enabler;
		if (!e.target.checked) {
			setValue(`menuItem[${index}].menuItemID`, null);
			setValue(`menuItem[${index}].quantity`, null);
			setValue(`menuItem[${index}].name`, null);
			setValue(`menuItem[${index}].enabler`, true);
			temp.splice(index, 1, true);
			setEnabler([...temp]);
		} else {
			setValue(`menuItem[${index}].menuItemID`, e.target.value);
			setValue(`menuItem[${index}].enabler`, false);
			temp.splice(index, 1, false);
			setEnabler([...temp]);
			// enabler.push(false);
		}
		// setEnabler(menuItem[index].enabler);
	};

	const onSubmit = (data: any) => {
		let name: any;

		getMenuItemForSetMenuOptionItem?.map((el, index) => {
			data?.menuItem?.map((ell, index) => {
				if (el?.ID == ell?.menuItemID) {
					setValue(`menuItem[${index}].name`, el.name);
				} else {
					console.log('fail ');
				}
			});
		});

		const finalInput = {
			input: data?.menuItem
				.filter((x: any) => {
					return x.menuItemID;
				})
				.map((v: any) => {
					return {
						menuItemID: v.menuItemID,
						// outletID: outletID,
						quantity: parseInt(v.quantity),
						name: v.name,
					};
				}),
		};

		createSetMenuStandardOptionItem({
			variables: {
				// input: {
				outletID: outletID,
				setMenuID: setMenuID,
				setMenuOptionItemInput: finalInput.input,
				// },
			},

			refetchQueries: [
				{
					query: GetSetMenuOptionDocument,
					variables: { menuItemID: setMenuID, maxSelections: 0, outletID },
				},
				{
					query: GetMenuItemForSetMenuOptionItemDocument,
					variables: {
						setMenuID: setMenuID,
						outletID: outletID,
						isStandard: true,
					},
				},
			],
		});
	};

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	return (
		<>
			{menuItemLoading && <Loading />}
			{setMenuStandardOptionItemLoad && <Loading />}
			{outletLoading && <Loading />}
			{createMealPeriodLoading && <Loading />}
			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Outlet Settings' },
					{ name: 'Standard Item', current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>{' '}
			<DynamicSubHeader
				title={getMenuItem[0]?.name} // title text
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
					></StyledMenu>
				}
			/>
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<div>
					<MsgAlert message={'Select Menu Item to Set Menu. '} />
				</div>

				<ContentWrapper footer>
					{!setMenuStandardOptionItemCalled &&
					!menuItemLoading &&
					!setMenuStandardOptionItemLoad &&
					!outletLoading &&
					!createMealPeriodLoading &&
					(getMenuItemForSetMenuOptionItem === undefined ||
						getMenuItemForSetMenuOptionItem?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add more menu item(s) to continue"
						/>
					) : (
						<CardContents>
							{getMenuItemForSetMenuOptionItem?.map((el, index) => {
								const menuItem = `menuItem[${index}]`;
								// enabler.push(true);
								return (
									<Grid container direction="row" alignItems="center">
										<Grid
											item
											xs={9}
											className="left"
											style={{ paddingRight: '10px', paddingBottom: '12px' }}
										>
											<div>
												<Controller
													as={
														<FormControlLabel
															style={{
																textDecoration: 'underline',
																fontWeight: 90000, //sdsds
															}}
															control={
																<Checkbox
																	name={`menuItem[${index}].menuItemID`}
																	value={el.ID}
																	color="primary"
																	style={{ height: '1vh' }}
																	onChange={e => handleCheckBoxChange(e, index)}
																/>
															}
															label={
																<span className="xsTitle item-name">
																	{el.name}
																</span>
															}
														></FormControlLabel>
													}
													className="xsTitle"
													name={`menuItem[${index}].menuItemID`}
													inputRef={register(`menuItem[${index}].menuItemID`)}
													control={control}
												/>
											</div>
											<div
												className="desc text-noflow"
												style={{ paddingLeft: '30px' }}
											>
												{el.description}
											</div>
										</Grid>
										<Grid item xs={3}>
											<Controller
												as={
													<NumberFormat
														name={`menuItem[${index}].quantity`}
														customInput={TextField}
														allowNegative={false}
														className="quantity"
														thousandSeparator
														color="primary"
														autoComplete="off"
														disabled={enabler[index]}
														onChange={e => {
															register(`menuItem[${index}].quantity`);
															setValue(
																`menuItem[${index}].quantity`,
																e.target.value,
															);
														}}
														InputProps={{
															startAdornment: (
																<InputAdornment
																	className="highlight-text"
																	position="start"
																>
																	<div className="highlight-text xsTitle">
																		Qty
																	</div>
																</InputAdornment>
															),
														}}
													/>
												}
												style={{ margin: '0px', padding: '0px' }}
												id={`menuItem[${index}].quantity`}
												variant="outlined"
												size="small"
												fullWidth
												margin="dense"
												name={`menuItem[${index}].quantity`}
												data-id={index}
												defaultValue={0}
												ref={register}
												control={control}
											/>
										</Grid>
									</Grid>
								);
							})}
						</CardContents>
					)}
				</ContentWrapper>
			</form>
			<DynamicFooter
				options={[
					{
						name: 'Save',
						// onClick: () => {
						// 	handleSubmit(onSubmit)();
						// },
						color: 'primary',
						props: {
							type: 'submit',
						},
						onClick: () => {
							handleSubmit(data => !createUpdateCalled && onSubmit(data))();
						},
					},
				]}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/outlet-setting/${outletID}/set-menu/${setMenuID}/set-menu-standard-item`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push({
											pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu/${setMenuID}/set-menu-standard-item`,
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
