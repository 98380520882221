import { useLogoutMutation } from 'generated/graphql';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import { setAccessToken } from '../../AccessToken';
import localForage from 'localforage';
import { SocketContext } from 'containers/Socket/SocketContext';

export const Logout = () => {
	let history = useHistory();
	const [logout, { client }] = useLogoutMutation();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const outletID = localStorage.getItem('latestOutlet');
	const { socket}: any =
	useContext(SocketContext)
	
	useEffect(() => {
		async function userLogout() {
			await logout({
				variables: {
					ID: user?.ID,
					outletID: outletID,
				},
			});
			socket.emit('data', {Status : 'LOGOUT'})
			setAccessToken('');
			await client!.resetStore();
		}
		userLogout();
		localStorage.removeItem('loggedInUser');
		localForage.removeItem('permission');
		history.push({
			pathname: '/login',
			state: {
				mode: 'logout',
			},
		});
	}, []);

	return <></>;
};
