import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Box,
	Checkbox,
	Grid,
	ListItem,
	ListItemText,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import BigNumber from 'bignumber.js';
import { BillFooter } from 'components/Footer/BillFooter';
import { OrderItemList } from 'components/Order/OrderItemList';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { el } from 'date-fns/locale';
import {
	CalcType,
	CommonStatus,
	GetBillDiscountOrderDocument,
	GetOrderDiscountDocument,
	GetOrderDocument,
	GetOrderItemDiscountDocument,
	GetAllOrderItemDocument,
	OrderDiscountStatus,
	OrderItemDiscountStatus,
	OrderStatus,
	PosPermission,
	useCreateOrderDiscountMutation,
	useCreateOrderItemDiscountMutation,
	useDeleteOrderDiscountMutation,
	useDeleteOrderItemDiscountMutation,
	useGetBillDiscountOrderQuery,
	useGetCurrentMealPeriodLazyQuery,
	useGetDiscountQuery,
	useGetFilteredDiscountLazyQuery,
	useGetOutletNameQuery,
	useGetReasonLazyQuery,
	useGetAllOrderItemQuery,
	useGetUsersByAccountAndSoftwareQuery,
	useGetVoidedDiscountLazyQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { AuditDialog } from '../AuditLog/AuditDialog';

interface OrderItemDiscountProps {
	discountCode: string;
	discountID: string;
}
interface OrderDiscountProps {
	discountCode: string;
	discountID: string;
}
interface VoidDiscountProps {
	reasonCode: string;
	remark: string;
}

interface OrderItemTax {
	taxAmount: number;
	taxSchemeType: string;
}

interface OrderItemOptionItemTax {
	taxAmount: number;
}

interface OrderItemDiscountTax {
	taxAmount: number;
}

interface OrderItemOptionItemDiscountTax {
	taxAmount: number;
}

interface OrderDiscountItemTax {
	taxAmount: number;
}
interface orderDiscountItemOptionTax {
	taxAmount: number;
}

interface OrderDiscountItem {
	orderItemID?: string;
	baseAmount: number;
	orderDiscountItemTax?: OrderDiscountItemTax[];
}

interface OrderItemDiscount {
	orderItemID?: string;
	baseAmount: number;
	status: string;
	orderItemDiscountTax: OrderItemDiscountTax[];
}

interface OrderItemOptionItem {
	optionItemID: string;
	price: number;
	basePrice: number;
	unitPrice: number;
	orderItemOptionItemTax: OrderItemOptionItemTax[];
	discountBaseAmount: number;
	orderItemOptionItemDiscountTax: OrderItemOptionItemDiscountTax[];
	orderDiscountItemOptionTax: orderDiscountItemOptionTax[];
	// Include other properties if needed
}

interface OrderItemOption {
	optionID: string;
	orderItemOptionItem: OrderItemOptionItem[];
	// Include other properties if needed
}
interface Item {
	ID: string;
	amount: number;
	orderItemTax: OrderItemTax[];
	orderItemDiscount?: OrderItemDiscount;
	orderDiscountItem?: OrderDiscountItem;
	orderItemOption?: OrderItemOption[];
}

export const BillItemDiscount = (props: any) => {
	useEffect(() => {
		console.log('BillItemDiscount');
	}, []);
	const { mode } = props;
	const history = useHistory();
	const { pathname } = useLocation();
	const { [`tableID`]: tableID, outletID, orderID } = useParams<
		Record<string, any>
	>();
	// const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const [openDialogOID, setOpenDialogOID] = useState(false);
	const [openDialogOD, setOpenDialogOD] = useState(false);
	const [openVoidDialog, setOpenVoidDialog] = useState(false);
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);
	const [openAuditDialog, setOpenAuditDialog] = useState(false);
	const [partialPaid, setPartialPaid] = useState(false);
	const [negOrderItem, setNegOrderItem] = useState({});

	const { globalState }: any = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { handlePermDisabled } = usePermissionChecker();

	const { register, control, handleSubmit } = useForm<OrderItemDiscountProps>({
		defaultValues: {},
	});

	const {
		register: odRegister,
		handleSubmit: odHandleSubmit,
		control: odControl,
	} = useForm<OrderDiscountProps>({
		defaultValues: {},
	});

	const {
		register: voidRegister,
		control: voidControl,
		handleSubmit: voidHandleSubmit,
		watch,
		errors: voidErrors,
	} = useForm<VoidDiscountProps>({
		defaultValues: {},
		mode: 'onSubmit',
	});

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	//getOutlet
	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	//getOrder
	const {
		data: { getOrder } = { getOrder: [] },
		called: getOrderCalled,
		loading: getOrderLoading,
	} = useGetBillDiscountOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
			outletID: outletID,
			status: OrderStatus.Open,
		},
		onCompleted: () => {
			// loadDiscount({
			// 	variables: {
			// 		outletID,
			// 	},
			// });
		},
	});

	const [
		loadReason,
		{
			loading: reasonLoading,
			error: reasonError,
			called: reasonCalled,
			data: { getReason } = { getReason: [] },
		},
	] = useGetReasonLazyQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	const handleChangeOrder = (e, obj) => {
		history.push({
			pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${obj?.ID}/item-discount`,
		});
	};

	//getDiscount
	const {
		called,
		loading,
		error,
		data: { getDiscount } = { getDiscount: [] },
		loading: discountLoading,
	} = useGetDiscountQuery({
		fetchPolicy: 'network-only',
		// variables: { outletID: outletID },
		onCompleted: () => {
			// setOpenDialogOD(true);
		},
	});

	//getFilteredDiscount
	const [
		loadFilteredDiscount,
		{
			called: getFilteredDiscountCalled,
			loading: getFilteredDiscountLoading,
			error: getFilteredDiscountError,
			data: { getFilteredDiscount } = { getFilteredDiscount: [] },
		},
	] = useGetFilteredDiscountLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: () => {
			// setOpenDialogOID(true);
		},
	});

	const {
		data: { getAllOrderItem } = { getAllOrderItem: [] },
		loading: getAllOrderItemLoading,
	} = useGetAllOrderItemQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: orderID,
		},
		onCompleted: ({ getAllOrderItem }) => {
			setPartialPaid(
				getAllOrderItem?.filter(item => item?.billID !== null)?.length !== 0,
			);
			let unpaidOrderItem = getAllOrderItem?.filter(
				item => item?.billID === null,
			);

			let temp = {};
			unpaidOrderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				activeOID?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItem?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};

						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;
							let optionData;
							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});

								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});
			setNegOrderItem({ ...temp });
			// setItemArray(
			// 	unpaidOrderItem?.filter(
			// 		list2 => list2.quantity - (temp[list2?.ID] ?? 0) > 0,
			// 	),
			// );
		},
	});

	const itemArray = getAllOrderItem?.filter(
		list2 =>
			list2.quantity - (negOrderItem?.[list2?.ID]?.quantity ?? 0) > 0 &&
			list2?.billID === null,
	);

	const orderItemForOrderDiscount = getAllOrderItem
		?.filter(v => v?.orderItemDiscount?.length === 0)
		?.filter(x => x?.menuItem?.isDiscountable === true);

	const amountOrderItemForOrderDiscount = orderItemForOrderDiscount?.reduce(
		(a: any, b: any) => {
			return a + b?.amount;
		},
		0,
	);

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	//createOrderItemDiscount
	const [
		createOrderItemDiscount,
		{
			data: createdOrderItemDiscount,
			loading: createdOrderItemDiscountLoading,
		},
	] = useCreateOrderItemDiscountMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setTimeout(() => {
				if (mode === 'dineIn') {
					history.push({
						pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/item-discount`,
						state: { success: true, msgMode: 'create' },
					});
				} else if (mode === 'takeAway') {
					history.push({
						pathname: `/menu/outlet-app/${outletID}/order/${orderID}/item-discount/takeaway`,
						state: { success: true, msgMode: 'create' },
					});
				}
			}, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const onSubmitOID = (data: any) => {
		createOrderItemDiscount({
			variables: {
				input: {
					outletID: outletID,
					discountID: data.discountCode,
					orderItemID: selectedOrderItem?.ID,
					status: OrderItemDiscountStatus.Active,
					// amount: data.amount,
				},
				orderID: orderID,
			},
			refetchQueries: [
				{ query: GetOrderItemDiscountDocument, variables: { orderID } },
				{
					query: GetAllOrderItemDocument,
					variables: { orderID },
				},
				{
					query: GetBillDiscountOrderDocument,
					variables: { outletID: outletID, tableID, status: OrderStatus.Open },
				},
				{
					query: GetOrderDocument,
					variables: {
						...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});

		// if (selectedNegOrderItem) {
		// 	createOrderItemDiscount({
		// 		variables: {
		// 			input: {
		// 				outletID: outletID,
		// 				discountID: data.discountCode,
		// 				orderItemID: selectedNegOrderItem?.ID,
		// 				status: OrderItemDiscountStatus.Active,
		// 				amount: data.amount,
		// 			},
		// 			orderID: orderID,
		// 		},
		// 		refetchQueries: [
		// 			{ query: GetOrderItemDiscountDocument, variables: { orderID } },
		// 			{
		// 				query: GetAllOrderItemDocument,
		// 				variables: { orderID },
		// 			},
		// 			{
		// 				query: GetBillDiscountOrderDocument,
		// 				variables: { tableID, status: OrderStatus.Open },
		// 			},
		// 		],
		// 	});
		// }
		setOpenDialogOID(false);
	};

	//deleteOrderItemDiscount
	const [
		deleteOrderItemDiscount,
		{ loading: deleteOrderItemDiscountLoading },
	] = useDeleteOrderItemDiscountMutation({
		onError: error => {
			console.log('ERROR', error);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecordFail());
		},
		onCompleted: data => {
			setSelectedOrderItem(null);
			setSelectedNegOrderItem(null);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecord());
		},
	});

	//createOrderDiscount

	console.log(
		'orderitem',
		getAllOrderItem?.filter(x => x?.menuItem?.isDiscountable === true),
	);

	const discountable = getAllOrderItem?.filter(
		x => x?.menuItem?.isDiscountable === true,
	);

	const [
		createOrderDiscount,
		{ data: createdOrderDiscount, loading: createdOrderDiscountLoading },
	] = useCreateOrderDiscountMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			console.log('data', data);
			setTimeout(() => {
				if (mode === 'dineIn') {
					history.push({
						pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/item-discount`,
						state: { success: true, msgMode: 'create' },
					});
				} else if (mode === 'takeAway') {
					history.push({
						pathname: `/menu/outlet-app/${outletID}/order/${orderID}/item-discount/takeaway`,
						state: { success: true, msgMode: 'create' },
					});
				}
			}, 500);
			setOpenSnackBar(true);
			if (discountable?.length === 0 && data?.createOrderDiscount === false) {
				setSnackBarMsg('Discount not applicable');
			} else {
				setSnackBarMsg(SystemMsgs.createNewRecord());
			}
		},
	});

	const onSubmitOD = (data: any) => {
		createOrderDiscount({
			variables: {
				input: {
					outletID: outletID,
					discountID: data.discountCode,
					orderID: orderID,
					businessDate: getOutlet[0]?.businessDate,
					status: OrderDiscountStatus.Active,
					amount:
						getDiscount?.filter(
							discount => discount?.ID === data?.discountCode,
						)[0]?.calcType === CalcType.Amount
							? getDiscount?.filter(
									discount => discount?.ID === data?.discountCode,
							  )[0]?.amount
							: (total *
									getDiscount?.filter(
										discount => discount?.ID === data?.discountCode,
									)[0]?.amount) /
							  100,
				},
			},
			refetchQueries: [
				{ query: GetOrderDiscountDocument, variables: { orderID, outletID } },
				{
					query: GetAllOrderItemDocument,
					variables: { orderID },
				},
				{
					query: GetBillDiscountOrderDocument,
					variables: { outletID: outletID, tableID, status: OrderStatus.Open },
				},
				{
					query: GetOrderDocument,
					variables: {
						...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});
		setOpenDialogOD(false);
	};

	//deleteOrderDiscount
	const [
		deleteOrderDiscount,
		{ loading: deleteOrderDiscountLoading },
	] = useDeleteOrderDiscountMutation({
		onError: error => {
			console.log('ERROR', error);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecordFail());
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.voidRecord());
		},
	});

	// get users
	const {
		loading: userLoading,
		error: userError,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	//getVoidedDiscount

	const [
		voidedDiscount,
		{
			data: { getVoidedDiscount } = { getVoidedDiscount: [] },
			loading: voidedDiscountLoading,
		},
	] = useGetVoidedDiscountLazyQuery({
		fetchPolicy: 'network-only',
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: () => {
			console.log('treigger');
			setOpenAuditDialog(true);
		},
	});

	//setSelectedOrder
	const [selectedOrder, setSelectedOrder] = useState(null);

	useEffect(() => {
		setSelectedOrder(getOrder?.filter(order => order?.ID === orderID)[0]);
	}, [getOrder, orderID]);

	const [selectedOrderItem, setSelectedOrderItem] = useState(null);

	const [selectedNegOrderItem, setSelectedNegOrderItem] = useState(null);

	//checkbox for orderItemDiscount
	const handleCheckBoxOID = (e: any, index: number, el: any) => {
		handleClick(e, el.ID, index, el);

		const tempNegOI = getAllOrderItem?.filter(
			orderItem => orderItem?.refID === el?.ID,
		);

		if (e.target.checked) {
			setOpenDialogOID(true);
			setSelectedOrderItem(el);
			setSelectedNegOrderItem(tempNegOI);
			const oiAmt = el?.amount;
			const negOIAmt = tempNegOI.reduce(
				(previousValue, currentValue) => previousValue + currentValue?.amount,
				0,
			);
			loadFilteredDiscount({
				variables: {
					outletID: outletID,
					amount: new BigNumber(oiAmt).plus(negOIAmt ?? 0).toNumber(),
					isOrderItemDiscount: true,
				},
			});
		} else {
			setSelectedOrderItem(el);
			setSelectedNegOrderItem(tempNegOI);
			setOpenVoidDialog(true);
			loadReason();
		}
	};

	//checkbox for orderDiscount
	const handleCheckBoxOD = (e: any) => {
		if (e.target.checked) {
			setOpenDialogOD(true);
			loadFilteredDiscount({
				variables: {
					outletID: outletID,
					amount: fs,
					isOrderItemDiscount: false,
				},
			});
		} else {
			setOpenVoidDialog(true);
			loadReason();
		}
	};

	const onVoid = data => {
		if (selectedOrderItem?.ID) {
			deleteOrderItemDiscount({
				variables: {
					input: {
						orderItemID: selectedOrderItem?.ID,
						outletID: outletID,
						remark: data?.remark,
						reasonCode: data?.reasonCode,
					},
					orderID: orderID,
				},
				refetchQueries: [
					{ query: GetOrderItemDiscountDocument, variables: { orderID } },
					{
						query: GetAllOrderItemDocument,
						variables: { orderID },
					},
					{
						query: GetBillDiscountOrderDocument,
						variables: {
							outletID: outletID,
							tableID,
							status: OrderStatus.Open,
						},
					},
					{
						query: GetOrderDocument,
						variables: {
							...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
							status: OrderStatus.Open,
							outletID,
						},
					},
				],
			});
		} else {
			deleteOrderDiscount({
				variables: {
					input: {
						orderID: orderID,
						outletID: outletID,
						remark: data?.remark,
						reasonCode: data?.reasonCode,
					},
				},
				refetchQueries: [
					{ query: GetOrderItemDiscountDocument, variables: { orderID } },
					{
						query: GetAllOrderItemDocument,
						variables: { orderID },
					},
					{
						query: GetBillDiscountOrderDocument,
						variables: {
							outletID: outletID,
							tableID,
							status: OrderStatus.Open,
						},
					},
					{
						query: GetOrderDocument,
						variables: {
							...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
							status: OrderStatus.Open,
							outletID,
						},
					},
				],
			});
		}
		setOpenVoidDialog(false);
	};

	const orderItems = getOrder[0]?.orderItem
		?.map(el => {
			if (
				el?.quantity - negOrderItem?.[el?.ID]?.quantity === 0 ||
				el?.quantity < 0
			) {
				return { ...el, void: true };
			} else {
				return { ...el, void: false };
			}
		})
		?.filter(list => list.void === false);
	//CALCULATION
	let ttl,
		subtotal,
		finalServiceCharge,
		finalTax,
		total,
		discount,
		totalOptItm,
		discOptItm,
		optTaxAmount,
		optServiceCharge,
		discOptTaxAmount,
		discOptServiceCharge,
		orderDiscount;
	if (orderItems?.length > 0) {
		const itemArray = getAllOrderItem;
		const filterItemArray: {
			ID: string;
			amount: number;
			orderItemTax: OrderItemTax[];
			orderItemDiscount?: OrderItemDiscount;
			orderDiscountItem?: OrderDiscountItem;
			orderItemOption?: OrderItemOption[];
		}[] = itemArray.map(
			({
				ID,
				amount,
				orderItemTax,
				orderItemDiscount,
				orderDiscountItem,
				orderItemOption,
			}) => {
				const activeOID = orderItemDiscount?.filter(
					x => x?.status === OrderItemDiscountStatus.Active,
				)[0];

				return {
					ID,
					amount,
					orderItemTax: orderItemTax?.map(({ taxAmount, taxSchemeType }) => ({
						taxAmount,
						taxSchemeType,
					})),
					orderItemDiscount: {
						baseAmount: activeOID?.baseAmount ?? 0,
						status: activeOID?.status ?? '',
						orderItemID: ID,
						orderItemDiscountTax: activeOID
							? activeOID?.orderItemDiscountTax?.map(({ taxAmount }) => ({
									taxAmount,
							  }))
							: [{ taxAmount: 0 }],
					},
					orderDiscountItem: {
						baseAmount: orderDiscountItem?.baseAmount ?? 0,
						orderItemID: ID,
						orderDiscountItemTax: orderDiscountItem?.orderDiscountItemTax
							? orderDiscountItem?.orderDiscountItemTax?.map(
									({ taxAmount }) => ({
										taxAmount,
									}),
							  )
							: [{ taxAmount: 0 }],
					},

					orderItemOption: orderItemOption?.map(
						({ optionID, orderItemOptionItem }) => ({
							optionID,
							orderItemOptionItem: orderItemOptionItem.map(
								({
									optionItemID,
									price,
									basePrice,
									unitPrice,
									orderItemOptionItemTax,
									discountBaseAmount,
									orderItemOptionItemDiscountTax,
									orderDiscountItemOptionTax,
								}) => ({
									optionItemID,
									price,
									basePrice,
									unitPrice,
									orderItemOptionItemTax: orderItemOptionItemTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									discountBaseAmount,
									orderItemOptionItemDiscountTax: orderItemOptionItemDiscountTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									orderDiscountItemOptionTax: orderDiscountItemOptionTax?.map(
										({ taxAmount }) => ({
											taxAmount,
										}),
									),
									// Include other properties if needed
								}),
							),
							// Include other properties from OrderItemOption if needed
						}),
					),
				};
			},
		);

		//subtotal amount
		const sumAmount = (items: Item[]): number => {
			let totalBaseAmount = 0;
			for (const item of items) {
				totalBaseAmount += item.amount;
			}
			return totalBaseAmount;
		};

		const totalAmount = sumAmount(filterItemArray);

		//subtotal tax amount

		const sumTaxAmount = (items: Item[]): number => {
			let totalTax = 0;
			for (const item of items) {
				for (const tax of item?.orderItemTax) {
					totalTax += tax?.taxAmount;
				}
			}
			return totalTax;
		};

		const totalTaxAmount = sumTaxAmount(filterItemArray);
		const roundedTotalTaxAmount = parseFloat(totalTaxAmount.toFixed(2));

		//subtotal order discount item base amount

		const sumODIBaseAmount = (items: Item[]): number => {
			let totalODIBaseAmt = 0;
			for (const item of items) {
				totalODIBaseAmt += item?.orderDiscountItem?.baseAmount;
			}
			return totalODIBaseAmt;
		};

		const totalODIBaseAmount = sumODIBaseAmount(filterItemArray);

		//subtotal order discount item tax amount

		const sumODITaxAmount = (items: Item[]): number => {
			let totalTax = 0;
			for (const item of items) {
				for (const tax of item?.orderDiscountItem?.orderDiscountItemTax)
					totalTax += tax?.taxAmount;
			}
			return totalTax;
		};

		const totalODITaxAmount = sumODITaxAmount(filterItemArray);
		const roundedTotalODITaxAmount = parseFloat(totalODITaxAmount.toFixed(2));

		//subtotal order item discount base amount
		const sumOIDBaseAmount = (items: Item[]): number => {
			let totalOIDBaseAmt = 0;
			for (const item of items) {
				totalOIDBaseAmt += item?.orderItemDiscount?.baseAmount;
			}
			return totalOIDBaseAmt;
		};

		const totalOIDBaseAmount = sumOIDBaseAmount(filterItemArray);

		//subtotal order item discount tax amount

		const sumOIDTaxAmount = (items: Item[]): number => {
			let totalOIDTax = 0;
			for (const item of items) {
				for (const tax of item?.orderItemDiscount?.orderItemDiscountTax)
					totalOIDTax += tax?.taxAmount;
			}
			return totalOIDTax;
		};

		const totalOIDTaxAmount = sumOIDTaxAmount(filterItemArray);
		const roundedTotalOIDTaxAmount = parseFloat(totalOIDTaxAmount.toFixed(2));

		//overall option item important field to sum up
		const sumOptionItemOverallField = (
			items: Item[],
		): {
			basePrice: number;
			taxAmount: number;
			discountBaseAmount: number;
			itemDiscountTaxAmount: number;
			discountItemTaxAmount: number;
		} => {
			let totalBasePriceOpt = 0;
			let totalTaxAmountOpt = 0;
			let totalDiscBaseAmountOpt = 0;
			let totalItemDiscTaxAmountOpt = 0;
			let totalDiscItemTaxAmountOpt = 0;
			for (const item of items) {
				if (item.orderItemOption) {
					for (const option of item.orderItemOption) {
						if (option.orderItemOptionItem) {
							for (const optionItem of option.orderItemOptionItem) {
								let totalTax = 0;
								optionItem?.orderItemOptionItemTax?.map(x => {
									totalTax += x?.taxAmount;
								});

								let totalOptItemOIDTax = 0;
								optionItem?.orderItemOptionItemDiscountTax?.map(x => {
									totalOptItemOIDTax += x?.taxAmount;
								});

								let totalOptItemODITax = 0;
								optionItem?.orderDiscountItemOptionTax?.map(v => {
									totalOptItemODITax += v?.taxAmount;
								});

								totalBasePriceOpt += optionItem.basePrice;
								totalTaxAmountOpt += totalTax;
								totalDiscBaseAmountOpt += optionItem.discountBaseAmount;
								totalItemDiscTaxAmountOpt += totalOptItemOIDTax;
								totalDiscItemTaxAmountOpt += totalOptItemODITax;
							}
						}
					}
				}
			}
			return {
				basePrice: totalBasePriceOpt,
				taxAmount: parseFloat(totalTaxAmountOpt.toFixed(2)),
				discountBaseAmount: totalDiscBaseAmountOpt,
				itemDiscountTaxAmount: parseFloat(totalItemDiscTaxAmountOpt.toFixed(2)),
				discountItemTaxAmount: parseFloat(totalDiscItemTaxAmountOpt.toFixed(2)),
			};
		};

		const totalOptItmOverallAmount = sumOptionItemOverallField(filterItemArray);

		optTaxAmount = totalOptItmOverallAmount?.taxAmount;

		discOptTaxAmount =
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		totalOptItm =
			totalOptItmOverallAmount?.basePrice + totalOptItmOverallAmount?.taxAmount;

		discOptItm =
			totalOptItmOverallAmount?.discountBaseAmount +
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		//tax
		finalTax =
			roundedTotalTaxAmount +
			optTaxAmount -
			roundedTotalODITaxAmount -
			roundedTotalOIDTaxAmount -
			discOptTaxAmount;

		//subtotal
		subtotal = totalAmount + roundedTotalTaxAmount + totalOptItm;

		//discount
		let ODI = totalODIBaseAmount + roundedTotalODITaxAmount;

		let OID = totalOIDBaseAmount + roundedTotalOIDTaxAmount;

		discount = ODI + OID + discOptItm;

		//total
		total = subtotal - discount;
	}

	//final total

	// const dsc = getAllOrderItem?.map((el) => {
	// 	return el?.orderItemDiscount?.amount > el?.amount
	// 		? el?.amount
	// 		: el?.orderItemDiscount
	// 		? el?.orderItemDiscount?.amount
	// 		: null;
	// });

	// const discount = dsc.reduce((accumulator: any, currentValue: any) => {
	// 	return accumulator + currentValue;
	// }, 0);

	const [fs, setFS] = useState(null);
	const [ta, setTa] = useState(null);
	const [fd, setFD] = useState(null);
	const [to, setTo] = useState(null);

	useEffect(() => {
		setFS(subtotal);
		setTa(finalTax);
		setFD(discount);
		setTo(total);
	}, [subtotal, finalTax, discount, total]);

	const calcTotalBasePrice = bPrice => {
		const totalBasePrice = bPrice.reduce((x: any, y: any) => {
			return Number(x) + Number(y);
		}, 0);

		return totalBasePrice;
	};

	const auditLogListing = (data, index) => {
		return (
			<>
				<ListItem key={data?.ID}>
					<ListItemText
						primary={
							<>
								<span className="mdDesc" style={{ paddingRight: '5px' }}>
									{index + 1 + '.'}
								</span>
								<span className="xsTitle flex-space">
									{data?.name ? `${data.name} - ${data.type}` : data.type}
								</span>
								<span className="xsTitle right-info m-t-6">{data?.status}</span>
							</>
						}
						secondary={
							<>
								<span
									className="xsTitle flex-space"
									style={{ marginLeft: '20px' }}
								>
									{getOutlet[0].currencyCode + data?.amount}
									<span className=" right-info" style={{ marginLeft: '20px' }}>
										{
											getUsersByAccountAndSoftware?.filter(
												x => x?.ID === data?.modBy || x?.ID === data?.createdBy,
											)[0]?.name
										}
									</span>
								</span>

								<span className="extra-row">
									<span
										className="icon-text desc text-overflow flex-space"
										style={{ marginLeft: '20px' }}
									>
										{data?.reasonCode}
									</span>
								</span>

								<span className="extra-row">
									<span
										className="icon-text desc text-overflow flex-space"
										style={{ marginLeft: '20px' }}
									>
										{data?.remark}
									</span>
								</span>
							</>
						}
					/>
				</ListItem>
			</>
		);
	};

	return (
		<>
			{outletLoading && <Loading />}
			{getOrderLoading && <Loading />}
			{discountLoading && <Loading />}
			{getFilteredDiscountLoading && <Loading />}
			{getAllOrderItemLoading && <Loading />}
			{createdOrderItemDiscountLoading && <Loading />}
			{deleteOrderItemDiscountLoading && <Loading />}
			{createdOrderDiscountLoading && <Loading />}
			{deleteOrderDiscountLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{voidedDiscountLoading && <Loading />}
			<MainHeader
				onClick={() => {
					if (mode === 'dineIn') {
						history.push({
							pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}`,
						});
					} else if (mode === 'takeAway') {
						history.push({
							pathname: `/menu/outlet-app/${outletID}/order/${orderID}/takeaway/detail`,
						});
					}
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				// currency={
				// 	<span className="xxTitle flex-space" style={{ color: 'white' }}>
				// 		Table
				// 		<span className="title flex-space" style={{ color: 'orange' }}>
				// 			{' ' + selectedOrder?.table?.tableNumber}
				// 		</span>
				// 	</span>
				// }
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Table 03 (HARDCODE)' },
					// { name: 'Bill' },
					{ name: 'Bill / Item Discount (New & Void)', current: true },
				]}
			/>
			<OrderSubheader
				mode={mode}
				getOrderLoading={getOrderLoading}
				getOrder={getOrder}
				selectedOrder={selectedOrder}
				orderID={selectedOrder?.ID}
				handleChangeOrder={handleChangeOrder}
				outletID={outletID}
				isAuditDialog={true}
				setOpenAuditDialog={setOpenAuditDialog}
				getVoidedDiscountQuery={voidedDiscount}
			/>
			<ContentWrapper
				singleDynamicInfo
				// multiLineSubheader={false}
				multiLineSubheader={mode === 'takeAway' ? true : false}
			>
				{!outletLoading &&
					!getOrderLoading &&
					!getCurrentMealPeriodLoading &&
					// !discountLoading &&
					// !getDiscountForOrderItemLoading &&
					!getAllOrderItemLoading &&
					(itemArray === undefined || itemArray?.length === 0 ? (
						<EmptyList
							title="No confirmed order item fou	nd"
							subtitle="Add a order item now."
						/>
					) : (
						<>
							<OrderItemList
								outletID={outletID}
								itemArray={itemArray}
								negOrderItem={negOrderItem}
								rightIcon="checkbox"
								rightAction={handleCheckBoxOID}
								page="bill-item-discount"
								hasOrderDiscount={
									selectedOrder?.orderDiscount?.filter(
										disc => disc?.status === OrderDiscountStatus.Active,
									)?.length !== 0
								}
								currencyCode={getOutlet[0].currencyCode ?? ''}
							/>
							<BillFooter
								style={{
									width:
										isDesktop && globalState.drawerOpen
											? 'calc(100% - 308px)'
											: isDesktop && !globalState.drawerOpen
											? 'calc(100% - 75px)'
											: '91%',
									left: !isDesktop && !globalState.drawerOpen ? 0 : null,
									zIndex: 40,
									bottom: '54px',
								}}
								page="bill-item-discount"
								subtotal={fs}
								tax={ta}
								discount={fd}
								total={to}
								currencyCode={getOutlet[0].currencyCode ?? ''}
								outletID={outletID}
								taxSchemeInfo={selectedOrder?.taxSchemeInfo}
								taxSchemeFooterInfo={selectedOrder?.taxSchemeFooterInfo}
								takeaway={mode === 'takeAway' ? true : false}
								orderID={selectedOrder?.ID}
							/>
						</>
					))}
			</ContentWrapper>
			<div style={{ paddingTop: '200px' }}></div>
			<div
				className="item-discount-footer"
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
					// width:
					// 	isDesktop && globalState.drawerOpen
					// 		? 'calc(100% - 305px)'
					// 		: isDesktop && !globalState.drawerOpen
					// 		? 'calc(100% - 70px)'
					// 		: '95%',
				}}
			>
				{/* <br /> */}
				{!getOrderLoading && (
					<Checkbox
						checked={
							selectedOrder?.orderDiscount?.filter(
								disc => disc?.status === OrderDiscountStatus.Active,
							)?.length !== 0 || openDialogOD
						}
						onChange={e => {
							if (
								!partialPaid &&
								itemArray !== undefined &&
								itemArray?.length !== 0
							) {
								handleCheckBoxOD(e);
							}
						}}
						color="primary"
						inputProps={{ 'aria-label': 'secondary checkbox' }}
						style={{ padding: '5px', marginLeft: '4vw' }}
						disabled={
							handlePermDisabled({
								outletID: outletID,
								permEnum:
									PosPermission.HomeTableLayoutBillItemDiscountCreateOrderDiscountCreate,
							}) ||
							partialPaid ||
							(itemArray === undefined || itemArray?.length === 0
								? true
								: false)
						}
					/>
				)}
				{/* <span className="mdDesc"> */}
				{selectedOrder?.orderDiscount?.filter(
					disc => disc?.status === OrderDiscountStatus.Active,
				)?.length !== 0 ? (
					<span className="mdDesc highlight-text">
						{
							getDiscount?.filter(
								disc =>
									disc?.ID ===
									selectedOrder?.orderDiscount?.filter(
										disc => disc?.status === OrderDiscountStatus.Active,
									)[0]?.discountID,
							)[0]?.code
						}
					</span>
				) : (
					<span
						className="mdDesc"
						style={{
							color: `${
								!partialPaid &&
								itemArray !== undefined &&
								itemArray?.length !== 0
									? 'black'
									: 'darkgray'
							}`,
						}}
					>
						Order Discount
					</span>
				)}
				{/* </span> */}
			</div>

			{/* order item discount */}
			<form onSubmit={handleSubmit(onSubmitOID)} id="submitOID-form">
				{/* <Box mt={1}> */}
				<CommonDialogV2
					fullWidth={true}
					open={openDialogOID}
					onClose={() => setOpenDialogOID(false)}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title">Item Discount</div>
											{/* <div className="title">
													{selectedOrderItem?.menuItem?.name}
												</div> */}
										</div>
									</div>
									<div className="infoline-content ">
										<div className="xsTitle infoline">
											{selectedOrderItem?.menuItem?.name}
										</div>
									</div>
								</div>
							),
						},
						body: () => (
							<>
								<div
									className="content-container "
									style={{ overflow: 'hidden' }}
								>
									<Controller
										as={
											<TextField name="discountCode" ref={register}>
												{getFilteredDiscount?.map(x => (
													<MenuItem key={x.ID} value={x.ID}>
														{x.code}
													</MenuItem>
												))}
											</TextField>
										}
										name="discountCode"
										margin="normal"
										label="Discount Code.*"
										fullWidth
										autoComplete="off"
										multiline={true}
										select
										control={control}
										ref={register}
										style={{ paddingLeft: '5px' }}
									/>
								</div>
							</>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => setOpenDialogOID(false),
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										//onClick: () => handleSubmit(onSubmit)(),
										variant: 'contained',
										form: 'submitOID-form',
										color: createdOrderItemDiscountLoading
											? 'inherit'
											: 'primary',
										disabled: createdOrderItemDiscountLoading ? true : false,
										type: 'submit',
									},
								},
							],
						},
					}}
				/>
				{/* </Box> */}
			</form>

			{/* order discount */}
			<form onSubmit={odHandleSubmit(onSubmitOD)} id="submitOD-form">
				{/* <Box mt={1}> */}
				<CommonDialogV2
					fullWidth={true}
					open={openDialogOD}
					onClose={() => setOpenDialogOD(false)}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title">Order Discount</div>
										</div>
									</div>
									<div className="infoline-content ">
										<div className="xsTitle infoline">
											{selectedOrder?.docNo}{' '}
										</div>
									</div>
								</div>
							),
						},
						body: () => (
							<>
								<div className="content-container">
									<Controller
										as={
											<TextField name="discountCode" ref={odRegister}>
												{getFilteredDiscount?.map(x => (
													<MenuItem key={x.ID} value={x.ID}>
														{x.code}
													</MenuItem>
												))}
											</TextField>
										}
										name="discountCode"
										margin="normal"
										label="Discount Code.*"
										fullWidth
										autoComplete="off"
										multiline={true}
										select
										control={odControl}
										ref={odRegister}
									/>
								</div>
							</>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => setOpenDialogOD(false),
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										// onClick: () => odHandleSubmit(onSubmitOD)(),
										variant: 'contained',
										form: 'submitOD-form',
										color: createdOrderDiscountLoading ? 'inherit' : 'primary',
										disabled: createdOrderDiscountLoading ? true : false,
										type: 'submit',
									},
								},
							],
						},
					}}
				/>
				{/* </Box> */}
			</form>

			{/* reason */}
			<CommonDialogV2
				fullWidth={true}
				open={openVoidDialog}
				onClose={() => {
					setOpenVoidDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{`Void ${
											selectedOrderItem?.ID ? 'Item Discount' : 'Discount'
										}`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="mdDesc">
								<Grid container className="mdDesc">
									<Grid item xs={12} style={{ marginBlockStart: '10px' }}>
										<Controller
											as={
												<TextField select size="small" label="Reason">
													{getReason?.map((x, index) => (
														<MenuItem key={index} value={x?.name}>
															{x?.name}
														</MenuItem>
													))}
												</TextField>
											}
											name="reasonCode"
											autoComplete="off"
											control={voidControl}
											ref={voidRegister}
											multiline
											fullWidth
											required
											margin="normal"
											helperText={voidErrors?.reasonCode?.message}
											error={voidErrors?.reasonCode ? true : false}
										></Controller>
									</Grid>
									<Grid item xs={12}>
										<Controller
											as={<TextField />}
											name="remark"
											label="Remark"
											autoComplete="off"
											control={voidControl}
											ref={voidRegister}
											multiline
											required
											fullWidth
											margin="normal"
											helperText={voidErrors?.remark?.message}
											error={voidErrors?.remark ? true : false}
										></Controller>
									</Grid>
								</Grid>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenVoidDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										voidHandleSubmit(onVoid)();
									},
									variant: 'contained',
									type: 'submit',
									color:
										watch('reasonCode') === undefined ||
										watch('reasonCode') === null ||
										watch('remark') === undefined ||
										watch('remark') === null ||
										watch('remark') === '' ||
										deleteOrderItemDiscountLoading ||
										deleteOrderDiscountLoading
											? 'inherit'
											: 'primary',
									disabled:
										watch('reasonCode') === undefined ||
										watch('reasonCode') === null ||
										watch('remark') === undefined ||
										watch('remark') === null ||
										watch('remark') === '' ||
										deleteOrderItemDiscountLoading ||
										deleteOrderDiscountLoading,
								},
							},
						],
					},
				}}
			/>

			<AuditDialog
				openAuditDialog={openAuditDialog}
				setOpenAuditDialog={setOpenAuditDialog}
				auditArray={getVoidedDiscount}
				listItemDisplayFn={auditLogListing}
			/>
		</>
	);
};
