import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { IconButton, List, ListItem, ListItemText } from '@material-ui/core';
import EmailIcon from '@material-ui/icons/Email';
import PhoneIcon from '@material-ui/icons/Phone';
import React, { useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { WhatsappIcon } from 'react-share';

export const BankAccountDetail = () => {
	useEffect(() => {
		console.log('BankAccountDetail');
	}, []);
	let history = useHistory();
	let location = useLocation();
	let bankAccountData: any = location.state;
	const { BankAccID }: any = useParams();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	// const {
	// 	loading: bankAccountLoading,
	// 	data: { getBankAccount } = { getBankAccount: [] },
	// } = useGetBankAccountQuery({
	// 	fetchPolicy: 'network-only',
	// 	variables: { ID: BankAccID },
	// });

	// const bankAccountData: any[] = getBankAccount;

	return (
		<>
			{/* {bankAccountLoading && <Loading />} */}
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/account-journals/account-mapping/bank-account`,
					})
				}
				mainBtn="back"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: 'Account Mapping' },
					{ name: 'Bank Account', current: true },
				]}
				rightRouteSegments={[
					{
						name: 'Details',
						current: true,
					},
				]}
			/>
			<List className="core-list subheader single">
				<ContentWrapper>
					<CardContents>
						<div className="content-wrap left">
							<div className="desc" style={{ color: 'GrayText' }}>
								Bank Name
							</div>
							<div className="mdDesc" style={{ fontWeight: 'bold' }}>
								{' '}
								{bankAccountData?.bankName}
							</div>
						</div>

						<div className="content-wrap full">
							<div className="desc" style={{ color: 'GrayText' }}>
								Account Holder Name
							</div>
							<div className="mdDesc" style={{ fontWeight: 'bold' }}>
								{bankAccountData?.accountName}
							</div>
						</div>

						<div className="content-wrap full">
							<div className="desc" style={{ color: 'GrayText' }}>
								Bank Account No.
							</div>
							<div className="mdDesc" style={{ fontWeight: 'bold' }}>
								{bankAccountData?.accountNo}
							</div>
						</div>

						<div className="content-wrap full">
							<div className="desc" style={{ color: 'GrayText' }}>
								Bank Swift Code
							</div>
							<div className="mdDesc" style={{ fontWeight: 'bold' }}>
								{' '}
								{bankAccountData?.SWIFTCode}
							</div>
						</div>

						<div className="content-wrap full">
							<div className="desc" style={{ color: 'GrayText' }}>
								Bank Branch
							</div>
							<div className="mdDesc" style={{ fontWeight: 'bold' }}>
								{' '}
								{bankAccountData?.branch}
							</div>
						</div>
					</CardContents>
					<CardContents
						contentStyle={{
							display: 'contents',
						}}
						section={{
							header: {
								title: 'Contact Details',
							},
						}}
					>
						<List>
							{bankAccountData?.bankAccountContact?.map((el, index) => {
								return (
									<>
										<ListItem
											style={{ backgroundColor: 'white', boxShadow: 'none' }}
										>
											<ListItemText
												primary={
													<>
														<span className="desc">
															<span className="xsTitle">{el.name}</span>
															{` `}
															<span className="desc">({el.designation})</span>
														</span>
													</>
												}
												secondary={
													<span className="desc">
														<span className="desc">
															<span>
																<PhoneIcon
																	style={{
																		fontSize: '10px',
																		color: 'black',
																		marginBottom: '-1px',
																		marginRight: '-1px',
																	}}
																/>
																<span
																	className={`primary-click-text`}
																	onClick={e => {
																		window.open(`tel:${el.mobileNo}`);
																	}}
																>
																	+{el.mobileNo}
																</span>
																{` | `}
																<span>
																	<EmailIcon
																		style={{
																			fontSize: '12px',
																			color: 'black',
																			marginBottom: '-3px',
																			marginRight: '3px',
																		}}
																	/>
																	<span
																		className={`primary-click-text`}
																		onClick={e => {
																			window.open(`mailto: ${el.email}`);
																		}}
																	>
																		{el.email}
																	</span>
																</span>
															</span>
														</span>
													</span>
												}
											/>
											<IconButton edge="end">
												<WhatsappIcon size={40} round={true} />
											</IconButton>
										</ListItem>
									</>
								);
							})}
						</List>
					</CardContents>
				</ContentWrapper>
			</List>
		</>
	);
};
