import { Alert, AlertProps } from '@material-ui/lab'
import React from 'react'
import Icon from 'assets/icons/advances.svg'
interface Props {
  message?: string
  icon?: any
}
export const MsgAlert = (props: Props) => {
  return (
    <div {...props} className="message-alert icon-text">
      <span className="icon">{props.icon}</span>
      <span className="desc c-darkblue">{props.message}</span>
    </div>
  )
}

export const MsgAlertWIcon = (props: Props) => {
  return (
    <div {...props} className="message-alert-icon icon-text">
      <span className="icon">{props.icon}</span>
      <span className="desc c-darkblue">{props.message}</span>
    </div>
  )
}
