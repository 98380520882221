import DateFnsUtils from '@date-io/date-fns';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import { CardActions, ListItemText, useMediaQuery } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import AppContext from 'containers/App/Store/AppContext';
import {
	useGetDiscountTypeAnalysisLazyQuery,
	useGetOutletQuery,
	useGetShareAttachmentMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import * as htmlToImage from 'html-to-image';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Bar,
	BarChart,
	LabelList,
	Legend,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import ShareDialog from '../../../../../components/Dialog/ShareDialog';
import '../bi.scss';

export const DiscountTypeAnalysis = () => {
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const history = useHistory();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const latestOutlet = localStorage.getItem('latestOutlet');

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	const [selectedStartDate, setSelectedStartDate] = useState(firstDay);
	const [selectedEndDate, setSelectedEndDate] = useState(lastDay);

	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date);
	};
	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date);
	};

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);

	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const onSubmit = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: selectedOutlet?.ID,
			},
		});
	};

	const {
		data: { getOutlet } = { getOutlet: [] },
		called: getOutletCalled,
		loading: getOutletLoading,
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
	});

	const [selectedOutlet, setSelectedOutlet] = useState(null);
	useEffect(() => {
		setSelectedOutlet(
			getOutlet?.filter(outlet => outlet?.ID === latestOutlet)[0],
		);
	}, [getOutlet]);

	const [
		loadDiscountTypeAnalysis,
		{
			data: { discountTypeAnalysis } = { discountTypeAnalysis: [] },
			called: discountTypeAnalysisCalled,
			loading: discountTypeAnalysisLoading,
		},
	] = useGetDiscountTypeAnalysisLazyQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (selectedOutlet && selectedStartDate && selectedEndDate) {
			loadDiscountTypeAnalysis({
				variables: {
					outletID: selectedOutlet?.ID,
					startDate: selectedStartDate,
					endDate: selectedEndDate,
				},
			});
		}
	}, [selectedOutlet, selectedStartDate, selectedEndDate]);

	const renderBillCountLabel = (props: any) => {
		const { x, y, width, height, value } = props;
		const radius = 10;
		return (
			<g>
				<text
					x={x + height / 4 + radius / 4}
					y={y + radius / 5 + height / 2}
					fill="#fff"
					textAnchor="start"
					dominantBaseline="middle"
					fontSize="12px"
				>
					{value}
				</text>
			</g>
		);
	};

	const renderDiscountSumLabel = (props: any) => {
		const { x, y, width, height, value } = props;
		const radius = 10;
		return (
			<g>
				<text
					x={x + height / 4 + radius / 4}
					y={y + radius / 5 + height / 2}
					fill="#fff"
					textAnchor="start"
					dominantBaseline="middle"
					fontSize="12px"
				>
					{value}
				</text>
			</g>
		);
	};

	const CustomizedXAxisLabel = () => {
		return (
			<>
				<text
					x={0}
					y={0}
					dx="50%"
					dy="5%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={16}
					fontWeight={500}
					width={180}
				>
					{`${selectedOutlet?.name}`}
				</text>
				<text
					x={0}
					y={0}
					dx="50%"
					dy="10%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={12}
					fontWeight={350}
					width={180}
				>
					{`Discount Type Analysis  ${formatDate(
						selectedStartDate.toISOString(),
					)} - ${formatDate(selectedEndDate.toISOString())} `}
				</text>
			</>
		);
	};

	return (
		<>
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/business-insight/business-analytic`,
					})
				}
				mainBtn="back"
				smTitle="Business Insights"
				// HC
				title={user?.companyName}
				routeSegments={[
					{ name: 'Business Insights' },
					{ name: 'Discount Type Analysis', current: true },
				]}
			/>

			{!getOutletLoading && (
				<DynamicSubHeader
					// style={{
					// 	width:
					// 		isDesktop && globalState.drawerOpen
					// 			? 'calc(100% - 288px)'
					// 			: isDesktop && !globalState.drawerOpen
					// 			? `calc(100% - 48px)`
					// 			: null,
					// }}
					title={
						getOutlet === undefined || getOutlet?.length === 0
							? ``
							: `${selectedOutlet?.name}`
					} // title text
					handleClick={e => handleClick(e, null, 0)} // onClick function for dropdown
					dropDownOptions={
						// dropdown component place here
						<StyledMenu
							id="customized-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
							onClick={handleClose}
						>
							{getOutlet.map((outlet, index) => (
								<StyledMenuItem
									key={index}
									value={outlet.ID}
									defaultValue={outlet.ID}
									onClick={e => {
										setSelectedOutlet(outlet);
									}}
								>
									<ListItemText primary={outlet?.name} />
								</StyledMenuItem>
							))}
						</StyledMenu>
					}
				/>
			)}
			<ContentWrapper search footer>
				<CardActions>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							id="date-picker-dialog"
							label="Start Date"
							style={{
								background: 'white',
								width: '50%',
								zIndex: 40,
							}}
							format="dd MMM yyyy"
							value={selectedStartDate}
							onChange={handleStartDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							inputVariant="outlined"
							size="small"
							className="left"
						/>

						<KeyboardDatePicker
							id="date-picker-dialog"
							label="End Date"
							format="dd MMM yyyy"
							value={selectedEndDate}
							style={{
								background: 'white',
								width: '50%',
								zIndex: 40,
							}}
							onChange={handleEndDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							inputVariant="outlined"
							size="small"
							className="right"
						/>
					</MuiPickersUtilsProvider>
					<div style={{ height: '50px' }}></div>
				</CardActions>
				{!discountTypeAnalysisLoading &&
				!getOutletLoading &&
				(discountTypeAnalysis === undefined ||
					discountTypeAnalysis?.length === 0) ? (
					<EmptyList
						title={`No discount type created for ${selectedOutlet?.name}`}
						subtitle="Please add a discount type and create a new order to continue"
					/>
				) : (
					!discountTypeAnalysisLoading &&
					!getOutletLoading && (
						<>
							{discountTypeBarChart(
								discountTypeAnalysis,
								CustomizedXAxisLabel,
								renderBillCountLabel,
								renderDiscountSumLabel,
							)}
							{discountTypeBarChart(
								discountTypeAnalysis,
								CustomizedXAxisLabel,
								renderBillCountLabel,
								renderDiscountSumLabel,
								true,
							)}
							<div className="cover-layer">
								<CardContents>
									<></>
								</CardContents>
							</div>
						</>
					)
				)}
			</ContentWrapper>

			{ShareDialog({
				shareTitle: `Discount Type Analysis - ${selectedOutlet?.name}`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}

			<DynamicFooter
				overlap
				// isHidden={openShareDialog}
				options={[
					{
						name: 'Share',
						onClick: async () => {
							let temp = await document.getElementById('hiddenBarChart');
							htmlToImage
								.toBlob(temp, { style: { background: '#fff' } })
								.then(value => {
									onSubmit(value);
								});
						},
						disabled:
							discountTypeAnalysis == undefined ||
							discountTypeAnalysis.length == 0,
						color: 'primary',
						props: {
							type: 'submit',
						},
						startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
					},
				]}
			/>
		</>
	);
};

const heightConst = 300;
const discountTypeBarChart = (
	discountTypeAnalysis,
	CustomizedXAxisLabel,
	renderBillCountLabel,
	renderDiscountSumLabel,
	hidden?: Boolean,
) => {
	return (
		<div
			style={
				hidden
					? {
							zIndex: -40,
							top: 0,
							left: 0,
							position: 'fixed',
							width: '100%',
					  }
					: {
							marginTop: '0px',
							zIndex: 40,
							position: 'relative',
					  }
			}
			className={hidden ? 'hidden-content' : null}
		>
			<CardContents
				section={
					!hidden
						? {
								header: {
									title: 'All',
								},
						  }
						: null
				}
			>
				<div
					style={{
						width: '100%',
						height: !hidden ? heightConst : heightConst + 44,
					}}
					id={!hidden ? 'barChart' : 'hiddenBarChart'}
				>
					<ResponsiveContainer>
						<BarChart
							data={discountTypeAnalysis}
							height={20}
							layout="vertical"
							margin={{
								top: hidden ? 50 : 10,
								right: 10,
								left: 10,
								bottom: 10,
							}}
						>
							<XAxis
								type="number"
								fontSize={10}
								tickLine={false}
								axisLine={false}
								label={hidden ? CustomizedXAxisLabel : null}
							/>

							<YAxis
								dataKey="name"
								type="category"
								fontSize={10}
								tickLine={false}
								axisLine={false}
							/>
							<Tooltip />

							<Legend
								align="center"
								wrapperStyle={{ fontSize: '10px' }}
								verticalAlign={'bottom'}
							/>

							<Bar
								dataKey="billCount"
								name="Bill Count"
								fill="#ff8c00"
								barSize={20}
								values="billCount"
							>
								<LabelList
									dataKey="billCount"
									fill="#fff"
									textAnchor="end"
									angle={-90}
									dominantBaseline="end"
									content={renderBillCountLabel}
								/>
							</Bar>
							<Bar
								dataKey="discountSum"
								name="Amount"
								fill="#4f86f7"
								barSize={20}
								values="discountSum"
							>
								<LabelList
									dataKey="discountSum"
									fill="#fff"
									textAnchor="end"
									angle={-90}
									dominantBaseline="end"
									content={renderDiscountSumLabel}
								/>
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
			</CardContents>
		</div>
	);
};
