import { IconButton, ListItemSecondaryAction } from '@material-ui/core'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown'
import React from 'react'

interface IDropdownBar {
  options: any
  className?: React.CSSProperties
  borderRadius?: string
  setOptionID?: React.Dispatch<string>
}

export default function DropdownBar(props) {
  const { options, borderRadius, setOptionID } = props
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const [selectedIndex, setSelectedIndex] = React.useState(0)
  const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuItemClick = (
    // event: React.MouseEvent<HTMLElement>,
    event: any,
    index: number
  ) => {
    // console.log(event.target.value, 'gg');
    setOptionID(options[index].ID)
    setSelectedIndex(index)
    setAnchorEl(null)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }
  // console.log(selectedIndex)
  return (
    <div className="filter-dropdown">
      <List
        disablePadding
        className="search-header"
        style={{ borderRadius: borderRadius }}
      >
        <ListItem
          button
          aria-haspopup="true"
          aria-controls="lock-menu"
          onClick={handleClickListItem}
        >
          <ListItemText
            disableTypography
            primary={
              <span className="xsTitle">{options[selectedIndex]?.name}</span>
            }
          />
          <ListItemSecondaryAction>
            <IconButton
              edge="end"
              aria-label="arrow"
              onClick={handleClickListItem}
            >
              <KeyboardArrowDownIcon />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </List>
      <Menu
        id="lock-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {options?.map((option, index) => (
          <MenuItem
            key={index}
            selected={index === selectedIndex}
            value={option.ID}
            onClick={event =>
              option.unclickable ? () => {} : handleMenuItemClick(event, index)
            }
          >
            {option.name}
          </MenuItem>
        ))}
      </Menu>
    </div>
  )
}
