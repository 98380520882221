import DateFnsUtils from '@date-io/date-fns';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import {
	CardActions,
	ListItemText,
	MenuItem,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import AppContext from 'containers/App/Store/AppContext';
import {
	useFastMovingMenuItemAnalysisLazyQuery,
	useGetBizInsightOutletQuery,
	useGetFamilyQuery,
	useGetMajorQuery,
	useGetMealPeriodQuery,
	useGetShareAttachmentMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import * as htmlToImage from 'html-to-image';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import ShareDialog from '../../../../../components/Dialog/ShareDialog';
import '../bi.scss';

interface FastMovingProps {
	major: string;
	family: string;
	mealPeriod: string;
	startDate: Date;
	endDate: Date;
}

export const FastMovingAnalysis = () => {
	const history = useHistory();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const latestOutlet = localStorage.getItem('latestOutlet');
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const { register, control, handleSubmit, errors, watch, setValue } = useForm<
		FastMovingProps
	>({
		defaultValues: {},
	});

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const onSubmit = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: selectedOutlet?.ID,
			},
		});
	};

	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	//selectedStartDate
	const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
		firstDay,
	);
	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date);
	};

	//selectedEndDate
	const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
		lastDay,
	);
	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date);
	};

	//getOutlet
	const {
		data: { getOutlet } = { getOutlet: [] },
		called: getOutletCalled,
		loading: getOutletLoading,
	} = useGetBizInsightOutletQuery({
		fetchPolicy: 'network-only',
	});

	//selectedOutlet
	const [selectedOutlet, setSelectedOutlet] = useState(null);
	useEffect(() => {
		setSelectedOutlet(
			getOutlet?.filter(outlet => outlet?.ID === latestOutlet)[0],
		);
	}, [getOutlet]);

	//getMajor
	const {
		data: { getMajor } = { getMajor: [] },
		called: majorCalled,
		loading: majorLoading,
	} = useGetMajorQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: selectedOutlet?.ID },
	});

	//selectedMajor
	const [selectedMajor, setSelectedMajor] = useState(null);
	useEffect(() => {
		setSelectedMajor(
			getMajor?.filter(major => major?.ID === watch('major'))[0],
		);
	}, [watch('major')]);

	//getFamily
	const {
		data: { getFamily } = { getFamily: [] },
		called: familyCalled,
		loading: familyLoading,
	} = useGetFamilyQuery({
		fetchPolicy: 'network-only',
		variables: { majorID: selectedMajor?.ID },
	});

	//selectedFamily
	const [selectedFamily, setSelectedFamily] = useState(null);
	useEffect(() => {
		setSelectedFamily(
			getFamily?.filter(family => family?.ID === watch('family'))[0],
		);
	}, [watch('family')]);

	//getMealPeriod
	const {
		data: { getMealPeriod } = { getMealPeriod: [] },
		called: mealPeriodCalled,
		loading: mealPeriodLoading,
	} = useGetMealPeriodQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: selectedOutlet?.ID },
	});

	//selectedMealPeriod
	const [selectedMealPeriod, setSelectedMealPeriod] = useState(null);
	useEffect(() => {
		setSelectedMealPeriod(
			getMealPeriod?.filter(
				mealPeriod => mealPeriod?.ID === watch('mealPeriod'),
			)[0],
		);
	}, [watch('mealPeriod')]);

	//fastMovingMenuItemAnalysis
	const [
		loadFastMovingMenuItemAnalysis,
		{
			data: { fastMovingMenuItemAnalysis } = { fastMovingMenuItemAnalysis: [] },
			called: fastMovingMenuItemAnalysisCalled,
			loading: fastMovingMenuItemAnalysisLoading,
		},
	] = useFastMovingMenuItemAnalysisLazyQuery({
		fetchPolicy: 'network-only',
	});

	//loadFastMovingMenuItemAnalysis
	useEffect(() => {
		if (selectedOutlet && selectedStartDate && selectedEndDate) {
			loadFastMovingMenuItemAnalysis({
				variables: {
					outletID: selectedOutlet?.ID,
					startDate: selectedStartDate,
					endDate: selectedEndDate,
					majorID: watch('major'),
					familyID: watch('family'),
					mealPeriodID: watch('mealPeriod'),
				},
			});
		}
	}, [
		selectedOutlet,
		selectedStartDate,
		selectedEndDate,
		selectedMajor,
		watch('major'),
		selectedFamily,
		watch('family'),
		selectedMealPeriod,
		watch('mealPeriod'),
	]);

	return (
		<>
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/business-insight/business-analytic`,
					})
				}
				mainBtn="back"
				smTitle="Business Insights"
				title={user?.companyName}
				routeSegments={[
					{ name: 'Business Insights' },
					{ name: 'Fast-Moving Menu Item Analysis', current: true },
				]}
			/>

			<DynamicSubHeader
				// style={{
				// 	width:
				// 		isDesktop && globalState.drawerOpen
				// 			? 'calc(100% - 288px)'
				// 			: isDesktop && !globalState.drawerOpen
				// 			? `calc(100% - 48px)`
				// 			: null,
				// }}
				title={
					getOutlet === undefined || getOutlet?.length === 0
						? ``
						: `${selectedOutlet?.name}`
				}
				handleClick={e => handleClick(e, null, 0)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						{getOutlet.map((outlet, index) => (
							<StyledMenuItem
								key={index}
								value={outlet.ID}
								defaultValue={outlet.ID}
								onClick={e => {
									setSelectedOutlet(outlet);
								}}
							>
								<ListItemText primary={outlet?.name} />
							</StyledMenuItem>
						))}
					</StyledMenu>
				}
			/>
			<ContentWrapper search footer>
				<CardActions style={{ paddingTop: '0px' }}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							id="date-picker-dialog"
							style={{
								background: 'white',
								width: '50%',
								zIndex: 40,
							}}
							format="dd MMM yyyy"
							value={selectedStartDate}
							onChange={handleStartDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							inputVariant="outlined"
							size="small"
							className="custom-date-business-insight"
						/>

						<KeyboardDatePicker
							id="date-picker-dialog"
							format="dd MMM yyyy"
							value={selectedEndDate}
							style={{
								background: 'white',
								width: '50%',
								zIndex: 40,
							}}
							onChange={handleEndDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							inputVariant="outlined"
							size="small"
							className="custom-date-business-insight"
						/>
					</MuiPickersUtilsProvider>
				</CardActions>

				<CardActions>
					<Controller
						as={
							<TextField select variant="outlined" size="small" label="Major">
								{getMajor?.map((major, index) => (
									<MenuItem
										key={index}
										value={major.ID}
										defaultValue={major.ID}
									>
										{major.name}
									</MenuItem>
								))}
							</TextField>
						}
						name={'major'}
						style={{
							marginTop: '0px',
							marginBottom: '0px',
							background: 'white',
							zIndex: 40,
						}}
						autoComplete="off"
						control={control}
						multiline
						fullWidth
						margin="normal"
					/>
					<Controller
						as={
							<TextField select variant="outlined" size="small" label="Family">
								{getFamily?.map((family, index) => (
									<MenuItem
										key={index}
										value={family.ID}
										defaultValue={family.ID}
									>
										{family.name}
									</MenuItem>
								))}
							</TextField>
						}
						name={'family'}
						disabled={!watch('major')}
						style={{
							marginTop: '0px',
							marginBottom: '0px',
							background: 'white',
							zIndex: 40,
						}}
						autoComplete="off"
						control={control}
						multiline
						fullWidth
						margin="normal"
					/>
					<Controller
						as={
							<TextField
								select
								variant="outlined"
								size="small"
								label="Meal Period"
							>
								{getMealPeriod?.map((mealPeriod, index) => (
									<MenuItem
										key={index}
										value={mealPeriod.ID}
										defaultValue={mealPeriod.ID}
									>
										{mealPeriod.name}
									</MenuItem>
								))}
							</TextField>
						}
						name={'mealPeriod'}
						style={{
							marginTop: '0px',
							marginBottom: '0px',
							background: 'white',
							zIndex: 40,
						}}
						autoComplete="off"
						control={control}
						multiline
						fullWidth
						margin="normal"
					/>
				</CardActions>

				{!fastMovingMenuItemAnalysisLoading &&
				(fastMovingMenuItemAnalysis === undefined ||
					fastMovingMenuItemAnalysis?.length === 0) ? (
					<EmptyList
						title={`No order item created for ${selectedOutlet?.name}`}
						subtitle="Please create new order to continue"
					/>
				) : (
					!fastMovingMenuItemAnalysisLoading && (
						<>
							{fastMovingTable(
								fastMovingMenuItemAnalysis,
								selectedOutlet,
								selectedMajor,
								selectedFamily,
								selectedMealPeriod,
								selectedStartDate,
								selectedEndDate,
							)}
							{fastMovingTable(
								fastMovingMenuItemAnalysis,
								selectedOutlet,
								selectedMajor,
								selectedFamily,
								selectedMealPeriod,
								selectedStartDate,
								selectedEndDate,
								true,
							)}
							<div className="cover-layer">
								<CardContents>
									<></>
								</CardContents>
							</div>
						</>
					)
				)}
			</ContentWrapper>

			{ShareDialog({
				shareTitle: `Fast-Moving Menu Item Analysis - ${selectedOutlet?.name}`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}

			<DynamicFooter
				overlap
				options={[
					{
						name: 'Share',
						onClick: () => {
							let temp = document.getElementById('hiddenTable');
							htmlToImage
								.toBlob(temp, { style: { background: '#fff' } })
								.then(value => {
									onSubmit(value);
								});
						},
						disabled:
							fastMovingMenuItemAnalysis == undefined ||
							fastMovingMenuItemAnalysis.length == 0,
						color: 'primary',
						props: {
							type: 'submit',
						},
						startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
					},
				]}
			/>
		</>
	);
};

const fastMovingTable = (
	fastMovingMenuItemAnalysis,
	selectedOutlet,
	selectedMajor,
	selectedFamily,
	selectedMealPeriod,
	selectedStartDate,
	selectedEndDate,
	hidden?: Boolean,
) => {
	return (
		<div
			style={
				hidden
					? {
							zIndex: -40,
							top: 0,
							left: 0,
							position: 'fixed',
							width: '100%',
					  }
					: {
							marginTop: '0px',
							zIndex: 40,
							position: 'relative',
					  }
			}
			className={hidden ? 'hidden-content' : null}
		>
			<CardContents
				section={
					!hidden
						? {
								header: {
									title: 'Fast-Moving Menu Item Listing',
								},
						  }
						: null
				}
			>
				<TableContainer
					id={!hidden ? 'table' : 'hiddenTable'}
					style={{ overflow: 'hidden' }}
				>
					{hidden && (
						<div
							style={{
								textAlign: 'center',
								margin: '25px 0px 25px 0px',
							}}
						>
							<span
								style={{
									fontSize: '16px',
									fontWeight: 600,
									width: '180',
									whiteSpace: 'pre-line',
								}}
							>
								{`${selectedOutlet?.name} \n`}
							</span>
							<span
								style={{
									fontSize: '16px',
									fontWeight: 500,
									width: '180',
									whiteSpace: 'pre-line',
								}}
							>
								{`Fast Moving Analysis \n`}
							</span>
							<span
								style={{
									fontSize: '14px',
									fontWeight: 500,
									width: '180',
									whiteSpace: 'pre-line',
								}}
							>
								{`${formatDate(selectedStartDate.toISOString())} - ${formatDate(
									selectedEndDate.toISOString(),
								)} \n`}
							</span>

							<span
								style={{
									fontSize: '14px',
									fontWeight: 500,
									width: '180',
									whiteSpace: 'pre-line',
								}}
							>
								{selectedMajor && `${selectedMajor.name}`}
								{selectedFamily && `-${selectedFamily.name}`}
								{selectedMealPeriod && `(${selectedMealPeriod.name})`}
							</span>
						</div>
					)}
					<Table size="small" aria-label="a dense table">
						<TableHead>
							<TableRow
								style={{
									backgroundColor: '#8B92F9',
									color: 'white',
								}}
							>
								<TableCell
									align="center"
									style={{
										color: 'white',
										borderWidth: '2px',
										borderColor: 'white',
										borderStyle: 'solid',
										padding: '2px',
									}}
									component="td"
									width="40%"
								>
									Menu Item
								</TableCell>
								<TableCell
									align="center"
									style={{
										color: 'white',
										borderWidth: '2px',
										borderColor: 'white',
										borderStyle: 'solid',
										padding: '2px',
									}}
									component="td"
									width="30%"
								>
									Quantity
								</TableCell>
								<TableCell
									align="center"
									style={{
										color: 'white',
										borderWidth: '2px',
										borderColor: 'white',
										borderStyle: 'solid',
										padding: '2px',
									}}
									component="td"
									width="30%"
								>
									Sales Amt
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{fastMovingMenuItemAnalysis.map((el, index) => (
								<TableRow>
									<TableCell
										component="th"
										style={{
											backgroundColor: '#CADCFF',
											borderWidth: '2px',
											borderColor: 'white',
											borderStyle: 'solid',
											padding: '5px 15px 5px 15px',
										}}
									>
										{index + 1 + '.'}&nbsp;
										{el.name}
									</TableCell>
									<TableCell
										component="th"
										style={{
											backgroundColor: '#CADCFF',
											borderWidth: '2px',
											borderColor: 'white',
											borderStyle: 'solid',
											padding: '5px 15px 5px 15px',
										}}
									>
										{el.quantity}
									</TableCell>
									<TableCell
										component="th"
										style={{
											backgroundColor: '#CADCFF',
											color: '#ff9800',
											borderWidth: '2px',
											borderColor: 'white',
											borderStyle: 'solid',
											padding: '5px 15px 5px 15px',
										}}
									>
										{amtStr(el.amount)}
									</TableCell>
								</TableRow>
							))}
						</TableBody>
					</Table>
					{!hidden ? <div style={{ paddingBottom: '10px' }}></div> : null}
				</TableContainer>
			</CardContents>
		</div>
	);
};
