import React from 'react';
import { ReservationForm } from './ReservationForm';
import { ReservationList } from './ReservationList';
import ReservationTable from './ReservationTable';

const reservationRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/reservation`,
		},
		component: <ReservationList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/reservation/add`,
		},
		component: <ReservationForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/reservation/:reservationID/edit`,
		},
		component: <ReservationForm mode="edit" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/reservation/:reservationID/table/grid`,
		},
		component: <ReservationTable />,
	},
];

export default reservationRoutes;
