import DateFnsUtils from '@date-io/date-fns';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { MultiPieChart } from '@ifca-root/react-component/src/components/Charts/MultiPieChart';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import { CardActions, ListItemText, useMediaQuery } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import AppContext from 'containers/App/Store/AppContext';
import {
	useGetOutletQuery,
	useGetPatronTypeAnalysisLazyQuery,
	useGetShareAttachmentMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import * as htmlToImage from 'html-to-image';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ShareDialog from '../../../../../components/Dialog/ShareDialog';

const data = [
	{ name: 'Balance To Spend', value: 400 },
	{ name: 'Subcontract Cost', value: 300 },
	{ name: 'Material Cost', value: 300 },
	{ name: 'P&M Cost', value: 200 },
	{ name: 'Labour Cost', value: 200 },
	{ name: 'Misc. Expenses', value: 200 },
];

const COLORS = ['#0a3d62', '#0d71a5', '#34bcd2', '#ffc853', '#f8f0d7'];

export const PatronTypeAnalysis = (props: any) => {
	const history = useHistory();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const latestOutlet = localStorage.getItem('latestOutlet');

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const [selectedOutlet, setSelectedOutlet] = useState(null);

	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	//selectedStartDate
	const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
		firstDay,
	);
	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date);
	};

	//selectedEndDate
	const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
		lastDay,
	);
	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date);
	};

	const {
		data: { getOutlet } = { getOutlet: [] },
		called: getOutletCalled,
		loading: getOutletLoading,
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		setSelectedOutlet(
			getOutlet?.filter(outlet => outlet?.ID === latestOutlet)[0],
		);
	}, [getOutlet]);

	const [
		loadPatronTypeAnalysis,
		{
			data: { patronTypeAnalysis } = { patronTypeAnalysis: [] },
			called: patronTypeAnalysisCalled,
			loading: patronTypeAnalysisLoading,
		},
	] = useGetPatronTypeAnalysisLazyQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (selectedOutlet && selectedStartDate && selectedEndDate) {
			loadPatronTypeAnalysis({
				variables: {
					outletID: selectedOutlet?.ID,
					startDate: selectedStartDate,
					endDate: selectedEndDate,
				},
			});
		}
	}, [selectedOutlet, selectedStartDate, selectedEndDate]);

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);
	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const onSubmit = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: selectedOutlet?.ID,
			},
		});
	};
	// useEffect(() => {
	// 	if (openShareDialog) {
	// 		getShareBlob();
	// 	}
	// }, [openShareDialog, getPatronTypeAnalysis]);

	// const getShareBlob = async () => {
	// 	let temp = document.getElementById('pieChart');
	// 	htmlToImage
	// 		.toBlob(temp, { style: { background: '#fff' } })
	// 		.then((value) => {
	// 			onSubmit(value);
	// 		});
	// };

	return (
		<>
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/business-insight/business-analytic`,
					})
				}
				mainBtn="back"
				smTitle="Business Insights"
				// HC
				title={user?.companyName}
				routeSegments={[
					{ name: 'Business Insights' },
					{ name: 'Patron Type Analysis', current: true },
				]}
			/>

			{!getOutletLoading && (
				<DynamicSubHeader
					// style={{
					// 	width:
					// 		isDesktop && globalState.drawerOpen
					// 			? 'calc(100% - 288px)'
					// 			: isDesktop && !globalState.drawerOpen
					// 			? `calc(100% - 48px)`
					// 			: null,
					// }}
					title={
						getOutlet === undefined || getOutlet?.length === 0
							? ``
							: `${selectedOutlet?.name}`
					} // title text
					handleClick={e => handleClick(e, null, 0)} // onClick function for dropdown
					dropDownOptions={
						// dropdown component place here
						<StyledMenu
							id="customized-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
							onClick={handleClose}
						>
							{getOutlet.map((outlet, index) => (
								<StyledMenuItem
									key={index}
									value={outlet.ID}
									defaultValue={outlet.ID}
									onClick={e => {
										setSelectedOutlet(outlet);
									}}
								>
									<ListItemText primary={outlet?.name} />
								</StyledMenuItem>
							))}
						</StyledMenu>
					}
				/>
			)}
			<ContentWrapper search footer>
				<CardActions style={{ paddingTop: '0px' }}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							id="date-picker-dialog"
							style={{
								background: 'white',
								width: '50%',
								zIndex: 40,
							}}
							format="dd MMM yyyy"
							value={selectedStartDate}
							onChange={handleStartDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							inputVariant="outlined"
							size="small"
							className="left"
						/>

						<KeyboardDatePicker
							id="date-picker-dialog"
							format="dd MMM yyyy"
							value={selectedEndDate}
							style={{
								background: 'white',
								width: '50%',
								zIndex: 40,
							}}
							onChange={handleEndDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							inputVariant="outlined"
							size="small"
							className="right"
						/>
					</MuiPickersUtilsProvider>
				</CardActions>
				{!patronTypeAnalysisLoading &&
				!getOutletLoading &&
				(patronTypeAnalysis === undefined ||
					patronTypeAnalysis?.length === 0) ? (
					<EmptyList
						title={`No order created for ${selectedOutlet?.name} within the date range`}
						subtitle="Please create a new order to continue"
					/>
				) : (
					!getOutletLoading &&
					!patronTypeAnalysisLoading && (
						<>
							{patronTypePieChart(patronTypeAnalysis, selectedOutlet)}
							{patronTypePieChart(
								patronTypeAnalysis,
								selectedOutlet,
								selectedStartDate,
								selectedEndDate,
								formatDate,
								true,
							)}
							<div className="cover-layer">
								<CardContents>
									<></>
								</CardContents>
							</div>
						</>
					)
				)}
			</ContentWrapper>

			{ShareDialog({
				shareTitle: `Patron Type Analysis - ${selectedOutlet?.name}`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}
			<DynamicFooter
				overlap
				options={[
					{
						name: 'Share',
						onClick: async () => {
							let temp = await document.getElementById('hiddenPieChart');
							htmlToImage
								.toBlob(temp, { style: { background: '#fff' } })
								.then(value => {
									onSubmit(value);
								});
						},
						disabled:
							patronTypeAnalysis == undefined || patronTypeAnalysis.length == 0,
						color: 'primary',
						props: {
							type: 'submit',
						},
						startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
					},
				]}
			/>
		</>
	);
};

const heightConst = 400;
const patronTypePieChart = (
	patronTypeAnalysis,
	selectedOutlet,
	selectedStartDate?: any,
	selectedEndDate?: any,
	formatDate?: any,
	hidden?: boolean,
) => {
	return (
		<div
			style={
				hidden
					? {
							zIndex: -40,
							top: 0,
							left: 0,
							position: 'fixed',
							width: '100%',
					  }
					: {
							marginTop: '0px',
							zIndex: 40,
							position: 'relative',
					  }
			}
			className={hidden ? 'hidden-content' : null}
		>
			<CardContents
				section={
					!hidden
						? {
								header: {
									title: 'Customer Type',
								},
						  }
						: null
				}
			>
				<div
					style={{
						width: '100%',
						height: !hidden ? heightConst : heightConst + 44,
					}}
					id={!hidden ? 'pieChart' : 'hiddenPieChart'}
				>
					<MultiPieChart
						data={patronTypeAnalysis}
						outlet={hidden ? selectedOutlet : null}
						analysis={hidden ? 'Patron Type' : null}
						selectedStartDate={hidden ? selectedStartDate : null}
						selectedEndDate={hidden ? selectedEndDate : null}
						formatDate={formatDate}
					/>
				</div>
			</CardContents>
		</div>
	);
};
