import React from 'react';
import { AccountJournalListing } from './AccountJournalListing';
import accountMappingRoutes from './AccountMapping/AccountMappingRoutes';
import { GeneralLedgerJournalDetail } from './GeneralLedgerJournal/GeneralLedgerJournalDetail';
import { GeneralLedgerJournalForm } from './GeneralLedgerJournal/GeneralLedgerJournalForm';
import { GeneralLedgerJournalList } from './GeneralLedgerJournal/GeneralLedgerJournalList';
import { GeneralLedgerJournalIndividualDetail } from './GeneralLedgerJournal/GeneralLedgerJournalIndividualDetail';
import { CashBookTransactionList } from '@menu-root/outlet-react/src/containers/OutletModule/AccountJournalsModule/CashBookTransaction/CashBookTransactionList';
import { CashBookTransactionForm } from './CashBookTransaction/CashBookTransactionForm';
import { CashBookTransactionDetail } from './CashBookTransaction/CashBookTransactionDetail';
import { CashBookTransactionIndividualDetail } from './CashBookTransaction/CashBookTransactionIndividualDetail';

const accountjournalsRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals`,
		},
		component: <AccountJournalListing />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/gl-journal`,
		},
		component: <GeneralLedgerJournalList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/gl-journal/add`,
		},
		component: <GeneralLedgerJournalForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/gl-journal/detail`,
		},
		component: <GeneralLedgerJournalDetail />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/gl-journal/detail/individual`,
		},
		component: <GeneralLedgerJournalIndividualDetail />,
	},

	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/cb-transaction`,
		},
		component: <CashBookTransactionList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/cb-transaction/add`,
		},
		component: <CashBookTransactionForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/cb-transaction/detail`,
		},
		component: <CashBookTransactionDetail />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/cb-transaction/detail/individual`,
		},
		component: <CashBookTransactionIndividualDetail />,
	},
	...accountMappingRoutes,
];

export default accountjournalsRoutes;
