import React, { Reducer, useEffect, useReducer, useState } from 'react';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import { useHistory } from 'react-router';

interface DialogProps {
	updateConfirm: boolean;
	setUpdateConfirm: any;
	handleSubmit: any;
	onSubmit: any;
}

export const ChangePatronConfirmationDialog = (props: DialogProps) => {
	const { updateConfirm, setUpdateConfirm, handleSubmit, onSubmit } = props;

	let history = useHistory();

	return (
		<>
			<CommonDialogV2
				fullWidth={true}
				open={updateConfirm}
				onClose={() => setUpdateConfirm(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Confirmation</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{/* <CardContents> */}
							<span className="mdDesc" style={{ whiteSpace: 'initial' }}>
								The discount will be removed. Do you want to proceed with the
								changes?
							</span>
							{/* </CardContents> */}
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'No',
								props: {
									onClick: () => setUpdateConfirm(false),
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Yes',
								props: {
									onClick: () => {
										handleSubmit(onSubmit)();
										setUpdateConfirm(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
