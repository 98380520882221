import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetStaffDocument,
	PosPermission,
	useDeleteStaffMutation,
	useGetStaffQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};

export const AuthorisedStaffList = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const [inactiveDialog, setInactiveDialog] = useState(false);
	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const { IDK } = useParams<Record<string, any>>();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const staff = JSON.parse(localStorage.getItem('searchFilter'));

	const outletID = localStorage.getItem('latestOutlet');

	const { handlePermDisabled } = usePermissionChecker();

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
	const [filterUserStatus, setFilterUserStatus] = useState<any>('NONE');

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);
	const { globalState, dispatch } = useContext(AppContext as any);

	// useEffect(() => {
	// 	const newHistory = createHistory();
	// 	if (newHistory.location.state && newHistory.location.state['success']) {
	// 		let state = { ...(newHistory.location.state as object) } as any;
	// 		delete state['success'];
	// 		newHistory.replace({ ...newHistory.location, state });
	// 	}
	// });
	/////////////////////////////////////
	/////// QUERIES AND MUTATIONS ///////
	/////////////////////////////////////

	const {
		called,
		loading: staffLoading,
		error,
		data: { getStaff } = { getStaff: [] },
	} = useGetStaffQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderByDesc: 'name',
		},
		onCompleted: ({ getStaff }) => {},
	});

	const [rerender, setRender] = useState(false);

	useEffect(() => {
		if (getStaff && getStaff?.length > 0) {
			setOriginalListing(getStaff);
		}
	}, [getStaff]);

	const [deleteStaff, { loading: deleteStaffLoading }] = useDeleteStaffMutation(
		{
			onError: error => {
				console.log('error cant delete', error);
			},

			onCompleted: data => {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecord());
			},
		},
	);

	useEffect(() => {
		if (!staffLoading && getStaff?.length > 0 && staff?.value) {
			handleSearch(`'"${staff?.value ?? ''}"`, ['name', 'designation']);
			setRender(true);
		}
	}, [staffLoading, getStaff, rerender]);

	const moreVertList = [
		{
			name: 'View',
			action: () => {
				//history.push(__OUTLET_APP__GENERAL_SETTING__EMPLOYEE__VIEW);
				handleClose();
			},
		},
		{
			name: 'Edit',
			action: () => {
				//setInactiveDialog(true);
				handleClose();
			},
		},
		{
			name: 'Delete',
			action: () => {
				setDeleteDialog(true);
				handleClose();
			},
		},
	];

	if (menu?.obj?.status === 'Active') {
		moreVertList.splice(1, 0, {
			name: 'Deactivate',
			action: () => {
				setInactiveDialog(true);
				handleClose();
			},
		});
	}
	//SnackBar

	let location: any = useLocation();

	const [success, setSuccess] = useState<boolean>(
		location.state ? location.state['success'] : false,
	);

	return (
		<>
			{staffLoading && <Loading />}
			{deleteStaffLoading && <Loading />}

			<MainHeader
				onClick={() => {
					localStorage.removeItem('searchFilter');
					history.push({ pathname: `/menu/outlet-app/general-setting` });
				}}
				mainBtn="back"
				smTitle={'Outlet App'}
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Settings' },
					{ name: 'Authorised Staff', current: true },
				]}
			/>
			<div
				className={
					globalState.drawerOpen
						? 'search-filter with-dropdown-filter-general-settings'
						: 'search-filter with-dropdown-filter-general-settings-fw'
				}
			>
				<SearchHeader
					title={`Authorised Staff Listing`}
					value={`${
						filteredList?.filter(e => {
							if (filterUserStatus === 'NONE') {
								return e;
							} else if (filterUserStatus !== 'ACTIVE') {
								return e?.isActive === false;
							} else {
								return e?.isActive === true;
							}
						})?.length
					}`}
					onChangeAction={e => {
						handleSearch(`'"${e?.target?.value}"`, ['name', 'designation']);
						localStorage.setItem(
							'searchFilter',
							JSON.stringify({ value: e?.target?.value }),
						);
					}}
					isDefaultValue={!!staff?.value ?? false}
					defaultValue={staff?.value ?? ''}
					search
					onCloseAction={() => handleSearch('', [])}
				/>
				<DropdownBar
					options={[
						{
							name: 'All',
							ID: 'NONE',
						},
						{
							name: 'Active ',
							ID: 'ACTIVE',
						},
						{
							name: 'Inactive',
							ID: 'INACTIVE',
						},
					]}
					setOptionID={setFilterUserStatus}
					borderRadius="4px"
				/>
			</div>

			<ContentWrapper searchBarDropdown>
				<List className="core-list">
					{!staffLoading &&
					(getStaff === undefined || getStaff?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Add a new record now."
						/>
					) : (
						filteredList
							?.filter(e => {
								if (filterUserStatus === 'NONE') {
									return e;
								} else if (filterUserStatus !== 'ACTIVE') {
									return e?.isActive === false;
								} else {
									return e?.isActive === true;
								}
							})
							?.sort((a, b) => (!a?.isActive ? 1 : -1))
							?.map(
								(el, index) => (
									<ListItem key={index} disabled={el.isActive === false}>
										<ListItemText
											primary={
												<>
													<span className="xsTitle flex-space">{el.name}</span>
													<span className="xxTitle highlight-text text-separator"></span>
													<span className="xxTitle highlight-text">
														{/* <Grid
														component={Card}
														style={{
															background: el.isActive ? '#c0d3fe' : '#dbdbdb',
															padding: '.2rem .5rem .2rem .5rem ',
															boxShadow: 'none',
														}}
													>
														<Typography
															variant="caption"
															style={{
																fontSize: '10px',
																color: el.isActive ? '#0019d8' : '#656565',
															}}
														>
															{el?.isActive ? 'Active' : 'Inactive'}
														</Typography>
													</Grid> */}
													</span>
												</>
											}
											secondary={
												<>
													<span className="desc" style={{ color: '' }}>
														{el?.designation}
													</span>{' '}
													{/* <span className="desc">/80 Accessible</span>{' '} */}
												</>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="delete"
												aria-controls="menu-list"
												aria-haspopup="true"
												onClick={e => handleClick(e, el.ID, index, el)}
											>
												<MoreVert />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								),

								// <PersonListing
								// 	el={el}
								// 	stats={el.isActive}
								// 	index={index}
								// 	handleClick={handleClick}
								// 	TLInfo={el?.name}
								// 	BLInfo={el?.designation}
								// 	isEmpty={getStaff?.length === 0}
								// 	moreVertNeeded={true}
								// 	moreVertList={moreVertList}
								// 	anchorEl={anchorEl}
								// 	handleClose={handleClose}
								// />,
							)
					)}
				</List>

				<GeneralDeleteDialog
					ID={menu?.ID}
					refetchID={{ IDK: IDK, orderByDesc: 'name' }}
					openDialog={openDeleteDialog}
					setOpenDialog={setDeleteDialog}
					dialogName={menu?.obj?.name}
					deleteMutation={deleteStaff}
					refetchDocument={GetStaffDocument}
				/>

				<FloatButton
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum: PosPermission.GeneralSettingAuthorisedStaffCreate,
					})}
					onClick={() =>
						history.push(`/menu/outlet-app/general-setting/employee/add`)
					}
				/>
			</ContentWrapper>
			<Menu
				id="menu-list"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				onClick={handleClose}
			>
				<MenuItem
					onClick={() =>
						history.push({
							// pathname: __OUTLET_APP__GENERAL_SETTING__EMPLOYEE__DETAIL
							pathname: `/menu/outlet-app/general-setting/employee/${menu?.ID}/detail`,
							state: menu.obj,
						})
					}
				>
					<span> View </span>
				</MenuItem>

				<MenuItem
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum: PosPermission.GeneralSettingAuthorisedStaffUpdate,
					})}
					onClick={() =>
						history.push({
							pathname: `/menu/outlet-app/general-setting/employee/${menu?.ID}/edit`,
							//   pathname: `/${pageMode}/general-setting/access-security/user/edit/${menu.ID}`,
							state: menu.obj,
						})
					}
				>
					<span className="">Edit</span>
				</MenuItem>
				<MenuItem
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum: PosPermission.GeneralSettingAuthorisedStaffDelete,
					})}
					onClick={() => setDeleteDialog(true)}
				>
					<span className="">Delete</span>
				</MenuItem>
			</Menu>
		</>
	);
};
