import { AddressAutocomplete } from '@ifca-root/react-component/src/components/Input/AddressAutocomplete'
import { TextField } from '@material-ui/core'
import { createFilterOptions } from '@material-ui/lab'
import React from 'react'

export const AddressAutoCompleteComponent = (props: any) => {
  const {
    getCountry, // country
    getStatebyCountry, // State
    getCitybyState, // City
    getPostcodebyCity, // Postcode
    handleCountryOnChange,
    handleStateOnChange,
    handleCityOnChange,
    handlePostCodeOnChange,
    handleGoogleOnChange,
    autoCompleteRef,
    state,
    formType,
    country,
    setCountry,
    setState,
    setCity,
    newState,
    city,
    newPostcode,
    setPostcode,
    isMandatory = true,
    isSubmitted,
  } = props

  const filterOptions = createFilterOptions({
    matchFrom: 'start',
    stringify: (option: any) => option.name || option?.postcode,
  })

  return (
    <AddressAutocomplete
      sections={{
        address: {
          props: {
            inputRef: autoCompleteRef,
            label: isMandatory ? 'Address*' : 'Address',
            // value: query,
            value: state.address,
            multiline: true,
            onChange: e => handleGoogleOnChange(e.target.value),
            style: { width: '100%' },
            error: isMandatory && !state.address && isSubmitted ? true : false,
            helperText:
              isMandatory && !state.address && isSubmitted
                ? 'Address is required'
                : '',
          },
        },
        country: {
          props: {
            value: state.country,
            options: getCountry || [],
            style: { width: '50%' },
            filterOptions: filterOptions,
            onChange: (e, newValue) => {
              handleCountryOnChange(newValue)
            },
            inputValue: country,
            onInputChange: (e, value) => {
              setCountry(value)
            },
            getOptionLabel: (option: any) => option.name || '',
            renderInput: params => {
              if (formType === 'edit') {
                // anyone changing this astronomically weird shit, this is the key
                // to it all when editing because apparently setting default value
                // doesnt fucking work
                params.inputProps.value = country
              }

              return (
                <TextField
                  {...params}
                  value={state.country.name}
                  style={{ width: '100%' }}
                  label="Country"
                />
              )
            },
          },
        },
        state: {
          props: {
            value: state.state,
            options: getStatebyCountry || [],
            style: { width: '50%' },
            filterOptions: filterOptions,
            getOptionLabel: (option: any) => option.name || '',
            inputValue: newState,
            onChange: (e, newValue) => {
              // if (newValue) {
              // handleStateOnChange(newValue || { name: e.target.value })
              handleStateOnChange(newValue)

              // }
            },
            onInputChange: (e, value) => {
              // handleStateOnChange({ name: value })
              setState(value)
            },
            renderInput: params => {
              if (formType === 'edit') {
                params.inputProps.value = newState
              }

              return (
                <TextField
                  {...params}
                  value={state?.state?.name}
                  style={{ width: '100%', paddingLeft: '4px' }}
                  label="State"
                />
              )
            },
          },
        },
        city: {
          props: {
            value: state.city,
            options: getCitybyState || [],
            style: { width: '50%' },
            inputValue: city,
            filterOptions: filterOptions,
            getOptionLabel: option => option.name || '',
            onChange: (e, newValue) => {
              // if (newValue) {
              handleCityOnChange(newValue)
              // }
            },
            onInputChange: (e, value) => {
              // handleCityOnChange({ name: value })
              setCity(value)
            },
            renderInput: params => {
              if (formType === 'edit') {
                params.inputProps.value = city
              }

              return (
                <TextField
                  {...params}
                  value={state?.city?.name}
                  label="City"
                  style={{ width: '100%' }}
                />
              )
            },
          },
        },
        postCode: {
          props: {
            value: state.postcode,
            options: getPostcodebyCity || [],
            style: { width: '50%' },
            inputValue: newPostcode,
            filterOptions: filterOptions,
            onChange: (e: any, newValue) => {
              // if (!!newValue) {
              handlePostCodeOnChange(newValue)
              // }
            },
            onInputChange: (e, value) => {
              // handlePostCodeOnChange({ postcode: newValue })
              setPostcode(value)
            },
            getOptionLabel: option => option?.postcode || '',
            renderInput: params => {
              if (formType === 'edit') {
                params.inputProps.value = newPostcode
              }

              return (
                <TextField
                  {...params}
                  value={state?.postcode?.postcode}
                  style={{ width: '100%', paddingLeft: '4px' }}
                  label="Postcode"
                />
              )
            },
          },
        },
      }}
    />
  )
}
