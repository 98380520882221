import theme from '@ifca-root/react-component/src/assets/theme';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Box, Divider, Grid, Paper, useMediaQuery } from '@material-ui/core';
import Accordion from '@material-ui/core/ExpansionPanel';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import { AccordionDetails } from '@material-ui/core';
import { KeyboardArrowDown } from '@material-ui/icons';
import ShareIcon from '@material-ui/icons/Share';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import ShareDialog from 'components/Dialog/ShareDialog';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetGlListingDocument,
	useGetGlExportLazyQuery,
	useGetOutletQuery,
	useGetShareAttachmentMutation,
	useMenuAccountXListQuery,
	usePostGlExportMutation,
} from 'generated/graphql';
import { formatDate, daysDifferences } from 'helpers/hooks/formatDate';
import { amtStr } from 'helpers/numFormatter';
import * as htmlToImage from 'html-to-image';
import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';
import { CSVLink } from 'react-csv';
import { useHistory } from 'react-router-dom';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import '../../AccountJournalsModule/GeneralLedgerJournal/generalLedger.scss';
import { IAction } from 'helpers/model';
import * as _ from 'underscore';
import { addDays } from 'date-fns/esm';
import { useSnackBar } from 'helpers/hooks/useSnackBar';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';

interface Props {
	StartDate: Date;
	EndDate: Date;
	GLDate: Date;
	Dialog: boolean;
	GLExportData?: any;
	GLDetailsData?: any;
}

export const GeneralLedgerJournalForm = (props: any) => {
	useEffect(() => {
		console.log('GeneralLedgerJournalForm');
	}, []);

	///////////////// DATE SEARCH HEADER ///////////////
	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	var dateFormat = require('dateformat');
	const initialState: Props = {
		StartDate: addDays(date, -1), //dateFormat(firstDay, 'mm/dd/yyyy'),
		EndDate: addDays(date, -1), //dateFormat(lastDay, 'mm/dd/yyyy'),
		GLDate: addDays(date, -1), //dateFormat(lastDay, 'mm/dd/yyyy'),
		Dialog: false,
		GLExportData: [],
		GLDetailsData: [],
	};
	const PreviousDate = addDays(new Date(), -1);

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};
	const [state, dispatch] = useReducer(reducer, initialState);

	const [ErrDialog, setErrDialog] = useState(false);

	const [message, setMessage] = useState('');

	const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar();
	// console.log('state', state)

	const { globalState, dispatches } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const outletID = localStorage.getItem('latestOutlet');
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const history = useHistory();

	//selectedStartDate
	const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
		firstDay,
	);
	const handleStartDateChange = (date: Date | null) => {
		dispatch({ type: 'StartDate', payload: date });
	};

	//selectedEndDate
	const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
		lastDay,
	);
	const handleEndDateChange = (date: Date | null) => {
		dispatch({ type: 'EndDate', payload: date });
		dispatch({ type: 'GLDetailsData', payload: [] });
		getGlExportData({
			variables: {
				startDate: state.StartDate,
				endDate: date,
				outletID: outletID,
				accountID: user.accountID,
			},
		});
	};

	const [
		getGlExportData,
		{ loading: glExportLoading, data: { getGLExport } = { getGLExport: [] } },
	] = useGetGlExportLazyQuery({
		fetchPolicy: 'no-cache',
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const {
		loading: MenuAccountXListLoading,
		data: { MenuAccountXList } = { MenuAccountXList: [] },
	} = useMenuAccountXListQuery({
		variables: {
			outletID: outletID,
		},
		fetchPolicy: 'no-cache',
	});

	const [glPost, { loading: glPostLoading }] = usePostGlExportMutation({
		onError: error => {
			if (error.graphQLErrors[0].message) {
				setErrDialog(true);
				setMessage(`${error.graphQLErrors[0].message}`);
				snackFunc('Posted Fail', false);
			}
		},
		onCompleted: y => {
			if (y.postGLExport === true) {
				csvRef.current.link.click();
				snackFunc('Posted Succesfully', true);
				getGLExport({
					variables: {
						startDate: state.StartDate,
						endDate: state.EndDate,
						outletID: outletID,
						accountID: user.accountID,
					},
				});
				dispatch({
					type: 'reset',
					payload: {},
				});
			}
		},
	});

	const [openExitDialog, setOpenExitDialog] = useState(false);

	const ErrorDialog = (
		<StandardDialog
			fullWidth={true}
			open={ErrDialog}
			onClose={() => {
				setErrDialog(true);
			}}
			sections={{
				header: {
					title: 'GL Transfer Error',
				},
				body: () => <div>{`${message}`}</div>,
				footer: {
					actions: [
						{
							displayText: 'OK',
							props: {
								onClick: () => {
									setErrDialog(false);
								},
								variant: 'contained',
								color: 'primary',
							},
						},
					],
				},
			}}
		/>
	);
	useEffect(() => {
		if (getGLExport?.length > 0)
			dispatch({
				type: 'GLDetailsData',
				payload: getGLExport,
			});
	}, [getGLExport]);
	const GLDetailsGroupBy = _.groupBy(state.GLDetailsData, x => x.trxType);

	// console.log(GLDetailsGroupBy, 'heregroup');

	const GLDetailsList = array => {
		let keys = Object.keys(array);
		let values = Object.values(array);
		let map = new Map();
		for (var i = 0; i < keys.length; i++) {
			map.set(keys[i], values[i]);
		}
		return [...map].map(([GLType, Data]) => ({ GLType, Data }));
	};
	// console.log(GLDetailsList(GLDetailsGroupBy));

	const debitAmount = getGLExport?.reduce((prev, curr) => {
		return prev + Number(curr.debit);
	}, 0);

	const creditAmount = getGLExport?.reduce((prev, curr) => {
		return prev + Number(curr.credit);
	}, 0);

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);

	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const handleShare = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: outletID,
			},
		});
	};

	//Export stuff

	let csvRef = useRef(null);

	const downloadGlExport = async () => {
		dispatch({
			type: 'Dialog',
			payload: false,
		});
		dispatch({
			type: 'GLExportData',
			payload: getGLExport?.map(x => ({
				TrxDate: `${dateFormat(x.trxDate, 'dd/mm/yyyy')}`,
				GLDate: `${dateFormat(state.GLDate, 'dd/mm/yyyy')}`,
				Submenu: 'MX',
				GLType: x.trxType,
				Description: x.trxDesc,
				Department: x.department,
				Division: x.division,
				AccountCode: x.accountCode,
				Debit: Number(x.debit).toFixed(2),
				Credit: Number(x.credit).toFixed(2),
				Module: 'MX',
			})),
		});
		glPost({
			variables: {
				glDate: state.GLDate,
				startDate: state.StartDate,
				endDate: state.EndDate,
				outletID: outletID,
				accountID: user.accountID,
				isTransfer: MenuAccountXList?.vendorName === 'AccountX' ? true : false,
			},
			refetchQueries: [
				{
					query: GetGlListingDocument,
					variables: {
						startDate: selectedStartDate,
						endDate: selectedEndDate,
						outletID: outletID,
						accountID: user.accountID,
					},
				},
			],
		}).then(i => {
			console.log(i);
			if (i.data.postGLExport.GLPost) {
				if (MenuAccountXList?.vendorName === 'AccountX') {
					dispatch({
						type: 'GLExportData',
						payload: i.data.postGLExport.ExportedGLTransfer?.map(x => ({
							JournalDate: `${dateFormat(x.JournalDate, 'dd/mm/yyyy')}`,
							JournalType: x.JournalTypeName,
							RefNo: x.DocNo,
							JournalDescription: x.Description.replace(/["\\/]/g, ''),
							AccountName: x.AccountName.replace(/["\\/]/g, ''),
							MasterCOACode: x.MasterCOACode,
							DepartmentCode: x.DepartmentCode,
							Remark: x.Remark.replace(/["\\/]/g, ''),
							DocAmt: Number(x.Amount).toFixed(2),
						})),
					});
				}
			}
		});
		//post query
	};
	const [downloadSuccess, setDownloadSuccess] = useState(false);
	useEffect(() => {
		const hasNullAccountCode = getGLExport.find(
			x => x?.accountCode === null || x?.accountCode === '',
		);
		if (hasNullAccountCode && !downloadSuccess) {
			snackFunc('Account Code not setup in GL Mapping', false);
		}
	}, [getGLExport, downloadSuccess]);

	console.log(MenuAccountXList?.vendorName !== 'AccountX', 'accountx');

	const ExportDialog = () => {
		return (
			<CommonDialog
				fullWidth={true}
				open={state.Dialog}
				onClose={() => dispatch({ type: 'CloseDialog', payload: {} })}
				sections={{
					header: {
						dynamic: (
							<div className="session">
								<div className="title flex-space color-primary-orange">
									{MenuAccountXList?.vendorName === 'AccountX'
										? 'GL Transfer'
										: 'GL Export'}
								</div>
							</div>
						),
					},
					body: () => (
						<KeyboardDatePicker
							format=" dd MMM yyyy"
							className="custom-date-reservation"
							autoOk
							label="GL Date"
							inputVariant="outlined"
							value={state.GLDate}
							maxDate={PreviousDate}
							onChange={(date: Date | null) => {
								dispatch({ type: 'GLDate', payload: date });
							}}
							fullWidth
							style={{
								zIndex: 40,
								width: '100%',
								boxShadow: 'revert',
								paddingTop: '0px !important',
							}}
							KeyboardButtonProps={{
								style: {},
								'aria-label': 'change date',
							}}
							InputProps={{
								style: {
									fontSize: '10px',
									backgroundColor: 'white',
									color: 'black',
								},
								readOnly: true,
								fullWidth: true,
							}}
						/>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => dispatch({ type: 'Dialog', payload: false }),
									variant: 'contained',
									color: 'primary',
									// startIcon: <ShareIcon />,
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										downloadGlExport();
									},

									variant: 'contained',
									color: 'primary',
									// startIcon: <ShareIcon />,
								},
							},
						],
					},
				}}
			/>
		);
	};

	const Display = (hidden?: boolean) => {
		return (
			<div
				style={
					hidden
						? {
								zIndex: -40,
								top: 0,
								left: 0,
								position: 'fixed',
								width: '100%',
						  }
						: {
								marginTop: '0px',
								zIndex: 40,
								position: 'relative',
						  }
				}
				className={hidden ? 'hidden-content' : null}
			>
				<div
					id={!hidden ? 'gl' : 'hiddenGl'}
					style={
						hidden
							? { marginTop: '-85px', width: '100%', height: '100%' }
							: { width: '100%', height: '100%' }
					}
				>
					<ContentWrapper
						float
						footer
						onlyContentScrollable
						style={{
							marginTop: isDesktop ? '152px' : '86px',
						}}
					>
						<>
							{GLDetailsList(GLDetailsGroupBy)?.map((gl, index) => (
								<Accordion className="accordion-card " key={index}>
									<AccordionSummary
										expandIcon={<ExpandMoreIcon />}
										aria-controls="panel1a-content"
										id="panel1a-header"
									>
										<Grid container justifyContent="flex-start">
											<Grid
												item
												xs={6}
												className="xsTitle"
												style={{
													whiteSpace: 'normal',
												}}
											>
												{gl?.GLType}
											</Grid>
											<Grid
												item
												xs={3}
												className="desc"
												style={{ display: 'flex', justifyContent: 'end' }}
											>
												{amtStr(
													gl.Data?.reduce?.((acc, curr) => {
														return acc + Number(curr.debit);
													}, 0),
												) || 0}
											</Grid>
											<Grid
												item
												xs={3}
												className="desc"
												style={{ display: 'flex', justifyContent: 'end' }}
											>
												{`${amtStr(
													gl.Data?.reduce?.((acc, curr) => {
														return acc + Number(curr.credit);
													}, 0),
												) || 0}`}
											</Grid>
										</Grid>
									</AccordionSummary>
									<AccordionDetails>
										<>
											<Grid container spacing={3}>
												{gl?.Data?.sort(
													(a, b) =>
														Number(new Date(a.trxDate)) -
														Number(new Date(b.trxDate)),
												).map((trx, trxIndex) => (
													<>
														<Grid
															key={trxIndex}
															container
															justifyContent="flex-start"
															className="desc"
															style={{ width: '90%', padding: '4px' }}
														>
															<Grid item xs={3}>
																{formatDate(trx.trxDate)}
															</Grid>
															<Grid item xs={3} className="desc">
																{/* {trx.department}/{trx.division}/{trx.accountCode} */}
																{trx.department === null ||
																trx.department === ''
																	? 'DEP'
																	: trx.department}
																/
																{trx.division === null || trx.division === ''
																	? 'DIV'
																	: trx.division}
																/{trx.accountCode}
															</Grid>
															<Grid
																item
																xs={3}
																className="desc "
																style={{
																	display: 'flex',
																	justifyContent: 'end',
																}}
															>
																{trx.debit === '0' ? null : amtStr(trx.debit)}
															</Grid>
															<Grid
																item
																xs={3}
																className="desc "
																style={{
																	display: 'flex',
																	justifyContent: 'end',
																}}
															>
																{trx.credit === '0'
																	? null
																	: `${amtStr(trx.credit)}`}
															</Grid>
															<Grid
																item
																xs={3}
																style={{ paddingTop: '6px' }}
															></Grid>
															<Grid
																item
																xs={9}
																className="desc "
																style={{
																	paddingTop: '6px',
																}}
															>
																{trx.trxDesc}
															</Grid>
														</Grid>
														<Divider variant="fullWidth" className="flex" />
													</>
												))}
											</Grid>
										</>
									</AccordionDetails>
								</Accordion>
							))}
						</>
					</ContentWrapper>
				</div>
			</div>
		);
	};

	return (
		<>
			{outletLoading && <Loading />}
			{(glExportLoading || glPostLoading || MenuAccountXListLoading) && (
				<Loading />
			)}
			<MainHeader
				onClick={() => {
					setOpenExitDialog(true);
				}}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Account Journals' },
					{ name: 'GL Journal', current: true },
				]}
				// rightRouteSegments={[{ name: mode === 'edit' ? 'Edit' : 'Add' }]}
				rightRouteSegments={[{ name: 'New' }]}
			/>
			<div
				className={`fix-subheader`}
				style={{
					marginTop: '90px',
					//marginBottom: isDesktop && globalState.drawerOpen ? 115 : 0,
				}}
			>
				<div
					className={`dynamic-subheader multiple`}
					style={{
						paddingBottom: '10px',
						height: '88px',
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 285px)'
								: isDesktop && !globalState.drawerOpen
								? 'calc(100% - 47px) '
								: '100%',
					}}
				>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						spacing={2}
					>
						<Grid item xs={6} container justifyContent="center">
							<KeyboardDatePicker
								format="dd MMM yyyy"
								className="custom-date-reservation"
								autoOk
								inputVariant="outlined"
								id="date-picker-dialog"
								value={state.StartDate}
								onChange={(date: Date | null) => {
									dispatch({ type: 'StartDate', payload: date });
								}}
								KeyboardButtonProps={{
									style: {},
									'aria-label': 'change date',
								}}
								style={{
									zIndex: 40,
									width: '100%',
									boxShadow: 'revert',
								}}
								InputProps={{
									style: {
										fontSize: '10px',
										backgroundColor: 'white',
										color: 'black',
									},
									readOnly: true,
									fullWidth: true,
								}}
							/>
						</Grid>
						<Grid item xs={6} container justifyContent="center">
							<KeyboardDatePicker
								format="dd MMM yyyy"
								autoOk
								inputVariant="outlined"
								id="date-picker-dialog"
								value={state.EndDate}
								maxDate={PreviousDate}
								onChange={(date: Date | null) => {
									dispatch({ type: 'EndDate', payload: date });
									dispatch({ type: 'GLDate', payload: date });
									dispatch({ type: 'GLDetailsData', payload: [] });
									getGlExportData({
										variables: {
											startDate: state.StartDate,
											endDate: date,
											outletID: outletID,
											accountID: user.accountID,
										},
									});
								}}
								className="custom-date-reservation"
								KeyboardButtonProps={{
									style: {},
									'aria-label': 'change date',
								}}
								style={{
									zIndex: 40,
									width: '100%',
								}}
								InputProps={{
									style: {
										fontSize: '12px',
										backgroundColor: 'white',
										color: 'black',
									},
									readOnly: true,
									fullWidth: true,
								}}
							/>
						</Grid>
					</Grid>
					<Grid
						container
						direction="row"
						justifyContent="space-between"
						spacing={2}
					>
						<Grid
							container
							direction="row"
							justifyContent="space-between"
							alignItems="center"
							style={{
								paddingRight: '12px',
								paddingLeft: '12px',
								paddingTop: '23px',
							}}
						>
							<span className="xsTitle" style={{ paddingTop: '5px' }}>
								<span className="color-primary-orange">Debit:</span>
								<span style={{ color: 'black' }}> Σ {amtStr(debitAmount)}</span>
							</span>
							<span className="xsTitle">
								<span className="color-primary-orange">Credit:</span>
								<span style={{ color: 'black' }}>
									{' '}
									Σ {amtStr(creditAmount)}
								</span>
							</span>
						</Grid>
					</Grid>
				</div>
			</div>

			{Display()}
			{Display(true)}

			<div className="cover-layer" />
			{ErrorDialog}
			<ExportDialog />

			<CSVLink
				filename={`GL_Export_${dateFormat(state.GLDate, 'dd_mm_yyyy')}.csv`}
				data={state.GLExportData}
				ref={csvRef}
			/>
			<SnackBarMsg open={openSnackBar} message={snackBarMessage} />

			{ShareDialog({
				shareTitle: `GL Export - (${formatDate(
					selectedStartDate.toISOString(),
				)} - ${formatDate(selectedEndDate.toISOString())})`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}

			<DynamicFooter
				options={[
					MenuAccountXList?.vendorName !== 'AccountX'
						? // {
						  // 	name: 'Share',
						  // 	onClick: () => {
						  // 		let temp = document.getElementById('hiddenGl');
						  // 		htmlToImage
						  // 			.toBlob(temp, { style: { background: '#fff' } })
						  // 			.then(value => {
						  // 				handleShare(value);
						  // 			})
						  // 			.catch(error => console.error(error, 'Something went wrong'));
						  // 	},
						  // 	color:
						  // 		getGLExport?.length === 0 ? 'inherit' : 'primary',
						  // 	disabled:
						  // 		getGLExport?.length === 0,
						  // 	startIcon: <ShareIcon />,
						  // },
						  {
								name: 'Download',
								onClick: () => {
									dispatch({ type: 'Dialog', payload: true });
								},
								color:
									getGLExport?.length === 0 ||
									getGLExport.find(x => x?.accountCode === '')
										? 'inherit'
										: 'primary',
								disabled:
									getGLExport?.length === 0 ||
									getGLExport.find(x => x?.accountCode === ''),
								startIcon: <ShareIcon />,
						  }
						: {
								name: 'GL Transfer',
								onClick: () => {
									dispatch({ type: 'Dialog', payload: true });
								},
								color:
									getGLExport?.length === 0 ||
									getGLExport.find(
										x => x?.accountCode === '' || x?.accountCode === null,
									)
										? 'inherit'
										: 'primary',
								disabled:
									getGLExport?.length === 0 ||
									getGLExport.find(
										x => x?.accountCode === '' || x?.accountCode === null,
									),
								startIcon: <ShareIcon />,
						  },
				]}
			/>
			{snackBarMessage && (
				<SnackBarMsg open={openSnackBar} message={snackBarMessage} />
			)}
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/account-journals/gl-journal`}
			/>
		</>
	);
};
