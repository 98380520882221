import React from 'react';
import roleAssignmentRoutes from './RoleAssignment/RoleAssignmentRoutes';
import roleRoutes from './Roles/RolesRoutes';
import { UserAccessSubmenu } from './UserAccessSubmenu';
import userRoutes from './Users/UsersRoutes';

const userAccessRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/user-access`,
		},
		component: <UserAccessSubmenu />,
	},
	...userRoutes,
	...roleRoutes,
	...roleAssignmentRoutes,
];

export default userAccessRoutes;
