// import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import theme from '@ifca-root/react-component/src/assets/theme';
import GLJournalSubHeader from '@ifca-root/react-component/src/components/Header/GLJournalSubheader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import { Box, Divider, Grid, useMediaQuery } from '@material-ui/core';
import Accordion from '@material-ui/core/ExpansionPanel';
import { AccordionDetails } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import ShareIcon from '@material-ui/icons/Share';
import { Auditor } from 'assets';
import ShareDialog from 'components/Dialog/ShareDialog';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import AppContext from 'containers/App/Store/AppContext';
import {
	useExportedGlTransferDetailsLazyQuery,
	useGetGlExportedDetailsQuery,
	useGetOutletQuery,
	useGetShareAttachmentMutation,
	useGetUsersByAccountQuery,
	useMenuAccountXListQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import * as htmlToImage from 'html-to-image';
import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useRef,
	useState,
} from 'react';
import { CSVLink } from 'react-csv';
import { useHistory, useLocation } from 'react-router';
import * as _ from 'underscore';
import '../../AccountJournalsModule/GeneralLedgerJournal/generalLedger.scss';
import { IAction } from 'helpers/model';
import { amtStr } from 'helpers/numFormatter';

interface Props {
	Dialog?: boolean;
	ImageUrl?: string;
	GLDetailsData?: any;
}

export const GeneralLedgerJournalDetail = (props: any) => {
	useEffect(() => {
		console.log('GeneralLedgerJournalDetail');
	}, []);
	let history = useHistory();
	var dateFormat = require('dateformat');
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const outletID = localStorage.getItem('latestOutlet');

	const { state: detailState }: any = useLocation();

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);
	const [glExportList, setGlExportList] = useState(null);

	const csvRef = useRef(null);
	// console.log(csvRef)
	const initialState: Props = {
		Dialog: false,
		ImageUrl: '',
		GLDetailsData: [],
	};

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};
	const [state, dispatch] = useReducer(reducer, initialState);

	const createdByName = ID => {
		return getUsersByAccount?.find(x => x.ID === ID)?.name || '';
	};

	const {
		data: { getUsersByAccount } = { getUsersByAccount: [] },
	} = useGetUsersByAccountQuery({
		variables: {
			accountID: user?.accountID,
		},
		fetchPolicy: 'network-only',
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const {
		loading: glExportDetailLoading,
		data: { getExportedGLDetails } = { getExportedGLDetails: [] },
	} = useGetGlExportedDetailsQuery({
		fetchPolicy: 'no-cache',
		variables: {
			batchNo: Number(detailState.batchNo),
			outletID: outletID,
		},
	});

	const [
		ExportedGLTransferQuery,
		{
			loading: ExportedGLTransferDetailsLoading,
			data: { ExportedGLTransferDetails } = { ExportedGLTransferDetails: [] },
		},
	] = useExportedGlTransferDetailsLazyQuery({
		fetchPolicy: 'no-cache',
		variables: {
			outletID: outletID,
			accountID: JSON.parse(localStorage.getItem('loggedInUser'))?.accountID,
			batchNo: Number(detailState?.batchNo),
		},
	});

	const {
		loading: MenuAccountXListLoading,
		data: { MenuAccountXList } = {
			MenuAccountXList: {
				ID: '',
				outletID: '',
				vendorName: '',
				isActive: true,
			},
		},
	} = useMenuAccountXListQuery({
		variables: {
			outletID: outletID,
		},
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (getExportedGLDetails?.length > 0)
			dispatch({
				type: 'GLDetailsData',
				payload: getExportedGLDetails,
			});
	}, [getExportedGLDetails]);
	const GLDetailsGroupBy = _.groupBy(state.GLDetailsData, x => x.glType);
	const ExportCSVData = getExportedGLDetails?.map(x => ({
		DocDate: `${dateFormat(x.date, 'dd/mm/yyyy')}`,
		GLDate: `${dateFormat(x.gLDate, 'dd/mm/yyyy')}`,
		Submenu: 'MX',
		GLType: x.glType,
		Description: x.description,
		Department:
			x.department === null || x.department === '' ? 'DEP' : x.department,
		Division: x.division === null || x.division === '' ? 'DIV' : x.division,
		AccountCode: x.accountCode,
		Debit: Number(x.debit).toFixed(2),
		Credit: Number(x.credit).toFixed(2),
		Module: 'MX',
	}));
	// console.log(ExportCSVData, 'ExportCSVData')
	const GLDetailsList = array => {
		let keys = Object.keys(array);
		let values = Object.values(array);
		let map = new Map();
		for (var i = 0; i < keys.length; i++) {
			map.set(keys[i], values[i]);
		}
		return [...map].map(([glType, data]) => ({ glType, data }));
	};
	// console.log(
	//   GLDetailsList(GLDetailsGroupBy),
	//   'here'
	// )

	useEffect(() => {
		if (MenuAccountXList?.vendorName === 'AccountX') {
			ExportedGLTransferQuery({
				variables: {
					outletID: outletID,
					accountID: JSON.parse(localStorage.getItem('loggedInUser'))
						?.accountID,
					batchNo: Number(detailState?.batchNo),
				},
			});
		}
	}, [MenuAccountXList]);

	const ExportTransferCSVData =
		MenuAccountXList?.vendorName === 'AccountX'
			? ExportedGLTransferDetails?.map(x => ({
					JournalDate: `${dateFormat(x.JournalDate, 'dd/mm/yyyy')}`,
					JournalType: x.JournalTypeName,
					RefNo: x.DocNo,
					JournalDescription: x.Description,
					AccountName: x.AccountName,
					MasterCOACode: x.MasterCOACode,
					DepartmentCode: x.DepartmentCode,
					Remark: x.Remark,
					DocAmt: Number(x.Amount).toFixed(2),
			  }))
			: [];

	const debitAmount = getExportedGLDetails?.reduce((prev, curr) => {
		return prev + Number(curr.debit);
	}, 0);

	const creditAmount = getExportedGLDetails?.reduce((prev, curr) => {
		return prev + Number(curr.credit);
	}, 0);

	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const handleShare = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: outletID,
			},
		});
	};

	const handleGlExport = async () => {
		csvRef.current.link.click();
	};

	const Display = (hidden?: boolean) => {
		return (
			<div
				style={
					hidden
						? {
								zIndex: -40,
								top: 0,
								left: 0,
								position: 'fixed',
								width: '100%',
						  }
						: {
								marginTop: '0px',
								zIndex: 40,
								position: 'relative',
						  }
				}
				className={hidden ? 'hidden-content' : null}
			>
				<div
					id={!hidden ? 'gl ' : 'hiddenGl'}
					style={
						hidden
							? { marginTop: '-85px', width: '100%', height: '100%' }
							: { width: '100%', height: '100%' }
					}
				>
					<ContentWrapper
						style={{
							marginTop: isDesktop ? '152px' : '86px',
						}}
					>
						{GLDetailsList(GLDetailsGroupBy)?.map((gl, index) => (
							<Accordion
								className="accordion-card"
								defaultExpanded={hidden ? true : false}
								key={index}
							>
								<AccordionSummary
									expandIcon={<KeyboardArrowDown />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Grid container justifyContent="flex-start">
										<Grid
											item
											xs={6}
											className="xsTitle"
											style={{
												whiteSpace: 'normal',
												padding: '0px 6px',
											}}
										>
											{gl?.glType}
										</Grid>

										<Grid
											item
											xs={3}
											className="desc"
											style={{ display: 'flex', justifyContent: 'center' }}
										>
											{amtStr(
												gl.data?.reduce?.((acc, curr) => {
													return acc + Number(curr.debit);
												}, 0),
											) || 0}
										</Grid>
										<Grid
											item
											xs={3}
											className="desc"
											style={{ display: 'flex', justifyContent: 'center' }}
										>
											{amtStr(
												gl.data?.reduce?.((acc, curr) => {
													return acc + Number(curr.credit);
												}, 0),
											) || 0}
										</Grid>
									</Grid>
								</AccordionSummary>
								<AccordionDetails>
									{gl.data
										?.sort(
											(a, b) =>
												Number(new Date(a.date)) - Number(new Date(b.date)),
										)
										.map((trx, trxIndex) => (
											<>
												<Grid
													container
													justifyContent="flex-start"
													className="desc"
													style={{ width: '90%', padding: '4px' }}
												>
													<Grid item xs={3}>
														{dateFormat(trx.date, 'dd mmm yyyy')}
													</Grid>
													<Grid item xs={5} className="desc">
														{/* {trx.department}/{trx.division}/{trx.accountCode} */}
														{trx.department === null || trx.department === ''
															? 'DEP'
															: trx.department}
														/
														{trx.division === null || trx.division === ''
															? 'DIV'
															: trx.division}
														/{trx.accountCode}
													</Grid>
													<Grid
														item
														xs={2}
														className="desc "
														style={{
															display: 'flex',
															justifyContent: 'center',
														}}
													>
														{trx.debit === '0.00' ? null : amtStr(trx.debit)}
													</Grid>
													<Grid
														item
														xs={2}
														className="desc "
														style={{
															display: 'flex',
															justifyContent: 'center',
														}}
													>
														{trx.credit === '0.00' ? null : amtStr(trx.credit)}
													</Grid>
													<Grid
														item
														xs={3}
														style={{ paddingTop: '6px' }}
													></Grid>
													<Grid
														item
														xs={9}
														className="desc "
														style={{
															paddingTop: '6px',
														}}
													>
														{trx.description}
													</Grid>
												</Grid>
												<Box
													width="10%"
													display="flex"
													justifyContent="center"
													margin="auto"
												>
													<KeyboardArrowRight
														style={{ width: '18px', height: '18px' }}
														onClick={() => {
															history.push({
																pathname:
																	'/menu/outlet-app/account-journals/gl-journal/detail/individual',
																state: trx,
															});
														}}
													/>
												</Box>
												<Divider variant="fullWidth" className="flex" />
											</>
										))}
								</AccordionDetails>
							</Accordion>
						))}
					</ContentWrapper>
				</div>
			</div>
		);
	};

	return (
		<>
			{outletLoading && <Loading />}
			{shareAttachmentLoading && <Loading />}
			{glExportDetailLoading && <Loading />}
			{(ExportedGLTransferDetailsLoading || MenuAccountXListLoading) && (
				<Loading />
			)}

			<MainHeader
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/account-journals/gl-journal`,
					});
				}}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Account Journals' },
					{ name: 'GL Journal' },
					{ name: 'GL Journal', current: true },
				]}
			/>

			<GLJournalSubHeader
				style={{ top: '63px' }}
				// style={{ top: '1px' }}
				// isHidden={hidden}
				title={
					<span className="xsTitle" style={{ color: 'orange' }}>
						{formatDate(detailState.startDate)} -{' '}
						{formatDate(detailState.endDate)}
					</span>
				}
				infoLine={
					<>
						<div>
							<span className="desc icon-text">
								<span style={{ color: '#ff9800' }}>
									{detailState.transactionCnt}
								</span>
								&nbsp; transactions <TextSeparator />
								<img
									src={Auditor}
									alt="ifca"
									width={12}
									loading="lazy"
									style={{ paddingRight: '4px' }}
								/>
								<span style={{ color: '#ff9800' }}>
									{formatDate(detailState.glDate)}{' '}
									{createdByName(detailState.createdBy)}
								</span>
							</span>
						</div>
						<div style={{ marginTop: '20px' }}>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								spacing={2}
							>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									style={{
										paddingRight: '12px',
										paddingLeft: '12px',
									}}
								>
									<span className="xsTitle" style={{ paddingTop: '5px' }}>
										<span className="color-primary-orange">Debit:</span>
										<span style={{ color: 'black' }}>
											{' '}
											Σ {debitAmount?.toFixed(2)}
										</span>
									</span>
									<span className="xsTitle">
										<span className="color-primary-orange">Credit:</span>
										<span style={{ color: 'black' }}>
											{' '}
											Σ{creditAmount?.toFixed(2)}
										</span>
									</span>
								</Grid>
							</Grid>
						</div>
					</>
				}
			/>

			{Display()}
			{Display(true)}
			<div className="cover-layer" />
			<div style={{ marginBottom: '40px' }} />
			<CSVLink
				filename={`GL_Export_${dateFormat(
					detailState.createdTs,
					'dd_mm_yyyy',
				)}.csv`}
				data={
					MenuAccountXList?.vendorName === 'AccountX'
						? ExportTransferCSVData
						: ExportCSVData
				}
				ref={csvRef}
			/>

			{ShareDialog({
				shareTitle: `GL Export - ${dateFormat(
					detailState.createdTs,
					'dd_mm_yyyy',
				)}`,
				// shareTitle: `GL Export -`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}

			<DynamicFooter
				options={[
					// {
					// 	name: 'Share',
					// 	onClick: () => {
					// 		let temp = document.getElementById('hiddenGl');
					// 		htmlToImage
					// 			.toBlob(temp, { style: { background: '#fff' } })
					// 			.then(value => {
					// 				handleShare(value);
					// 			})
					// 			.catch(error => console.error(error, 'Something Went Wrong'));
					// 	},
					// 	color: 'primary',
					// 	startIcon: <ShareIcon />,
					// },
					{
						name: 'Download',
						onClick: () => handleGlExport(),
						color: 'primary',
						startIcon: <ShareIcon />,
					},
				]}
			/>
		</>
	);
};
