import { PosPermission } from 'generated/graphql';
import { useEffect, useState } from 'react';
import localForage from 'localforage';

interface PermProps {
	outletID?: string;
	permEnum: PosPermission;
}
export const usePermissionChecker = () => {
	const [permission, setPermMatch] = useState({});
	const [posPermission, setPOSPermission] = useState({});
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	useEffect(() => {
		localForage?.getItem('permission', function(err, value) {
			setPermMatch(value);
		});
	}, [setPermMatch]);

	useEffect(() => {
		localForage?.getItem('POSPermission', function(err, value) {
			setPOSPermission(value);
		});
	}, [setPOSPermission]);

	const handlePermDisabled = ({ outletID, permEnum }: PermProps) => {
		return !!outletID && !!posPermission
			? posPermission[outletID]?.hasOwnProperty(permEnum) === false &&
					!user?.superUser
			: permission?.hasOwnProperty(permEnum) === false && !user?.superUser;
	};

	const handlePermHidden = ({ outletID, permEnum }: PermProps) => {
		if (permEnum === null || permEnum === undefined) {
			return true;
		}
		return outletID
			? posPermission[outletID]?.hasOwnProperty(permEnum) || user?.superUser
			: permission?.hasOwnProperty(permEnum) || user?.superUser;
	};

	return {
		permission,
		posPermission,
		handlePermDisabled,
		handlePermHidden,
	};
};
