import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { useGetOutletQuery, useGetPromoMenuQuery } from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// interface DialogFormFields {
// 	name: string;
// 	StartDate: string;
// 	EndDate: string;
// 	description: string;
// 	taxType: string;
// 	PromotionValue: string;
// }

// const dialogFormFieldsInitialState: DialogFormFields = {
// 	name: '',
// 	StartDate: '',
// 	EndDate: '',
// 	description: '',
// 	taxType: '',
// 	PromotionValue: '',
// };

export const PromotionalMenuList = () => {
	useEffect(() => {
		console.log('PromotionalMenuList');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const [state, setState] = useState({
		blacklisted: true,
	});
	const { outletID, promomtionalMenuID } = useParams<any>();

	const {
		data,
		called: promoMenuCalled,
		loading: promoMenuLoading,
	} = useGetPromoMenuQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
	});

	useEffect(() => {
		if (menu?.ID) {
			history.push(
				`/menu/outlet-app/outlet-setting/${outletID}/promotional-menu/${menu?.ID}/edit/promotional-menu-pricing`,
			);
		}
	}, [menu?.ID]);

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	return (
		<>
			{promoMenuLoading && <Loading />}
			{outletLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },

					{ name: 'Promotional Menu & Pricing', current: true },
				]}
			/>
			<ContentWrapper float>
				<List className="core-list">
					{!promoMenuLoading &&
					!outletLoading &&
					(data?.getPromoMenu === undefined ||
						data?.getPromoMenu?.length === 0) ? (
						<EmptyList
							title="No Promotion Menu found"
							subtitle="Please add a promotion menu to continue"
						/>
					) : (
						data?.getPromoMenu?.map((el, index) => (
							<ListItem>
								<ListItemText
									primary={
										<>
											<span className="xsTitle">{el.name}</span>
										</>
									}
									secondary={
										<>
											<span className="desc flex-space">{`No. of Menu Items: ${el?.promoMenuItem?.length}`}</span>
										</>
									}
								/>

								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										onClick={e => {
											handleClick(e, el?.ID, index, el);
											history.push(
												`/menu/outlet-app/outlet-setting/${outletID}/promotional-menu/${el.ID}/edit/promotional-menu-pricing`,
											);
										}}
									>
										<KeyboardArrowRight />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))
					)}
				</List>
			</ContentWrapper>
			<FloatButton
				// disabled={handlePermDisabled({
				// //   contractID: '',
				// //   permEnum:
				// //     ContractPermission.HomeProjectPurchasesContractListingExecSummaryDeliveryOrderCreate,
				// })}
				onClick={() =>
					history.push(
						`/menu/outlet-app/outlet-setting/${outletID}/promotional-menu/add`,
					)
				}
			/>
		</>
	);
};
