import React from 'react';
import { CustomerPolicySetupForm } from './CustomerPolicySetupForm';
import { OutletPolicyList } from './OutletPolicyList';
import { PatronSetupForm } from './PatronSetupForm';
import { ReasonSetupList } from './ReasonSetupList';
import { ServiceChargeList } from './ServiceChargeList';

const outletPolicyRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/outlet-policy/patron-setup`,
		},
		component: <PatronSetupForm />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/outlet-policy`,
		},
		component: <OutletPolicyList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/outlet-policy/service-charge`,
		},
		component: <ServiceChargeList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/outlet-policy/reason-setup`,
		},
		component: <ReasonSetupList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/outlet-policy/customer-policy-setup`,
		},
		component: <CustomerPolicySetupForm />,
	},
];

export default outletPolicyRoutes;
