import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Checkbox,
	Grid,
	ListItem,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetStopSalesDocument,
	useGetMenuItemForStopSaleLazyQuery,
	useGetOutletQuery,
	useGetStopSalesQuery,
	useSubmitStopSalesMutation,
} from 'generated/graphql';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

interface StopSalesThing {
	name: string;
	description: string;
	menuItemID: string;
	stopSales: any;
}

export const StopSalesItemForm = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();

	const [checkedID, setCheckedID] = useState([{}]);
	const { outletID }: any = useParams();
	const { mode } = props;

	let location = useLocation();

	const editData: any = location?.state as any;

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { globalState, dispatch } = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	// const [
	// 	loadMenuItem,
	// 	{
	// 		called: menuItemCalled,
	// 		loading: menuItemLoading,
	// 		error: menuItemError,
	// 		data: { getMenuItem } = { getMenuItem: [] },
	// 	},
	// ] = useGetMenuItemLazyQuery({
	// 	fetchPolicy: 'network-only',
	// 	variables: { outletID: outletID },
	// });

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const [
		loadMenuItemForStopSale,
		{
			called: menuItemForStopSaleCalled,
			loading: menuItemForStopSaleLoading,
			error: menuItemForStopSaleError,
			data: { getMenuItemForStopSale } = { getMenuItemForStopSale: [] },
		},
	] = useGetMenuItemForStopSaleLazyQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
		onCompleted: ({ getMenuItemForStopSale }) => {},
	});

	useEffect(() => {
		if (getMenuItemForStopSale && getMenuItemForStopSale.length > 0) {
			setOriginalListing(getMenuItemForStopSale);
		}
	}, [getMenuItemForStopSale]);

	useEffect(() => {
		if (!menuItemForStopSaleCalled) {
			loadMenuItemForStopSale({ variables: { outletID } });
		}
	}, [menuItemForStopSaleCalled, menuItemForStopSaleLoading]);

	const {
		called: stopSalesCalled,
		loading: stopSalesLoading,
		error: stopSalesError,
		data: { getStopSales } = { getStopSales: [] },
	} = useGetStopSalesQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
		onCompleted: data => {
			setCheckedID(
				data?.getStopSales?.map(x => {
					return { menuItemID: x?.menuItemID };
				}),
			);
		},
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	//Checkbox

	// const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
	// 	setState({ ...state, [event.target.name]: event.target.checked });
	// 	// console.log(state.OnBehalfPurchasing);
	// };

	const [
		submitStopSales,
		{ loading: submitStopSalesLoading, error },
	] = useSubmitStopSalesMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(`${PROJECT_PURCHASE_DO_PATH}`);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/stop-sales-item`,

					// pathname: `${PROJECT_OUTLET_APP_PROMO}`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
		},
	});

	const { handleSubmit, register, setValue, watch, errors, control } = useForm<
		StopSalesThing
	>({
		mode: 'all',
		defaultValues: {
			name: editData ? editData?.name : '',
			description: editData ? editData?.description : '',
		},
		// resolver: yupResolver(validationSchema),
	});

	// const handleToggle = ({ target }) =>
	// setState(s => ({ ...s, [target.name]: !s[target.name] }));

	const onSubmit = async (data: any) => {
		submitStopSales({
			variables: {
				input: checkedID?.map((check: any, index) => {
					return {
						menuItemID: check?.menuItemID,
					};
				}),
				outletID: outletID,
			},
			refetchQueries: [
				{ query: GetStopSalesDocument, variables: { outletID } },
			],
		});
	};

	// const [pack, setPack] = useState(new Set());

	const handleCheckBoxChange = (e: any, index: number) => {
		let temp: any = checkedID;
		if (e.target.checked) {
			temp.push({ menuItemID: e.target.value });
			setCheckedID(temp);
		} else {
			temp = checkedID.filter((v: any) => v?.menuItemID !== e.target.value);
			setCheckedID(temp);
		}
	};

	const [openExitDialog, setOpenExitDialog] = useState(false);

	return (
		<>
			{menuItemForStopSaleLoading && <Loading />}
			{stopSalesLoading && <Loading />}
			{outletLoading && <Loading />}
			{submitStopSalesLoading && <Loading />}

			<MainHeader
				onClick={() =>
					/* history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/stop-sales-item`,
					}) */
					setOpenExitDialog(true)
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Stop Sales Items', current: true },
				]}
				rightRouteSegments={[{ name: 'New' }]}
			/>
			<SearchHeader
				title="Menu Item"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['name']);
				}}
				fixed
				search
				onCloseAction={() => handleSearch('', [])}
			/>
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<ContentWrapper search footer>
					<MsgAlert
						//   icon={<InfoOutlined />}
						message={'Select Menu Item to stop sales.'}
					/>
					<CardContents>
						{!menuItemForStopSaleLoading &&
							!stopSalesLoading &&
							!outletLoading &&
							!submitStopSalesLoading &&
							(getMenuItemForStopSale === undefined ||
							getMenuItemForStopSale?.length === 0 ? (
								<EmptyList
									title="No Record found"
									subtitle="Please add reservation to continue"
								/>
							) : (
								stopSalesCalled &&
								!stopSalesLoading &&
								filteredList.map((el, index) => {
									return (
										<>
											<Grid container>
												<Grid item xs={1}>
													<Checkbox
														value={el?.ID}
														defaultChecked={
															getStopSales?.filter(
																def => def?.menuItemID === el?.ID,
															).length > 0
																? true
																: false
														}
														onChange={e => handleCheckBoxChange(e, index)}
														name="menuItemID"
														color="primary"
														inputProps={{
															'aria-label': 'secondary checkbox',
														}}
													/>
												</Grid>
												<Grid item xs={11}>
													<ListItem key={index}>
														<ListItemText
															primary={
																<div className="smTitle">{el?.name}</div>
															}
															secondary={
																<>
																	{el?.setMenuInfo
																		? el?.setMenuInfo?.map((set, index) => {
																				return (
																					<div className="desc">
																						{set?.setMenuName}
																					</div>
																				);
																		  })
																		: null}
																</>
															}
														/>
													</ListItem>
												</Grid>
											</Grid>
										</>
									);
								})
							))}
					</CardContents>
				</ContentWrapper>
				<DynamicFooter
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					options={[
						{
							name: 'Save',
							color: 'primary',
							// onClick: () => {
							// 	handleSubmit(onSubmit);
							// 	// history.goBack()
							// },
							onClick: handleSubmit(onSubmit),
							props: {
								type: 'submit',
							},
						},
					]}
				/>
				{/* <DynamicFooter
				options={[
					{
						name: 'Save',
						onClick: () => {
							console.log(checkedID, 'data');
						},
						color: 'primary',
						props: {
							type: 'submit',
						},
					},
				]}
			/> */}
				<ExitConfirmationDialog
					openExitDialog={openExitDialog}
					setOpenExitDialog={setOpenExitDialog}
					backPath={`/menu/outlet-app/outlet-setting/${outletID}/stop-sales-item`}
				/>
				{/* <CommonDialog
					fullWidth={true}
					open={openExitDialog}
					onClose={() => {
						setOpenExitDialog(false);
					}}
					sections={{
						header: {
							dynamic: (
								<div>
									<div className="dialogo-dynamic-content">
										<span
											className="title c-orange flex-space"
											style={{
												fontSize: '13px',
												fontWeight: 'bold',
											}}
										>
											Exit Confirmation
										</span>
									</div>
								</div>
							),
						},
						body: () => (
							<CardContents>
								<span className="">
									Are you sure you want to exit? Your changes will not be saved.
								</span>
							</CardContents>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
										},
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
											history.push({
												pathname: `/menu/outlet-app/outlet-setting/${outletID}/stop-sales-item`,
											});
										},
										variant: 'contained',
										color: 'primary',
									},
								},
							],
						},
					}}
				/> */}
			</form>
		</>
	);
};
