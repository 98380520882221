import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetStopSalesDocument,
	useDeleteStopSalesMutation,
	useGetOutletQuery,
	useGetStopSalesQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
interface DialogFormFields {
	name: string;
	description: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
	description: '',
};

const sampleData = [
	{
		ID: '1',
		name: 'Beer 1',
		description: 'something beer 1',
	},
	{
		ID: '2',
		name: 'Beer 2',
		description: 'something beer 2',
	},
	{
		ID: '3',
		name: 'Beer 3',
		description: 'something beer 3',
	},
];

export const StopSalesItemList = () => {
	const history = useHistory();
	const { pathname } = useLocation();

	const { outletID } = useParams<Record<string, any>>();
	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [deleteID, setDeleteID] = useState('');
	const [deleteName, setDeleteName] = useState('');
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const {
		called: stopSalesCalled,
		loading: stopSalesLoading,
		error: stopSalesError,
		data: { getStopSales } = { getStopSales: [] },
	} = useGetStopSalesQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
	});

	const [
		deleteStopSales,
		{ loading: deleteStopSalesLoading },
	] = useDeleteStopSalesMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});
	// variables:
	// {
	// 	isActive = true;
	// }

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	return (
		<>
			{stopSalesLoading && <Loading />}
			{outletLoading && <Loading />}
			{deleteStopSalesLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Stop Sales Items', current: true },
				]}
			/>

			<ContentWrapper>
				<List className="core-list">
					{!stopSalesLoading &&
						!outletLoading &&
						!deleteStopSalesLoading &&
						((!stopSalesCalled && getStopSales === undefined) ||
						getStopSales?.length === 0 ? (
							<EmptyList
								title="No Record found"
								subtitle="Please add stop sales item to continue"
							/>
						) : (
							getStopSales.map((el, index) => {
								return (
									<ListItem key={index} alignItems="flex-start">
										<ListItemText
											// style={{ display: 'inline' }}
											primary={
												<>
													<span className="xsTitle" style={{ fontSize: 12 }}>
														{/* {'PROMO30'} */}
														{el?.menuItem?.name}
													</span>
												</>
											}
											secondary={
												<>
													<span className="desc flex-space text-noflow ">
														{el?.menuItem?.description}
													</span>
												</>
											}
										/>

										<ListItemSecondaryAction>
											<DeleteIcon
												color={'error'}
												fontSize="small"
												onClick={e => {
													handleClick(e, el.ID, index, el);
													setDeleteDialog(true);
												}}
											/>
										</ListItemSecondaryAction>
									</ListItem>
								);
							})
						))}
				</List>
			</ContentWrapper>
			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={'Stop Sales Item'}
				deleteMutation={deleteStopSales}
				refetchDocument={GetStopSalesDocument}
			/>
			<FloatButton
				// isCreate={true}
				onClick={() =>
					history.push(
						`/menu/outlet-app/outlet-setting/${outletID}/stop-sales-item/add`,
						// __OUTLET_APP__OUTLETS__OUTLET__STOP_SALES__ADD
					)
				}
			/>
		</>
	);
};
