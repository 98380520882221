import React from 'react';
import { ReceiptPrinterList } from './ReceiptPrinterList';

const receiptPrinterRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/receipt-printer`,
		},
		component: <ReceiptPrinterList />,
	},
];

export default receiptPrinterRoutes;
