import theme from '@ifca-root/react-component/src/assets/theme';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import { ListItemText, useMediaQuery } from '@material-ui/core';
import DeliveryColorIcon from 'assets/icons/delivery_color.svg';
import HandphoneIcon from 'assets/icons/handphone.svg';
import PickupColorIcon from 'assets/icons/pickup_color.svg';
import TimeBlackIcon from 'assets/icons/time_black.svg';
import AuditIcon from 'assets/icons/audit.svg';
import { MutliLineSubheader } from 'components/Layout/MultiLineSubHeader';
import AppContext from 'containers/App/Store/AppContext';
import {
	useGetMenuXInhouseListLazyQuery,
	useGetOfficerLazyQuery,
	useGetOutletQuery,
	useGetStaffLazyQuery,
} from 'generated/graphql';
// import { formatTime } from 'helpers/formatDate';
import { formatDateTime, formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext } from 'react';
import { useHistory } from 'react-router';

interface OrderSubheaderProps {
	mode: any;
	getOrderLoading: boolean;
	getOrder: any;
	selectedOrder: any;
	orderID: string;
	handleChangeOrder?: any;
	outletID: string;
	isAuditDialog?: boolean;
	setOpenAuditDialog?: any;
	getVoidedDiscountQuery?: any;
	path?: any;
	module?: any;

	// subTitle?: string;
	// secSubTitle?: string;
	// simpleDescription?: string;
	// isForm?: boolean;
	// handleSubmit: any;
	// onSubmit: any;
	// formStructure: any;
	// infoLine?: string;
	// infoLineRight?: string;
	// infoLineMiddle?: any;
}

export const OrderSubheader = (props: OrderSubheaderProps) => {
	const {
		mode,
		getOrderLoading,
		getOrder,
		selectedOrder,
		orderID,
		handleChangeOrder,
		outletID,
		isAuditDialog,
		setOpenAuditDialog,
		getVoidedDiscountQuery,
		path,
		module,
	} = props;

	const history = useHistory();

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadMenuXInHouseList({
				variables: {
					hotelID: getOutlet[0]?.outletChannel?.hotelID,
					outletID: outletID,
				},
			});
			loadOfficer({
				variables: {
					isActive: true,
				},
			});
			loadStaff({
				variables: {
					isActive: true,
				},
			});
		},
	});

	const [
		loadStaff,
		{ loading: getStaffLoading, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadOfficer,
		{ loading: getOfficerLoading, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadMenuXInHouseList,
		{
			loading: MenuXInhouseListLoading,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
	});

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const customerTypeInfoLine = () => {
		const patronClass = selectedOrder?.patron?.patronClass;
		if (patronClass === 'Visitor') {
			return selectedOrder?.patronName;
		} else if (patronClass === 'Hotel Guest') {
			const selectedGuest = getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
				x => x?.RegistrationID === selectedOrder?.patronAccountID,
			)[0];
			return selectedGuest
				? `${selectedGuest?.RoomNo ? `${selectedGuest?.RoomNo} |` : ''} ${
						selectedGuest?.GuestName
				  }`
				: 'Hotel Guest';
		} else if (patronClass === 'Staff') {
			return getStaff?.filter(x => x?.ID === selectedOrder?.patronAccountID)[0]
				?.name;
		} else if (patronClass === 'Officer') {
			return getOfficer?.filter(
				x => x?.ID === selectedOrder?.patronAccountID,
			)[0]?.name;
		} else if (patronClass === 'Member') {
			return '';
		}
	};

	const { globalState, dispatch }: any = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	return (
		<>
			{mode === 'dineIn' ? (
				!getOrderLoading && (
					<DynamicSubHeader
						title={
							getOrder === undefined || getOrder?.length === 0 ? (
								''
							) : (
								<>
									<span
										className="highlight"
										style={{ color: '#ff9800', paddingRight: '4px' }}
									>
										{`${selectedOrder?.table?.prefix}${selectedOrder?.table?.tableNumber}`}
									</span>
									<span>{`${selectedOrder?.docNo}`}</span>
								</>
							)
						} // title text
						handleClick={e => handleClick(e, orderID, 0)} // onClick function for dropdown
						dropDownOptions={
							// dropdown component place here
							module !== 'audit-log' ? (
								<StyledMenu
									id="customized-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleClose}
									onClick={handleClose}
								>
									{getOrder.map((order, index) => (
										<StyledMenuItem
											key={index}
											value={order.ID}
											defaultValue={order.ID}
											onClick={e => {
												handleChangeOrder(e, order);
											}}
										>
											<ListItemText primary={order?.docNo} />
										</StyledMenuItem>
									))}
								</StyledMenu>
							) : null
						}
						rightText={
							mode === 'takeAway' ? (
								<span>{`${amtStr(selectedOrder?.outstandingAmount)}`}</span>
							) : (
								<div
									style={{ color: '#ff9800' }}
								>{`${selectedOrder?.pax} Pax`}</div>
							)
						} // right text
						infoLine={
							// infoline text
							<>
								<div className="desc">
									<span className="desc">
										{selectedOrder?.patron?.patronClass === 'Member'
											? `${selectedOrder?.patron?.patronClass}`
											: `${customerTypeInfoLine()} | ` +
											  `${selectedOrder?.patron?.patronClass}`}
									</span>
									<span style={{ float: 'right' }}>
										{isAuditDialog ? (
											<span
												className="desc"
												onClick={
													() =>
														getVoidedDiscountQuery
															? getVoidedDiscountQuery({
																	variables: {
																		orderID: orderID,
																		outletID: outletID,
																	},
															  })
															: history.push(`${path}/audit-log`, {
																	orderID: orderID,
															  })
													// setOpenAuditDialog(true)
												}
											>
												<img
													src={AuditIcon}
													alt="ifca"
													width={12}
													loading="lazy"
													style={{ paddingRight: '4px', paddingLeft: '4px' }}
												/>
											</span>
										) : null}
									</span>
								</div>
							</>
						}
					/>
				)
			) : (
				<MutliLineSubheader
					title={
						getOrder === undefined || getOrder.length === 0 ? (
							''
						) : (
							<>
								{`#${selectedOrder?.docNo} |
							${customerTypeInfoLine()}`}
							</>
						)
					}
					subtitle={
						<>
							<span className="flex-space mdDesc">
								{selectedOrder?.contactNo && (
									<>
										<img
											src={HandphoneIcon}
											style={{
												width: '10px',
												height: '10px',
												paddingRight: '3px',
											}}
										/>
										<span className="primary-click-text">
											{selectedOrder?.contactNo}
										</span>
										<TextSeparator />
									</>
								)}

								<img
									src={TimeBlackIcon}
									style={{
										width: '10px',
										height: '10px',
										paddingRight: '3px',
									}}
								/>
								<span>
									{selectedOrder?.pickupTime
										? formatTime(selectedOrder?.pickupTime)
										: formatDateTime(selectedOrder?.createdTs)}
								</span>

								<img
									src={
										selectedOrder?.serviceMode === 'PICKUP'
											? PickupColorIcon
											: DeliveryColorIcon
									}
									style={{
										width: '13px',
										height: '13px',
										paddingLeft: '3px',
									}}
								/>
							</span>
						</>
					}
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 288px)'
								: isDesktop && !globalState.drawerOpen
								? 'calc(100% - 47px) '
								: '100%',
						marginLeft: isDesktop ? '3px' : null,
					}}
				/>
			)}
		</>
	);
};
