import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Grid, TextField } from '@material-ui/core';
import {
	PaymentClass,
	useGetDefaultPaymentQuery,
	useGetOutletQuery,
	useGetPatronQuery,
} from 'generated/graphql';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

// const useStyles = makeStyles({
// 	spaced: {
// 		['& > *']: {
// 			marginRight: '0.5rem',
// 		},
// 		['& :not(span)']: {
// 			width: '0.8rem',
// 			height: '0.8rem',
// 		},
// 	},
// });

interface InterfaceSetup {
	API: String;
	secretKey: String;
}

export const InterfaceSetup = () => {
	// const classes = useStyles();
	const { outletID }: any = useParams();
	const history = useHistory();
	const location = useLocation();
	const editData = location?.state as any;
	const [hotel, setHotel] = useState(false);
	const [member, setMember] = useState(false);

	const { register, control, handleSubmit, watch, setValue } = useForm<
		InterfaceSetup
	>({
		defaultValues: {
			API: editData ? editData?.API : '',
			secretKey: editData ? editData?.secretKey : '',
		},
	});

	//QUERY
	//QUERY
	//QUERY

	const {
		called: getPatronCalled,
		loading: getPatronLoading,
		data: { getPatron } = { getPatron: [] },
	} = useGetPatronQuery({
		variables: { outletID: outletID },
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const {
		called: defaultPaymentCalled,
		loading: defaultPaymentLoading,
		error: defaultPaymentError,
		data: { getDefaultPayment } = { getDefaultPayment: [] },
	} = useGetDefaultPaymentQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
	});

	const showMember = !getDefaultPayment?.filter(
		def => def?.paymentClass === PaymentClass.Member,
	)[0]
		? false
		: true;

	const showHotel = !getDefaultPayment?.filter(
		def => def?.paymentClass === PaymentClass.Room,
	)[0]
		? false
		: true;

	return (
		<>
			{outletLoading && <Loading />}
			{defaultPaymentLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: '3rd Party Interface Setup', current: true },
				]}
			/>
			<ContentWrapper>
				{!defaultPaymentLoading && showMember ? (
					<CardContents>
						<Grid container>
							<Grid item xs={12}>
								<div className="flex-space xsTitle">Member</div>
							</Grid>
							<Grid item xs={12}>
								<div className="desc">API</div>
								<Controller
									as={<TextField />}
									name={'member.API'}
									variant="outlined"
									margin="dense"
									label="API Here..."
									fullWidth
									autoComplete="off"
									control={control}
									inputRef={register()}
								/>
							</Grid>
							<Grid item xs={12}>
								<div className="desc">Secret Key</div>
								<Controller
									as={<TextField />}
									name={'member.secretKey'}
									variant="outlined"
									margin="dense"
									label="Secret Key Here..."
									fullWidth
									autoComplete="off"
									control={control}
									inputRef={register()}
								/>
							</Grid>
						</Grid>
					</CardContents>
				) : null}

				{!defaultPaymentLoading && showHotel ? (
					<CardContents>
						<Grid container>
							<Grid item xs={12}>
								<div className="flex-space xsTitle">Hotel</div>
							</Grid>
							<Grid item xs={12}>
								<div className="desc">API</div>
								<Controller
									as={<TextField />}
									name={'hotel.API'}
									variant="outlined"
									margin="dense"
									label="API Here..."
									fullWidth
									autoComplete="off"
									control={control}
									inputRef={register()}
								/>
							</Grid>
							<Grid item xs={12}>
								<div className="desc">Secret Key</div>
								<Controller
									as={<TextField />}
									name={'hotel.secretKey'}
									variant="outlined"
									margin="dense"
									label="Secret Key Here..."
									fullWidth
									autoComplete="off"
									control={control}
									inputRef={register()}
								/>
							</Grid>
						</Grid>
					</CardContents>
				) : null}
			</ContentWrapper>
			<DynamicFooter
				options={[
					{
						name: 'Save',
						// onClick: () => {
						// 	handleSubmit(onSubmit)();
						// 	history.push({
						// 	pathname: `/menu/outlet-app/general-setting/employee`,
						// 	});
						// },

						color: 'primary',
						props: {
							type: 'submit',
						},
					},
				]}
			/>
		</>
	);
};
