import { TextField } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import {
	renderChargesTypeDefaultValue,
	renderDebitCreditDefaultValue,
	renderProperCase,
	renderRevenueDefaultValue,
} from './Common';

export const ClosedBill = ({ state, dispatch, outlet }) => {
	let majorAndTax = state?.charges?.filter(
		charge =>
			charge?.descriptionType === 'Major' ||
			charge?.descriptionType === 'OutletTaxPolicy',
	);
	let discAndPayment = state?.charges?.filter(
		charge =>
			charge?.descriptionType === 'DiscountType' ||
			charge?.descriptionType === 'DiscountTaxType' ||
			charge?.descriptionType === 'DiscountServiceChargeType' ||
			charge?.descriptionType === 'PaymentType' ||
			charge?.descriptionType === 'Rounding',
	);
	return (
		<>
			<TableRow className="mapping-tr">
				<TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
					<span className="xsTitle ">Closed Bill</span>
				</TableCell>
				<TableCell align="left">
					<span className="xsTitle title-row">-</span>
				</TableCell>
				<TableCell align="left">
					<span className="xsTitle  title-row">-</span>{' '}
				</TableCell>
				<TableCell align="left">
					<span className="xsTitle title-row">-</span>{' '}
				</TableCell>
				<TableCell align="left">
					<span className="xsTitle title-row">-</span>
				</TableCell>
				<TableCell align="left">
					<span className="xsTitle title-row">-</span>
				</TableCell>
			</TableRow>
			{majorAndTax?.map((row, i) => (
				<TableRow className="mapping-tr" key={i}>
					<TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
						{renderProperCase(row?.description, row?.descriptionType)}
					</TableCell>
					<TableCell align="left">
						{state?.mode === 'edit' ? (
							row?.outletGLAccount?.revenueDiv ||
							renderRevenueDefaultValue(
								row?.descriptionType,
								'Division',
								outlet,
							)
						) : (
							<TextField
								id="outlined-basic"
								value={majorAndTax?.[i]?.outletGLAccount?.revenueDiv}
								placeholder={renderRevenueDefaultValue(
									row?.descriptionType,
									'Division',
									outlet,
								)}
								className="mapping-textfield"
								variant="outlined"
								onChange={event => {
									const newArr: any = state?.charges;
									let selected = newArr[i]?.outletGLAccount;
									selected.revenueDiv = event?.target?.value;

									dispatch({
										type: 'charges',
										payload: newArr,
									});
								}}
							/>
						)}
					</TableCell>
					<TableCell align="left">
						{state?.mode === 'edit' ? (
							row?.outletGLAccount?.revenueDept ||
							renderRevenueDefaultValue(
								row?.descriptionType,
								'Department',
								outlet,
							)
						) : (
							<TextField
								id="outlined-basic"
								value={majorAndTax?.[i]?.outletGLAccount?.revenueDept}
								placeholder={
									row?.outletGLAccount?.revenueDept ||
									renderRevenueDefaultValue(
										row?.descriptionType,
										'Department',
										outlet,
									)
								}
								className="mapping-textfield"
								variant="outlined"
								onChange={event => {
									const newArr: any = state?.charges;
									let selected = newArr[i]?.outletGLAccount;
									selected.revenueDept = event?.target?.value;

									dispatch({
										type: 'charges',
										payload: newArr,
									});
								}}
							/>
						)}
					</TableCell>
					<TableCell align="left">{row?.outletGLAccount?.type}</TableCell>
					<TableCell
						align="left"
						style={{
							backgroundColor: '#f5f5f5',
						}}
					></TableCell>
					<TableCell align="left">
						{state?.mode === 'edit' ? (
							row?.outletGLAccount?.revenueCredit ||
							`${renderProperCase(
								row?.description,
								row?.descriptionType,
							)} Revenue`
						) : (
							<TextField
								id="outlined-basic"
								value={majorAndTax?.[i]?.outletGLAccount?.revenueCredit}
								placeholder={`${renderProperCase(
									row?.description,
									row?.descriptionType,
								)} Revenue`}
								className="mapping-textfield"
								variant="outlined"
								onChange={event => {
									const newArr: any = state?.charges;
									let selected = newArr[i]?.outletGLAccount;
									selected.revenueCredit = event?.target?.value;

									dispatch({
										type: 'charges',
										payload: newArr,
									});
								}}
							/>
						)}
					</TableCell>
				</TableRow>
			))}
			{discAndPayment?.map((row, i) => (
				<TableRow className="mapping-tr" key={i}>
					<TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
						{row?.description === 'Bank TT' ? row?.description : renderProperCase(row?.description, row?.descriptionType)}
					</TableCell>
					<TableCell align="left">
						{row?.descriptionType === 'PaymentType' ?
							state?.mode === 'edit' ? (
								row?.outletGLAccount?.revenueDiv ||
								renderRevenueDefaultValue(
									row?.descriptionType,
									'Division',
									outlet,
								)
							) : (
								<TextField
									id="outlined-basic"
									value={discAndPayment?.[i]?.outletGLAccount?.revenueDiv}
									placeholder={renderRevenueDefaultValue(
										row?.descriptionType,
										'Division',
										outlet,
									)}
									className="mapping-textfield"
									variant="outlined"
									onChange={event => {
										const newArr: any = state?.charges;
										let selected = newArr[i + majorAndTax?.length]?.outletGLAccount;
										selected.revenueDiv = event?.target?.value;

										dispatch({
											type: 'charges',
											payload: newArr,
										});
									}}
								/>
							) : null}
						{row?.descriptionType === 'DiscountType' || row?.descriptionType === 'DiscountTaxType' || row?.descriptionType === 'DiscountServiceChargeType' ?
							state?.mode === 'edit' ? (
								row?.outletDiscountGLAcct?.revenueDiv ||
								renderRevenueDefaultValue(
									row?.descriptionType,
									'Division',
									outlet,
								)
							) : (
								<TextField
									id="outlined-basic"
									value={discAndPayment?.[i]?.outletDiscountGLAcct?.revenueDiv}
									placeholder={renderRevenueDefaultValue(
										row?.descriptionType,
										'Division',
										outlet,
									)}
									className="mapping-textfield"
									variant="outlined"
									onChange={event => {
										const newArr: any = state?.charges;
										let selected = newArr[i + majorAndTax?.length]?.outletDiscountGLAcct;
										selected.revenueDiv = event?.target?.value;

										dispatch({
											type: 'charges',
											payload: newArr,
										});
									}}
								/>
							) : null}
						{row?.descriptionType === 'Rounding' ?
							state?.mode === 'edit' ? (
								row?.outletRoundingGLAccount?.revenueDiv ||
								renderRevenueDefaultValue(
									row?.descriptionType,
									'Division',
									outlet,
								)
							) : (
								<TextField
									id="outlined-basic"
									value={discAndPayment?.[i]?.outletRoundingGLAccount?.revenueDiv}
									placeholder={renderRevenueDefaultValue(
										row?.descriptionType,
										'Division',
										outlet,
									)}
									className="mapping-textfield"
									variant="outlined"
									onChange={event => {
										const newArr: any = state?.charges;
										let selected = newArr[i + majorAndTax?.length]?.outletRoundingGLAccount;
										selected.revenueDiv = event?.target?.value;

										dispatch({
											type: 'charges',
											payload: newArr,
										});
									}}
								/>
							) : null}
					</TableCell>
					<TableCell align="left">
						{row?.descriptionType === 'PaymentType' ?
							state?.mode === 'edit' ? (
								row?.outletGLAccount?.revenueDept ||
								renderRevenueDefaultValue(
									row?.descriptionType,
									'Department',
									outlet,
								)
							) : (
								<TextField
									id="outlined-basic"
									value={discAndPayment?.[i]?.outletGLAccount?.revenueDept}
									placeholder={renderRevenueDefaultValue(
										row?.descriptionType,
										'Department',
										outlet,
									)}
									className="mapping-textfield"
									variant="outlined"
									onChange={event => {
										const newArr: any = state?.charges;
										let selected = newArr[i + majorAndTax?.length]?.outletGLAccount;
										selected.revenueDept = event?.target?.value;

										dispatch({
											type: 'charges',
											payload: newArr,
										});
									}}
								/>
							) : null}
						{row?.descriptionType === 'DiscountType' || row?.descriptionType === 'DiscountTaxType' || row?.descriptionType === 'DiscountServiceChargeType' ?
							state?.mode === 'edit' ? (
								row?.outletDiscountGLAcct?.revenueDept ||
								renderRevenueDefaultValue(
									row?.descriptionType,
									'Department',
									outlet,
								)
							) : (
								<TextField
									id="outlined-basic"
									value={discAndPayment?.[i]?.outletDiscountGLAcct?.revenueDept}
									placeholder={renderRevenueDefaultValue(
										row?.descriptionType,
										'Department',
										outlet,
									)}
									className="mapping-textfield"
									variant="outlined"
									onChange={event => {
										const newArr: any = state?.charges;
										let selected = newArr[i + majorAndTax?.length]?.outletDiscountGLAcct;
										selected.revenueDept = event?.target?.value;

										dispatch({
											type: 'charges',
											payload: newArr,
										});
									}}
								/>
							) : null}
						{row?.descriptionType === 'Rounding' ?
							state?.mode === 'edit' ? (
								row?.outletRoundingGLAccount?.revenueDept ||
								renderRevenueDefaultValue(
									row?.descriptionType,
									'Department',
									outlet,
								)
							) : (
								<TextField
									id="outlined-basic"
									value={discAndPayment?.[i]?.outletRoundingGLAccount?.revenueDept}
									placeholder={renderRevenueDefaultValue(
										row?.descriptionType,
										'Department',
										outlet,
									)}
									className="mapping-textfield"
									variant="outlined"
									onChange={event => {
										const newArr: any = state?.charges;
										let selected = newArr[i + majorAndTax?.length]?.outletRoundingGLAccount;
										selected.revenueDept = event?.target?.value;

										dispatch({
											type: 'charges',
											payload: newArr,
										});
									}}
								/>
							) : null}
					</TableCell>
					<TableCell align="left">
						{row?.descriptionType === 'PaymentType' ?
							state?.mode === 'edit' ? (
								row?.outletGLAccount?.type
							) : state?.mode === 'save' && row?.outletGLAccount?.type === 'GL' ? row?.outletGLAccount?.type
								:
								(
									<TextField
										id="outlined-basic"
										value={discAndPayment?.[i]?.outletGLAccount?.type}
										placeholder={discAndPayment?.[i]?.outletGLAccount?.type}
										className="mapping-textfield"
										variant="outlined"
										disabled={discAndPayment?.[i]?.outletGLAccount?.type === "GL"}
										onChange={event => {
											const newArr: any = state?.charges;
											let selected = newArr[i + majorAndTax?.length]?.outletGLAccount;
											selected.type = event?.target?.value;

											dispatch({
												type: 'charges',
												payload: newArr,
											});
										}}
									/>
								) : null}
						{row?.descriptionType === 'DiscountType' || row?.descriptionType === 'DiscountTaxType' || row?.descriptionType === 'DiscountServiceChargeType' ?
							state?.mode === 'edit' ? (
								row?.outletDiscountGLAcct?.type
							) : state?.mode === 'save' && row?.outletDiscountGLAcct?.type === 'GL' ? row?.outletDiscountGLAcct?.type
								:
								(
									<TextField
										id="outlined-basic"
										value={discAndPayment?.[i]?.outletDiscountGLAcct?.type}
										placeholder={discAndPayment?.[i]?.outletDiscountGLAcct?.type}
										className="mapping-textfield"
										variant="outlined"
										disabled={discAndPayment?.[i]?.outletDiscountGLAcct?.type === "GL"}
										onChange={event => {
											const newArr: any = state?.charges;
											let selected = newArr[i + majorAndTax?.length]?.outletDiscountGLAcct;
											selected.type = event?.target?.value;

											dispatch({
												type: 'charges',
												payload: newArr,
											});
										}}
									/>
								) : null}
						{row?.descriptionType === 'Rounding' ?
							state?.mode === 'edit' ? (
								row?.outletRoundingGLAccount?.type
							) : state?.mode === 'save' && row?.outletRoundingGLAccount?.type === 'GL' ? row?.outletRoundingGLAccount?.type
								:
								(
									<TextField
										id="outlined-basic"
										value={discAndPayment?.[i]?.outletRoundingGLAccount?.type}
										placeholder={discAndPayment?.[i]?.outletRoundingGLAccount?.type}
										className="mapping-textfield"
										variant="outlined"
										disabled={discAndPayment?.[i]?.outletRoundingGLAccount?.type === "GL"}
										onChange={event => {
											const newArr: any = state?.charges;
											let selected = newArr[i + majorAndTax?.length]?.outletRoundingGLAccount;
											selected.type = event?.target?.value;

											dispatch({
												type: 'charges',
												payload: newArr,
											});
										}}
									/>
								) : null}

					</TableCell>
					<TableCell align="left">
						{row?.descriptionType === 'PaymentType' ?
							state?.mode === 'edit' ? (
								row?.outletGLAccount?.revenueDebit ||
								renderProperCase(
									renderDebitCreditDefaultValue(row?.description),
									row?.descriptionType,
								)
							) : state?.mode === 'save' ? (
								<TextField
									id="outlined-basic"
									value={discAndPayment?.[i]?.outletGLAccount?.revenueDebit}
									placeholder={renderProperCase(
										renderDebitCreditDefaultValue(row?.description),
										row?.descriptionType,
									)}
									className="mapping-textfield"
									variant="outlined"
									onChange={event => {
										const newArr: any = state?.charges;
										let selected = newArr[i + majorAndTax?.length].outletGLAccount;
										selected.revenueDebit = event?.target?.value;

										dispatch({
											type: 'charges',
											payload: newArr,
										});
									}}
								/>
							) : null
							: null}
						{row?.descriptionType === 'DiscountType' || row?.descriptionType === 'DiscountTaxType' || row?.descriptionType === 'DiscountServiceChargeType' ?
							state?.mode === 'edit' ? (
								row?.outletDiscountGLAcct?.revenueDebit ||
								renderProperCase(
									renderDebitCreditDefaultValue(row?.description),
									row?.descriptionType,
								)
							) : state?.mode === 'save' ? (
								<TextField
									id="outlined-basic"
									value={discAndPayment?.[i]?.outletDiscountGLAcct?.revenueDebit}
									placeholder={renderProperCase(
										renderDebitCreditDefaultValue(row?.description),
										row?.descriptionType,
									)}
									className="mapping-textfield"
									variant="outlined"
									onChange={event => {
										const newArr: any = state?.charges;
										let selected = newArr[i + majorAndTax?.length].outletDiscountGLAcct;
										selected.revenueDebit = event?.target?.value;

										dispatch({
											type: 'charges',
											payload: newArr,
										});
									}}
								/>
							) : null
							: null}
						{row?.descriptionType === 'Rounding' ?
							state?.mode === 'edit' ? (
								row?.outletRoundingGLAccount?.revenueDebit ||
								renderProperCase(
									renderDebitCreditDefaultValue(row?.description),
									row?.descriptionType,
								)
							) : state?.mode === 'save' ? (
								<TextField
									id="outlined-basic"
									value={discAndPayment?.[i]?.outletRoundingGLAccount?.revenueDebit}
									placeholder={renderProperCase(
										renderDebitCreditDefaultValue(row?.description),
										row?.descriptionType,
									)}
									className="mapping-textfield"
									variant="outlined"
									onChange={event => {
										const newArr: any = state?.charges;
										let selected = newArr[i + majorAndTax?.length].outletRoundingGLAccount;
										selected.revenueDebit = event?.target?.value;

										dispatch({
											type: 'charges',
											payload: newArr,
										});
									}}
								/>
							) : null
							: null}
					</TableCell>
					<TableCell
						align="left"
						style={{
							backgroundColor: '#f5f5f5',
						}}
					></TableCell>
				</TableRow>
			))}
		</>
	);
};
