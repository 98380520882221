import React from 'react';
import appointedOfficerRoutes from './AppointedOfficer/AppointedOfficerRoutes';
import authorisedStaffRoutes from './AuthorisedStaff/AuthorisedStaffRoutes';
import { GeneralSettingSubmenu } from './GeneralSettingSubmenu';
import roundingPolicyRoutes from './RoundingPolicy/RoundingPolicyRoutes';
import uomRoutes from './UOM/UOMRoutes';
import userAccessRoutes from './UserAccess/UserAccessRoutes';

const generalSettingRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting`,
		},
		component: <GeneralSettingSubmenu />,
	},
	...userAccessRoutes,
	...appointedOfficerRoutes,
	...authorisedStaffRoutes,
	...roundingPolicyRoutes,
	...uomRoutes,
];

export default generalSettingRoutes;
