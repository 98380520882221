import React from 'react';
import { PromotionalMenuForm } from './PromotionalMenuForm';
import { PromotionalMenuList } from './PromotionalMenuList';
import { PromotionalMenuPricingForm } from './PromotionalMenuPricingForm';
import { PromotionalMenuPricingList } from './PromotionalMenuPricingList';

const promotionalMenuPricingRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/promotional-menu`,
		},
		component: <PromotionalMenuList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/promotional-menu/add`,
		},
		component: <PromotionalMenuForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/promotional-menu/:promotionalMenuID/edit`,
		},
		component: <PromotionalMenuForm mode="edit" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/promotional-menu/:promotionalMenuID/edit/promotional-menu-pricing`,
		},
		component: <PromotionalMenuPricingList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/promotional-menu/:promotionalMenuID/edit/promotional-menu-pricing/add`,
		},
		component: <PromotionalMenuPricingForm mode="add" />,
	},
];

export default promotionalMenuPricingRoutes;
