import { SchemeType } from 'containers/OutletModule/CommonSettings/TaxSetup/TaxSetupListing';
import {
	useGetOutletQuery,
	useGetTaxSchemeDetailLazyQuery,
} from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import React, { useEffect } from 'react';

interface Props {
	outletID;
	taxSchemeID;
	orderID;
	currencyCode;
	type;
	taxSchemeInfo;
	taxSchemeFooterInfo;
	takeaway;
}

export const TaxSchemeComponent = (props: Props) => {
	const {
		outletID,
		taxSchemeID,
		orderID,
		currencyCode,
		type,
		taxSchemeInfo,
		taxSchemeFooterInfo,
		takeaway,
	} = props;

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const [
		defaultTax,
		{
			data: { getTaxSchemeDetail } = {
				getTaxSchemeDetail: null,
			},
		},
	] = useGetTaxSchemeDetailLazyQuery({
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (getOutlet?.length > 0 && orderID !== undefined) {
			if (taxSchemeID === null) {
				defaultTax({
					variables: {
						accountID: user?.accountID,
						taxSchemeID: getOutlet[0]?.taxSchemeID,
						orderID,
					},
				});
			} else {
				defaultTax({
					variables: {
						accountID: user?.accountID,
						taxSchemeID: taxSchemeID,
						orderID,
					},
				});
			}
		}
	}, [getOutlet, orderID]);

	// console.log('taxSchemeFooterInfo', taxSchemeFooterInfo);

	const taxAmount = (taxType, taxRate) => {
		const tax = taxSchemeFooterInfo
			?.filter(x => x?.name === taxType)
			?.filter(v => parseInt(v?.rate) === taxRate)[0];

		return tax?.sum;
	};

	const taxTypeDisplay = taxType => {
		let display;
		if (taxType === 'ServiceCharge') {
			display = 'Service Charge';
		} else {
			display = taxType;
		}

		return display;
	};

	const sortedTaxScheme = getTaxSchemeDetail?.sort((a, b) => {
		if (a.taxType < b.taxType) return -1;
		if (a.taxType > b.taxType) return 1;

		if (a.taxRate < b.taxRate) return -1;
		if (a.taxRate > b.taxRate) return 1;

		return 0;
	});

	return (
		<>
			{sortedTaxScheme?.map((x, index) => {
				return (
					<>
						{type === 'detail' ? (
							takeaway ? (
								<div
									key={index}
									className="m-t-10"
									style={{ display: 'flex', width: '100%' }}
								>
									{x?.taxType !== 'ServiceCharge' ? (
										<>
											<span className="mdDesc flex-space">
												{taxTypeDisplay(x?.taxType)} ({x?.taxRate}%)
											</span>
											<span className="mdDesc">{`${currencyCode}${amtStr(
												Number(taxAmount(x?.taxType, x?.taxRate)),
											)}`}</span>
										</>
									) : null}
								</div>
							) : (
								<div
									key={index}
									className="m-t-10"
									style={{ display: 'flex', width: '100%' }}
								>
									<span className="mdDesc flex-space">
										{taxTypeDisplay(x?.taxType)} ({x?.taxRate}%)
									</span>
									<span className="mdDesc">{`${currencyCode}${amtStr(
										Number(taxAmount(x?.taxType, x?.taxRate)),
									)}`}</span>
								</div>
							)
						) : type === 'receipt' ? (
							<div
								key={index}
								className="m-t-10"
								style={{ display: 'flex', width: '100%' }}
							>
								<span
									className="flex-space mdDesc "
									style={{
										alignSelf: 'center',
										fontSize: '16px',
										color: '#212121',
									}}
								>
									{taxTypeDisplay(x?.taxType)} ({x?.taxRate}%)
								</span>
								<span
									className="xsTitle rightText"
									style={{ fontSize: '16px', color: '#212121' }}
								>{`${currencyCode}${amtStr(
									Number(taxAmount(x?.taxType, x?.taxRate)),
								)}`}</span>
							</div>
						) : null}
					</>
				);
			})}
		</>
	);
};
