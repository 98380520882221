import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Card,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Typography,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetMenuItemDocument,
	useDeleteMenuItemMutation,
	useGetMenuItemQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { NoImage } from '../../../../assets';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
import './menuItemImage.scss';

export const MenuItemList = (props: any) => {
	const { outletID } = useParams<any>();

	const history = useHistory();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const [openDeleteDialog, setDeleteDialog] = useState(false);

	const [rerender, setRender] = useState(false);

	const menuItemSearch = JSON.parse(localStorage.getItem('searchFilter'));

	const {
		data: { getMenuItem } = { getMenuItem: [] },
		loading: menuItemLoading,
	} = useGetMenuItemQuery({
		fetchPolicy: 'network-only',
		variables: {
			isSetMenu: false,
			outletID: outletID,
			orderByAsc: 'name',
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const [
		deleteMenuItem,
		{ loading: deleteMenuItemLoading },
	] = useDeleteMenuItemMutation({
		onError: error => {
			console.log('ERROR', error);
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	useEffect(() => {
		if (getMenuItem && getMenuItem?.length > 0) {
			setOriginalListing(getMenuItem);
		}
	}, [getMenuItem]);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	useEffect(() => {
		if (
			!menuItemLoading &&
			!!menuItemSearch?.menuItemSearch &&
			getMenuItem?.length > 0
		) {
			handleSearch(`'"${menuItemSearch?.menuItemSearch ?? ''}"`, ['name']);
			setRender(true);
		}
	}, [menuItemLoading, rerender, getMenuItem]);

	return (
		<>
			{menuItemLoading && <Loading />}
			{outletLoading && <Loading />}
			{deleteMenuItemLoading && <Loading />}

			<MainHeader
				onClick={() => {
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							outletList: menuItemSearch?.outletList ?? '',
							outletSubmenu: menuItemSearch?.outletSubmenu ?? '',
							menuItemSearch: '',
						}),
					);
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					});
				}}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Menu Items', current: true },
				]}
			/>

			<SearchHeader
				title="Menu Items"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['name']);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							outletList: menuItemSearch?.outletList ?? '',
							outletSubmenu: menuItemSearch?.outletSubmenu ?? '',
							menuItemSearch: e?.target?.value,
						}),
					);
				}}
				fixed
				isDefaultValue={!!menuItemSearch?.menuItemSearch ?? false}
				defaultValue={menuItemSearch?.menuItemSearch ?? ''}
				search
				onCloseAction={() => {
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							outletList: menuItemSearch?.outletList ?? '',
							outletSubmenu: menuItemSearch?.outletSubmenu ?? '',
							menuItemSearch: '',
						}),
					);
					handleSearch('', []);
				}}
				clickText
			/>
			<ContentWrapper float search>
				<List className="core-list">
					{!menuItemLoading &&
					!outletLoading &&
					(getMenuItem === undefined || getMenuItem?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Add a new record now."
						/>
					) : (
						filteredList?.map((el, index) => (
							<ListItem>
								{/* <div style={{ paddingRight: '6px' }}>
									<img
										src={el?.attachments?.fileURL}
										className="img-owned"
										loading="lazy"
									/>
								</div> */}

								<ListItemIcon>
									{el?.attachments?.fileURL ? (
										<img className="img-owned" src={el?.attachments?.fileURL} />
									) : (
										<img
											className="img-owned"
											style={{
												border: 'none',
											}}
											src={NoImage}
										/>
									)}
								</ListItemIcon>
								<ListItemText
									primary={
										<>
											<span className="xsTitle flex-space">{el?.name}</span>
											<span className="xxTitle highlight-text text-separator"></span>

											<span className="xxTitle highlight-text text-separator">
												{el.isActive ? (
													<Grid
														component={Card}
														style={{
															background: '#ff9800',
															padding: '.2rem .5rem .2rem .5rem ',
															boxShadow: 'none',
														}}
													>
														<Typography
															variant="caption"
															style={{
																fontSize: '10px',
																color: 'white',
															}}
														>
															Active
														</Typography>
													</Grid>
												) : null}
											</span>
										</>
									}
									secondary={
										<>
											<span className="desc">
												<span className="mdDesc fw-medium">
													{el?.nativeName}
													<br></br>
												</span>
												<span className="desc">{el?.description}</span>
											</span>
										</>
									}
								/>

								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="more"
										aria-controls="menu-list"
										aria-haspopup="true"
										onClick={e => handleClick(e, el.ID, index, el)}
									>
										<MoreVert />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() =>
							history.push({
								pathname: `/menu/outlet-app/outlet-setting/${outletID}/menu-item/${menu?.ID}/edit`,
								state: {
									menuItem: menu?.obj,
									menuOptionItem: menu?.obj?.menuItemOptions,
								},
							})
						}
					>
						<span className="">Edit</span>
					</MenuItem>

					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID, isSetMenu: false, orderByAsc: 'name' }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteMenuItem}
				refetchDocument={GetMenuItemDocument}
			/>

			<FloatButton
				onClick={() =>
					history.push(
						`/menu/outlet-app/outlet-setting/${outletID}/menu-item/add`,
					)
				}
			/>
		</>
	);
};
