import React from 'react';
import { OutletProfileDetail } from './OutletProfileDetail';
import { OutletProfileForm } from './OutletProfileForm';

const outletProfileRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/outlet-profile/add`,
		},
		component: <OutletProfileForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/outlet-profile/edit`,
		},
		component: <OutletProfileForm mode="edit" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/outlet-profile`,
		},
		component: <OutletProfileDetail />,
	},
];

export default outletProfileRoutes;
