import IconButton from '@material-ui/core/IconButton'
import ArrowDropDownOutlinedIcon from '@material-ui/icons/ArrowDropDownOutlined'
import React, { ReactNode, useContext } from 'react'
import theme from '@ifca-root/react-component/src/assets/theme'
import AppContext from 'containers/App/Store/AppContext'
import { useMediaQuery } from '@material-ui/core'
interface subHeaderProps {
  title?: any
  rightText?: any
  infoLine?: any
  dropDownOptions?: any
  subInfoLine?: ReactNode
  handleClick?: any
  avatar?: ReactNode
  noMainheader?: boolean
  style?: any
}
const GLJournalSubHeader = ({
  infoLine,
  rightText,
  title,
  dropDownOptions,
  handleClick,
  subInfoLine,
  avatar,
  noMainheader,
  style,
}: subHeaderProps) => {
  const { globalState, dispatch } = useContext(AppContext as any)
  const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
    defaultMatches: true,
  })
  return (
    <div
      id="dynamic-subheader"
      className={`${
        noMainheader ? 'dynamic-subheader-noMainheader' : 'dynamic-subheader'
      } ${infoLine ? 'multiple' : 'single'} ${avatar ? 'avatar' : null}`}
      style={{
        height: 95,
        width:
          isDesktop && globalState.drawerOpen
            ? 'calc(100% - 288px)'
            : isDesktop && !globalState.drawerOpen
            ? 'calc(100% - 48px)'
            : '100%',
        // width: `calc(100% - 48px)`,
      }}
    >
      {avatar && <div className="avatar-content">{avatar}</div>}

      <div id="subheader-content" style={{ width: '100%' }}>
        <div className="dynamic-content" onClick={handleClick}>
          <div className="session">
            <div className="title flex-space">{title}</div>
            {rightText && <div className="rightText">{rightText}</div>}
            {dropDownOptions ? (
              <IconButton className="right-btn">
                <ArrowDropDownOutlinedIcon />
              </IconButton>
            ) : null}
          </div>
        </div>
        <div
          className="infoline-content"
          style={{ display: subInfoLine ? 'block' : 'flex' }}
        >
          <div className="infoline">{infoLine}</div>
          {subInfoLine && <div className="infoline m-t-2">{subInfoLine}</div>}
        </div>
      </div>

      {dropDownOptions}
    </div>
  )
}

export default GLJournalSubHeader
