import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { List } from '@material-ui/core';
import React from 'react';

export const AuditDialog = props => {
	const {
		openAuditDialog,
		setOpenAuditDialog,
		auditArray,
		listItemDisplayFn,
		currencyCode,
	} = props;

	return (
		<>
			<CommonDialog
				open={openAuditDialog}
				fullWidth
				onClose={() => {
					setOpenAuditDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<>
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div
												className="title highlight-text flex-space"
												style={{ color: '#ff9800' }}
											>
												{'History'}
											</div>
										</div>
									</div>
								</div>
							</>
						),
					},
					body: () => (
						<>
							{auditArray?.length === 0 ? (
								<EmptyList title="No item movement history found" />
							) : (
								<List className="core-list">
									{auditArray?.map((el, index) => {
										return listItemDisplayFn(el, index);
									})}
								</List>
							)}
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => {
										setOpenAuditDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
