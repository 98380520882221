import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import OrangeDollarIcon from 'assets/icons/Money/dollar-orange.svg';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';
import { TaxSchemeComponent } from './TaxSchemeComponent';

interface BillFooterProps {
	total?: number;
	discount?: any;
	subtotal?: number;
	tax?: number;
	serviceCharge?: number;
	totalAmount?: number;
	style: React.CSSProperties;
	isConfirmOrder?: boolean;
	highlightText?: boolean;
	currencyCode?: string;
	rounding?: number;
	outletID?: string;
	taxSchemeInfo?: any;
	taxSchemeFooterInfo?: any;
	takeaway?: boolean;
	orderID?: string;
	page?:
		| 'bill-item-discount' // done
		| 'bill-settlement' // done
		| 'confirm-order' // done
		| 'bill-listing-detail' // done
		| 'item-transfer-dialog' // done
		| 'kitchen-home'; // no need not the same
}

export const BillFooter = (props: BillFooterProps) => {
	const {
		total,
		discount,
		subtotal,
		serviceCharge,
		tax,
		totalAmount,
		style,
		isConfirmOrder,
		highlightText,
		currencyCode = '',
		rounding,
		outletID,
		taxSchemeInfo,
		taxSchemeFooterInfo,
		takeaway,
		page,
		orderID,
	} = props;
	const divChild = (info1: any, info2: any) => {
		return (
			<div
				style={isConfirmOrder ? { paddingBottom: '5px' } : null}
				className={isConfirmOrder ? 'mdDesc' : 'mdDesc bill-ftr-child'}
			>
				<span>{info1}</span>
				<span className="right-info">{info2}</span>
			</div>
		);
	};

	return (
		<>
			<div
				className={isConfirmOrder ? null : 'bill-footer'}
				style={{ ...style }}
			>
				{divChild('Sub-Total', currencyCode + amtStr(subtotal))}
				{divChild('Discount', `(${currencyCode}${amtStr(discount)})`)}
				{page === 'bill-listing-detail' || page === 'bill-settlement'
					? divChild(
							'Rounding',
							rounding < 0
								? `(${currencyCode} ${Math.abs(rounding).toFixed(2)})`
								: currencyCode + amtStr(rounding),
					  )
					: ''}
				<div
					className={
						{ highlightText }
							? 'smTitle bill-total'
							: 'smTitle bill-total highlightText'
					}
				>
					{highlightText ? (
						<>
							<div style={{ display: 'flex', flex: 1 }}>
								<div className="flex-space">
									<span>Total</span>
								</div>
								<div className="right-info highlight-text">
									{/* <IconText
										icon={
											<img
												src={OrangeDollarIcon}
												style={{ width: '17px', paddingRight: '2px' }}
											/>
										}
									> */}
									{`${currencyCode}${amtStr(total)}`}
									{/* </IconText> */}
								</div>
							</div>
						</>
					) : (
						<>
							<span>Total</span>
							<span className="right-info">{currencyCode + amtStr(total)}</span>
						</>
					)}
				</div>
				<hr className="line-bill" />
				<div
					className={
						{ highlightText }
							? 'smTitle bill-total'
							: 'smTitle bill-total highlightText'
					}
				>
					{/* {divChild('Service Charge', currencyCode + amtStr(serviceCharge))}
					{divChild('Tax', currencyCode + amtStr(tax))} */}
					<TaxSchemeComponent
						outletID={outletID}
						taxSchemeID={null}
						currencyCode={currencyCode}
						type={'detail'}
						taxSchemeInfo={taxSchemeInfo}
						taxSchemeFooterInfo={taxSchemeFooterInfo}
						takeaway={takeaway}
						orderID={orderID}
					/>
				</div>
			</div>
		</>
	);
};
