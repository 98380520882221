import { Button } from '@material-ui/core'
import React from 'react'

interface CustomFooterProps {
  option: OptionProp
  label: string
  value: any
  style?: React.CSSProperties
}

interface OptionProp {
  name: string
  color: 'primary' | 'secondary' | 'default' | 'inherit'
  onClick?: any
  disabled?: boolean
}

export const FooterWithTotal = (props: CustomFooterProps) => {
  const { option, label, value, style } = props

  return (
    <>
      <div className="footer" style={{ ...style, paddingBottom: '10px' }}>
        <div style={{ float: 'left', marginTop: '6px', marginLeft: '15px' }}>
          <span
            className="footer-order-label xsTitle"
            style={{ padding: '6px 0px' }}
          >
            {label}
          </span>
          <span
            className="footer-order-label"
            style={{
              width: '100%',
              color: '#ff9800',
              fontSize: '20px',
              fontWeight: 'bold',
              marginTop: '-15px',
              padding: '6px 0px',
              // marginBottom: '5px',
            }}
          >
            {value}
          </span>
        </div>
        {option && (
          <div className="footer-order-btn">
            <Button
              type="submit"
              color={option.color}
              style={{ minWidth: '126px', backgroundColor: '#ff9800' }}
              variant="contained"
              onClick={option.onClick}
              disabled={option.disabled}
            >
              <span className="smTitle">{option.name}</span>
            </Button>
          </div>
        )}
        <div>
          <span
            className="desc"
            style={{
              width: '100%',
              position: 'absolute',
              bottom: '5px',
              paddingLeft: '16px',
              textAlign: 'left',
              left: '0px',
            }}
          >
            Subject to tax and service charge
          </span>
        </div>
      </div>
    </>
  )
}
