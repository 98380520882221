import {
	Button,
	Card,
	CardContent,
	CardHeader,
	IconButton,
	IconButtonProps,
	Box,
} from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import React from 'react';
import './kitchen.scss';

interface ICardAddOnProps {
	children: any;
	style?: Record<string, string>;
	contentStyle?: Record<string, string>;
	section?: {
		header?: {
			title?: string;
			onClickAction?: (event: React.MouseEvent<HTMLButtonElement>) => void;
			icon?: any;
			disabled?: boolean;
		};
		footer?: {
			onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
			label?: string;
		};
	};
}

export function CardContentsKitchen(props: ICardAddOnProps) {
	const { children, section, style, contentStyle } = props;

	return (
		<>
			<Box className="custom-card-form" style={style}>
				<Card>
					{section?.header && (
						<CardHeader
							disableTypography
							title={
								<span className="smTitle c-darkblue">
									{section?.header?.title}{' '}
								</span>
							}
							action={
								<IconButton
									disabled={section?.header?.disabled}
									className="card-header-btn"
									aria-label="edit"
									onClick={section?.header?.onClickAction}
								>
									{section?.header?.icon}
								</IconButton>
							}
						/>
					)}

					<CardContent style={contentStyle}>{children}</CardContent>
				</Card>
			</Box>
		</>
	);
}
export default CardContentsKitchen;
