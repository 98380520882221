import React from 'react';
import { MenuSchedulingForm } from './MenuSchedulingForm';
import { MenuSchedulingList } from './MenuSchedulingList';

const menuSchedulingRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/menu-scheduling`,
		},
		component: <MenuSchedulingList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/menu-scheduling/add`,
		},
		component: <MenuSchedulingForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/menu-scheduling/:menuScheduleID/edit`,
		},
		component: <MenuSchedulingForm mode="edit" />,
	},
];

export default menuSchedulingRoutes;
