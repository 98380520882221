import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { makeStyles, TextField, useMediaQuery } from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetMealPeriodDocument,
	useCreateMealPeriodMutation,
	useGetMealPeriodQuery,
	useGetOutletQuery,
	useUpdateMealPeriodMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import * as Moment from 'moment';
import { extendMoment } from 'moment-range';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

const moment = extendMoment(Moment);

interface MealPeriodProps {
	name: string;
	startTime: Date;
	endTime: Date;
	validFrom: Date;
	validTill: Date;
}

export const MealPeriodForm = (props: any) => {
	const history = useHistory();
	const location = useLocation();

	const { mode } = props;
	const { outletID }: any = useParams();
	const editData = location?.state as any;

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { globalState, dispatch } = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const {
		data: { getMealPeriod } = { getMealPeriod: [] },
		called: mealCalled,
		loading: mealLoading,
	} = useGetMealPeriodQuery({ variables: { outletID } });

	const [
		createMealPeriodFn,
		{
			data: { createMealPeriod } = { createMealPeriod: {} as any },
			loading: createMealPeriodLoading,
			called: createdMealPeriodCalled,
		},
	] = useCreateMealPeriodMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			// loadAllMealPeriod();
			// setTimeout(() => {
			history.push({
				pathname: `/menu/outlet-app/outlet-setting/${outletID}/meal-period`,
				state: { success: true, msgMode: 'create' },
			});
			// }, 500);
		},
	});

	const [
		updateMealPeriodFn,
		{
			loading: updateMealPeriodLoading,
			error: subUpdateError,
			called: subUpdateCalled,
			data: { updateMealPeriod } = { updateMealPeriod: {} as any },
		},
	] = useUpdateMealPeriodMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: ({ updateMealPeriod }) => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			// loadAllMealPeriod();
			history.push({
				pathname: `/menu/outlet-app/outlet-setting/${outletID}/meal-period`,
				state: { success: true, msgMode: 'update' },
			});
		},
	});

	// const currTs = new Date();
	// const currTimeInt = currTs.getHours() * 60 + currTs.getMinutes();

	// const [
	// 	loadAllMealPeriod,
	// 	{
	// 		called: allMealPeriodCalled,
	// 		loading: allMealPeriodLoading,
	// 		data: { getAllMealPeriod } = { getAllMealPeriod: [] },
	// 	},
	// ] = useGetAllMealPeriodLazyQuery({
	// 	fetchPolicy: 'network-only',
	// 	onError: error => {
	// 		console.log('ERROR', error);
	// 	},
	// 	onCompleted: ({ getAllMealPeriod }) => {
	// 		localStorage.setItem('allMealPeriod', JSON.stringify(getAllMealPeriod));
	// 		var outletIDs = Object.keys(getAllMealPeriod);
	// 		let currentMealPeriod = {};
	// 		outletIDs?.map(outletID => {
	// 			let currMealPeriod = getAllMealPeriod[outletID]?.filter(mp => {
	// 				const formattedExMp = {
	// 					startTime:
	// 						Number(mp?.startTime.split(':')[0]) * 60 +
	// 						Number(mp?.startTime.split(':')[1]),
	// 					endTime:
	// 						Number(mp?.endTime.split(':')[0]) * 60 +
	// 						Number(mp?.endTime.split(':')[1]),
	// 				};
	// 				//find if existing mp overlap with current one
	// 				if (getTimeRange(formattedExMp).includes(currTimeInt)) {
	// 					return mp;
	// 				}
	// 				// mealPeriod =>
	// 				// 	mealPeriod.startTime < currTime && mealPeriod.endTime > currTime,
	// 			});
	// 			currentMealPeriod[outletID] = currMealPeriod;
	// 		});
	// 		localStorage.setItem(
	// 			'currentMealPeriod',
	// 			JSON.stringify(currentMealPeriod),
	// 		);

	// 		setTimeout(() => {
	// 			history.push({
	// 				pathname: `/menu/outlet-app/outlet-setting/${outletID}/meal-period`,
	// 				state: { success: true, msgMode: 'update' },
	// 			});
	// 		}, 500);
	// 	},
	// });

	const createUpdateCalled = editData
		? subUpdateCalled
		: createdMealPeriodCalled;

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const existingMealPeriodName = getMealPeriod?.map(m => m?.name);
	const existingMealPeriodTime = getMealPeriod
		?.filter(m => m?.ID !== editData?.ID)
		?.map(m => {
			return {
				startTime: m?.startTime,
				endTime: m?.endTime,
			};
		});

	const allRange = [];
	for (var i = 0; i <= 1440; i++) {
		allRange.push(i);
	}

	const getTimeRange = range => {
		if (range.startTime < range.endTime) {
			let timeRange = [];
			for (var i = range.startTime; i <= range.endTime; i++) {
				timeRange.push(i);
			}
			return timeRange;
		} else {
			let timeRange = [];
			for (var i = range.endTime + 1; i <= range.startTime; i++) {
				timeRange.push(i);
			}
			return allRange.filter(val => !timeRange.includes(val));
		}
	};

	const overlapTime = (startTime, endTime) => {
		const formattedStartTime =
			startTime?.length === 5 ? startTime + ':00' : startTime; //08:00:00
		const formattedEndTime = endTime?.length === 5 ? endTime + ':00' : endTime; //13:00:00
		const formattedMp = {
			startTime:
				Number(formattedStartTime.split(':')[0]) * 60 +
				Number(formattedStartTime.split(':')[1]), //540
			endTime:
				Number(formattedEndTime.split(':')[0]) * 60 +
				Number(formattedEndTime.split(':')[1]), //840
		};
		if (
			existingMealPeriodTime?.filter(mp => {
				const formattedExMp = {
					startTime:
						Number(mp?.startTime.split(':')[0]) * 60 +
						Number(mp?.startTime.split(':')[1]),
					endTime:
						Number(mp?.endTime.split(':')[0]) * 60 +
						Number(mp?.endTime.split(':')[1]),
				};
				//find if existing mp overlap with current one
				if (
					getTimeRange(formattedMp).filter(val => {
						return getTimeRange(formattedExMp).includes(val);
					}).length > 0
				) {
					return mp;
				}
			}).length > 0
		) {
			return true;
		} else {
			return false;
		}
	};
	const [openExitDialog, setOpenExitDialog] = useState(false);

	const yupSchema = yup.object().shape({
		name: yup
			.string()
			.required(SystemMsgs.title())
			.trim()
			.test('name', 'Meal period name already exist', value => {
				if (
					(watch('name') !== editData?.name && mode === 'edit') ||
					mode === 'add'
				) {
					return !existingMealPeriodName?.includes(value);
				}
				return true;
			}),
		startTime: yup
			.string()
			.required(SystemMsgs.startTime())
			.test('same-time', 'Start time cannot be the same as end time', value => {
				// if (
				// 	(watch('startTime') !== editData?.startTime && mode === 'edit') ||
				// 	mode === 'add'
				// ) {
				return watch('endTime') !== watch('startTime');
				// }
			})
			.test(
				'overlap-starttime',
				'Meal period overlaps with other meal periods',
				value => {
					return !overlapTime(watch('startTime'), watch('endTime'));
				},
			),
		endTime: yup
			.string()
			.required(SystemMsgs.endTime())
			.test(
				'same-time',
				'End time cannot be the same as start time',
				value => {
					// if (
					// 	(watch('endTime') !== editData?.endTime && mode === 'edit') ||
					// 	mode === 'add'
					// ) {
					return watch('endTime') !== watch('startTime');
				},
				// },
			)
			.test(
				'overlap-endtime',
				'Meal period overlaps with other meal periods',
				value => {
					return !overlapTime(watch('startTime'), watch('endTime'));
				},
			),
	});

	const {
		register,
		control,
		errors,
		handleSubmit,
		watch,
		clearErrors,
		formState,
	} = useForm<MealPeriodProps>({
		defaultValues: {
			name: mode === 'add' ? '' : editData?.name,
			startTime: mode === 'add' ? '' : editData?.startTime?.slice(0, -3),
			endTime: mode === 'add' ? '' : editData?.endTime?.slice(0, -3),
		},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const onSubmit = (data: any) => {
		if (mode === 'add') {
			createMealPeriodFn({
				variables: {
					input: {
						outletID: outletID,
						name: data?.name,
						startTime: data?.startTime,
						endTime: data?.endTime,
					},
				},
				refetchQueries: [
					{ query: GetMealPeriodDocument, variables: { outletID } },
				],
			});
		} else if (mode === 'edit') {
			updateMealPeriodFn({
				variables: {
					input: {
						ID: editData?.ID,
						name: data?.name,
						startTime: data?.startTime,
						endTime: data?.endTime,
					},
				},
				refetchQueries: [
					{ query: GetMealPeriodDocument, variables: { outletID } },
				],
			});
		}
	};

	const useStyles = makeStyles({
		root: {
			['& *']: {
				boxSizing: 'border-box',
			},
		},
		form: {
			['& > *']: {
				marginBottom: '0.5rem',
			},
		},
		halfWidths: {
			display: 'flex',
			flexDirection: 'row',
			flexWrap: 'wrap',

			['& > *']: {
				flex: '0 0 auto',
				width: '50%',
				marginBottom: '0.5rem',
			},
		},
	});
	const classes = useStyles();

	return (
		<>
			{createMealPeriodLoading && <Loading />}
			{updateMealPeriodLoading && <Loading />}
			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Meal Period', current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>
			<ContentWrapper footer>
				<CardContents devFullWidth={true}>
					{/* <form id="submit-form"> */}
					<Controller
						as={TextField}
						name="name"
						label="Title"
						autoComplete="off"
						multiline={true}
						// required
						fullWidth
						ref={register}
						control={control}
						margin="normal"
						required
						helperText={errors?.name?.message}
						error={errors?.name ? true : false}
					></Controller>
					<Controller
						as={TextField}
						name="startTime"
						label="Start Time"
						type="time" //defaultValue="14:00"
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300, // 5 min
						}}
						required
						className="left"
						fullWidth
						ref={register}
						control={control}
						margin="normal"
						autoComplete="off"
						helperText={errors?.startTime?.message}
						error={errors?.startTime ? true : false}
						onChange={() => clearErrors('startTime')}
					></Controller>
					<Controller
						as={TextField}
						name="endTime"
						label="End Time"
						type="time" //defaultValue="14:00"
						InputLabelProps={{
							shrink: true,
						}}
						inputProps={{
							step: 300, // 5 min
						}}
						className="right"
						fullWidth
						ref={register}
						control={control}
						margin="normal"
						autoComplete="off"
						required
						helperText={errors?.endTime?.message}
						error={errors?.endTime ? true : false}
						onChange={() => clearErrors('endTime')}
					></Controller>
					{/* </form> */}
				</CardContents>
			</ContentWrapper>
			<DynamicFooter
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				options={[
					{
						name: 'Save',
						// onClick: () => {
						// 	handleSubmit(onSubmit)();
						// },
						color: 'primary',
						onClick: () => {
							handleSubmit(data => onSubmit(data))();
						},
						disabled: formState.isSubmitted,
					},
				]}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/outlet-setting/${outletID}/meal-period`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push({
											pathname: `/menu/outlet-app/outlet-setting/${outletID}/meal-period`,
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
