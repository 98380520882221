import { Button, IconButton, Snackbar } from '@material-ui/core';
import { FormatColorFill } from '@material-ui/icons';
import { menuxSocketUrl } from 'POSClient';
import React, { useContext, useEffect, useState } from 'react';
import io from 'socket.io-client';
import CloseIcon from '@material-ui/icons/Close';
import { StandardDialog } from '@ifca-root/react-component/src/components/Dialog/StandardDialog';
import { SocketContext } from 'containers/Socket/SocketContext';
export const ADEWrapper = ({ children }) => {
	const [open, setOpen] = useState(false);
	const [message, setMessage] = useState('');
	const [notification, setNotification] = useState('');
	const [snckcolor, setcolor] = useState('');
	// const [socket, setSocket] = useState(null);
	const [color, setColor] = useState('');
	const [snackbarClosed, setsnackbarClosed] = useState(false);
	const [messages, setMessages] = useState('');
	const [ConfirmationDialog, setConfirmationDialog] = useState(false);
	const [messageColor, setMessageColor] = useState('black');

	const { socket }: any = useContext(SocketContext);

	// useEffect(() => {
	// 	const newSocket = io(menuxSocketUrl);
	// 	setSocket(newSocket);

	// Cleanup function
	// 	return () => {
	// 		newSocket.disconnect();
	// 	};
	// }, []);

	//get notification
	useEffect(() => {
		if (socket) {
			socket.on('notification', notification => {
				setNotification(() => notification.status);
				console.log(notification, 'notification');
			});

			return () => {
				if (socket) {
					socket.off('message');
				}
			};
		}
	}, [socket]);

	// useEffect(() => {
	// 	if (notification === 'COMPLETED') {
	// 		setConfirmationDialog(true);
	// 		setMessages('Auto Day End is COMPLETED');
	// 		setMessageColor('#4cd038');
	// 	} else if (notification === 'PENDING') {
	// 		setConfirmationDialog(true);
	// 		setMessages('Auto Day End is IN PROGRESS');
	// 		setMessageColor('#FF0000');
	// 	} else if (notification === 'START') {
	// 		setOpen(true);
	// 		let remainingTime = 30 * 60;
	// 		const countdownInterval = setInterval(() => {
	// 			const minutes = Math.floor(remainingTime / 60);
	// 			const seconds = remainingTime % 60;
	// 			const formattedMinutes = String(minutes).padStart(2, '0');
	// 			const formattedSeconds = String(seconds).padStart(2, '0');
	// 			setMessage(
	// 				`Auto Day End process will start in ${formattedMinutes} minutes time, please prepare for the closing now`,
	// 			);
	// 			remainingTime--;
	// 			if (remainingTime < 0) {
	// 				clearInterval(countdownInterval);
	// 				setMessage('Auto Day End process is starting...');
	// 				// Perform the actual process here
	// 			}
	// 		}, 1000);
	// 	}
	// }, [notification]);

	const handleSnackbarClose = () => {
		setsnackbarClosed(true);
		setOpen(false);
	};
	// console.log(message, 'message nya');
	return (
		<>
			{children}
			{notification === 'START' ? (
				<Snackbar
					className=""
					style={{ top: '10px', right: '10px' }}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'right',
					}}
					open={open}
					autoHideDuration={3000}
					onClose={() => setOpen(true)}
					ContentProps={{
						'aria-describedby': 'message-id',
						style: { backgroundColor: 'white', color: 'red' },
					}}
					message={<span id="message-id">{message}</span>}
					action={
						// Show IconButton with Close icon if notification is START
						<IconButton
							key="close"
							aria-label="close"
							color="inherit"
							onClick={handleSnackbarClose}
						>
							<CloseIcon />
						</IconButton>
					}
				/>
			) : (
				<StandardDialog
					fullWidth={true}
					open={ConfirmationDialog}
					onClose={() => {
						setConfirmationDialog(true);
					}}
					sections={{
						header: {
							title: 'Auto Day End',
						},
						body: () => (
							<div style={{ color: messageColor, fontWeight: 'bold' }}>
								{messages}
							</div>
						), // Add style here
						footer: {
							actions: [
								{
									displayText: 'OK',
									props: {
										onClick: () => {
											setConfirmationDialog(false);
										},
										variant: 'contained',
										color: 'primary',
										disabled: notification === 'PENDING',
									},
								},
							],
						},
					}}
				/>
			)}
		</>
	);
};
