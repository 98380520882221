import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Chip,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
	PosPermission,
	useGetTaxSchemeListingQuery,
	useIsTaxSchemeInUseLazyQuery,
	useTaxSchemeDeleteMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { useSnackBar } from 'helpers/hooks/useSnackBar';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';

export const TaxSchemeList = () => {
	let history = useHistory();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const { outletID } = useParams<any>();

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const { snackFunc, snackBarMessage, openSnackBar, close } = useSnackBar();
	const { handlePermDisabled }: any = usePermissionChecker();

	const [isDelete, setIsDelete] = useState(false);
	const [rerender, setRender] = useState(false);

	const taxSchemeSearch = JSON.parse(localStorage.getItem('searchFilter'));

	const {
		data: { getTaxSchemeListing } = { getTaxSchemeListing: [] },
		loading: taxSchemeListingLoading,
		refetch,
	} = useGetTaxSchemeListingQuery({
		fetchPolicy: 'network-only',
		variables: {
			accountID: user?.accountID,
		},
	});

	const [
		loadIsTaxSchemeInUse,
		{
			data: { isTaxSchemeInUse } = { isTaxSchemeInUse: [] },
			loading: isTaxSchemeInUseLoading,
		},
	] = useIsTaxSchemeInUseLazyQuery();

	const [
		taxSchemeDelete,
		{ loading: taxSchemeDeleteLoading },
	] = useTaxSchemeDeleteMutation({
		fetchPolicy: 'network-only',
	});

	const SuccessDelete = () => {
		snackFunc('Delete Success', false);
		refetch();
	};
	const onDelete = () => {
		taxSchemeDelete({
			variables: {
				TaxSchemeID: menu.obj.id,
				// outletID: JSON.parse(localStorage.getItem('latestOutlet')),
			},
		}).then(i => {
			i.data.taxSchemeDelete === true
				? SuccessDelete()
				: snackFunc('Delete Failed', false);
		});
	};

	useEffect(() => {
		if (getTaxSchemeListing && getTaxSchemeListing?.length > 0) {
			setOriginalListing(getTaxSchemeListing);
		}
	}, [getTaxSchemeListing]);

	useEffect(() => {
		if (
			!taxSchemeListingLoading &&
			getTaxSchemeListing?.length > 0 &&
			taxSchemeSearch?.value
		) {
			handleSearch(`'"${taxSchemeSearch?.value ?? ''}"`, [
				'name',
				'description',
			]);
			setRender(true);
		}
	}, [taxSchemeListingLoading, getTaxSchemeListing, rerender]);

	return (
		<>
			{taxSchemeListingLoading && <Loading />}
			{taxSchemeDeleteLoading && <Loading />}
			{isTaxSchemeInUseLoading && <Loading />}

			<MainHeader
				mainBtn="back"
				onClick={() => {
					history.push(`/menu/outlet-app/common-setting`);
					localStorage.removeItem('searchFilter');
				}}
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: '' },
					{ name: 'Tax Scheme Policy Listing', current: true },
				]}
			/>

			<SearchHeader
				title={`Tax Scheme Policy Listing`}
				value={`${filteredList?.length}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['name', 'description']);
					localStorage.setItem(
						'searchFilter',
						JSON.stringify({ value: e?.target?.value }),
					);
				}}
				isDefaultValue={!!taxSchemeSearch?.value ?? false}
				defaultValue={taxSchemeSearch?.value ?? ''}
				fixed
				search
				onCloseAction={() => {
					handleSearch('', []);
					localStorage.removeItem('searchFilter');
				}}
			/>

			<ContentWrapper search float>
				<List className="core-list">
					{filteredList?.map((el, index) => {
						return (
							<ListItem key={index}>
								<ListItemText
									primary={
										<>
											<span className="xsTitle" style={{ marginRight: '10px' }}>
												{el?.name}
											</span>

											{/* {el?.isDefault === true ? (
												<span
													className="desc"
													style={{ marginRight: '10px', marginBottom: '3px' }}
												>
													<Chip
														size="small"
														label="Default"
														color="primary"
														style={{
															color: 'black',
															fontSize: '10px',
															padding: '0',
															margin: '0',
															backgroundColor: 'rgba(255, 165, 0, 0.5)',
														}}
													/>
												</span>
											) : null} */}

											{el?.isExclusive === false ? (
												<span
													className="desc"
													style={{ marginRight: '10px', marginBottom: '3px' }}
												>
													<Chip
														size="small"
														label="Inclusive"
														color="primary"
														style={{
															color: 'black',
															fontSize: '10px',
															padding: '0',
															margin: '0',
															backgroundColor: 'rgba(255, 165, 0, 0.5)',
														}}
													/>
												</span>
											) : null}
										</>
									}
									secondary={
										<>
											<span className="desc flex-space">{el?.description}</span>
										</>
									}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										onClick={e => {
											handleClick(e, el?.id, index, el);
											loadIsTaxSchemeInUse({
												variables: {
													taxSchemeID: el?.id,
													outletID: outletID,
												},
											});
										}}
									>
										<MoreVert />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}

					<Menu
						id="menu-list"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						<MenuItem
							onClick={() => {
								history.push(
									`/menu/outlet-app/common-setting/tax-scheme-policy/${menu?.ID}/detail`,
									{ state: menu?.obj },
								);
							}}
						>
							View
						</MenuItem>
						<MenuItem
							disabled={handlePermDisabled({
								outletID: outletID,
								permEnum: PosPermission.CommonSettingTaxSchemePolicyUpdate,
							})}
							onClick={() => {
								history.push(
									`/menu/outlet-app/common-setting/tax-scheme-policy/${menu?.ID}/edit`,
									{ state: menu?.obj },
								);
							}}
						>
							Edit
						</MenuItem>
						<MenuItem
							onClick={() => {
								setIsDelete(true);
							}}
							disabled={
								handlePermDisabled({
									outletID: outletID,
									permEnum: PosPermission.CommonSettingTaxSchemePolicyDelete,
								}) ||
								isTaxSchemeInUse === true ||
								taxSchemeDeleteLoading === true
							}
						>
							Delete
						</MenuItem>
						{/* <MenuItem onClick={() => {}}>Default</MenuItem>
						<MenuItem onClick={() => {}}>Active</MenuItem>
						<MenuItem onClick={() => {}}>Inactive</MenuItem> */}
					</Menu>
				</List>
			</ContentWrapper>

			<CommonDialog
				fullWidth={true}
				open={isDelete}
				onClose={() => setIsDelete(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div
										className="session"
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<div className="title flex-space">Tax Scheme</div>
										<div className="title">Delete</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<div className="fs-14 fw-medium flex-space">
							Do you want to delete {menu?.obj?.name}?
						</div>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setIsDelete(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										onDelete();
										setIsDelete(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>

			<FloatButton
				onClick={() => {
					history.push(`/menu/outlet-app/common-setting/tax-scheme-policy/add`);
				}}
				disabled={handlePermDisabled({
					outletID: outletID,
					permEnum: PosPermission.CommonSettingTaxSchemePolicyCreate,
				})}
			/>
		</>
	);
};
