import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import { MainHeader } from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Divider,
	FormControl,
	Grid,
	Input,
	InputAdornment,
	InputLabel,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetPromoMenuForPriceDocument,
	GetRemainingMenuItemsForPromoDocument,
	useDeletePromoMenuItemAndPriceMutation,
	useGetMenuItemQuery,
	useGetOutletQuery,
	useGetPromoMenuForPriceQuery,
	useGetPromoMenuQuery,
	useUpdatePromoMenuItemPriceMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

interface DialogFormFields {
	name: string;
	StartDate: string;
	EndDate: string;
	description: string;
	taxType: string;
	PromotionValue: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
	StartDate: '',
	EndDate: '',
	description: '',
	taxType: '',
	PromotionValue: '',
};

// interface PatronType {
// 	key: '';
// }

export const PromotionalMenuPricingList = () => {
	useEffect(() => {
		console.log('PromotionalMenuPricingList');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();

	const { outletID, promotionalMenuID } = useParams<any>();

	const { anchorEl, handleClick, handleClose, menu } = useMenuOption();

	const [openEditDialog, setOpenEditDialog] = React.useState(false);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const [menuData, setMenuData] = useState(null);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const PriceSchema = yup.lazy(details => {
		return yup.object().shape(
			Object.fromEntries(
				Object.keys(details).map(key => {
					return [
						key,
						yup
							.number()
							.transform(value => (isNaN(value) ? 0 : value))
							.required('Required field!'),
					];
				}),
			),
		);
	});

	const { register, control, setValue, watch, handleSubmit, errors } = useForm({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(PriceSchema),
	});

	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS

	const {
		data: promoMenuData,
		loading: promoMenuLoading,
	} = useGetPromoMenuQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, ID: promotionalMenuID },
	});

	const {
		data: { getPromoMenu } = { getPromoMenu: [] },
		loading: promoMenuForPriceLoading,
		called: promoMenuCalled,
	} = useGetPromoMenuForPriceQuery({
		fetchPolicy: 'network-only',
		variables: { ID: promotionalMenuID },
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const { data: { getMenuItem } = { getMenuItem: [] } } = useGetMenuItemQuery({
		variables: {
			isSetMenu: false,
			outletID: outletID,
		},
	});

	const [
		updatePromoMenuItemPrice,
		{ loading: updatePromoMenuItemPriceLoading },
	] = useUpdatePromoMenuItemPriceMutation({
		onCompleted: ({ updatePromoMenuItemPrice }) => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			if (updatePromoMenuItemPrice) {
				setOpenEditDialog(false);
				setMenuData(null);
			}
		},
	});

	const [
		deletePromoMenuItemAndPrice,
		{ loading: deletePromoMenuItemAndPriceLoading },
	] = useDeletePromoMenuItemAndPriceMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: ({ deletePromoMenuItemAndPrice }) => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
			if (deletePromoMenuItemAndPrice) {
				// setOpenConfirm(false);
				setMenuData(null);
			}
		},
	});

	//COMPONENTS
	//COMPONENTS
	//COMPONENTS
	//COMPONENTS
	//COMPONENTS

	const spacing_nine = () => {
		return (
			<Grid container>
				<Grid item xs={9} className="left"></Grid>
			</Grid>
		);
	};

	const patron_content = (data: any, styleJson?: any) => {
		return (
			<Grid container>
				{data?.map((v: any, i: number) => (
					<Grid item xs={3}>
						<div className="desc" style={{ ...styleJson }}>
							{v?.patron?.patronType.name}
						</div>
						<div className="desc" style={{ ...styleJson }}>
							{typeof v?.price === 'string'
								? v?.price
								: v?.price !== null
								? `${amtStr(v?.price)}`
								: null}
						</div>
					</Grid>
				))}
			</Grid>
		);
	};

	const edit_pricing_dialog = () => {
		return (
			<CommonDialog
				className="list-item-diag"
				fullWidth={true}
				open={openEditDialog}
				onClose={() => {
					setMenuData(null);
					setOpenEditDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Promotional Menu Item Pricing</div>
										{/* <div className="flex session">
											<div className="title flex-space">
												{menuData?.menuItem?.name}
											</div>
										</div> */}
									</div>
								</div>
								<div className="session">
									<div className="flex session">
										<div
											className="xsTitle flex-space infoline-content"
											style={{ color: 'black' }}
										>
											<div className="flex-space infoline">
												{menuData?.menuItem?.name}{' '}
											</div>
											<div className="xsTitle rightText infoline">Edit</div>
										</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<ContentWrapper style={{ marginTop: '0px' }}>
								<div
									style={{
										marginBottom: '20px',
									}}
									className="text-noflow mdDesc"
								>
									{menuData?.menuItem?.description}
								</div>
								{menuData?.promoMenuItemPrice?.map((v, index) => (
									// <div className="patron-type-div">
									<Grid container style={{ paddingTop: '6px' }}>
										<Grid item xs={6}>
											<span
												className="mdDesc patron-type-span"
												id={`patron[${index}]`}
											>
												<span>{v?.patron?.patronType.name}</span>
											</span>
										</Grid>
										<Grid item xs={6}>
											{/* <span className="list-item-patron-diag "> */}
											{/* <span className="dialog-span"> */}
											<Controller
												render={({ onChange, onBlur, value }) => (
													<NumberFormat
														customInput={TextField}
														allowNegative={false}
														fullWidth
														autoComplete="off"
														isNumericString
														decimalScale={2}
														fixedDecimalScale
														thousandSeparator
														variant="outlined"
														// color="primary"
														// ref={register}
														className="custom-add align-text"
														onValueChange={(value: any) => {
															const { floatValue } = value;
															onChange(floatValue);
														}}
														defaultValue={v.price}
														style={{ justifyContent: 'right' }}
													/>
												)}
												variant="outlined"
												control={control}
												ref={register}
												name={v.patronID}
												defaultValue={v.price}
												helperText={errors?.[v?.patronID]?.message}
												error={errors?.[v?.patronID]}
											/>
											{/* </span> */}
										</Grid>
									</Grid>
								))}
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Delete',
								props: {
									onClick: () => {
										setOpenDeleteDialog(true);
										setOpenEditDialog(false);
									},
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: () => {
										handleSubmit((data: any) => {
											let menuItemPriceInput = Object.keys(data)?.map(
												(x, index) => {
													return {
														patronID: x,
														price: data[x],
													};
												},
											);

											updatePromoMenuItemPrice({
												variables: {
													outletID: outletID,
													promoMenuItemID: menuData?.ID,
													promoMenuItemPriceInput: menuItemPriceInput,
												},
												refetchQueries: [
													{
														query: GetPromoMenuForPriceDocument,
														variables: { ID: promotionalMenuID },
													},
												],
											});
										})();
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		);
	};

	// const CssTextField = withStyles({
	// 	root: {
	// 		'& .MuiOutlinedInput-root': {
	// 			'& fieldset': {
	// 				borderColor: '#ff9800',
	// 			},
	// 		},
	// 	},
	// })(TextField);

	return (
		<>
			{promoMenuLoading && <Loading />}
			{promoMenuForPriceLoading && <Loading />}
			{outletLoading && <Loading />}
			{updatePromoMenuItemPriceLoading && <Loading />}
			{deletePromoMenuItemAndPriceLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/promotional-menu`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Promotional Menu & Pricing' },
					{ name: 'Promotional Menu & Pricing', current: true },
				]}
			/>
			<DynamicSubHeader
				// style={{
				// 	width:
				// 		isDesktop && globalState.drawerOpen
				// 			? 'calc(100% - 288px)'
				// 			: isDesktop && !globalState.drawerOpen
				// 			? `calc(100% - 48px)`
				// 			: null,
				// }}
				title={promoMenuData?.getPromoMenu[0]?.name}
			/>
			<ContentWrapper float style={{ marginTop: isDesktop ? '95px' : '28px' }}>
				{/* <ContentWrapper footer style={{ marginTop: isDesktop ? '70px' : null }}> */}
				<form>
					<CardContents>
						{!promoMenuLoading &&
							!promoMenuForPriceLoading &&
							!outletLoading &&
							promoMenuCalled && (
								<>
									<FormControl>
										<InputLabel>Promotion Menu</InputLabel>
										<Input
											value={promoMenuData?.getPromoMenu[0]?.name}
											endAdornment={
												<InputAdornment position="end">
													<EditIcon
														style={{
															fontSize: '15px',
															color: 'white',
															backgroundColor: '#ff9800',
															borderRadius: '3px',
														}}
														onClick={() => {
															history.push({
																pathname: `/menu/outlet-app/outlet-setting/${outletID}/promotional-menu/${promotionalMenuID}/edit`,
																state: promoMenuData?.getPromoMenu[0],
															});
														}}
													/>
												</InputAdornment>
											}
										/>
									</FormControl>
								</>
							)}
					</CardContents>
					{!promoMenuLoading &&
					!promoMenuForPriceLoading &&
					!outletLoading &&
					promoMenuCalled &&
					(getPromoMenu[0]?.promoMenuItem === undefined ||
						getPromoMenu[0]?.promoMenuItem?.length) === 0 ? (
						<EmptyList
							title="No Promotion Menu Item found"
							subtitle="Please add a promotion menu  item to continue"
						/>
					) : (
						<CardContents>
							<Grid container>
								<Grid item xs={7} className="left">
									<div className="xxTitle" style={{ fontWeight: 'bold' }}>
										Menu Item
									</div>
								</Grid>
								<Grid
									item
									xs={4}
									className="left"
									style={{ textAlign: 'end', paddingRight: '10px' }}
								>
									<div className="xxTitle">Standard Price</div>
								</Grid>
								<Grid item xs={1}></Grid>
							</Grid>

							<Divider style={{ display: 'flex', width: '100%' }} />

							{getPromoMenu[0]?.promoMenuItem?.map((v: any, i: number) => (
								<div style={{ float: 'left', width: '100%' }}>
									<Grid container>
										<Grid item xs={12} className="left"></Grid>
									</Grid>
									<Grid container>
										<Grid
											item
											xs={6}
											className="left"
											style={{ alignSelf: 'center' }}
										>
											<div
												className="xxTitle left"
												style={{
													textDecorationLine: 'underline',
													fontWeight: 'bold',
												}}
											>
												{v?.menuItem?.name}
											</div>
										</Grid>

										<Grid
											item
											xs={5}
											style={{
												textAlign: 'end',
												paddingRight: '10px',
												alignSelf: 'center',
											}}
										>
											<div
												className="desc"
												style={{ color: '#ff9800', fontWeight: 'bold' }}
											>
												{`${amtStr(
													v?.promoMenuItemPrice?.filter(
														price => price?.patron?.patronClass === 'Visitor',
													)[0]?.price,
												)}`}
											</div>
										</Grid>
										<Grid
											item
											xs={1}
											style={{ textAlign: 'end', alignSelf: 'center' }}
										>
											<div>
												<EditIcon
													style={{
														fontSize: '15px',
														color: 'white',
														backgroundColor: '#ff9800',
														borderRadius: '3px',
													}}
													onClick={(e: any) => {
														setOpenEditDialog(true);
														setMenuData(v);
													}}
												/>
											</div>
										</Grid>
									</Grid>

									<Grid container>
										<Grid item xs={9} className="left">
											<div className="desc text-noflow">
												{v?.menuItem?.description}
											</div>
										</Grid>
									</Grid>

									{spacing_nine()}
									{patron_content(
										v?.promoMenuItemPrice?.filter(
											price =>
												price?.patron?.patronClass !== 'Visitor' &&
												price?.patron?.isActive,
										),
									)}
									{spacing_nine()}
								</div>
							))}
						</CardContents>
					)}
				</form>
				{edit_pricing_dialog()}
				<GeneralDeleteDialog
					ID={menu?.ID}
					outletID
					openDialog={openDeleteDialog}
					setOpenDialog={setOpenDeleteDialog}
					dialogName={`${menuData?.menuItem?.name} Pricing`}
					deleteID={{ promoMenuItemID: menuData?.ID }}
					deleteMutation={deletePromoMenuItemAndPrice}
					// refetchDocument={GetPromoMenuForPriceDocument}
					// refetchID={{ ID: promotionalMenuID }}
					refetchQueries={[
						{
							query: GetPromoMenuForPriceDocument,
							variables: { ID: promotionalMenuID },
						},
						{
							query: GetRemainingMenuItemsForPromoDocument,
							variables: {
								promoMenuID: promotionalMenuID,
								outletID: outletID,
							},
						},
					]}
				/>
				<FloatButton
					onClick={() =>
						history.push(
							`/menu/outlet-app/outlet-setting/${outletID}/promotional-menu/${promotionalMenuID}/edit/promotional-menu-pricing/add`,
						)
					}
				/>
			</ContentWrapper>
		</>
	);
};
