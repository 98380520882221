import React from 'react';
import { CommonSettingSubmenu } from './CommonSettingSubmenu';
import taxSetupRoutes from './TaxSetup/TaxSetupRoutes';
import taxSchemeRoutes from './TaxScheme/TaxSchemeRoutes';

const commonSettingRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/common-setting`,
		},
		component: <CommonSettingSubmenu />,
	},
	...taxSetupRoutes,
	...taxSchemeRoutes,
];

export default commonSettingRoutes;
