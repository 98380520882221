import React from 'react';
import { TakeawayBillItemDiscount } from './TakeawayBillItemDiscount';
import { TakeawayConfirmOrder } from './TakeawayConfirmOrder';
import { TakeawayList } from './TakeawayList';

const takeawayRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/takeaway`,
		},
		component: <TakeawayList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/order/:orderID/takeaway/detail`,
		},
		component: <TakeawayConfirmOrder mode="detail" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/order/:orderID/takeaway/confirm-order`,
		},
		component: <TakeawayConfirmOrder mode="confirmOrder" />,
	},
	// {
	// 	props: {
	// 		exact: true,
	// 		path: `/menu/outlet-app/:outletID/order/:orderID/takeaway/discount`,
	// 	},
	// 	component: <TakeawayBillItemDiscount />,
	// },
];

export default takeawayRoutes;
