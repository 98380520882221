import { List, ListItem } from '@material-ui/core'
import React from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'

interface DraggableProps {
  id: string
  index: number
  draggableId: string
  children: any
}

/**
 *
 * @param id
 *
 * each id of the list item
 *
 * @param index
 *
 * index of the list
 *
 * @param
 *
 * draggableId (should be the same as id)
 * unless the the determination id for the dragging over is index id
 *
 */

export const DraggableItem = (props: DraggableProps) => {
  const { id, index, draggableId, children } = props
  return (
    <Draggable key={id} index={index} draggableId={draggableId}>
      {(provided, snapshot) => (
        <>
          <ListItem
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            innerRef={provided.innerRef}
            isdragging={snapshot.isdragging}
            key={id}
          >
            {children}
          </ListItem>
        </>
      )}
    </Draggable>
  )
}

interface DropableItemProps {
  droppableId: string
  children: any
}

/**
 *
 * @param droppableId
 *
 * the "id" or the name of the list (can be anything just to give specific id to the list)
 * the "id" is required for the droppable list to stay on the same list
 *
 */

export const DropableItem = (props: DropableItemProps) => {
  const { droppableId, children } = props
  return (
    <Droppable droppableId={droppableId}>
      {(provided, snapshot) => (
        <List
          innerRef={provided.innerRef}
          {...provided.droppableProps}
          isdraggingover={snapshot.isdraggingover}
          className="core-list"
        >
          {/* {data?.map((el, index) => (
            <DraggableItem
              data={el}
              index={index}
              draggableId={el?.id}
              children={children}
            />
          ))} */}
          {children}
          {provided.placeholder}
        </List>
      )}
    </Droppable>
  )
}

interface DragAndDropListProps {
  onDragEnd: any
  children: any
}

/**
 *
 * @param onDragEnd
 *
 * processing data after drag and drop
 *
 * @example onDragEnd()
 *
 * const onDragEnd = (result, data) => {
 *      const {destination, source, draggableId } = result
 *
 *      // draggableId -- the current id dragged
 *
 *      // this part is depending on how the data is filtered
 *      const currDraggedItem = data?.filter(v => v?.id === draggableId)[0]
 *
 *      if (!destination) {
 *          return
 *      }
 *
 *      if (
 *          destination.droppableId === source.droppableId &&
 *          destination.index === source.index
 *      ) {
 *          return
 *      }
 *
 *      // this part is depending on how to mutate the data after dragging
 *      const newData = data;
 *
 *      newData.splice(source.index, 1)
 *      newData.splice(destination.index, 0, currDraggedItem)
 *
 *      // depending on how you set up the data in the first place (preferably using useState)
 *      setData(newData)
 * }
 *
 *
 */

export const DragNDropList = (props: DragAndDropListProps) => {
  const { onDragEnd, children } = props
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      {/* <DropableItem droppableId={list_id} data={data}>  */}
      {children}
    </DragDropContext>
  )
}
