import React from 'react';
import { BillReceipt } from './BillReceipt/BillReceipt';
import { BillSettlement } from './BillSettlement';
import { OnlinePaymentError } from './OnlinePaymentError';
import { OnlinePaymentRedirect } from './OnlinePaymentRedirect';
import { AuditLog } from '../AuditLog/AuditLogNew';

const billSettlementRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/bill-settlement`,
		},
		component: <BillSettlement />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/bill-settlement/audit-log`,
		},
		component: <AuditLog mode="bill-settlement" module="audit-log" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/bill-settlement/online-payment/redirect`,
		},
		component: <OnlinePaymentRedirect />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/online-payment/error`,
		},
		component: <OnlinePaymentError />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/bill-settlement/:billID`,
		},
		component: <BillReceipt module="bill-settlement" mode="dineIn" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/order/:orderID/bill-settlement/:billID/takeaway`,
		},
		component: <BillReceipt module="bill-settlement" mode="takeAway" />,
	},
];

export default billSettlementRoutes;
