import React from 'react';
import { StopSalesItemForm } from './StopSalesItemForm';
import { StopSalesItemList } from './StopSalesItemList';

const stopSalesItemRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/stop-sales-item`,
		},
		component: <StopSalesItemList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/stop-sales-item/add`,
		},
		component: <StopSalesItemForm mode="add" />,
	},
];

export default stopSalesItemRoutes;
