import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { useGetOutletQuery, useGetScheduledMenuQuery } from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const MenuSchedulingList = () => {
	const history = useHistory();
	const { outletID } = useParams<any>();
	const { data, loading: scheduledMenuLoading } = useGetScheduledMenuQuery({
		variables: { outletID },
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	return (
		<>
			{scheduledMenuLoading && <Loading />}
			{outletLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Menu Scheduling', current: true },
				]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{!scheduledMenuLoading &&
					!outletLoading &&
					(data?.getScheduledMenu === undefined ||
						data?.getScheduledMenu.length === 0) ? (
						<EmptyList title="No Records found" />
					) : null}
					{data?.getScheduledMenu.map((el, index) => (
						<ListItem>
							<ListItemText
								primary={
									<>
										<span className="xsTitle flex-space">{`${formatDate(
											el.effectiveTs,
										)} | ${el?.name}`}</span>
									</>
								}
								secondary={
									<span className="desc text-separator">
										{'No of Menu:'}
										<span className="highlight-text text-separator">
											{el?.scheduledDigitalMenuMealPeriod?.length}
										</span>
									</span>
								}
							/>
							{/* payment-reminder */}
							<ListItemSecondaryAction>
								<IconButton edge="end" aria-label="delete">
									<KeyboardArrowRight
										onClick={() =>
											//handleClick(e, el.ID, index, el)
											history.push({
												pathname: `/menu/outlet-app/outlet-setting/${outletID}/menu-scheduling/${el.ID}/edit`,
												state: el,
											})
										}
									/>
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
				<FloatButton
					onClick={() =>
						history.push(
							`/menu/outlet-app/outlet-setting/${outletID}/menu-scheduling/add`,
						)
					}
				/>
			</ContentWrapper>
		</>
	);
};
