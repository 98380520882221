import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import React from 'react';

interface DialogProps {
	openDialog: boolean;
	setOpenDialog: any;
	personType?: string;
	name?: string;
	handleConfirm?: any;
	customMsg?: any;
	customTitle?: string;
}

export const InactiveDialog = (props: DialogProps) => {
	const {
		openDialog,
		setOpenDialog,
		personType,
		name,
		handleConfirm,
		customMsg,
		customTitle,
	} = props;

	return (
		<CommonDialog
			fullWidth={true}
			open={openDialog}
			onClose={() => setOpenDialog(false)}
			sections={{
				header: {
					dynamic: (
						<div className="">
							<div className="dialog-dynamic-content">
								<div className="session">
									<div className="title">{`${
										!!customTitle ? customTitle : 'Confirm'
									}`}</div>
								</div>
							</div>
						</div>
					),
				},
				body: () => (
					<>
						{!!customMsg ? (
							customMsg
						) : (
							<div>
								<span className="mdDesc text-noflow">
									{`Are you sure you want to set ${personType} ${name} to inactive?`}
								</span>
							</div>
						)}
					</>
				),
				footer: {
					actions: [
						{
							displayText: 'Cancel',
							props: {
								onClick: () => {
									setOpenDialog(false);
								},
								variant: 'contained',
								color: 'secondary',
							},
						},
						{
							displayText: 'Confirm',
							props: {
								onClick: () => {
									if (!!handleConfirm) handleConfirm();
									setOpenDialog(false);
								},
								variant: 'contained',
								color: 'primary',
							},
						},
					],
				},
			}}
		/>
	);
};
