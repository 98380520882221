import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CalcType,
	GetPromoCodeDocument,
	useCreatePromoCodeMutation,
	useDeletePromoCodeMutation,
	useGetOutletQuery,
	useGetPromoCodeQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

interface DialogFormFields {
	name: string;
	StartDate: string;
	EndDate: string;
	description: string;
	taxType: string;
	PromotionValue: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
	StartDate: '',
	EndDate: '',
	description: '',
	taxType: '',
	PromotionValue: '',
};

export const PromotionCodeList = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	// const { outletID } = props;

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();

	const { outletID } = useParams<Record<string, any>>();
	const [
		deletePromoCode,
		{ loading: deletePromoCodeLoading },
	] = useDeletePromoCodeMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const [openDeleteDialog, setDeleteDialog] = useState(false);

	const {
		data,
		called: promoCalled,
		loading: promoLoading,
	} = useGetPromoCodeQuery({ variables: { outletID } });

	const [
		submitPromoCode,
		{ data: createdPromoCodeData },
	] = useCreatePromoCodeMutation({
		fetchPolicy: 'no-cache',
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const morevertList = [
		{
			name: 'Edit',
			action: () => {
				handleClose();
			},
		},
		{
			name: 'Delete',
			action: () => {
				handleClose();
			},
		},
	];

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	// useEffect(
	// 	() => {
	// 		if (
	// 			(!promoCalled && data?.getPromoCode === undefined) ||
	// 			data?.getPromoCode?.length === 0
	// 		) {
	// 			history.push(
	// 				`/menu/outlet-app/outlet-setting/${outletID}/promo-code/add`,
	// 			);
	// 		} else {
	// 			history.push({
	// 				pathname: `/menu/outlet-app/outlet-setting/${outletID}/promo-code`,
	// 			});
	// 		}
	// 	},
	// 	// [],
	// 	[data?.getPromoCode],
	// );

	return (
		<>
			{promoLoading && <Loading />}
			{outletLoading && <Loading />}
			{deletePromoCodeLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Promo Code', current: true },
				]}
			/>
			<ContentWrapper float>
				<List className="core-list">
					{!promoLoading &&
					!outletLoading &&
					(data?.getPromoCode === undefined ||
						data?.getPromoCode?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add promotion code to continue"
						/>
					) : (
						data?.getPromoCode.map((el, index) => {
							return (
								<ListItem key={index}>
									<ListItemText
										primary={
											<>
												<span className="xsTitle" style={{ fontSize: 12 }}>
													{/* {'PROMO30'} */}
													{el.code}
												</span>
											</>
										}
										secondary={
											<>
												<span className="desc flex-space">
													{/* {'25 Aug 2020 - 31 Aug 2020 | $30.00'} */}
													{formatDate(el.validFrom)} -{' '}
													{formatDate(el.validTill)} |{' '}
													{el.calcType === CalcType.Amount
														? `$${el.amount}`
														: `${el.amount}%`}
													{/* {el.calcType} */}
													{/* {el.amount} */}
												</span>
											</>
										}
									/>

									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="delete"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, el.ID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							);
						})
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem>
						<span
							onClick={() =>
								history.push({
									pathname: `/menu/outlet-app/outlet-setting/${outletID}/promo-code/${menu.ID}/edit`,
									state: menu.obj,
								})
							}
						>
							Edit
						</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>
			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.code}
				deleteMutation={deletePromoCode}
				refetchDocument={GetPromoCodeDocument}
			/>
			<FloatButton
				// disabled={handlePermDisabled({
				// //   contractID: '',
				// //   permEnum:
				// //     ContractPermission.HomeProjectPurchasesContractListingExecSummaryDeliveryOrderCreate,
				// })}
				onClick={() =>
					history.push(
						`/menu/outlet-app/outlet-setting/${outletID}/promo-code/add`,
					)
				}
			/>
		</>
	);
};
