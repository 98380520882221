import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Grid,
	InputAdornment,
	List,
	ListItem,
	ListItemText,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetDailyMenuItemAvailabilityDocument,
	GetMenuItemDocument,
	useGetDailyMenuItemAvailabilityQuery,
	useGetOutletQuery,
	useSubmitDailyMenuItemAvailabilityMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './dailyMenuItemAvail.scss';

interface AvailabilityMode {
	quantity: Number;
	dayTs: Date;
	menuItemID: string;
	name: string;
	outstandingQty: Number;
}
export const DailyMenuItemAvailabilityForm = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { outletID }: any = useParams();

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const { globalState, dispatch }: any = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS

	const [
		submitDailyMenuItemAvailability,
		{
			loading: submitDailyMenuLoading,
			called: submitDailyMenuCalled,
			error: submitDailyMenuError,
		},
	] = useSubmitDailyMenuItemAvailabilityMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
		},
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const { data } = useGetDailyMenuItemAvailabilityQuery({
		variables: { outletID: outletID },
	});

	const {
		loading: dailyMenuItemAvailabilityLoading,
		called: dailyMenuItemAvailabilityCalled,
		data: { getDailyMenuItemAvailability } = {
			getDailyMenuItemAvailability: [],
		},
	} = useGetDailyMenuItemAvailabilityQuery({
		variables: {
			outletID: outletID,
		},
	});

	const labelOffset = -6;

	const { handleSubmit, register, setValue, watch, errors, control } = useForm<
		AvailabilityMode
	>({
		mode: 'all',
		defaultValues: {},
	});

	const check = (quantity, defaultQuantity) => {
		if (quantity > defaultQuantity) {
			return true;
		} else if (quantity <= defaultQuantity) {
			return false;
		}
	};

	//ONSUBMIT
	//ONSUBMIT
	//ONSUBMIT

	const onSubmit = (data: any) => {
		const menuItems: any[] = Object.keys(data?.menuItems);
		const finalInput = menuItems?.map(menuItemID => {
			return {
				menuItemID: menuItemID,
				quantity: parseFloat(data?.menuItems[menuItemID]) || 10,
				dayTs: new Date(),
			};
		});
		{
			submitDailyMenuItemAvailability({
				variables: {
					input: finalInput,
					outletID,
				},
				refetchQueries: [
					{
						query: GetDailyMenuItemAvailabilityDocument,
						variables: { outletID },
					},
					{
						query: GetMenuItemDocument,
						variables: {
							outletID: outletID,
							isSetMenu: false,
							isQuantityControlled: true,
						},
					},
				],
			});
		}
	};

	return (
		<>
			{outletLoading && <Loading />}
			{dailyMenuItemAvailabilityLoading && <Loading />}
			{submitDailyMenuLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Daily Menu Item Availability', current: true },
				]}
			/>
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<ContentWrapper>
					<List className="core-list">
						{!dailyMenuItemAvailabilityCalled &&
						!dailyMenuItemAvailabilityLoading &&
						(getDailyMenuItemAvailability === undefined ||
							getDailyMenuItemAvailability?.length === 0) ? (
							<EmptyList
								title="No controlled quantity menu item found"
								subtitle="Please add a controlled quantity menu item to continue"
							/>
						) : (
							!dailyMenuItemAvailabilityLoading &&
							getDailyMenuItemAvailability?.map((el, index) => {
								const menuItems = `menuItems[${index}]`;
								return (
									<>
										<ListItem style={{ padding: '4px 12px' }}>
											<ListItemText
												primary={
													<>
														<Grid container justify="flex-start">
															<Grid item xs={8}>
																<Grid
																	item
																	xs={12}
																	className="xsTitle flex-space"
																	style={{ paddingTop: '6px' }}
																>
																	{el?.menuItem?.name}
																</Grid>

																<Grid item xs={12} className="flex-space desc">
																	{el?.menuItem?.description}
																</Grid>
															</Grid>
															<Grid item xs={4}>
																<Grid item xs={12}>
																	<Controller
																		as={NumberFormat}
																		allowNegative={false}
																		// className="p-l-10 price-input"
																		className="quantity"
																		thousandSeparator
																		customInput={TextField}
																		InputProps={{
																			startAdornment: (
																				<InputAdornment
																					className="highlight-text"
																					position="start"
																				>
																					<div className="highlight-text xsTitle">
																						Qty
																					</div>
																				</InputAdornment>
																			),
																		}}
																		style={{ margin: '0px', padding: '0px' }}
																		id={`menuItems[${el?.menuItemID}]`}
																		variant="outlined"
																		// size="small"
																		fullWidth
																		margin="dense"
																		name={`menuItems[${el?.menuItemID}]`}
																		data-id={index}
																		defaultValue={el?.quantity}
																		ref={register}
																		control={control}
																		helperText={
																			check(
																				watch(`menuItems[${el?.menuItemID}]`),
																				el?.menuItem?.defaultQuantity,
																			)
																				? 'Quantity cannot be lesser than Default Quantity in Menu Items'
																				: null
																		}
																		error={
																			check(
																				watch(`menuItems[${el?.menuItemID}]`),
																				el?.menuItem?.defaultQuantity,
																			)
																				? true
																				: false
																		}
																	/>
																</Grid>
																{console.log(
																	'apa',
																	check(
																		watch(`menuItems[${el?.menuItemID}]`),
																		el?.menuItem?.defaultQuantity,
																	),
																)}
																<Grid
																	item
																	xs={12}
																	className="xsTitle highlight-text"
																	style={{
																		textAlign: 'right',
																		paddingRight: '14px',
																	}}
																>
																	{`${el?.quantity - el?.outstandingQty}/${
																		el?.quantity
																	}`}
																</Grid>
															</Grid>
														</Grid>
													</>
												}
											/>
										</ListItem>
									</>
								);
							})
						)}
					</List>
					<DynamicFooter
						style={{
							width:
								isDesktop && globalState.drawerOpen
									? 'calc(100% - 240px)'
									: '100%',
						}}
						options={[
							{
								name: 'Save',
								color: 'primary',
								props: {
									type: 'submit',
								},
							},
						]}
					/>
				</ContentWrapper>
			</form>
		</>
	);
};
