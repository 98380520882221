import theme from '@ifca-root/react-component/src/assets/theme';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
// import { DateSubHeader } from '@ifca-root/react-component/src/components/Header/DateSubHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import PhoneText from '@ifca-root/react-component/src/components/Typography/PhoneText';
import {
	Box,
	Divider,
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { Check, MoreVert, Person, TableChart } from '@material-ui/icons';
import CallIcon from '@material-ui/icons/Call';
import { KeyboardDatePicker } from '@material-ui/pickers';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetReservationDocument,
	PosPermission,
	ReservationStatus,
	useCancelReservationMutation,
	useGetAssignedOutletByUserQuery,
	useGetCurrentMealPeriodLazyQuery,
	useGetReservationQuery,
	useReinstateReservationMutation,
} from 'generated/graphql';
import {
	formatDate,
	formatDay,
	formatTimeDate,
} from 'helpers/hooks/formatDate';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import { CloseTableIcon, OutletIcon } from '../../../assets';

interface CancelReservationProps {
	ID?: String;
}

interface ReinstateReservationProps {
	ID?: String;
}

export const ReservationList = () => {
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const history = useHistory();
	const { globalState, dispatch } = useContext(AppContext as any);

	const { handlePermDisabled } = usePermissionChecker();

	const { outletID } = useParams<any>();
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	/////////////////////////////////////
	/////// QUERIES AND MUTATIONS ///////
	/////////////////////////////////////

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const {
		called: reservationCalled,
		loading: reservationLoading,
		error,
		data: { getReservation } = { getReservation: [] },
	} = useGetReservationQuery({
		fetchPolicy: 'network-only',
		// fetchPolicy: 'cache-first',
		errorPolicy: 'ignore',
		variables: { outletID: outletID },
		onCompleted: () => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	// const {
	// 	data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
	// 	loading: getAssignedOutletByUserLoading,
	// } = useGetOutletQuery();

	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
	});

	const cancel = (data: string) => {
		return data === 'Cancelled';
	};
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const [openSwitch, setOpenSwitch] = useState(null);

	const [reinstateDialog, setReinstateDialog] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [
		reinstateReservation,
		{ data: restoreReservation, loading: reinstateLoading },
	] = useReinstateReservationMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setReinstateDialog(false);
			setOpenSnackBar(true);
			setSnackBarMsg('Record reinstated successfully');
		},
	});

	const [reservationCancelled, setReservationCancelled] = useState(false);
	const { handleSubmit: rHandleSubmit } = useForm<ReinstateReservationProps>({
		defaultValues: {},
	});

	const onReinstateSubmit = async (data: any) => {
		reinstateReservation({
			variables: {
				reservationID: menu?.obj?.ID,
			},
			refetchQueries: [
				// {
				// 	query: GetOutletDocument,
				// 	variables: { outletID: outletID },
				// },
				// {
				// 	query: GetTableDocument,
				// 	variables: { outletID: outletID },
				// },
				{
					query: GetReservationDocument,
					variables: { outletID: outletID },
				},
			],
		});
	};

	const [
		cancelReservation,
		{ data: closeReservation, loading: closeReservationLoading },
	] = useCancelReservationMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setReservationCancelled(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.cancelRecord());
		},
	});
	const { handleSubmit, control, register } = useForm<CancelReservationProps>({
		defaultValues: {},
	});

	const onSubmit = async (data: any) => {
		cancelReservation({
			variables: {
				reservationID: menu?.obj?.ID,
			},
			refetchQueries: [
				{
					query: GetReservationDocument,
					variables: { outletID: outletID },
				},
			],
		});
	};

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	useEffect(() => {
		if (getReservation && getReservation?.length > 0) {
			setOriginalListing(getReservation);
		}
	}, [getReservation]);

	// const { filteredList : filteredListReservationTableInfo,
	// 	handleSearch: handleSearchReservationTableInfo,
	// 	setOriginalListing: setOriginalListingReservationTableInfo} = useFuseSearch();

	// useEffect(() => {
	// 	if (getReservation?.table && getReservation?.table?.length > 0) {
	// 		setOriginalListingReservationTableInfo(getReservation?.table);
	// 	}
	// }, [getReservation?.table]);

	const {
		filteredList: filteredList1,
		handleSearch: handleSearch1,
		setOriginalListing: setOriginalListing1,
	} = useFuseSearch();

	useEffect(() => {
		if (getAssignedOutletByUser && getAssignedOutletByUser?.length > 0) {
			setOriginalListing1(getAssignedOutletByUser);
		}
	}, [getAssignedOutletByUser]);

	// Drawer Toggler
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	///////////////// DATE SEARCH HEADER ///////////////
	const firstDay = new Date();
	const lastDay = new Date();

	//selectedStartDate
	const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
		firstDay,
	);
	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date);
	};

	//selectedEndDate
	const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
		lastDay,
	);
	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date);
	};

	/////////////// CSS ALIGNMENT DEKSTOP/MOBILE ////////////////
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	return (
		<>
			{/* {loading && <Loading />} */}
			{reservationLoading && <Loading />}
			{getAssignedOutletByUserLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Outlet App"
				sideBtn="switch"
				onClickAction={() => setOpenSwitch(true)}
				title={
					getAssignedOutletByUser?.filter(outlet => outlet?.ID === outletID)[0]
						?.name
				}
				// title={user?.companyName}
				routeSegments={[{ name: 'Listing', current: true }]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${currentMealPeriod?.name} ${formatTime(
									currentMealPeriod?.startTime,
							  )} - ${formatTime(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<div
				className={`fix-subheader`}
				style={{
					width:
						isDesktop && globalState.drawerOpen
							? 'calc(100% - 288px) '
							: isDesktop && !globalState.drawerOpen
							? 'calc(100% - 48px)'
							: '100%',
				}}
			>
				<div
					className={`dynamic-subheader fix-subheader-content multiple`}
					style={{ paddingBottom: '30px' }}
				>
					{/* <CardActions> */}
					<Grid container direction="row">
						<Grid item xs={5} container justify="center">
							<KeyboardDatePicker
								inputVariant="outlined"
								format="dd MMM yyyy"
								id="date-picker-dialog"
								value={selectedStartDate}
								autoOk
								onChange={handleStartDateChange}
								className="custom-date-reservation"
								KeyboardButtonProps={{
									style: {},
									'aria-label': 'change date',
								}}
								style={{
									zIndex: 40,
									width: '100%',
								}}
								InputProps={{
									style: {
										fontSize: '12px',
										backgroundColor: 'white',
										color: 'black',
									},
									readOnly: true,
									disableUnderline: true,
									fullWidth: true,
								}}
							/>
						</Grid>
						<Grid item xs={2} container justify="center">
							<div
								style={{
									marginTop: '8px',
								}}
							>
								{'>'}
							</div>
						</Grid>
						<Grid item xs={5} container justify="center">
							<KeyboardDatePicker
								inputVariant="outlined"
								format="dd MMM yyyy"
								id="date-picker-dialog"
								value={selectedEndDate}
								onChange={handleEndDateChange}
								autoOk
								className="custom-date-reservation"
								KeyboardButtonProps={{
									'aria-label': 'change date',
									style: { width: '100%' },
								}}
								style={{
									zIndex: 40,
									width: '100%',
								}}
								InputProps={{
									style: {
										fontSize: '12px',
										backgroundColor: 'white',
										color: 'black',
									},
									readOnly: true,
									disableUnderline: true,
									fullWidth: true,
								}}
								fullWidth
							/>
						</Grid>
					</Grid>
					{/* </CardActions> */}
				</div>
			</div>

			{/* <DateSubHeader
				selectedDateLeft={selectedStartDate}
				selectedDateRight={selectedEndDate}
				disable={false}
				dateChangeLeft={handleStartDateChange}
				dateChangeRight={handleEndDateChange}
			/> */}

			<SearchHeader
				title="Reservation Listing"
				value={`${filteredList?.filter(a => {
					var queryDate = new Date(a?.reservationDate);
					const formatQueryDate = `${queryDate.getFullYear()}/${(
						queryDate.getMonth() + 1
					)
						.toString()
						.padStart(2, '0')}/${queryDate
						.getDate()
						.toString()
						.padStart(2, '0')}`;

					var dateStart = new Date(selectedStartDate);
					const formatStartDate = `${dateStart.getFullYear()}/${(
						dateStart.getMonth() + 1
					)
						.toString()
						.padStart(2, '0')}/${dateStart
						.getDate()
						.toString()
						.padStart(2, '0')}`;

					var dateEnd = new Date(selectedEndDate);
					const formatLastDate = `${dateEnd.getFullYear()}/${(
						dateEnd.getMonth() + 1
					)
						.toString()
						.padStart(2, '0')}/${dateEnd
						.getDate()
						.toString()
						.padStart(2, '0')}`;

					return (
						formatQueryDate >= formatStartDate &&
						formatQueryDate <= formatLastDate
					);
				})?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, [
						'name',
						'contactNo',
						'docNo',
						'description',
						'table.tablePrfixNumberInfo',
					]);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({
							value: e?.target?.value,
						}),
					);
				}}
				fixed
				search
				datePickerSubInfo
				onCloseAction={() => handleSearch('', [])}
				// style={{
				// 	width:
				// 		isDesktop && globalState.drawerOpen
				// 			? 'calc(100% - 288px) '
				// 			: isDesktop && !globalState.drawerOpen
				// 			? 'calc(100% - 48px)'
				// 			: '100%',
				// }}
			/>

			<ContentWrapper datePickerSearchBar>
				<List className="core-list">
					{!reservationLoading &&
					!getAssignedOutletByUserLoading &&
					!getCurrentMealPeriodLoading &&
					(getReservation === undefined || getReservation?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add reservation to continue"
						/>
					) : (
						filteredList
							?.filter(a => {
								var queryDate = new Date(a?.reservationDate);
								const formatQueryDate = `${queryDate.getFullYear()}/${(
									queryDate.getMonth() + 1
								)
									.toString()
									.padStart(2, '0')}/${queryDate
									.getDate()
									.toString()
									.padStart(2, '0')}`;

								var dateStart = new Date(selectedStartDate);
								const formatStartDate = `${dateStart.getFullYear()}/${(
									dateStart.getMonth() + 1
								)
									.toString()
									.padStart(2, '0')}/${dateStart
									.getDate()
									.toString()
									.padStart(2, '0')}`;

								var dateEnd = new Date(selectedEndDate);
								const formatLastDate = `${dateEnd.getFullYear()}/${(
									dateEnd.getMonth() + 1
								)
									.toString()
									.padStart(2, '0')}/${dateEnd
									.getDate()
									.toString()
									.padStart(2, '0')}`;

								return (
									formatQueryDate >= formatStartDate &&
									formatQueryDate <= formatLastDate
								);
							})
							?.slice()
							?.sort(function(a, b) {
								var queryDate1 = new Date(a?.createdTs);
								const formatQueryDate1 = `${queryDate1
									.getDate()
									.toString()
									.padStart(2, '0')}/${(queryDate1.getMonth() + 1)
									.toString()
									.padStart(2, '0')}/${queryDate1.getFullYear()}`;
								var queryDate2 = new Date(a?.createdTs);
								const formatQueryDate2 = `${queryDate2
									.getDate()
									.toString()
									.padStart(2, '0')}/${(queryDate2.getMonth() + 1)
									.toString()
									.padStart(2, '0')}/${queryDate2.getFullYear()}`;
								var aa = formatQueryDate1
										.split('/')
										.reverse()
										.join(),
									bb = formatQueryDate2
										.split('/')
										.reverse()
										.join();
								return aa < bb ? -1 : aa > bb ? 1 : 0;
							})
							?.map((el, index) => (
								<ListItem selected={el.noShow === true}>
									<>
										<Grid container>
											<Grid
												item
												xs={2}
												className="desc"
												style={{
													textDecoration:
														el.status === 'CANCELLED' ? 'line-through' : null,
													alignSelf: 'center',
												}}
											>
												{el.docNo}
											</Grid>
											<Grid
												item
												xs={6}
												className="xsTitle highlight-text"
												style={{
													color: 'black',
													textDecoration:
														el.status === 'CANCELLED' ? 'line-through' : null,
												}}
											>
												{el?.name}
											</Grid>
											<Grid
												item
												xs={4}
												style={{
													color: '#ff9800',
													textDecoration:
														el.status === 'CANCELLED' ? 'line-through' : null,
													textAlign: 'right',
													alignSelf: 'center',
												}}
												className="desc click-text highlight-text"
											>
												{/* <IconButton
													edge="end"
													aria-label="delete"
													onClick={() =>
														window.open(`https://wa.me/${el.contactNo}`)
													}
												>
													<CallIcon
														style={{
															backgroundColor: '#4FCE5D',
															color: 'white',
															borderRadius: '3px',
															fontSize: '12px',
															marginRight: '4px',
														}}
													/>
												</IconButton> */}
												<PhoneText font="xsTitle">
													{/* <span className="color-primary-orange"> */}
													{`${el?.contactNo}`}
													{/* </span> */}
												</PhoneText>
											</Grid>

											<Grid
												item
												xs={2}
												className="desc"
												style={{
													textDecoration:
														el.status === 'CANCELLED' ? 'line-through' : null,
												}}
											>
												<Person
													style={{
														fontSize: '17px',
														verticalAlign: 'sub',
														marginRight: '4px',
														color: '#ff9800',
													}}
												/>
												<span
													className="color-primary-orange"
													style={{
														fontSize: '12px',
														fontWeight: 600,
													}}
												>
													{el?.pax}
												</span>
											</Grid>

											<Grid
												item
												xs={10}
												className="desc"
												style={{
													textDecoration:
														el.status === 'CANCELLED' ? 'line-through' : null,
												}}
											>
												{formatDay(el.reservationDate)}{' '}
												{formatTime(el.reservationTime)} |{' '}
												{formatDate(el.reservationDate)}
											</Grid>

											<Grid
												item
												xs={2}
												className="desc highlight-text"
												// style={{ color: '#ff9800' }}
											>
												{/* <TableChart
													style={{
														fontSize: '17px',
														verticalAlign: 'sub',
														marginRight: '4px',
														color: '#ff9800',
													}}
												/> */}
												<img
													src={CloseTableIcon}
													style={{
														width: '16px',
														height: '16px',
														marginRight: '4px',
														verticalAlign: 'sub',
													}}
												/>

												<span className="color-primary-orange">
													{el?.table?.prefix + el?.table?.tableNumber || '_'}
												</span>
											</Grid>
											<Grid
												item
												xs={6}
												className="desc"
												style={{
													textDecoration:
														el.status === 'CANCELLED' ? 'line-through' : null,
												}}
											>
												{el.description}
											</Grid>
											<Grid
												item
												xs={4}
												className="desc"
												style={{
													textDecoration:
														el.status === 'CANCELLED' ? 'line-through' : null,
													textAlign: 'right',
												}}
											>
												{formatTimeDate(el?.createdTs)}
											</Grid>
										</Grid>
									</>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="delete"
											aria-controls="menu-list"
											aria-haspopup="true"
											disabled={el.noShow == true}
											style={{
												backgroundColor: el.noShow ? '#d1d1d1' : 'white',
											}}
											onClick={e => handleClick(e, el?.ID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<>
						<MenuItem
							// disabled={handlePermDisabled({
							// 	outletID: outletID,
							// 	permEnum: PosPermission.ReservationUpdate,
							// })}
							onClick={() =>
								history.push({
									pathname: `/menu/outlet-app/${outletID}/reservation/${menu.ID}/edit`,
									state: menu.obj,
									// `${__OUTLET_APP__OUTLETS}/${outletID}/${DISCOUNT}/${discountID}/edit`,
								})
							}
						>
							<span>Edit</span>
						</MenuItem>
						{menu?.obj?.status === ReservationStatus.Cancelled ? (
							<MenuItem
								// disabled={handlePermDisabled({
								// 	outletID: outletID,
								// 	permEnum: PosPermission.ReservationReinstate,
								// })}
								onClick={e => {
									setReinstateDialog(true);
									// handleClick(e, el.ID, index, el);
									// setIsEdit(false);
								}}
							>
								<span>Reinstate</span>
							</MenuItem>
						) : null}

						{menu?.obj?.status === ReservationStatus.Active ? (
							<MenuItem
								// onClick={ () => {
								// 	setOpenDialog(false);
								// 	isConfirm(true);
								// }}
								// onClick={() => {
								// 	setDeleteDialog(true);
								// }}
								// disabled={handlePermDisabled({
								// 	outletID: outletID,
								// 	permEnum: PosPermission.ReservationAssignTable,
								// })}
								onClick={() =>
									history.push({
										pathname: `/menu/outlet-app/${outletID}/reservation/${menu.ID}/table/grid`,
										state: menu.obj,
									})
								}
							>
								<span className="">Assign Table</span>
							</MenuItem>
						) : null}

						{menu?.obj?.status === ReservationStatus.Active ? (
							<MenuItem
								// onClick={ () => {
								// 	setOpenDialog(false);
								// 	isConfirm(true);
								// }}
								// onClick={() => {
								// 	setDeleteDialog(true);
								// }}
								// disabled={handlePermDisabled({
								// 	outletID: outletID,
								// 	permEnum: PosPermission.ReservationCancel,
								// })}
								onClick={e => {
									setReservationCancelled(true);
									// handleClick(e, el.ID, index, el);
									// setIsEdit(false);
								}}
							>
								<span className="">Cancellation</span>
							</MenuItem>
						) : null}
					</>
				</Menu>
			</ContentWrapper>
			<CommonDialog
				fullWidth={true}
				open={openSwitch}
				onClose={() => setOpenSwitch(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{`Switch Outlet`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{getAssignedOutletByUser?.length > 2 ? (
								<TextField
									// className="m-b-20"
									id="standard-basic"
									label="Search here..."
									fullWidth
									onChange={e => {
										handleSearch1(`'${e?.target?.value}`, ['name']);
									}}
								/>
							) : null}
							<ContentWrapper style={{ marginTop: '0px' }}>
								<List>
									{filteredList1?.map((outlet, index) => (
										<>
											<ListItem
												className={
													outlet?.ID === outletID ? 'bg-blue-light' : null
												}
											>
												<div style={{ paddingRight: '6px' }}>
													{outlet?.logo?.fileURL ? (
														<img
															src={outlet?.logo?.fileURL}
															style={{
																display: 'flex',
																marginTop: '2px',
																marginBottom: '2px',
																marginRight: '4px',
																width: '40px',
																height: '40px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													) : (
														<img
															src={OutletIcon}
															style={{
																display: 'flex',
																marginTop: '7px',
																marginBottom: '8px',
																marginRight: '4px',
																width: '40px',
																height: '25px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													)}
												</div>
												<ListItemText
													primary={
														<span className="xsTitle">{outlet?.name}</span>
													}
													onClick={() => {
														history.push(
															`/menu/outlet-app/${outlet?.ID}/reservation`,
														);
														setOpenSwitch(false);
														localStorage.setItem('latestOutlet', outlet?.ID);
													}}
												/>
												{outlet?.ID === outletID ? (
													<ListItemSecondaryAction>
														<IconButton edge="end" aria-label="delete">
															<Check color="primary" />
														</IconButton>
													</ListItemSecondaryAction>
												) : null}
											</ListItem>
											<Divider
												// variant="fullWidth"
												style={{ display: 'flex', width: '100%' }}
											/>
										</>
									))}
								</List>
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => setOpenSwitch(false),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>

			<form onSubmit={handleSubmit(onSubmit)} id="close-form">
				<Box mt={1}>
					<CommonDialog
						fullWidth={true}
						open={reservationCancelled}
						onClose={() => setReservationCancelled(false)}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session" style={{ color: '' }}>
												<div className="title" style={{ paddingBottom: '7px' }}>
													Cancel Reservation
												</div>
											</div>
										</div>
										<div className="infoline-content ">
											<div className="xsTitle infoline">
												{menu?.obj?.docNo}
												{/* {menu?.obj?.tableNumber} */}
												{/* {selectedTable?.prefix}
													{selectedTable?.tableNumber} */}
											</div>
										</div>
									</div>
								),
							},
							body: () => (
								<>
									<div className="mdDesc">
										{/* {'Mr'}&nbsp; */}
										{menu?.obj?.name}
										{menu?.obj?.tableNumber}
										{'`s'}&nbsp;{'Reservation?'}
										{/* {selectedTable?.prefix}
										{selectedTable?.tableNumber}? */}
									</div>
								</>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => setReservationCancelled(false),
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Confirm',
										props: {
											//onClick: () => handleSubmit(onSubmit)(),
											onClick: () => setReservationCancelled(true),

											variant: 'contained',
											form: 'close-form',
											color: 'primary',
											type: 'submit',
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			<form onSubmit={rHandleSubmit(onReinstateSubmit)} id="open-form">
				<Box mt={1}>
					<CommonDialog
						fullWidth={true}
						open={reinstateDialog}
						onClose={() => setReinstateDialog(false)}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												<div className="title">Reinstate</div>
											</div>
										</div>
										<div className="infoline-content ">
											<div className="xsTitle infoline">
												{menu?.obj?.docNo}
												{/* {menu?.obj?.tableNumber} */}
												{/* {selectedTable?.prefix}
													{selectedTable?.tableNumber} */}
											</div>
										</div>
									</div>
								),
							},
							body: () => (
								<>
									<div className="mdDesc">
										{'Mr'}&nbsp;
										{menu?.obj?.name}
										{menu?.obj?.tableNumber}
										{'`s'}&nbsp;{'Reservation?'}
										{/* {selectedTable?.prefix}
										{selectedTable?.tableNumber}? */}
									</div>
								</>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => setReinstateDialog(false),
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Confirm',
										props: {
											//onClick: () => handleSubmit(onSubmit)(),
											onClick: () => setReinstateDialog(true),

											variant: 'contained',
											form: 'open-form',
											color: 'primary',
											type: 'submit',
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			<FloatButton
				// disabled={handlePermDisabled({
				// 	outletID: outletID,
				// 	permEnum: PosPermission.ReservationCreate,
				// })}
				onClick={() =>
					history.push(`/menu/outlet-app/${outletID}/reservation/add`)
				}
			/>
		</>
	);
};
