import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { Grid, List, ListItem, TextField } from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	useGetTaxDetailsQuery,
	useGetTaxTypeListingLazyQuery,
} from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
const dateFormat = require('dateformat');

export enum SchemeType {
	GST = 'GST',
	ServiceCharge = 'ServiceCharge',
	SST = 'SST',
	VAT = 'VAT',
}

export const TaxSetupDetail = () => {
	let history = useHistory();
	let location = useLocation();
	const { taxId } = useParams<any>();
	const state: any = location.state;
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const [taxDetail, setTaxDetail] = useState(null);

	const {
		loading: getTaxDetailsLoading,
		data: { getTaxDetails } = { GetTaxDetails: null },
	} = useGetTaxDetailsQuery({
		variables: {
			taxID: taxId,
			accountID: user?.accountID,
		},
		fetchPolicy: 'no-cache',
	});

	const [
		getTaxTypeListingQuery,
		{ data: { getTaxTypeListing } = { getTaxTypeListing: null } },
	] = useGetTaxTypeListingLazyQuery();

	useEffect(() => {
		let taxDetailArr: any = [];
		getTaxDetails?.taxDetail?.map(el => {
			const arrObj = {
				EffectiveDate: dateFormat(el?.effectiveDate, 'dd mmm yyyy'),
				Rate: el?.rate,
			};

			taxDetailArr.push(arrObj);
		});

		const data = getTaxTypeListing?.find(
			x => x?.id === getTaxDetails?.schemeTypeId,
		);
		console.log('data', data);

		const taxObj = {
			Code: getTaxDetails?.code,
			Description: getTaxDetails?.description,
			ClassType: getTaxDetails?.classType,
			TaxSchemeType:
				data?.name === 'ServiceCharge' ? 'Service Charge' : data?.name,
			TaxRateArr: taxDetailArr,
		};
		setTaxDetail(taxObj);
		getTaxTypeListingQuery({
			variables: {
				softwareCode: 'HotelX',
			},
		});
		filterTaxType = getTaxTypeListing?.filter(
			x => x?.id === getTaxDetails?.schemeTypeId,
		)[0];
		modeTax = filterTaxType?.name === SchemeType.SST ? 'sst' : 'servicecharge';
		// console.log(filterTaxType);
	}, [getTaxDetails]);
	let filterTaxType = getTaxTypeListing?.filter(
		x => x?.id === getTaxDetails?.schemeTypeId,
	)[0];
	let modeTax =
		filterTaxType?.name === SchemeType.SST ? 'sst' : 'servicecharge';

	console.log(filterTaxType);

	return getTaxDetailsLoading ? (
		<Loading />
	) : (
		<>
			<MainHeader
				onClick={() =>
					history.push(`/menu/outlet-app/common-setting/tax-policy`)
				}
				mainBtn="back"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: '' },
					{ name: '' },
					{ name: '' },
					{ name: 'Tax Policy Detail', current: true },
				]}
			/>
			<ContentWrapper>
				<CardContents>
					<TextField
						label="Tax Code"
						fullWidth
						InputProps={{
							disableUnderline: true,
							readOnly: true,
							style: {
								fontSize: '13px',
							},
						}}
						value={taxDetail?.Code}
						margin="normal"
					/>
					<TextField
						label="Description"
						fullWidth
						InputProps={{
							disableUnderline: true,
							readOnly: true,
							style: {
								fontSize: '13px',
							},
						}}
						value={taxDetail?.Description}
						margin="normal"
					/>
					<TextField
						label="Tax Scheme Type"
						fullWidth
						InputProps={{
							disableUnderline: true,
							readOnly: true,
							style: {
								fontSize: '13px',
							},
						}}
						value={taxDetail?.TaxSchemeType}
						margin="normal"
					/>
					<TextField
						label="Type"
						fullWidth
						InputProps={{
							disableUnderline: true,
							readOnly: true,
							style: {
								fontSize: '13px',
							},
						}}
						value={taxDetail?.ClassType}
						margin="normal"
					/>
				</CardContents>
				<CardContents
					section={{
						header: {
							title: 'Tax Rates',
						},
					}}
				>
					<div className="flex-space ">
						<List className="core-list">
							{taxDetail?.TaxRateArr?.map((el, index) => (
								<ListItem
									divider
									style={{
										boxShadow: 'none',
										paddingTop: '10px',
										fontSize: '13px',
									}}
								>
									<div className="content-wrap" style={{ paddingLeft: '10px' }}>
										<div
											className="fw-normal"
											style={{ color: 'gray', paddingBottom: '6px' }}
										>
											Effective Date
										</div>
										<div className="fw-medium">{el?.EffectiveDate}</div>
									</div>
									<div className="content-wrap" style={{ paddingLeft: '10px' }}>
										<div
											className="fw-normal"
											style={{ color: 'gray', paddingBottom: '6px' }}
										>
											Tax Rate (%)
										</div>
										<div className="fw-normal">{amtStr(el?.Rate)}</div>
									</div>
								</ListItem>
							))}
						</List>
					</div>
				</CardContents>
			</ContentWrapper>
		</>
	);
};
