import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetOfficerDocument,
	PosPermission,
	useDeleteOfficerMutation,
	useGetOfficerQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
import { InactiveDialog } from '../GenSetGenericComp/InactiveDialog';

interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};

export const AppointedOfficerList = () => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const [inactiveDialog, setInactiveDialog] = useState(false);
	const { IDK } = useParams<Record<string, any>>();
	const [openDeleteDialog, setDeleteDialog] = useState(false);

	const outletID = localStorage.getItem('latestOutlet');

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const { handlePermDisabled } = usePermissionChecker();

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const officer = JSON.parse(localStorage.getItem('searchFilter'));
	const [filterUserStatus, setFilterUserStatus] = useState<any>('NONE');

	const [rerender, setRender] = useState(false);

	/////////////////////////////////////
	/////// QUERIES AND MUTATIONS ///////
	/////////////////////////////////////

	const {
		called,
		loading: officerLoading,
		error,
		data: { getOfficer } = { getOfficer: [] },
	} = useGetOfficerQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderByDesc: 'name',
		},
		onCompleted: ({ getOfficer }) => {},
	});

	useEffect(() => {
		if (getOfficer && getOfficer?.length > 0) {
			setOriginalListing(getOfficer);
		}
	}, [getOfficer]);

	// useEffect(() => {
	// 	if (getOfficer && getOfficer?.length > 0 && !loading) {
	// 		setOriginalListing(getOfficer);
	// 	}
	// }, [loading, getOfficer]);

	useEffect(() => {
		if (!officerLoading && getOfficer?.length > 0 && officer?.value) {
			handleSearch(`'"${officer?.value ?? ''}"`, ['name']);
			setRender(true);
		}
	}, [officerLoading, getOfficer, rerender]);

	// Delete Mutation

	const [
		deleteOfficer,
		{ loading: deleteOfficerLoading },
	] = useDeleteOfficerMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const moreVertList = [
		{
			name: 'View',
			action: () => {
				handleClose();
			},
		},
		{
			name: 'Edit',
			action: () => {
				//setInactiveDialog(true);
				handleClose();
			},
		},
		{
			name: 'Delete',
			action: () => {
				setDeleteDialog(true);
				handleClose();
			},
		},
	];

	if (menu?.obj?.status === 'Active') {
		moreVertList.splice(1, 0, {
			name: 'Deactivate',
			action: () => {
				setInactiveDialog(true);
				handleClose();
			},
		});
	}

	let location: any = useLocation();

	const [success, setSuccess] = useState<boolean>(
		location.state ? location.state['success'] : false,
	);
	const { globalState, dispatch } = useContext(AppContext as any);

	setTimeout(() => {
		setSuccess(false);
	}, 3000);

	return (
		<>
			{officerLoading && <Loading />}
			{deleteOfficerLoading && <Loading />}

			<MainHeader
				onClick={() => {
					localStorage.removeItem('searchFilter');
					history.push({ pathname: `/menu/outlet-app/general-setting` });
				}}
				mainBtn="back"
				smTitle={'Outlet App'}
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Settings' },
					{ name: 'Appointed Officer', current: true },
				]}
			/>

			<div
				className={
					globalState.drawerOpen
						? 'search-filter with-dropdown-filter-general-settings'
						: 'search-filter with-dropdown-filter-general-settings-fw'
				}
			>
				<SearchHeader
					title="Appointed Officer Listing "
					value={`${
						filteredList?.filter(e => {
							if (filterUserStatus === 'NONE') {
								return e;
							} else if (filterUserStatus !== 'ACTIVE') {
								return e?.isActive === false;
							} else {
								return e?.isActive === true;
							}
						})?.length
					}`}
					onChangeAction={e => {
						handleSearch(`'"${e?.target?.value}"`, ['name']);
						localStorage.setItem(
							`searchFilter`,
							JSON.stringify({ value: e?.target?.value }),
						);
					}}
					isDefaultValue={!!officer?.value ?? false}
					defaultValue={officer?.value ?? ''}
					search
					onCloseAction={() => handleSearch('', [])}
				/>
				<DropdownBar
					searchBarDropdown
					options={[
						{
							name: 'All',
							ID: 'NONE',
						},
						{
							name: 'Active ',
							ID: 'ACTIVE',
						},
						{
							name: 'Inactive',
							ID: 'INACTIVE',
						},
					]}
					setOptionID={setFilterUserStatus}
					borderRadius="4px"
				/>
			</div>
			<ContentWrapper searchBarDropdown>
				<List className="core-list">
					{!officerLoading &&
					(getOfficer === undefined || getOfficer?.length <= 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Add a new record now."
						/>
					) : (
						filteredList
							?.filter(e => {
								if (filterUserStatus === 'NONE') {
									return e;
								} else if (filterUserStatus !== 'ACTIVE') {
									return e?.isActive === false;
								} else {
									return e?.isActive === true;
								}
							})
							?.sort((a, b) => (!a?.isActive ? 1 : -1))
							?.map((el, index) => (
								<ListItem key={index} disabled={el?.isActive === false}>
									<ListItemText
										primary={
											<>
												<span className="xsTitle flex-space">{el.name}</span>
												<span className="xxTitle highlight-text text-separator"></span>
												<span className="xxTitle highlight-text"></span>
											</>
										}
										secondary={
											<>
												<span className="desc" style={{ color: '' }}>
													{el?.designation}
												</span>{' '}
											</>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="delete"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, el.ID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
					)}
				</List>

				<InactiveDialog
					openDialog={inactiveDialog}
					setOpenDialog={setInactiveDialog}
					personType="officer"
					name="Ken Yong"
				/>
				<GeneralDeleteDialog
					ID={menu?.ID}
					refetchID={{ IDK: IDK, orderByDesc: 'name' }}
					openDialog={openDeleteDialog}
					setOpenDialog={setDeleteDialog}
					dialogName={menu?.obj?.name}
					deleteMutation={deleteOfficer}
					refetchDocument={GetOfficerDocument}
				/>

				<FloatButton
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum: PosPermission.GeneralSettingAppointedOfficerCreate,
					})}
					onClick={() =>
						history.push(`/menu/outlet-app/general-setting/officer/add`)
					}
				/>
			</ContentWrapper>

			<Menu
				id="menu-list"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				onClick={handleClose}
			>
				<MenuItem
					onClick={() =>
						history.push({
							pathname: `/menu/outlet-app/general-setting/officer/${menu?.ID}/detail`,
							state: menu.obj,
						})
					}
				>
					<span> View </span>
				</MenuItem>

				<MenuItem
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum: PosPermission.GeneralSettingAppointedOfficerUpdate,
					})}
					onClick={() =>
						history.push({
							pathname: `/menu/outlet-app/general-setting/officer/${menu?.ID}/edit`,
							state: menu.obj,
						})
					}
				>
					<span className="">Edit</span>
				</MenuItem>
				<MenuItem
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum: PosPermission.GeneralSettingAppointedOfficerDelete,
					})}
					onClick={() => setDeleteDialog(true)}
				>
					<span className="">Delete</span>
				</MenuItem>
			</Menu>
		</>
	);
};
