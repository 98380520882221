import React from 'react';
import { PaymentTypeForm } from './PaymentTypeForm';

const paymentTypeRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/payment-type`,
		},
		component: <PaymentTypeForm />,
	},
];

export default paymentTypeRoutes;
