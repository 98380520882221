import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetMenuItemDocument,
	useDeleteMenuItemMutation,
	useGetMenuItemQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

interface DialogFormFields {
	name: string;
	StartDate: string;
	EndDate: string;
	description: string;
	taxType: string;
	PromotionValue: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
	StartDate: '',
	EndDate: '',
	description: '',
	taxType: '',
	PromotionValue: '',
};

export const SetMenuList = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	// const { outletID } = props;
	// const [user] = useAtom(userAtom);
	// const [posOutlet] = useAtom(posActiveOutletAtom);
	const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const { [`outletID`]: outletID } = useParams<Record<string, any>>();

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	// const { data: { getMenuItem } = { getMenuItem: [] } } = useGetMenuItemQuery({
	// 	fetchPolicy: 'network-only',
	// 	variables: {
	// 		isSetMenu: true,
	// 		outletID: outletID,
	// 	},
	// });

	const {
		data,
		called: setMenuCalled,
		loading: setMenuLoading,
	} = useGetMenuItemQuery({
		fetchPolicy: 'network-only',
		variables: { isSetMenu: true, outletID: outletID },
	});

	const [
		deleteSetMenu,
		{ data: createdSetMenuData, loading: deleteSetMenuLoading },
	] = useDeleteMenuItemMutation({
		fetchPolicy: 'no-cache',
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	return (
		<>
			{setMenuLoading && <Loading />}
			{outletLoading && <Loading />}
			{deleteSetMenuLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Set Menu', current: true },
				]}
			/>
			<ContentWrapper float>
				<List className="core-list">
					{!setMenuLoading &&
					!outletLoading &&
					(data?.getMenuItem === undefined ||
						data?.getMenuItem?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add set menu to continue"
						/>
					) : (
						data?.getMenuItem?.map((el, index) => (
							<ListItem>
								<div style={{ paddingRight: '6px' }}>
									<img
										src={el?.attachments?.fileURL}
										style={{
											display: 'flex',
											marginTop: '2px',
											marginBottom: '2px',
											marginRight: '4px',
											border: '1px solid #eaeaea',
											width: '55px',
											height: '55px',
											justifyItems: 'center',
											boxSizing: 'border-box',
											borderRadius: '5px',
										}}
										loading="lazy"
									/>
								</div>
								<ListItemText
									primary={
										<>
											<span className="xsTitle flex-space">{el?.name}</span>
										</>
									}
									secondary={
										<>
											<span className="desc">
												{/* <span className="xxTitle fw-medium"> */}
												{el?.description}
												{/* </span> */}
											</span>
										</>
									}
								/>

								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="more"
										aria-controls="menu-list"
										aria-haspopup="true"
										onClick={e => handleClick(e, el.ID, index, el)}
									>
										<MoreVert />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() =>
							history.push({
								pathname: `/menu/outlet-app/outlet-setting/${outletID}/set-menu/${menu?.ID}/edit`,
								state: menu?.obj,
							})
						}
					>
						<span className="">Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() =>
							history.push(
								`/menu/outlet-app/outlet-setting/${outletID}/set-menu/${menu?.ID}/set-menu-standard-item`,
							)
						}
					>
						<span className="">Standard Item</span>
					</MenuItem>
					<MenuItem
						onClick={() =>
							history.push(
								`/menu/outlet-app/outlet-setting/${outletID}/set-menu/${menu?.ID}/set-menu-option-item`,
							)
						}
					>
						<span className="">Option Item</span>
					</MenuItem>
					<MenuItem onClick={() => setOpenDeleteDialog(true)}>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ isSetMenu: true, outletID: outletID }}
				openDialog={openDeleteDialog}
				setOpenDialog={setOpenDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteSetMenu}
				refetchDocument={GetMenuItemDocument}
			/>

			<FloatButton
				// floatTotalAmt onClick={() => handleClickStatus('create')}
				// onClick={() =>
				// 	history.push(
				// 		`${__OUTLET_APP__OUTLETS}/${outletID}/set-menu/add`,
				// 	)
				// }
				onClick={() =>
					history.push(
						`/menu/outlet-app/outlet-setting/${outletID}/set-menu/add`,
					)
				}
			/>
		</>
	);
};
