import DateFnsUtils from '@date-io/date-fns';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	FormControl,
	FormLabel,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetScheduledMenuDocument,
	useCreateScheduledMenuAndPeriodsMutation,
	useDeleteScheduledMenuMealPeriodsMutation,
	useGetDigitalMenuQuery,
	useGetMealPeriodQuery,
	useGetOutletQuery,
	useUpdateScheduledMenuMealPeriodsMutation,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export interface MenuScheduledProps {
	name: string;
	description: string;
	effectiveTs: Date;
	periods: any;
}

export const MenuSchedulingForm = (props: any) => {
	const history = useHistory();
	const location = useLocation();

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const { globalState, dispatch } = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { mode } = props;
	const { outletID, menuScheduleID }: any = useParams();
	const editData = location?.state as any;
	const [openExitDialog, setOpenExitDialog] = useState(false);

	const { register, control, errors, handleSubmit, setValue } = useForm<
		MenuScheduledProps
	>({
		defaultValues: {
			name: mode === 'add' ? '' : editData?.name,
			description: mode === 'add' ? '' : editData?.description,
			effectiveTs: mode === 'add' ? null : editData?.effectiveTs,
		},
		mode: 'onSubmit',
	});

	const {
		data: { getDigitalMenu } = { getDigitalMenu: [] },
		loading: digitalMenuLoading,
	} = useGetDigitalMenuQuery({
		variables: {
			outletID: outletID,
		},
	});

	const {
		data: { getMealPeriod } = { getMealPeriod: [] },
		loading: mealPeriodLoading,
	} = useGetMealPeriodQuery({
		variables: {
			outletID: outletID,
		},
	});

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [
		submitScheduledMenu,
		{
			data: createdScheduledMenuData,
			loading: createdScheduledMenuLoading,
			called: createdScheduledMenuCalled,
			error: createdScheduledMenuError,
		},
	] = useCreateScheduledMenuAndPeriodsMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			if (data.createScheduledMenuAndPeriods) {
				setTimeout(() => {
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/menu-scheduling`,
						state: { success: true, msgMode: 'create' },
					});
				}, 500);
			}
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		updateScheduleMenu,
		{
			loading: subUpdateLoading,
			error: subUpdateError,
			called: subUpdateCalled,
		},
	] = useUpdateScheduledMenuMealPeriodsMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/menu-scheduling`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const [
		deleteScheduledMenu,
		{ loading: deleteLoading, error: deleteError },
	] = useDeleteScheduledMenuMealPeriodsMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/menu-scheduling`,
					state: { success: true, msgMode: 'delete' },
				});
			}, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const createUpdateCalled = editData
		? subUpdateCalled
		: createdScheduledMenuCalled;

	const onSubmit = (data: any) => {
		const finalScheduledMenuIn = {
			name: data.name,
			effectiveTs: data.effectiveTs,
			outletID: outletID,
			...(mode === 'edit' ? { ID: menuScheduleID } : null),
		};

		const finalScheduledMenuPeriodsIn = data.periods
			.filter((v: any) => !!v.digitalMenuID)
			.map((v: any) => {
				return {
					outletID: outletID,
					mealPeriodID: v.mealPeriodID,
					digitalMenuID: v.digitalMenuID,
				};
			});

		if (mode === 'add') {
			submitScheduledMenu({
				variables: {
					scheduledMenuInput: finalScheduledMenuIn,
					scheduledMenuMealPeriodInput: finalScheduledMenuPeriodsIn,
				},
				refetchQueries: [
					{
						query: GetScheduledMenuDocument,
						variables: { outletID },
					},
				],
			});
		} else {
			updateScheduleMenu({
				variables: {
					scheduledMenuInput: finalScheduledMenuIn,
					scheduledMenuMealPeriodInput: finalScheduledMenuPeriodsIn,
				},
				refetchQueries: [
					{
						query: GetScheduledMenuDocument,
						variables: { outletID },
					},
				],
			});
		}
	};

	const onDelete = (data: any) => {
		deleteScheduledMenu({
			variables: {
				scheduledMenuID: menuScheduleID,
			},
			refetchQueries: [
				{
					query: GetScheduledMenuDocument,
					variables: { outletID },
				},
			],
		});
	};

	const mealTime = (
		period: string,
		timeRange: string,
		data: any,
		index: number,
		mealPeriodID: string,
		mode?: string,
		editData?: any,
	) => {
		const currDigitalMenuID = editData?.filter(
			(v: any) => v.mealPeriodID === mealPeriodID,
		)[0]?.digitalMenuID;

		const regValSetVal = (name: string, ID: string) => {
			register(name);
			setValue(name, ID);
		};

		const regSetMealPeriod = (name: string, ID: string) => {
			regValSetVal(name, ID);
		};

		const regSetDigitalMenu = (name: string, ID: string) => {
			regValSetVal(name, ID);
		};

		if (mode === 'edit') {
			regSetMealPeriod(`periods[${index}].mealPeriodID`, mealPeriodID);
			regSetDigitalMenu(`periods[${index}].digitalMenuID`, currDigitalMenuID);
		}

		return (
			<FormControl component="fieldset" margin="dense" fullWidth>
				<FormLabel
					style={{
						fontSize: '14px',
						color: 'black',
						fontWeight: 'bold',
					}}
				>
					{period}
				</FormLabel>
				<FormLabel
					style={{
						fontSize: '11px',
						color: 'grey',
						paddingTop: '8px',
					}}
				>
					{timeRange}
				</FormLabel>

				<TextField
					id="standard-select-customer"
					select
					fullWidth
					variant="outlined"
					label="Select Outlet Menu"
					margin="dense"
					defaultValue={currDigitalMenuID}
					name={`periods[${index}].digitalMenuID`}
					onChange={(e: any) => {
						regSetMealPeriod(`periods[${index}].mealPeriodID`, mealPeriodID);
						regSetDigitalMenu(
							`periods[${index}].digitalMenuID`,
							e.target.value,
						);
					}}
				>
					{data?.map((v: any) => (
						<MenuItem key={v?.ID} value={v?.ID}>
							{v?.name}
						</MenuItem>
					))}
				</TextField>
			</FormControl>
		);
	};

	const DeleteOption = {
		name: 'Delete',
		onClick: handleSubmit(onDelete),
		color: 'secondary',
	};

	const SaveOption = {
		name: 'Save',
		// onClick: handleSubmit(onSubmit),
		onClick: () => {
			handleSubmit(data => !createUpdateCalled && onSubmit(data))();
		},
		color: 'primary',
	};

	const edit = [DeleteOption, SaveOption];
	const add = [SaveOption];

	const optionFooter = () => {
		if (mode === 'edit') {
			return edit;
		} else {
			return add;
		}
	};

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	return (
		<>
			{digitalMenuLoading && <Loading />}
			{mealPeriodLoading && <Loading />}
			{outletLoading && <Loading />}
			{createdScheduledMenuLoading && <Loading />}
			{deleteLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Menu Scheduling', current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<ContentWrapper footer>
					<CardContents devFullWidth={true}>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Controller
								as={KeyboardDatePicker}
								name="effectiveTs"
								label={'Effective Date'}
								control={control}
								disablePast={mode === 'add' ? true : false}
								minDate={mode === 'edit' ? editData?.effectiveTs : null}
								onChange={(date: Date | null) => {}}
								format="dd MMM yyyy"
								value={null}
								defaultValue={null}
								margin="normal"
								allowKeyboardControl
								fullWidth
								ref={register}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
							/>
						</MuiPickersUtilsProvider>

						<Controller
							as={TextField}
							name="name"
							label="Scheduled Menu Name"
							autoComplete="off"
							multiline={true}
							// required
							fullWidth
							ref={register}
							control={control}
							margin="normal"
						/>

						{	getMealPeriod
							?.slice()
							?.sort(function(a, b) {
								if (a.startTime < b.startTime) {
									return -1;
								}
								if (a.startTime > b.startTime) {
									return 1;
								}
								return 0;
							})
							.map((v,i) =>
							mealTime(
								v?.name,
								`${formatTime(v?.startTime)} - ${formatTime(v?.endTime)}`,
								getDigitalMenu,
								i,
								v?.ID,
								mode,
								editData?.scheduledDigitalMenuMealPeriod,
							),
						)}
					</CardContents>
				</ContentWrapper>
			</form>
			<DynamicFooter
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				options={optionFooter()}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/outlet-setting/${outletID}/menu-scheduling`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push({
											pathname: `/menu/outlet-app/outlet-setting/${outletID}/menu-scheduling`,
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
