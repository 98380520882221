import { yupResolver } from '@hookform/resolvers';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	Checkbox,
	List,
	ListItem,
	ListItemText,
	TextField,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { TreeItem, TreeView } from '@material-ui/lab';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetRoleDocument,
	useCreateRolePermissionMutation,
	useGetRoleQuery,
	useUpdateRolePermissionMutation,
} from 'generated/graphql';
import { IAction } from 'helpers/model';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, {
	Reducer,
	useContext,
	useEffect,
	useReducer,
	useState,
} from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import * as yup from 'yup';
import {
	KitchenPerm,
	PrinterServerPerm,
	RenderPermissionTree,
	RestaurantPerm,
} from './RolesTree';

interface Props {
	// roleName: string;
	selected: any;
	restaurantTree: RenderPermissionTree;
	kitchenTree: RenderPermissionTree;
	printerServerTree: RenderPermissionTree;
	// homeTree: RenderPermissionTree;
	// generalSettingTree: RenderPermissionTree;
	// outletSettingTree: RenderPermissionTree;
	// billListingTree: RenderPermissionTree;

	// reservationTree: RenderPermissionTree;
	// utilityTree: RenderPermissionTree;

	// //customerProfileTree: RenderPermissionTree;
	// businessInsightTree: RenderPermissionTree;
}

interface formProps {
	mode: string;
}
interface RoleForm {
	roleName: string;
}

export const RolesForm = (props: any) => {
	const { mode } = props;
	const history = useHistory();
	let location = useLocation();
	const [check, setCheck] = useState(false);
	const editData = location?.state as any;

	const { data: { getRole } = { getRole: [] } } = useGetRoleQuery({
		fetchPolicy: 'network-only',
	});

	const initialState: Props = {
		restaurantTree: RestaurantPerm,
		kitchenTree: KitchenPerm,
		printerServerTree: PrinterServerPerm,
		// homeTree: HomePerm,
		// generalSettingTree: GeneralSettingPerm,
		// outletSettingTree: OutletSettingPerm,
		// billListingTree: BillListingPerm,
		// reservationTree: ReservationPerm,
		// //customerProfileTree: CustomerProfilePerm,
		// businessInsightTree: BusinessInsightPerm,
		// utilityTree: UtilityPerm,
		selected: mode === 'add' ? [] : editData?.rolePerm.map(x => x.permName),
	};
	const RoleSchema = yup.object().shape({
		roleName: CommonYupValidation.requireField(SystemMsgs.roleName()).trim(),
	});

	const { control, register, handleSubmit, errors, setValue } = useForm<
		RoleForm
	>({
		defaultValues: {
			roleName: mode === 'edit' ? editData?.name : '',
		},
		resolver: yupResolver(RoleSchema),
		mode: 'all',
	});

	var nodeIdToExpand = '';

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		createRolePermission,
		{ loading, error },
	] = useCreateRolePermissionMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(REDIRECT_PATH);
			setTimeout(() => {
				history.push({
					pathname: REDIRECT_PATH,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});
	const REDIRECT_PATH: string = `/menu/outlet-app/general-setting/user-access/role`;
	const [
		updateRolePermission,
		{ loading: updateLoading, error: updateError },
	] = useUpdateRolePermissionMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(REDIRECT_PATH);
			setTimeout(() => {
				history.push({
					pathname: REDIRECT_PATH,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const handleCreateUpdate =
		mode === 'add' ? createRolePermission : updateRolePermission;

	// console.log('RestaurantPerm', RestaurantPerm);
	// console.log('KitchenPerm', KitchenPerm);
	// console.log('PrinterServerPerm', PrinterServerPerm);
	console.log('state', state);

	const handleRenderModule = (modules: string, isForExpand: boolean) => {
		let module;
		switch (modules) {
			case 'restaurant':
				if (isForExpand) {
					nodeIdToExpand = RestaurantPerm.id;
					state.selected.push(RestaurantPerm.id);
				} else {
					module = state.restaurantTree;
				}
				break;
			case 'kitchen':
				if (isForExpand) {
					nodeIdToExpand = KitchenPerm.id;
					state.selected.push(KitchenPerm.id);
				} else {
					module = state.kitchenTree;
				}
				break;
			case 'printerServer':
				if (isForExpand) {
					nodeIdToExpand = PrinterServerPerm.id;
					state.selected.push(PrinterServerPerm.id);
				} else {
					module = state.printerServerTree;
					console.log('apa', PrinterServerPerm, state.printerServerTree);
				}
				break;
			// case 'home':
			// 	if (isForExpand) {
			// 		nodeIdToExpand = HomePerm.id;
			// 		state.selected.push(HomePerm.id);
			// 	} else {
			// 		module = state.homeTree;
			// 	}
			// 	break;
			// case 'generalSetting':
			// 	if (isForExpand) {
			// 		nodeIdToExpand = GeneralSettingPerm.id;
			// 		state.selected.push(GeneralSettingPerm.id);
			// 	} else {
			// 		module = state.generalSettingTree;
			// 	}
			// 	break;
			// case 'outletSetting':
			// 	if (isForExpand) {
			// 		nodeIdToExpand = OutletSettingPerm.id;
			// 		state.selected.push(OutletSettingPerm.id);
			// 	} else {
			// 		module = state.outletSettingTree;
			// 	}
			// 	break;
			// case 'billListing':
			// 	if (isForExpand) {
			// 		nodeIdToExpand = BillListingPerm.id;
			// 		state.selected.push(BillListingPerm.id);
			// 	} else {
			// 		module = state.billListingTree;
			// 	}
			// 	break;
			// case 'reservation':
			// 	if (isForExpand) {
			// 		nodeIdToExpand = ReservationPerm.id;
			// 		state.selected.push(ReservationPerm.id);
			// 	} else {
			// 		module = state.reservationTree;
			// 	}
			// 	break;
			// case 'businessInsight':
			// 	if (isForExpand) {
			// 		nodeIdToExpand = BusinessInsightPerm.id;
			// 		state.selected.push(BusinessInsightPerm.id);
			// 	} else {
			// 		module = state.businessInsightTree;
			// 	}
			// 	break;
			// case 'utility':
			// 	if (isForExpand) {
			// 		nodeIdToExpand = UtilityPerm.id;
			// 		state.selected.push(UtilityPerm.id);
			// 	} else {
			// 		module = state.utilityTree;
			// 	}
			// 	break;
			default:
				module = null;
				break;
		}
		console.log('module', module);
		return module;
	};

	//handleRenderModule(reModules, true);

	const getChildById = (node: RenderPermissionTree, id: string) => {
		console.log('data', node, id);
		let array: string[] = [];
		const getAllChild = (nodes: RenderPermissionTree | null) => {
			if (nodes === null) return [];
			array.push(nodes.id);
			if (Array.isArray(nodes.children)) {
				nodes.children.forEach(node => {
					array = [...array, ...getAllChild(node)];
					array = array.filter((v, i) => array.indexOf(v) === i);
				});
			}
			return array;
		};

		const getNodeById = (nodes: RenderPermissionTree, id: string) => {
			if (nodes.id === id) {
				return nodes;
			} else if (Array.isArray(nodes.children)) {
				let result = null;
				nodes.children.forEach(node => {
					if (!!getNodeById(node, id)) {
						result = getNodeById(node, id);
					}
				});
				return result;
			}

			return null;
		};

		return getAllChild(getNodeById(node, id));
	};

	const [needRerender, setNeedBool] = useState(false);

	const isAllChildrenSelected = (
		selectedNode: any[],
		nodes: RenderPermissionTree,
		id: string,
	) => {
		let k = 0;
		let retBool = true;
		let test = selectedNode;

		if (Array.isArray(nodes.children)) {
			nodes.children.forEach((v, i) => {
				if (selectedNode.includes(v.id)) {
					k = k + 1;
				}
			});
		}

		nodes.selected = k;
	};

	// need to rerender the checkbox since isAllChildrenSelected
	// is checking from parent to children and so on
	useEffect(() => {
		setNeedBool(true);
	}, [state.selected]);

	// initialize save button for each icon
	useEffect(() => {
		if (nodeIdToExpand.length > 0) {
			setCheck(true);
		}
	}, [nodeIdToExpand]);

	//////////////////////////////////////////

	const handleOnChange = (
		checked: boolean,
		nodes: RenderPermissionTree,
		modules: string,
	) => {
		const allNode: string[] = getChildById(
			handleRenderModule(modules, false),
			nodes.id,
		);

		// nodes.selected = checked ? nodes?.children?.length : 0;

		let array = checked
			? [...state.selected, ...allNode]
			: state.selected.filter(value => !allNode.includes(value));
		// array = array.filter((v, i) => array.indexOf(v) === i);

		if (array.length > 0) {
			setCheck(true);
		} else {
			setCheck(false);
		}

		dispatch({
			type: 'selected',
			payload: array,
		});

		return true;
	};

	const onSubmit = data => {
		handleCreateUpdate({
			variables: {
				input: {
					ID: editData?.ID,
					name: data.roleName,
				},
				permissionArr: state.selected,
			},
			refetchQueries: [
				{
					query: GetRoleDocument,
					variables: { ID: editData?.ID },
				},
			],
		});
	};

	const isChildrenSelected = (selected, nodes: RenderPermissionTree, id) => {
		let isChildSelect = nodes?.children?.filter(v => selected?.includes(v?.id));

		if (isChildSelect?.length > 0 && !selected?.includes(id)) {
			selected.push(id);

			dispatch({
				type: 'selected',
				payload: selected,
			});
		}

		isAllChildrenSelected(selected, nodes, id);

		return isChildSelect?.length > 0 ? true : undefined;
	};

	const calculatePermissionChecked = (
		nodes: RenderPermissionTree,
		nodesSelected,
	) => {
		return true;
	};

	{
	}
	const renderTree = (nodes: RenderPermissionTree, modules: string) => (
		<TreeItem
			key={nodes.id}
			nodeId={`${nodes.id}`}
			label={
				<List disablePadding>
					<ListItem disableGutters>
						<Checkbox
							color="primary"
							edge="start"
							checked={
								isChildrenSelected(state.selected, nodes, nodes.id) ||
								state.selected.some(item => item === nodes.id)
							}
							onChange={event => {
								console.log('satu', modules);
								handleOnChange(event.currentTarget.checked, nodes, modules);
							}}
							onClick={e => e.stopPropagation()}
						/>
						<ListItemText
							primary={
								<span className="xsTitle flex-space color-primary-orange">
									{nodes.label}
								</span>
							}
							secondary={
								<>
									{nodes.children?.length > 0 && (
										<span className="desc flex-space">
											{nodes?.selected ? nodes?.selected : 0}
											<span style={{ margin: '0px 4px' }}>/</span>
											{nodes.children?.length} Accessible
										</span>
									)}
								</>
							}
						/>
					</ListItem>
				</List>
			}
		>
			{Array.isArray(nodes?.children)
				? nodes.children.map(node => renderTree(node, modules))
				: null}
		</TreeItem>
	);

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const [openExitDialog, setOpenExitDialog] = useState(false);
	return (
		<>
			{/* {loading && <Loading />} */}
			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="close"
				smTitle={'Outlet App'}
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Settings' },
					{ name: 'Access Security' },
					{ name: 'Roles', current: true },
				]}
				// rightRouteSegments={[
				// ]}
			/>
			<ContentWrapper footer>
				<CardContents devFullWidth={true}>
					<Controller
						as={TextField}
						name="roleName"
						label="Role"
						fullWidth
						defaultValue={editData?.name || ''}
						ref={register}
						control={control}
						required
						helperText={errors?.roleName?.message}
						error={errors?.roleName ? true : false}
					/>
				</CardContents>
				<CardContents
					section={{
						header: {
							title: 'Access Permission',
						},
					}}
				>
					<TreeView
						className="tree-view-list"
						defaultExpandIcon={<ExpandMoreIcon />}
						defaultExpanded={[nodeIdToExpand]}
						defaultCollapseIcon={<ExpandLessIcon />}
						multiSelect
					>
						{renderTree(state.restaurantTree, 'restaurant')}
						{renderTree(state.kitchenTree, 'kitchen')}
						{renderTree(state.printerServerTree, 'printerServer')}

						{/* {renderTree(state.homeTree, 'home')}
						{renderTree(state.generalSettingTree, 'generalSetting')}
						{renderTree(state.outletSettingTree, 'outletSetting')}
						{renderTree(state.billListingTree, 'billListing')}

						{renderTree(state.reservationTree, 'reservation')}
						{/* {renderTree(state.customerProfileTree, 'customerProfile')} */}
						{/* {renderTree(state.businessInsightTree, 'businessInsight')}
						{renderTree(state.utilityTree, 'utility')} */}

						{/* old one */}
						{/* {doCheck('bizInsights') ? renderTree(state.biTree, 'bi') : null} */}
						{/* {renderTree(state.generalSettingTree, 'generalsetting')} */}
					</TreeView>
				</CardContents>

				<DynamicFooter
					options={[
						{
							name: 'SAVE',
							onClick: check ? handleSubmit(onSubmit) : null,
							color: check ? 'primary' : '#D3D3D3',
						},
					]}
				/>
				<ExitConfirmationDialog
					openExitDialog={openExitDialog}
					setOpenExitDialog={setOpenExitDialog}
					backPath={`/menu/outlet-app/general-setting/user-access/role`}
				/>
				{/* <CommonDialog
					fullWidth={true}
					open={openExitDialog}
					onClose={() => {
						setOpenExitDialog(false);
					}}
					sections={{
						header: {
							dynamic: (
								<div>
									<div className="dialogo-dynamic-content">
										<span
											className="title c-orange flex-space"
											style={{
												fontSize: '13px',
												fontWeight: 'bold',
											}}
										>
											Exit Confirmation
										</span>
									</div>
								</div>
							),
						},
						body: () => (
							<CardContents>
								<span className="">
									Are you sure you want to exit? Your changes will not be saved.
								</span>
							</CardContents>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
										},
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
											if (mode === 'edit') {
												history.push({
													pathname: `/menu/outlet-app/general-setting/user-access/role`,
												});
											} else {
												history.push({
													pathname: `/menu/outlet-app/general-setting/user-access/role`,
												});
											}
										},
										variant: 'contained',
										color: 'primary',
									},
								},
							],
						},
					}}
				/> */}
			</ContentWrapper>
		</>
	);
};
