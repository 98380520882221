import { MenuItem, TextField } from '@material-ui/core';
import { TableDialog } from 'components/Dialog/TableDialog';
import { OrderItemList } from 'components/Order/OrderItemList';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetHomeTableSummaryDocument,
	GetOrderDocument,
	GetTableSubmenuTableDocument,
	OrderItemDiscountStatus,
	OrderStatus,
	useGetAllOrderItemLazyQuery,
} from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface TransferItemProps {
	billNo: string;
	billItems: any;
	targetTable: string;
	targetBill: string;
	name: string;
	qty: number;
}

export const ItemTransferDialog = (props: any) => {
	//ITEM TRANSFER MUTATION
	const {
		selectedTable,
		orderItemIDs,
		setOrderItemIDs,
		itemTransfer,
		setItemTransfer,
		outletList,
		tableID,
		outletID,
		orderID,
		getOrder,
		getTable,
		orderTransferItemByQuantity,
	} = props;

	const [negOrderItem, setNegOrderItem] = useState({});

	const [selectedOrderID, setSelectedOrderID] = useState({
		selectedID: null,
	});
	const [updateConfirm, setUpdateConfirm] = useState(false);

	const handleSelectedOrderID = id => {
		setSelectedOrderID({
			selectedID: id,
		});
	};

	// DONT REMOVE THIS QUERY,
	// IT WILL SHOW ERROR ON NETWORK FIRST!
	// SELECT OrderNo FROM ITEM TRANSFER,
	const [
		loadOrderItem,
		{
			data: { getAllOrderItem } = {
				getAllOrderItem: [],
			},
		},
	] = useGetAllOrderItemLazyQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderID: selectedOrderID.selectedID,
		},
		onCompleted: ({ getAllOrderItem }) => {
			let unpaidOrderItem = getAllOrderItem?.filter(
				item => item?.billID === null,
			);
			let temp = {};
			unpaidOrderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				activeOID?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItem?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,

							orderItemOptions: [],
						};

						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;

							let optionData;

							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});
			setNegOrderItem({ ...temp });
		},
	});

	useEffect(() => {
		if (selectedOrderID.selectedID) {
			loadOrderItem(selectedOrderID.selectedID);
		}
	}, [selectedOrderID.selectedID]);

	const objOrderItemQty = Object.keys(negOrderItem);

	const newArray = getAllOrderItem?.filter(v => {
		return (
			v?.quantity - (negOrderItem[v?.ID]?.quantity ?? 0) > 0 &&
			v?.billID === null
		);
	});

	const [quantity1, setQuantity] = useState(0); // this should be changed accordingly
	const [quantityPopUp, setQuantityPopUp] = useState([false]);
	const [selectedOrderItems, setSelectedOrderItems] = useState({});
	const [disable, setDisable] = useState(false);

	const handleCheckBoxPopUpQuantity = (
		e: any,
		ID: string,
		quantity: number,
	) => {
		let temp: any = orderItemIDs;

		if (e.target.checked) {
			setQuantityPopUp([true]);

			selectedOrderItems[ID] = {
				showQuantity: true,
				selectedQuantity: 1,
				maxQuantity: quantity,
			};
		} else {
			setQuantityPopUp([false]);
			selectedOrderItems[ID] = {
				showQuantity: false,
				selectedQuantity: 1,
				maxQuantity: quantity,
			};
		}
	};

	useEffect(() => {
		let temp = orderItemIDs.filter((x: any) => x?.itemID === null);

		if (itemTransfer === false) {
			setQuantityPopUp([false]);
			setOrderItemIDs(temp);
			setSelectedOrderItems(null);
			setSelectedOrderID({
				selectedID: null,
			});
		}
	}, [itemTransfer]);

	const { register, handleSubmit, control, watch, setValue } = useForm<
		TransferItemProps
	>({
		defaultValues: {},
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const selectedOrderItemIDs = Object.keys(selectedOrderItems);

	const transferInput = selectedOrderItemIDs?.map(oi => {
		return {
			orderItemID: oi,
			removeQty: selectedOrderItems[oi].selectedQuantity,
		};
	});
	const onSubmitTransferItemByQuantity = async (data: any) => {
		orderTransferItemByQuantity({
			variables: {
				transferInput: transferInput?.filter(oi => oi.removeQty !== 0),
				targetOrderID: watch('targetOrder'),
				sourceOrderID: watch('orderNo'),
			},
			refetchQueries: [
				{
					query: GetOrderDocument,
					variables: { tableID: tableID, status: OrderStatus.Open },
				},
				{
					query: GetHomeTableSummaryDocument,
					variables: { tableID: tableID },
				},
				{
					query: GetTableSubmenuTableDocument,
					variables: {
						outletID: outletID,
						orderByAsc: 'sequence',
						isActive: true,
					},
				},
			],
		});
		setItemTransfer(false);
	};

	const clickButton = (mode: string, ID: any, quantity: number) => {
		if (mode === 'add') {
			if (
				selectedOrderItems[ID].selectedQuantity <
				selectedOrderItems[ID]?.maxQuantity
			) {
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: true,
						selectedQuantity: selectedOrderItems[ID]?.selectedQuantity + 1,
						maxQuantity: quantity,
					},
				});
			} else {
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: true,
						selectedQuantity: selectedOrderItems[ID]?.selectedQuantity,
						maxQuantity: quantity,
					},
				});
			}
		} else if (mode === 'reduce') {
			if (selectedOrderItems[ID].selectedQuantity === 1) {
				selectedOrderItems[ID].selectedQuantity = 1;
			} else {
				setSelectedOrderItems({
					...selectedOrderItems,
					[ID]: {
						showQuantity: true,
						selectedQuantity: selectedOrderItems[ID].selectedQuantity - 1,
						maxQuantity: quantity,
					},
				});
			}
		}
	};

	const checkedOrderItemsIDs = selectedOrderItemIDs
		?.map(x => selectedOrderItems[x]?.showQuantity)
		?.filter(x => x === true);

	////////////DISABLE BUTTON///////////
	useEffect(() => {
		let oi = checkedOrderItemsIDs[0];
		if (
			oi === true &&
			watch('orderNo') !== undefined &&
			getAllOrderItem?.length > 0 &&
			watch('targetOrder') !== undefined &&
			watch('targetTable') !== undefined
		) {
			return setDisable(false);
		} else {
			setDisable(true);
		}
	});

	const getRoundedQty = qty1 => {
		newArray?.map(el => {
			let outstandingQuantity = selectedOrderItems[el?.ID]?.maxQuantity;
			let roundedQty =
				qty1 > outstandingQuantity ? outstandingQuantity : qty1 < 1 ? 1 : qty1;

			return roundedQty;
		});
	};

	const targetPatron = getTable
		?.find(x => x?.ID === watch('targetTable'))
		?.order?.find(y => y?.ID === watch('targetOrder'))?.patron?.ID;

	const currPatron = getOrder?.filter(x => x?.ID === watch('orderNo'))[0]
		?.patronID;

	const discount = getOrder?.filter(x => x?.ID === watch('orderNo'))[0]
		?.totalDiscountAmount;

	const itemTransferForm = () => {
		return (
			<>
				{/* Order No */}
				<div className="rm-padding table-wrap sample">
					<Controller
						as={
							<TextField select required>
								{getOrder
									?.filter(x => x?.ID !== orderID)
									?.map((el: any, index: number) => (
										<MenuItem
											key={el.ID}
											value={el.ID}
											onClick={e => {
												handleSelectedOrderID(el?.ID);
											}}
											selected={selectedOrderID.selectedID === el?.ID}
										>
											<span>{el.docNo} &nbsp;</span>
											<span className="highlight-text">
												{/* {el.patronAccount?.name} */}
											</span>
										</MenuItem>
									))}
							</TextField>
						}
						label={'Order No.'}
						name={'orderNo'}
						autoComplete="off"
						control={control}
						multiline={true}
						fullWidth
						margin="normal"
						required={true}
						ref={register}
					/>

					<Controller
						as={
							<OrderItemList
								outletID={outletID}
								itemArray={newArray}
								negOrderItem={negOrderItem}
								leftIcon="checkbox"
								leftAction={handleCheckBoxPopUpQuantity}
								rightIcon="quantity"
								selectedObj={selectedOrderItems}
								setSelectedObj={setSelectedOrderItems}
								quantityFunc={{
									onButtonClick: clickButton,
								}}
								page="item-transfer-dialog"
							/>
						}
						name={'orderItem'}
						autoComplete="off"
						control={control}
						multiline={true}
						fullWidth
						margin="normal"
						required={true}
						// helperText={errors?.title?.message}
						// error={errors?.title ? true : false}
						ref={register}
					/>
					{/* Table Dropdown */}

					<Controller
						as={
							<TextField select>
								{getAllOrderItem?.length === 0
									? null
									: getTable
											?.filter(
												x => x?.ID === tableID || x?.status === 'OCCUPIED',
											)
											?.map((el: any, index: number) => (
												<MenuItem key={el.ID} value={el.ID}>
													<span>{el.prefix}</span>
													<span>{el.tableNumber}</span>
												</MenuItem>
											))}
							</TextField>
						}
						label={'To Table'}
						name={'targetTable'}
						autoComplete="off"
						control={control}
						multiline={true}
						disabled={getAllOrderItem?.length === 0 ? true : false}
						fullWidth
						margin="normal"
						required={true}
						// helperText={errors?.title?.message}
						// error={errors?.title ? true : false}
						ref={register}
					/>

					<Controller
						as={
							<TextField select>
								{getTable
									?.filter(x => x?.ID === watch('targetTable'))[0]
									?.order?.filter(y => {
										return (
											y?.status === OrderStatus.Open &&
											y?.ID !== watch('orderNo')
										);
									})
									?.map((el: any, index: number) => (
										<MenuItem key={el.ID} value={el.ID}>
											<span>{el.docNo} &nbsp;</span>
											<span className="highlight-text">
												{/* {el.patronAccount?.name} */}
											</span>
										</MenuItem>
									))}
							</TextField>
						}
						label={'To Order No.'}
						name={'targetOrder'}
						autoComplete="off"
						control={control}
						multiline={true}
						fullWidth
						margin="normal"
						required={true}
						// helperText={errors?.title?.message}
						// error={errors?.title ? true : false}
						ref={register}
						disabled={
							!watch('targetTable') || getAllOrderItem?.length === 0
								? true
								: false
						}
					/>
				</div>
			</>
		);
	};

	return (
		<TableDialog
			open={itemTransfer}
			setOpen={setItemTransfer}
			title={outletList[4].title}
			infoLine={`${getTable?.filter(x => x?.ID === tableID)[0]?.prefix}${
				getTable?.filter(x => x?.ID === tableID)[0]?.tableNumber
			}`}
			infoLineMiddle={
				watch('orderNo') && (
					<span className="highlight-text">
						{getOrder?.filter(x => x?.ID === watch('orderNo'))[0].docNo}
					</span>
				)
			}
			infoLineRight={`$${amtStr(selectedTable?.orderInfo?.totalAmount)}`}
			isForm={true}
			handleSubmit={handleSubmit}
			onSubmit={onSubmitTransferItemByQuantity}
			formStructure={itemTransferForm}
			disabled={disable}
			updateConfirm={updateConfirm}
			setUpdateConfirm={setUpdateConfirm}
			targetPatron={targetPatron}
			currPatron={currPatron}
			discount={discount}

			// handleClickSnackbar={handleClickSnackbar}
		/>
	);
};
