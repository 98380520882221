import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Grid, ListItem, TextField, useMediaQuery } from '@material-ui/core';
import { AddBox, IndeterminateCheckBox } from '@material-ui/icons';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetTableDocument,
	useGetOutletQuery,
	useGetTableQuery,
	useSubmitTableMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import './table.scss';

export interface TableProps {
	outletID: string;
	seatCount: number;
	prefix: string;
	tableNumber: string;
}

interface TableFieldProps {
	tableItems: any;
}

export const TableForm = () => {
	const initialState: TableProps = {
		outletID: '',
		seatCount: 0,
		prefix: '',
		tableNumber: '',
	};

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { globalState } = useContext(AppContext as any);

	const [openExitDialog, setOpenExitDialog] = useState(false);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const history = useHistory();
	const { [`outletID`]: outletID } = useParams<Record<string, any>>();
	initialState.outletID = outletID;

	const TableSchema = yup.object().shape({
		tableItems: yup.array().of(
			yup.object().shape({
				prefix: yup
					.string()
					.required('Required field!')
					.typeError('Required field!'),
				tableNumber: yup
					.string()
					.required('Required field!')
					.typeError('Required field!'),
				seatCount: yup
					.string()
					.required('Required field!')
					.typeError('Required field!'),
			}),
		),
	});

	const {
		register,
		control,
		handleSubmit,
		setError,
		errors,
		clearErrors,
		watch,
		formState: { isSubmitting },
	} = useForm<TableFieldProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(TableSchema),
	});

	const { fields, append, remove, insert, prepend } = useFieldArray({
		control,
		name: 'tableItems',
	});

	const [
		submitTable,
		{
			data: createdTableData,
			loading: createTableLoading,
			called: createTableCalled,
			error: createTableError,
		},
	] = useSubmitTableMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/table`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
		},
	});

	const {
		data: { getTable } = { getTable: [] },
		loading: tableLoading,
	} = useGetTableQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'sequence', isActive: true },
		onCompleted: (data: any) => {
			let temp = [];
			for (const v of data?.getTable) {
				temp.push({
					outletID: v.outletID,
					ID: v.ID,
					seatCount: v.seatCount,
					prefix: v.prefix,
					tableNumber: v.tableNumber,
					sequence: v.sequence,
				});
			}
			append(temp);
		},
	});

	const onSubmit = async (data: any) => {
		const tables: any[] = data?.tableItems;
		const finalInput = tables?.map((v: any, index) => {
			return {
				outletID: outletID,
				ID: v?.ID,
				prefix: v?.prefix,
				tableNumber: v?.tableNumber,
				seatCount: parseFloat(v?.seatCount),
				sequence: index + 1,
			};
		});

		submitTable({
			variables: { input: finalInput, outletID },
			refetchQueries: [
				{
					query: GetTableDocument,
					variables: {
						outletID: outletID,
						orderByAsc: 'sequence',
						isActive: true,
					},
				},
			],
		});
	};

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const [compare, setCompare] = useState('');

	const compareData = (a, b) => {
		const tryData = fields?.map(v => v?.prefix?.concat(v?.tableNumber));

		if (tryData.includes(a.concat(b))) {
			return true;
		} else {
			return false;
		}
	};

	return (
		<>
			{tableLoading && <Loading />}
			{outletLoading && <Loading />}
			{createTableLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/table`,
					})
				}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Table', current: true },
				]}
				rightRouteSegments={[{ name: 'Edit' }]}
			/>

			<ContentWrapper
				tableHeader
				style={{
					marginTop: isDesktop ? '84px' : '-3px',
					padding: '8px 8px 0px',
					top: isDesktop ? '65px' : '65px',
				}}
			>
				<CardContents>
					<Grid container>
						<Grid item xs={4} className="left">
							<div className="content-wrap full">
								<div style={{ padding: '3px' }} className="xsTitle">
									Prefix
								</div>
							</div>
						</Grid>
						<Grid item xs={4} className="left">
							<div className="content-wrap full">
								<div style={{ padding: '3px' }} className="xsTitle">
									Table No.
								</div>
							</div>
						</Grid>

						<Grid item xs={3} className="left">
							<div className="content-wrap full">
								<div style={{ padding: '3px' }} className="xsTitle">
									Max Seating
								</div>
							</div>
						</Grid>
						<Grid item xs={1} className="left">
							<AddBox
								style={{ color: '#ff9800' }}
								onClick={() => {
									prepend({
										ID: '00000000-0000-0000-0000-000000000000',
										prefix: '',
										tableNumber: '',
										seatCount: '',
									});
								}}
							/>
						</Grid>
					</Grid>
				</CardContents>
			</ContentWrapper>

			<ContentWrapper
				float
				style={{
					marginTop: isDesktop ? '100px' : '40px',
				}}
			>
				<div className="table-form">
					<CardContents>
						{fields?.map((v, index) => {
							const tableItems = `tableItems[${index}]`;
							return (
								<>
									<div className="table">
										<ListItem key={v?.id}>
											<Grid container>
												<Controller
													render={({ onChange, onBlur, value }) => {
														return null;
													}}
													name={`${tableItems}.ID`}
													key={v?.ID}
													value={v?.ID}
													required
													ref={register}
													control={control}
													defaultValue={v?.ID}
												/>
												<Grid item xs={4} className="left">
													<span
														className="xxTitle fw-medium"
														style={{ color: '#ff9800' }}
														onChange={(f: any) => {
															setCompare(f.target.value);
															if (f.target.value) {
																if (
																	(watch(
																		`${tableItems}.tableNumber`,
																	)?.toString()?.length ?? 0) +
																		(watch(`${tableItems}.prefix`)?.toString()
																			?.length ?? 0) >
																	6
																) {
																	setOpenSnackBar(true);
																	setSnackBarMsg(
																		'Exceed maximum name combination',
																	);
																	setError(`${tableItems}.prefix`, {
																		type: 'manual',
																		message: '',
																	});
																} else {
																	clearErrors(`${tableItems}.prefix`);
																}
															} else {
																clearErrors(`${tableItems}.prefix`);
																setOpenSnackBar(false);
															}
														}}
													>
														<Controller
															as={TextField}
															name={`${tableItems}.prefix`}
															autoComplete="off"
															key={v?.ID}
															required
															fullWidth
															ref={register}
															control={control}
															defaultValue={v?.prefix}
															variant="outlined"
															className="p-l-0 qty-input color-primary-orange helper"
															style={{ width: '70px' }}
															error={
																errors?.tableItems &&
																errors?.tableItems[index]?.prefix
																	? true
																	: false
															}
															helperText={
																errors?.tableItems &&
																errors?.tableItems[index]?.prefix?.message
															}
														/>
													</span>
												</Grid>
												<Grid item xs={4} className="left">
													<div className="content-wrap full">
														<div className="xsTitle">
															<span
																className="xxTitle fw-medium"
																style={{ color: '#ff9800' }}
																onChange={(e: any) => {
																	if (e.target.value) {
																		if (
																			compareData(compare, e.target.value) ===
																			true
																		) {
																			setOpenSnackBar(true);
																			setSnackBarMsg('Duplicate Table Number');
																			setError(`${tableItems}.tableNumber`, {
																				type: 'manual',
																				message: '',
																			});
																		} else if (
																			(watch(
																				`${tableItems}.tableNumber`,
																			)?.toString()?.length ?? 0) +
																				(watch(
																					`${tableItems}.prefix`,
																				)?.toString()?.length ?? 0) >
																			6
																		) {
																			setOpenSnackBar(true);
																			setSnackBarMsg(
																				'Exceed maximum name combination',
																			);
																			setError(`${tableItems}.tableNumber`, {
																				type: 'manual',
																				message: '',
																			});
																		} else {
																			clearErrors(`${tableItems}.tableNumber`);
																		}
																	} else if (!e.target.value) {
																		clearErrors(`${tableItems}.tableNumber`);
																		setOpenSnackBar(false);
																	}
																}}
															>
																<Controller
																	as={TextField}
																	name={`${tableItems}.tableNumber`}
																	autoComplete="off"
																	// type="number"
																	className="p-l-0 qty-input helper"
																	key={v?.ID}
																	required
																	fullWidth
																	ref={register}
																	control={control}
																	defaultValue={v?.tableNumber}
																	variant="outlined"
																	style={{ width: '70px' }}
																	error={
																		errors?.tableItems &&
																		errors?.tableItems[index]?.tableNumber
																			? true
																			: false
																	}
																	helperText={
																		errors?.tableItems &&
																		errors?.tableItems[index]?.tableNumber
																			?.message
																	}
																/>
															</span>
														</div>
													</div>
												</Grid>

												<Grid item xs={3} className="right">
													<div className="content-wrap full">
														<div className="xsTitle">
															<span
																className="xxTitle fw-medium"
																style={{ color: '#ff9800' }}
															>
																<Controller
																	as={TextField}
																	name={`${tableItems}.seatCount`}
																	autoComplete="off"
																	type="number"
																	className="p-l-0 qty-input helper"
																	key={v?.ID}
																	required
																	fullWidth
																	ref={register}
																	control={control}
																	defaultValue={v?.seatCount}
																	variant="outlined"
																	style={{ width: '70px' }}
																	error={
																		errors?.tableItems &&
																		errors?.tableItems[index]?.seatCount
																			? true
																			: false
																	}
																	helperText={
																		errors?.tableItems &&
																		errors?.tableItems[index]?.seatCount
																			?.message
																	}
																/>
															</span>
														</div>
													</div>
												</Grid>
												<Grid item xs={1} className="delete-icon ">
													<IndeterminateCheckBox
														style={{ color: 'red' }}
														onClick={() => {
															remove(index);
														}}
													/>
												</Grid>
											</Grid>
										</ListItem>
									</div>
								</>
							);
						})}
					</CardContents>
				</div>
			</ContentWrapper>

			<DynamicFooter
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				options={[
					{
						name: 'Save',
						color:
							Object?.keys(errors)?.length > 0 && isSubmitting
								? 'grey'
								: 'primary',
						disabled: Object?.keys(errors)?.length > 0 && isSubmitting,

						onClick: () => {
							!isSubmitting && handleSubmit(onSubmit)();
						},
					},
				]}
			/>

			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/outlet-setting/${outletID}/table`}
			/>
		</>
	);
};
