import React from 'react';
import { BillItemDiscount } from './BillItemDiscount';

const billItemDiscountRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/order/:orderID/item-discount`,
		},
		component: <BillItemDiscount mode="dineIn" />,
	},

	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/order/:orderID/item-discount/takeaway`,
		},
		component: <BillItemDiscount mode="takeAway" />,
	},
];

export default billItemDiscountRoutes;
