import React from 'react';
import { RolesDetail } from './RolesDetail';
import { RolesForm } from './RolesForm';
import { RolesList } from './RolesList';

const roleRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/user-access/role`,
		},
		component: <RolesList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/user-access/role/:menuID/detail`,
		},
		component: <RolesDetail />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/user-access/role/add`,
		},
		component: <RolesForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/user-access/role/:menuID/edit`,
		},
		component: <RolesForm mode="edit" />,
	},
];

export default roleRoutes;
