import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Grid, Switch, useMediaQuery } from '@material-ui/core';
import Settings from '@material-ui/icons/Settings';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetOutletCustomerPolicyDocument,
	OrderStatus,
	useGetOrderLazyQuery,
	useGetOutletCustomerPolicyQuery,
	useGetOutletQuery,
	useSubmitOutletCustomerPolicyMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
interface CustomerPolicyProps {
	selfOrder: boolean;
	selfPayment: boolean;
	qrCodeGenerate: boolean;
}

export const CustomerPolicySetupForm = () => {
	const history = useHistory();

	const { outletID } = useParams<any>();
	const [selfOrder, setSelfOrder] = useState(false);
	const [selfPayment, setSelfPayment] = useState(false);
	const [qrCodeGenerate, setQrCodeGenerate] = useState(false);
	const [openExitDialog, setOpenExitDialog] = useState(false);

	const { globalState }: any = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const { handleSubmit, watch } = useForm<CustomerPolicyProps>({
		defaultValues: {},
		mode: 'onSubmit',
	});

	// query
	const {
		loading: getOutletCustomerPolicyLoading,
		data: { getOutletCustomerPolicy } = { getOutletCustomerPolicy: [] },
	} = useGetOutletCustomerPolicyQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
		onCompleted: () => {
			loadOrder({
				variables: {
					outletID: outletID,
					status: OrderStatus.Open,
				},
			});
		},
	});

	const [
		loadOrder,
		{ loading: getOrderLoading, data: { getOrder } = { getOrder: [] } },
	] = useGetOrderLazyQuery({
		onCompleted: () => {
			setSelfOrder(getOutletCustomerPolicy[0]?.selfOrder);
			setSelfPayment(getOutletCustomerPolicy[0]?.selfPayment);
			setQrCodeGenerate(getOutletCustomerPolicy[0]?.qrCodeGenerate);
		},
	});

	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	// mutation
	const [
		submitCustomerPolicy,
		{ loading: submitCustomerPolicyLoading },
	] = useSubmitOutletCustomerPolicyMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: () => {
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/outlet-policy`,
				});
			}, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	// functions
	const handleSwitch = (e: any, field: string) => {
		if (e.target.checked) {
			if (field === 'selfOrder') {
				setSelfOrder(true);
			} else if (field === 'selfPayment') {
				setSelfPayment(true);
			} else if (field === 'qrCodeGenerate') {
				setQrCodeGenerate(true);
			}
		} else {
			if (field === 'selfOrder') {
				setSelfOrder(false);
				setSelfPayment(false);
			} else if (field === 'selfPayment') {
				setSelfPayment(false);
			} else if (field === 'qrCodeGenerate') {
				setQrCodeGenerate(false);
			}
		}
	};

	const onSubmit = async () => {
		submitCustomerPolicy({
			variables: {
				outletCustomerPolicyInput: {
					selfOrder: selfOrder,
					selfPayment: selfPayment,
					qrCodeGenerate: qrCodeGenerate,
					outletID: outletID,
				},
			},
			refetchQueries: [
				{ query: GetOutletCustomerPolicyDocument, variables: { outletID } },
			],
		});
	};

	const tempData = [
		{ name: 'Allow Self Ordering', field: 'selfOrder' },
		{ name: 'Allow Self Payment', field: 'selfPayment' },
		{ name: 'Allow QR Code Generation', field: 'qrCodeGenerate' },
	];

	return (
		<>
			{getOutletCustomerPolicyLoading && <Loading />}
			{outletLoading && <Loading />}
			{submitCustomerPolicyLoading && <Loading />}
			{getOrderLoading && <Loading />}
			{getOutletCustomerPolicyLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Outlet Policy', current: true },
				]}
			/>

			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<ContentWrapper>
					<CardContents devFullWidth={true}>
						{!getOutletCustomerPolicyLoading && (
							<>
								<div className="smTitle" style={{ padding: '5px 10px' }}>
									Customer Policy
								</div>
								<Grid container>
									{tempData?.map(el => {
										return (
											<>
												<Grid
													item
													xs={1}
													style={{
														alignSelf: 'center',
														textAlign: 'center',
														paddingTop: '4px',
													}}
												>
													<Settings style={{ color: '#ff9800' }} />
												</Grid>
												<Grid
													item
													xs={8}
													className="mdDesc"
													style={{
														alignSelf: 'center',
														paddingLeft: '8px',
													}}
												>
													{el?.name}
												</Grid>
												<Grid item xs={3} style={{ textAlign: 'right' }}>
													<Switch
														checked={
															el?.field === 'selfOrder'
																? selfOrder
																: el?.field === 'selfPayment'
																? selfPayment
																: qrCodeGenerate
														}
														onChange={event => {
															handleSwitch(event, el?.field);
														}}
														name={el?.field}
														color="primary"
														disabled={
															(el?.field === 'selfPayment' && !selfOrder) ||
															getOrder?.length !== 0
														}
													/>
												</Grid>
											</>
										);
									})}
								</Grid>
							</>
						)}
					</CardContents>
				</ContentWrapper>

				<DynamicFooter
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					options={[
						{
							name: 'Save',
							color: 'primary',
							props: {
								type: 'submit',
							},
						},
					]}
				/>

				<ExitConfirmationDialog
					openExitDialog={openExitDialog}
					setOpenExitDialog={setOpenExitDialog}
					backPath={`/menu/outlet-app/outlet-setting/${outletID}/outlet-policy`}
				/>
			</form>
		</>
	);
};
