import {
  IconButton,
  InputAdornment,
  makeStyles,
  TextField,
} from '@material-ui/core'
import { Mic } from '@material-ui/icons'
import { createStyles } from '@material-ui/styles'
import React, { useEffect } from 'react'
import { Controller } from 'react-hook-form'
import SpeechRecognition, {
  useSpeechRecognition,
} from 'react-speech-recognition'

export const VoiceTextField = ({
  mounted,
  label,
  name,
  required,
  setValue,
  audioInput,
  setAudioInput,
  openDialog,
  record,
  setRecord,
  helperTextProps,
  control,
  register,
  clearErrors,
}: any) => {
  const { transcript, listening, resetTranscript } = useSpeechRecognition()
  useEffect(() => {
    if ((!transcript || transcript !== '') && record) {
      setAudioInput(transcript)
    }
  }, [transcript])

  useEffect(() => {
    if (record) {
      resetTranscript()
      SpeechRecognition.startListening({ continuous: true })
    } else {
      SpeechRecognition.stopListening()
    }
  }, [record])

  useEffect(() => {
    if (!openDialog && listening) {
      SpeechRecognition.stopListening()
      setRecord(false)
    }
  }, [openDialog])

  const useStyles = makeStyles(theme =>
    createStyles({
      root: {
        '& label': {
          fontSize: '11px',
          overflowWrap: 'break-word',
        },

        '& label.Mui-focused': {
          color: 'orange',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: 'orange',
          // border: 'solid',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'orange',
            // border: 'solid',
          },
          '&:hover fieldset': {
            borderColor: 'orange',
            //     // border: 'solid',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'orange',
            // border: 'solid',
          },
        },
      },
    })
  )
  const classes = useStyles()

  if (mounted) {
    return (
      <Controller
        render={({ onChange, value }) => (
          <TextField
            classes={{ root: classes.root }}
            margin="normal"
            style={{
              width: '100%',
              borderTopWidth: '100px',
              height: '65px',
              flexFlow: 'flex-end',
              borderWidth: '2px',
              //border: 'solid',
              // borderColor: 'orange',
            }}
            id="stardard-basic"
            label={label}
            color="primary"
            {...helperTextProps}
            multiline
            variant="outlined"
            required={required}
            value={audioInput}
            // defaultValue={audioInput}
            onChange={e => {
              // onChange(e)
              setAudioInput(e?.target?.value)
              // handleCustomOnChange
            }}
            InputProps={{
              endAdornment: (
                <InputAdornment position="start">
                  <IconButton
                    style={{
                      paddingRight: '0px',
                      contain: 'size',
                      color: 'orange',
                    }}
                    onClick={() => {
                      if (listening) {
                        setRecord(false)
                      } else {
                        setRecord(true)
                      }
                    }}
                  >
                    <div style={{ color: listening ? 'red' : null }}>
                      <Mic />
                    </div>
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        )}
        name={name}
        control={control}
        ref={register}
        fullWidth
      />
    )
  } else return null
}
