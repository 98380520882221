import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import { MainHeader } from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Checkbox,
	Divider,
	FormControl,
	Grid,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetRemainingMenuItemsForPromoDocument,
	PatronClass,
	useCreatePromoMenuItemPriceMutation,
	useGetOutletQuery,
	useGetPatronQuery,
	useGetPatronTypeQuery,
	useGetRemainingMenuItemsForPromoQuery,
	usePromoMenuItemCheckingLazyQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

interface PatronPrice {
	patronPrice: any;
}

export const PromotionalMenuPricingForm = props => {
	useEffect(() => {
		console.log('PromotionalMenuPricingForm');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	const location = useLocation();
	const { mode } = props;
	const editData: any = location?.state as any;

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { globalState, dispatch } = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});
	const [openExitDialog, setOpenExitDialog] = useState(false);

	const {
		[`outletID`]: outletID,
		promotionalMenuID,
		promoMenuItemID,
		patronTypeID,
		menuItemID,
	} = useParams<Record<string, any>>();

	const PriceSchema = yup.object().shape({
		patronPrice: yup
			.array()
			.of(
				yup.object().shape({
					selected: yup.boolean(),
					patronInfo: yup.array().of(
						yup.lazy(details => {
							return yup.object().shape(
								Object.fromEntries(
									Object.keys(details).map(key => {
										return [
											key,
											yup
												.number()
												.transform(value => (isNaN(value) ? 0 : value))
												.required('Required field!'),
										];
									}),
								),
							);
						}),
					),
				}),
			)
			.compact(v => !v?.selected),
	});

	const {
		control,
		register,
		handleSubmit,
		watch,
		setValue,
		errors,
		formState: { isSubmitted, isSubmitting, isValidating },
	} = useForm<PatronPrice>({
		defaultValues: {
			patronPrice: [],
		},
		mode: 'onSubmit',
		resolver: yupResolver(PriceSchema),
	});

	const { anchorEl, handleClick, handleClose } = useMenuOption();
	const [state, setState] = useState({
		blacklisted: true,
	});

	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS

	const {
		data: { getMenuItemForPromoMenuItemPrice } = {
			getMenuItemForPromoMenuItemPrice: [],
		},
		loading: menuItemForPromoMenuItemPriceLoading,
	} = useGetRemainingMenuItemsForPromoQuery({
		variables: {
			promoMenuID: promotionalMenuID,
			outletID: outletID,
		},
	});

	const {
		data: { getPatron } = { getPatron: [] },
		loading: patronLoading,
	} = useGetPatronQuery({
		variables: {
			outletID: outletID,
			isActive: true,
		},
	});

	const {
		data: { getPatronType } = { getPatronType: [] },
		loading: patronTypeLoading,
	} = useGetPatronTypeQuery();

	const standardPatronTypeID = getPatronType?.filter(
		type => type?.patronClass === PatronClass.Visitor,
	)[0]?.ID;

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const [input, setInput] = useState(null);
	const [openExistDialog, setOpenExistDialog] = useState(false);
	const [
		promoMenuItemCheck,
		{
			called: promoMenuItemCheckingCalled,
			loading: promoMenuItemCheckingLoading,
			error: promoMenuItemCheckingError,
			data: { promoMenuItemChecking } = { promoMenuItemChecking: [] },
		},
	] = usePromoMenuItemCheckingLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ promoMenuItemChecking }) => {
			// if (promoMenuItemChecking?.length === 0) {
			// 	submitPromoMenuItemPrice({
			// 		variables: {
			// 			...input,
			// 		},
			// 		refetchQueries: [
			// 			{
			// 				query: GetRemainingMenuItemsForPromoDocument,
			// 				variables: {
			// 					promoMenuID: promotionalMenuID,
			// 					outletID: outletID,
			// 				},
			// 			},
			// 		],
			// 	});
			// } else {
			// 	// alert('Menu item promo already exist');
			// 	setOpenExistDialog(true);
			// }
		},
	});

	console.log('promoMenuItemChecking', promoMenuItemChecking);
	const outletArray: any[] = getOutlet;

	const [
		submitPromoMenuItemPrice,
		{ data: createdPromoMenuData, loading: submitPromoMenuItemPriceLoading },
	] = useCreatePromoMenuItemPriceMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/promotional-menu/${promotionalMenuID}/edit/promotional-menu-pricing`,
					state: { success: true, msgMode: 'create' },
				});
				//history.goBack();
			}, 500);
		},
	});

	//COMPONENTS
	//COMPONENTS
	//COMPONENTS
	//COMPONENTS
	//COMPONENTS

	const patron_content_outlined = (
		data: any,
		name: any,
		register: any,
		control: any,
		styleJson?: any,
		itemIndex?: number,
	) => {
		return (
			<Grid container>
				{data.map((v: any, i: number) => (
					<Grid item xs={3}>
						<Grid item xs={12}>
							<div className="desc text-noflow" style={{ ...styleJson }}>
								{v.patronClass}
							</div>
							<div
								className="desc custom-div-add form-patron-price"
								style={{ marginRight: '4px' }}
							>
								{/* <TextField
								variant={'outlined'}
								className="custom-add-multiple"
								style={{ width: '80px' }}
							/> */}
								<Controller
									render={({ onChange, onBlur, value }) => (
										<NumberFormat
											customInput={TextField}
											allowNegative={false}
											fullWidth
											autoComplete="off"
											isNumericString
											decimalScale={2}
											fixedDecimalScale
											thousandSeparator
											variant="outlined"
											required
											className="custom-add-multiple"
											disabled={
												!watch(`${name}.selected`) === false ? false : true
											}
											onValueChange={(value: any) => {
												const { floatValue } = value;
												onChange(floatValue);
											}}
										/>
									)}
									name={`${name}.patronInfo[${i + 1}].${v?.ID}`}
									control={control}
									ref={register}
									className="desc custom-add-multiple"
									required
									helperText={
										errors?.patronPrice?.[itemIndex]?.patronInfo?.[i + 1]?.[
											v?.ID
										]?.message
									}
									error={
										errors?.patronPrice?.[itemIndex]?.patronInfo?.[i + 1]?.[
											v?.ID
										]
									}
								/>
							</div>
						</Grid>
					</Grid>
				))}
			</Grid>
		);
	};

	//ON SUBMIT
	//ON SUBMIT
	//ON SUBMIT
	//ON SUBMIT
	//ON SUBMIT

	const onSubmit = (data: any) => {
		let createInput = {
			promoMenuID: promotionalMenuID,
			outletID: outletID,
			promoMenuItemInput: [],
		};

		let promoMenuItemInput = data?.patronPrice
			?.filter(k => k.selected)
			.map((v, i) => {
				if (v.selected) {
					return {
						menuItemID: v.menuItemID,
						promoMenuItemPriceInput: v?.patronInfo?.map((x, index) => {
							let key = Object.keys(x)[0];
							return {
								patronID: key,
								price: parseFloat(x[key]),
							};
						}),
					};
				}
			});

		createInput.promoMenuItemInput = promoMenuItemInput;
		setInput(createInput);
		promoMenuItemCheck({
			variables: {
				promoMenuID: promotionalMenuID,
				menuItemIDs: data?.patronPrice
					?.filter(k => k.selected)
					.map((v, i) => v.menuItemID),
			},
		});
		submitPromoMenuItemPrice({
			variables: {
				...createInput,
			},
			refetchQueries: [
				{
					query: GetRemainingMenuItemsForPromoDocument,
					variables: {
						promoMenuID: promotionalMenuID,
						outletID: outletID,
					},
				},
			],
		});
	};

	return (
		<>
			{menuItemForPromoMenuItemPriceLoading && <Loading />}
			{patronLoading && <Loading />}
			{patronTypeLoading && <Loading />}
			{outletLoading && <Loading />}
			{promoMenuItemCheckingLoading && <Loading />}
			{submitPromoMenuItemPriceLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Promotional Menu & Pricing' },
					{ name: 'Promotional Menu & Pricing', current: true },
				]}
			/>
			<ContentWrapper float>
				{!menuItemForPromoMenuItemPriceLoading &&
				!patronLoading &&
				!patronTypeLoading &&
				!outletLoading &&
				(getMenuItemForPromoMenuItemPrice === undefined ||
					getMenuItemForPromoMenuItemPrice?.length === 0) ? (
					<EmptyList
						title="No Menu Item Record(s) remaining"
						subtitle="Add Menu Item(s) to proceed."
					/>
				) : (
					<>
						<MsgAlert
							message={'Select Menu Item, define display sequence and pricing.'}
						/>

						<CardContents devFullWidth={true}>
							<Grid container>
								<Grid item xs={1}></Grid>
								<Grid item xs={8} className="left">
									<div className="xxTitle" style={{ fontWeight: 'bold' }}>
										Menu Item
									</div>
								</Grid>

								{watch(`patronPrice`).some(item => item?.selected) && (
									<Grid
										item
										xs={3}
										className="right"
										style={{ textAlign: 'end' }}
									>
										<div className="xxTitle">Visitor Price</div>
									</Grid>
								)}
							</Grid>

							<Divider style={{ display: 'flex', width: '100%' }} />

							{getMenuItemForPromoMenuItemPrice.map((v: any, i: number) => {
								const patronPrice = `patronPrice[${i}]`;
								const visitorID = getPatron?.filter(
									v => v.patronClass === 'Visitor',
								)[0]?.ID;
								return (
									<Grid container>
										<Grid item xs={1}>
											<FormControl
												component="fieldset"
												style={{ paddingRight: 'revert' }}
											>
												<Checkbox
													onChange={e => {
														register(`${patronPrice}.menuItemID`);
														setValue(`${patronPrice}.menuItemID`, v?.ID);
													}}
													name={`${patronPrice}.selected`}
													inputRef={register()}
													color="primary"
												/>
											</FormControl>
										</Grid>
										<Grid item xs={8} style={{ paddingTop: '14px' }}>
											<div
												className="xsTitle left"
												style={{
													textDecorationLine: 'underline',
													fontWeight: 'bold',
												}}
											>
												{v.name}
											</div>
											<div
												className="desc text-noflow"
												style={{ padding: '6px 0px' }}
											>
												{v.description}
											</div>
										</Grid>
										{watch(`${patronPrice}.selected`) && (
											<>
												<Grid item xs={3}>
													<div
														className="desc form-visitor-price"
														style={{ color: '#ff9800', fontWeight: 'bold' }}
													>
														<Controller
															render={({ onChange, onBlur, value }) => (
																<NumberFormat
																	customInput={TextField}
																	allowNegative={false}
																	fullWidth
																	autoComplete="off"
																	isNumericString
																	decimalScale={2}
																	fixedDecimalScale
																	thousandSeparator
																	variant="outlined"
																	margin="dense"
																	disabled={
																		!watch(`${patronPrice}.selected`) === false
																			? false
																			: true
																	}
																	required
																	// ref={register}
																	className="custom-add"
																	onValueChange={(value: any) => {
																		const { floatValue } = value;
																		onChange(floatValue);
																	}}
																/>
															)}
															name={`${patronPrice}.patronInfo[0].${
																getPatron?.filter(
																	v => v.patronClass === 'Visitor',
																)[0]?.ID
															}`}
															control={control}
															ref={register}
															className="desc custom-add"
															helperText={
																errors?.patronPrice?.[i]?.patronInfo?.[0]?.[
																	visitorID
																]?.message
															}
															error={
																errors?.patronPrice?.[i]?.patronInfo?.[0]?.[
																	visitorID
																]
															}
														/>
													</div>
												</Grid>
												<Grid item xs={1}></Grid>
												<Grid item xs={11}>
													{patron_content_outlined(
														getPatron
															?.filter(x => x?.isActive)
															?.filter(
																patron => patron?.patronClass !== 'Visitor',
															),
														`${patronPrice}`,
														register,
														control,
														{
															overflowWrap: 'break-word',
															wordWrap: 'break-word',
															hyphens: 'auto',
															width: 'max-content',
														},
														i,
													)}
												</Grid>
											</>
										)}
									</Grid>
								);
							})}
						</CardContents>
					</>
				)}

				<DynamicFooter
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					options={[
						{
							name: 'Save',
							onClick: () => {
								!isSubmitting && handleSubmit(onSubmit)();
							},
							color: 'primary',
							props: {
								type: 'submit',
							},
						},
					]}
				/>
			</ContentWrapper>

			<CommonDialog
				fullWidth
				open={openExistDialog}
				onClose={() => setOpenExitDialog(true)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{`Intersecting Promotion for Menu Item`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<div className="rm-padding table-wrap sample">
								<div
									className="mdDesc text-noflow"
									style={{ paddingBottom: '10px' }}
								>
									The following item already exists in another promotion menu
								</div>
								{promoMenuItemChecking?.map(item => (
									<div>
										<span className="mdDesc">{`${item?.menuItem?.name} `}</span>
										<span className="xsTitle">
											{`- ${item?.promoMenu?.name}`}
										</span>
									</div>
								))}
							</div>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => {
										setOpenExistDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/outlet-setting/${outletID}/promotional-menu/${promotionalMenuID}/edit/promotional-menu-pricing`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push({
											pathname: `/menu/outlet-app/outlet-setting/${outletID}/promotional-menu/${promotionalMenuID}/edit/promotional-menu-pricing`,
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
