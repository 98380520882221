import React from 'react';
import { FamilyList } from './FamilyList';
import { MajorList } from './MajorList';

const majorFamilyRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/major`,
		},
		component: <MajorList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/major/:majorID/family`,
		},
		component: <FamilyList />,
	},
];

export default majorFamilyRoutes;
