import { formatDate } from 'helpers/formatDate';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import {
	Grid,
	TableContainer,
	Paper,
	Table,
	TableBody,
	TableRow,
	TableCell,
} from '@material-ui/core';
import { MonetizationOn } from '@material-ui/icons';
import {
	useGetExportedCbDetailsByTrxTypeQuery,
	useGetOutletQuery,
	useGetShareAttachmentMutation,
} from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import React, { useRef, useState } from 'react';
import { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import * as htmlToImage from 'html-to-image';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import ShareIcon from '@material-ui/icons/Share';
import { CSVLink } from 'react-csv';
import ShareDialog from 'components/Dialog/ShareDialog';

export const CashBookTransactionIndividualDetail = () => {
	useEffect(() => {
		console.log('CashBookTransactionIndividualDetail');
	}, []);

	let history = useHistory();
	let location: any = useLocation();

	let individualState = location.state;
	// console.log('individualState', individualState)

	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const outletID = localStorage.getItem('latestOutlet');

	const csvRef = useRef(null);

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);

	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const handleShare = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: outletID,
			},
		});
	};

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const handleCBExport = async () => {
		csvRef.current.link.click();
	};

	const data = [
		{
			billNo: individualState?.billNo,
			bankCode: individualState?.bankCode,
			amount: individualState?.amount,
			trxDate: individualState?.trxDate,
			description: individualState?.description,
			referenceNo: individualState?.referenceNo,
		},
	];

	const exportedCBData = data?.map(x => ({
		DocNo: x.bankCode,
		Description: x.description,
		ReferenceNo: x.referenceNo,
		amount: x.amount,
	}));

	const Display = (hidden?: boolean) => {
		return (
			<div
				style={
					hidden
						? {
								zIndex: -40,
								top: 0,
								left: 0,
								position: 'fixed',
								width: '100%',
						  }
						: {
								marginTop: '0px',
								position: 'relative',
						  }
				}
				className={hidden ? 'hidden-content' : null}
			>
				<div
					id={!hidden ? 'gl ' : 'hiddenCB'}
					style={
						hidden
							? { marginTop: '-85px', width: '100%', height: '100%' }
							: { width: '100%', height: '100%' }
					}
				>
					<DynamicSubHeader
						style={{
							marginTop: hidden ? '-155px' : null,
							width: hidden ? 'calc (100% - 0px)' : null,
						}}
						title={
							<div
								className="flex xsTitle"
								style={{ justifyContent: 'space-between' }}
							>
								<Grid container direction="row" justifyContent="space-between">
									<span className="color-primary-orange flex-space">
										{formatDate(individualState?.trxDate)}
										<TextSeparator /> {individualState?.bankCode}
									</span>
									<span className="icon-text">
										<MonetizationOn />
										{amtStr(individualState?.amount)}
									</span>
								</Grid>
							</div>
						}
						infoLine={
							<div className="flex">
								<span className="desc icon-text">{individualState.cbType}</span>
							</div>
						}
					/>
					<ContentWrapper style={{ marginTop: hidden ? '76px' : null }}>
						<div className="gl-margin-detail" />
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableBody className="core-table">
									<TableRow className="gl-export-row">
										<TableCell
											className="header-bg"
											align="center"
											style={{ borderRight: '1px solid #fff' }}
										>
											Document No
										</TableCell>
										<TableCell
											className="header-bg"
											align="center"
											style={{ borderRight: '1px solid #fff' }}
										>
											Description
										</TableCell>
										<TableCell
											className="header-bg"
											align="center"
											style={{ borderRight: '1px solid #fff' }}
										>
											Reference No
										</TableCell>
										<TableCell
											className="header-bg"
											align="center"
											style={{ borderRight: '1px solid #fff' }}
										>
											Amount
										</TableCell>
									</TableRow>

									{data?.map((x, i) => (
										<>
											<TableRow
												className="gl-export-row"
												key={i}
												style={{
													background: '#fff',
												}}
											>
												<TableCell align="center" className="mdLabel">
													{x.billNo}
												</TableCell>
												<TableCell align="center" className="mdLabel">
													{x.description}
												</TableCell>
												<TableCell align="center" className="mdLabel">
													{x.referenceNo}
												</TableCell>
												<TableCell align="center" className="mdLabel">
													{amtStr(x.amount)}
												</TableCell>
											</TableRow>
										</>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</ContentWrapper>
				</div>
			</div>
		);
	};

	return (
		<>
			{outletLoading && <Loading />}

			<MainHeader
				onClick={() => history.goBack()}
				mainBtn="back"
				smTitle="PMS"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Account Journals' },
					{ name: 'CB Transaction' },
					{ name: 'CB Transaction' },
					{ name: 'CB Transaction', current: true },
				]}
			/>

			{Display()}
			{Display(true)}
			<div className="cover-layer" />

			<CSVLink
				filename={`CB_Export_${formatDate(individualState.cbDate)}.csv`}
				data={exportedCBData}
				ref={csvRef}
			/>

			{ShareDialog({
				shareTitle: `GL Export - ${formatDate(individualState.cbDate)}`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}

			<DynamicFooter
				options={[
					{
						name: 'Share ',
						onClick: () => {
							let temp = document.getElementById('hiddenCB');
							htmlToImage
								.toBlob(temp, { style: { background: '#fff' } })
								.then(value => {
									handleShare(value);
								})
								.catch(error => console.error(error, 'Something Went Wrong'));
						},
						color: 'primary',
						props: {
							startIcon: <ShareIcon />,
						},
					},
					{
						name: 'Download',
						onClick: () => {
							handleCBExport();
						},
						color: 'primary',
						props: {
							startIcon: <ShareIcon />,
						},
					},
				]}
			/>
		</>
	);
};
