import React from 'react';
import { TaxSchemeList } from './TaxSchemeList';
import { TaxSchemeDetail } from './TaxSchemeDetail';
import { TaxSchemeForm } from './TaxSchemeForm';

const taxSchemeRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/common-setting/tax-scheme-policy`,
		},
		component: <TaxSchemeList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/common-setting/tax-scheme-policy/:taxSchemeID/detail`,
		},
		component: <TaxSchemeDetail />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/common-setting/tax-scheme-policy/add`,
		},
		component: <TaxSchemeForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/common-setting/tax-scheme-policy/:taxSchemeID/edit`,
		},
		component: <TaxSchemeForm mode="edit" />,
	},
];

export default taxSchemeRoutes;
