import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	Box,
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { Check, KeyboardArrowRight } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	PosPermission,
	useDuplicateOutletMenuMutation,
	useGetOutletQuery,
	useSyncOutletDailyMenuMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { OutletIcon } from '../../../assets';

interface DuplicateMenuProps {
	source: string;
	target: string;
}

interface SyncDailyMenuProps {
	outlet: string;
}

export const UtilityList = (props: any) => {
	const { mode } = props;
	const history = useHistory();
	const location = useLocation();
	const { globalState, dispatch } = useContext(AppContext as any);

	const outletID = localStorage.getItem('latestOutlet');

	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const [openDuplicateDialog, setOpenDuplicateDialog] = useState(false);
	const [openUpdateDialog, setOpenUpdateDialog] = useState(false);
	const editData = location?.state as any;

	const [success, setSuccess] = useState({ msg: '', bool: false });

	const { handlePermHidden } = usePermissionChecker();

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const {
		register: dupReg,
		handleSubmit: dupHandleSubmit,
		control: dupControl,
		setValue,
		errors,
		watch: dupWatch,
	} = useForm<DuplicateMenuProps>({
		defaultValues: {},
	});

	const {
		register: syncReg,
		handleSubmit: syncHandleSubmit,
		control: syncControl,
		watch: syncWatch,
	} = useForm<SyncDailyMenuProps>({
		defaultValues: {},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		// onCompleted: ({ getOutlet }) => {
		// 	setSelectedOutlet(getOutlet?.filter(outlet => outlet?.ID === outletID));
		// },
	});
	const outletArray: any[] = getOutlet;

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	useEffect(() => {
		if (getOutlet && getOutlet?.length > 0) {
			setOriginalListing(getOutlet);
		}
	}, [getOutlet]);

	const [syncOutletDailyMenu] = useSyncOutletDailyMenuMutation({
		onCompleted: ({ syncOutletDailyMenu }) => {
			setOpenDuplicateDialog(false);
			setOpenSnackBar(true);
			setSnackBarMsg(
				`${syncOutletDailyMenu ? 'Sync successful' : 'Sync failed'}`,
			);
			// setSuccess({
			// 	msg: syncOutletDailyMenu ? 'Sync successful' : 'Sync failed',
			// 	bool: true,
			// });
		},
		onError: ({ message }) => {
			setOpenSnackBar(true);
			setSnackBarMsg(message);
		},
	});

	const [duplicateOutletMenu] = useDuplicateOutletMenuMutation({
		onCompleted: ({ duplicateOutletMenu }) => {
			setOpenSnackBar(true);
			setSnackBarMsg(
				`${duplicateOutletMenu ? 'Duplicate successful' : 'Duplicate failed'}`,
			);
			// setSuccess({
			// 	msg: duplicateOutletMenu ? 'Duplicate successful' : 'Duplicate failed',
			// 	bool: true,
			// });
		},
	});

	const onSubmitSyncMenu = () => {
		syncOutletDailyMenu({
			variables: {
				outletID: selectedOutlet?.ID,
			},
		});
		setOpenUpdateDialog(false);
	};

	const onSubmitDuplicateMenu = () => {
		duplicateOutletMenu({
			variables: {
				sourceOutletID: dupWatch('sourceOutlet'),
				targetOutletID: dupWatch('targetOutlet'),
			},
		});
		setOpenDuplicateDialog(false);
	};

	const [selectedOutlet, setSelectedOutlet] = useState(null);

	const utilityList = [
		{
			action: () => {
				setOpenDuplicateDialog(true);
			},
			title: `Duplicate Menu`,
			label1: `Duplicate Menu Item from another outlet`,
			permission: PosPermission.UtilityDuplicateMenuDuplicate,
		},
		{
			action: () => {
				setOpenUpdateDialog(true);
				setSelectedOutlet(
					getOutlet?.filter(outlet => outlet?.ID === outletID)[0],
				);
			},
			title: `Update Daily Menu Item`,
			label1: `Update daily menu item for an outlet`,
			permission: PosPermission.UtilityUpdateDailyMenuItemUpdate,
		},
	];

	// setTimeout(() => {
	// 	setSuccess({ msg: 'null', bool: false });
	// }, 5000);

	// Drawer Toggler
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	return (
		<>
			{/* {success?.bool ? (
				<SnackBarMsg open={success?.bool} message={success?.msg} />
			) : null} */}
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				// customIcon={IFCALogo}
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: 'Utility' },
					{ name: 'Utility', current: true },
				]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{utilityList.map((el, index) => {
						if (
							handlePermHidden({
								outletID: outletID,
								permEnum: el.permission,
							})
						)
							return (
								<ListItem onClick={el?.action}>
									<ListItemText
										primary={
											<>
												<span className="desc">
													<span className="xsTitle">{el.title}</span>
												</span>
											</>
										}
										secondary={
											<span className="desc">
												<>
													<span className="desc">{el.label1}</span>
												</>
											</span>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete">
											<KeyboardArrowRight onClick={el.action} />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							);
					})}
				</List>
			</ContentWrapper>

			<Box mt={1}>
				<CommonDialogV2
					fullWidth={true}
					open={openDuplicateDialog}
					onClose={() => setOpenDuplicateDialog(false)}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												<div className="title">Utility</div>
											</div>
										</div>
									</div>
									<div className="infoline-content">
										{' '}
										<div className="xsTitle infoline">Duplicate Menu</div>
									</div>
								</div>
							),
						},
						body: () => (
							<>
								<Controller
									as={
										<TextField name="sourceOutlet">
											{getOutlet?.map(el => (
												<MenuItem key={el.ID} value={el.ID}>
													<span>{el.name} &nbsp;</span>
												</MenuItem>
											))}
										</TextField>
									}
									name="sourceOutlet"
									margin="normal"
									label="Source Outlet"
									fullWidth
									autoComplete="off"
									multiline={true}
									select
									control={dupControl}
									ref={dupReg}
								/>

								<Controller
									as={
										<TextField name="targetOutlet">
											{getOutlet
												?.filter(
													outlet => outlet?.ID !== dupWatch('sourceOutlet'),
												)
												?.map(el => (
													<MenuItem key={el.ID} value={el.ID}>
														<span>{el.name} &nbsp;</span>
													</MenuItem>
												))}
										</TextField>
									}
									name="targetOutlet"
									margin="normal"
									label="Target Outlet"
									fullWidth
									autoComplete="off"
									multiline={true}
									select
									control={dupControl}
									ref={dupReg}
								/>
							</>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => setOpenDuplicateDialog(false),
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										onClick: () => {
											dupHandleSubmit(onSubmitDuplicateMenu)();
										},
										variant: 'contained',
										form: 'open-form',
										color: 'primary',
										type: 'submit',
									},
								},
							],
						},
					}}
				/>
			</Box>

			<CommonDialogV2
				fullWidth={true}
				open={openUpdateDialog}
				onClose={() => setOpenUpdateDialog(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div className="title">Utility</div>
										</div>
									</div>
								</div>
								<div className="infoline-content">
									{' '}
									<div className="xsTitle infoline">Update Daily Menu Item</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className={'desc'} style={{ paddingTop: '10px' }}>
								{selectedOutlet ? 'Business Date' : null}
							</div>
							<div className={'xsTitle'} style={{ paddingBottom: '10px' }}>
								{selectedOutlet
									? formatDate(selectedOutlet?.businessDate)
									: null}
							</div>

							{getOutlet?.length > 2 ? (
								<div className="rm-padding table-wrap sample">
									<List
										className="core-list content-wrap full"
										style={{ paddingBottom: '0px' }}
									>
										<ListItem
											style={{
												boxShadow: 'none',
												padding: '8px 12px 0px 12px',
											}}
										>
											<TextField
												id="standard-basic"
												label="Search here..."
												fullWidth
												onChange={e => {
													handleSearch(`'"${e?.target?.value}"`, ['name']);
												}}
											/>
										</ListItem>
									</List>
								</div>
							) : null}

							<ContentWrapper
								style={{
									marginTop: '0px',
									width: 'inherit',
									position: 'initial',
									padding: '8px 0px 8px 0px',
								}}
							>
								<List>
									{filteredList?.map((el, index) => (
										<>
											<ListItem
												style={
													selectedOutlet
														? {
																backgroundColor:
																	el?.ID === selectedOutlet?.ID
																		? '#E5EDFF'
																		: null,
														  }
														: {
																backgroundColor:
																	el?.ID === outletID ? '#E5EDFF' : null,
														  }
												}
											>
												<div style={{ paddingRight: '6px' }}>
													{el?.logo?.fileURL ? (
														<img
															src={el?.logo?.fileURL}
															style={{
																display: 'flex',
																marginTop: '2px',
																marginBottom: '2px',
																marginRight: '4px',
																width: '40px',
																height: '40px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													) : (
														<img
															src={OutletIcon}
															style={{
																display: 'flex',
																marginTop: '7px',
																marginBottom: '8px',
																marginRight: '4px',
																width: '40px',
																height: '25px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													)}
												</div>

												<ListItemText
													primary={<span className="xsTitle">{el?.name}</span>}
													onClick={e => {
														setSelectedOutlet(
															getOutlet?.filter(
																outlet => outlet?.ID === el?.ID,
															)[0],
														);
														// history.push()
													}}
												/>

												{selectedOutlet ? (
													el?.ID === selectedOutlet?.ID ? (
														<ListItemSecondaryAction>
															<IconButton edge="end" aria-label="delete">
																<Check color="primary" />
															</IconButton>
														</ListItemSecondaryAction>
													) : null
												) : el?.ID === outletID && !selectedOutlet ? (
													<ListItemSecondaryAction>
														<IconButton edge="end" aria-label="delete">
															<Check color="primary" />
														</IconButton>
													</ListItemSecondaryAction>
												) : null}
											</ListItem>

											<Divider style={{ display: 'flex' }} />
										</>
									))}
								</List>
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => setOpenUpdateDialog(false),
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										syncHandleSubmit(onSubmitSyncMenu)();
									},
									variant: 'contained',
									form: 'open-form',
									color: 'primary',
									type: 'submit',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
