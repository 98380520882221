import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import AppContext from 'containers/App/Store/AppContext';
import React, { useContext, useEffect } from 'react';
import { TaxSetup } from '../../../assets';
import { formatDate } from 'helpers/hooks/formatDate';
import {
	PosPermission,
	useGetGeneralSettingSummaryLazyQuery,
} from 'generated/graphql';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useHistory } from 'react-router';
import { KeyboardArrowRight } from '@material-ui/icons';

const details = (description: string, highlighted: any) => {
	return (
		<span className="desc">
			{' '}
			{description} <span className="highlight-text">{highlighted}</span>
		</span>
	);
};

export const CommonSettingSubmenu = () => {
	const { globalState, dispatch }: any = useContext(AppContext as any);
	const { handlePermHidden } = usePermissionChecker();
	const history = useHistory();

	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const outletID = localStorage.getItem('latestOutlet');

	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	const [
		loadGeneralSettingSummary,
		{
			called: generalSettingSummaryCalled,
			loading: generalSettingSummaryLoading,
			data: generalSettingSummaryData,
		},
	] = useGetGeneralSettingSummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!generalSettingSummaryCalled) {
			loadGeneralSettingSummary();
		}
	}, [generalSettingSummaryCalled]);

	const taxSetupDate = generalSettingSummaryData?.taxDate;
	const taxSchemeDate = generalSettingSummaryData?.taxSchemeDate;

	const menuList = [
		{
			name: 'Tax Policy',
			icon: TaxSetup,
			description: details('Last updated on ', formatDate(taxSetupDate)),
			path: `/menu/outlet-app/common-setting/tax-policy`,
			permission: PosPermission.CommonSettingTaxPolicyShow,
		},
		{
			name: 'Tax Scheme Policy',
			icon: TaxSetup,
			description: details('Last updated on ', formatDate(taxSchemeDate)),
			path: `/menu/outlet-app/common-setting/tax-scheme-policy`,
			permission: PosPermission.CommonSettingTaxSchemePolicyShow,
		},
	];

	return (
		<>
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[{ name: 'Common Settings', current: true }]}
			/>

			<ContentWrapper>
				<List className="core-list">
					{menuList?.map(el => {
						if (
							handlePermHidden({
								outletID: outletID,
								permEnum: el?.permission,
							})
						) {
							return (
								<>
									<ListItem onClick={() => history.push(el?.path)}>
										<ListItemIcon>
											<img
												className="icon-svg"
												style={{ paddingRight: '10px' }}
												src={el?.icon}
												alt="drawer-icon"
											/>
										</ListItemIcon>

										<ListItemText
											primary={
												<>
													<span className="xsTitle flex-space">{el?.name}</span>
												</>
											}
											secondary={el?.description}
										/>

										<ListItemSecondaryAction>
											<IconButton edge="end" aria-label="delete">
												<KeyboardArrowRight
													onClick={() => history.push(el?.path)}
												/>
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								</>
							);
						}
					})}
				</List>
			</ContentWrapper>
		</>
	);
};
