import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { TextField } from '@material-ui/core';
import React from 'react';
import { Controller } from 'react-hook-form';

interface PersonProps {
	control: any;
	register: any;
	children: any;
	errors: any;
}

export const PersonDetailForm = (props: PersonProps) => {
	const { control, register, children, errors } = props;

	return (
		<>
			<ContentWrapper>
				<form>
					<CardContents devFullWidth={true}>
						<Controller
							as={TextField}
							name="name"
							label="Name"
							required
							autoComplete="off"
							control={control}
							multiline={true}
							fullWidth
							margin="normal"
							ref={register}
							helperText={errors?.name?.message}
							error={errors?.name ? true : false}
							// defaultValue={menu?.obj?.name || ''}
						/>
						<Controller
							as={TextField}
							name="designation"
							label="Designation"
							required
							autoComplete="off"
							control={control}
							multiline={true}
							fullWidth
							ref={register}
							helperText={errors?.designation?.message}
							error={errors?.designation ? true : false}
							// className="left"
							// defaultValue={menu?.obj?.designation || ''}
						/>

						{/* <div style={{ margin: '10px' }}></div> */}
						<Controller
							as={
								<PhoneNumInput
									country={'my'}
									specialLabel="Mobile No."
									fullWidth
									// inputProps={{
									// 	style: {
									// 		borderColor: errors?.mobileNo ? '#E53935' : null,
									// 	},
									// }}
								/>
							}
							// customInput={TextField}
							name="mobileNo"
							// required
							type="tel"
							marginTop
							marginBottom
							// autoComplete="off"
							margin="normal"
							control={control}
							ref={register}
							helperText={errors?.mobileNo?.message}
							error={errors?.mobileNo ? true : false}
						/>
						{/* {errors?.mobileNo && (
							<div
								style={{
									color: '#E53935',
									fontWeight: 400,
									margin: '5px 0px 20px',
								}}
								className="mdDesc"
							>
								{errors?.mobileNo?.message}
							</div>
						)} */}

						<Controller
							as={TextField}
							name="email"
							label="Email Address"
							required
							autoComplete="off"
							control={control}
							multiline={true}
							fullWidth
							helperText={errors?.email?.message}
							error={errors?.email ? true : false}
							ref={register}
							// defaultValue={menu?.obj?.email || ''}
						/>

						{children}
					</CardContents>
				</form>
			</ContentWrapper>
		</>
	);
};
