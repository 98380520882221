import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import React from 'react';
import { StandardMenuOptionItem } from './StandardMenuOptionItem';
import { StandardMenuOptionList } from './StandardMenuOptionList';

const standardMenuOptionRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/standard-menu-option`,
			//path: `/menu/outlet-app/outlet-setting/:outletID/option`,
			//path: `/menu/outlet-app/outlet-setting/:outletID/`,
		},
		component: <StandardMenuOptionList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/standard-menu-option/:optionID/standard-menu-option-item`,
		},
		component: <StandardMenuOptionItem />,
	},
];

export default standardMenuOptionRoutes;
