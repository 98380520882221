import { Button } from '@material-ui/core'
import Snackbar from '@material-ui/core/Snackbar'
import React from 'react'

interface ISnackBarMsgProps {
  message?: string
  open?: boolean
  onClose?: any
  setOpen?: React.Dispatch<boolean>
  autoHideDuration?: any
  onClickButton?: any
  label?: string
}

const action = (
  setOpenSnack: React.Dispatch<boolean>,
  onClickButton?: Function,
  label?: string
) => {
  return (
    <Button
      className="color-nav-alert"
      size="small"
      onClick={() => {
        !!onClickButton ? onClickButton() : {}
        setOpenSnack(false)
      }}
    >
      {!!label ? label : 'DISMISS'}
    </Button>
  )
}

export default function SnackBarMsg(props: ISnackBarMsgProps) {
  const {
    message,
    open,
    onClose,
    setOpen,
    autoHideDuration,
    onClickButton,
    label,
  } = props

  return (
    <Snackbar
      className="snackbar"
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      open={open}
      autoHideDuration={!!autoHideDuration ? autoHideDuration : 4000}
      onClose={
        !!onClose
          ? onClose
          : () => {
              setOpen(false)
            }
      }
      ContentProps={{
        'aria-describedby': 'message-id',
      }}
      message={
        <span id="message-id" color="secondary">
          {message}
        </span>
      }
      action={action(setOpen, onClickButton, label)}
    />
  )
}
