import Button from '@material-ui/core/Button'
import React from 'react'
import { BaseProps } from 'utils/models'

interface SearchHeaderProps {
  title: string
  action?: any
  actionTitle?: String
}

export const SubHeaderAction = (props: BaseProps<SearchHeaderProps>) => {
  const { title, action, actionTitle, ...other } = props

  return (
    <div className="action-header">
      <span className="xsTitle c-darkblue flex-space">{title}</span>

      <Button
        variant="contained"
        color="primary"
        // onClick={action}
        form="submit-form"
        className="action-btn"
        type="submit"
        {...other}
      >
        {actionTitle ? actionTitle : 'Save'}
      </Button>
    </div>
  )
}
export default SubHeaderAction
