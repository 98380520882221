import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Box, MenuItem, TextField } from '@material-ui/core';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetHomeTableSummaryDocument,
	GetOrderDocument,
	GetTableSubmenuTableDocument,
	OrderStatus,
	TableStatus,
} from 'generated/graphql';
import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';

interface TransferTableProps {
	prefix: string;
	newTableID: string;
	orderID: string;
}

export const TransferTableDialog = (props: any) => {
	//ITEM TRANSFER MUTATION
	const {
		editData,
		mode,
		tableID,
		outletID,
		transferTable,
		setTransferTable,
		changeTableID,
		setTableID,
		getTable,
		getTableLoading,
		getTableCalled,
		getOrder,
		selectedTable,
		changeTable,
		transferItemLoading,
	} = props;

	//TRANSFER TABLE USE FORM
	//TRANSFER TABLE USE FORM
	//TRANSFER TABLE USE FORM

	const { register, handleSubmit, control, setValue, errors, watch } = useForm<
		TransferTableProps
	>({
		defaultValues: {
			prefix: mode === 'add' ? '' : editData?.prefix,
			newTableID: mode === 'add' ? '' : editData?.newTableID,
			orderID: mode === 'add' ? '' : editData?.orderID,
		},
	});

	const selectionData = mode?.menuOptionItem as any;

	useEffect(() => {
		if (mode === 'edit' && selectionData?.length > 0) {
			register('newTableID');
		}
	}, [mode, selectionData]);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	//TRANSFER TABLE USE MUTATION
	//TRANSFER TABLE USE MUTATION
	//TRANSFER TABLE USE MUTATION

	// const [changeTable, { data: transferTableData }] = useTransferTableMutation({
	// 	onError: error => {
	// 		console.log('Suberror', error);
	// 	},
	// 	onCompleted: data => {
	// 		setOpenSnackBar(true);
	// 		setSnackBarMsg(SystemMsgs.transferRecord());
	// 	},
	// });

	const onSubmit = async (data: any) => {
		changeTable({
			variables: {
				newTableID: data?.newTableID,
				oldTableID: tableID,
				orderID: data?.orderID,
			},
			refetchQueries: [
				{
					query: GetOrderDocument,
					variables: { tableID: tableID, status: OrderStatus.Open },
				},
				{
					query: GetHomeTableSummaryDocument,
					variables: { tableID: tableID },
				},
				{
					query: GetTableSubmenuTableDocument,
					variables: {
						outletID: outletID,
						orderByAsc: 'sequence',
						isActive: true,
					},
				},
			],
		});
		setTransferTable(false);
	};

	const handleChange = (e: any) => {
		const name = e.target.name;
		const value = e.target.value;
		setTableID({ ...changeTableID, [name]: value });
	};

	return (
		<>
			{!getTableLoading && getTableCalled && (
				<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
					{getTableLoading && <Loading />}
					<Box mt={1}>
						<CommonDialog
							fullWidth={true}
							open={transferTable}
							onClose={() => setTransferTable(false)}
							sections={{
								header: {
									dynamic: (
										<>
											<div className="dialog-dynamic-content">
												<div className="session">
													<div className="title">Change / Transfer Table</div>
													{/* <div className="title">
													{selectedTable?.prefix}
													{selectedTable?.tableNumber}
												</div> */}
												</div>
											</div>
											<div className="infoline-content ">
												<div className="xsTitle infoline">
													{selectedTable?.prefix}
													{selectedTable?.tableNumber}
												</div>
											</div>
										</>
									),
								},
								body: () => (
									<>
										<div className="rm-padding table-wrap sample">
											{/* <List className="core-list content-wrap full">
												<ListItem> */}
											<Controller
												as={
													<TextField name="newTableID" required>
														{getOrder
															?.filter(
																x => x?.ID !== tableID,
																// &&
																// x.status == TableStatus.Available,
															)
															.map(x => (
																<MenuItem key={x.ID} value={x.ID}>
																	{x.docNo}{' '}
																	<span
																		// style={{ color: '#ff9800' }}
																		className="highlight-text"
																	>
																		{' '}
																		&nbsp;
																		{/* {x.patronAccount?.name} */}
																	</span>
																</MenuItem>
															))}
													</TextField>
												}
												name="orderID"
												margin="normal"
												label="Bill No"
												fullWidth
												autoComplete="off"
												value={changeTableID.tableNumber}
												multiline={true}
												select
												control={control}
												ref={register}
											/>
											<Controller
												as={
													<TextField name="newTableID" required>
														{getTable
															?.filter(
																x =>
																	x?.ID !== tableID &&
																	x.status == TableStatus.Available,
															)
															.map(x => (
																<MenuItem key={x.ID} value={x.ID}>
																	{x.prefix}
																	{x.tableNumber}
																</MenuItem>
															))}
													</TextField>
												}
												name="newTableID"
												margin="normal"
												label="New Table"
												fullWidth
												autoComplete="off"
												value={changeTableID.tableNumber}
												multiline={true}
												select
												control={control}
												ref={register}
											/>
											{/* </ListItem>
											</List> */}
										</div>
									</>
								),
								footer: {
									actions: [
										{
											displayText: 'Cancel',
											props: {
												onClick: () => setTransferTable(false),
												variant: 'contained',
												color: 'primary',
											},
										},
										{
											displayText: 'Confirm',
											props: {
												variant: 'contained',
												form: 'submit-form',
												// color: 'primary',
												type: 'submit',
												color:
													watch('newTableID') === undefined ||
													watch('orderID') === undefined ||
													transferItemLoading
														? 'inherit'
														: 'primary',
												disabled:
													watch('newTableID') === undefined ||
													watch('orderID') === undefined ||
													transferItemLoading
														? true
														: false,
											},
										},
									],
								},
							}}
						/>
					</Box>
				</form>
			)}
		</>
	);
};
