import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	MenuItem,
	Radio,
	RadioGroup,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CalcType,
	DiscountType,
	GetDiscountDocument,
	useCreateDiscountMutation,
	useGetDiscountQuery,
	useGetOutletQuery,
	useUpdateDiscountMutation,
} from 'generated/graphql';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

interface DiscountThing {
	code: string;
	description: string;
	amount: number;
	calcType: CalcType;
	discountType: DiscountType;
}

export const DiscountTypeForm = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { outletID }: any = useParams();
	const { discountID }: any = useParams();
	const { mode } = props;
	let location = useLocation();
	let formMode = mode === 'add' ? 'New' : 'Edit';

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { globalState, dispatch } = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const [OutletDiscount, setOutletDiscount] = useState({
		code: '',
		description: '',
		discountType: '',
		amount: 0,
		calcType: '',
	});

	const [amountNum, setAmount] = useState(0);

	const editData: any = location?.state as any;

	const modApp = mode === 'approve-reject';

	const { data, called: discountCalled } = useGetDiscountQuery();

	const yupSchema = yup.object().shape({
		code: CommonYupValidation?.requireField(SystemMsgs.title()),
		description: CommonYupValidation?.requireField(SystemMsgs.description()),
		amount: yup
			.string()
			.when('calcType', {
				is: 'PERCENTAGE',
				then: yup.string().required('Percentage is required'),
			})
			.when('calcType', {
				is: 'AMOUNT',
				then: yup.string().required('Amount is required'),
			})
			.nullable(),
	});

	const {
		handleSubmit,
		register,
		setValue,
		watch,
		errors,
		control,
		getValues,
	} = useForm<DiscountThing>({
		mode: 'all',
		defaultValues: {
			code: editData ? editData?.code : '',
			description: editData ? editData?.description : '',
			calcType: editData ? editData?.calcType : '',
			discountType: editData ? editData?.discountType : '',
			amount: editData ? editData?.amount : '',
		},
		resolver: yupResolver(yupSchema),
	});

	const [
		submitDiscount,
		{ data: createdDiscountData },
	] = useCreateDiscountMutation({
		fetchPolicy: 'no-cache',
		// variables: { ID: outletID },
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const handleChange = (e: any) => {
		const name = e.target.name;
		const value = e.target.value;
		setOutletDiscount({ ...OutletDiscount, [name]: value });
	};

	const withValueLimit = inputObj => {
		const { value } = inputObj;
		if (watch('calcType') === CalcType.Percentage && value <= 100) {
			return inputObj;
		} else if (watch('calcType') === CalcType.Amount && value > 0) {
			return inputObj;
		}
	};
	const [
		createDiscount,
		{ loading: createDiscountLoading, error, called: createDiscountCalled },
	] = useCreateDiscountMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/discount-type`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
		},
	});

	const [
		updateDiscount,
		{ called: updateDiscountCalled, loading: updateDiscountLoading },
	] = useUpdateDiscountMutation({
		onError: error => {},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/discount-type`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
		},
	});

	const createUpdateCalled = editData
		? updateDiscountCalled
		: createDiscountCalled;

	const onSubmit = (data: any) => {
		if (mode == 'add') {
			//add the data with GraphQL mutation
			createDiscount({
				variables: {
					input: {
						outletID: outletID,
						code: data.code,
						description: data.description,
						amount: parseFloat(data.amount),
						calcType: data.calcType,
						discountType: data.discountType,
					},
				},
				refetchQueries: [
					{ query: GetDiscountDocument, variables: { outletID } },
				],
			});
		} else if (mode === 'edit') {
			//update the data

			updateDiscount({
				variables: {
					input: {
						ID: editData?.ID,
						outletID: outletID,

						code: data.code,
						description: data.description,
						amount: parseFloat(data.amount),
						calcType: data.calcType,
						discountType: data.discountType,
					},
				},
				refetchQueries: [
					{ query: GetDiscountDocument, variables: { outletID } },
				],
			});
		}
	};

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const watchValue = watch('amount') != 0 ? watch('amount') : 0;

	console.log('aaa', DiscountType.OfficerCheck);

	const discountTypeRename = data => {
		let discountType;
		if (data === DiscountType.OfficerCheck) {
			discountType = 'Officer Check';
		} else if (data === DiscountType.Discount) {
			discountType = 'Discount';
		} else if (data === DiscountType.Entertainment) {
			discountType = 'Entertainment';
		} else if (data === DiscountType.Promo) {
			discountType = 'Promo';
		}

		return discountType;
	};

	const [openExitDialog, setOpenExitDialog] = useState(false);
	return (
		<>
			{createDiscountLoading && <Loading />}
			{updateDiscountLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Discount Type', current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<ContentWrapper footer>
					<CardContents devFullWidth={true}>
						<Controller
							as={TextField}
							name="code"
							label="Discount Title"
							// required
							autoComplete="off"
							multiline={true}
							margin="normal"
							value={OutletDiscount.code}
							onChange={handleChange}
							control={control}
							ref={register}
							helperText={errors?.code?.message}
							error={!!errors?.code ? true : false}
							required
						/>

						<Controller
							as={TextField}
							name="description"
							label="Description"
							// required
							autoComplete="off"
							multiline={true}
							margin="normal"
							value={OutletDiscount.description}
							onChange={handleChange}
							control={control}
							ref={register}
							helperText={errors?.description?.message}
							error={!!errors?.description ? true : false}
							required
						/>

						<Controller
							as={
								<TextField
									name="discountType"
									defaultValue={editData?.discountType}
								>
									{Object.values(DiscountType)?.map(x => (
										<MenuItem key={x} value={x}>
											{discountTypeRename(x)}
										</MenuItem>
									))}
								</TextField>
							}
							name="discountType"
							margin="normal"
							label="Discount Type"
							fullWidth
							autoComplete="off"
							multiline={true}
							select
							control={control}
							ref={register}
							required
							helperText={errors?.discountType?.message}
							error={errors?.discountType ? true : false}
							// inputRef={register()}
							// defaultValue={editData?.familyID}
						/>

						<FormControl component="fieldset" margin="dense" fullWidth required>
							<Controller
								as={
									<RadioGroup aria-label="VOType">
										<Grid container>
											<Grid item xs={6} className="right">
												<FormControlLabel
													value={CalcType.Percentage}
													name="calcType"
													disabled={mode === 'approve-reject'}
													control={<Radio color="primary" />}
													label="Percentage"
													// ref={register()}
													onChange={() => setValue('amount', 0)}
												/>
											</Grid>
											<Grid item xs={6} className="left">
												<FormControlLabel
													value={CalcType.Amount}
													name="calcType"
													disabled={mode === 'approve-reject'}
													control={<Radio color="primary" />}
													label="Amount"
													// ref={register()}
													onChange={() => setValue('amount', 0)}
												/>
											</Grid>
										</Grid>
									</RadioGroup>
								}
								name="calcType"
								control={control}
								ref={register}
								helperText={errors?.calcType?.message}
								error={errors?.calcType ? true : false}
							/>
							<FormHelperText error={errors?.calcType ? true : false}>
								{errors?.calcType?.message}
							</FormHelperText>
						</FormControl>

						{/* {watch('calcType') && (
							<Controller
								render={({ onChange, onBlur, value }) => (
									<NumberFormat
										allowNegative={false}
										thousandSeparator
										customInput={TextField}
										onValueChange={(value: any) => {
											const { floatValue } = value;
											onChange(floatValue);
										}}
										decimalScale={
											watch('calcType') === CalcType.Amount ? 2 : ''
										}
										label={
											watch('calcType') === CalcType.Amount
												? 'Amount'
												: 'Percentage (%)'
										}
									/>
								)}
								id="standard-basic"
								name="amount"
								helperText={errors?.amount?.message}
								error={errors?.amount ? true : false}
								autoComplete="off"
								control={control}
								fixedDecimalScale
								margin="dense"
								required
                // value={OutletDiscount.amount}
							  // onChange={handleChange}
                // defaultValue={editData ? editData?.description : ''}
								ref={register}
								disabled={formMode === 'approve-reject' ? true : false}
							/>
						)} */}

						{watch('calcType') && (
							<Controller
								as={
									<NumberFormat
										customInput={TextField}
										allowNegative={false}
										fullWidth
										autoComplete="off"
										isNumericString
										decimalScale={
											watch('calcType') === CalcType.Amount ? 2 : null
										}
										fixedDecimalScale
										onValueChange={(value: any) => {
											const { floatValue } = value;
										}}
									/>
								}
								name="amount"
								label={
									watch('calcType') === CalcType.Amount
										? 'Amount'
										: 'Percentage (%)'
								}
								control={control}
								ref={register}
								defaultValue={editData ? editData?.amount : ''}
							/>
						)}
					</CardContents>
				</ContentWrapper>
			</form>
			<DynamicFooter
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				options={[
					{
						name: 'Save',
						color: 'primary',
						props: {
							color:
								watchValue === 0 || watchValue === undefined
									? 'inherit'
									: 'primary',
							type: 'submit',
							disabled: watchValue === 0 ? true : false,
						},
						onClick: () => {
							handleSubmit(data => !createUpdateCalled && onSubmit(data))();
						},
					},
				]}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/outlet-setting/${outletID}/discount-type`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push({
											pathname:
												// (!discountCalled && data?.getDiscount === undefined) ||
												// data?.getDiscount?.length === 0
												// 	? `/menu/outlet-app/outlet-setting/${outletID}`
												// 	: `/menu/outlet-app/outlet-setting/${outletID}/discount-type`,
												`/menu/outlet-app/outlet-setting/${outletID}/discount-type`,
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
