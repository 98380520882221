export const countryCurrencyCodes = {
	AED: `United Arab Emirates (the)`,
	AFN: `Afghanistan`,
	ALL: `Albania`,
	AMD: `Armenia`,
	ANG: `Curaçao`,
	AOA: `Angola`,
	ARS: `Argentina`,
	AUD: `Australia, Christmas Island, Cocos (Keeling) Islands (the), Heard Island and McDonald Islands, Kiribati, Nauru, Norfolk Island, Tuvalu`,
	AWG: `Aruba`,
	AZN: `Azerbaijan`,
	BAM: `Bosnia and Herzegovina`,
	BBD: `Barbados`,
	BDT: `Bangladesh`,
	BGN: `Bulgaria`,
	BHD: `Bahrain`,
	BIF: `Burundi`,
	BMD: `Bermuda`,
	BND: `Brunei`,
	BOB: `Bolivia (Plurinational State of)`,
	BRL: `Brazil`,
	BSD: `Bahamas (the)`,
	BTN: `Bhutan`,
	BWP: `Botswana`,
	BYN: `Belarus`,
	BZD: `Belize`,
	CAD: `Canada`,
	CDF: `Congo (the Democratic Republic of the)`,
	CHE: `Switzerland`,
	CHF: `Switzerland, Liechtenstein`,
	CHW: `Switzerland`,
	CLF: `Chile`,
	CLP: `Chile`,
	COP: `Colombia`,
	COU: `Colombia`,
	CRC: `Costa Rica`,
	CUC: `Cuba`,
	CUP: `Cuba`,
	CVE: `Cabo Verde`,
	CZK: `Czechia`,
	DJF: `Djibouti`,
	DKK: `Denmark, Faroe Islands (the), Greenland`,
	DOP: `Dominican Republic (the)`,
	DZD: `Algeria`,
	EGP: `Egypt`,
	ERN: `Eritrea`,
	ETB: `Ethiopia`,
	EUR: `Åland Islands, European Union, Andorra, Austria, Belgium, Cyprus, Estonia, Finland, France, French Southern and Antarctic Lands, Germany, Greece, Guadeloupe, Ireland, Italy, Latvia, Lithuania, Luxembourg, Malta, French Guiana, Martinique, Mayotte, Monaco, Montenegro, Netherlands (the), Portugal, Réunion, Saint Barthélemy, Saint Martin (French part), Saint Pierre and Miquelon, San Marino, Slovakia, Slovenia, Spain, Vatican City, French Southern Territories (the)`,
	FJD: `Fiji`,
	FKP: `Falkland Islands (the)`,
	GBP: `United Kingdom of Great Britain and Northern Ireland (the), Isle of Man, Jersey, Guernsey, Tristan da Cunha`,
	GEL: `Georgia`,
	GHS: `Ghana`,
	GIP: `Gibraltar`,
	GMD: `Gambia (the)`,
	GNF: `Guinea`,
	GTQ: `Guatemala`,
	GYD: `Guyana`,
	HKD: `Hong Kong`,
	HNL: `Honduras`,
	HRK: `Croatia`,
	HTG: `Haiti`,
	HUF: `Hungary`,
	IDR: `Indonesia`,
	ILS: `Israel`,
	INR: `India, Bhutan`,
	IQD: `Iraq`,
	IRR: `Iran (Islamic Republic of)`,
	ISK: `Iceland`,
	JMD: `Jamaica`,
	JOD: `Jordan`,
	JPY: `Japan`,
	KES: `Kenya`,
	KGS: `Kyrgyzstan`,
	KHR: `Cambodia`,
	KMF: `Comoros (the)`,
	KPW: `North Korea`,
	KRW: `South Korea`,
	KWD: `Kuwait`,
	KYD: `Cayman Islands (the)`,
	KZT: `Kazakhstan`,
	LAK: `Laos`,
	LBP: `Lebanon`,
	LKR: `Sri Lanka`,
	LRD: `Liberia`,
	LSL: `Lesotho`,
	LYD: `Libya`,
	MAD: `Morocco, Western Sahara`,
	MDL: `Moldova (the Republic of)`,
	MGA: `Madagascar`,
	MKD: `North Macedonia`,
	MMK: `Myanmar`,
	MNT: `Mongolia`,
	MOP: `Macau`,
	MRU: `Mauritania`,
	MUR: `Mauritius`,
	MVR: `Maldives`,
	MWK: `Malawi`,
	MXN: `Mexico`,
	MXV: `Mexico`,
	MYR: `Malaysia`,
	MZN: `Mozambique`,
	NAD: `Namibia`,
	NGN: `Nigeria`,
	NIO: `Nicaragua`,
	NOK: `Norway, Svalbard and Jan Mayen, Bouvet Island`,
	NPR: `Nepal`,
	NZD: `New Zealand, Cook Islands (the), Niue, Pitcairn Islands, Tokelau`,
	OMR: `Oman`,
	PAB: `Panama`,
	PEN: `Peru`,
	PGK: `Papua New Guinea`,
	PHP: `Philippines (the)`,
	PKR: `Pakistan`,
	PLN: `Poland`,
	PYG: `Paraguay`,
	QAR: `Qatar`,
	RON: `Romania`,
	RSD: `Serbia`,
	CNY: `China`,
	RUB: `Russian Federation (the)`,
	RWF: `Rwanda`,
	SAR: `Saudi Arabia`,
	SBD: `Solomon Islands`,
	SCR: `Seychelles`,
	SDG: `Sudan (the)`,
	SEK: `Sweden`,
	SGD: `Singapore`,
	SHP: `Saint Helena, Ascension Island`,
	SLLSLE: `Sierra Leone`,
	SOS: `Somalia`,
	SRD: `Suriname`,
	SSP: `South Sudan`,
	STN: `São Tomé and Príncipe`,
	SVC: `El Salvador`,
	SYP: `Syria`,
	SZL: `Eswatini`,
	THB: `Thailand`,
	TJS: `Tajikistan`,
	TMT: `Turkmenistan`,
	TND: `Tunisia`,
	TOP: `Tonga`,
	TRY: `Turkey`,
	TTD: `Trinidad and Tobago`,
	TWD: `Taiwan (Province of China)`,
	TZS: `Tanzania`,
	UAH: `Ukraine`,
	UGX: `Uganda`,
	USD: `United States of America (the), American Samoa, British Indian Ocean Territory (the), Virgin Islands (British), Caribbean Netherlands, Ecuador, El Salvador, Guam, Marshall Islands (the), Micronesia (Federated States of), Northern Mariana Islands (the), Palau, Panama, Puerto Rico, Timor-Leste, Turks and Caicos Islands (the), Virgin Islands (U.S.), United States Minor Outlying Islands (the), Sint Maarten (Dutch part)`,
	UYI: `Uruguay`,
	UYU: `Uruguay`,
	UYW: `Uruguay`,
	UZS: `Uzbekistan`,
	VEF: `Venezuela (Bolivarian Republic of)`,
	VND: `Vietnam`,
	VUV: `Vanuatu`,
	WST: `Samoa`,
	XAF: `Cameroon, Central African Republic (the), Congo (the), Chad, Equatorial Guinea, Gabon`,
	XAG: ` `,
	XAU: ` `,
	XBA: ` `,
	XBB: ` `,
	XBC: ` `,
	XBD: ` `,
	XCD: `Anguilla, Antigua and Barbuda, Dominica, Grenada, Montserrat, Saint Kitts and Nevis, Saint Lucia, Saint Vincent and the Grenadines`,
	XDR: `International Monetary Fund`,
	XOF: `Benin, Burkina Faso, Côte d'Ivoire, Guinea-Bissau, Mali, Niger (the), Senegal, Togo`,
	XPD: ` `,
	XPF: `French territories of the Pacific Ocean: French Polynesia, New Caledonia, Wallis and Futuna`,
	XPT: ` `,
	XSU: `Unified System for Regional Compensation`,
	XTS: ` `,
	XUA: `African Development Bank`,
	XXX: ` `,
	YER: `Yemen`,
	ZAR: `Eswatini, Lesotho, Namibia, South Africa`,
	ZMW: `Zambia`,
	ZWL: `Zimbabwe`,
};
