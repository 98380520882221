import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Auditor } from 'assets';
import { useGetExportedCbQuery, useGetOutletQuery } from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import React, { useEffect } from 'react';
import { useHistory } from 'react-router';

export const CashBookTransactionList = () => {
	useEffect(() => {
		console.log('CashBookTransactionList');
	}, []);

	const outletID = localStorage.getItem('latestOutlet');
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const history = useHistory();
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const {
		data: { getExportedCB } = { getExportedCB: [] },
		loading: listLoading,
	} = useGetExportedCbQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
		onError: error => {
			console.log('error');
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	useEffect(() => {
		if (getExportedCB && getExportedCB?.length > 0 && !listLoading) {
			setOriginalListing(getExportedCB);
		}
	}, [getExportedCB, listLoading]);

	return (
		<>
			{listLoading && <Loading />}
			{outletLoading && <Loading />}
			<MainHeader
				mainBtn="back"
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/account-journals`,
					});
				}}
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: '../' },
					{ name: 'CB Transaction', current: true },
				]}
			/>

			<SearchHeader
				title="CB Transaction Listing"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, []);
				}}
				fixed
				// defaultValue={billListingSearch?.value ?? ''}
				// isDefaultValue={!!billListingSearch?.value ?? false}
				search
				onCloseAction={() => handleSearch('', [])}
			/>

			<ContentWrapper float search>
				<List className="core-list">
					{getExportedCB?.length === 0 ? (
						<EmptyList title="No Record Found" />
					) : (
						filteredList?.map((x, i) => (
							<>
								<ListItem key={i}>
									<ListItemText
										primary={
											<>
												<span className="xsTitle">
													{formatDate(x.startDate)} - {formatDate(x.endDate)}
												</span>
											</>
										}
										secondary={
											<>
												<span className="desc icon-text">
													<span style={{ color: '#ff9800' }}>
														{x.transactionCount}
													</span>
													&nbsp;transactions <TextSeparator />
													<img
														src={Auditor}
														alt="ifca"
														width={12}
														loading="lazy"
														style={{ paddingRight: '4px' }}
													/>
													<span style={{ color: '#ff9800' }}>
														{formatDate(x.cbDate)}
													</span>
												</span>
											</>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete">
											<KeyboardArrowRight
												onClick={() => {
													history.push({
														pathname: `/menu/outlet-app/account-journals/cb-transaction/detail`,
														state: x,
													});
												}}
											/>
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							</>
						))
					)}
				</List>

				<FloatButton
					onClick={() =>
						history.push(`/menu/outlet-app/account-journals/cb-transaction/add`)
					}
				/>
			</ContentWrapper>
		</>
	);
};
