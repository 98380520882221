import React from 'react';
import { MealPeriodForm } from './MealPeriodForm';
import { MealPeriodList } from './MealPeriodList';

const mealPeriodRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/meal-period`,
		},
		component: <MealPeriodList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/meal-period/add`,
		},
		component: <MealPeriodForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/meal-period/:mealPeriodID/edit`,
		},
		component: <MealPeriodForm mode="edit" />,
	},
];

export default mealPeriodRoutes;
