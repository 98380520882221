import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Grid, List, Switch, useMediaQuery } from '@material-ui/core';
import AccountBoxIcon from '@material-ui/icons/AccountBox';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import DirectionsRunIcon from '@material-ui/icons/DirectionsRun';
import KingBedIcon from '@material-ui/icons/KingBed';
import LocalAtmIcon from '@material-ui/icons/LocalAtm';
import LoyaltyIcon from '@material-ui/icons/Loyalty';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import PersonIcon from '@material-ui/icons/Person';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import SmartphoneIcon from '@material-ui/icons/Smartphone';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetOutletSettingSummaryDocument,
	GetPaymentTypeDocument,
	PaymentClass,
	useGetDefaultPaymentQuery,
	useGetOutletQuery,
	useSubmitDefaultPaymentMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

interface DefaultPaymentProps {
	defaultPayment: any;
}

export const PaymentTypeForm = () => {
	useEffect(() => {
		console.log('PaymentTypeForm');
	}, []);
	const { outletID } = useParams<Record<string, any>>();
	const history = useHistory();

	const { globalState, dispatch } = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const {
		called: defaultPaymentCalled,
		loading: defaultPaymentLoading,
		error: defaultPaymentError,
		data: { getDefaultPayment } = { getDefaultPayment: [] },
	} = useGetDefaultPaymentQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID,
			orderByAsc: 'paymentClass',
		},
	});

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [
		submitDefaultPayment,
		{
			loading: submitDefaultPaymentLoading,
			called: submitDefaultPaymentCalled,
		},
	] = useSubmitDefaultPaymentMutation({
		fetchPolicy: 'no-cache',
		onCompleted: data => {
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const {
		register,
		control,
		handleSubmit,
		watch,
		setValue,
		getValues,
	} = useForm<DefaultPaymentProps>({
		defaultValues: {},
		mode: 'onSubmit',
	});

	//SWITCH
	//SWITCH
	//SWITCH

	useEffect(() => {
		getDefaultPayment?.map((payment, index) => {
			register(`defaultPayment[${payment?.ID}]`);
			setValue(
				`defaultPayment[${payment?.ID}]`,
				payment?.isActive ? true : false,
			);
		});
	}, [getDefaultPayment]);

	const handleSwitch = (e: any, index: number, payment) => {
		if (e.target.checked) {
			setValue(`defaultPayment[${payment?.ID}]`, true);
		} else {
			setValue(`defaultPayment[${payment?.ID}]`, false);
		}
	};

	//ONSUBMIT
	//ONSUBMIT
	//ONSUBMIT

	const onSubmit = async (data: any) => {
		let paymentIDs = Object.keys(data?.defaultPayment);
		submitDefaultPayment({
			variables: {
				defaultPaymentIDs: paymentIDs?.filter(id =>
					watch(`defaultPayment[${id}]`),
				),
				outletID: outletID,
			},
			refetchQueries: [
				{ query: GetPaymentTypeDocument, variables: { outletID } },
				{ query: GetOutletSettingSummaryDocument, variables: { outletID } },
			],
		});
		history.push({
			pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
			state: { success: true, msgMode: 'create' },
		});
	};

	const paymentTypeIcons = description => {
		switch (description) {
			case 'Member':
				return <AccountBoxIcon style={{ color: '#ff9800' }} />;
			case 'Online Payment':
				return <SmartphoneIcon style={{ color: '#ff9800' }} />;
			case 'Cash':
				return <LocalAtmIcon style={{ color: '#ff9800' }} />;
			case 'Credit Card':
				return <CreditCardIcon style={{ color: '#ff9800' }} />;
			case 'Room':
				return <KingBedIcon style={{ color: '#ff9800' }} />;
			case 'Skipper':
				return <DirectionsRunIcon style={{ color: '#ff9800' }} />;
			case 'Officer':
				return <PersonIcon style={{ color: '#ff9800' }} />;
			case 'Staff':
				return <PersonAddIcon style={{ color: '#ff9800' }} />;
			case 'Voucher':
				return <LoyaltyIcon style={{ color: '#ff9800' }} />;
			default:
				return <MonetizationOnIcon style={{ color: '#ff9800' }} />;
		}
	};

	// 	CreditCardIcon
	// SmartphoneIcon
	// LocalAtmIcon
	// AccountBoxIcon
	// KingBedIcon
	// DirectionsRunIcon
	// PersonIcon
	// PersonAddIcon
	// LoyaltyIcon

	const [openExitDialog, setOpenExitDialog] = useState(false);
	return (
		<>
			{outletLoading && <Loading />}
			{defaultPaymentLoading && <Loading />}
			{submitDefaultPaymentLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Payment Type', current: true },
				]}
			/>
			{defaultPaymentLoading && <Loading />}
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<ContentWrapper float footer>
					<CardContents devFullWidth={true}>
						<List>
							{!defaultPaymentLoading &&
								watch('defaultPayment')?.length !== 0 &&
								watch('defaultPayment') !== undefined && (
									<Grid container>
										{getDefaultPayment
											// ?.sort((a, b) => {
											// 	return a.paymentTypeInfo.description.localeCompare(
											// 		b.paymentTypeInfo.description,
											// 	);
											// })
											?.slice()
											?.filter(
												x => x?.paymentClass !== PaymentClass.UnsettledBill,
											)
											// ?.sort((a, b) =>
											// 	a.paymentClass > b.paymentClass ? 1 : -1,
											// )
											.map((el, index) => {
												return (
													<>
														<Grid
															item
															xs={1}
															style={{
																alignSelf: 'center',
																textAlign: 'center',
																paddingTop: '4px',
															}}
														>
															{paymentTypeIcons(
																el?.paymentTypeInfo?.description,
															)}
														</Grid>
														<Grid
															item
															xs={8}
															className="mdDesc"
															style={{
																alignSelf: 'center',
																paddingLeft: '8px',
															}}
														>
															{el?.paymentTypeInfo?.description}
														</Grid>
														<Grid item xs={3} style={{ textAlign: 'right' }}>
															<Switch
																checked={watch(`defaultPayment[${el?.ID}]`)}
																onChange={event => {
																	handleSwitch(event, index, el);
																}}
																disabled={
																	(el?.paymentClass === PaymentClass.Room ||
																		el?.paymentClass ===
																			PaymentClass.NonGuest ||
																		el?.paymentClass === PaymentClass.Debtor ||
                                    el?.paymentClass === PaymentClass.SuspendFolio) &&
																	!getOutlet[0].outletChannel?.hotelID
																}
																name="isActive"
																color="primary"
															/>
														</Grid>
													</>
												);
											})}
									</Grid>
								)}
						</List>
					</CardContents>
				</ContentWrapper>
				<DynamicFooter
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					options={[
						{
							name: 'Save',
							onClick: () => {
								handleSubmit(onSubmit)();
							},
							color: 'primary',
							props: {
								type: 'submit',
							},
						},
					]}
				/>
				<ExitConfirmationDialog
					openExitDialog={openExitDialog}
					setOpenExitDialog={setOpenExitDialog}
					backPath={`/menu/outlet-app/outlet-setting/${outletID}`}
				/>
				{/* <CommonDialog
					fullWidth={true}
					open={openExitDialog}
					onClose={() => {
						setOpenExitDialog(false);
					}}
					sections={{
						header: {
							dynamic: (
								<div>
									<div className="dialogo-dynamic-content">
										<span
											className="title c-orange flex-space"
											style={{
												fontSize: '13px',
												fontWeight: 'bold',
											}}
										>
											Exit Confirmation
										</span>
									</div>
								</div>
							),
						},
						body: () => (
							<CardContents>
								<span className="">
									Are you sure you want to exit? Your changes will not be saved.
								</span>
							</CardContents>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
										},
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
											history.push({
												pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
											});
										},
										variant: 'contained',
										color: 'primary',
									},
								},
							],
						},
					}}
				/> */}
			</form>
		</>
	);
};
