import DateFnsUtils from '@date-io/date-fns';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import {
	CardActions,
	Grid,
	InputAdornment,
	ListItemText,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { MonetizationOn } from '@material-ui/icons';
import ShareIcon from '@material-ui/icons/Share';
import StopIcon from '@material-ui/icons/Stop';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import AppContext from 'containers/App/Store/AppContext';
import {
	useGetBizInsightOutletQuery,
	useGetShareAttachmentMutation,
	useGetUsersByAccountAndSoftwareQuery,
	useGetWaiterLazyQuery,
	useGetWaiterSalesCollectionAnalysisLazyQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import * as htmlToImage from 'html-to-image';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory } from 'react-router-dom';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import ShareDialog from '../../../../../components/Dialog/ShareDialog';
import './WaiterSalesAnalysis.scss';

const data = [
	{ name: 'Member', total: 440 },
	{ name: 'Credit Card', total: 330 },
	{ name: 'e-Wallet', total: 380 },
	{ name: 'Cash', total: 120 },
	{ name: 'Online', total: 300 },
	{ name: 'Room', total: 110 },
];

// const COLORS = ['#0a3d62', '#0d71a5', '#34bcd2', '#ffc853', '#f8f0d7'];
const COLORS = [
	'#34bcd2',
	'#4f86f7',
	'#f5c71a',
	'#ff8c00',
	'#2f4f4f',
	'#0d71a5',
];
interface WaiterSalesProps {
	user: string;
}
export const WaiterSalesAnalysis = () => {
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const latestOutlet = localStorage.getItem('latestOutlet');

	const [selectedOutlet, setSelectedOutlet] = useState(null);
	const { register, control, handleSubmit, errors, watch, setValue } = useForm<
		WaiterSalesProps
	>({
		defaultValues: {},
		// mode: 'onSubmit',
	});

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const [selectedDate, setSelectedDate] = useState<Date | null>(new Date());
	const [selectedUser, setSelectedUser] = useState(null);
	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);

	const handleDateChange = (date: Date | null) => {
		setSelectedDate(date);
	};

	const {
		data: { getOutlet } = { getOutlet: [] },
		called: getOutletCalled,
		loading: getOutletLoading,
	} = useGetBizInsightOutletQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadWaiterSalesCollectionAnalysis,
		{
			data: { waiterSalesCollectionAnalysis } = {
				waiterSalesCollectionAnalysis: [],
			},
			called: waiterSalesCollectionAnalysisCalled,
			loading: waiterSalesCollectionAnalysisLoading,
		},
	] = useGetWaiterSalesCollectionAnalysisLazyQuery({
		fetchPolicy: 'network-only',
	});

	console.log(waiterSalesCollectionAnalysis, 'waiterSalesCollectionAnalysis');

	const [
		loadWaiter,
		{
			called,
			loading,
			error,
			data: { getUsersWaiterRole } = {
				getUsersWaiterRole: [],
			},
		},
	] = useGetWaiterLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getUsersWaiterRole }) => {},
	});

	const {
		called: getUsersByAccountAndSoftwareCalled,
		loading: getUsersByAccountAndSoftwareLoading,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getUsersByAccountAndSoftware }) => {},
	});

	const history = useHistory();

	useEffect(() => {
		setSelectedOutlet(
			getOutlet?.filter(outlet => outlet?.ID === latestOutlet)[0],
		);
	}, [getOutlet]);

	useEffect(() => {
		if (selectedOutlet?.ID) {
			loadWaiter({
				variables: {
					outletID: selectedOutlet?.ID,
				},
			});
		}
	}, [selectedOutlet]);

	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const onSubmit = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: selectedOutlet?.ID,
			},
		});
	};

	useEffect(() => {
		setSelectedUser(
			getUsersByAccountAndSoftware?.filter(
				user => user?.ID === watch('user'),
			)[0],
		);
	}, [watch('user')]);

	useEffect(() => {
		if (selectedDate && selectedOutlet && selectedUser) {
			loadWaiterSalesCollectionAnalysis({
				variables: {
					outletID: selectedOutlet?.ID,
					selectedDate: selectedDate,
					userID: watch('user'),
					salesAmount: parseFloat(watch('salesAmount')),
				},
			});
		}
	}, [
		selectedOutlet,
		selectedDate,
		selectedUser,
		watch('user'),
		watch('salesAmount'),
	]);

	const gridSize = waiterSalesCollectionAnalysis.length > 4 ? 4 : 6;

	let waiterSalesAnalysisFilter = waiterSalesCollectionAnalysis?.map(x => {
		if (x?.name === 'CASH') {
			return {
				name: 'Cash',
				total: x?.total,
				color: '#FFFF00',
			};
		} else if (x?.name === 'ONLINE') {
			return {
				name: 'Online',
				total: x?.total,
				color: '#00C5FF',
			};
		} else if (x?.name === 'NON_GUEST') {
			return {
				name: 'Non Guest',
				total: x?.total,
				color: '#FF8F00',
			};
		} else if (x?.name === 'UNSETTLED_BILL') {
			return {
				name: 'Unsettled Bill',
				total: x?.total,
				color: '#FF0000',
			};
		} else if (x?.name === 'OFFICER') {
			return {
				name: 'Officer',
				total: x?.total,
				color: '#00FF42',
			};
		} else if (x?.name === 'VOUCHER') {
			return {
				name: 'Voucher',
				total: x?.total,
				color: '#9B00FF',
			};
		} else if (x?.name === 'ROOM') {
			return {
				name: 'Room',
				total: x?.total,
				color: '#0061FF',
			};
		} else if (x?.name === 'MEMBER') {
			return {
				name: 'Member',
				total: x?.total,
				color: '#1300FF',
			};
		} else if (x?.name === 'CREDIT') {
			return {
				name: 'Credit Card',
				total: x?.total,
				color: '#FFE5B4',
			};
		} else if (x?.name === 'OTHERS') {
			return {
				name: 'Others',
				total: x?.total,
				color: '#808080',
			};
		} else if (x?.name === 'SKIPPER') {
			return {
				name: 'Skipper',
				total: x?.total,
				color: '#000000',
			};
		} else if (x?.name === 'DEBTOR') {
			return {
				name: 'Debtor',
				total: x?.total,
				color: '#FFC0CB',
			};
		} else if (x?.name === 'CHEQUE') {
			return {
				name: 'Cheque',
				total: x?.total,
				color: '#EE82EE',
			};
		} else if (x?.name === 'BANK_TT') {
			return {
				name: 'Bank TT',
				total: x?.total,
				color: '#6918BF',
			};
		} else if (x?.name === 'STAFF') {
			return {
				name: 'Staff',
				total: x?.total,
				color: '#97FF00',
			};
		} else if (x?.name === 'SUSPEND_FOLIO') {
			return {
				name: 'Suspend Folio',
				total: x?.total,
				color: '#964B00',
			};
		}
	});

	console.log(waiterSalesAnalysisFilter, 'waiterSalesAnalysis');

	const CustomizedYAxisLabel = () => {
		return (
			<text
				x={0}
				y={0}
				dx="-150px"
				dy="5%"
				textAnchor="middle"
				dominantBaseline="middle"
				fontSize={12}
				fontWeight={500}
				width={180}
				transform="rotate(-90)"
			>
				Total Amount
			</text>
		);
	};

	const CustomizedXAxisLabel = (hidden?: boolean) => {
		return (
			<>
				<text
					x={0}
					y={0}
					dx="58%"
					dy="95%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={12}
					fontWeight={500}
					width={180}
				>
					{selectedUser?.name}
				</text>

				{hidden && (
					<>
						<text
							x={0}
							y={0}
							dx="58%"
							dy="5%"
							textAnchor="middle"
							dominantBaseline="middle"
							fontSize={16}
							fontWeight={500}
							width={180}
						>
							{`${selectedOutlet?.name}`}
						</text>
						<text
							x={0}
							y={0}
							dx="58%"
							dy="11%"
							textAnchor="middle"
							dominantBaseline="middle"
							fontSize={12}
							fontWeight={350}
							width={180}
						>
							{`Waiter Sales Analysis - ${formatDate(
								selectedDate.toISOString(),
							)} `}
						</text>
					</>
				)}
			</>
		);
	};

	return (
		<>
			{loading && <Loading />}
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/business-insight/business-analytic`,
					})
				}
				mainBtn="back"
				smTitle="Business Insights"
				// HC
				title={user?.companyName}
				routeSegments={[
					{ name: 'Business Insights' },
					{ name: 'Waiter Sales & Collection Analysis', current: true },
				]}
			/>

			<DynamicSubHeader
				title={
					getOutlet === undefined || getOutlet?.length === 0
						? ``
						: `${selectedOutlet?.name}`
				} // title text
				handleClick={e => handleClick(e, null, 0)} // onClick function for dropdown
				dropDownOptions={
					// dropdown component place here
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						{getOutlet.map((outlet, index) => (
							<StyledMenuItem
								key={index}
								value={outlet.ID}
								defaultValue={outlet.ID}
								onClick={e => {
									setSelectedOutlet(outlet);
								}}
							>
								<ListItemText primary={outlet?.name} />
							</StyledMenuItem>
						))}
					</StyledMenu>
				}
			/>

			<ContentWrapper search footer>
				<CardActions style={{ paddingTop: '0px' }}>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							id="date-picker-dialog"
							style={{
								background: 'white',
								width: '100%',
								zIndex: 40,
							}}
							format="dd MMM yyyy"
							value={selectedDate}
							onChange={handleDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							inputVariant="outlined"
							size="small"
							className="custom-date-business-insight"
						/>
					</MuiPickersUtilsProvider>
					<Controller
						as={
							<TextField select variant="outlined" size="small" label="Waiter">
								{getUsersByAccountAndSoftware?.map(x => (
									<MenuItem key={x.ID} value={x.ID}>
										{x.name}
									</MenuItem>
								))}
							</TextField>
						}
						name={'user'}
						style={{
							marginTop: '0px',
							marginBottom: '0px',
							background: 'white',
							zIndex: 40,
						}}
						className="user-dropdown"
						autoComplete="off"
						control={control}
						multiline
						fullWidth
						margin="normal"
					/>
				</CardActions>
				<CardActions style={{ paddingTop: '0px', paddingBottom: '12px' }}>
					<Controller
						as={
							<NumberFormat
								customInput={TextField}
								variant="outlined"
								decimalScale={0}
								fixedDecimalScale
								defaultValue={0}
								allowNegative={false}
								InputProps={{
									startAdornment: (
										<InputAdornment position="start">
											<MonetizationOn
												style={{ paddingRight: '7px', color: '#ff9800' }}
											/>
										</InputAdornment>
									),
								}}
								ref={register}
							/>
						}
						name={'salesAmount'}
						style={{
							marginTop: '0px',
							marginBottom: '0px',
							background: 'white',
							zIndex: 40,
						}}
						className="sales-amount"
						autoComplete="off"
						control={control}
						multiline
						fullWidth
						margin="normal"
						required
					/>
				</CardActions>

				{!waiterSalesCollectionAnalysisLoading &&
				!getOutletLoading &&
				(waiterSalesCollectionAnalysis === undefined ||
					waiterSalesCollectionAnalysis?.length === 0) ? (
					<EmptyList
						title={`No orders created under this waiter`}
						subtitle="Please select a different waiter"
					/>
				) : !selectedUser ? (
					<EmptyList title={`Please select a waiter to continue`} />
				) : (
					!waiterSalesCollectionAnalysisLoading &&
					!getOutletLoading &&
					(waiterSalesCollectionAnalysis !== undefined ||
						waiterSalesCollectionAnalysis?.length !== 0) && (
						<>
							{waiterSalesAnalysisBarChart(
								waiterSalesAnalysisFilter,
								CustomizedXAxisLabel,
								CustomizedYAxisLabel,
								gridSize,
							)}
							{waiterSalesAnalysisBarChart(
								waiterSalesAnalysisFilter,
								CustomizedXAxisLabel,
								CustomizedYAxisLabel,
								gridSize,
								true,
							)}
							<div className="cover-layer">
								<CardContents>
									<></>
								</CardContents>
							</div>
						</>
					)
				)}
			</ContentWrapper>
			<div style={{ height: '50px' }}></div>

			{ShareDialog({
				shareTitle: `Waiter Sales & Collection Analysis - ${selectedOutlet?.name}`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}

			<DynamicFooter
				overlap
				options={[
					{
						name: 'Share',
						color: 'primary',
						onClick: () => {
							let temp = document.getElementById('hiddenBarChart');

							htmlToImage
								.toBlob(temp, { style: { background: '#fff' } })
								.then(value => {
									onSubmit(value);
								});
						},
						disabled:
							waiterSalesCollectionAnalysis == undefined ||
							waiterSalesCollectionAnalysis.length == 0,
						props: {
							type: 'submit',
						},
						startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
					},
				]}
			/>
		</>
	);
};

const waiterSalesAnalysisBarChart = (
	waiterSalesAnalysisFilter,
	CustomizedXAxisLabel,
	CustomizedYAxisLabel,
	gridSize,
	hidden?: boolean,
) => {
	return (
		<div
			style={
				hidden
					? {
							zIndex: -40,
							top: 0,
							left: 0,
							position: 'fixed',
							width: '100%',
					  }
					: {
							marginTop: '0px',
							zIndex: 40,
							position: 'relative',
					  }
			}
			className={hidden ? 'hidden-content' : null}
		>
			<CardContents
				section={
					!hidden
						? {
								header: {
									title: 'Collection',
								},
						  }
						: null
				}
			>
				<div id={!hidden ? 'barChart ' : 'hiddenBarChart'}>
					<div
						style={{
							width: '100%',
							height: 300,
							paddingBottom: '16px',
						}}
					>
						<ResponsiveContainer>
							<BarChart
								data={waiterSalesAnalysisFilter}
								margin={{
									top: hidden ? 40 : 10,
									right: 10,
									left: 0,
									bottom: 10,
								}}
							>
								<CartesianGrid strokeDasharray="2 3" />
								<XAxis
									dataKey="name"
									axisLine={false}
									tickLine={false}
									tick={false}
									label={CustomizedXAxisLabel(hidden ? true : false)}
								/>
								<YAxis
									fontSize={12}
									axisLine={false}
									tickLine={false}
									label={<CustomizedYAxisLabel />}
								/>
								<Tooltip />

								<Bar
									dataKey="total"
									name="Total Amount"
									stackId="a"
									fill="#4ba0ea"
									barSize={20}
								>
									{waiterSalesAnalysisFilter?.map((entry, index) => (
										<Cell fill={entry?.color} />
									))}
								</Bar>
							</BarChart>
						</ResponsiveContainer>
					</div>

					<Grid container justify="center">
						{waiterSalesAnalysisFilter?.map((entry, index) => (
							<Grid
								item
								xs={gridSize}
								container
								style={{ justifyContent: 'center' }}
							>
								<Grid
									item
									xs={2}
									style={{
										textAlign: 'center',
										alignSelf: 'center',
										paddingTop: '4px',
										marginTop: '2px',
										marginRight: '4px',
									}}
								>
									<StopIcon style={{ color: entry?.color }} />
								</Grid>
								<Grid
									item
									xs={6}
									style={{ textAlign: 'left', alignSelf: 'center' }}
									className="mdDesc"
								>
									{entry?.name}
								</Grid>
							</Grid>
						))}
					</Grid>
				</div>
			</CardContents>
		</div>
	);
};
