import { useQuery } from '@apollo/react-hooks';
import DefaultAvatar from '@ifca-root/react-component/src/assets/images/default-avatar.png';
import Authentication from '@ifca-root/react-component/src/assets/icons/authentication.svg';
import Logout from '@ifca-root/react-component/src/assets/icons/logout.svg';
import Cover from '@ifca-root/react-component/src/assets/images/calendar-bg.jpg';
import theme from '@ifca-root/react-component/src/assets/theme';
import IconItem from '@ifca-root/react-component/src/components/Avatar/IconItem';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import EmailText from '@ifca-root/react-component/src/components/Typography/EmailText';
import PhoneText from '@ifca-root/react-component/src/components/Typography/PhoneText';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import { Edit, KeyboardArrowRight } from '@material-ui/icons';
import {
	useGetUserLogoQuery,
	useLoggedInUserProfileQuery,
} from 'generated/graphql';
import React, { useEffect, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { POSClient } from '../../POSClient';
import { AccountClient } from '../../ProductsClient';
import { GET_ACCOUNT_DETAIL } from './AccountQuery';

export const Profile = props => {
	let history = useHistory();

	let location = useLocation();

	const {
		loading,
		error,
		data: { loggedInUserProfile } = { loggedInUserProfile: null },
	} = useLoggedInUserProfileQuery({
		client: POSClient as any,
	});

	let dataToReturn = [];
	const [previewFile, setPreview] = useState(null);
	const {
		loading: accLoad,
		error: accError,
		data: { getAccountDetail } = { getAccountDetail: {} },
	} = useQuery(GET_ACCOUNT_DETAIL, {
		client: AccountClient as any,

		variables: {
			accountID: loggedInUserProfile?.accountID,
		},
	});

	const { data: userLogo } = useGetUserLogoQuery({
		variables: {
			refID: loggedInUserProfile?.ID,
		},
		onCompleted: data => {
			setPreview(data?.logoView?.fileURL);
		},
	});

	dataToReturn.push({ data: loggedInUserProfile });

	const [userData, setUserData] = useState({});
	const [accountDetailData, setAccountDetailData] = useState({});

	useEffect(() => {
		setUserData(loggedInUserProfile);
		setAccountDetailData(getAccountDetail);
	}, [loggedInUserProfile]);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const latestOutlet = localStorage.getItem('latestOutlet');

	const previousPath = localStorage.getItem('prevPath');

	return (
		<>
			{loading && <Loading />}
			<MainHeader
				mainBtn="back"
				onClick={() => {
					// 	history.push(`/menu/outlet-app/${latestOutlet}/table/grid`);
					history.push(`${previousPath}`);
					localStorage.removeItem('prevPath');
				}}
				smTitle={'Outlet App'}
				title="Profile"
				routeSegments={[{ name: 'My Profile', current: true }]}
				rightRouteSegments={[{ name: 'Edit ', current: true }]}
			/>

			<div
				style={{
					marginTop: isDesktop ? '61px' : '0px',
					marginBottom: isDesktop ? '-45px' : '10px',
				}}
			>
				<img src={Cover} alt="" className="profile-cover" />
				{/* <img src={userLogo?.logoView?.fileURL} alt="" className="user-avatar" /> */}

				{!previewFile ? (
					<img src={DefaultAvatar} alt="" className="user-avatar" />
				) : (
					<img
						src={userLogo?.logoView?.fileURL}
						alt=""
						className="user-avatar"
					/>
				)}
			</div>

			<ContentWrapper>
				<CardContents
					devFullWidth={true}
					section={{
						header: {
							title: loggedInUserProfile?.name,
							onClickAction: () =>
								history.push({
									pathname: `/profile/edit`,
									state: { userData, accountDetailData, previewFile },
								}),
							icon: <Edit />,
						},
					}}
				>
					<div className="content-wrap full">
						<div className="desc">Email</div>
						<EmailText font="xsTitle">{loggedInUserProfile?.email}</EmailText>
					</div>
					<div className="content-wrap full">
						<div className="desc">Mobile No.</div>
						<PhoneText font="xsTitle">
							{loggedInUserProfile?.contactNo}
						</PhoneText>
					</div>
					{/* <div className="content-wrap full">
						<div className="desc">Company</div>
						<div className="xsTitle">{getAccountDetail?.name}</div>
					</div> */}
					{/* <div className="content-wrap full">
						<div className="desc">Department</div>
						<div className="xsTitle">
							{loggedInUserProfile.department ?? 'NA'}{' '}
						</div>
					</div>
					<div className="content-wrap full">
						<div className="desc">Job Title</div>
						<div className="xsTitle">
							{loggedInUserProfile.jobTitle ?? 'NA'}
						</div>
					</div> */}
				</CardContents>
				<List className="core-list">
					<ListItem>
						<IconItem
							image={Authentication}
							imageType="svg"
							badgeType="default"
						/>
						<ListItemText
							onClick={() =>
								history.push({ pathname: '/profile/change-password' })
							}
							primary={<span className="xsTitle">Change Password</span>}
						/>
						<ListItemSecondaryAction>
							<IconButton edge="end" aria-label="arrow">
								<KeyboardArrowRight
									onClick={() =>
										history.push({ pathname: '/profile/change-password' })
									}
								/>
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
					<ListItem onClick={() => history.push(`/logout`)}>
						<IconItem image={Logout} imageType="svg" badgeType="default" />
						<ListItemText primary={<span className="xsTitle">Logout</span>} />
						<ListItemSecondaryAction>
							<IconButton edge="end" aria-label="arrow">
								<KeyboardArrowRight onClick={() => history.push(`/logout`)} />
							</IconButton>
						</ListItemSecondaryAction>
					</ListItem>
				</List>
			</ContentWrapper>
		</>
	);
};
