import takeawayRoutes from 'containers/OutletModule/Takeaway/TakeawayRoutes';
import React from 'react';
import addEditOrderRoutes from '../TableSubmenu/AddEditOrder/AddEditOrderRoutes';
import billItemDiscountRoutes from '../TableSubmenu/BillItemDiscount/BillItemDiscountRoutes';
import billSettlementRoutes from '../TableSubmenu/BillSettlement/BillSettlementRoutes';
import newOrderRoutes from '../TableSubmenu/NewOrder/NewOrderRoutes';
import { TableSubmenu } from '../TableSubmenu/TableSubmenu';
import { TableGrid } from './TableGrid';

const tableGridRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid`,
		},
		component: <TableGrid />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID`,
		},
		component: <TableSubmenu />,
	},
	...addEditOrderRoutes,
	...billItemDiscountRoutes,
	...billSettlementRoutes,
	...newOrderRoutes,
	...takeawayRoutes,
];

export default tableGridRoutes;
