import React from 'react';
import { DigitalMenuForm } from './DigitalMenuForm';
import { DigitalMenuList } from './DigitalMenuList';
import { DigitalMenuPricingForm } from './DigitalMenuPricingForm';
import { DigitalMenuPricingList } from './DigitalMenuPricingList';

const digitalMenuPricingRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/digital-menu`,
		},
		component: <DigitalMenuList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/digital-menu/:digitalMenuID/edit`,
		},
		component: <DigitalMenuForm />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/digital-menu/:digitalMenuID/edit/digital-menu-pricing/:menuCategoryID`,
		},
		component: <DigitalMenuPricingList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/digital-menu/:digitalMenuID/edit/digital-menu-pricing/:menuCategoryID/add`,
		},
		component: <DigitalMenuPricingForm />,
	},
];

export default digitalMenuPricingRoutes;
