import React, { useState } from 'react'
import easyTimer from 'easytimer.js'

export const useMultiPurposeFileUpload = () => {
  const [media, setMedia] = useState([])
  const [previewMedia, setPreviewMedia] = useState([])
  const [files, setFiles] = useState([])
  const [previewFiles, setPreviewFiles] = useState([])
  const [remarks, setRemark] = useState([])
  const [singleRemark, setSingleRemark] = useState(null)
  const [timer, setTimer] = useState(new easyTimer())
  const [timeVal, setTimeVal] = useState('')
  const [timerStarted, setStart] = useState(false)

  //////////////// timer

  const tick = () => {
    const timeValue = timer.getTimeValues().toString()
    setTimeVal(timeValue)
  }

  //////////////// media
  const {
    remove: removeMedia,
    removeAll: removeAllMedia,
    handleUploadChange: handleUploadMedia,
    handleEditUpload: handleEditMedia,
  } = fileUploadFunc(media, setMedia, previewMedia, setPreviewMedia)

  //////////////// document
  const {
    remove: removeFile,
    removeAll: removeAllFile,
    handleUploadChange: handleUploadFile,
    handleEditUpload: handleEditFile,
  } = fileUploadFunc(files, setFiles, previewFiles, setPreviewFiles)

  //////////////// remarks

  const handleAddingRemark = remark => {
    remarks.push(remark)
    let newArr = remarks
    setRemark(newArr)
  }

  const handleSingleRemark = remark => {
    setSingleRemark(remark)
  }

  const removeRemark = index => {
    remarks.splice(index, 1)
    let newArr = remarks
    setRemark(newArr)
  }

  const removeSingleRemark = () => {
    setSingleRemark(null)
  }

  const handleUploadDynamic = async (file: any) => {
    const prevFile = []
    //start (new)
    const newFiles = await Promise.all(
      file.map(async (x, i) => {
        const data = await fetch(x.fileURL)
          .then(res => res.blob())
          .then(file => new File([file], x.fileName, { type: x?.mediaType }))
        prevFile.push(data)
      })
    )
    //end
    return prevFile
  }

  return {
    removeMedia, // media-image
    removeAllMedia,
    handleUploadMedia,
    handleEditMedia,
    media,
    previewMedia,
    setPreviewMedia,
    removeFile, // document
    removeAllFile,
    handleUploadFile,
    handleEditFile,
    files,
    previewFiles,
    setPreviewFiles,
    remarks, // text | remarks
    removeRemark,
    handleAddingRemark,
    singleRemark,
    handleSingleRemark,
    timer,
    timerStarted,
    setStart,
    tick,
    timeVal,
    handleUploadDynamic,
    removeSingleRemark,
  }
}

function fileUploadFunc(files, setFiles, previewFiles, setPreviewFiles) {
  const handleUploadChange = e => {
    const fileObj = []
    const fileArray = []
    const fileToPush = []

    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
      fileToPush.push(e.target.files.item(i))
    }

    const newPreview = [...previewFiles, ...fileArray]
    const newFiles = [...files, ...fileToPush]
    setPreviewFiles(newPreview)
    setFiles(newFiles)
  }
  //Remove img preview
  const remove = index => {
    setPreviewFiles(prevIndexes => [
      ...prevIndexes.filter((item, i) => i !== index),
    ])
    const newFiles = [...Array.from(files)]
    newFiles.splice(index, 1)
    setFiles(newFiles)
  }

  const handleEditUpload = async (file: any) => {
    const prevFile = []
    //start (new)
    const newFiles = await Promise.all(
      file.map(async (x, i) => {
        const data = await fetch(x.fileURL)
          .then(res => res.blob())
          .then(file => new File([file], x.fileName, { type: x?.mediaType }))
        prevFile.push(data)
      })
    )
    //end
    setFiles(prevFile)
  }

  // const handleEditUpload = async (file: any) => {
  //   const prevFile = []
  //   file.map((x, i) => {
  //     fetch(x.fileURL).then(res =>
  //       res.blob().then(y => {
  //         let a = new File([y], x?.fileName, { type: x?.mediaType })
  //         prevFile.push(a)
  //       })
  //     )
  //   })

  //   setFiles(prevFile)
  // }

  const removeAll = () => {
    setFiles([])
    setPreviewFiles([])
  }

  return {
    remove,
    removeAll,
    handleUploadChange,
    handleEditUpload,
  }
}
