import { yupResolver } from '@hookform/resolvers';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { TextField } from '@material-ui/core';
import AppContext from 'containers/App/Store/AppContext';
import {
	GetRoleDocument,
	useDuplicateRolePermissionMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
// import { CommonYupValidation } from 'helpers/Form/YupValidation'
import React, { useContext } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';

interface IDuplicateRole {
	roleID: string;
	roleName: string;
	duplicateDialog: boolean;
	setDuplicateDialog: any;
	existingRole: any;
}

interface DuplicateRoleForm {
	roleName: string;
}
export function DuplicateRoleDialog(props) {
	const {
		duplicateDialog,
		setDuplicateDialog,
		roleID,
		roleName,
		existingRole,
	} = props;

	const DuplicateRoleSchema = yup.object().shape({
		// roleName: CommonYupValidation.requireField('Role is required'),
		roleName: yup
			.string()
			.required(SystemMsgs.roleName())
			.trim()
			.test('name', 'Role name already exist', value => {
				if (watch('roleName') !== roleName) {
					return !existingRole?.includes(value);
				} else {
					return !existingRole?.includes(value);
				}
			}),
	});

	const { globalState, dispatch }: any = useContext(AppContext as any);

	const { handleSubmit, register, errors, control, watch, getValues } = useForm<
		DuplicateRoleForm
	>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(DuplicateRoleSchema),
	});

	const [
		duplicateRolePermission,
		{ loading: mutationUpdateLoading, error: mutationUpdateError },
	] = useDuplicateRolePermissionMutation({
		onError: error => {
			return error.graphQLErrors.map(({ message }) => {
				dispatch({
					type: 'snackBar',
					payload: {
						open: true,
						message: message,
					},
				});
			});
		},
		onCompleted: data => {
			setDuplicateDialog(false);
		},
	});

	const onSubmit = data => {
		duplicateRolePermission({
			variables: {
				roleID: roleID,
				name: data?.roleName,
			},
			refetchQueries: [
				{
					query: GetRoleDocument,
					variables: {
						orderByAsc: 'name',
					},
				},
			],
		});
	};

	return (
		<CommonDialog
			fullWidth={true}
			open={duplicateDialog}
			onClose={() => setDuplicateDialog(false)}
			sections={{
				header: {
					dynamic: (
						<div className="">
							<div className="dialog-dynamic-content">
								<div className="session">
									<div className="dialog-title c-orange title">
										{`${roleName}`}
									</div>
								</div>
							</div>
							<div className="infoline-content">
								<>
									<div className="xsTitle">Add Duplicate</div>
								</>
							</div>
						</div>
					),
				},
				body: () => (
					<>
						<div style={{ marginTop: '-15px' }}>
							<Controller
								as={TextField}
								name="roleName"
								fullWidth
								margin="normal"
								label="Role"
								variant="standard"
								helperText={errors?.roleName?.message}
								error={errors?.roleName ? true : false}
								control={control}
								ref={register}
								required
							/>
						</div>
					</>
				),
				footer: {
					actions: [
						{
							displayText: 'Cancel',
							props: {
								onClick: () => setDuplicateDialog(false),
								variant: 'contained',
								color: 'primary',
							},
						},
						{
							displayText: 'Save',
							props: {
								// onClick: () => handleSubmit(onSubmit)(),
								onClick: () => {
									if (errors?.roleName ? false : true) {
										handleSubmit(onSubmit)();
										// setDouble(true);
									}
								},
								variant: 'contained',
								color: 'primary',
							},
						},
					],
				},
			}}
		/>
	);
}
