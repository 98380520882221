import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import {
	OrderStatus,
	useGetCurrentMealPeriodLazyQuery,
	useGetDailyMenuCategoryLazyQuery,
	useGetOrderQuery,
	useGetOutletNameQuery,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export const AddMenuList = (props: any) => {
	useEffect(() => {
		console.log('MenuList Outlet App');
	}, []);
	const [hadOrder, setHadOrder] = useState('Pending');
	const { mode } = props;
	const location = useLocation();

	const history = useHistory();
	const { tableID, outletID, digitalMenuID, orderID } = useParams<
		Record<string, any>
	>();
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	// const currentMealPeriod = JSON.parse(
	// 	localStorage.getItem('currentMealPeriod'),
	// )[outletID][0];

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const [orderItemVal, setOrderitemVal] = useState(null);
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
			loadOutletDailyMenuCategory({
				variables: {
					outletID: outletID,
					mealPeriodID: getCurrentMealPeriod?.ID,
				},
			});
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const {
		data: { getOrder } = { getOrder: [] },
		called: getOrderCalled,
		loading: getOrderLoading,
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			// tableID: tableID,
			...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
			status: OrderStatus.Open,
			outletID,
		},
	});

	const [selectedOrder, setSelectedOrder] = useState(
		getOrder?.filter(order => order?.ID === orderID)[0],
	);

	useEffect(() => {
		setSelectedOrder(getOrder?.filter(order => order?.ID === orderID)[0]);
	}, [getOrder, orderID]);

	const [
		loadOutletDailyMenuCategory,
		{
			loading: outletDailyMenuCategoryLoading,
			called: outletDailyMenuCategoryCalled,
			data: { getOutletDailyMenuCategory } = { getOutletDailyMenuCategory: [] },
		},
	] = useGetDailyMenuCategoryLazyQuery({
		fetchPolicy: 'network-only',
		// variables: {
		// 	outletID: outletID,
		// 	mealPeriodID: currentMealPeriod?.ID,
		// },
	});

	const handleChangeOrder = (e, obj) => {
		if (obj?.ID !== orderID) {
			history.push({
				pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${obj?.ID}`,
			});
		}
	};

	const categoryItemCount = items => {
		const filterItem = items?.filter(
			item => item?.menuItem?.stopSales === null,
		);
		return filterItem?.length;
	};

	return (
		<>
			{outletLoading && <Loading />}
			{getOrderLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			{outletDailyMenuCategoryLoading && <Loading />}

			<MainHeader
				onClick={() => {
					if (mode === 'takeAway') {
						history.push(
							`/menu/outlet-app/${outletID}/order/${orderID}/takeaway/detail`,
						);
					} else if (mode === 'dineIn') {
						history.push(`/menu/outlet-app/${outletID}/table/grid/${tableID}`);
					}
				}}
				mainBtn={'back'}
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Menu Category' },
					{ name: 'Add Menu Item', current: true },
				]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${currentMealPeriod?.name} ${formatTime(
									currentMealPeriod?.startTime,
							  )} - ${formatTime(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<OrderSubheader
				mode={mode}
				getOrderLoading={getOrderLoading}
				getOrder={getOrder}
				selectedOrder={selectedOrder}
				orderID={selectedOrder?.ID}
				handleChangeOrder={handleChangeOrder}
				outletID={outletID}
			/>

			<ContentWrapper
				float
				singleDynamicInfo
				multiLineSubheader={mode === 'takeAway' ? true : false}
			>
				{!outletDailyMenuCategoryLoading && (
					<List className="core-list">
						{!outletLoading &&
							!getOrderLoading &&
							!outletDailyMenuCategoryLoading &&
							!getCurrentMealPeriodLoading &&
							(getCurrentMealPeriod === undefined ||
							getCurrentMealPeriod === null ? (
								<EmptyList
									title={`No applicable current meal period ath the moment`}
								/>
							) : [...getOutletDailyMenuCategory]?.filter(
									cat => categoryItemCount(cat?.outletDailyMenuItem) !== 0,
							  )?.length === 0 ? (
								<EmptyList title={`No menu during the current meal period`} />
							) : (
								[...getOutletDailyMenuCategory]
									?.sort(function(a, b) {
										return a.priority - b.priority;
									})
									?.filter(
										cat => categoryItemCount(cat?.outletDailyMenuItem) !== 0,
									)
									?.map((v, i) => (
										<ListItem
											onClick={() => {
												if (mode === 'dineIn') {
													history.push(
														`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/menu-category/${v?.ID}`,
														// `/menu/outlet-app/hellothere/${outletID}/table/grid/${tableID}/order/${orderID}/menu-category/${v?.ID}`,
													);
												} else if (mode === 'takeAway') {
													history.push(
														`/menu/outlet-app/${outletID}/order/${orderID}/menu-category/${v?.ID}/takeaway`,
													);
												}
											}}
										>
											<ListItemText
												primary={
													<>
														<span className="smTitle">
															{i + 1 + '.'} &nbsp;
														</span>
														<span className="smTitle">{v?.name}</span>
													</>
												}
												secondary={
													<>
														<Grid
															container
															className="desc "
															style={{ paddingLeft: '19px' }}
														>
															{/* <Grid item xs={2} style={{ textAlign: 'center' }}> */}
															{`No. of Item : ${categoryItemCount(
																v?.outletDailyMenuItem,
															) ?? 0}`}

															{/* </Grid> */}
														</Grid>
													</>
												}
											/>

											<ListItemSecondaryAction>
												<IconButton
													edge="end"
													onClick={() => {
														if (mode === 'dineIn') {
															history.push(
																`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/menu-category/${v?.ID}`,
															);
														} else if (mode === 'takeAway') {
															history.push(
																`/menu/outlet-app/${outletID}/order/${orderID}/menu-category/${v?.ID}/takeaway`,
															);
														}
													}}
												>
													<KeyboardArrowRight />
												</IconButton>
											</ListItemSecondaryAction>
										</ListItem>
									))
							))}
					</List>
				)}
			</ContentWrapper>
		</>
	);
};
