import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Switch,
	TextField,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetOutletTaxPolicyDocument,
	TaxType,
	useCreateOutletTaxPolicyMutation,
	useDeleteOutletTaxPolicyMutation,
	useGetOutletQuery,
	useGetOutletTaxPolicyQuery,
	useGetPatronTypeQuery,
	useUpdateOutletTaxPolicyMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

interface ServiceChargeProps {
	serviceCharge: number;
	effectiveDate: Date;
	isTaxable: boolean;
}

export const ServiceChargeList = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	// const { outletID } = props;
	const { mode } = props;
	let location = useLocation();
	let editData: any = location?.state;

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const { outletID } = useParams<Record<string, any>>();

	const [openDialog, setOpenDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [taxableInput, setTaxableInput] = useState({
		isTaxable: menu?.obj?.isTaxable || null,
	});
	const [double, setDouble] = useState(false);

	const onChangeTaxableInput = (event: React.ChangeEvent<HTMLInputElement>) => {
		setTaxableInput({
			...taxableInput,
			[event.target.name]: event.target.checked,
		});
	};

	const yupSchema = yup.object().shape({
		serviceCharge: CommonYupValidation?.requireField(
			SystemMsgs.serviceCharge(),
		),
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const {
		data: { getPatronType } = { getPatronType: [] },
		loading: patronTypeLoading,
	} = useGetPatronTypeQuery();

	const {
		called: outletTaxPolicyCalled,
		loading: outletTaxPolicyLoading,
		error: outletTaxPolicyError,
		data: { getOutletTaxPolicy } = { getOutletTaxPolicy: [] },
	} = useGetOutletTaxPolicyQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, taxType: TaxType.ServiceCharge },
	});

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [
		createOutletTaxPolicy,
		{ loading: createOutletTaxPolicyLoading },
	] = useCreateOutletTaxPolicyMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenDialog(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		updateOutletTaxPolicy,
		{ loading: updateOutletTaxPolicyLoading },
	] = useUpdateOutletTaxPolicyMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenDialog(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const [
		deleteOutletTaxPolicy,
		{ loading: deleteOutletTaxPolicyLoading },
	] = useDeleteOutletTaxPolicyMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenDialog(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const { register, control, handleSubmit, errors, watch } = useForm<
		ServiceChargeProps
	>({
		// defaultValues: {
		// 	serviceCharge: isEdit ? menu?.obj?.value : '',
		// 	isTaxable: isEdit ? menu?.obj?.isTaxable : false,
		// 	effectiveDate: isEdit ? menu?.obj?.effectiveDate : new Date(),
		// },
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const watchValue = watch('serviceCharge') != 0 ? watch('serviceCharge') : 0;

	// useEffect(() => {
	// 	if (
	// 		outletTaxPolicyCalled &&
	// 		(getOutletTaxPolicy === undefined || getOutletTaxPolicy?.length === 0)
	// 	) {
	// 		setOpenDialog(true);
	// 		setIsEdit(false);
	// 	} else {
	// 		setOpenDialog(false);
	// 	}
	// }, getOutletTaxPolicy);

	// useEffect(() => {
	// 	if (!outletTaxPolicyLoading && getOutletTaxPolicy?.length === 0) {
	// 		setOpenDialog(true);
	// 		setIsEdit(false);
	// 	} else {
	// 		setOpenDialog(false);
	// 	}
	// }, []);

	const createUpdateMutation = isEdit
		? updateOutletTaxPolicy
		: createOutletTaxPolicy;

	const onSubmit = (data: any) => {
		createUpdateMutation({
			variables: {
				input: {
					...(isEdit ? { ID: menu?.obj?.ID } : null),
					outletID: outletID,
					taxType: TaxType.ServiceCharge,
					isTaxable: taxableInput?.isTaxable,
					effectiveDate: data?.effectiveDate,
					value: parseFloat(data?.serviceCharge),
				},
			},
			refetchQueries: [
				{
					query: GetOutletTaxPolicyDocument,
					variables: { outletID: outletID, taxType: TaxType.ServiceCharge },
				},
			],
		});
	};

	return (
		<>
			{outletLoading && <Loading />}
			{patronTypeLoading && <Loading />}
			{outletTaxPolicyLoading && <Loading />}
			{createOutletTaxPolicyLoading && <Loading />}
			{updateOutletTaxPolicyLoading && <Loading />}
			{deleteOutletTaxPolicyLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/outlet-policy`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Standard & Policy Guideline', current: true },
				]}
				rightRouteSegments={[
					{
						name: 'Service Charge',
						current: true,
					},
				]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{!outletLoading &&
						!patronTypeLoading &&
						!outletTaxPolicyLoading &&
						(getOutletTaxPolicy?.length === 0 ? (
							<EmptyList
								title="No Service Charge Tax Policy found"
								subtitle="Please add a service charge tax policy to continue"
							/>
						) : (
							getOutletTaxPolicy?.map((el, index) => (
								<ListItem>
									<ListItemText
										primary={
											<>
												<span className="desc">
													<span className="xsTitle">{`Service Charge: $${amtStr(
														el?.value,
													)}%`}</span>
												</span>
											</>
										}
										secondary={
											<span className="desc">
												<>
													<span className="desc">
														{`Effective Date: ${formatDate(el?.effectiveDate)}`}
													</span>
												</>
											</span>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="delete"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, el.ID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
						))}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem>
						<span
							onClick={() => {
								setOpenDialog(true);
								setIsEdit(true);
								setDouble(false);
								setTaxableInput({
									isTaxable: menu?.obj?.isTaxable,
								});
							}}
						>
							Edit
						</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			{/* <form onSubmit={rHandleSubmit(onReinstateSubmit)} id="open-form"> */}
			<Box mt={1}>
				<CommonDialog
					fullWidth={true}
					open={openDialog}
					onClose={() => setOpenDialog(false)}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="flex session">
												<div
													className="title flex-space"
													style={{ alignSelf: 'center' }}
												>
													{`${isEdit ? 'Edit' : 'Add'} Service Charge`}
												</div>
												<div className="title rightText color-primary-orange">
													{isEdit ? menu?.obj?.value : null}
												</div>
											</div>
										</div>
									</div>
								</div>
							),
						},
						body: () => (
							<>
								<div className="content-container">
									<Controller
										as={NumberFormat}
										customInput={TextField}
										name="serviceCharge"
										label="Service Charge Percentage %"
										autoComplete="off"
										multiline={true}
										decimalScale={2}
										isNumericString
										fixedDecimalScale
										required
										fullWidth
										ref={register}
										control={control}
										defaultValue={isEdit ? menu?.obj?.value : ''}
										onInput={() => {
											setDouble(false);
										}}
										helperText={errors?.serviceCharge?.message}
										error={errors?.serviceCharge ? true : false}
									></Controller>

									<MuiPickersUtilsProvider utils={DateFnsUtils}>
										<Controller
											as={KeyboardDatePicker}
											required
											fullWidth
											multiline={true}
											name="effectiveDate"
											margin="normal"
											id="effectiveDate"
											label="Effective Date"
											format="dd MMM yyyy"
											disablePast={isEdit ? false : true}
											value={new Date()}
											onChange={(date: Date | null) => {}}
											control={control}
											ref={register}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											defaultValue={
												isEdit ? menu?.obj?.effectiveDate : new Date()
											}
										/>
									</MuiPickersUtilsProvider>

									<div
										className="content-wrap full"
										style={{
											justifyContent: 'space-between',
											display: 'flex',
										}}
									>
										<span className="flex-space">Tax on Service Charge</span>

										<Switch
											name="isTaxable"
											checked={taxableInput.isTaxable}
											defaultChecked={isEdit ? menu?.obj?.isTaxable : null}
											onChange={e => {
												onChangeTaxableInput(e);
											}}
											color="primary"
										/>
									</div>
								</div>
							</>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => setOpenDialog(false),
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										variant: 'contained',
										form: 'open-form',
										color:
											isEdit && watchValue === 0
												? 'inherit'
												: !isEdit &&
												  (watchValue === 0 || watchValue === undefined)
												? 'inherit'
												: 'primary',
										type: 'submit',
										disabled: watchValue === 0 ? true : false,
										onClick: () => {
											handleSubmit(onSubmit)();
											setDouble(true);
										},
									},
								},
							],
						},
					}}
				/>
			</Box>

			<GeneralDeleteDialog
				ID={menu?.ID}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={'Service Charge'}
				deleteMutation={deleteOutletTaxPolicy}
				refetchQueries={[
					{
						query: GetOutletTaxPolicyDocument,
						variables: { outletID: outletID, taxType: TaxType.ServiceCharge },
					},
				]}
			/>

			<FloatButton
				// disabled={value !== 0 ? true : false}
				onClick={() => {
					setOpenDialog(true);
					setIsEdit(false);
				}}
			/>
		</>
	);
};
