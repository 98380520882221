import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import {
	PosPermission,
	useGetBusinessAnalyticsSummaryLazyQuery,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const BusinessInsightsSubmenu = () => {
	const history = useHistory();
	const { globalState, dispatch } = useContext(AppContext as any);
	const outletID = localStorage.getItem('latestOutlet');
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const { handlePermHidden } = usePermissionChecker();

	const [
		loadBusinessAnalyticsSummary,
		{
			called: businessAnalyticsSummaryCalled,
			loading: businessAnalyticsSummaryLoading,
			data: businessAnalyticsSummaryData,
		},
	] = useGetBusinessAnalyticsSummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!businessAnalyticsSummaryCalled) {
			loadBusinessAnalyticsSummary({});
		}
	}, [businessAnalyticsSummaryCalled]);

	const outletSalesDate = businessAnalyticsSummaryData?.outletSalesDate;
	const mealPeriodDate = businessAnalyticsSummaryData?.mealPeriodDate;
	const waiterSalesDate = businessAnalyticsSummaryData?.waiterSalesDate;
	const fastMovingMenuItemDate =
		businessAnalyticsSummaryData?.fastMovingMenuItemDate;
	const slowMovingMenuItemDate =
		businessAnalyticsSummaryData?.slowMovingMenuItemDate;
	const patronTypeDate = businessAnalyticsSummaryData?.patronTypeDate;
	const discountTypeDate = businessAnalyticsSummaryData?.discountTypeDate;
	const averageMealTimeDate = businessAnalyticsSummaryData?.averageMealTimeDate;

	const submenuList = [
		{
			path: `/menu/outlet-app/business-insight/business-analytic`,
			title: `Business Analytics`,
			label1: `Outlet Performance Analysis`,
			// permission: PosPermission.BizInsightBusinessAnalyticsShow,
		},
	];

	// Drawer Toggler
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	return (
		<>
			<MainHeader
				onClick={handleDrawer}
				mainBtn="menu"
				smTitle="Business Insights"
				title={user?.companyName}
				routeSegments={[{ name: 'Business Insights', current: true }]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{submenuList.map((el, index) => {
						// if (
						// 	handlePermHidden({
						// 		outletID: outletID,
						// 		permEnum: el.permission,
						// 	})
						// )
						return (
							<ListItem key={index} onClick={() => history.push(`${el.path}`)}>
								<ListItemText
									primary={
										<>
											<span className="desc">
												<span className="xsTitle">{el.title}</span>
											</span>
										</>
									}
									secondary={
										<span className="desc">
											<span className="desc">{el.label1}</span>
										</span>
									}
								/>
								<ListItemSecondaryAction>
									<IconButton edge="end" aria-label="delete">
										<KeyboardArrowRight
											onClick={() => history.push(`${el.path}`)}
										/>
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						);
					})}
				</List>
			</ContentWrapper>
		</>
	);
};
