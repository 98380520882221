import React from 'react';
import { BillReceipt } from '../Home/TableSubmenu/BillSettlement/BillReceipt/BillReceipt';
import { BillListingDetail } from './BillListingDetail';
import { BillListingList } from './BillListingList';
import { AuditLog } from '../Home/TableSubmenu/AuditLog/AuditLogNew';

const billListingRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/bill-listing`,
		},
		component: <BillListingList />,
	},
	{
		props: {
			exact: true,
			path: '/menu/outlet-app/:outletID/bill-listing/:billID/bill-detail',
		},
		component: <BillListingDetail />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/outlet-app/:outletID/bill-listing/:billID/bill-detail/audit-log',
		},
		component: <AuditLog mode="bill-listing" module="audit-log" />,
	},
	{
		props: {
			exact: true,
			path: '/menu/outlet-app/:outletID/bill-listing/:billID/bill-receipt',
		},
		component: <BillReceipt mode="bill-printing" />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/outlet-app/:outletID/bill-listing/:billID/bill-receipt/void-payment',
		},
		component: <BillReceipt mode="void-payment" />,
	},
	{
		props: {
			exact: true,
			path:
				'/menu/outlet-app/:outletID/bill-listing/:billID/bill-receipt/void-bill',
		},
		component: <BillReceipt mode="void-bill" />,
	},
];

export default billListingRoutes;
