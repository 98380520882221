import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { StyledMenu } from '@ifca-root/react-component/src/components/others/Menu';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Checkbox,
	Divider,
	FormControl,
	Grid,
	InputAdornment,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetRemainingMenuItemsForDigitalDocument,
	useCreateDigitalMenuItemPriceMutation,
	useGetDigitalMenuCategoryQuery,
	useGetOutletQuery,
	useGetPatronQuery,
	useGetRemainingMenuItemsForDigitalQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import './priceMenu.scss';

const sampleData = [
	{
		ID: '111',
		name: 'Beer Name 1',
	},
	{
		ID: '222',
		name: 'Beer Name 3',
	},
	{
		ID: '333',
		name: 'Beer Name 4',
	},
];

interface PatronPrice {
	patronPrice: any;
}

export const DigitalMenuPricingForm = (props: any) => {
	useEffect(() => {
		console.log('DigitalMenuPricingForm');
	}, []);
	const history = useHistory();
	const location = useLocation();
	const { [`outletID`]: outletID, digitalMenuID, menuCategoryID } = useParams<
		Record<string, any>
	>();

	const [openExitDialog, setOpenExitDialog] = useState(false);

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const PriceSchema = yup.object().shape({
		patronPrice: yup
			.array()
			.of(
				yup.object().shape({
					selected: yup.boolean(),
					patronInfo: yup.array().of(
						yup.lazy(details => {
							return yup.object().shape(
								Object.fromEntries(
									Object.keys(details).map(key => {
										return [
											key,
											yup
												.number()
												.transform(value => (isNaN(value) ? 0 : value))
												.required('Required field'),
										];
									}),
								),
							);
						}),
					),
				}),
			)
			.compact(v => !v?.selected),
	});

	const {
		control,
		register,
		handleSubmit,
		watch,
		setValue,
		errors,
		setError,
		formState: { isSubmitted, isSubmitting, isValidating },
	} = useForm<PatronPrice>({
		defaultValues: {
			patronPrice: [],
		},
		mode: 'onSubmit',
		resolver: yupResolver(PriceSchema),
	});

	const [menuItemName, setMenuItemName] = useState(null);

	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS.
	//QUERIES AND MUTATIONS

	const [
		submitDigitalMenuItemPrice,
		{
			data: submitDigitalMenuItemPriceData,
			loading: submitDigitalMenuItemPriceLoading,
			called: submitDigitalMenuItemPriceCalled,
			error: submitDigitalMenuItemPriceError,
		},
	] = useCreateDigitalMenuItemPriceMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${digitalMenuID}/edit/digital-menu-pricing/${menuCategoryID}`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
		},
	});

	const submitCalled = submitDigitalMenuItemPriceCalled;

	const {
		data: { getMenuItemForDigitalMenuItemPrice } = {
			getMenuItemForDigitalMenuItemPrice: [],
		},
		loading: remainingMenuItemsForDigitalLoading,
	} = useGetRemainingMenuItemsForDigitalQuery({
		variables: {
			digitalMenuCategoryID: menuCategoryID,
			outletID: outletID,
		},
	});

	const {
		data: { getPatron } = { getPatron: [] },
		loading: patronLoading,
	} = useGetPatronQuery({
		variables: {
			outletID: outletID,
			isActive: true,
		},
	});

	const {
		data: getOtherDigitalMenuCategory,
		loading: OtherDigitalMenuCategoryLoading,
	} = useGetDigitalMenuCategoryQuery({
		fetchPolicy: 'network-only',
		variables: {
			digitalMenuID: digitalMenuID,
		},
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	//COMPONENTS
	//COMPONENTS
	//COMPONENTS
	//COMPONENTS
	//COMPONENTS

	const patron_content_outlined = (
		data: any,
		name: any,
		register: any,
		control: any,
		styleJson?: any,
		itemIndex?: number,
	) => {
		return (
			<Grid container>
				{data?.map((v: any, i: number) => (
					<Grid item xs={3}>
						<>
							<Grid item xs={12}>
								<div className="desc text-noflow" style={{ ...styleJson }}>
									{v?.patronClass}
								</div>
								<div
									className="desc custom-div-add form-patron-price"
									style={{ marginRight: '4px' }}
								>
									<Controller
										render={({ onChange, onBlur, value }) => (
											<NumberFormat
												customInput={TextField}
												allowNegative={false}
												fullWidth
												autoComplete="off"
												isNumericString
												decimalScale={2}
												fixedDecimalScale
												thousandSeparator
												variant="outlined"
												className="custom-add-multiple"
												disabled={
													!watch(`${name}.selected`) === false ? false : true
												}
												onValueChange={(value: any) => {
													const { floatValue } = value;
													onChange(floatValue);
												}}
											/>
										)}
										name={`${name}.patronInfo[${i + 1}].${v?.ID}`}
										control={control}
										ref={register}
										className="desc custom-add-multiple"
										helperText={
											errors?.patronPrice?.[itemIndex]?.patronInfo?.[i + 1]?.[
												v?.ID
											]?.message
										}
										error={
											errors?.patronPrice?.[itemIndex]?.patronInfo?.[i + 1]?.[
												v?.ID
											]
										}
									/>
								</div>
							</Grid>
						</>
					</Grid>
				))}
			</Grid>
		);
	};

	//ON SUBMIT
	//ON SUBMIT
	//ON SUBMIT
	//ON SUBMIT
	//ON SUBMIT

	const onSubmit = (data: any) => {
		let createInput = {
			digitalMenuCategoryID: menuCategoryID,
			outletID: outletID,
			digitalMenuItemInput: [],
		};

		let menuItemInput = data?.patronPrice
			?.filter(k => k.selected)
			.map((v, i) => {
				if (v.selected) {
					return {
						menuItemID: v.menuItemID,
						digitalMenuItemPrice: v?.patronInfo?.map((x, index) => {
							let key = Object.keys(x)[0];
							return {
								patronID: key,
								price: parseFloat(x[key]),
							};
						}),
					};
				}
			});

		createInput.digitalMenuItemInput = menuItemInput;

		submitDigitalMenuItemPrice({
			variables: { ...createInput },
			refetchQueries: [
				{
					query: GetRemainingMenuItemsForDigitalDocument,
					variables: {
						digitalMenuCategoryID: menuCategoryID,
						outletID: outletID,
					},
				},
			],
		});
	};

	return (
		<>
			{remainingMenuItemsForDigitalLoading && <Loading />}
			{patronLoading && <Loading />}
			{outletLoading && <Loading />}
			{submitDigitalMenuItemPriceLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Digital Menu & Pricing' },
					{ name: 'Menu Items' },
					{ name: 'Menu Item Pricing', current: true },
				]}
				rightRouteSegments={[{ name: 'New' }]}
			/>
			<DynamicSubHeader
				// style={{
				// 	width:
				// 		isDesktop && globalState.drawerOpen
				// 			? 'calc(100% - 288px)'
				// 			: isDesktop && !globalState.drawerOpen
				// 			? `calc(100% - 48px)`
				// 			: null,
				// }}
				handleClick={e => handleClick(e, menuCategoryID, 0)}
				title={menuItemName ?? (location.state as string)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						{getOtherDigitalMenuCategory?.getDigitalMenuCategory?.map(
							(x, i) => (
								<MenuItem
									key={i}
									value={x.name}
									defaultValue={x.ID}
									onClick={e => {
										history.push({
											pathname: `/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${digitalMenuID}/edit/digital-menu-pricing/${x.ID}/add`,
										});
										setMenuItemName(x.name);
									}}
								>
									{x.name}
								</MenuItem>
							),
						)}
					</StyledMenu>
				}
			/>
			<ContentWrapper float style={{ marginTop: isDesktop ? '95px' : '28px' }}>
				{!remainingMenuItemsForDigitalLoading &&
				!patronLoading &&
				!outletLoading &&
				(getMenuItemForDigitalMenuItemPrice === undefined ||
					getMenuItemForDigitalMenuItemPrice?.filter(x => x?.stopSales === null)
						?.length === 0) ? (
					<EmptyList
						title="No Menu Item Record(s) remaining"
						subtitle="Add Menu Item(s) to proceed."
					/>
				) : (
					<>
						<MsgAlert
							message={'Select Menu Item, define display sequence and pricing.'}
						/>
						<CardContents devFullWidth={true}>
							<Grid container>
								<Grid item xs={1}></Grid>
								<Grid item xs={8} className="left">
									<div className="xxTitle" style={{ fontWeight: 'bold' }}>
										Menu Item
									</div>
								</Grid>

								{watch(`patronPrice`).some(item => item?.selected) && (
									<Grid
										item
										xs={3}
										className="right"
										style={{ textAlign: 'end' }}
									>
										<div className="xxTitle">Visitor Price</div>
									</Grid>
								)}
							</Grid>

							<Divider style={{ display: 'flex', width: '100%' }} />

							{getMenuItemForDigitalMenuItemPrice
								?.filter(x => x?.stopSales === null)
								?.map((v: any, i: number) => {
									const patronPrice = `patronPrice[${i}]`;
									const visitorID = getPatron?.filter(
										v => v.patronClass === 'Visitor',
									)[0]?.ID;
									return (
										<Grid container>
											<Grid item xs={1}>
												<FormControl
													component="fieldset"
													style={{ paddingRight: 'revert' }}
												>
													<Checkbox
														onChange={e => {
															register(`${patronPrice}.menuItemID`);
															setValue(`${patronPrice}.menuItemID`, v?.ID);
														}}
														name={`${patronPrice}.selected`}
														inputRef={register()}
														color="primary"
													/>
												</FormControl>
											</Grid>
											<Grid item xs={8} style={{ paddingTop: '14px' }}>
												<div
													className="xxTitle left"
													style={{
														textDecorationLine: 'underline',
														fontWeight: 'bold',
													}}
												>
													{v.name}
												</div>
												<div
													className="desc"
													style={{
														padding: '6px 0px',
														overflowWrap: 'break-word',
														wordWrap: 'break-word',
														whiteSpace: 'pre-wrap',
														hyphens: 'auto',
													}}
												>
													{v.description}
												</div>
											</Grid>
											{watch(`${patronPrice}.selected`) && (
												<>
													<Grid item xs={3}>
														<div
															className="desc form-visitor-price"
															style={{ color: '#ff9800', fontWeight: 'bold' }}
														>
															<Controller
																render={({ onChange, onBlur, value }) => (
																	<NumberFormat
																		customInput={TextField}
																		allowNegative={false}
																		fullWidth
																		autoComplete="off"
																		isNumericString
																		decimalScale={2}
																		fixedDecimalScale
																		thousandSeparator
																		variant="outlined"
																		margin="dense"
																		// ref={register}
																		disabled={
																			!watch(`${patronPrice}.selected`) ===
																			false
																				? false
																				: true
																		}
																		className="custom-add desc"
																		onValueChange={(value: any) => {
																			const { floatValue } = value;
																			onChange(floatValue);
																		}}
																	/>
																)}
																name={`${patronPrice}.patronInfo[0].${
																	getPatron?.filter(
																		v => v.patronClass === 'Visitor',
																	)[0]?.ID
																}`}
																control={control}
																ref={register}
																className="desc custom-add"
																helperText={
																	errors?.patronPrice?.[i]?.patronInfo?.[0]?.[
																		visitorID
																	]?.message
																}
																error={
																	errors?.patronPrice?.[i]?.patronInfo?.[0]?.[
																		visitorID
																	]
																}
															/>
														</div>
													</Grid>

													<Grid item xs={1}></Grid>
													<Grid item xs={11}>
														{patron_content_outlined(
															getPatron
																?.filter(x => x?.isActive)
																?.filter(v => v.patronClass !== 'Visitor'),
															`${patronPrice}`,
															register,
															control,
															{
																overflowWrap: 'break-word',
																wordWrap: 'break-word',
																hyphens: 'auto',
																width: 'max-content',
															},
															i,
														)}
													</Grid>
												</>
											)}
										</Grid>
									);
								})}
						</CardContents>
					</>
				)}
				<DynamicFooter
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					options={[
						{
							name: 'save',
							// onClick: () => {
							// 	handleSubmit(onSubmit)();
							// },
							color: 'primary',
							props: {
								type: 'submit',
							},
							onClick: () => {
								handleSubmit(data => !isSubmitting && onSubmit(data))();
							},
						},
					]}
				/>
				<ExitConfirmationDialog
					openExitDialog={openExitDialog}
					setOpenExitDialog={setOpenExitDialog}
					backPath={`/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${digitalMenuID}/edit/digital-menu-pricing/${menuCategoryID}`}
				/>
				{/* <CommonDialog
					fullWidth={true}
					open={openExitDialog}
					onClose={() => {
						setOpenExitDialog(false);
					}}
					sections={{
						header: {
							dynamic: (
								<div>
									<div className="dialogo-dynamic-content">
										<span
											className="title c-orange flex-space"
											style={{
												fontSize: '13px',
												fontWeight: 'bold',
											}}
										>
											Exit Confirmation
										</span>
									</div>
								</div>
							),
						},
						body: () => (
							<CardContents>
								<span className="">
									Are you sure you want to exit? Your changes will not be saved.
								</span>
							</CardContents>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
										},
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
											history.push(
												`/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${digitalMenuID}/edit/digital-menu-pricing/${menuCategoryID}`,
											);
										},
										variant: 'contained',
										color: 'primary',
									},
								},
							],
						},
					}}
				/> */}
			</ContentWrapper>
		</>
	);
};
