import React from 'react';
import { ActivatedUser } from './ActivatedUser';
import { ChangePassword } from './ChangePassword';
import { CreatePassword } from './CreatePassword';
import { EditProfile } from './EditProfile';
import { ForgotPassword } from './ForgotPassword';
import { Login } from './Login';
import { Logout } from './Logout';
import { NotFound } from './NotFound';
import { Profile } from './Profile';
import { ResetPassword } from './ResetPassword';

const accountRoutes = [
	{
		props: { exact: true, path: '/user/activate/:token' },
		component: <ActivatedUser />,
	},
	{
		props: { exact: true, path: '/user/create-password/:token' },
		component: <CreatePassword />,
	},
	{
		props: { exact: true, path: '/profile/change-password' },
		component: <ChangePassword />,
	},
	{
		props: { exact: true, path: `/reset-password/:token` },
		component: <ResetPassword />,
	},
	{
		props: { exact: true, path: '/profile/edit' },
		component: <EditProfile />,
	},
	{
		props: { exact: true, path: '/profile' },
		component: <Profile />,
	},
	{
		props: { exact: true, path: '/authentication/404' },
		component: <NotFound />,
	},
	{
		props: { exact: true, path: '/logout' },
		component: <Logout />,
	},
	{
		props: { exact: true, path: '/login' },
		component: <Login />,
	},
	{
		props: { exact: true, path: '/forgot' },
		component: <ForgotPassword />,
	},
];

export default accountRoutes;
