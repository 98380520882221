import { Button, ButtonProps } from '@material-ui/core';
import './installbanner.scss';
import React from 'react';

interface InstallBannerProps {
	ButtonOption?: {
		section?: {
			props?: any;
		};
		option1?: {
			props?: ButtonProps;
		};
		option2?: {
			props?: ButtonProps;
		};
	};
}
export const InstallBanner = ({ ButtonOption }: InstallBannerProps) => {
	return (
		<div className="banner-section" {...ButtonOption?.section?.props}>
			<div className="xsTitle fs-14">Add to Home Screen?</div>
			<div className="button-section ">
				<Button
					{...ButtonOption?.option1?.props}
					variant="outlined"
					color="primary"
					component="span"
					className="common-button m-r-10"
				>
					Dismiss
				</Button>
				<Button
					{...ButtonOption?.option2?.props}
					variant="contained"
					color="primary"
					component="span"
					className="common-button m-l-10"
				>
					Install Now
				</Button>
			</div>
		</div>
	);
};
