import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	PosPermission,
	useAccountMappingTaxGlInsertMutation,
	useAccountMappingTaxGlListQuery,
	useGetOutletBillChargesQuery,
	useGetOutletBillEntChargesQuery,
	useGetOutletBillOcChargesQuery,
	useGetOutletQuery,
	useSubmitOutletGlInterfaceMutation,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { IAction } from 'helpers/model';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { Reducer, useContext, useEffect, useReducer } from 'react';
import { useHistory } from 'react-router';
import { renderTypePaymentDefaultValue } from './components/Common';
import './glInterface.scss';
import { useSnackBar } from 'helpers/hooks/useSnackBar';
import { Charges } from '../TaxMapping/components/Charges';
import { Discount } from '../TaxMapping/components/Discount';

type Mode = string | 'edit' | 'save';
interface props {
	mode?: Mode;
	TaxSSTMappingCharges: any;
	TaxSCMappingCharges: any,
	TaxGSTMappingCharges: any,
	TaxVATMappingCharges: any,
	type?: any;
}
export const TaxMapping = () => {
	let history = useHistory();
	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);
	const { dispatch: GlobalDispatch } = useContext(AppContext as any);

	const { snackFunc, close, snackBarMessage, openSnackBar } = useSnackBar()
	const { handlePermDisabled } = usePermissionChecker();
	const outletID = localStorage.getItem('latestOutlet');

	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: localStorage.getItem('latestOutlet') },
	});

	const {
		loading: chargesLoading,
		data: { getOutletBillCharges } = { getOutletBillCharges: [] },
		refetch: chargesRefetch,
	} = useGetOutletBillChargesQuery({
		variables: {
			outletID: localStorage.getItem('latestOutlet'),
		},
		fetchPolicy: 'no-cache',
	});

	// const {
	// 	loading: ocChargesLoading,
	// 	data: { getOutletBillOCCharges } = { getOutletBillOCCharges: [] },
	// 	refetch: ocChargesRefetch,
	// } = useGetOutletBillOcChargesQuery({
	// 	variables: {
	// 		outletID: localStorage.getItem('latestOutlet'),
	// 	},
	// 	fetchPolicy: 'no-cache',
	// });

	// const {
	// 	loading: entChargesLoading,
	// 	data: { getOutletBillEntCharges } = { getOutletBillEntCharges: [] },
	// 	refetch: entChargesRefetch,
	// } = useGetOutletBillEntChargesQuery({
	// 	variables: {
	// 		outletID: localStorage.getItem('latestOutlet'),
	// 	},
	// 	fetchPolicy: 'no-cache',
	// });

	const {
		data: { AccountMappingTaxGLList } = { AccountMappingTaxGLList: [] },
		refetch: chargesRefetch1,
	  } = useAccountMappingTaxGlListQuery({
		variables: {
		  outletID: outletID,
		  accountID: loggedInUser?.accountID ,
		},
		fetchPolicy: 'no-cache',
	  })
	
	  const [
		AccountMappingTaxGLInsert,
		{ loading: insertLoading, error: insertError },
	  ] = useAccountMappingTaxGlInsertMutation({})

	// const [
	// 	submitOutletGLInterface,
	// 	{ loading: insertLoading, error: insertError },
	// ] = useSubmitOutletGlInterfaceMutation({
	// 	onError: () => {
	// 		setOpenSnackBar(true);
	// 		setSnackBarMsg('Update record unsuccessful!');
	// 	},
	// });
	const initialState: props = {
		mode: 'edit',
		TaxSSTMappingCharges: [],
		TaxSCMappingCharges: [],
		TaxGSTMappingCharges: [],
		TaxVATMappingCharges: []
	};

	const reducer: Reducer<props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};
	const [state, dispatch] = useReducer(reducer, initialState);

	const handleReturnArr = array =>
	array.map(x => (
		console.log(x, "x2"),{
	  ...x,
	  outletTaxGLAcct: {
	  revenueDiv: x.outletTaxGLAcct?.revenueDiv || '',
	  revenueDept: x.outletTaxGLAcct?.revenueDept || '',
	  revenueDebit: x.outletTaxGLAcct?.revenueDebit || '',
	  revenueCredit: x.outletTaxGLAcct?.revenueCredit || '',
	  reverseDiv: x.outletTaxGLAcct?.reverseDiv || '',
	  reverseDept: x.outletTaxGLAcct?.reverseDept || '',
	  reverseDebit: x.outletTaxGLAcct?.reverseDebit || '',
	  reverseCredit: x.outletTaxGLAcct?.reverseCredit || '',
	  type: x.outletTaxGLAcct?.type || 'GL',
	//   GLType: 'Çharge'
	},
	outletTaxDiscountGLAcct:{
	revenueDiv: x.outletTaxDiscountGLAcct?.revenueDiv || '',
	revenueDept: x.outletTaxDiscountGLAcct?.revenueDept || '',
	revenueDebit: x.outletTaxDiscountGLAcct?.revenueDebit || '',
	revenueCredit: x.outletTaxDiscountGLAcct?.revenueCredit || '',
	reverseDiv: x.outletTaxDiscountGLAcct?.reverseDiv || '',
	reverseDept: x.outletTaxDiscountGLAcct?.reverseDept || '',
	reverseDebit: x.outletTaxDiscountGLAcct?.reverseDebit || '',
	reverseCredit: x.outletTaxDiscountGLAcct?.reverseCredit || '',
	type: x.outletTaxDiscountGLAcct?.type || 'GL',
	// GLType: 'Discount'

    }
}
	))
	// .sort((a, b) => {
	//   if (a.Description.toUpperCase() < b.Description.toUpperCase()) return -1
	//   if (a.Description.toUpperCase() > b.Description.toUpperCase()) return 1
	// })
	useEffect(() => {
		// Charges
		if (AccountMappingTaxGLList?.length > 0) {
		  dispatch({
			type: 'TaxSSTMappingCharges',
			payload: handleReturnArr(
			  AccountMappingTaxGLList?.filter(x => x?.DescriptionType === 'Service Tax')
			),
		  })
	
		  dispatch({
			type: 'TaxSCMappingCharges',
			payload: handleReturnArr(
			  AccountMappingTaxGLList?.filter(x => x?.DescriptionType === 'ServiceCharge')
			),
		  })
	
		  dispatch({
			type: 'TaxGSTMappingCharges',
			payload: handleReturnArr(
			  AccountMappingTaxGLList?.filter(x => x?.DescriptionType === 'GST')
			),
		  })
	
		  dispatch({
			type: 'TaxVATMappingCharges',
			payload: handleReturnArr(
			  AccountMappingTaxGLList?.filter(x => x?.DescriptionType === 'VAT')
			),
		  })
		}
	  }, [AccountMappingTaxGLList])
	// console.log("state", state)

	const useStyles = makeStyles({
		table: {
			minWidth: 650,
		},
	});
	const classes = useStyles();

	const handleGLInputs = Array =>

    Array?.map(x => ( 	console.log(x, "x carry apa?"),
	{
      Description: x.Description,
      DescriptionType: x.DescriptionType,
      id: x.ID,
      taxID: x?.taxID,
      GL: {
		revenueDiv: x.outletTaxGLAcct?.revenueDiv || '',
		revenueDept: x.outletTaxGLAcct?.revenueDept || '',
		revenueDebit: x.outletTaxGLAcct?.revenueDebit || '',
		revenueCredit: x.outletTaxGLAcct?.revenueCredit || '',
		reverseDiv: x.outletTaxGLAcct?.reverseDiv || '',
		reverseDept: x.outletTaxGLAcct?.reverseDept || '',
		reverseDebit: x.outletTaxGLAcct?.reverseDebit || '',
		reverseCredit: x.outletTaxGLAcct?.reverseCredit || '',
		type: x.outletTaxGLAcct?.type || 'GL',
	},
	GLDisc:  {
	revenueDiv: x.outletTaxDiscountGLAcct?.revenueDiv || '',
	revenueDept: x.outletTaxDiscountGLAcct?.revenueDept || '',
	revenueDebit: x.outletTaxDiscountGLAcct?.revenueDebit || '',
	revenueCredit: x.outletTaxDiscountGLAcct?.revenueCredit || '',
	reverseDiv: x.outletTaxDiscountGLAcct?.reverseDiv || '',
	reverseDept: x.outletTaxDiscountGLAcct?.reverseDept || '',
	reverseDebit: x.outletTaxDiscountGLAcct?.reverseDebit || '',
	reverseCredit: x.outletTaxDiscountGLAcct?.reverseCredit || '',
	type: x.outletTaxDiscountGLAcct?.type || 'GL',
	// GLType: 'Discount'
	}
	
} ),

    )

const ChargeInput = [
    ...state.TaxSSTMappingCharges,
    ...state.TaxSCMappingCharges,
    ...state.TaxGSTMappingCharges,
    ...state.TaxVATMappingCharges,
  ]

  
  const handleSubmit = () => {
    AccountMappingTaxGLInsert({
      variables: {
		outletID: outletID,
		accountID: loggedInUser?.accountID,
        TaxGLInput: { 
			TaxGL: handleGLInputs(
			ChargeInput, 
		 )},
      },
    }).then(i => {
      if (i.data.AccountMappingTaxGLInsert) {
        snackFunc('Update Successfully ', false)
        chargesRefetch1();
		chargesRefetch();
      } else {
        snackFunc('Update Fail!', false)
      }
    })
  }

	return (
		<>
			{(
				// chargesLoading ||
				// ocChargesLoading ||
				// entChargesLoading ||
				insertLoading) && <Loading />}
			<MainHeader
				onClick={() =>
					history.push('/menu/outlet-app/account-journals/account-mapping')
				}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Account Mapping' },
					{ name: 'Tax GL Interface', current: true },
				]}
			/>
			<ContentWrapper footer>
				<TableContainer component={Paper}>
					<Table className={classes.table} aria-label="simple table">
						<TableBody className="mapping-table">
							<TableRow className="mapping-tr">
								<TableCell className="header-bg table-row-sticky">
									Type of Transaction
								</TableCell>
								<TableCell className="header-bg" align="center">
									Division
								</TableCell>
								<TableCell className="header-bg" align="center">
									Department
								</TableCell>
								<TableCell className="header-bg" align="center">
									Type
								</TableCell>
								<TableCell className="header-bg" align="center">
									Debit
								</TableCell>
								<TableCell className="header-bg" align="center">
									Credit
								</TableCell>
							</TableRow>

							<Charges
								state={state}
								dispatch={dispatch}
								// outlet={getOutlet[0]}
							/>
							<Discount 
							state={state} 
							dispatch={dispatch} 
							outlet={getOutlet[0]} />
						</TableBody>
					</Table>
				</TableContainer>
			</ContentWrapper>
			<DynamicFooter
				options={[
					{
						name: state.mode === 'edit' ? 'Edit' : 'Save',
						onClick: () => {
							dispatch({
								type: 'mode',
								payload: state.mode === 'edit' ? 'save' : 'edit',
							});
							state.mode === 'save' && handleSubmit();
						},

						color: 'primary',
						disabled: handlePermDisabled({
							outletID: outletID,
							permEnum:
								PosPermission.AccountJournalsAccountsMappingOutletGlInterfaceUpdate,
						}),
					},
				]}
			/>
		</>
	);
};
