import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { convertDate24Hrs } from 'helpers/hooks/formatDate';
import { toProperCase } from 'helpers/hooks/stringNumberFunction/stringConverter';
import React from 'react';
import './orderItemReceipt.scss';
import { alignment } from '../../BillSettlement/BillReceipt/BillReceiptFunctions';
import * as bxlPOS from '../../BillSettlement/BixolonJS/bxlpos.js';

interface orderItemReceiptProps {
	outletName: string;
	data: any;
	printerName?: any;
	orderItemData?: any;
	voidItemData?: any;
	voidQuantity?: any;
	reasonCode?: any;
	remark?: any;
	hidden?: boolean;
	mode?: string;
}

export const OrderItemReceipt = (props: orderItemReceiptProps) => {
	const {
		outletName,
		data,
		printerName,
		orderItemData,
		voidItemData,
		voidQuantity,
		reasonCode,
		remark,
		hidden,
		mode,
	} = props;

	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

	return (
		<div
			id={printerName}
			style={
				hidden
					? {
							zIndex: -40,
							top: 0,
							left: 0,
							position: 'fixed',
							width: '300px',
							fontSize: '18px',
					  }
					: {
							zIndex: 40,
							position: 'relative',
					  }
			}
		>
			<CardContents>
				{mode !== 'void' ? (
					<div className="content-wrap full" style={{ marginRight: '5px' }}>
						<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
							Outlet: &nbsp;
							<span className="lgDesc">{outletName}</span>
						</div>

						<div
							className="mdTitle-bold flex-space"
							style={{ paddingBottom: '5px' }}
						>
							{`Order No: ${data?.docNo}`}
							<div className="mdTitle-bold right-info">{`Cover: ${data?.pax}`}</div>
						</div>

						<div
							className="mdTitle-bold right-info"
							style={{ paddingBottom: '5px' }}
						>
							{`Table: ${data?.table?.prefix + data?.table?.tableNumber}`}
						</div>

						<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
							{Array.from(
								new Set(
									orderItemData?.map(x => x?.menuItem?.kitchenPrinter?.name),
								),
							)}
						</div>

						<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
							{loggedInUser?.name}
							<div className="mdTitle-bold right-info">
								<span className="mdDesc">
									{convertDate24Hrs(data?.createdTs)}
								</span>
							</div>
						</div>
						<hr className="receipt-divider" />

						{orderItemData?.map((v, index) => (
							<>
								<div
									className="mdTitle-bold"
									style={{
										paddingBottom: '5px',
										lineHeight: '14px',
										alignSelf: 'center',
										// fontSize: '16px',
									}}
								>
									{`${v?.menuItem?.name}`}&nbsp; {`x ${v?.quantity}`}
									{/* <div
										className="desc"
										style={{
											// paddingBottom: '5px',
											alignSelf: 'center',
											fontSize: '16px',
										}}
									>{`x ${v?.quantity}`}</div> */}
								</div>

								<div
									className="mdTitle-bold"
									style={{
										alignSelf: 'center',
										paddingBottom: '5px',
										// fontSize: '16px'
									}}
								>
									{v?.specialRequest}
								</div>

								{v?.orderItemOption?.length
									? v?.orderItemOption?.map(x => (
											<>
												{x?.orderItemOptionItem?.map(x => (
													<div
														className="mdTitle-bold"
														style={{
															// color: '#757575',
															paddingBottom: '5px',
															// fontWeight: 500,
															alignSelf: 'center',
															// fontSize: '16px',
														}}
													>
														{x?.optionItem?.name}
													</div>
												))}
											</>
									  ))
									: v?.orderItemSetMenuOption?.map(x => (
											<>
												{x?.orderItemSetMenuOptionItem?.map(e => (
													<div
														className="mdTitle-bold"
														style={{
															// color: '#757575',
															// fontWeight: 500,
															paddingBottom: '5px',
															// fontSize: '16px',
														}}
													>
														{e?.setMenuOptionItem?.menuItem?.name}
													</div>
												))}
											</>
									  ))}
							</>
						))}

						<hr className="receipt-divider" style={{ marginTop: '10px' }} />
					</div>
				) : (
					<div className="content-wrap full" style={{ marginRight: '5px' }}>
						<div
							className={'mdTitle-bold'}
							style={{
								marginBlock: '15px',
								textAlign: 'center',
								color: '#000000',
							}}
						>
							Void Item
						</div>
						<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
							Outlet: &nbsp;
							<span className="lgDesc">{outletName}</span>
						</div>

						<div
							className="mdTitle-bold flex-space"
							style={{ paddingBottom: '5px' }}
						>
							{`Order No: ${data?.docNo}`}
							<div className="mdTitle-bold right-info">{`Cover: ${data?.pax}`}</div>
						</div>

						<div
							className="mdTitle-bold right-info"
							style={{ paddingBottom: '5px' }}
						>
							{`Table: ${data?.table?.prefix + data?.table?.tableNumber}`}
						</div>

						{voidItemData?.menuItem?.kitchenPrinter !== null && (
							<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
								Printer: &nbsp;
								{voidItemData?.menuItem?.kitchenPrinter?.name}
							</div>
						)}

						<div className={'mdTitle-bold'} style={{ paddingBottom: '5px' }}>
							{loggedInUser?.name}
							<div className="mdTitle-bold right-info">
								<span className="mdDesc">
									{convertDate24Hrs(new Date().toISOString())}
									{/* {new Date().toISOString()} */}
								</span>
							</div>
						</div>
						<hr className="receipt-divider" />

						<div
							className="desc flex-space"
							style={{
								// paddingBottom: '5px',
								alignSelf: 'center',
								fontSize: '16px',
							}}
						>
							{`${voidItemData?.menuItem?.name}`}
							<div
								className="desc right-info"
								style={{
									// paddingBottom: '5px',
									alignSelf: 'center',
									fontSize: '16px',
								}}
							>{`x ${voidQuantity}`}</div>
						</div>

						{voidItemData?.orderItemOption?.length
							? voidItemData?.orderItemOption?.map(x => (
									<>
										{x?.orderItemOptionItem?.map(x => (
											<div
												className="desc flex-space"
												style={{
													color: '#000000',
													fontWeight: 500,
													alignSelf: 'center',
													fontSize: '14px',
												}}
											>
												{x?.optionItem?.name}
											</div>
										))}
									</>
							  ))
							: voidItemData?.orderItemSetMenuOption?.map(x => (
									<>
										{x?.orderItemSetMenuOptionItem?.map(e => (
											<div
												className="desc"
												style={{
													color: '#000000',
													fontWeight: 500,
													paddingBottom: '2px',
													fontSize: '14px',
												}}
											>
												{e?.setMenuOptionItem?.menuItem?.name}
											</div>
										))}
									</>
							  ))}

						<div className="session" style={{ color: '#000000' }}>
							<div className="flex session">
								<div
									className="flex-space desc"
									style={{ alignSelf: 'center' }}
								>
									Reason:
									<span style={{ fontSize: '14px' }}>{` ${reasonCode}`}</span>
								</div>
							</div>
						</div>
						{remark && (
							<div
								className="session"
								style={{ color: '#000000', marginTop: '2px' }}
							>
								<div className="flex session">
									<div
										className=" flex-space desc"
										style={{ alignSelf: 'center' }}
									>
										Remark:
										<span>{` ${remark}`}</span>
									</div>
								</div>
							</div>
						)}

						<hr className="receipt-divider" style={{ marginTop: '10px' }} />
					</div>
				)}
			</CardContents>
		</div>
	);
};

export const OrderReceipt = (
	outletName,
	orderData,
	tableData,
	orderItemData,
	orderMode,
	voidItemData,
	voidQuantity,
	reasonCode,
	remark,
) => {
	if (orderMode === 'void') {
		bxlPOS.printText(`Void Item\n\n`, 0, 0, true, false, false, 0, 1);
	}

	alignment(`Outlet: ${outletName}`, `\n`, true);

	alignment(
		`Order No: ${orderData?.docNo}`,
		`Cover: ${orderData?.pax}\n`,
		true,
	);

	const printerName = Array.from(
		new Set(orderItemData?.map(x => x?.menuItem?.kitchenPrinter?.name)),
	);

	if (orderMode === 'void') {
		alignment(
			`${printerName}`,
			`Table: ${tableData?.prefix + tableData?.tableNumber}\n`,
			true,
		);
	} else {
		alignment(
			`${printerName}`,
			`Table: ${tableData?.prefix + tableData?.tableNumber}\n`,
			true,
		);
	}

	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

	if (orderMode === 'void') {
		alignment(
			`${loggedInUser?.name}`,
			`${convertDate24Hrs(new Date().toISOString())}\n`,
			true,
		);
	} else {
		alignment(
			`${loggedInUser?.name}`,
			`${convertDate24Hrs(orderData?.createdTs)}\n`,
			true,
		);
	}

	bxlPOS.printText(
		`------------------------------------------\n`,
		0,
		0,
		true,
		false,
		false,
		0,
		1,
	);

	{
		orderItemData?.map(oi => {
			if (orderMode === 'void') {
				if (oi?.isTakeaway === true) {
					alignment(
						`${oi?.menuItem?.kitchenName}`,
						`(T) x${voidQuantity}\n`,
						false,
					);
				} else if (oi?.isTakeaway === false) {
					alignment(
						`${oi?.menuItem?.kitchenName}`,
						`x${voidQuantity}\n`,
						false,
					);
				}
			} else {
				if (oi?.isTakeaway === true) {
					alignment(
						`${oi?.menuItem?.kitchenName}`,
						`(T) x${oi?.quantity}\n`,
						false,
					);
				} else if (oi?.isTakeaway === false) {
					alignment(
						`${oi?.menuItem?.kitchenName}`,
						`x${oi?.quantity}\n`,
						false,
					);
				}
			}

			if (oi?.specialRequest) {
				alignment(`${oi?.specialRequest}`, `\n`, false);
			}

			if (oi?.orderItemOption?.length > 0) {
				oi?.orderItemOption?.map(oiOption => {
					oiOption?.orderItemOptionItem?.map(oiOptionItem => {
						alignment(`${oiOptionItem?.optionItem?.name}`, `\n`, false);
					});
				});
			} else if (oi?.orderItemSetMenuOption?.length > 0) {
				oi?.orderItemSetMenuOption?.map(oiSetMenuOption => {
					oiSetMenuOption?.orderItemSetMenuOptionItem?.map(
						oiSetMenuOptionItem => {
							alignment(
								`${oiSetMenuOptionItem?.menuItem?.kitchenName}`,
								`\n`,
								false,
							);
						},
					);
				});
			}

			if (orderMode === 'void') {
				alignment(`Reason: ${reasonCode}`, `\n`, false);

				if (remark) {
					alignment(`Remark: ${remark}`, `\n`, false);
				}
			}
		});
	}

	// if (orderMode === 'void') {
	// 	alignment(`${voidItemData?.menuItem?.name}`, `x${voidQuantity}\n`, false);
	// 	if (voidItemData?.orderItemOption?.length > 0) {
	// 		voidItemData?.orderItemOption?.map(oiOption => {
	// 			oiOption?.orderItemOptionItem?.map(oiOptionItem => {
	// 				alignment(`${oiOptionItem?.optionItem?.name}`, `\n`, false);
	// 			});
	// 		});
	// 	} else if (voidItemData?.orderItemSetMenuOption?.length > 0) {
	// 		voidItemData?.orderItemSetMenuOption?.map(oiSetMenuOption => {
	// 			oiSetMenuOption?.map(oiSetMenuOptionItem => {
	// 				alignment(`${oiSetMenuOptionItem?.menuItem?.name}`, `\n`, false);
	// 			});
	// 		});
	// 	}

	// 	alignment(`Reason: ${reasonCode}`, `\n`, false);

	// 	if (remark) {
	// 		alignment(`Remark: ${remark}`, `\n`, false);
	// 	}
	// } else {
	// 	{
	// 		orderItemData?.map(oi => {
	// 			alignment(`${oi?.menuItem?.name}`, `x${oi?.quantity}\n`, false);

	// 			if (oi?.specialRequest) {
	// 				alignment(`${oi?.specialRequest}`, `\n`, false);
	// 			}

	// 			if (oi?.orderItemOption?.length > 0) {
	// 				oi?.orderItemOption?.map(oiOption => {
	// 					oiOption?.orderItemOptionItem?.map(oiOptionItem => {
	// 						alignment(`${oiOptionItem?.optionItem?.name}`, `\n`, false);
	// 					});
	// 				});
	// 			} else if (oi?.orderItemSetMenuOption?.length > 0) {
	// 				oi?.orderItemSetMenuOption?.map(oiSetMenuOption => {
	// 					oiSetMenuOption?.orderItemSetMenuOptionItem?.map(
	// 						oiSetMenuOptionItem => {
	// 							alignment(
	// 								`${oiSetMenuOptionItem?.menuItem?.name}`,
	// 								`\n`,
	// 								false,
	// 							);
	// 						},
	// 					);
	// 				});
	// 			}

	//       if (orderMode === 'void') {
	//         alignment(`Reason: ${reasonCode}`, `\n`, false);

	// 	if (remark) {
	// 		alignment(`Remark: ${remark}`, `\n`, false);
	// 	}
	//       }
	// 		});
	// 	}
	// }

	bxlPOS.printText(
		`------------------------------------------\n`,
		0,
		0,
		true,
		false,
		false,
		0,
		1,
	);
};
