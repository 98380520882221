import DateFnsUtils from '@date-io/date-fns';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	TextField,
} from '@material-ui/core';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetReservationDocument,
	useCreateReservationMutation,
	useGetOutletQuery,
	useGetReservationQuery,
	useNoShowCheckLazyQuery,
	useUpdateReservationMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { ChangeEvent, useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router';

interface Reservation {
	name: string;
	reservationDate: Date;
	reservationTime: string;
	pax: number;
	contactNo: string;
	description: string;
	noShow: boolean;
	// didTurnUp: boolean;
}

export const ReservationForm = (props: any) => {
	const history = useHistory();
	const location = useLocation();
	const { mode } = props;
	const { outletID, reservationID }: any = useParams();

	let formMode = mode === 'add' ? 'New' : 'Edit';

	const { data: { getOutlet } = { getOutlet: [] } } = useGetOutletQuery();

	const {
		called: reservationCalled,
		loading: reservationLoading,
		error,
		data: { getReservation } = { getReservation: [] },
	} = useGetReservationQuery({
		fetchPolicy: 'network-only',
	});

	// const [submitReservation, { data: createReservation }] =
	// 	useCreateReservationMutation({
	// 		fetchPolicy: 'no-cache',
	// 	});

	const [
		noShowCheck,
		{
			called: noShowCheckCalled,
			loading: noShowCheckLoading,
			data: noShowCheckData,
		},
	] = useNoShowCheckLazyQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		// setNoShow(noShow);
		if (!noShowCheckCalled) {
			noShowCheck({
				variables: {
					reservationID: reservationID,
				},
			});
		}
	}, [noShowCheckCalled]);

	const noShowCheckBox = noShowCheckData?.noShowCheck;

	const [reservation, setReservation] = useState({
		name: '',
		reservationDate: '',
		reservationTime: '',
		pax: '',
		contactNo: '',
		description: '',
		// didTurnUp: '',
	});

	const editData: any = location?.state as any;

	const { control, register, handleSubmit, errors } = useForm<Reservation>({
		mode: 'all',
		defaultValues: {
			name: editData ? editData?.name : '',
			reservationDate: editData ? editData?.reservationDate : new Date(),
			reservationTime: editData ? editData?.reservationTime : '',
			pax: editData ? editData?.pax : '',
			contactNo: editData ? editData?.contactNo : '',
			description: editData ? editData?.description : '',
			noShow: editData ? editData?.noShow : '',
		},
		// mode: 'onSubmit',
		// resolver: yupResolver(ContactSchema),
	});

	const [noShow, setNoShow] = useState(
		editData?.noShow != null ? editData?.noShow : false,
	);

	const handleNoShowCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
		setNoShow(!noShow);
	};

	console.log('errors: ', errors);

	const handleChange = (e: any) => {
		const name = e.target.name;
		const value = e.target.value;
		setReservation({ ...reservation, [name]: value });
	};

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [
		submitReservation,
		{
			data: createdReservationData,
			loading: createReservationLoading,
			called: createReservationCalled,
			error: createReservationError,
		},
	] = useCreateReservationMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconID}/vo`);

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/${outletID}/reservation`,
					state: { success: true, msgMode: 'create' },
				});
				//history.goBack();
			}, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		updateReservation,
		{
			loading: subUpdateLoading,
			loading: updateReservationLoading,
			called: updateReservationCalled,
			error: updateReservationError,
		},
	] = useUpdateReservationMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconId}/vo`);

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/${outletID}/reservation`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const createUpdateCalled = editData
		? updateReservationCalled
		: createReservationCalled;

	const onSubmit = (data: any) => {
		if (mode == 'add') {
			//add the data with GraphQL mutation
			submitReservation({
				variables: {
					input: {
						outletID: outletID,
						name: data.name,
						reservationDate: data.reservationDate,
						reservationTime: data.reservationTime,
						pax: parseFloat(data.pax),
						contactNo: data.contactNo,
						description: data.description,
					},
				},
				refetchQueries: [
					{ query: GetReservationDocument, variables: { outletID } },
				],
			});
		} else if (mode === 'edit') {
			//update the data

			updateReservation({
				variables: {
					input: {
						// ID: outletID,
						// contractID: discountID,
						// patronID: editData?.patronID,
						ID: editData?.ID,

						outletID: outletID,
						// accountID: editData?.accountID,
						name: data.name,
						reservationDate: data.reservationDate,
						reservationTime: data.reservationTime,
						pax: parseFloat(data.pax),
						contactNo: data.contactNo,
						description: data.description,
						noShow: noShow,
					},
				},
				refetchQueries: [
					{ query: GetReservationDocument, variables: { outletID } },
				],
			});
		}
	};

	const [openExitDialog, setOpenExitDialog] = useState(false);
	return (
		<>
			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet?.filter(outlet => outlet?.ID === outletID)[0]?.name}
				routeSegments={[{ name: 'Reservation', current: true }]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>
			<ContentWrapper>
				<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
					<CardContents devFullWidth={true}>
						{mode === 'edit' ? (
							<Grid container style={{ height: '0px' }}>
								<Grid item xs={9} className="left"></Grid>
								<Grid item xs={3} className="right">
									<FormControl component="fieldset">
										<Controller
											as={
												<FormControlLabel
													control={
														<Checkbox
															checked={noShow}
															onChange={handleNoShowCheckboxChange}
															name="noShow"
															color="primary"
															disabled={
																noShowCheckData?.noShowCheck == false
																	? true
																	: false
															}
														/>
													}
													label="No Show"
												/>
											}
											name="isActive"
											control={control}
										/>
									</FormControl>
								</Grid>
							</Grid>
						) : null}

						<Controller
							as={TextField}
							name="name"
							label="Name"
							required
							autoComplete="off"
							control={control}
							multiline={true}
							fullWidth
							margin="normal"
							onChange={handleChange}
							// helperText={errors?.name?.message}
							// error={errors?.name ? true : false}
							ref={register}
							// defaultValue={menu?.obj?.name || ''}
						/>

						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Controller
								as={KeyboardDatePicker}
								name="reservationDate"
								label={'Date'}
								control={control}
								disablePast
								onChange={(date: Date | null) => {}}
								format="dd MMM yyyy"
								value={null}
								defaultValue={new Date()}
								margin="normal"
								allowKeyboardControl
								fullWidth
								ref={register}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								className="left"
							/>
						</MuiPickersUtilsProvider>

						<Controller
							as={TextField}
							name="reservationTime"
							label="Time"
							type="time"
							margin="normal"
							defaultValue="07:30"
							className={'right'}
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								step: 300, // 5 min
							}}
							ref={register}
							control={control}
						/>

						<Controller
							as={
								<NumberFormat
									customInput={TextField}
									allowNegative={false}
									fullWidth
									autoComplete="off"
									isNumericString
									fixedDecimalScale
									required
									// ref={register}
									onValueChange={(value: any) => {
										const { floatValue } = value;
									}}
								/>
							}
							name="pax"
							label="No. of Pax"
							control={control}
							ref={register}
							// helperText={errors?.claimRunNum?.message}
							// error={errors?.claimRunNum ? true : false}
						/>

						<Controller
							as={
								<PhoneNumInput
									country={'my'}
									specialLabel="Mobile No."
									fullWidth
								/>
							}
							name="contactNo"
							// required
							// fullWidth
							marginTop
							marginBottom
							margin="normal"
							// id="standard-basic"
							type="tel"
							// autoComplete="off"
							control={control}
							helperText={errors?.contactNo?.message}
							error={errors?.contactNo ? true : false}
							ref={register}
							// className="right"
							// defaultValue={menu?.obj?.contactNo || ''}
						/>

						<Controller
							as={TextField}
							name="description"
							label="Remarks"
							autoComplete="off"
							control={control}
							onChange={handleChange}
							multiline={true}
							fullWidth
							// helperText={errors?.email?.message}
							// error={errors?.email ? true : false}
							ref={register}
							// defaultValue={menu?.obj?.email || ''}
						/>
					</CardContents>
				</form>
				<DynamicFooter
					options={[
						{
							name: 'Save',
							// onClick: () => {
							// 	handleSubmit(onSubmit)();
							// },
							color: 'primary',
							// props: {
							// 	type: 'submit',
							// },
							onClick: () => {
								handleSubmit(data => !createUpdateCalled && onSubmit(data))();
							},
						},
					]}
				/>
				<ExitConfirmationDialog
					openExitDialog={openExitDialog}
					setOpenExitDialog={setOpenExitDialog}
					backPath={`/menu/outlet-app/${outletID}/reservation`}
				/>
				{/* <CommonDialog
					fullWidth={true}
					open={openExitDialog}
					onClose={() => {
						setOpenExitDialog(false);
					}}
					sections={{
						header: {
							dynamic: (
								<div>
									<div className="dialogo-dynamic-content">
										<span
											className="title c-orange flex-space"
											style={{
												fontSize: '13px',
												fontWeight: 'bold',
											}}
										>
											Exit Confirmation
										</span>
									</div>
								</div>
							),
						},
						body: () => (
							<CardContents>
								<span className="">
									Are you sure you want to exit? Your changes will not be saved.
								</span>
							</CardContents>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
										},
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
											history.push({
												pathname: `/menu/outlet-app/${outletID}/reservation`,
											});
										},
										variant: 'contained',
										color: 'primary',
									},
								},
							],
						},
					}}
				/> */}
			</ContentWrapper>
		</>
	);
};
