import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { FooterWithTotal } from '@ifca-root/react-component/src/components/Footer/FooterWithTotal';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import { useMultiPurposeFileUpload } from '@ifca-root/react-component/src/utils/hooks/useMultiPurposeFileUpload';
import {
	Grid,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	useMediaQuery,
} from '@material-ui/core';
import { AddBox } from '@material-ui/icons';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import { NoImage, RecommendedIcon } from 'assets';
import BigNumber from 'bignumber.js';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetOrderDocument,
	GetScheduledDailyMenuCategoryDocument,
	OrderItemStatus,
	OrderStatus,
	useAttachmentListingLazyQuery,
	useCreateOrderItemWithOptionsMutation,
	useCreateOrderItemWithSetMenuOptionsMutation,
	useGetCurrentMealPeriodLazyQuery,
	useGetOrderQuery,
	useGetOutletNameQuery,
	useGetOutletVisitorPatronQuery,
	useGetScheduledDailyMenuCategoryLazyQuery,
	useGetUomQuery,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { amtStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
//import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { MenuDialog, SetMenuDialog } from './MenuDialog/OrderMenuDialog';
import './order.scss';

interface OptionItem {
	remark: string;
	name: string;
	optionItem: String;
	openPrice: number;
	specialRequest: String;
	option: any[];
	qty: number;
}

export const Order = (props: any) => {
	useEffect(() => {
		console.log('Order Outlet App');
	}, []);
	const { mode } = props;
	const history = useHistory();
	const { tableID, outletID, menuCategoryID, orderID } = useParams<
		Record<string, any>
	>();

	// const currentMealPeriod = JSON.parse(
	// 	localStorage.getItem('currentMealPeriod'),
	// )[outletID][0];
	const { globalState, dispatch }: any = useContext(AppContext as any);
	const [openMenu, setOpenMenu] = useState(false);
	const [openSetMenuDialog, setOpenSetMenuDialog] = useState(false);
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	const editData = useState();
	//const editData: any = location?.state;
	const [text, setText] = useState();
	const [record, setRecord] = useState(false);
	const [audioData, setAudioData] = useState(null);
	const [audioInput, setAudioInput] = useState('');
	const { files, handleSingleRemark } = useMultiPurposeFileUpload();

	const [currMenu, setCurrMenu] = useState<any>();
	const [quantity, setQuantity] = useState(1); // this should be changed accordingly
	const [qty, setQty] = useState(1); //use to reroute change dialog
	const { anchorEl: orderAnchor, handleClose, handleClick } = useMenuOption();
	const [takeAwayStatus, setTakeAwayStatus] = useState(false);
	const [selectedCategory, setSelectedCategory] = useState(null);
	let amount = 0;
	const [selectedIndex, setSelectedIndex] = React.useState(0);
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const handleClickListItem = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const [isSubmitting, setIsSubmitting] = useState(false);

	const yupSchema = yup.object().shape({
		openItem: currMenu?.isOpenItem
			? yup
					.string()
					.required('Open item name is required')
					.trim()
			: null,
		openPrice:
			currMenu?.isOpenPrice || currMenu?.isOpenItem
				? yup.number().required('Open item price is required')
				: null,
	});

	let {
		handleSubmit,
		register,
		control,
		watch,
		setValue,
		unregister,
		errors,
		reset,
		clearErrors,
	}: any = useForm<OptionItem>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const categoryDDClose = () => {
		setAnchorEl(null);
	};

	// const searchParameter = JSON.parse(localStorage.getItem('menuItemSearch'));
	const menuSearch = JSON.parse(localStorage.getItem('searchFilter'));

	const handleMenuCategoryClick = (
		// event: React.MouseEvent<HTMLElement>,
		event: any,
		index: number,
	) => {
		setSelectedIndex(index);
		setAnchorEl(null);
		localStorage.setItem(
			'searchFilter',
			JSON.stringify({
				searchString: menuSearch?.searchString ?? '',
				category: event?.ID,
			}),
		);
		if (mode === 'dineIn') {
			history.push(
				`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/menu-category/${event?.ID}`,
			);
		} else if (mode === 'takeAway') {
			history.push(
				`/menu/outlet-app/${outletID}/order/${orderID}/menu-category/${event?.ID}/takeaway`,
			);
		}
		// localStorage.setItem(
		// 	'menuItemSearch',
		// 	JSON.stringify({
		// 		category: event?.ID,
		// 		name: searchParameter?.name,
		// 	}),
		// );
		// handleSearch(searchParameter?.name, [
		// 	'name',
		// 	'email',
		// 	'commonStatus',
		// 	'superuser',
		// ]);
	};

	//get outlet and table
	//get outlet and table

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
			loadScheduledMenuCategory({
				variables: {
					outletID: outletID,
					mealPeriodID: getCurrentMealPeriod?.ID,
				},
			});
		},
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const {
		called: outletVisitorPatronCalled,
		loading: outletVisitorPatronLoading,
		error: outletVisitorPatronError,
		data: { getOutletVisitorPatron } = { getOutletVisitorPatron: [] },
	} = useGetOutletVisitorPatronQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
	});

	//get menu based on meal period
	//get menu based on meal period

	const [
		loadScheduledMenuCategory,
		{
			loading: scheduledDailyMenuCategoryLoading,
			called: getScheduledDailyMenuCategoryCalled,
			data: { getOutletDailyMenuCategory } = { getOutletDailyMenuCategory: [] },
		},
	] = useGetScheduledDailyMenuCategoryLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getOutletDailyMenuCategory }) => {
			// setSelectedCategory(
			// 	getOutletDailyMenuCategory?.filter(
			// 		(category) => category?.ID === menuCategoryID,
			// 	)[0],
			// );
			// setSelectedIndex(
			// 	getOutletDailyMenuCategory
			// 		?.map((cat) => cat?.ID)
			// 		?.indexOf(menuCategoryID),
			// );
		},
	});

	useEffect(() => {
		setSelectedCategory(
			getOutletDailyMenuCategory?.filter(
				category => category?.ID === menuCategoryID,
			)[0],
		);
		setOriginalListing(
			getOutletDailyMenuCategory
				?.filter(category => category?.ID === menuCategoryID)[0]
				?.outletDailyMenuItem?.filter(
					item => item?.menuItem?.stopSales === null,
				),
		);
		setSelectedIndex(
			getOutletDailyMenuCategory?.map(cat => cat?.ID)?.indexOf(menuCategoryID),
		);
		localStorage.setItem(
			'searchFilter',
			JSON.stringify({
				category: getOutletDailyMenuCategory?.filter(
					category => category?.ID === menuCategoryID,
				)[0]?.ID,
				searchString: menuSearch?.searchString ?? '',
			}),
		);
	}, [getOutletDailyMenuCategory, menuCategoryID]);

	const [dahHabis, setHabis] = useState(null);

	useEffect(() => {
		setHabis(
			selectedCategory?.outletDailyMenuItem?.filter(
				id => id?.outletDailyMenuCategoryID === menuCategoryID,
			),
		);
	}, [selectedCategory]);

	//for order quantity
	//for order quantity

	const clickButton = (mode: string) => {
		if (mode === 'add') {
			if (quantity === currMenu?.menuItem?.dailyAvailability?.outstandingQty) {
				setQuantity(quantity);
				setValue('qty', quantity);
			} else {
				setQuantity(quantity + 1);
				setValue('qty', quantity + 1);
			}
		} else {
			if (quantity === 1) {
				setQuantity(1);
				setValue('qty', 1);
			} else {
				setQuantity(quantity - 1);
				setValue('qty', quantity - 1);
			}
		}
	};

	const [
		loadAttachment,
		{ loading: attachmentLoad, error: attachmentError, data: attachmentData },
	] = useAttachmentListingLazyQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		loadAttachment({ variables: { refID: currMenu?.menuItem?.ID } });
	}, [currMenu]);

	//DYNAMIC SUB HEADER
	//DYNAMIC SUB HEADER
	//DYNAMIC SUB HEADER

	const {
		called: orderCalled,
		loading: orderLoading,
		error: orderError,
		data: { getOrder } = { getOrder: [] },
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
			status: OrderStatus.Open,
			outletID,
		},
	});

	const [selectedOrder, setSelectedOrder] = useState(
		getOrder?.filter(order => order?.ID === orderID)[0],
	);

	useEffect(() => {
		setSelectedOrder(getOrder?.filter(order => order?.ID === orderID)[0]);
	}, [getOrder, orderID, orderCalled]);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	//CREATE ORDER ITEM //CREATE ORDER ITEM //CREATE ORDER ITEM //CREATE ORDER ITEM //CREATE ORDER ITEM //CREATE ORDER ITEM
	//CREATE ORDER ITEM //CREATE ORDER ITEM //CREATE ORDER ITEM //CREATE ORDER ITEM //CREATE ORDER ITEM //CREATE ORDER ITEM

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [optionErr, setOptionErr] = useState(false);
	const [
		createOrderItemWithOptions,
		{ called: createOptionCalled, loading: createOptionLoading },
	] = useCreateOrderItemWithOptionsMutation({
		onError: error => {
			console.log('Suberror', error);
			setOptionErr(true);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			if (error.message.includes('This table')) {
				history.push(`/menu/outlet-app/${outletID}/table/grid`);
			} else {
				history.push(`/menu/outlet-app/${outletID}/table/grid/${tableID}`);
			}
		},
		onCompleted: data => {
			setOpenMenu(false);
			setOptionErr(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setIsSubmitting(false);
		},
	});

	const [
		createOrderItemWithSetMenuOptions,
		{ called: createSetMenuOptionCalled, loading: createSetMenuOptionLoading },
	] = useCreateOrderItemWithSetMenuOptionsMutation({
		onError: error => {
			console.log('Suberror', error);
			setOptionErr(true);
			setOpenSnackBar(true);
			setSnackBarMsg(error.message);
			if (error.message.includes('This table')) {
				history.push(`/menu/outlet-app/${outletID}/table/grid`);
			} else {
				history.push(`/menu/outlet-app/${outletID}/table/grid/${tableID}`);
			}
		},
		onCompleted: data => {
			setOpenSetMenuDialog(false);
			setOptionErr(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setIsSubmitting(false);
		},
	});

	//GET UOM
	//GET UOM
	//GET UOM

	const {
		loading: uomLoading,
		data: { getUOM } = { getUOM: [] },
	} = useGetUomQuery({
		fetchPolicy: 'network-only',
		// variables: {
		// 	outletID: outletID,
		// },
	});

	//get price based on patron ID
	//get price based on patron ID

	const [menuItemPatronPrice, setPatronPrice] = useState(null);

	const currTs = new Date();
	const currTimeInt = currTs.getHours() * 60 + currTs.getMinutes();

	const allRange = [];
	for (var i = 0; i <= 1440; i++) {
		allRange.push(i);
	}

	const getTimeRange = range => {
		if (range.startTime < range.endTime) {
			let timeRange = [];
			for (var i = range.startTime; i <= range.endTime; i++) {
				timeRange.push(i);
			}
			return timeRange;
		} else {
			let timeRange = [];
			for (var i = range.endTime + 1; i <= range.startTime; i++) {
				timeRange.push(i);
			}
			return allRange.filter(val => !timeRange.includes(val));
		}
	};

	useEffect(() => {
		if (currMenu?.promoChecking) {
			setPatronPrice(
				new BigNumber(
					menuItemListPatronPrice(currMenu, true) ?? 0,
					// currMenu?.outletDailyPromoMenuItem[0]?.outletDailyPromoMenuItemPrice?.filter(
					// 	id => id?.patronID === selectedOrder?.patronID,
					// )[0]?.price ?? 0,
				).toNumber(),
			);
		} else {
			setPatronPrice(
				new BigNumber(
					menuItemListPatronPrice(currMenu) ?? 0,
					// currMenu?.outletDailyMenuItemPatronPrice?.filter(
					// 	itemprice => itemprice?.patronID === selectedOrder?.patronID,
					// )[0]?.price ?? 0,
				).toNumber(),
			);
		}
	}, [currMenu]);

	const menuItemListPatronPrice = (item, promo?: Boolean) => {
		let price;
		if (!promo) {
			price = item?.outletDailyMenuItemPatronPrice?.filter(
				itemprice => itemprice?.patronID === selectedOrder?.patronID,
			)[0]?.price;
		} else {
			price = item?.outletDailyPromoMenuItem
				.filter(item => {
					const formattedExItem = {
						startTime:
							Number(item?.startTime.split(':')[0]) * 60 +
							Number(item?.startTime.split(':')[1]),
						endTime:
							Number(item?.endTime.split(':')[0]) * 60 +
							Number(item?.endTime.split(':')[1]),
					};
					//find if existing item overlap with current one
					if (getTimeRange(formattedExItem).includes(currTimeInt)) {
						return item;
					}
				})[0]
				?.outletDailyPromoMenuItemPrice?.filter(
					id => id?.patronID === selectedOrder?.patronID,
				)[0]?.price;
		}
		return price;
	};

	const handleChangeOrder = (e, obj) => {
		if (obj?.ID !== orderID) {
			history.push({
				pathname: `/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${obj?.ID}/menu-category/${menuCategoryID}`,
			});
		}
	};

	let totalAmountFooter = amtStr(selectedOrder?.outstandingAmount);

	//UNREIGSTER CHECKBOX FIELD
	//UNREIGSTER CHECKBOX FIELD

	useEffect(() => {
		unregister(`option`);
		reset();
	}, [openMenu, openSetMenuDialog]);

	const [weightName, setWeightName] = useState(null);

	useEffect(() => {
		setWeightName(getUOM?.filter(id => id?.ID === currMenu?.uomID)[0]);
	}, [getOutletDailyMenuCategory, currMenu]);

	const checkMenuOptionError = () => {
		const boolArr = currMenu?.menuItem?.menuItemOptions?.map(menuItemOption => {
			const maxSelections = menuItemOption?.maxSelections;
			const currSelectedOption = watch(`option`)?.filter(
				selectedOption =>
					Object?.keys(selectedOption)[0] === menuItemOption?.optionID,
			)[0];

			if (maxSelections === 0) {
				return true;
			} else if (
				(maxSelections !== 1 && currSelectedOption === undefined) ||
				(maxSelections === 1 &&
					currSelectedOption[menuItemOption?.optionID][0] === undefined)
			) {
				return false;
			} else if (
				maxSelections === 1 &&
				typeof currSelectedOption[menuItemOption?.optionID][0] === 'string'
			) {
				return true;
			} else if (
				maxSelections > 1 &&
				Object.keys(currSelectedOption[menuItemOption?.optionID]).length <=
					maxSelections
			) {
				return true;
			} else {
				return false;
			}
		});

		return boolArr;
	};

	const checkSetMenuOptionError = () => {
		const boolArr = currMenu?.menuItem?.setMenuOption?.map(setMenuOption => {
			const maxSelections = setMenuOption?.maxSelections;
			const currSelectedOption = watch(`option`).filter(
				selectedOption => Object.keys(selectedOption)[0] === setMenuOption?.ID,
			)[0];

			if (maxSelections === 0) {
				return true;
			} else if (
				(maxSelections !== 1 && currSelectedOption === undefined) ||
				(maxSelections === 1 &&
					currSelectedOption[setMenuOption?.ID][0] === undefined)
			) {
				return false;
			} else if (
				maxSelections === 1 &&
				typeof currSelectedOption[setMenuOption?.ID][0] === 'string'
			) {
				return true;
			} else if (
				maxSelections > 1 &&
				Object.keys(currSelectedOption[setMenuOption?.ID]).length <
					maxSelections
			) {
				return true;
			} else {
				return false;
			}
		});

		return boolArr;
	};

	console.log(currMenu, selectedCategory, 'currMenu');

	const setAddOrder = async (data: any) => {
		if (data?.getWeight) {
			amount = menuItemPatronPrice * parseFloat(data?.getWeight);
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice) * quantity;
		} else {
			amount = menuItemPatronPrice * quantity;
		}

		createOrderItemWithOptions({
			variables: {
				orderItemInput: {
					unitPrice: parseFloat(data?.openPrice) || menuItemPatronPrice,
					name: currMenu?.name,
					description: currMenu?.description,
					outletID: outletID,
					amount: amount,
					baseAmount: amount,
					quantity: data?.getWeight ? 1 : quantity,
					menuItemID: currMenu?.menuItemID,
					orderID: orderID,
					mealPeriodID: currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: takeAwayStatus,
					openItemName: data?.openItem,
					weight: parseFloat(data?.getWeight) || 1,
					digitalMenuCategoryID: selectedCategory?.digitalMenuCategoryID,
				},
				orderItemOptionInput: data?.option?.map(orderItemOption => {
					let optionID = Object?.keys(orderItemOption)[0];
					return {
						outletID,
						optionID: optionID,
						orderItemOptionItems:
							typeof orderItemOption[optionID][0] === 'string'
								? [
										{
											outletID,
											optionItemID: JSON.parse(orderItemOption[optionID][0])
												?.itemID,
											price:
												JSON.parse(orderItemOption[optionID][0])?.price *
												quantity,
											unitPrice: JSON.parse(orderItemOption[optionID][0])
												?.price,
										},
								  ]
								: Object.keys(orderItemOption[optionID])
										?.filter(item => item != null)
										?.map(optionItem => {
											return {
												outletID,
												optionItemID: optionItem,
												price: orderItemOption[optionID][optionItem] * quantity,
												unitPrice: orderItemOption[optionID][optionItem],
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetScheduledDailyMenuCategoryDocument,
					variables: {
						outletID: outletID,
						mealPeriodID: currentMealPeriod?.ID,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});
		setQuantity(1);
		setTakeAwayStatus(false);
	};

	const addSetMenuOrderItem = async (data: any) => {
		if (data?.getWeight) {
			amount = menuItemPatronPrice * parseFloat(data?.getWeight);
		} else if (data?.openPrice) {
			amount = parseFloat(data?.openPrice) * quantity;
		} else {
			amount = menuItemPatronPrice * quantity;
		}
		createOrderItemWithSetMenuOptions({
			variables: {
				orderItemInput: {
					unitPrice: parseFloat(data?.openPrice) || menuItemPatronPrice,
					name: currMenu?.name,
					description: currMenu?.description,
					outletID: outletID,
					amount: amount,
					baseAmount: amount,
					quantity: parseFloat(data?.getWeight) || quantity,
					menuItemID: currMenu?.menuItemID,
					orderID: orderID,
					mealPeriodID: currentMealPeriod?.ID,
					status: OrderItemStatus.Pending,
					specialRequest: audioInput,
					isTakeaway: takeAwayStatus,
					openItemName: data?.openItem,
					weight: parseFloat(data?.getWeight) || 1,
					digitalMenuCategoryID: selectedCategory?.digitalMenuCategoryID,
				},
				orderItemSetMenuOptionInput: data?.option?.map(setMenuOption => {
					let setMenuOptionID = Object?.keys(setMenuOption)[0];
					return {
						outletID,
						setMenuOptionID: setMenuOptionID,
						orderItemSetMenuOptionItemInput:
							typeof setMenuOption[setMenuOptionID][0] === 'string'
								? [
										{
											outletID,
											menuItemID: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.menuItemID,
											setMenuOptionItemID: JSON.parse(
												setMenuOption[setMenuOptionID][0],
											)?.itemID,
											quantity: JSON.parse(setMenuOption[setMenuOptionID][0])
												?.quantity,
										},
								  ]
								: Object.keys(setMenuOption[setMenuOptionID])
										?.filter(setMenuOptionItemID => setMenuOptionItemID != null)
										?.map(setMenuOptionItemID => {
											return {
												outletID,
												setMenuOptionItemID: setMenuOptionItemID,
												menuItemID:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														?.menuItemID,
												quantity:
													setMenuOption[setMenuOptionID][setMenuOptionItemID]
														?.quantity,
											};
										}),
					};
				}),
			},
			refetchQueries: [
				{
					query: GetScheduledDailyMenuCategoryDocument,
					variables: {
						outletID: outletID,
						mealPeriodID: currentMealPeriod?.ID,
					},
				},
				{
					query: GetOrderDocument,
					variables: {
						...(mode === 'dineIn' ? { tableID: tableID } : { ID: orderID }),
						status: OrderStatus.Open,
						outletID,
					},
				},
			],
		});
		setQuantity(1);
		setTakeAwayStatus(false);
	};

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();
	// useEffect(() => {
	// 	if (
	// 		selectedCategory?.outletDailyMenuItem &&
	// 		selectedCategory?.outletDailyMenuItem?.length > 0
	// 	) {
	// 		setOriginalListing(selectedCategory?.outletDailyMenuItem);
	// 	}
	// }, [selectedCategory?.outletDailyMenuItem]);

	// use effect for standard item in set menu
	useEffect(() => {
		if (openSetMenuDialog) {
			currMenu?.menuItem?.setMenuOption
				?.filter(setMenuOption => setMenuOption?.maxSelections === 0)[0]
				?.setMenuOptionItem?.map((setMenuOptionItem, i) => {
					register(
						`option[0].${
							currMenu?.menuItem?.setMenuOption?.filter(
								setMenuOption => setMenuOption?.maxSelections === 0,
							)[0].ID
						}[${setMenuOptionItem?.ID}]`,
					);
					setValue(
						`option[0].${
							currMenu?.menuItem?.setMenuOption?.filter(
								setMenuOption => setMenuOption?.maxSelections === 0,
							)[0].ID
						}[${setMenuOptionItem?.ID}]`,
						{
							quantity: setMenuOptionItem?.quantity,
							menuItemID: setMenuOptionItem?.menuItem?.ID,
						},
					);
				});
		}
	}, [openSetMenuDialog, currMenu]);

	//CHECK MENU BY WEIGHT PRICE ITEM SET MENU OR NORMAL
	//CHECK MENU BY WEIGHT PRICE ITEM SET MENU OR NORMAL

	const checkItem = menu => {
		if (mode === 'takeAway') {
			setTakeAwayStatus(true);
		}
		if (menu?.isSetMenu) {
			setOpenSetMenuDialog(true);
		} else {
			setOpenMenu(true);
		}
	};

	useEffect(() => {
		if (
			!scheduledDailyMenuCategoryLoading &&
			selectedCategory?.outletDailyMenuItem?.length > 0 &&
			!!menuSearch?.searchString
		) {
			handleSearch(`'"${menuSearch?.searchString ?? ''}"`, ['name']);
		}
	}, [scheduledDailyMenuCategoryLoading, selectedCategory]);

	const categoryItemCount = items => {
		const filterItem = items?.filter(
			item => item?.menuItem?.stopSales === null,
		);
		return filterItem?.length;
	};

	const getRoundedQty = qty1 => {
		let outstandingQuantity =
			currMenu?.menuItem?.dailyAvailability?.outstandingQty;
		let roundedQty =
			qty1 > outstandingQuantity ? outstandingQuantity : qty1 < 1 ? 1 : qty1;
		return roundedQty;
	};

	const checkSetMenu = setMenuOption => {
		let outstanding: boolean;
		setMenuOption?.map(x => {
			x?.setMenuOptionItem?.map(y => {
				if (y?.menuItem?.dailyAvailability) {
					if (y?.menuItem?.dailyAvailability?.outstandingQty <= 0) {
						outstanding = true;
					} else if (y?.menuItem?.dailyAvailability?.outstandingQty > 0) {
						outstanding = false;
					}
				}
			});
		});

		return outstanding;
	};

	return (
		<>
			{scheduledDailyMenuCategoryLoading && <Loading />}
			{orderLoading && <Loading />}
			{outletLoading && <Loading />}
			{uomLoading && <Loading />}
			{createOptionLoading && <Loading />}
			{createSetMenuOptionLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}

			<MainHeader
				onClick={() => {
					if (mode === 'dineIn') {
						localStorage.removeItem(`searchFilter`);
						history.push(
							`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}`,
						);
					} else if (mode === 'takeAway') {
						localStorage.removeItem(`searchFilter`);
						history.push(
							`/menu/outlet-app/${outletID}/order/${orderID}/takeaway`,
						);
					}
					// localStorage.removeItem('menuItemSearch');
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Add Menu Item', current: true },
				]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${currentMealPeriod?.name} ${formatTime(
									currentMealPeriod?.startTime,
							  )} - ${formatTime(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
					},
				]}
			/>

			<div
				className={
					isDesktop && globalState.drawerOpen
						? 'search-filter with-dropdown-filter with-subinfo'
						: isDesktop && !globalState.drawerOpen
						? 'search-filter with-dropdown-filter-fw with-subheader'
						: 'search-filter with-dropdown-filter with-subheader '
				}
			>
				<Grid container>
					<Grid item xs={7}>
						<SearchHeader
							title="Menu Item Listing "
							value={`${filteredList?.filter(e => {
								if (selectedCategory?.outletDailyMenuItem) {
									return e;
								}
							})?.length ?? '0'}`}
							defaultValue={
								menuSearch?.searchString ? menuSearch?.searchString : ''
							}
							onChangeAction={e => {
								handleSearch(`'"${e?.target?.value}"`, ['name']);
								localStorage.setItem(
									'searchFilter',
									JSON.stringify({
										category: menuSearch?.category,
										searchString: e?.target?.value,
									}),
								);
							}}
							search
							isDefaultValue={!!menuSearch?.searchString ?? false}
							onCloseAction={() => {
								localStorage.setItem(
									'searchFilter',
									JSON.stringify({
										category: menuSearch?.category ?? '',
										searchString: '',
									}),
								);
								handleSearch('', []);
							}}
							clickText
						/>
					</Grid>

					{/* <Grid item xs={1}></Grid> */}
					<Grid item xs={5}>
						<List
							disablePadding
							className="search-header"
							style={{
								borderRadius: '4px',
								marginLeft: '4px',
							}}
						>
							<ListItem
								button
								aria-haspopup="true"
								aria-controls="lock-menu"
								onClick={handleClickListItem}
								style={{ minHeight: '38px' }}
							>
								<ListItemText
									disableTypography
									primary={
										<span className="xsTitle">
											{getOutletDailyMenuCategory[selectedIndex]?.name ??
												'Category'}
										</span>
									}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="arrow"
										onClick={handleClickListItem}
									>
										<KeyboardArrowDownIcon />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						</List>
						<Menu
							id="lock-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={categoryDDClose}
							// onClose={categoryDDClose}
						>
							{getOutletDailyMenuCategory
								?.sort(function(a, b) {
									return a.priority - b.priority;
								})
								?.filter(
									cat => categoryItemCount(cat?.outletDailyMenuItem) !== 0,
								)
								?.map((e, index) => (
									<MenuItem
										key={index}
										value={e.ID}
										onClick={() => handleMenuCategoryClick(e, index)}
									>
										<span className="value">{e.name}</span>
									</MenuItem>
								))}
						</Menu>
					</Grid>
				</Grid>
			</div>

			<OrderSubheader
				mode={mode}
				getOrderLoading={orderLoading}
				getOrder={getOrder}
				selectedOrder={selectedOrder}
				orderID={selectedOrder?.ID}
				handleChangeOrder={handleChangeOrder}
				outletID={outletID}
			/>

			<ContentWrapper
				float
				multiLineSubheader={mode === 'takeAway' ? true : false}
				style={{
					marginTop: isDesktop ? '151px' : '88px',
				}}
			>
				<MsgAlert
					icon={<TouchAppIcon style={{ color: '#FFB11F', fontSize: '20px' }} />}
					message={'Tick to select Menu Item.'}
				/>

				<List className="core-list" style={{ marginBottom: '100px' }}>
					{!scheduledDailyMenuCategoryLoading &&
					!orderLoading &&
					!outletLoading &&
					!uomLoading &&
					!getCurrentMealPeriodLoading &&
					(getOutletDailyMenuCategory === undefined ||
						getOutletDailyMenuCategory?.length === 0) ? (
						<EmptyList
							title="No menu item found under this category"
							subtitle="Please set up menu items under Outlet Settings to continue"
						/>
					) : (
						filteredList
							// ?.filter(e => {
							// 	if (
							// 		selectedCategory?.outletDailyMenuItem?.filter(
							// 			item =>
							// 				menuItemListPatronPrice(item) !== undefined &&
							// 				menuItemListPatronPrice(item) !== 0 &&
							// 				menuItemListPatronPrice(item, true) !== undefined &&
							// 				menuItemListPatronPrice(item, true) !== 0,
							// 		)
							// 	) {
							// 		return e;
							// 	}
							// })
							// selectedCategory?.outletDailyMenuItem
							// 	?.filter(
							// 		(item) =>
							// 			menuItemListPatronPrice(item) !== undefined &&
							// 			menuItemListPatronPrice(item) !== 0,
							// 	)
							?.map((el, index) => {
								const notAvailable =
									el?.menuItem?.stopSales ||
									(el?.menuItem?.dailyAvailability &&
										el?.menuItem?.dailyAvailability?.outstandingQty <= 0) ||
									checkSetMenu(el?.menuItem?.setMenuOption);

								return (
									// <>
									// 	{el?.menuItem?.stopSales?.isActive ? (
									// 		<div></div>
									// 	) : (
									<ListItem
										style={{
											padding: '0px 10px 0px',
											backgroundColor: notAvailable ? '#ffc0c0' : null,
										}}
										disabled={notAvailable ? true : false}
									>
										<div
											className="container"
											style={{
												paddingRight: '6px',
											}}
										>
											<ListItemIcon>
												{el?.attachments?.fileURL ? (
													<>
														<img
															className="img-owned"
															src={el?.attachments?.fileURL}
														/>
													</>
												) : (
													<img
														className="img-owned"
														style={{ border: 'none' }}
														src={NoImage}
													/>
												)}
												{/* {el?.promoChecking && (
														<img
															className="img-overlay"
															src={path.join(
																__dirname,
																'../../../../../assets/images/Promo_Tag.png',
															)}
														/>
													)} */}
											</ListItemIcon>
											{notAvailable && (
												<div className="img-sold-out-text">SOLD</div>
											)}
											{el?.promoChecking && (
												<div className="img-promo-text">PROMO</div>
											)}
										</div>

										<ListItemText
											primary={
												<>
													{/* {el?.menuItem?.stopSales?.isActive ? (
												<span className="xsTitle">SOLD</span> */}
													{/* ) :  */}
													{el?.isOpenPrice || el?.isOpenItem ? (
														<span className="xsTitle">
															{/* {el?.isOpenItem
																? 'Open Food Item'
																: 'Open Price Item'} */}
															{el?.isOpenPrice == true ? (
																<span className="xsTitle">{el?.name} (OP)</span>
															) : (
																<span className="xsTitle">{el?.name} (OI)</span>
															)}
														</span>
													) : (
														<span className="xsTitle">{el?.name}</span>
													)}
													{el?.isChefRecommended && (
														<img
															className="icon-svg"
															src={RecommendedIcon}
															style={{
																height: '1em',
																marginLeft: '4px',
																marginBottom: '4px',
															}}
														/>
														// <ThumbUp
														// 	htmlColor="white"
														// 	fontSize="small"
														// 	style={{
														// 		width: '18px',
														// 		height: '18px',
														// 		color: '#ff9800',
														// 		marginLeft: '4px',
														// 	}}
														// />
													)}
												</>
											}
											secondary={
												<>
													<span className="flex-space desc">
														<div>{el?.description}</div>
														<div>
															{el?.isByWeight ? (
																<span className="highlight-text">{`${getOutlet[0]
																	?.currencyCode ?? ''}${amtStr(
																	new BigNumber(
																		menuItemListPatronPrice(el),
																	).toNumber(),
																)}/${
																	getUOM?.filter(id => id?.ID === el?.uomID)[0]
																		?.code
																}`}</span>
															) : el?.promoChecking ? (
																<>
																	<span className="highlight-text">
																		{`${getOutlet[0]?.currencyCode ??
																			''}${amtStr(
																			new BigNumber(
																				menuItemListPatronPrice(el, true),
																			).toNumber(),
																		)}`}
																	</span>
																	<span
																		style={{
																			marginLeft: '8px',
																			textDecoration: 'line-through',
																			fontWeight: 'bolder',
																		}}
																	>
																		{`${getOutlet[0]?.currencyCode ??
																			''}${amtStr(
																			new BigNumber(
																				menuItemListPatronPrice(el),
																			).toNumber(),
																		)}`}
																	</span>
																</>
															) : (
																<span className="highlight-text">
																	{getOutlet[0]?.currencyCode ?? ''}
																	{amtStr(
																		new BigNumber(
																			menuItemListPatronPrice(el),
																		).toNumber(),
																	)}
																</span>
															)}
														</div>
													</span>
												</>
											}
										/>

										<IconButton
											style={{ padding: '0px' }}
											disabled={notAvailable ? true : false}
										>
											<AddBox
												style={{
													fontSize: '32px',
													paddingTop: '28px',
													color: '#ff9800',
													// el?.menuItem?.stopSales?.isActive ||
													// el?.menuItem?.dailyAvailability?.outstandingQty < 0
													// 	? 'dimgray'
													// 	: '#ff9800',
												}}
												onClick={() => {
													setCurrMenu(el);
													checkItem(el);
													// if (
													// 	!el?.menuItem?.stopSales?.isActive &&
													// 	el?.menuItem?.dailyAvailability?.outstandingQty >= 0
													// ) {
													// 	setCurrMenu(el);
													// 	checkItem(el);
													//}
												}}
											/>
										</IconButton>
									</ListItem>
									// )}
									// </>
								);
							})
					)}
				</List>
			</ContentWrapper>
			<MenuDialog
				audioInput={audioInput}
				checkMenuOptionError={checkMenuOptionError}
				clickButton={clickButton}
				currMenu={currMenu}
				handleSubmit={handleSubmit}
				menuItemPatronPrice={menuItemPatronPrice}
				mode={mode}
				openMenu={openMenu}
				quantity={quantity}
				selectedOrder={selectedOrder}
				setAddOrder={setAddOrder}
				setCurrMenu={setCurrMenu}
				setOpenMenu={setOpenMenu}
				setOptionErr={setOptionErr}
				setQuantity={setQuantity}
				setTakeAwayStatus={setTakeAwayStatus}
				weightName={weightName}
				control={control}
				optionErr={optionErr}
				register={register}
				setValue={setValue}
				unregister={unregister}
				watch={watch}
				setAudioInput={setAudioInput}
				record={record}
				setRecord={setRecord}
				setText={setText}
				// handleSingleRemark={handleSingleRemark}
				attachmentData={attachmentData}
				clearErrors={clearErrors}
				errors={errors}
				isSubmitting={isSubmitting}
				setIsSubmitting={setIsSubmitting}
				isDesktop={isDesktop}
				getRoundedQty={getRoundedQty}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				createOptionLoading={createOptionLoading}
			/>
			{/* {menuDialog(
				audioInput,
				checkMenuOptionError,
				clickButton,
				currMenu,
				handleSubmit,
				menuItemPatronPrice,
				mode,
				openMenu,
				quantity,
				selectedOrder,
				setAddOrder,
				setCurrMenu,
				setOpenMenu,
				setOptionErr,
				setQuantity,
				setTakeAwayStatus,
				weightName,
				control,
				optionErr,
				register,
				setValue,
				unregister,
				watch,
				setAudioInput,
				record,
				setRecord,
				setText,
				handleSingleRemark,
			)} */}
			<SetMenuDialog
				addSetMenuOrderItem={addSetMenuOrderItem}
				checkSetMenuOptionError={checkSetMenuOptionError}
				clickButton={clickButton}
				currMenu={currMenu}
				handleSubmit={handleSubmit}
				menuItemPatronPrice={menuItemPatronPrice}
				mode={mode}
				openSetMenuDialog={openSetMenuDialog}
				quantity={quantity}
				selectedOrder={selectedOrder}
				setCurrMenu={setCurrMenu}
				setOpenSetMenuDialog={setOpenSetMenuDialog}
				setOptionErr={setOptionErr}
				setQuantity={setQuantity}
				setTakeAwayStatus={setTakeAwayStatus}
				//={//}extra
				control={control}
				optionErr={optionErr}
				register={register}
				setValue={setValue}
				unregister={unregister}
				watch={watch}
				attachmentData={attachmentData}
				clearErrors={clearErrors}
				errors={errors}
				isSubmitting={isSubmitting}
				setIsSubmitting={setIsSubmitting}
				isDesktop={isDesktop}
				getRoundedQty={getRoundedQty}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				createSetMenuOptionLoading={createSetMenuOptionLoading}
			/>
			{/* {promoMenuDialog()} */}

			<FooterWithTotal
				label="Total"
				value={`${getOutlet[0]?.currencyCode ?? ''}${totalAmountFooter || 0}`}
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				option={{
					name: 'Order Summary',
					onClick: () => {
						if (mode === 'dineIn') {
							history.push(
								`/menu/outlet-app/${outletID}/table/grid/${tableID}/order/${orderID}/menu-category/${menuCategoryID}/summary`,
							);
						} else if (mode === 'takeAway') {
							history.push(
								`/menu/outlet-app/${outletID}/order/${orderID}/menu-category/${menuCategoryID}/summary/takeaway`,
							);
						}
					},
					color: 'primary',
				}}
			/>
		</>
	);
};
