import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import {
	Button,
	Checkbox,
	FormControlLabel,
	Grid,
	IconButton,
	InputAdornment,
	TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import {
	LoggedInUserProfileDocument,
	LoggedInUserProfileQuery,
	useLoginMutation,
} from 'generated/graphql';
import { encryptMessage } from 'helpers/Crypto/JSEncrypt';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import Background from '../../../src/assets/images/HR-Background.jpg';
import Logo from '../../../src/assets/images/MenuX-Logo.svg';
import { setAccessToken } from '../../AccessToken';
import { POSClient } from '../../POSClient';
import { SocketContext } from 'containers/Socket/SocketContext';

interface ILoginForm {
	email: string;
	password: string;
	showPassword: boolean;
	rememberMe: boolean;
	product: string;
}

export const Login = () => {
	let history = useHistory();
	let location = useLocation();
	const { socket}: any =
	useContext(SocketContext)
	

	const rememberme: any = localStorage.getItem('rememberme');
	const appCode: any = localStorage.getItem('appCode');

	const [outletID, setOutletID] = useState(null);

	localStorage.clear();

	if (rememberme !== null) {
		localStorage.setItem('rememberme', rememberme);
	}

	const [login, { data, called, loading, error }] = useLoginMutation({
		onError: error => {
			error?.graphQLErrors?.map(({ message }) => {
				console.log('ERROR', message);
				setMessage(message);
				setOpenAlert(true);
			});
		},
		update: (store, { data }) => {
			if (!data) {
				return null;
			}
			store.writeQuery<LoggedInUserProfileQuery>({
				query: LoggedInUserProfileDocument,
				data: {
					__typename: 'Query',
					loggedInUserProfile: data.login.user as any,
				},
			});
		},
		onCompleted: data => {
			if (data && data.login.accessToken) {
				setAccessToken(data.login.accessToken);
				sessionStorage.setItem('key', data.login.accessToken);
			}

			if (rememberMe) {
				let rememberme = {
					username: getValues('email'),
					password: getValues('password'),
				};
				localStorage.setItem('rememberme', JSON.stringify(rememberme));
			} else {
				localStorage.removeItem('rememberme');
			}
			setTimeout(() => {
				history.push({ pathname: `/home`, state: data?.login?.user });
			}, 100);

			socket.emit('data', {
				Status: 'LOGIN',
				OutletID: data.login.user.lastestAccessedEntity,
				UserID: data.login.user.ID,
			  });
		},
		client: POSClient as any,
	});

	const [rememberMe, setRememberMe] = useState(
		rememberme !== null ? true : false,
	);

	const [values, setValues] = useState(false);
	const [open, setOpen] = useState(false);
	const [openAlert, setOpenAlert] = useState(false);
	const [message, setMessage] = useState('');

	const handleCloseAlert = () => {
		setOpenAlert(false);
	};

	const handleCloseSnackBar = () => {
		setOpen(false);
		history.push('/profile');
	};
	const MESSAGE = 'Password Changed Successfully';

	const handleClickShowPassword = () => {
		setValues(!values);
	};

	const handleMouseDownPassword = (event: any) => {
		event.preventDefault();
	};
	const handleCheckRememberMe = (checked: boolean) => {
		setRememberMe(checked);
	};

	// useForm set DefaultValues, validationSchema
	const { handleSubmit, errors, control, getValues } = useForm<ILoginForm>({
		defaultValues: {
			email: rememberme !== null ? JSON.parse(rememberme)?.username : '',
			password: rememberme !== null ? JSON.parse(rememberme)?.password : '',
		},
	});

	const onSubmit = (data: any) => {
		login({
			variables: {
				loginID: data?.email,
				password: encryptMessage(data?.password),
			},
		});
	};

	return (
		<>
			{loading && <Loading />}
			<AuthLayout logo={Logo} image={Background} className="margin-override">
				<span className="page-title">Login</span>
				<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
					<div className="form-box">
						<Controller
							as={TextField}
							fullWidth
							helperText={errors?.email?.message}
							error={errors?.email ? true : false}
							autoComplete="off"
							name="email"
							label="Email/Username"
							control={control}
							data-qa="usernameInput"
						/>
						<Grid container>
							<Controller
								as={TextField}
								fullWidth
								helperText={errors?.password?.message}
								error={errors?.password ? true : false}
								autoComplete="off"
								name="password"
								label="Password "
								type={values ? 'text' : 'password'}
								value={getValues('password')}
								//   onChange={handleChange('password')}
								InputProps={{
									endAdornment: (
										<InputAdornment position="end">
											<IconButton
												aria-label="Password"
												onClick={handleClickShowPassword}
												onMouseDown={handleMouseDownPassword}
											>
												{values ? <Visibility /> : <VisibilityOff />}
											</IconButton>
										</InputAdornment>
									),
								}}
								control={control}
								data-qa="passwordInput"
							/>
						</Grid>

						<div className="other-auth">
							<FormControlLabel
								control={
									<Checkbox
										name="checkedB"
										color="primary"
										checked={rememberMe}
										onChange={e => handleCheckRememberMe(e.target.checked)}
									/>
								}
								label="Remember Me"
							/>
							<span
								className="forget-link"
								onClick={() => history.push('/forgot-password')}
								data-qa="forgotPasswordLink"
							>
								Forgot Password?
							</span>
						</div>

						<Button
							type="submit"
							variant="contained"
							color="primary"
							className="login-btn"
							// onClick={() => history.push('/')}
							data-qa="loginButton"
							// onClick={() => {
							// 	// history.push(loginRedirectPath);
							// }}
						>
							Login
						</Button>
					</div>
				</form>

				<CommonDialog
					fullWidth={true}
					open={openAlert}
					onClose={handleCloseAlert}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title">Login Error</div>
										</div>
									</div>
								</div>
							),
						},
						body: () => <div>{message}</div>,
						footer: {
							actions: [
								{
									displayText: 'Ok',
									props: {
										onClick: () => handleCloseAlert(),
										variant: 'contained',
										color: 'primary',
									},
								},
							],
						},
					}}
				/>
				<SnackBarMsg
					open={open}
					message={MESSAGE}
					onClose={handleCloseSnackBar}
				/>
			</AuthLayout>
		</>
	);
};
