import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { Box } from '@material-ui/core';
import { GetTableDocument, OrderStatus } from 'generated/graphql';
import React from 'react';
import { useForm } from 'react-hook-form';

interface CloseTableProps {
	tableID?: String;
	status?: OrderStatus;
}

export const CloseTableDialog = (props: any) => {
	const {
		outletID,
		tableID,
		history,
		closeTableDialog,
		setOpenForClOSEDialog,
		selectedTable,
		closeTableStatus,
		billInput,
		getSelectedOrderItemIDs,
		closeTableLoading,
	} = props;

	const { handleSubmit: tcHandleSubmit } = useForm<CloseTableProps>({
		defaultValues: {},
	});

	const onCloseSubmit = async (data: any) => {
		closeTableStatus({
			variables: {
				tableID: tableID,
				billInput: billInput,
				paymentByQuantityInput: getSelectedOrderItemIDs(),
			},
			refetchQueries: [
				{
					query: GetTableDocument,
					variables: { outletID: outletID },
				},
			],
		});
	};

	return (
		<>
			<form onSubmit={tcHandleSubmit(onCloseSubmit)} id="close-form">
				<Box mt={1}>
					<CommonDialog
						fullWidth={true}
						open={closeTableDialog}
						onClose={() => setOpenForClOSEDialog(false)}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												<div className="title highlight-text">Close Table</div>
												{/* <div className="title">
													{selectedTable?.prefix}
													{selectedTable?.tableNumber}
												</div> */}
											</div>
										</div>
										<div className="infoline-content ">
											<div className="xsTitle infoline">
												{selectedTable?.prefix}
												{selectedTable?.tableNumber}
											</div>
										</div>
									</div>
								),
							},
							body: () => (
								<>
									<div className="content-container mdDesc">
										Close table {selectedTable?.prefix}
										{selectedTable?.tableNumber}?
									</div>
								</>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => setOpenForClOSEDialog(false),
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Confirm',
										props: {
											//onClick: () => handleSubmit(onSubmit)(),
											variant: 'contained',
											form: 'close-form',
											color: closeTableLoading ? 'inherit' : 'primary',
											disabled: closeTableLoading ? true : false,
											type: 'submit',
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>
		</>
	);
};
