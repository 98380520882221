import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import React from 'react';
import { format } from 'date-fns';
import { Grid } from '@material-ui/core';
import QRCode from 'qrcode.react';
import * as bxlPOS from '../BillSettlement/BixolonJS/bxlpos.js';

export const PrintedQR = props => {
	const {
		outletName,
		// outletLogo,
		userName,
		outletID,
		qrID,
		accountID,
		tableNo,
	} = props;

	const printedOn = new Date();

	let qrUrl;
	switch (process.env.REACT_APP_ENV) {
		case 'local':
			qrUrl = `http://localhost:3500/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'dev':
			qrUrl = `https://posx-dev-customer.ifca.io/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'uat':
			qrUrl = `https://menux-uat-customer.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		default:
			qrUrl = `https://customer.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
	}

	//-----------------------------------------------------------------//
	// const user = JSON.parse(localStorage.getItem('loggedInUser'));

	// const {
	// 	called: ouletCalled,
	// 	loading: outletLoading,
	// 	error: outletError,
	// 	data: { getOutlet } = { getOutlet: [] },
	// } = useGetOutletQuery({
	// 	fetchPolicy: 'network-only',
	// 	variables: { ID: '1b852c0c-d95b-11eb-9fdf-0f1134af9e1b' },
	// });
	//-----------------------------------------------------------------//

	return (
		<div
			style={{
				zIndex: -40,
				top: 0,
				left: 0,
				position: 'fixed',
				width: '350px',
			}}
			id="printed-qr"
		>
			<CardContents>
				<div
					className="content-wrap full xsTitle"
					style={{
						textAlign: 'center',
						marginBottom: '10px',
						marginTop: '20px',
						fontSize: '16px',
					}}
				>
					{`Welcome to ${outletName}`}
					{/* {`Welcome to IFCA Cafe`} */}
				</div>

				<div
					className="content-wrap full"
					style={{
						textAlign: 'center',
						marginTop: '10px',
					}}
				>
					{`------------------------------------`}
				</div>

				<div className="dialog-dynamic-content">
					<div className="session">
						<div className="flex session" style={{ marginTop: '10px' }}>
							<div
								className="mdDesc"
								style={{ marginRight: '5px', fontSize: '16px' }}
							>
								Table:
							</div>
							<div className="xsTitle desc" style={{ fontSize: '16px' }}>
								{tableNo}
							</div>
						</div>

						<div className="flex session" style={{ marginTop: '10px' }}>
							<div
								className="mdDesc"
								style={{ marginRight: '5px', fontSize: '16px' }}
							>
								Printed on:
							</div>
							<div className="mdDesc" style={{ fontSize: '16px' }}>{`${format(
								printedOn,
								'dd MMM yyyy, hh:mm a',
							)}`}</div>
						</div>

						<div className="flex session" style={{ marginTop: '10px' }}>
							<Grid container>
								<Grid item xs={2}>
									<div
										className="mdDesc"
										style={{ marginRight: '5px', fontSize: '16px' }}
									>
										Staff:
									</div>
								</Grid>
								<Grid item xs={10}>
									<div
										className="mdDesc text-noflow"
										style={{ fontSize: '16px' }}
									>
										{/* {`${user?.name}`} */}
										{`${userName}`}
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
				</div>

				<div
					className="content-wrap full"
					style={{
						textAlign: 'center',
						marginTop: '10px',
					}}
				>
					{`------------------------------------`}
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'center',
						paddingLeft: '75px',
						background: '#fff',
					}}
				>
					<div style={{ textAlignLast: 'center', marginBottom: '20px' }}>
						<QRCode
							value={`${qrUrl}/${qrID}/${accountID}`}
							// value={'www.google.com'}
							size={128}
							bgColor={'#ffffff'}
							fgColor={'#000000'}
							level={'L'}
							includeMargin={false}
							// imageSettings={{
							// 	// src: getOutlet[0]?.logo?.fileURL,
							// 	src: outletLogo,
							// 	x: null,
							// 	y: null,
							// 	height: 26,
							// 	width: 26,
							// }}
						/>
						<div className="title" style={{ textAlign: 'center' }}>
							{`Scan the QR to Order`}
						</div>
					</div>
				</div>
			</CardContents>
		</div>
	);
};

export const NewOrderReceipt = (
	outletName,
	tableNo,
	userName,
	outletID,
	qrID,
	accountID,
) => {
	bxlPOS.printText(
		`Welcome to ${outletName}\n`,
		0,
		0,
		true,
		false,
		false,
		0,
		1,
	);

	bxlPOS.printText(
		`------------------------------------------\n`,
		0,
		0,
		true,
		false,
		false,
		0,
		1,
	);

	bxlPOS.printText(`Table: ${tableNo}\n`, 0, 0, true, false, false, 0, 0);

	const printedOn = new Date();

	bxlPOS.printText(
		`Printed on: ${format(printedOn, 'dd MMM yyyy, hh:mm a')}\n`,
		0,
		0,
		true,
		false,
		false,
		0,
		0,
	);
	bxlPOS.printText(`Staff: ${userName}\n`, 0, 0, true, false, false, 0, 0);

	bxlPOS.printText(
		`------------------------------------------\n`,
		0,
		0,
		true,
		false,
		false,
		0,
		1,
	);

	let qrUrl;
	switch (process.env.REACT_APP_ENV) {
		case 'local':
			qrUrl = `http://localhost:3500/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'dev':
			qrUrl = `https://posx-dev-customer.ifca.io/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'uat':
			qrUrl = `https://menux-uat-customer.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		default:
			qrUrl = `https://customer.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
	}

	bxlPOS.printQRCode(`${qrUrl}/${qrID}/${accountID}`, 0, 1, 5, 0);

	bxlPOS.printText(`Scan the QR to Order\n`, 0, 0, true, false, false, 0, 1);
};
