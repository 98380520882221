import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import { GeneralDeleteDialog } from 'components/Dialog/GeneralDeleteDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetDigitalMenuDocument,
	useCreateDigitalMenuMutation,
	useDeleteDigitalMenuMutation,
	useDuplicateDigitalMenuMutation,
	useGetDigitalMenuQuery,
	useGetOutletQuery,
	useIsDigitalMenuInUseLazyQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

interface DigitalMenuProps {
	name: string;
	description: string;
}

export const DigitalMenuList = () => {
	useEffect(() => {
		console.log('DigitalMenuList');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const [openDialog, setOpenDialog] = React.useState(false);
	const [double, setDouble] = useState(false);
	const [deleteDialog, setDeleteDialog] = useState(false);

	const yupSchema = yup.object().shape({
		name: CommonYupValidation.requireField(SystemMsgs.name()),
		description: CommonYupValidation.requireField(SystemMsgs.description()),
	});

	// const [data, setData] = useState(null);
	const { [`outletID`]: outletID } = useParams<Record<string, any>>();
	const { register, control, handleSubmit, errors } = useForm<DigitalMenuProps>(
		{
			defaultValues: {},
			resolver: yupResolver(yupSchema),
		},
	);

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		duplicateDigitalMenu,
		{ loading: duplicateDigitalMenuLoading },
	] = useDuplicateDigitalMenuMutation({
		onCompleted: ({ duplicateDigitalMenu }) => {
			setOpenSnackBar(true);
			setSnackBarMsg('Duplicate successful');
			// setSuccess({
			// 	msg: duplicateDigitalMenu ? 'Duplicate successful' : 'Duplicate failed',
			// 	bool: true,
			// });
		},
	});

	const [
		deleteDigitalMenu,
		{ loading: deleteDigitalMenuLoading },
	] = useDeleteDigitalMenuMutation({
		onError: error => {
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const {
		data: { getDigitalMenu } = { getDigitalMenu: [] },
		loading: digitalMenuLoading,
	} = useGetDigitalMenuQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	const [
		newDigitalMenu,
		{ error: digitalMenuError, loading: createDigitalMenuLoading },
	] = useCreateDigitalMenuMutation({
		onCompleted: data => {
			setOpenDialog(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		loadIsDigitalMenuInUse,
		{
			loading: isDigitalMenuInUseLoading,
			data: { isDigitalMenuInUse } = { isDigitalMenuInUse: [] },
		},
	] = useIsDigitalMenuInUseLazyQuery({
		fetchPolicy: 'network-only',
	});

	const moreVertList = [
		{
			name: 'Edit/Menu Pricing',
			action: () => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${menu.ID}/edit`,
					state: menu.obj,
				});
				handleClose();
			},
		},

		{
			name: 'Duplicate',
			action: () => {
				duplicateDigitalMenu({
					variables: {
						digitalMenuID: menu.ID,
						outletID: outletID,
					},
					refetchQueries: [
						{
							query: GetDigitalMenuDocument,
							variables: {
								outletID: outletID,
							},
						},
					],
				});

				handleClose();
			},
		},

		{
			name: 'Delete',
			action: () => {
				setDeleteDialog(true);
				handleClose();
			},
			disabled:
				isDigitalMenuInUse === true || isDigitalMenuInUseLoading === true,
		},
	];

	const onSubmit = (data: any) => {
		newDigitalMenu({
			variables: {
				input: {
					outletID: outletID,
					name: data.name,
					description: data.description,
				},
			},
			refetchQueries: [
				{
					query: GetDigitalMenuDocument,
					variables: {
						outletID: outletID,
					},
				},
			],
		});
	};

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const [success, setSuccess] = useState({
		msg: '',
		bool: false,
	});

	setTimeout(() => {
		setSuccess({ msg: 'null', bool: false });
	}, 5000);

	return (
		<>
			{digitalMenuLoading && <Loading />}
			{outletLoading && <Loading />}
			{duplicateDigitalMenuLoading && <Loading />}
			{deleteDigitalMenuLoading && <Loading />}
			{createDigitalMenuLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push(`/menu/outlet-app/outlet-setting/${outletID}`)
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Digital Menu & Pricing', current: true },
				]}
			/>

			<ContentWrapper>
				<List className="core-list">
					{!digitalMenuLoading &&
					!outletLoading &&
					!isDigitalMenuInUseLoading &&
					(getDigitalMenu === undefined || getDigitalMenu?.length === 0) ? (
						<EmptyList
							title="No Record Found"
							subtitle="Add a new record now"
						/>
					) : (
						getDigitalMenu?.map((el, index) => (
							// <DigitalMenuLibraryListing
							// 	el={el}
							// 	index={index}
							// 	handleClick={handleClick}
							// 	TLInfo={el?.name}
							// 	BLInfo={el?.description}
							// 	isEmpty={
							// 		getDigitalMenu?.length === 0 || getDigitalMenu === undefined
							// 	}
							// 	moreVertNeeded={true}
							// 	anchorEl={anchorEl}
							// 	handleClose={handleClose}
							// 	moreVertList={morevertList}
							// />

							<ListItem>
								<ListItemText
									primary={
										<>
											{el?.name && (
												<span className="xsTitle flex-space">{el?.name}</span>
											)}
										</>
									}
									secondary={
										<>
											<span className="desc">
												{el?.description && (
													<span className="xxTitle fw-medium">
														{el?.description}
													</span>
												)}
											</span>
										</>
									}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="more"
										aria-controls="menu-list"
										aria-haspopup="true"
										onClick={e => {
											handleClick(e, el.ID, index, el);
											loadIsDigitalMenuInUse({
												variables: {
													outletID: outletID,
													digitalMenuID: el?.ID,
												},
											});
										}}
									>
										<MoreVert />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))
					)}
				</List>
				<Menu
					disableScrollLock
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
				>
					{moreVertList?.map((v: any, i: any) => (
						<MenuItem onClick={v?.action} disabled={v?.disabled}>
							<span className="">{v?.name}</span>
						</MenuItem>
					))}
				</Menu>
			</ContentWrapper>
			<FloatButton
				onClick={() => {
					setOpenDialog(true);
					setDouble(false);
				}}
			/>

			{/* <form> */}
			<CommonDialog
				fullWidth={true}
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				sections={{
					header: {
						// title: `New Digital Menu `,
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div className="title flex-space">New Digital Menu</div>
										</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<CardContents>
								<Controller
									as={TextField}
									name="name"
									label="Digital Menu"
									autoComplete="off"
									multiline={true}
									fullWidth
									margin="normal"
									helperText={errors?.name?.message}
									error={errors?.name ? true : false}
									ref={register}
									control={control}
									required
								/>
								<Controller
									as={TextField}
									name="description"
									label="Description"
									autoComplete="off"
									multiline={true}
									fullWidth
									helperText={errors?.description?.message}
									error={errors?.description ? true : false}
									ref={register}
									control={control}
									required
								/>
							</CardContents>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => setOpenDialog(false),
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Save',
								props: {
									variant: 'contained',
									color: 'primary',
									//disabled: isExceed,
									disabled: double,

									onClick: () => {
										handleSubmit(onSubmit)();
										setDouble(true);
									},
								},
							},
						],
					},
				}}
			/>
			{/* </form> */}

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID }}
				openDialog={deleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteDigitalMenu}
				refetchDocument={GetDigitalMenuDocument}
			/>
		</>
	);
};
