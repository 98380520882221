import ApolloClient, { InMemoryCache } from 'apollo-boost';
import { InMemoryCache as UploadInMemoryCache } from 'apollo-cache-inmemory';

const apolloCache = new UploadInMemoryCache({
	addTypename: false,
});

let accountNodeUrl;
switch (process.env.REACT_APP_ENV) {
	case 'local':
		accountNodeUrl = 'https://subscriptionx-dev-api.ifca.io/subscriptionx';
		break;
	case 'dev':
		accountNodeUrl = 'https://subscriptionx-dev-api.ifca.io/subscriptionx';
		break;
	case 'uat':
		accountNodeUrl = 'https://subscriptionx-api.ifca.asia/subscriptionx';
		break;
	default:
		accountNodeUrl = 'https://subscriptionx-api.ifca.asia/subscriptionx';
		break;
}

export const AccountClient = new ApolloClient({
	cache: new InMemoryCache({
		addTypename: false,
	}),
	credentials: 'include',
	uri: accountNodeUrl,
	onError: error => {
		error.graphQLErrors?.map(({ message }) => {
			if (message === 'session can not be established') {
				// browserHistory.push('/login', {})
			}
		});
	},
});

/////////////////////////////////////////////////////////////////////////////////////////
// @access-control-node
export const accessControlUrl =
	process.env.NODE_ENV === 'development'
		? 'http://localhost:5020/access-control'
		: 'https://ifca.io/access-control';

export const AccessControlClient = new ApolloClient({
	cache: new InMemoryCache({
		addTypename: false,
	}),
	credentials: 'omit',
	uri: accessControlUrl,
});
