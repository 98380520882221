import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Button } from '@material-ui/core';
import { useActivateUserMutation } from 'generated/graphql';
import React from 'react';
import { useHistory, useParams } from 'react-router';
import Background from '../../../src/assets/images/HR-Background.jpg';
import Logo from '../../../src/assets/images/MenuX-Logo.svg';

export const ActivatedUser = () => {
	const { token }: any = useParams();
	// const jwtDecodeToken = jwtDecode(token);
	// const handleRedirectPath = jwtDecodeToken => {
	//   let path;
	//   switch (jwtDecodeToken?.softwareCode[0]) {
	//     case SoftwareCode.Contract:
	//       path = 'https://contractx-dev.ifca.io/login';
	//       break;
	//     case SoftwareCode.Hr:
	//       path = 'https://hrx-dev.ifca.io/login';
	//       break;
	//     case SoftwareCode.Property:
	//       path = 'https://propertyx-dev.ifca.io/login';
	//       break;
	//     case SoftwareCode.Hotel:
	//       path = 'https://hotelx-dev.ifca.io/login';
	//       break;
	//     case SoftwareCode.Rent:
	//       path = 'https://rentx-dev.ifca.io/login';
	//       break;
	//     case SoftwareCode.Account:
	//       path = 'https://accountx-dev.ifca.io/login';
	//       break;
	//     default:
	//       path = null;
	//       break;
	//   }
	//   return window.location.assign(path);
	// };
	let history = useHistory();
	const [
		activateUser,
		{ loading: mutationUpdateLoading, error: mutationUpdateError },
	] = useActivateUserMutation({
		onError: error => {
			console.log('error', error);
		},
		onCompleted: data => {
			if (data.activateUser) {
				// history.push(`https://contractx-dev.ifca.io/login`);
				// handleRedirectPath(jwtDecodeToken);
				// history.replace(`${handleRedirectPath(jwtDecodeToken)}`)
				// return <Redirect to={`${handleRedirectPath(jwtDecodeToken)}`} />
				if (data?.activateUser && token) {
					history.push(`/user/create-password/${token}`);
				} else {
					console.log('mutation return false');
				}
			}
		},
	});
	const handleActivateUser = () => {
		activateUser({
			variables: {
				token: token,
			},
		});
	};

	return (
		<AuthLayout logo={Logo} image={Background} className="margin-override">
			{mutationUpdateLoading && <Loading />}
			<div className="form-box">
				<span className="page-title">Your account has been created</span>
				<Button
					type="submit"
					variant="contained"
					color="primary"
					className="login-btn"
					onClick={handleActivateUser}
				>
					Proceed to login
				</Button>
			</div>
		</AuthLayout>
	);
};
