import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { useCreatePaymentByQuantityMutation } from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import '../../../BusinessInsights/BusinessAnalytics/bi.scss';

export const OnlinePaymentRedirect = (props: any) => {
	const history = useHistory();
	const { search } = useLocation();
	const onlinePaymentInfo = queryString.parse(search);
	useEffect(() => {
		console.log('OnlinePaymentRedirect', search, onlinePaymentInfo);
	});

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const paymentInfo = JSON.parse(localStorage.getItem('paymentInfo'));
	const checkoutID = JSON.parse(localStorage.getItem('checkoutId'));

	const [
		createPaymentByQuantity,
		{ loading: paymentQuantityLoading },
	] = useCreatePaymentByQuantityMutation({
		onError: error => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.paymentFailed());
			history.push(
				`/menu/outlet-app/${paymentInfo?.billInput?.outletID}/table/grid/${paymentInfo?.tableID}/order/${paymentInfo?.billInput?.orderID}/online-payment/error`,
			);
		},
		onCompleted: ({ paymentByQuantity }) => {
			history.push(
				`/menu/outlet-app/${paymentInfo?.billInput?.outletID}/table/grid/${paymentInfo?.tableID}/order/${paymentInfo?.billInput?.orderID}/bill-settlement/${paymentByQuantity?.ID}`,
			);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.paymentSuccess());
		},
	});

	useEffect(() => {
		if (onlinePaymentInfo?.status === 'SUCCESS') {
			createPaymentByQuantity({
				variables: {
					paymentByQuantityInput: paymentInfo?.paymentByQuantityInput,
					billInput: paymentInfo?.billInput,
					paymentInput: {
						...paymentInfo?.paymentInput,
						checkoutID: `${checkoutID}`,
					},
				},
			});
		} else {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.paymentFailed());
			history.push(
				`/menu/outlet-app/${paymentInfo?.billInput?.outletID}/table/grid/${paymentInfo?.tableID}/order/${paymentInfo?.billInput?.orderID}/online-payment/error`,
			);
		}
	}, []);

	return <></>;
};
