import React from 'react';
import { UserDetail } from './UsersDetails';
import { UsersForm } from './UsersForm';
import { UsersList } from './UsersList';

const userRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/user-access/user`,
		},
		component: <UsersList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/user-access/user/:userID/detail`,
		},
		component: <UserDetail />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/user-access/user/add`,
		},
		component: <UsersForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/user-access/user/:userID/edit`,
		},
		component: <UsersForm mode="edit" />,
	},
];

export default userRoutes;
