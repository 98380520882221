import React from 'react';
import { HomeIcon } from './HomeIcon';

const homeRoutes = [
	{
		props: { exact: true, path: '/' },
		component: <HomeIcon />,
	},
	{
		props: { exact: true, path: '/home' },
		component: <HomeIcon />,
	},
];

export default homeRoutes;
