import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  IconButton,
} from '@material-ui/core'
import React from 'react'

interface ICardAddOnProps {
  children: any
  style?: Record<string, string>
  contentStyle?: Record<string, string>
  section?: {
    header?: {
      title?: string
      subtitle?: string
      onClickAction?: (event: React.MouseEvent<HTMLButtonElement>) => void
      icon?: any
      disabled?: boolean
    }
    footer?: {
      onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
      label?: string
    }
  }
  devFullWidth?: any
}

export function CardContents(props: ICardAddOnProps) {
  const { children, section, style, contentStyle, devFullWidth } = props
  // subtitle={
  //   <span className="mdDesc c-darkblue">
  //     {section?.header?.subtitle}{' '}
  //   </span>
  // }

  return (
    // <Box className={devFullWidth ? 'dev-card-form' : 'card-form'} style={style}>
    <Box className="dev-card-form" style={style}>
      <Card>
        {section?.header && (
          <CardHeader
            disableTypography
            title={
              <span className="smTitle color-primary-orange">
                {section?.header?.title}{' '}
              </span>
            }
            action={
              <IconButton
                disabled={section?.header?.disabled}
                className="card-header-btn"
                aria-label="edit"
                onClick={section?.header?.onClickAction}
              >
                {section?.header?.icon}
              </IconButton>
            }
          />
        )}

        <CardContent style={contentStyle}>{children}</CardContent>
      </Card>
      {section?.footer && (
        <Box className="btn-container">
          <Button
            color="primary"
            variant="contained"
            onClick={section?.footer?.onClick}
          >
            {section?.footer?.label}
          </Button>
        </Box>
      )}
    </Box>
  )
}

export default CardContents
