import React from 'react'
import PhoneIphoneIcon from '@material-ui/icons/PhoneIphone'
import { makeStyles } from '@material-ui/core/styles'
import theme from '@ifca-root/react-component/src/assets/theme'

interface Props {
  space?: string
  font?: string
  children: any
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
}

const PhoneText = (props: Props) => {
  let { children, font, space, onClick } = props

  //Assing Space to Container
  const useStyles = makeStyles({
    containerSpace: {
      width: space,
      padding: 0,
      [theme.breakpoints.up(600)]: {
        height: '3vh !important',
      },
    },
  })
  const classes = useStyles(props)

  //Default Font
  if (font == null) font = 'fs-10'

  return (
    <>
      <span className={`${classes.containerSpace} icon-text`} onClick={onClick}>
        <PhoneIphoneIcon className="indicator" />

        <span className={`${font} primary-click-text`}>{children}</span>
      </span>
    </>
  )
}

export default PhoneText
