import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import PhoneText from '@ifca-root/react-component/src/components/Typography/PhoneText';
import CreateIcon from '@material-ui/icons/Create';
import {
	useGetHotelBySubscriptionQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import React from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

export const OutletProfileDetail = () => {
	let history = useHistory();

	const { outletID }: any = useParams();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const {
		loading: hotelBySubscriptionLoading,
		data: { getHotelBySubscription } = { getHotelBySubscription: [] },
	} = useGetHotelBySubscriptionQuery({
		fetchPolicy: 'network-only',
	});

	const hotelName = getHotelBySubscription?.data?.MenuXHotelListing?.filter(
		hotel => hotel?.ID === getOutlet[0]?.outletChannel?.hotelID,
	)[0]?.HotelName;

	return (
		<>
			{outletLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Outlet Profile', current: true },
				]}
				rightRouteSegments={[{ name: 'Details' }]}
			/>
			<ContentWrapper>
				<CardContents
					devFullWidth={true}
					section={{
						header: {
							title: 'Outlet Profile Details',
							onClickAction: () =>
								history.push({
									pathname: `/menu/outlet-app/outlet-setting/${outletID}/outlet-profile/edit`,
									//pathname: `/menu/outlet-app/outlet-setting/${outletID}/profile/edit`,
									state: getOutlet[0],
								}),
							icon: <CreateIcon />,
						},
					}}
				>
					<div className="content-wrap">
						<div className="desc">Outlet Name</div>
						<div className="xxTitle">{outletArray[0]?.name}</div>
					</div>
					<div className="content-wrap">
						<div className="desc">Outlet Code</div>
						<div className="xxTitle">{outletArray[0]?.outletCode}</div>
					</div>
					<div className="content-wrap" style={{ marginBottom: '0px' }}>
						<div className="desc">Company Name</div>
						<div className="xxTitle" style={{ marginBottom: '12px' }}>
							{outletArray[0]?.companyName}
						</div>
						<div className="desc">Hotel</div>
						<div className="xxTitle">{hotelName}</div>
					</div>

					<div className="content-wrap" style={{ marginBottom: '0px' }}>
						<div className="desc">Outlet Logo</div>
						{outletArray[0]?.logo?.fileURL && (
							<img
								style={{
									height: '80px',
									width: '80px',
									cursor: 'pointer',
									padding: '5px',
									border: '1',
								}}
								src={outletArray[0]?.logo?.fileURL}
							/>
						)}
					</div>

					<div className="content-wrap full">
						<div className="desc">Outlet Address</div>
						<div className="xxTitle text-noflow">
							{outletArray[0]?.address.address},
							{outletArray[0]?.address.postCode} {outletArray[0]?.address.city},
							{outletArray[0]?.address.state}, {outletArray[0]?.address.country}
						</div>
					</div>

					<div className="content-wrap">
						<div className="desc">Company Registration No</div>
						<div className="xxTitle">{outletArray[0]?.registrationNo}</div>
					</div>
					<div className="content-wrap">
						<div className="desc">SST Registration No</div>
						<div className="xxTitle"> {outletArray[0]?.taxRegistrationNo}</div>
					</div>

					<div className="content-wrap">
						<div className="desc">Phone No</div>
						<PhoneText font="xsTitle">
							<a
								href={`tel:${outletArray[0]?.contactNo}`}
								className="highlight-text"
							>
								{outletArray[0]?.contactNo || 'N/A'}
							</a>
						</PhoneText>
					</div>

					<div className="content-wrap">
						<div className="desc">Email</div>
						<div className="xxTitle">{outletArray[0]?.email}</div>
					</div>

					<div className="content-wrap">
						<div className="desc">Operating Hours</div>
						<div className="xxTitle">
							{outletArray[0]?.openingTime} - {outletArray[0]?.closingTime}
						</div>
					</div>

					<div className="content-wrap">
						<div className="desc">Capacity (Pax)</div>
						<div className="xxTitle">{outletArray[0]?.capacity}</div>
					</div>

					<div className="content-wrap">
						<div className="desc">Auto Day End Time</div>
						<div className="xxTitle">{outletArray[0]?.autoDayEndTime}</div>
					</div>

					<div className="content-wrap">
						<div className="desc">Govt Tax</div>
						<div className="xxTitle">{outletArray[0]?.govTax}%</div>
					</div>

					<div className="content-wrap">
						<div className="desc">Service Charge</div>
						<div className="xxTitle">{outletArray[0]?.serviceCharge}%</div>
					</div>

					<div className="content-wrap" style={{ marginBottom: '0px' }}>
						<div className="desc">Outlet Gallery</div>
						{outletArray[0]?.gallery?.fileURL && (
							<img
								style={{
									height: '80px',
									width: '80px',
									cursor: 'pointer',
									padding: '5px',
									border: '1',
								}}
								src={outletArray[0]?.gallery?.fileURL}
							/>
						)}
					</div>

					{/* <div className="content-wrap full">
						<div className="desc">Description</div>
						<div className="xxTitle text-noflow">
							{outletArray[0]?.description}
						</div>
					</div>
					<div className="content-wrap full">
						<div className="desc">Attachment(s)</div>
						{/* {DocumentListing} */}
					{/* </div> */}
				</CardContents>
			</ContentWrapper>
		</>
	);
};
