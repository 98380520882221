import React from 'react';
import { AuthorisedStaffDetail } from './AuthorisedStaffDetail';
import { AuthorisedStaffForm } from './AuthorisedStaffForm';
import { AuthorisedStaffList } from './AuthorisedStaffList';

const authorisedStaffRoutes = [
	{
		props: { exact: true, path: `/menu/outlet-app/general-setting/employee` },
		component: <AuthorisedStaffList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/employee/add`,
		},
		component: <AuthorisedStaffForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/employee/:staffID/edit`,
		},
		component: <AuthorisedStaffForm mode="edit" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/employee/:staffID/detail`,
		},
		component: <AuthorisedStaffDetail />,
	},
];

export default authorisedStaffRoutes;
