import theme from '@ifca-root/react-component/src/assets/theme';
import {
	Breadcrumbs,
	Grid,
	IconButton,
	Toolbar,
	useMediaQuery,
} from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import MenuIcon from '@material-ui/icons/Menu';
import AppContext from 'containers/App/Store/AppContext';
import React, { useContext } from 'react';

interface CustomMainHeader {
	infoLeft?: any;
	mainTitle?: string;
	smallTitle?: string;
	infoRight?: any;
	customIcon?: string;
	dropDownOptions?: any;
}

export const MainHeaderKitchen = (props: CustomMainHeader) => {
	const {
		infoLeft: routeSegments,
		mainTitle,
		smallTitle,
		infoRight: rightRouteSegments,
	} = props;

	let iphone5Width = 320;

	const { globalState, dispatch } = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	// Drawer Toggler
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	return (
		<Grid container>
			<AppBar
				className={
					isDesktop ? 'desktop-header' : `mobile-header fix-responsive`
				}
				position={'fixed'}
				style={{ backgroundColor: '#ff9800' }}
			>
				<Toolbar>
					{/* <div style={{ borderBottom: ' 1px solid  #ffb11f' }}> */}
					<IconButton
						color="inherit"
						aria-label="open drawer"
						edge="start"
						onClick={handleDrawer}
					>
						<MenuIcon />
					</IconButton>
					<Grid item xs={10}>
						<div
							className={'domain-container'}
							style={{ borderBottom: 'inherit' }}
						>
							<div
								className={isDesktop ? '' : 'domain-session'}
								style={{ fontSize: 'small' }}
							>
								<div
									className="authorizer text-noflow"
									style={{
										lineHeight: '0.7rem',
										fontSize: isDesktop ? 'small' : 'x-small',
										fontWeight: isDesktop ? 'bold' : 'bolder',
									}}
								>
									{smallTitle}
								</div>
								<div
									className={isDesktop ? '' : 'domain'}
									style={{
										color: '#212121',
										fontSize: '12px',
										fontWeight: isDesktop ? 'bold' : 'bolder',
									}}
								>
									{mainTitle}
								</div>
							</div>
						</div>
					</Grid>
					<div
						style={{
							position: 'absolute',
							right: isDesktop ? '20px' : '20px',
						}}
					>
						{/* <ExitToAppIcon /> */}
					</div>
					<div
						style={{
							position: 'absolute',
							top: '45px',
							width: isDesktop ? ' 95%' : '94%',
							borderBottom: 'inset 1px #ffb11f',
							left: isDesktop ? '25px' : '11px',
						}}
					></div>
					<div style={{ borderBottom: '1px solid #ffb11f' }}></div>
					<div
						style={{
							width: '100%',
							display: 'flex',
							marginLeft: isDesktop ? '100px' : '0px',
							// position: isDesktop ? 'absolute' : 'relative',
							// top: isDesktop ? '45px' : '',
							// left: isDesktop ? '-39px' : '',
						}}
					>
						<Breadcrumbs
							className="kitchen-breadcrumbs text-noflow"
							separator="/"
							aria-label="breadcrumb"
							style={{
								position: isDesktop ? 'absolute' : 'relative',
								top: isDesktop ? '45px' : '-12px',
								left: isDesktop ? '62px' : '',
								marginTop: '-4px',
							}}
						>
							{routeSegments?.map((el, index) => {
								return (
									<span
										className="breadcrumb-mainTitle text-noflow"
										key={index}
										style={{
											fontWeight: el.current ? 'bolder' : 'bolder',
											lineHeight: '1rem',
											fontSize: isDesktop ? 'x-small' : 'xx-small',
											color: '#212121',
										}}
									>
										{el.current ? el.name : '...'}
									</span>
								);
							})}
						</Breadcrumbs>

						{rightRouteSegments && (
							<Breadcrumbs
								className="breadcrumbs-right"
								separator="/"
								aria-label="breadcrumb"
								style={{
									position: isDesktop ? 'absolute' : 'absolute',
									top: isDesktop ? '45px' : '45px',
									right: isDesktop ? '10px' : '10px',
								}}
							>
								{rightRouteSegments?.map((el, index) => {
									return (
										<span
											className="breadcrumb-mainTitle-right"
											key={index}
											style={{
												fontWeight: el.current ? 'normal' : 'normal',
												fontSize: isDesktop ? 'x-small' : 'xx-small',
												// paddingRight: isDesktop ? '30px' : '15px',
											}}
										>
											{' '}
											{el.name}
										</span>
									);
								})}
							</Breadcrumbs>
						)}
					</div>
					{/* </div> */}
				</Toolbar>
			</AppBar>
		</Grid>
	);
};
