import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	Radio,
	RadioGroup,
	TextField,
} from '@material-ui/core';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import BigNumber from 'bignumber.js';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	FrequencyType,
	GetPromoMenuDocument,
	useCreatePromoMenuMutation,
	useGetOutletQuery,
	useGetPromoMenuQuery,
	useUpdatePromoMenuMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { useContext, useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './promoMenu.scss';
import * as yup from 'yup';

// interface DialogFormFields {
// 	name: string;
// 	StartDate: string;
// 	EndDate: string;
// 	description: string;
// 	taxType: string;
// 	PromotionValue: string;
// }

// const dialogFormFieldsInitialState: DialogFormFields = {
// 	name: '',
// 	StartDate: '',
// 	EndDate: '',
// 	description: '',0.........................

// 	taxType: '',
// 	PromotionValue: '',
// };

interface PromoMenuProps {
	name: string;
	description: string;
	startDate: Date;
	endDate: Date;
	startTime: Date;
	endTime: Date;
	frequencyType: string;
	weekly: any;
	monthly: any;
}

export const dayList = [
	{
		name: 'Sunday',
		value: 0b0000001,
	},
	{
		name: 'Monday',
		value: 0b0000010,
	},
	{
		name: 'Tuesday',
		value: 0b0000100,
	},
	{
		name: 'Wednesday',
		value: 0b0001000,
	},

	{
		name: 'Thursday',
		value: 0b0010000,
	},
	{
		name: 'Friday',
		value: 0b0100000,
	},
	{
		name: 'Saturday',
		value: 0b1000000,
	},
];

export const weekList = [
	{
		name: 'Week 1',
		value: 0b0000001,
	},
	{
		name: 'Week 2',
		value: 0b0000010,
	},
	{
		name: 'Week 3',
		value: 0b0000100,
	},

	{
		name: 'Week 4',
		value: 0b0001000,
	},
	{
		name: 'Week 5',
		value: 0b0010000,
	},
];

export const PromotionalMenuForm = (props: any) => {
	useEffect(() => {
		console.log('PromotionalMenuForm');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	const { mode } = props;
	const location = useLocation();

	const editData: any = location?.state as any;

	const { outletID, promotionalMenuID }: any = useParams();

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { data } = useGetPromoMenuQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, ID: promotionalMenuID },
	});
	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const [
		createPromoMenu,
		{ data: createdPromoMenuData, loading: createPromoMenuLoading },
	] = useCreatePromoMenuMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/promotional-menu`,
					state: { success: true, msgMode: 'create' },
				});
				//history.goBack();
			}, 500);
		},
	});
	const [
		updatePromoMenu,
		{ loading: subUpdateLoading, error: subUpdateError },
	] = useUpdatePromoMenuMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			// history.push(`/subcontract/${consubconId}/vo`);

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/promotional-menu/${promotionalMenuID}/edit/promotional-menu-pricing`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
		},
	});
	const onSubmit = (data: any) => {
		const weeklyRadioSum = data?.weekly?.day
			?.filter(d => d !== false)
			.reduce(
				(accumulator, currentValue) =>
					new BigNumber(accumulator).plus(currentValue).toNumber(),
				0,
			);

		const monthlyRadioWeekSum = data?.monthly?.weekly?.week
			?.filter(d => d !== false)
			.reduce(
				(accumulator, currentValue) =>
					new BigNumber(accumulator).plus(currentValue).toNumber(),
				0,
			);

		const monthlyRadioDaySum = data?.monthly?.weekly?.day
			?.filter(d => d !== false)
			.reduce(
				(accumulator, currentValue) =>
					new BigNumber(accumulator).plus(currentValue).toNumber(),
				0,
			);

		let everyday = data.frequencyType === FrequencyType.Daily ? 1 : null;

		let recurXWeek = data.frequencyType === FrequencyType.Weekly ? 1 : null;

		let recurDayX =
			data?.monthly?.daily?.recurAtDayXOfMonth && watch('monthly.daily.button')
				? new BigNumber(data?.monthly?.daily?.recurAtDayXOfMonth).toNumber()
				: null;

		let recurXMonth =
			data?.monthly?.daily?.recurEveryXMonths && watch('monthly.daily.button')
				? new BigNumber(data?.monthly?.daily?.recurEveryXMonths).toNumber()
				: null;

		let recurWeekBitmap = watch('monthly.weekly.button')
			? monthlyRadioWeekSum
			: null;
		let recurDayBitmap = watch('monthly.weekly.button')
			? monthlyRadioDaySum
			: weeklyRadioSum;

		if (mode == 'add') {
			createPromoMenu({
				variables: {
					input: {
						outletID: outletID,
						name: data?.name,
						description: '',
						frequencyType: data?.frequencyType,
						validityStartTs: data?.startDate,
						validityEndTs: data?.endDate,
						recurStartTime: data?.startTime,
						recurEndTime: data?.endTime,
						//SCHEDULER
						recurEveryXDays: everyday,
						recurEveryXWeeks: recurXWeek,
						recurEveryXMonths: recurXMonth,
						recurAtDayXOfMonth: recurDayX,
						recurWeekBitmap: recurWeekBitmap ?? null,
						recurDayBitmap: recurDayBitmap ?? null,
					},
				},
				refetchQueries: [
					{ query: GetPromoMenuDocument, variables: { outletID } },
				],
			});
		} else if (mode === 'edit') {
			updatePromoMenu({
				variables: {
					input: {
						outletID: outletID,
						ID: promotionalMenuID,
						name: data?.name,
						description: '',
						frequencyType: data?.frequencyType,
						validityStartTs: data?.startDate,
						validityEndTs: data?.endDate,
						recurStartTime: data?.startTime,
						recurEndTime: data?.endTime,
						//SCHEDULER
						recurEveryXDays: everyday,
						recurEveryXWeeks: recurXWeek,
						recurEveryXMonths: recurXMonth,
						recurAtDayXOfMonth: recurDayX,
						recurWeekBitmap: recurWeekBitmap ?? null,
						recurDayBitmap: recurDayBitmap ?? null,
					},
				},
				refetchQueries: [
					{
						query: GetPromoMenuDocument,
						variables: { outletID },
					},
				],
			});
		}
	};

	const yupSchema = yup.object().shape({
		name: CommonYupValidation.requireField(SystemMsgs.name()).trim(),
	});

	const { handleSubmit, register, watch, setValue, errors, control } = useForm<
		PromoMenuProps
	>({
		defaultValues: {
			name: mode === 'add' ? '' : editData?.name,
			frequencyType: mode === 'add' ? '' : editData?.frequencyType,
			startDate: mode === 'add' ? new Date() : editData?.validityStartTs,
			endDate: mode === 'add' ? new Date() : editData?.validityEndTs,
			startTime: mode === 'add' ? '' : editData?.recurStartTime,
			endTime: mode === 'add' ? '' : editData?.recurEndTime,
		},
		resolver: yupResolver(yupSchema),
		mode: 'onSubmit',
	});

	const handleWeeklyDayCheckbox = (e: any, index: number) => {
		register(`weekly.day[${index}]`);
		if (!e.target.checked) {
			setValue(`weekly.day[${index}]`, null);
		} else {
			setValue(`weekly.day[${index}]`, parseFloat(e.target.value));
		}
	};

	const handleMonthlyWeeklyDayCheckbox = (e: any, index: number) => {
		register(`monthly.weekly.day[${index}]`);
		if (!e.target.checked) {
			setValue(`monthly.weekly.day[${index}]`, null);
		} else {
			setValue(`monthly.weekly.day[${index}]`, parseFloat(e.target.value));
		}
	};

	const handleMonthlyWeeklyWeekCheckbox = (e: any, index: number) => {
		register(`monthly.weekly.week[${index}]`);
		if (!e.target.checked) {
			setValue(`monthly.weekly.week[${index}]`, null);
		} else {
			setValue(`monthly.weekly.week[${index}]`, parseFloat(e.target.value));
		}
	};

	const handleMonthlyDailyButton = (e: any) => {
		setValue(`monthly.daily.button`, e.target.value);
		setValue(`monthly.weekly.button`, null);
	};

	const handleMonthlyWeeklyButton = (e: any) => {
		setValue(`monthly.weekly.button`, e.target.value);
		setValue(`monthly.daily.button`, null);
	};

	useEffect(() => {
		if (new BigNumber(editData?.recurAtDayXOfMonth).isGreaterThan(0)) {
			setValue(`monthly.daily.button`, FrequencyType.Daily);
		} else if (new BigNumber(editData?.recurWeekBitmap).isGreaterThan(0)) {
			setValue(`monthly.weekly.button`, FrequencyType.Weekly);
		}
	}, [editData]);

	return (
		<>
			{outletLoading && <Loading />}
			{createPromoMenuLoading && <Loading />}
			{subUpdateLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: editData
							? `/menu/outlet-app/outlet-setting/${outletID}/promotional-menu/${promotionalMenuID}/edit/promotional-menu-pricing`
							: `/menu/outlet-app/outlet-setting/${outletID}/promotional-menu`,
					})
				}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Promotional Menu & Pricing', current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<ContentWrapper>
					<CardContents devFullWidth={true}>
						<Controller
							as={TextField}
							name="name"
							fullWidth
							autoComplete="off"
							margin="normal"
							label={`Promotion Name`}
							control={control}
							ref={register}
							// onChange={handleChange('name')}
							// value={PromotionMenu.name}
							required
							helperText={errors?.name?.message}
							error={errors?.name ? true : false}
						/>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Controller
								as={KeyboardDatePicker}
								name="startDate"
								label="Start Date"
								control={control}
								format="dd MMM yyyy"
								value={new Date()}
								defaultValue={new Date()}
								onChange={(date: Date | null) => {
									console.log(date);
								}}
								allowKeyboardControl
								disablePast
								fullWidth
								className="left"
								margin="normal"
								// inputRef={registerCancel({})}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
							/>
						</MuiPickersUtilsProvider>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Controller
								as={KeyboardDatePicker}
								name="endDate"
								label="End Date"
								control={control}
								format="dd MMM yyyy"
								value={new Date()}
								disablePast
								defaultValue={new Date()}
								onChange={(date: Date | null) => {
									console.log(date);
								}}
								allowKeyboardControl
								fullWidth
								className="right"
								margin="normal"
								// inputRef={registerCancel({})}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
							/>
						</MuiPickersUtilsProvider>
						<Controller
							as={TextField}
							name="startTime"
							label="Start Time"
							type="time" //defaultValue="14:00"
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								step: 60, // 1 min
							}}
							required
							className="left"
							fullWidth
							ref={register}
							control={control}
							margin="normal"
							autoComplete="off"
						></Controller>{' '}
						<Controller
							as={TextField}
							name="endTime"
							label="End Time"
							type="time" //defaultValue="14:00"
							InputLabelProps={{
								shrink: true,
							}}
							inputProps={{
								step: 60, // 1 min
							}}
							required
							className="left"
							fullWidth
							ref={register}
							control={control}
							margin="normal"
							autoComplete="off"
						></Controller>
						{/* {RADIO BUTTONS} */}
						{/* {RADIO BUTTONS} */}
						{/* {RADIO BUTTONS} */}
						<Controller
							as={
								<RadioGroup
									aria-label="example"
									row
									style={{ width: '100%' }}
									className="place-content-center"
								>
									{/* {options?.map((v, index) => ( */}
									<Grid container>
										<Grid item xs={4} className="left">
											<FormControlLabel
												//   key={index}
												color="primary"
												value={FrequencyType.Daily}
												className="left "
												control={<Radio color="primary" />}
												label={<span>{'Daily'}</span>}
												// name="everyday"
												// ref={register}
											/>
										</Grid>
										<Grid item xs={4} className="left">
											<FormControlLabel
												//   key={index}
												color="primary"
												value={FrequencyType.Weekly}
												className="right"
												control={<Radio color="primary" />}
												label={'Weekly'}
											/>
										</Grid>
										<Grid item className="right">
											<FormControlLabel
												//   key={index}
												color="primary"
												value={FrequencyType.Monthly}
												className="right"
												control={<Radio color="primary" />}
												label={<span>{'Monthly'}</span>}
											/>
										</Grid>
									</Grid>

									{/* ))} */}
								</RadioGroup>
							}
							name="frequencyType"
							control={control}
							ref={register}
							// helperText={errors?.calcType?.message}
							// error={errors?.calcType ? true : false}
						/>
					</CardContents>

					{/* {WEEKLY} */}
					{/* {WEEKLY} */}
					{/* {WEEKLY} */}
					{watch('frequencyType') === FrequencyType.Weekly && (
						<CardContents>
							{dayList.map((el, index) => {
								return (
									<>
										<FormControl
											component="fieldset"
											style={{ marginBottom: '0px' }}
										>
											<FormControlLabel
												control={
													<Checkbox
														onChange={e => {
															handleWeeklyDayCheckbox(e, index);
														}}
														defaultChecked={
															editData?.recurDayBitmap & el?.value
																? true
																: false
														}
														name={`weekly.day[${index}]`}
														inputRef={register()}
														color="primary"
														value={el?.value}
													/>
												}
												label={el?.name}
											/>
										</FormControl>
									</>
								);
							})}
						</CardContents>
					)}

					{/* {MONTHLY} */}
					{/* {MONTHLY} */}
					{/* {MONTHLY} */}
					{watch('frequencyType') === FrequencyType.Monthly && (
						<CardContents style={{ width: '100%' }}>
							<RadioGroup
								aria-label="example"
								name="example"
								row
								style={{ width: '100%' }}
								className="place-content-center"
								defaultValue={
									new BigNumber(editData?.recurAtDayXOfMonth).isGreaterThan(0)
										? FrequencyType.Daily
										: new BigNumber(editData?.recurWeekBitmap).isGreaterThan(0)
										? FrequencyType.Weekly
										: null
								}
							>
								{/* {DAILY} */}
								{/* {DAILY} */}
								{/* {DAILY} */}
								<Grid container spacing={0}>
									<Grid item xs={3} className="left">
										<Controller
											as={
												<FormControlLabel
													control={
														<Radio
															name="monthly.daily.button"
															color="primary"
															value={FrequencyType.Daily}
															onChange={e => {
																handleMonthlyDailyButton(e);
															}}
														/>
													}
													label="Daily"
												/>
											}
											name="monthly.daily.button"
											control={control}
										/>
									</Grid>
									<Grid item className="right" style={{ paddingTop: '8px' }}>
										<span>Day of Month</span>
										<span>
											<Controller
												as={NumberFormat}
												customInput={TextField}
												name="monthly.daily.recurAtDayXOfMonth"
												autoComplete="off"
												multiline={true}
												fullWidth
												ref={register}
												control={control}
												margin="dense"
												className="monthly-day-info"
												disabled={!watch('monthly.daily.button')}
												style={{
													width: '50px',
													margin: '0px',
													paddingRight: '5px',
													paddingLeft: '5px',
												}}
												defaultValue={editData?.recurAtDayXOfMonth || null}
											></Controller>
										</span>
										<span>of Every</span>
										<span>
											<Controller
												as={NumberFormat}
												customInput={TextField}
												name="monthly.daily.recurEveryXMonths"
												autoComplete="off"
												multiline={true}
												fullWidth
												ref={register}
												control={control}
												margin="dense"
												className="monthly-day-info"
												disabled={!watch('monthly.daily.button')}
												style={{
													width: '50px',
													margin: '0px',
													paddingRight: '5px',
													paddingLeft: '5px',
												}}
												defaultValue={editData?.recurEveryXMonths || null}
											></Controller>
										</span>
										<span>Months</span>
									</Grid>

									{/* {WEEKLY} */}
									{/* {WEEKLY} */}
									{/* {WEEKLY} */}
									<Grid item xs={12} className="left">
										<Controller
											as={
												<FormControlLabel
													control={
														<Radio
															name="monthly.weekly.button"
															color="primary"
															value={FrequencyType.Weekly}
															onChange={e => {
																handleMonthlyWeeklyButton(e);
															}}
														/>
													}
													label="Weekly"
												/>
											}
											name="monthly.weekly.button"
											control={control}
										/>
									</Grid>
									<Grid item xs={1}></Grid>
									<Grid item xs={5}>
										{weekList.map((el, index) => {
											return (
												<>
													<FormControl
														component="fieldset"
														style={{ marginBottom: '0px' }}
													>
														<FormControlLabel
															control={
																<Checkbox
																	onChange={e => {
																		handleMonthlyWeeklyWeekCheckbox(e, index);
																	}}
																	defaultChecked={
																		editData?.recurWeekBitmap & el?.value
																			? true
																			: false
																	}
																	disabled={!watch('monthly.weekly.button')}
																	name={`monthly.weekly.week[${index}]`}
																	inputRef={register()}
																	color="primary"
																	value={el?.value}
																/>
															}
															label={el?.name}
														/>
													</FormControl>
												</>
											);
										})}
									</Grid>
									<Grid item xs={6}>
										{dayList.map((el, index) => {
											return (
												<>
													<FormControl
														component="fieldset"
														style={{ marginBottom: '0px' }}
													>
														<FormControlLabel
															control={
																<Checkbox
																	onChange={e => {
																		handleMonthlyWeeklyDayCheckbox(e, index);
																	}}
																	defaultChecked={
																		editData?.recurDayBitmap & el?.value
																			? true
																			: false
																	}
																	disabled={!watch('monthly.weekly.button')}
																	name={`monthly.weekly.day[${index}]`}
																	inputRef={register()}
																	color="primary"
																	value={el?.value}
																/>
															}
															label={el?.name}
														/>
													</FormControl>
												</>
											);
										})}
									</Grid>
								</Grid>
							</RadioGroup>
						</CardContents>
					)}
				</ContentWrapper>
				<div style={{ paddingTop: '46px' }}></div>
				<DynamicFooter
					options={[
						{
							name: 'Save',
							//   onClick: handleSubmit(data => onSubmit(data, 'submit')),

							color: 'primary',
							props: {
								type: 'submit',
							},
						},
					]}
				/>
			</form>
		</>
	);
};
