import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { StyledMenu } from '@ifca-root/react-component/src/components/others/Menu';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Divider,
	FormControl,
	Grid,
	Input,
	InputAdornment,
	InputLabel,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetDigitalMenuCategoryDocument,
	GetDigitalMenuCategoryForPriceDocument,
	GetRemainingMenuItemsForDigitalDocument,
	useDeleteDigitalMenuItemAndPriceMutation,
	useDigitalMenuPriorityAndNameQuery,
	useGetDigitalMenuCategoryForPriceQuery,
	useGetDigitalMenuCategoryQuery,
	useGetOutletQuery,
	useGetPatronQuery,
	useUpdateDigitalMenuCategoryMutation,
	useUpdateDigitalMenuItemPriceMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { amtStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { StringLocale } from 'yup/lib/locale';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
import './priceMenu.scss';

interface MenuCategory {
	menu: string;
	no: StringLocale;
}

// interface PatronType {
// 	// [key: string]: number;
// 	key: '';
// }

export const DigitalMenuPricingList = () => {
	useEffect(() => {
		console.log('DigitalMenuPricingList');
	}, []);
	const history = useHistory();
	const location = useLocation();
	const { [`outletID`]: outletID, digitalMenuID, menuCategoryID } = useParams<
		Record<string, any>
	>();

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	// const categoryData = location.state as any;
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const [open, setOpen] = useState(false);
	const [openMenu, setOpenMenu] = useState(false);
	const [menuData, setMenuData] = useState(null);
	const [openConfirm, setOpenConfirm] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const PriceSchema = yup.lazy(details => {
		return yup.object().shape(
			Object.fromEntries(
				Object.keys(details).map(key => {
					return [
						key,
						yup
							.number()
							.transform(value => (isNaN(value) ? 0 : value))
							.required('Required field!'),
					];
				}),
			),
		);
	});

	const { register, control, setValue, watch, handleSubmit, errors } = useForm({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(PriceSchema),
	});

	const {
		register: menuReg,
		control: menuCont,
		handleSubmit: menuHandleSubmit,
	} = useForm<MenuCategory>({
		defaultValues: {},
	});

	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS

	const {
		data: menuCategoryData,
		loading: menuCategoryLoading,
	} = useDigitalMenuPriorityAndNameQuery({
		variables: {
			ID: menuCategoryID,
		},
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const {
		data: { getDigitalMenuCategory } = { getDigitalMenuCategory: [] },
		loading: digitalMenuCategoryLoading,
	} = useGetDigitalMenuCategoryForPriceQuery({
		fetchPolicy: 'network-only',
		variables: {
			ID: menuCategoryID,
		},
	});

	const {
		data: { getPatron } = { getPatron: [] },
		loading: getPatronLoading,
	} = useGetPatronQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
			isActive: true,
		},
	});

	const {
		data: getOtherDigitalMenuCategory,
		loading: OtherDigitalMenuCategoryLoading,
	} = useGetDigitalMenuCategoryQuery({
		fetchPolicy: 'network-only',
		variables: {
			digitalMenuID: digitalMenuID,
		},
	});

	const [
		updateMenuCategory,
		{ loading: updateMenuCategoryLoading },
	] = useUpdateDigitalMenuCategoryMutation();

	const [
		updateDigitalMenuItemPrice,
		{ loading: updateDigitalMenuItemPriceLoading },
	] = useUpdateDigitalMenuItemPriceMutation({
		onCompleted: ({ updateDigitalMenuItemPrice }) => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			if (updateDigitalMenuItemPrice) {
				setOpen(false);
				setMenuData(null);
			}
		},
	});

	const [
		deleteDigitalMenuItemAndPrice,
		{ loading: deleteDigitalMenuItemAndPriceLoading },
	] = useDeleteDigitalMenuItemAndPriceMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: ({ deleteDigitalMenuItemAndPrice }) => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
			if (deleteDigitalMenuItemAndPrice) {
				setOpenConfirm(false);
				setMenuData(null);
			}
		},
	});

	//COMPONENTS
	//COMPONENTS
	//COMPONENTS
	//COMPONENTS
	//COMPONENTS

	const spacing_nine = () => {
		return (
			<Grid container>
				<Grid item xs={9} className="left"></Grid>
			</Grid>
		);
	};

	const patron_content = (data: any, styleJson?: any) => {
		return (
			<>
				<Grid container>
					{data?.map((v: any, i: number) => (
						<Grid item xs={3}>
							<div
								className="desc"
								style={{ ...styleJson, paddingBottom: '5px', color: 'black' }}
							>
								{v.patron.patronType.name}
							</div>
							<div className="desc" style={{ ...styleJson, color: '#ff9800' }}>
								{`${amtStr(v.price)}`}
							</div>
						</Grid>
					))}
				</Grid>
			</>
		);
	};

	const confirm_deletion = () => {
		return (
			<CommonDialog
				fullWidth={true}
				open={openConfirm}
				onClose={() => {
					setOpenConfirm(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="smTitle">Delete Menu Item Pricing</div>
										<div className="flex session">
											<div className="title flex-space">
												{menuData?.menuItem?.name}
											</div>
										</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="mdDesc"> Confirm Deletion? </div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenConfirm(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										deleteDigitalMenuItemAndPrice({
											variables: {
												digitalMenuItemID: menuData?.ID,
											},
											refetchQueries: [
												{
													query: GetDigitalMenuCategoryForPriceDocument,
													variables: { ID: menuCategoryID },
												},
												{
													query: GetRemainingMenuItemsForDigitalDocument,
													variables: {
														digitalMenuCategoryID: menuCategoryID,
														outletID: outletID,
													},
												},
											],
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		);
	};

	const edit_pricing_dialog = () => {
		return (
			<CommonDialog
				className="list-item-diag"
				fullWidth={true}
				open={open}
				onClose={() => {
					setMenuData(null);
					setOpen(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Edit Menu Item Pricing</div>
										{/* <div className="flex session">
											<div className="title flex-space">
												{menuData?.menuItem?.name}
											</div>
										</div> */}
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle infoline">
										{menuData?.menuItem?.name}
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<ContentWrapper style={{ marginTop: '0px' }}>
								<div
									style={{
										marginBottom: '20px',
									}}
									className="text-noflow mdDesc"
								>
									{menuData?.menuItem?.description}
								</div>
								{getPatron?.map((v, index) => {
									const patronPrice = menuData?.digitalMenuItemPrice?.filter(
										p => p?.patronID === v?.ID,
									)[0];
									// <div className="patron-type-div">
									return (
										<Grid container style={{ paddingTop: '6px' }}>
											<Grid item xs={6}>
												<span
													className="mdDesc patron-type-span"
													id={`patron[${index}]`}
												>
													<span>{v?.patronType?.name}</span>
												</span>
											</Grid>
											<Grid item xs={6}>
												{/* <span className="list-item-patron-diag"> */}
												{/* <span className="dialog-span"> */}
												<Controller
													render={({ onChange, onBlur, value }) => (
														<NumberFormat
															style={{ justifyContent: 'right' }}
															customInput={TextField}
															allowNegative={false}
															fullWidth
															autoComplete="off"
															isNumericString
															decimalScale={2}
															fixedDecimalScale
															thousandSeparator
															variant="outlined"
															margin="dense"
															// color="primary"
															// ref={register}
															className="custom-add"
															onValueChange={(value: any) => {
																const { floatValue } = value;
																onChange(floatValue);
															}}
															defaultValue={patronPrice?.price ?? 0}
														/>
													)}
													className="custom-add"
													variant="outlined"
													control={control}
													ref={register}
													name={v.ID}
													defaultValue={patronPrice?.price ?? 0}
													helperText={errors?.[v?.ID]?.message}
													error={errors?.[v?.ID]}
												/>
												{/* </span> */}
											</Grid>
										</Grid>
									);
								})}
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Delete',
								props: {
									onClick: () => {
										setOpenConfirm(true);
										setOpen(false);
									},
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: () => {
										handleSubmit((data: any) => {
											let menuItemPriceInput = Object.keys(data)?.map(
												(x, index) => {
													return {
														patronID: x,
														price: data[x],
													};
												},
											);

											updateDigitalMenuItemPrice({
												variables: {
													outletID: outletID,
													digitalMenuItemID: menuData?.ID,
													digitalMenuItemPriceInput: menuItemPriceInput,
												},
												refetchQueries: [
													{
														query: GetDigitalMenuCategoryForPriceDocument,
														variables: { ID: menuCategoryID },
													},
												],
											});
										})();
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		);
	};

	return (
		<>
			{menuCategoryLoading && <Loading />}
			{outletLoading && <Loading />}
			{digitalMenuCategoryLoading && <Loading />}
			{getPatronLoading && <Loading />}
			{updateMenuCategoryLoading && <Loading />}
			{updateDigitalMenuItemPriceLoading && <Loading />}
			{deleteDigitalMenuItemAndPriceLoading && <Loading />}
			{OtherDigitalMenuCategoryLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push(
						`/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${digitalMenuID}/edit`,
					)
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Digital Menu & Pricing' },
					{ name: 'Digital Menu Item Price', current: true },
				]}
			/>
			<DynamicSubHeader
				title={menuCategoryData?.getDigitalMenuCategory[0]?.name}
				// style={{
				// 	width:
				// 		isDesktop && globalState.drawerOpen
				// 			? 'calc(100% - 288px)'
				// 			: isDesktop && !globalState.drawerOpen
				// 			? `calc(100% - 48px)`
				// 			: null,
				// }}
				handleClick={e => handleClick(e, digitalMenuID, 0)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						{getOtherDigitalMenuCategory?.getDigitalMenuCategory?.map(
							(x, i) => (
								<MenuItem
									key={i}
									value={x.ID}
									defaultValue={x.ID}
									onClick={e => {
										history.push({
											pathname: `/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${digitalMenuID}/edit/digital-menu-pricing/${x.ID}`,
										});
									}}
								>
									{x.name}
								</MenuItem>
							),
						)}
					</StyledMenu>
				}
			/>
			<ContentWrapper float style={{ marginTop: isDesktop ? '95px' : '28px' }}>
				<CardContents devFullWidth={true}>
					{menuCategoryData && (
						<>
							<Grid container style={{ paddingBottom: '0px' }}>
								<Grid item xs={2}>
									<TextField
										name="seq"
										label={'No'}
										autoComplete="off"
										multiline={true}
										fullWidth
										margin="dense"
										style={{ paddingRight: '10px' }}
										value={
											menuCategoryData?.getDigitalMenuCategory[0]?.priority
										}
										defaultValue={
											menuCategoryData?.getDigitalMenuCategory[0]?.priority
										}
										InputProps={{
											readOnly: true,
										}}
										//   helperText={errors?.docRef?.message}
										//   error={errors?.docRef ? true : false}
									/>
								</Grid>
								<Grid item xs={10} style={{ paddingTop: '7px' }}>
									<FormControl
										style={{ paddingTop: '7px', marginBottom: '0px' }}
									>
										<InputLabel>Menu Category</InputLabel>
										<Input
											value={menuCategoryData?.getDigitalMenuCategory[0]?.name}
											style={{ marginTop: '8px' }}
											endAdornment={
												<InputAdornment position="end">
													<EditIcon
														style={{
															fontSize: '15px',
															color: 'white',
															backgroundColor: '#ff9800',
															borderRadius: '3px',
														}}
														onClick={() => {
															setOpenMenu(true);
														}}
													/>
												</InputAdornment>
											}
										/>
									</FormControl>
								</Grid>
							</Grid>
						</>
					)}
				</CardContents>

				{!menuCategoryLoading &&
				!outletLoading &&
				!digitalMenuCategoryLoading &&
				(getDigitalMenuCategory === undefined ||
					getDigitalMenuCategory[0]?.digitalMenuItem?.filter(
						x => x?.menuItem?.stopSales === null,
					)?.length === 0) ? (
					<EmptyList
						title="No Digital Menu Item Record(s) found"
						subtitle="Add Digital Menu Item(s) to proceed."
					/>
				) : (
					<>
						<MsgAlert
							message={'Select Menu Item, define display sequence and pricing.'}
						/>
						<CardContents>
							<Grid container>
								<Grid item xs={7} className="left">
									<div className="xxTitle" style={{ fontWeight: 'bold' }}>
										Menu Item
									</div>
								</Grid>
								<Grid
									item
									xs={4}
									className="left"
									style={{ textAlign: 'end', paddingRight: '10px' }}
								>
									<div className="xxTitle">Standard Price</div>
								</Grid>
								<Grid item xs={1}></Grid>
							</Grid>

							<Divider style={{ display: 'flex', width: '100%' }} />

							{getDigitalMenuCategory[0]?.digitalMenuItem
								?.filter(x => x?.menuItem?.stopSales === null)
								?.map((v: any, i: number) => (
									<div
										style={{
											float: 'left',
											width: '100%',
											paddingBottom: '10px',
										}}
									>
										<Grid container>
											<Grid item xs={12} className="left"></Grid>
										</Grid>
										<Grid container>
											<Grid
												item
												xs={6}
												className="left"
												style={{ alignSelf: 'center' }}
											>
												<div
													className="xxTitle left"
													style={{
														textDecorationLine: 'underline',
														fontWeight: 'bold',
													}}
												>
													{v.menuItem.name}
												</div>
											</Grid>

											<Grid
												item
												xs={5}
												style={{
													textAlign: 'end',
													alignSelf: 'center',
													paddingRight: '10px',
												}}
											>
												<div
													className="desc"
													style={{ color: '#ff9800', fontWeight: 'bold' }}
												>
													{`${amtStr(
														v.digitalMenuItemPrice.filter(
															x => x.patron.patronClass === 'Visitor',
														)[0]?.price,
													)}`}
												</div>
											</Grid>

											<Grid
												item
												xs={1}
												style={{ textAlign: 'end', alignSelf: 'center' }}
											>
												<div>
													<EditIcon
														style={{
															fontSize: '15px',
															color: 'white',
															backgroundColor: '#ff9800',
															borderRadius: '3px',
														}}
														onClick={() => {
															setMenuData(v);
															setOpen(true);
														}}
													/>
												</div>
											</Grid>
										</Grid>

										<Grid container>
											<Grid item xs={9} className="left">
												<div
													className="desc"
													style={{
														paddingTop: '6px',
														overflowWrap: 'break-word',
														wordWrap: 'break-word',
														whiteSpace: 'pre-wrap',
														hyphens: 'auto',
													}}
												>
													{v.menuItem.description}
												</div>
											</Grid>
										</Grid>

										{spacing_nine()}
										{patron_content(
											v.digitalMenuItemPrice.filter(
												x =>
													x?.patron?.patronClass !== 'Visitor' &&
													x?.patron?.isActive,
											),
										)}
										{spacing_nine()}
									</div>
								))}
						</CardContents>
					</>
				)}

				<CommonDialog
					fullWidth={true}
					open={openMenu}
					onClose={() => setOpenMenu(false)}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title">Edit Menu Category</div>
											{/* <div className="flex session">
												<div className="title flex-space">
													{menuCategoryData?.getDigitalMenuCategory[0]?.name}
												</div>
											</div> */}
										</div>
									</div>
									<div className="infoline-content ">
										<div className="xsTitle infoline">
											{menuCategoryData?.getDigitalMenuCategory[0]?.name}
										</div>
									</div>
								</div>
							),
						},
						body: () => (
							<>
								<CardContents>
									<Controller
										as={TextField}
										name="no"
										label={'Display Sequence'}
										autoComplete="off"
										multiline={true}
										fullWidth
										margin="normal"
										defaultValue={
											menuCategoryData?.getDigitalMenuCategory[0]?.priority
										}
										//   helperText={errors?.docRef?.message}
										//   error={errors?.docRef ? true : false}
										ref={menuReg}
										control={menuCont}
									/>
									<Controller
										as={TextField}
										name="menu"
										label={'Menu Category'}
										autoComplete="off"
										multiline={true}
										fullWidth
										defaultValue={
											menuCategoryData?.getDigitalMenuCategory[0]?.name
										}
										//   helperText={errors?.docRef?.message}
										//   error={errors?.docRef ? true : false}
										ref={menuReg}
										control={menuCont}
									/>
								</CardContents>
							</>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => setOpenMenu(false),
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Save',
									props: {
										onClick: () => {
											menuHandleSubmit(data => {
												updateMenuCategory({
													variables: {
														input: {
															priority: Number(data.no),
															name: data.menu,
															ID: menuCategoryID,
															outletID: outletID,
															digitalMenuID: digitalMenuID,
														},
													},
													refetchQueries: [
														{
															query: GetDigitalMenuCategoryDocument,
															variables: {
																ID: menuCategoryID,
															},
														},
														{
															query: GetDigitalMenuCategoryDocument,
															variables: {
																digitalMenuID: digitalMenuID,
															},
														},
													],
												});
											})();
											setOpenMenu(false);
										},
										variant: 'contained',
										color: 'primary',
										//disabled: isExceed,
									},
								},
							],
						},
					}}
				/>

				{edit_pricing_dialog()}
				{/* {confirm_deletion()} */}
				<GeneralDeleteDialog
					ID={menu?.ID}
					outletID
					openDialog={openConfirm}
					setOpenDialog={setOpenConfirm}
					dialogName={`${menuData?.menuItem?.name} Pricing`}
					deleteID={{ digitalMenuItemID: menuData?.ID }}
					deleteMutation={deleteDigitalMenuItemAndPrice}
					// refetchDocument={GetDigitalMenuCategoryForPriceDocument}
					// refetchID={{ ID: menuCategoryID }}
					refetchQueries={[
						{
							query: GetDigitalMenuCategoryForPriceDocument,
							variables: { ID: menuCategoryID },
						},
						{
							query: GetRemainingMenuItemsForDigitalDocument,
							variables: {
								digitalMenuCategoryID: menuCategoryID,
								outletID: outletID,
							},
						},
					]}
				/>

				<FloatButton
					onClick={() => {
						history.push({
							pathname: `/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${digitalMenuID}/edit/digital-menu-pricing/${menuCategoryID}/add`,
							state: menuCategoryData?.getDigitalMenuCategory[0]?.name,
						});
					}}
				/>
			</ContentWrapper>
		</>
	);
};
