export const currencyCodeSymbols = {
	AED: 'AED',
	AFN: '؋',
	ALL: 'Lek',
	AMD: 'Դ',
	ANG: 'ƒ',
	AOA: 'is',
	ARS: '$',
	AUD: '$',
	AWG: 'ƒ',
	AZN: '₼',
	BAM: 'КМ',
	BBD: '$',
	BDT: '৳',
	BES: '$',
	BGN: 'лв',
	BHD: 'ب.د',
	BIF: '₣',
	BMD: '$',
	BND: '$',
	BOB: '$b',
	BOV: 'Mvdol',
	BRL: 'R$',
	BSD: '$',
	BTN: 'BTN',
	BWP: 'P',
	BYN: 'Br',
	BZD: 'BZ$',
	CAD: '$',
	CDF: '₣',
	CHF: 'CHF',
	CLP: '$',
	CNY: '¥',
	COP: '$',
	CRC: '₡',
	CUP: '₱',
	CVE: 'CVE',
	CZK: 'Kč',
	DJF: '₣',
	DKK: 'kr',
	DOP: 'RD$',
	DZD: 'د.ج',
	EGP: '£',
	ERN: 'Nfk',
	ETB: 'ETB',
	EUR: '€',
	FJD: '$',
	FKP: '£',
	GBP: '£',
	GEL: 'ლ',
	GGP: '£',
	GHS: '₵',
	GIP: '£',
	GMD: 'D',
	GNF: '₣',
	GTQ: 'Q',
	GYD: '$',
	HKD: '$',
	HNL: 'L',
	HRK: 'kn',
	HTG: 'G',
	HUF: 'Ft',
	IDR: 'Rp',
	ILS: '₪',
	IMP: '£',
	INR: 'INR',
	IQD: 'ع.د',
	IRR: '﷼',
	ISK: 'kr',
	JEP: '£',
	JMD: 'J$',
	JOD: 'د.ا',
	JPY: '¥',
	KES: 'Sh',
	KGS: 'лв',
	KHR: '៛',
	KMF: 'KMF',
	KPW: '₩',
	KRW: '₩',
	KWD: 'د.ك',
	KYD: '$',
	KZT: 'лв',
	LAK: '₭',
	LBP: '£',
	LKR: 'Rs',
	LRD: '$',
	LSL: 'L',
	LYD: 'ل.د',
	MAD: 'د.م.',
	MDL: 'L',
	MGA: 'MK',
	MKD: 'ден',
	MMK: 'Myanmar is K',
	MNT: '₮',
	MOP: 'P',
	MRU: 'UM',
	MUR: '₨',
	MVR: 'ރ',
	MWK: 'MWK',
	MXN: '$',
	MXV: 'MXV',
	MYR: 'RM',
	MZN: 'MT',
	NAD: '$',
	NGN: '₦',
	NIO: 'C$',
	NOK: 'kr',
	NPR: '₨',
	NZD: '$',
	OMR: '﷼',
	PAB: 'B/.',
	PEN: 'S/.',
	PGK: 'K',
	PHP: '₱',
	PKR: '₨',
	PLN: 'zł',
	PYG: 'Gs',
	QAR: '﷼',
	RON: 'lei',
	RSD: 'Дин.',
	RUB: '₽',
	RWF: '₣',
	SAR: '﷼',
	SBD: '$',
	SCR: 'Rs',
	SDG: '£',
	SEK: 'kr',
	SGD: '$',
	SHP: '£',
	SLL: 'Le',
	SOS: 'S',
	SRD: '$',
	SSP: 'SSP',
	STN: 'Db',
	SVC: `$`,
	SYP: '£',
	SZL: 'L',
	THB: '฿',
	TJS: 'ЅМ',
	TMT: 'm',
	TND: 'د.ت',
	TOP: 'T$',
	TRY: 'TRY',
	TTD: '$',
	TVD: '$',
	TWD: 'NT$',
	TZS: 'Sh',
	UAH: '₴',
	UGX: 'Sh',
	USD: '$',
	UYU: '$U',
	UZS: 'лв',
	VEF: 'Bs',
	VND: '₫',
	VUV: 'Vt',
	WST: '$',
	XAF: '₣',
	XCD: '$',
	XOF: 'XOF',
	XPF: '₣',
	YER: '﷼',
	ZAR: 'R',
	ZMW: 'ZK',
	ZWL: '$',
	ZWD: 'Z$',
};
