import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import React from 'react';
import { useHistory } from 'react-router';

interface DialogProps {
	openExitDialog: boolean;
	setOpenExitDialog: any;
	backPath: string;
}

export const ExitConfirmationDialog = (props: DialogProps) => {
	const { openExitDialog, setOpenExitDialog, backPath } = props;

	let history = useHistory();

	return (
		<>
			<CommonDialogV2
				fullWidth={true}
				open={openExitDialog}
				onClose={() => setOpenExitDialog(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Exit Confirmation</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<CardContents>
								<span className="mdDesc" style={{ whiteSpace: 'initial' }}>
									Are you sure you want to exit? Your changes will not be saved.
								</span>
							</CardContents>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => setOpenExitDialog(false),
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push(`${backPath}`);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
