import React from 'react';
import roleAssignmentRoutes from '../GeneralSettings/UserAccess/RoleAssignment/RoleAssignmentRoutes';
import closeDayRoutes from './CloseDay/CloseDayRoutes';
import dailyMenuItemAvailabilityRoutes from './DailyMenuItemAvailability/DailyMenuItemAvailabilityRoutes';
import digitalMenuPricingRoutes from './DigitalMenuAndPricing/DigitalMenuPricingRoutes';
import discountTypeRoutes from './DiscountType/DiscountTypeRoutes';
import interfaceSetupRoutes from './InterfaceSetup/InterfaceSetupRoutes';
import kitchenPrinterRoutes from './KitchenPrinter/KitchenPrinterRoutes';
import majorFamilyRoutes from './MajorFamily/MajorFamilyRoutes';
import mealPeriodRoutes from './MealPeriod/MealPeriodRoutes';
import menuItemRoutes from './MenuItem/MenuItemRoutes';
import menuSchedulingRoutes from './MenuScheduling/MenuSchedulingRoutes';
import { OutletList } from './OutletList';
import outletPolicyRoutes from './OutletPolicy/OutletPolicyRoutes';
import outletProfileRoutes from './OutletProfile/OutletProfileRoutes';
import { OutletSettingSubmenu } from './OutletSettingSubmenu';
import paymentTypeRoutes from './PaymentType/PaymentTypeRoutes';
import promotionalMenuPricingRoutes from './PromotionalMenuPricing/PromotionalMenuPricingRoutes';
import promoCodeRoutes from './PromotionCode/PromotionCodeRoutes';
import qrCodeRoutes from './QRCode/QRCodeRoutes';
import receiptPrinterRoutes from './ReceiptPrinter/ReceiptPrinterRoutes';
import setMenuRoutes from './SetMenu/SetMenuRoutes';
import standardMenuOptionRoutes from './StandardMenuOption/StandardMenuOptionRoutes';
import stopSalesItemRoutes from './StopSalesItem/StopSalesItemRoutes';
import tableRoutes from './Table/TableRoutes';

const outletSettingsRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting`,
		},
		component: <OutletList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID`,
		},
		component: <OutletSettingSubmenu />,
	},
	...outletProfileRoutes,
	...outletPolicyRoutes,
	...tableRoutes,
	...qrCodeRoutes,
	...kitchenPrinterRoutes,
	...receiptPrinterRoutes,
	...mealPeriodRoutes,
	...majorFamilyRoutes,
	...standardMenuOptionRoutes,
	...menuItemRoutes,
	...setMenuRoutes,
	...digitalMenuPricingRoutes,
	...menuSchedulingRoutes,
	...promotionalMenuPricingRoutes,
	...dailyMenuItemAvailabilityRoutes,
	...promoCodeRoutes,
	...discountTypeRoutes,
	...stopSalesItemRoutes,
	...closeDayRoutes,
	...roleAssignmentRoutes,
	...paymentTypeRoutes,
	...interfaceSetupRoutes,
];

export default outletSettingsRoutes;
