import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetUomDocument,
	PosPermission,
	useCreateUomMutation,
	useDeleteUomMutation,
	useGetUomQuery,
	useUpdateUomMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router';
import * as yup from 'yup';

const defaultVal: any = {
	name: '',
	code: '',
};
export interface IUOMForm {
	name: string;
	code: string;
}

export const UOMList = (props: any) => {
	let history = useHistory();
	let location = useLocation();
	const editData: any = location?.state;
	const { mode } = props;
	const { mode: pageMode }: any = useParams();

	const outletID = localStorage.getItem('latestOutlet');

	const UOMSchema = yup.object().shape({
		code: CommonYupValidation.requireField(SystemMsgs.code()).trim(),
		name: CommonYupValidation.requireField(SystemMsgs.name()).trim(),
	});

	const { handlePermDisabled } = usePermissionChecker();

	const [simple, setSimple] = useState(false);
	const handleClickSimpleDialog = () => {
		setSimple(true);
	};

	const [isSaveNew, setSaveNew] = useState(true);
	const [double, setDouble] = useState(false);
	const [editForm, setEditForm] = useState(false);
	const [deleteForm, setDeleteForm] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);
	// FOR DUPLICATE DIALOG
	const [errorDia, setErrorDia] = useState<boolean>(false);
	const [errMsg, setErrMsg] = useState<string>('');

	//CREATE
	const [
		createUOM,
		{ loading: mutationLoading, error: mutationError },
	] = useCreateUomMutation({
		onError: error => {
			console.log('ERROR', error);
			// FOR DUPLICATE DIALOG AFTER MUTATION
			setErrMsg(error.message.substr(15, error.message.length - 1));
			setErrorDia(true);
		},
		onCompleted: data => {
			if (!isSaveNew) {
				setSimple(false);
				setSaveNew(true);
			}
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	//READ
	const {
		data: { getUOM } = {
			getUOM: [],
			uomDate: null,
		},
		loading,
		error,
	} = useGetUomQuery({
		fetchPolicy: 'network-only',
		variables: {
			orderByAsc: 'name',
		},
	});

	//UPDATE
	const [
		updateUOM,
		{ loading: mutationUpdateLoading, error: mutationUpdateError },
	] = useUpdateUomMutation({
		onError: error => {
			console.log('ERROR', error);
			setErrMsg(error.message.substr(15, error.message.length - 1));
			setErrorDia(true);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setSimple(false);
		},
	});

	//DELETE
	const [
		deleteUOM,
		{ loading: mutationDeleteLoading, error: mutationDeleteError },
	] = useDeleteUomMutation({
		onError: error => {
			// if (error.message.includes('FK_')) {
			// 	cancelUOM({
			// 		variables: {
			// 			input: {
			// 				ID: menu?.obj?.ID,
			// 			},
			// 		},
			// 		refetchQueries: [
			// 			{
			// 				query: GetUomDocument,
			// 				variables: {
			// 					orderByAsc: 'name',
			// 				},
			// 			},
			// 		],
			// 	});
			// } else {
			// 	alert('You are not allowed to delete this!');
			// }
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecordInUse());
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
			setDeleteForm(false);
		},
	});

	const existingUOMNames = getUOM?.filter(u => u.name)?.map(e => e.name);
	const existingUOMCodes = getUOM?.filter(u => u.code)?.map(e => e.code);
	const yupSchema = yup.object().shape({
		name: yup
			.string()
			.required(SystemMsgs.name())
			.trim()
			.test('name', 'UOM name already exist', value => {
				if (
					(watch('name') !== menu?.obj?.name && editForm === true) ||
					editForm === false
				) {
					return !existingUOMNames?.includes(value);
				}
				return true;
			}),
		code: yup
			.string()
			.required(SystemMsgs.name())
			.trim()
			.test('code', 'UOM code already exist', value => {
				if (
					(watch('code') !== menu?.obj?.code && editForm === true) ||
					editForm === false
				) {
					return !existingUOMCodes?.includes(value);
				}
				return true;
			}),
	});

	const {
		handleSubmit,
		register,
		errors,
		control,
		watch,
		reset,
		formState: { isDirty },
	} = useForm<IUOMForm>({
		defaultValues: {
			name: mode === 'add' ? '' : editData?.name,
		},
		mode: 'all',
		resolver: yupResolver(yupSchema),
	});

	const onCreate = data => {
		createUOM({
			variables: {
				input: {
					name: data.name,
					code: data.code,
				},
			},
			refetchQueries: [
				{
					query: GetUomDocument,
					variables: {
						orderByAsc: 'name',
					},
				},
			],
		});
		if (isSaveNew) {
			reset(defaultVal);
		}
		setMsg(true);
	};

	const onUpdate = data => {
		updateUOM({
			variables: {
				input: {
					ID: menu?.obj?.ID,
					name: data.name,
					code: data.code,
				},
			},
			refetchQueries: [
				{
					query: GetUomDocument,
					variables: {
						orderByAsc: 'name',
					},
				},
			],
		});
		setMsg(true);
	};

	const onDelete = () => {
		deleteUOM({
			variables: {
				ID: menu?.obj?.ID,
			},
			refetchQueries: [
				{
					query: GetUomDocument,
					variables: {
						orderByAsc: 'name',
					},
				},
			],
		});
		setDeleteForm(false);
		setMsg(true);
	};

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const array: any[] = getUOM;

	//search part -----------------------------------------
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	useEffect(() => {
		if (array && array?.length > 0) {
			setOriginalListing(array);
		}
	}, [array]);
	//search part -----------------------------------------

	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const [msg, setMsg] = useState<boolean>(false);

	return (
		<>
			{loading && <Loading />}
			{mutationLoading && <Loading />}
			{mutationUpdateLoading && <Loading />}
			<MainHeader
				mainBtn="back"
				onClick={() => {
					history.push(`/menu/outlet-app/general-setting`);
				}}
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: 'System Admin' },
					{ name: 'UOM', current: true },
				]}
			/>

			<SearchHeader
				title="UOM Listing"
				// total={data?.getUser?.length || 0}
				search
				fixed
				onChangeAction={e => {
					handleSearch(e?.target?.value, ['code', 'name']);
				}}
				onCloseAction={() => handleSearch('', [])}
			/>

			<ContentWrapper footer float search>
				<List className="core-list">
					{!loading && (getUOM === undefined || getUOM?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Add a new record now."
						/>
					) : (
						filteredList?.map((el, index) => (
							<ListItem key={index}>
								<ListItemText
									primary={
										<>
											<span className="xsTitle date-width color-primary-orange">
												{el?.code}
											</span>
											<span className="xsTitle ">{el?.name}</span>
										</>
									}
									// secondary={
									// 	<span className="desc">
									// 		<span className="">{el.name} </span>
									// 	</span>
									// }
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="menu"
										aria-controls="menu-list"
										aria-haspopup="true"
										onClick={e => handleClick(e, menu.ID, index, el)}
									>
										<MoreVert />
									</IconButton>
								</ListItemSecondaryAction>
								{/* )} */}
							</ListItem>
						))
					)}
				</List>

				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<>
						<MenuItem
							disabled={handlePermDisabled({
								outletID: outletID,
								permEnum: PosPermission.GeneralSettingUomUpdate,
							})}
							onClick={() => {
								setEditForm(true);
								reset(menu?.obj?.name);
							}}
						>
							<span className="">Edit</span>
						</MenuItem>
						<MenuItem
							disabled={handlePermDisabled({
								outletID: outletID,
								permEnum: PosPermission.GeneralSettingUomDelete,
							})}
							onClick={() => setDeleteForm(true)}
						>
							<span className="">Delete</span>
						</MenuItem>
					</>
				</Menu>
			</ContentWrapper>
			<FloatButton
				disabled={handlePermDisabled({
					outletID: outletID,
					permEnum: PosPermission.GeneralSettingUomCreate,
				})}
				onClick={() => setSimple(true)}
			/>

			<form onSubmit={handleSubmit(onCreate)} id="createUOM-form">
				<Box mt={1}>
					<CommonDialogV2
						fullWidth={true}
						open={simple}
						onClose={() => setSimple(false)}
						sections={{
							header: {
								title: 'New UOM Type',
							},
							body: () => (
								<div className="content-container">
									{/* <Controller
                    as={TextField} */}
									<TextField
										label="Code*"
										name="code"
										autoFocus
										inputRef={register({})}
										// control={control}
										helperText={errors?.code?.message}
										error={errors?.code ? true : false}
										autoComplete="off"
									/>
									{/* <Controller
                    as={TextField} */}
									<TextField
										label="Name*"
										name="name"
										inputRef={register({})}
										// control={control}
										helperText={errors?.name?.message}
										error={errors?.name ? true : false}
										autoComplete="off"
									/>
								</div>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => setSimple(false),
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Save',
										props: {
											onClick: () => {
												handleSubmit(onCreate)();
												setSaveNew(false);
												setDouble(false);
											},
											disabled: double,
											variant: 'contained',
											color: 'primary',
											// form: 'createUOM-form',
											type: 'submit',
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			<form onSubmit={handleSubmit(onUpdate)} id="updateUOM-form">
				<Box mt={1}>
					<CommonDialogV2
						fullWidth={true}
						open={editForm}
						onClose={() => setEditForm(false)}
						sections={{
							header: {
								title: 'Edit UOM Type',
							},
							body: () => (
								<div className="content-container">
									{/* <Controller
                    as={TextField} */}
									<TextField
										label="Code*"
										name="code"
										autoFocus
										inputRef={register({})}
										defaultValue={menu?.obj?.code}
										// control={control}
										helperText={errors?.code?.message}
										error={errors?.code ? true : false}
										autoComplete="off"
									/>
									{/* <Controller
                    as={TextField} */}
									<TextField
										label="Name*"
										name="name"
										inputRef={register({})}
										defaultValue={menu?.obj?.name}
										// control={control}
										helperText={errors?.name?.message}
										error={errors?.name ? true : false}
										autoComplete="off"
									/>
								</div>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => setEditForm(false),
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Save',
										props: {
											onClick: () => setEditForm(false),
											variant: 'contained',
											color: 'primary',
											form: 'updateUOM-form',
											type: 'submit',
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			<Box mt={1}>
				<CommonDialogV2
					fullWidth={true}
					open={deleteForm}
					onClose={() => setDeleteForm(false)}
					sections={{
						header: {
							title: 'Delete UOM',
						},

						body: () => (
							<>
								<div className="content-wrap full">
									<div className="desc">Code</div>
									<div className="xsTitle">{menu?.obj?.code}</div>
								</div>
								<div className="content-wrap full">
									<div className="desc">Name</div>
									<div className="xsTitle">{menu?.obj?.name}</div>
								</div>
							</>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => setDeleteForm(false),
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										onClick: () => {
											onDelete();
											setDeleteForm(false);
										},

										variant: 'contained',
										color: 'primary',
										form: 'deleteUOM-form',
										type: 'submit',
									},
								},
							],
						},
					}}
				/>
			</Box>
		</>
	);
};
