import React from 'react';
import { AppointedOfficerDetail } from './AppointedOfficerDetail';
import { AppointedOfficerForm } from './AppointedOfficerForm';
import { AppointedOfficerList } from './AppointedOfficerList';

const appointedOfficerRoutes = [
	{
		props: { exact: true, path: `/menu/outlet-app/general-setting/officer` },
		component: <AppointedOfficerList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/officer/add`,
		},
		component: <AppointedOfficerForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/officer/:officerID/edit`,
		},
		component: <AppointedOfficerForm mode="edit" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/officer/:officerID/detail`,
		},
		component: <AppointedOfficerDetail />,
	},
];

export default appointedOfficerRoutes;
