import React from 'react';
import { AccountsMappingListing } from './AccountMappingListing';
import { BankAccountDetail } from './BankAccount/BankAccountDetail';
import { BankAccountForm } from './BankAccount/BankAccountForm';
import { BankAccountList } from './BankAccount/BankAccountList';
import { OutletGLInterface } from './OutletGLInterface/OutletGLInterface';
import { TaxMapping } from './TaxMapping/TaxMapping';

const accountMappingRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/account-mapping`,
		},
		component: <AccountsMappingListing />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/account-mapping/bank-account`,
		},
		component: <BankAccountList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/account-mapping/outlet-gl-interface`,
		},
		component: <OutletGLInterface />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/account-mapping/tax`,
		},
		component: <TaxMapping />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/account-mapping/bank-account/:bankAccountID/detail`,
		},
		component: <BankAccountDetail />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/account-mapping/bank-account/add`,
		},
		component: <BankAccountForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/account-journals/account-mapping/bank-account/:bankAccountID/edit`,
		},
		component: <BankAccountForm mode="edit" />,
	},
];

export default accountMappingRoutes;
