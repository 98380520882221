import { yupResolver } from '@hookform/resolvers';
import DefaultAvatar from '@ifca-root/react-component/src/assets/images/default-avatar.png';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { Fab, TextField } from '@material-ui/core';
import { Publish } from '@material-ui/icons';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import {
	GetUserLogoDocument,
	LoggedInUserProfileDocument,
	useGetUserLogoQuery,
	useLoggedInUserProfileQuery,
	useUpdateUserMutation,
	useUploadProfilePicMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import { POSClient } from 'POSClient';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import * as yup from 'yup';

interface ProfileFormProps {
	name: string;
	department: string;
	designation: string;
	email: string;
	contactNo: string;
	company: string;
}

export const EditProfile = props => {
	let history = useHistory();
	let location = useLocation();
	let editData: any = location.state;
	const userData = editData?.userData;
	const prevFile = editData?.previewFile;

	let user = JSON.parse(localStorage.getItem('loggedInUser'));
	const [selectedFile, setSelectedFile] = useState('');
	const [previewFile, setPreview] = useState(prevFile);
	const [open, setOpen] = useState(false);

	const userSchema = yup.object().shape({
		name: CommonYupValidation.requireField(SystemMsgs.name()),
		department: CommonYupValidation.requireField(SystemMsgs.department()),
		designation: CommonYupValidation.requireField(SystemMsgs.designation()),
		email: CommonYupValidation.requireField(SystemMsgs.email()),
		contactNo: CommonYupValidation.requireField(SystemMsgs.contactNo()),
	});

	const { handleSubmit, register, errors, control } = useForm<ProfileFormProps>(
		{
			defaultValues: {
				name: userData?.name,
				email: userData?.email,
				contactNo: userData?.contactNo,
				company: userData?.companyName,
				department: userData?.department,
				designation: userData?.jobTitle,
			},
			mode: 'onSubmit',
			resolver: yupResolver(userSchema),
		},
	);

	const [
		uploadPic,
		{ loading: upPicLoad, error: upPicError },
	] = useUploadProfilePicMutation({
		onError: () => {
			alert('error uploading');
		},
		onCompleted: data => {},
		fetchPolicy: 'network-only',
	});

	const [updateUser, { loading: updateUserLoading }] = useUpdateUserMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			if (selectedFile) {
				uploadPic({
					variables: { userID: editData?.userData?.ID, file: selectedFile },
					refetchQueries: [
						{
							query: GetUserLogoDocument,
							variables: {
								refID: editData?.userData?.ID,
							},
						},
						{ query: LoggedInUserProfileDocument },
					],
				});
			}
			delete location.state;
			history.goBack();
			// history.push(`/profile`);
		},
		fetchPolicy: 'network-only',
	});

	const fileChangedHandler = event => {
		if (event.target.files[0].type.includes('image')) {
			setSelectedFile(event.target.files[0]);
			let reader = new FileReader();

			reader.onloadend = () => {
				setPreview(reader.result);
			};
			reader.readAsDataURL(event.target.files[0]);
		} else {
			setOpen(true);
		}
	};

	const onSubmit = data => {
		updateUser({
			variables: {
				input: {
					ID: editData?.userData?.ID,
					name: data?.name,
					email: editData?.userData?.email,
					contactNo: data?.contactNo,
					department: data?.department,
					jobTitle: data?.designation,
				},
			},
			refetchQueries: [
				{
					query: GetUserLogoDocument && LoggedInUserProfileDocument,
				},
			],
		});

		user['department'] = data?.department;
		user['name'] = data?.name;
		user['contactNo'] = data?.contactNo;
		user['jobTitle'] = data?.jobTitle;
		localStorage.setItem('loggedInUser', JSON.stringify(user));
	};

	const {
		loading,
		error,
		data: { loggedInUserProfile } = { loggedInUserProfile: null },
	} = useLoggedInUserProfileQuery({
		client: POSClient as any,
	});

	const { data: userLogo } = useGetUserLogoQuery({
		variables: {
			refID: loggedInUserProfile?.ID,
		},
		onCompleted: data => {
			setPreview(data?.logoView?.fileURL);
		},
	});

	const [openExitDialog, setOpenExitDialog] = useState(false);

	return (
		<>
			<MainHeader
				mainBtn="close"
				onClick={() => setOpenExitDialog(true)}
				smTitle={user?.companyName}
				title="Profile"
				routeSegments={[
					{ name: 'Main' },
					{ name: 'Profile' },
					{ name: 'Edit ', current: true },
				]}
			/>

			<ContentWrapper float onlyContentScrollable>
				<CardContents devFullWidth={true}>
					{previewFile ? (
						<img src={previewFile} alt="" className="upload-user-avatar" />
					) : (
						<img src={DefaultAvatar} alt="" className="upload-user-avatar" />
					)}
					<Fab
						color="primary"
						size="small"
						aria-label="add"
						className="float-img-upload "
					>
						<label htmlFor="icon-button-file"></label>
						<Publish />
					</Fab>

					<input
						type="file"
						hidden
						id="icon-button-file"
						onChange={fileChangedHandler}
					/>

					{/* <form id="submit-form" onSubmit={handleSubmit(test)}> */}
					<Controller
						as={TextField}
						name="name"
						fullWidth
						margin="normal"
						label="Name"
						required
						helperText={errors?.name?.message}
						error={errors?.name ? true : false}
						control={control}
						ref={register}
					/>
					<Controller
						as={TextField}
						name="email"
						label="Email"
						margin="normal"
						fullWidth
						helperText={errors?.email?.message}
						error={errors?.email ? true : false}
						control={control}
						ref={register}
						disabled={true}
						defaultValue={editData.userData.email}
					/>
					<div style={{ margin: '10px' }}></div>
					<Controller
						as={
							<PhoneNumInput
								country={'my'}
								specialLabel="Mobile No."
								fullWidth
							/>
						}
						// fullWidth
						name="contactNo"
						margin="normal"
						marginTop
						marginBottom
						// label="Mobile No."
						control={control}
						ref={register}
						// required
						helperText={errors?.contactNo?.message}
						error={errors?.contactNo ? true : false}
					/>
					<div style={{ margin: '10px' }}></div>
					{/* <Controller
						className={'left'}
						as={TextField}
						fullWidth
						name="company"
						margin="normal"
						label="Company"
						control={control}
						ref={register}
						disabled={true}
					/> */}
					<Controller
						// className={'right'}
						as={TextField}
						margin="normal"
						name="department"
						label="Department"
						fullWidth
						required
						helperText={errors?.department?.message}
						error={errors?.department ? true : false}
						control={control}
						ref={register}
					/>
					<Controller
						as={TextField}
						margin="normal"
						name="designation"
						label="Job Title"
						fullWidth
						required
						helperText={errors?.designation?.message}
						error={errors?.designation ? true : false}
						control={control}
						ref={register}
					/>
					{/* </form> */}
				</CardContents>
			</ContentWrapper>

			<DynamicFooter
				options={[
					{
						name: 'Save',
						onClick: () => {
							handleSubmit(onSubmit)();
						},
						color: 'primary',
						props: {
							type: 'submit',
						},
					},
				]}
			/>

			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/profile`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push(`/profile`);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
