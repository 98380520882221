import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { Grid } from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
// import {
// 	useCloseDayMutation,
// 	useGetOutletQuery,
// } from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import React, { useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export const CloseDayForm = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();

	//

	const labelOffset = -6;

	const { [`outletID`]: outletID } = useParams<Record<string, any>>();
	const { register, control, handleSubmit } = useForm<any>({});

	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS
	//QUERIES AND MUTATIONS

	const [busDate, setBusDate] = useState(null);

	// const {
	// 	called: outletCalled,
	// 	loading: outletLoading,
	// 	error: outletError,
	// 	data: { getOutlet } = { getOutlet: [] },
	// } = useGetOutletQuery({
	// 	fetchPolicy: 'network-only',
	// 	variables: { ID: outletID },
	// 	onCompleted: (data) => {
	// 		setBusDate(outletArray[0]?.businessDate);
	// 	},
	// });
	// const outletArray: any[] = getOutlet;

	let currDate = new Date(busDate);
	let tmr = new Date(currDate);
	tmr.setDate(currDate.getDate() + 1);

	// const [closeDay, { data: closedDay }] = useCloseDayMutation({
	// 	onError: (error) => {
	// 		console.log('ERROR', error);
	// 	},
	// 	onCompleted: (data) => {

	// 		setTimeout(() => {
	// 			history.push({
	// 				pathname: `/menu/outlet-app/outlet-setting/${outletID}/close-day`,
	// 				state: { success: true, msgMode: 'create' },
	// 			});
	// 		}, 500);
	// 	},
	// });

	// const onSubmit = (data: any) => {
	// 	closeDay({
	// 		variables: {
	// 			outletID: outletID,
	// 		},
	// 	});
	// };

	const [openExitDialog, setOpenExitDialog] = useState(false);

	return (
		<>
			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="back"
				smTitle={`Outlet App`}
				// title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Close Day', current: true },
				]}
			/>
			<ContentWrapper
			// style={{ marginTop: '0' }} float
			>
				<CardContents>
					<Grid container justify="flex-start">
						<div className="content-wrap full">
							<div className="mdDesc">Current Business Date</div>
							<div className="smTitle">
								{formatDate(currDate.toISOString())}
							</div>
						</div>
						<div className="content-wrap full">
							<div className="mdDesc">Next Business Date</div>
							<div className="smTitle">{formatDate(tmr.toISOString())}</div>
						</div>
					</Grid>
				</CardContents>
				<DynamicFooter
					options={[
						{
							name: 'Close Day',
							// onClick: () => handleSubmit(onSubmit)(),
							// onClick: () => {
							//   console.log('onClick', noError);

							//   if (noError) {
							//     handleCLick();
							//   }
							// },
							color: 'primary',
						},
					]}
				/>
				<ExitConfirmationDialog
					openExitDialog={openExitDialog}
					setOpenExitDialog={setOpenExitDialog}
					backPath={`/menu/outlet-app/outlet-setting/${outletID}`}
				/>
				{/* <CommonDialog
					fullWidth={true}
					open={openExitDialog}
					onClose={() => {
						setOpenExitDialog(false);
					}}
					sections={{
						header: {
							dynamic: (
								<div>
									<div className="dialogo-dynamic-content">
										<span
											className="title c-orange flex-space"
											style={{
												fontSize: '13px',
												fontWeight: 'bold',
											}}
										>
											Exit Confirmation
										</span>
									</div>
								</div>
							),
						},
						body: () => (
							<CardContents>
								<span className="">
									Are you sure you want to exit? Your changes will not be saved.
								</span>
							</CardContents>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
										},
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										onClick: () => {
											setOpenExitDialog(false);
											history.push({
												pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
											});
										},
										variant: 'contained',
										color: 'primary',
									},
								},
							],
						},
					}}
				/> */}
			</ContentWrapper>
		</>
	);
};
