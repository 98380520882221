import { useQuery, useLazyQuery } from '@apollo/react-hooks';
import {
	CITY_BY_STATE,
	COUNTRY,
	POSTCODE_BY_CITY,
	STATE_BY_COUNTRY,
} from '../../containers/AccountModule/AccountQuery';
import { AccountClient } from '../../ProductsClient';

// import {
//   useGetCitybyStateLazyQuery,
//   useGetCountryQuery,
//   useGetPostcodebyCityLazyQuery,
//   useGetStatebyCountryLazyQuery,
// } from 'generated/graphql';

export const useCustomCountryQuery = () => {
	const { data: { getCountry } = { getCountry: [] } } = useQuery(COUNTRY, {
		client: AccountClient as any,
		variables: {
			orderByAsc: 'name',
		},
	});
	const [
		handleGetStateByCountry,
		{ data: { getStatebyCountry } = { getStatebyCountry: [] } },
	] = useLazyQuery(STATE_BY_COUNTRY, {
		client: AccountClient as any,
	});
	const [
		handleGetCityByState,
		{ data: { getCitybyState } = { getCitybyState: [] } },
	] = useLazyQuery(CITY_BY_STATE, {
		client: AccountClient as any,
	});
	const [
		handleGetPostcodeByCity,
		{ data: { getPostcodebyCity } = { getPostcodebyCity: [] } },
	] = useLazyQuery(POSTCODE_BY_CITY, {
		client: AccountClient as any,
	});

	return {
		getCountry, // country
		getStatebyCountry, // State
		getCitybyState, // City
		getPostcodebyCity, // Postcode
		handleGetStateByCountry, // variables : CountryID
		handleGetCityByState, // variables : StateID
		handleGetPostcodeByCity, // variables : StateID
	};
};
