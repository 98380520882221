import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { List } from '@material-ui/core';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';

export const AuthorisedStaffDetail = () => {
	let history = useHistory();
	let location = useLocation();
	let staffDetailData: any = location.state;
	const { staffID, mode: pageMode }: any = useParams();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	// const {
	// 	loading,
	// 	error,
	// 	// data: { getOfficer: {name, designation, mobileNo}} = { getOfficer: {name: '', designation: '', mobileNo: '', email: ''} },
	// 	data: { getOfficer } = { getOfficer: [] },
	// } = useGetOfficerQuery({
	// 	variables: {
	// 		ID: officerID,
	// 	},
	// });

	return (
		<>
			{/* {<Loading />} */}
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/general-setting/employee`,
					})
				}
				mainBtn="back"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Settings' },
					{ name: 'Appointed Staff', current: true },
				]}
				rightRouteSegments={[
					{
						name: 'Details',
						current: true,
					},
				]}
			/>
			<List className="core-list subheader single">
				{}

				<ContentWrapper>
					<CardContents>
						<div className="content-wrap left">
							<div className="xxTitle">Full Name</div>
							{/* <div className="xsTitle">{getOfficer[0]?.name}</div> */}
							<div className="xsTittle"> {staffDetailData?.name}</div>
						</div>
						<div className="content-wrap right">
							<div style={{ display: 'flex' }}>
								{/* {getOfficer[0]?.isActive && <div className="blue-status"></div>} */}
								{staffDetailData?.isActive ? (
									<div className="blue-status">Active</div>
								) : (
									<div className="grey-status">Inactive</div>
								)}
							</div>
						</div>
						<div className="content-wrap full">
							<div className="xxTitle">Designation</div>
							{/* <div className="xsTitle">{getOfficer[0]?.designation}</div> */}
							<div className="mdDesc">{staffDetailData?.designation}</div>
						</div>

						<div className="content-wrap full">
							<div className="xxTitle">Mobile No.</div>
							{/* <div className="xsTitle">{getOfficer[0]?.mobileNo}</div> */}
							<div className="mdDesc">{staffDetailData?.mobileNo}</div>
						</div>
						<div className="content-wrap full">
							<div className="xxTitle">Email</div>
							{/* <div className="xsTitle">{getOfficer[0]?.email}</div> */}
							<div className="mdDesc"> {staffDetailData?.email}</div>
						</div>
						<div className="content-wrap-full"></div>
					</CardContents>
				</ContentWrapper>
			</List>
		</>
	);
};
