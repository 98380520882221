import React, { useContext, useEffect, useState } from 'react';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
	OrderStatus,
	useAuditLogDetailsQuery,
	useGetOrderQuery,
	useGetOutletNameQuery,
} from 'generated/graphql';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { OrderSubheader } from 'components/Subheader/OrderSubheader';
import AuditLogListing from './AuditLogListing';
import './auditLog.scss';
import { MutliLineSubheader } from 'components/Layout/MultiLineSubHeader';
import { Typography, useMediaQuery } from '@material-ui/core';
import theme from '@ifca-root/react-component/src/assets/theme';
import AppContext from 'containers/App/Store/AppContext';
import { formatTime } from 'helpers/formatDate';
import TimeBlackIcon from 'assets/icons/time_black.svg';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';

export const AuditLog = (props: any) => {
	const { mode, module } = props;
	const history = useHistory();
	const location = useLocation();
	const localInfo: any = location.state;
	const { outletID, tableID } = useParams<Record<string, any>>();
	const [selectedOrder, setSelectedOrder] = useState(null);
	const [limit, setLimit] = useState(20);
	const { globalState, dispatch }: any = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const optionArray = [
		{
			displayValue: 'Order Info',
			tableName: 'p_order',
			fieldID: localInfo?.orderID,
			subDisplayValue: '',
		},
		{
			displayValue: 'Bill Info',
			tableName: 'p_bill',
			fieldID: localInfo?.billID,
			subDisplayValue: '',
		},
		{
			displayValue: 'Payment Info',
			tableName: 'p_payment',
			fieldID: localInfo?.paymentID,
			subDisplayValue: '',
		},
	];

	const [option, setOption] = useState(
		mode === 'bill-settlement'
			? [
					{
						displayValue: 'Order Info',
						tableName: 'p_order',
						fieldID: localInfo?.orderID,
						subDisplayValue: '',
					},
			  ]
			: optionArray,
	);

	const [selectedValue, setSelectedValue] = useState({
		displayValue: 'Order Info',
		tableName: 'p_order',
		fieldID: localInfo?.orderID,
		subDisplayValue: '',
	});

	const {
		fetchMore,
		loading: loadingAuditLogDetails,
		data: { auditLogDetails } = { auditLogDetails: [] },
	} = useAuditLogDetailsQuery({
		variables: {
			fieldID: selectedValue?.fieldID,
			tableName: selectedValue?.tableName,
			limit: 20,
			offset: 0,
		},
		fetchPolicy: 'network-only',
	});

	// query
	const {
		loading: outletLoading,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletNameQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const {
		loading: orderLoading,
		data: { getOrder } = { getOrder: [] },
	} = useGetOrderQuery({
		fetchPolicy: 'network-only',
		variables: {
			ID: localInfo?.orderID,
			outletID,
		},
	});

	const customerTypeInfoLine = (patronClass, order) => {
		if (patronClass === 'Visitor') {
			return order?.patronName ?? 'Visitor';
		} else if (patronClass === 'Hotel Guest') {
			const selectedGuest = localInfo?.getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
				x => x?.RegistrationID === order?.patronAccountID,
			)[0];
			return selectedGuest
				? `${selectedGuest?.RoomNo ? `${selectedGuest?.RoomNo} |` : ''} ${
						selectedGuest?.GuestName
				  }`
				: 'Hotel Guest';
		} else if (patronClass === 'Staff') {
			return localInfo?.getStaff?.filter(
				x => x?.ID === order?.patronAccountID,
			)[0]?.name;
		} else if (patronClass === 'Officer') {
			return localInfo?.getOfficer?.filter(
				x => x?.ID === order?.patronAccountID,
			)[0]?.name;
		} else if (patronClass === 'Member') {
			return 'Member';
		}
	};

	return (
		<>
			{outletLoading && <Loading />}
			{orderLoading && <Loading />}

			<MainHeader
				mainBtn="back"
				onClick={() => history.goBack()}
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Table 03 (HARDCODE)' },
					{ name: 'Bill Settlement' },
					{ name: 'Audit Log', current: true },
				]}
			/>

			{mode === 'bill-settlement' ? (
				<OrderSubheader
					mode={mode}
					getOrderLoading={orderLoading}
					getOrder={getOrder}
					selectedOrder={getOrder[0]}
					orderID={getOrder[0]?.ID}
					outletID={outletID}
					module={'audit-log'}
				/>
			) : (
				<MutliLineSubheader
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 288px)'
								: isDesktop && !globalState.drawerOpen
								? 'calc(100% - 48px)'
								: '100%',
						marginLeft: isDesktop ? '3px' : null,
						top: isDesktop ? '155px' : '67px',
					}}
					title={
						<div>
							<span>
								{`${getOrder[0]?.bill[0]?.billNo} | ${getOrder[0]?.patron?.patronType?.name}`}
							</span>
							<Typography
								variant="caption"
								style={{
									background: '#FFB11F',
									boxShadow: 'none',
									marginLeft: '5px',
									textDecoration: 'none',
									padding: '2px',
								}}
								className="outlet-table-typography"
							>
								{getOrder[0]?.table?.prefix}
								{getOrder[0]?.table?.tableNumber}
							</Typography>
						</div>
					}
					rightText={<span>{`${getOrder[0]?.patron?.patronType?.name}`}</span>}
					subtitle={
						<>
							<div>
								<img
									src={TimeBlackIcon}
									alt="ifca"
									width={12}
									loading="lazy"
									style={{ paddingRight: '4px' }}
								/>
								<span
									style={{
										fontWeight: 500,
										color: '#454545',
									}}
									className="flex-space"
								>
									{`${formatTime(getOrder[0]?.bill[0]?.createdTs)}`}
									<TextSeparator />
									{customerTypeInfoLine(
										getOrder[0]?.patron?.patronClass,
										getOrder[0],
									)}
								</span>
							</div>
						</>
					}
				/>
			)}

			<AuditLogListing
				dropdownOptions={option}
				queryData={auditLogDetails}
				queryLoading={loadingAuditLogDetails}
				dropdownValue={selectedValue}
				setDropdownValue={setSelectedValue}
				infiniteScroll={{
					next: () => {
						const currentLength = auditLogDetails?.length;
						fetchMore({
							variables: {
								fieldID: selectedValue?.fieldID,
								tableName: selectedValue?.tableName,
								limit: 10,
								offset: currentLength,
							},
							updateQuery: (prev: any, { fetchMoreResult }: any) => {
								if (!fetchMoreResult) return prev;
								return Object.assign({}, prev, {
									auditLogDetails: [
										...prev?.auditLogDetails,
										...fetchMoreResult?.auditLogDetails,
									],
								});
							},
						}).then(fetchMoreResult => {
							setLimit(
								currentLength + fetchMoreResult.data?.auditLogDetails?.length,
							);
						});
					},
				}}
				filterVariables={[
					'action',
					'actionDate',
					'createdByUserName',
					'fieldName',
					'modByUserName',
					'newValue',
					'oldValue',
					'tableName',
				]}
				hasSubheader={true}
			/>
		</>
	);
};
