import { yupResolver } from '@hookform/resolvers';
import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Button,
	IconButton,
	InputAdornment,
	TextField,
} from '@material-ui/core';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useCreatePasswordMutation } from 'generated/graphql';
import { encryptMessage } from 'helpers/Crypto/JSEncrypt';
import { IAction } from 'helpers/model';
import { CommonYupValidation } from 'helpers/yup';
import JwtDecode from 'jwt-decode';
import React, { Reducer, useReducer } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router';
import * as yup from 'yup';
import Background from '../../../src/assets/images/HR-Background.jpg';
import Logo from '../../../src/assets/images/MenuX-Logo.svg';

interface ResetProps {
	userName: string;
	newPassword: string;
	confirmPassword: string;
}

interface resetPasswordProps {
	showNewPassword: boolean;
	showConfirmPassword: boolean;
	message: string;
	dialog: boolean;
}
export const ResetPasswordSchema = yup.object().shape({
	newPassword: CommonYupValidation.password(),
	confirmPassword: yup
		.string()
		.required()
		.oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
});
export const CreatePassword = () => {
	let history = useHistory();
	const { token }: any = useParams();
	const userInfo = JwtDecode(token) as any;

	const [
		createPassword,
		{ loading, error: mutationError },
	] = useCreatePasswordMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			if (data.createPassword) {
				history.push(`/login`);
			}
		},
	});

	const reducer: Reducer<resetPasswordProps, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const initialState: resetPasswordProps = {
		showNewPassword: false,
		showConfirmPassword: false,
		dialog: false,
		message: '',
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const { handleSubmit, register, errors, control, getValues } = useForm<
		ResetProps
	>({
		defaultValues: {
			newPassword: '',
			confirmPassword: '',
		},
		mode: 'onChange',
		resolver: yupResolver(ResetPasswordSchema),
	});

	const onSubmit = data => {
		createPassword({
			variables: {
				input: {
					ID: userInfo.userID,
					password: encryptMessage(data?.newPassword),
					confirmedPassword: encryptMessage(data?.confirmPassword),
				},
			},
		});
	};
	return (
		<>
			{loading && <Loading />}
			<AuthLayout className="margin-override" logo={Logo} image={Background}>
				<span className="page-title">Let's Get You Started!</span>
				<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
					<div className="form-box">
						<Controller
							as={TextField}
							name="newPassword"
							label="New Password *"
							autoComplete="off"
							fullWidth
							ref={register}
							control={control}
							helperText={errors?.newPassword?.message}
							error={errors?.newPassword ? true : false}
							type={state.showNewPassword ? 'text' : 'password'}
							value={getValues('newPassword')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="Password"
											onClick={() =>
												dispatch({
													type: 'showNewPassword',
													payload: !state.showNewPassword,
												})
											}
										>
											{state.showNewPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>

						<Controller
							as={TextField}
							fullWidth
							helperText={errors?.confirmPassword?.message}
							error={errors?.confirmPassword ? true : false}
							autoComplete="off"
							name="confirmPassword"
							label="Confirm Password*"
							ref={register}
							control={control}
							type={state.showConfirmPassword ? 'text' : 'password'}
							value={getValues('confirmPassword')}
							InputProps={{
								endAdornment: (
									<InputAdornment position="end">
										<IconButton
											aria-label="Password"
											onClick={() =>
												dispatch({
													type: 'showConfirmPassword',
													payload: !state.showConfirmPassword,
												})
											}
										>
											{state.showConfirmPassword ? (
												<Visibility />
											) : (
												<VisibilityOff />
											)}
										</IconButton>
									</InputAdornment>
								),
							}}
						/>
					</div>

					<Button
						variant="contained"
						color="primary"
						className="login-btn"
						type="submit"
						onClick={handleSubmit(onSubmit)}
					>
						Sign Up
					</Button>
				</form>

				<CommonDialog
					fullWidth={true}
					open={state.dialog}
					onClose={() =>
						dispatch({
							type: 'dialog',
							payload: false,
						})
					}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title">Error</div>
										</div>
									</div>
								</div>
							),
						},
						body: () => <div>{state.message}</div>,
						footer: {
							actions: [
								{
									displayText: 'Ok',
									props: {
										onClick: () =>
											dispatch({
												type: 'dialog',
												payload: false,
											}),
										variant: 'contained',
										color: 'primary',
									},
								},
							],
						},
					}}
				/>
			</AuthLayout>
		</>
	);
};
