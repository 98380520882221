import { BillReceipt } from 'containers/OutletModule/Home/TableSubmenu/BillSettlement/BillReceipt/BillReceipt';
import React from 'react';
import { DigitalBillList } from '../../DigitalDocument/DigitalBillList';
import { AverageMealTimeAnalysis } from './AverageMealTimeAnalysis/AverageMealTimeAnalysis';
import { BusinessAnalyticsSubmenu } from './BusinessAnalyticsSubmenu';
import { DiscountTypeAnalysis } from './DiscountTypeAnalysis/DiscountTypeAnalysis';
import { FastMovingAnalysis } from './FastMovingAnalysis/FastMovingAnalysis';
import { MealPeriodAnalysis } from './MealPeriodAnalysis/MealPeriodAnalysis';
import { OutletSalesAnalysis } from './OutletSalesAnalysis/OutletSalesAnalysis';
import { PatronTypeAnalysis } from './PatronTypeAnalysis/PatronTypeAnalysis';
import { SlowMovingAnalysis } from './SlowMovingAnalysis/SlowMovingAnalysis';
import { WaiterSalesAnalysis } from './WaiterSalesAnalysis/WaiterSalesAnalysis';

const businessAnalyticsRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/business-insight/business-analytic/meal-time`,
		},
		component: <AverageMealTimeAnalysis />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/business-insight/business-analytic/discount-type`,
		},
		component: <DiscountTypeAnalysis />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/business-insight/business-analytic/fast-moving`,
		},
		component: <FastMovingAnalysis />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/business-insight/business-analytic/meal-period`,
		},
		component: <MealPeriodAnalysis />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/business-insight/business-analytic/outlet-sales`,
		},
		component: <OutletSalesAnalysis />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/business-insight/business-analytic/patron-type`,
		},
		component: <PatronTypeAnalysis />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/business-insight/business-analytic/slow-moving`,
		},
		component: <SlowMovingAnalysis />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/business-insight/business-analytic/waiter-sales`,
		},
		component: <WaiterSalesAnalysis />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/business-insight/digital-bill`,
		},
		component: <DigitalBillList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/business-insight/digital-bill/:billID`,
		},
		component: <BillReceipt />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/business-insight/business-analytic`,
		},
		component: <BusinessAnalyticsSubmenu />,
	},
];

export default businessAnalyticsRoutes;
