import { yupResolver } from '@hookform/resolvers';
import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { Button, TextField } from '@material-ui/core';
import { useForgotPasswordMutation } from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router';
import * as yup from 'yup';
import Background from '../../../src/assets/images/HR-Background.jpg';
import Logo from '../../../src/assets/images/MenuX-Logo.svg';

interface ForgotProps {
	email: string;
}
export const ForgotPasswordSchema = yup.object().shape({
	email: yup
		.string()
		.email()
		.required(SystemMsgs.email()),
	// product: yup.string().required(),
});
export const ForgotPassword = props => {
	let history = useHistory();
	const [dialog, setDialog] = useState(false);
	const [errorDialog, setErrorDialog] = useState(false);
	const [message, setMessage] = useState('');
	const [ForgotPassword, { loading, error }] = useForgotPasswordMutation({
		onError: error => {
			console.log('error', error);
			setErrorDialog(true);
			setMessage(error.graphQLErrors[0].message);
		},
		onCompleted: data => {
			setDialog(true);
		},
	});
	const { handleSubmit, register, errors, control } = useForm<ForgotProps>({
		defaultValues: {
			email: '',
		},
		mode: 'onChange',
		resolver: yupResolver(ForgotPasswordSchema),
	});
	const onSubmit = data => {
		ForgotPassword({
			variables: {
				email: data.email,
			},
		});
	};
	return (
		<AuthLayout logo={Logo} image={Background} className="margin-override">
			<span className="page-title">Forget Password</span>
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<div className="form-box">
					<Controller
						as={TextField}
						fullWidth
						helperText={errors?.email?.message}
						error={errors?.email ? true : false}
						autoComplete="off"
						name="email"
						label="Email"
						required
						control={control}
					/>
					<span className="forget-link" onClick={() => history.push('/login')}>
						Sign In Here
					</span>
				</div>
				<Button
					variant="contained"
					color="primary"
					className="login-btn"
					type="submit"
				>
					Send
				</Button>
			</form>
			<CommonDialog
				fullWidth={true}
				open={dialog || errorDialog}
				onClose={() => (dialog ? setDialog(false) : setErrorDialog(false))}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">{`${
											dialog ? 'Info' : 'Error'
										}`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<div>{dialog ? SystemMsgs.forgotPassword() : message}</div>
					),
					footer: {
						actions: [
							{
								displayText: 'Ok',
								props: {
									onClick: dialog
										? () => history.push('/login')
										: () => setErrorDialog(false),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</AuthLayout>
	);
};
