import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { Info, KeyboardArrowRight } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import {
	PosPermission,
	useGetBusinessAnalyticsSummaryLazyQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import React, { useContext, useEffect } from 'react';
import { useHistory } from 'react-router-dom';

export const BusinessAnalyticsSubmenu = () => {
	const history = useHistory();
	const { globalState, dispatch } = useContext(AppContext as any);

	const outletID = localStorage.getItem('latestOutlet');

	const [
		loadBusinessAnalyticsSummary,
		{
			called: businessAnalyticsSummaryCalled,
			loading: businessAnalyticsSummaryLoading,
			data: businessAnalyticsSummaryData,
		},
	] = useGetBusinessAnalyticsSummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	const { handlePermHidden } = usePermissionChecker();

	useEffect(() => {
		if (!businessAnalyticsSummaryCalled) {
			loadBusinessAnalyticsSummary({});
		}
	}, [businessAnalyticsSummaryCalled]);

	const outletSalesDate = businessAnalyticsSummaryData?.outletSalesDate;
	const mealPeriodDate = businessAnalyticsSummaryData?.mealPeriodDate;
	const waiterSalesDate = businessAnalyticsSummaryData?.waiterSalesDate;
	const fastMovingMenuItemDate =
		businessAnalyticsSummaryData?.fastMovingMenuItemDate;
	const slowMovingMenuItemDate =
		businessAnalyticsSummaryData?.slowMovingMenuItemDate;
	const patronTypeDate = businessAnalyticsSummaryData?.patronTypeDate;
	const discountTypeDate = businessAnalyticsSummaryData?.discountTypeDate;
	const averageMealTimeDate = businessAnalyticsSummaryData?.averageMealTimeDate;

	let outletList;
	outletList = [
		{
			path: `/menu/outlet-app/business-insight/business-analytic/outlet-sales`,
			icon: Info,
			title: `Outlet Sales & Collection Analysis`,
			label1: `Last updated on `,
			data1: formatDate(outletSalesDate),
			permission: PosPermission.BizAnalyticsOscAnalysisShow,
		},
		{
			path: `/menu/outlet-app/business-insight/business-analytic/meal-period`,

			icon: Info,
			title: `Meal Period & Collection Analysis`,
			label1: `Last updated on `,
			data1: formatDate(mealPeriodDate),
			permission: PosPermission.BizAnalyticsMpsAnalysisShow,
		},
		{
			path: `/menu/outlet-app/business-insight/business-analytic/waiter-sales`,
			icon: Info,
			title: `Waiter Sales & Collection Analysis`,
			label1: `Last updated on `,
			data1: formatDate(waiterSalesDate),
			permission: PosPermission.BizAnalyticsWscAnalysisShow,
		},
		{
			path: `/menu/outlet-app/business-insight/business-analytic/fast-moving`,
			icon: Info,
			title: `Fast-Moving Menu Item Analysis`,
			label1: `Last updated on `,
			data1: formatDate(fastMovingMenuItemDate),
			permission: PosPermission.BizAnalyticsFmviAnalysisShow,
		},
		{
			path: `/menu/outlet-app/business-insight/business-analytic/slow-moving`,
			icon: Info,
			title: `Slow-Moving Menu Item Analysis`,
			label1: `Last updated on `,
			data1: formatDate(slowMovingMenuItemDate),
			permission: PosPermission.BizAnalyticsSmviAnalysisShow,
		},
		{
			path: `/menu/outlet-app/business-insight/business-analytic/patron-type`,
			icon: Info,
			title: `Patron Type Analysis`,
			label1: `Last updated on `,
			data1: formatDate(patronTypeDate),
			permission: PosPermission.BizAnalyticsPtAnalysisShow,
		},
		{
			path: `/menu/outlet-app/business-insight/business-analytic/discount-type`,

			icon: Info,
			title: `Discount Type Analysis`,
			label1: `Last updated on `,
			data1: formatDate(discountTypeDate),
			permission: PosPermission.BizAnalyticsDtAnalysisShow,
		},
		{
			path: `/menu/outlet-app/business-insight/business-analytic/meal-time`,
			icon: Info,
			title: `Average Meal Time Analysis`,
			label1: `Last updated on `,
			data1: formatDate(averageMealTimeDate),
			permission: PosPermission.BizAnalyticsAmtAnalysisShow,
		},
	];
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	return (
		<>
			<MainHeader
				mainBtn="menu"
				onClick={handleDrawer}
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: 'Business Insight' },
					{ name: 'Business Analytics', current: true },
				]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{outletList.map((el, index) => {
						if (
							handlePermHidden({
								outletID: outletID,
								permEnum: el.permission,
							})
						)
							return (
								<ListItem
									key={index}
									onClick={() => history.push(`${el.path}`)}
								>
									<el.icon style={{ paddingRight: '10px', color: '#ff9800' }} />
									<ListItemText
										primary={
											<>
												<span className="desc">
													<span className="xsTitle">{el.title}</span>
												</span>
											</>
										}
										secondary={
											<span className="desc">
												<span className="desc">{el.label1}</span>
												<span className="highlight-text">{el.data1}</span>
											</span>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete">
											<KeyboardArrowRight
												onClick={() => history.push(`${el.path}`)}
											/>
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							);
					})}
				</List>
			</ContentWrapper>
		</>
	);
};
