import React from 'react';
import { InterfaceSetup } from './InterfaceSetup';

const interfaceSetupRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/third-party-interface-setup`,
		},
		component: <InterfaceSetup />,
	},
];

export default interfaceSetupRoutes;
