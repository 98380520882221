import DateFnsUtils from '@date-io/date-fns';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import {
	CardActions,
	Grid,
	ListItemText,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableHead,
	TableRow,
	useMediaQuery,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import StopOutlinedIcon from '@material-ui/icons/StopOutlined';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import AppContext from 'containers/App/Store/AppContext';
import {
	useGetBizInsightOutletQuery,
	useGetShareAttachmentMutation,
	useMealPeriodSalesAnalysisLazyQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import * as htmlToImage from 'html-to-image';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import ShareDialog from '../../../../../components/Dialog/ShareDialog';

const data = [
	{ name: 'Breakfast', costToDate: 9800, revBudget: 2400, amt: 2400 },
	{ name: 'Lunch', costToDate: 9800, revBudget: 1398, amt: 2210 },
	{ name: 'Dinner', costToDate: 9800, revBudget: 9800, amt: 2290 },
];
const COLORS = ['#28D4F5', '#F5A728', '#2849F5', '#ffc853', '#f8f0d7'];
export const MealPeriodAnalysis = () => {
	const history = useHistory();

	const { ID, outletID } = useParams<Record<string, any>>();

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
	const latestOutlet = localStorage.getItem('latestOutlet');

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	//-----------------START DATE & END DATE--------------------------

	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	//selectedStartDate
	const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
		firstDay,
	);
	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date);
	};

	//selectedEndDate
	const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
		lastDay,
	);
	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date);
	};

	//------------------OUTLET-------------------------------------

	const {
		data: { getOutlet } = { getOutlet: [] },
		called: getOutletCalled,
		loading: getOutletLoading,
	} = useGetBizInsightOutletQuery({
		fetchPolicy: 'network-only',
	});

	const [selectedOutlet, setSelectedOutlet] = useState(null);

	useEffect(() => {
		setSelectedOutlet(
			getOutlet?.filter(outlet => outlet?.ID === latestOutlet)[0],
		);
	}, [getOutlet]);

	//----------------MEAL PERIOD ANALYSIS-------------------------

	const [
		loadMealPeriodSalesAnalysis,
		{
			data: { mealPeriodSalesAnalysis } = { mealPeriodSalesAnalysis: [] },
			called: mealPeriodSalesAnalysisCalled,
			loading: mealPeriodSalesAnalysisLoading,
		},
	] = useMealPeriodSalesAnalysisLazyQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (selectedOutlet && selectedStartDate && selectedEndDate) {
			loadMealPeriodSalesAnalysis({
				variables: {
					outletID: selectedOutlet?.ID,
					startDate: selectedStartDate,
					endDate: selectedEndDate,
				},
			});
		}
	}, [selectedOutlet, selectedStartDate, selectedEndDate]);

	const fontSize = '12px';
	const CustomizedXAxisLabel = () => {
		return (
			<>
				<text
					x={0}
					y={0}
					dx="50%"
					dy="5%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={16}
					fontWeight={500}
					width={180}
				>
					{`${selectedOutlet?.name}`}
				</text>
				<text
					x={0}
					y={0}
					dx="50%"
					dy="10%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={12}
					fontWeight={350}
					width={180}
				>
					{`Meal Period Analysis  ${formatDate(
						selectedStartDate.toISOString(),
					)} - ${formatDate(selectedEndDate.toISOString())} `}
				</text>
			</>
		);
	};
	const CustomizedYAxisLabel = () => {
		return (
			<text
				x={0}
				y={0}
				dx={-150}
				dy={20}
				textAnchor="middle"
				dominantBaseline="middle"
				fontSize={12}
				fontWeight={500}
				width={180}
				transform="rotate(-90)"
				// If I uncomment the next line, then the rotation stops working.
				// scaleToFit={true}
			>
				Index
			</text>
		);
	};

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);
	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const onSubmit = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: selectedOutlet?.ID,
			},
		});
	};

	return (
		<>
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/business-insight/business-analytic`,
					})
				}
				mainBtn="back"
				smTitle="Business Insights"
				// HC
				title={loggedInUser?.companyName}
				routeSegments={[
					{ name: 'Business Insights' },
					{ name: 'Meal Period Sales Statistics', current: true },
				]}
			/>

			<DynamicSubHeader
				title={
					getOutlet === undefined || getOutlet?.length === 0
						? ``
						: `${selectedOutlet?.name}`
				} // title text
				handleClick={e => handleClick(e, null, 0)}
				dropDownOptions={
					// dropdown component place here
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						{getOutlet.map((outlet, index) => (
							<StyledMenuItem
								key={index}
								value={outlet.ID}
								defaultValue={outlet.ID}
								onClick={e => {
									setSelectedOutlet(outlet);
								}}
							>
								<ListItemText primary={outlet?.name} />
							</StyledMenuItem>
						))}
					</StyledMenu>
				}
			/>

			<ContentWrapper search footer>
				<CardActions>
					<MuiPickersUtilsProvider utils={DateFnsUtils}>
						<KeyboardDatePicker
							id="date-picker-dialog"
							style={{
								background: 'white',
								width: '50%',
								zIndex: 40,
							}}
							name="startDate"
							format="dd MMM yyyy"
							value={selectedStartDate}
							onChange={handleStartDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							inputVariant="outlined"
							size="small"
							className="custom-date-business-insight"
						/>

						<KeyboardDatePicker
							id="date-picker-dialog"
							format="dd MMM yyyy"
							name="endDate"
							value={selectedEndDate}
							style={{
								background: 'white',
								width: '50%',
								zIndex: 40,
							}}
							onChange={handleEndDateChange}
							KeyboardButtonProps={{
								'aria-label': 'change date',
							}}
							inputVariant="outlined"
							size="small"
							className="custom-date-business-insight"
						/>
					</MuiPickersUtilsProvider>
				</CardActions>

				{!mealPeriodSalesAnalysisLoading &&
				(mealPeriodSalesAnalysis === undefined ||
					mealPeriodSalesAnalysis?.length === 0) ? (
					<EmptyList
						title={`No Order Item created for ${selectedOutlet?.name}`}
						subtitle="Please create new order to continue"
					/>
				) : (
					!mealPeriodSalesAnalysisLoading && (
						<>
							{MealPeriodAnalysisBarGraph(
								mealPeriodSalesAnalysis,
								CustomizedXAxisLabel,
								CustomizedYAxisLabel,
								fontSize,
							)}
							{MealPeriodAnalysisBarGraph(
								mealPeriodSalesAnalysis,
								CustomizedXAxisLabel,
								CustomizedYAxisLabel,
								fontSize,
								true,
							)}
							<div className="cover-layer">
								<CardContents>
									<></>
								</CardContents>
							</div>
						</>
					)
				)}
			</ContentWrapper>

			{ShareDialog({
				shareTitle: `Meal Period Sales Statistic - ${selectedOutlet?.name}`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}
			<DynamicFooter
				overlap
				isHidden={openShareDialog}
				options={[
					{
						name: 'Share',
						onClick: async () => {
							let temp = await document.getElementById('hiddenBarChart');
							htmlToImage
								.toBlob(temp, { style: { background: '#fff' } })
								.then(value => {
									onSubmit(value);
								});
						},
						disabled:
							mealPeriodSalesAnalysis == undefined ||
							mealPeriodSalesAnalysis.length == 0,
						color: 'primary',
						props: {
							type: 'submit',
						},
						startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
					},
				]}
			/>
		</>
	);
};

const MealPeriodAnalysisBarGraph = (
	mealPeriodSalesAnalysis,
	CustomizedXAxisLabel,
	CustomizedYAxisLabel,
	fontSize,
	hidden?: boolean,
) => {
	return (
		<div
			style={
				hidden
					? {
							zIndex: -40,
							top: 0,
							left: 0,
							position: 'fixed',
							width: '100%',
					  }
					: {
							marginTop: '0px',
							zIndex: 40,
							position: 'relative',
					  }
			}
			className={hidden ? 'hidden-content' : null}
		>
			<CardContents
				section={
					!hidden
						? {
								header: {
									title: 'Sales',
								},
						  }
						: null
				}
			>
				<div
					id={!hidden ? 'barChart ' : 'hiddenBarChart'}
					style={{ width: '100%' }}
				>
					<div style={{ width: '100%', height: 300 }}>
						<ResponsiveContainer>
							<BarChart
								data={mealPeriodSalesAnalysis}
								margin={{
									top: hidden ? 55 : 10,
									right: 10,
									left: 10,
									bottom: 10,
								}}
							>
								<CartesianGrid strokeDasharray="2 3" />
								<XAxis
									dataKey="name"
									fontSize={10}
									axisLine={false}
									tickLine={false}
									label={hidden ? CustomizedXAxisLabel : null}
								/>
								<YAxis
									dataKey="totalSales"
									fontSize={10}
									axisLine={false}
									tickLine={false}
									label={<CustomizedYAxisLabel />}
								/>
								<Tooltip />

								<Bar
									dataKey="totalSales"
									fill="#000000"
									type="monotone"
									barSize={39}
								>
									{mealPeriodSalesAnalysis.map((entry, index) => (
										<Cell key={`cell-${index}`} fill={COLORS[index % 20]} />
									))}
								</Bar>
							</BarChart>
						</ResponsiveContainer>
					</div>

					<Grid container justify="center">
						<Grid item>
							<TableContainer style={{ overflow: 'hidden' }}>
								<Table size="small" aria-label="a dense table">
									<TableHead>
										<TableRow
											style={{
												color: 'black',
												borderWidth: '2px',
												borderColor: 'white',
												borderStyle: 'solid',
											}}
										>
											<TableCell
												style={{
													color: 'black',
													borderWidth: '2px',
													borderColor: 'white',
													borderStyle: 'solid',
													fontSize: fontSize,
												}}
												component="td"
												align="left"
											></TableCell>
											<TableCell
												style={{
													color: 'black',
													borderWidth: '2px',
													borderColor: 'white',
													borderStyle: 'solid',
													fontSize: fontSize,
												}}
												component="td"
												align="left"
											>
												Meal Period
											</TableCell>

											<TableCell
												align="center"
												style={{
													color: 'black',
													borderWidth: '2px',
													borderColor: 'white',
													borderStyle: 'solid',
													fontSize: fontSize,
												}}
												component="td"
												// width="20%"
											>
												No.of Table
											</TableCell>
											<TableCell
												align="left"
												style={{
													color: 'black',
													borderWidth: '2px',
													borderColor: 'white',
													borderStyle: 'solid',
													fontSize: fontSize,
												}}
												component="td"
											>
												Pax
											</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{mealPeriodSalesAnalysis.map((el, index) => (
											<TableRow
												style={{
													color: 'black',
													borderWidth: '2px',
													borderColor: 'white',
													borderStyle: 'solid',
												}}
											>
												<TableCell
													style={{
														color: 'black',
														borderWidth: '2px',
														borderColor: 'white',
														borderStyle: 'solid',
														fontSize: fontSize,
													}}
													component="td"
													align="left"
												>
													<StopOutlinedIcon
														style={{
															fill: COLORS[index % 20],
															backgroundColor: COLORS[index % 20],
															width: '10px',
															height: '10px',
														}}
													>
														<Cell key={`cell-${index}`} />
													</StopOutlinedIcon>
												</TableCell>
												<TableCell
													style={{
														color: 'black',
														borderWidth: '2px',
														borderColor: 'white',
														borderStyle: 'solid',
														fontSize: fontSize,
													}}
													component="td"
													align="left"
												>
													{el.name}
												</TableCell>
												<TableCell
													align="center"
													style={{
														color: 'black',
														borderWidth: '2px',
														borderColor: 'white',
														borderStyle: 'solid',
														fontSize: fontSize,
													}}
													component="td"
												>
													{el.totalTable}
												</TableCell>
												<TableCell
													align="left"
													style={{
														color: 'black',
														borderWidth: '2px',
														borderColor: 'white',
														borderStyle: 'solid',
														fontSize: fontSize,
													}}
													component="td"
												>
													{el.paxSum}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</TableContainer>
						</Grid>
					</Grid>
				</div>
				<div style={{ paddingBottom: '10px' }}></div>
			</CardContents>
		</div>
	);
};
