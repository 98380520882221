import { useLazyQuery } from '@apollo/react-hooks';
import theme from '@ifca-root/react-component/src/assets/theme';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	Box,
	Button,
	Divider,
	Grid,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	useMediaQuery,
} from '@material-ui/core';
import LocalMallIcon from '@material-ui/icons/LocalMall';
import ReplayIcon from '@material-ui/icons/Replay';
import AppContext from 'containers/App/Store/AppContext';
import { parseISO } from 'date-fns';
import {
	GetKitchenOrderDocument,
	KitchenStatus,
	OrderItemStatus,
	PosPermission,
	useConfirmKitchenItemStatusMutation,
	useGetKitchenOrderLazyQuery,
	useGetOutletQuery,
	useLoggedInUserProfileQuery,
	useRefreshOrderItemSubscription,
	useRevertKitchenItemStatusMutation,
	useUpdateKitchenItemStatusMutation,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { WSclient } from 'POSClient';
import React, { useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {
	GET_ACCOUNT_DETAIL,
	GET_CURRENCY,
	GET_PACKAGE_BY_ACCOUNT,
} from '../../containers/AccountModule/AccountQuery';
// import { WSclient } from '../../POSClient';
import { AccountClient } from '../../ProductsClient';
import { CardContentsKitchen } from './CardContentsKitchen';
import './kitchen.scss';
import { MainHeaderKitchen } from './MainHeaderKitchen';

interface kitchenProps {}

export const KitchenHome = (props: kitchenProps) => {
	const settings = {
		dots: false,
		infinite: false,
		speed: 500,
		swipeToSlide: true,
		rewind: true,
		arrows: false,
		rows: 1,
		initialSlide: 0,
	};

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { globalState, dispatch } = useContext(AppContext as any);

	const { outletID, kitchenPrinterID } = useParams<Record<string, any>>();

	const { handlePermDisabled } = usePermissionChecker();

	// const currentMealPeriod = JSON?.parse(
	// 	localStorage?.getItem('currentMealPeriod'),
	// )[outletID][0];

	const { data: { getOutlet } = { getOutlet: [] } } = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArr: any[] = getOutlet;

	const {
		data: { loggedInUserProfile } = { loggedInUserProfile: null },
		called: loggedInUserCalled,
	} = useLoggedInUserProfileQuery();

	// ACCOUNTX API CALLS
	const [
		loadAccountInfos,
		{
			loading: accountLoading,
			called: accountCalled,
			error,
			data: { getAccountDetail } = {
				getAccountDetail: {},
			},
		},
	] = useLazyQuery(GET_ACCOUNT_DETAIL, {
		client: AccountClient as any,
	});

	const [
		loadPackage,
		{
			loading: packageLoading,
			error: packageError,
			data: { getPackageByAccount } = { getPackageByAccount: [] },
		},
	] = useLazyQuery(GET_PACKAGE_BY_ACCOUNT, {
		client: AccountClient as any,
	});

	const [
		loadCurrency,
		{
			loading: currencyLoading,
			called: currencyCalled,
			error: currencyError,
			data: { getCurrency } = { getCurrency: [] },
		},
	] = useLazyQuery(GET_CURRENCY, {
		client: AccountClient as any,
	});

	useEffect(() => {
		if (loggedInUserProfile?.accountID) {
			loadAccountInfos({
				variables: {
					accountID: loggedInUserProfile?.accountID,
				},
			});
			loadPackage({
				variables: {
					softwareCode: 'POS',
					accountID: loggedInUserProfile?.accountID,
				},
			});
		}
	}, [loggedInUserProfile]);

	useEffect(() => {
		if (getAccountDetail?.currencyID) {
			loadCurrency({
				variables: {
					ID: getAccountDetail?.currencyID,
				},
			});
		}
	}, [getAccountDetail]);

	let checkStatus;
	// USER INFO
	let userAccDetail = {
		companyName: getAccountDetail?.name,
		companyEmail: getAccountDetail?.email,
		companyCurrency: getCurrency[0]?.name,
		companyCurrencyCode: getCurrency[0]?.code,
		mobileNo: loggedInUserProfile?.contactNo!,
		...getAccountDetail!,
		...loggedInUserProfile!,
	};
	localStorage.setItem('loggedInUser', JSON.stringify(userAccDetail));
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	//QUERY
	//QUERY

	// const [
	// 	loadOrder,
	// 	{
	// 		loading: getOrderLoading,
	// 		called: getOrderCalled,
	// 		data: { getOrder } = { getOrder: [] },
	// 		fetchMore,
	// 	},
	// ] = useGetOrderLazyQuery({
	// 	fetchPolicy: 'network-only',
	// });

	const [
		loadOrder,
		{
			loading: getKitchenOrderLoading,
			called: getKitchenOrderCalled,
			data: { getKitchenOrder } = { getKitchenOrder: [] },
			fetchMore,
		},
	] = useGetKitchenOrderLazyQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!getKitchenOrderCalled) {
			loadOrder({
				variables: {
					outletID: outletID,
					kitchenPrinterID: kitchenPrinterID,
				},
			});
		}
	}, [getKitchenOrderCalled]);

	const filterItem = (orderItem, order) => {
		let temp = {};
		orderItem?.map(el => {
			if (
				(el?.status === OrderItemStatus.TransferOut ||
					el?.status === OrderItemStatus.Voided) &&
				el?.quantity < 0
			) {
				if (el?.refID in temp) {
					temp[el?.refID] += el?.quantity * -1;
				} else {
					temp[el?.refID] = el?.quantity * -1;
				}
			}
		});

		return orderItem
			?.map(v => {
				return { ...v, quantity: v?.quantity - (temp[v?.ID] ?? 0) };
			})
			?.filter(v => {
				return v?.quantity > 0;
			});
	};

	//subscription //subscription //subscription

	const {
		loading: refreshOrderItemLoading,
		data: { refreshOrderItem } = { refreshOrderItem: {} as any },
	} = useRefreshOrderItemSubscription({
		variables: {
			outletID: outletID,
		},
		client: WSclient,
	});

	useEffect(() => {
		if (refreshOrderItem?.ID) {
			fetchMore({
				query: GetKitchenOrderDocument,
				variables: {
					outletID: outletID,
					kitchenPrinterID: kitchenPrinterID,
				},
			});
		}
	}, [refreshOrderItem?.ID]);

	//subscription //subscription //subscription

	//MUTAASI
	//MUTAASI

	const [updateKitchenStatus] = useUpdateKitchenItemStatusMutation({
		onError: err => {
			console.log('err', err);
		},
	});

	const [revertKitchenStatus] = useRevertKitchenItemStatusMutation({
		onError: err => {
			console.log('err', err);
		},
	});

	const [confirmKitchenStatus] = useConfirmKitchenItemStatusMutation({
		onError: err => {
			console.log('err', err);
		},
		// onCompleted: (data) => {
		// 	setKitchenState(data?.kitchenStatus);
		// },
	});

	//get mins

	const changeTime = date => {
		let changed = parseISO(date);
		let orderDate: any = new Date(changed);
		let currentDate: any = new Date();
		let mili = currentDate - orderDate;

		let hours = (mili / 60000 / 60).toFixed(0);
		let mins = ((mili / 60000) % 60).toFixed(0); // minutes

		return hours !== '0'
			? `${hours} hour ${mins} minutes ago...`
			: `${mins} minutes ago...`;
	};

	const pendingItem = (data: any) => {
		return data?.filter(
			(v: any) =>
				v.kitchenStatus === 'PENDING' || v.kitchenStatus === 'PREPARING',
		);
	};

	const confirmedItem = (data: any) => {
		return data?.filter((v: any) => v.kitchenStatus === 'PREPARED');
	};

	const grey_divider = (amount: number, indicator: string) => {
		return (
			<List className="mdDesc pending-completed">
				<div className="pend-comp-text">
					<span>{`${indicator} Order Item `}</span>(
					<span style={{ color: '#ff9800' }}>{`${amount}`}</span>)
				</div>
			</List>
		);
	};

	const filterOrder = data => {
		return data?.filter(
			v =>
				v?.kitchenOrderItems?.filter(e => e?.kitchenStatus !== 'COMPLETED')
					.length > 0,
		);
	};

	const checkPreparing = data => {
		return data?.map(
			x =>
				x?.kitchenOrderItems?.filter(x => x?.kitchenStatus === 'PREPARING')
					?.length,
		);
	};

	const [dataState, setOpenDialog] = useState({
		dialogState: false,
		data: null,
	});
	const [confirm, setConfirm] = useState(false);

	useEffect(() => {
		if (confirm) {
			confirmKitchenStatus({
				variables: {
					InputItemID: dataState.data?.kitchenOrderItems?.map(id => {
						return id?.ID;
					}),
				},
				refetchQueries: [
					{
						query: GetKitchenOrderDocument,
						variables: {
							outletID: outletID,
							kitchenPrinterID: kitchenPrinterID,
						},
					},
				],
			});
			setConfirm(false);
			setOpenDialog({ dialogState: false, data: null });
		}
	}, [confirm]);

	return (
		<>
			<MainHeaderKitchen
				// mainBtn={null}
				smallTitle="Kitchen App"
				mainTitle={outletArr[0]?.name}
				infoLeft={[
					{
						name: `Orders : ${
							getKitchenOrder?.length
						} | Items preparing : ${checkPreparing(getKitchenOrder)?.reduce(
							(x, y) => x + y,
							0,
						)}`,
						current: true,
					},
				]}
				// infoRight={[
				// 	{
				// 		name: currentMealPeriod
				// 			? `${currentMealPeriod?.name} ${formatTime(
				// 					currentMealPeriod?.startTime,
				// 			  )} - ${formatTime(currentMealPeriod?.endTime)} `
				// 			: 'No applicable meal period at the moment',
				// 	},
				// ]}
			/>

			{!getKitchenOrderLoading && (
				<div
					className="custom-wrapper-main with-float  "
					style={{
						marginTop: '-140px',
						// paddingBottom: '78px',
						// paddingTop: isDesktop ? '0px' : '20px',
					}}
				>
					{filterOrder(getKitchenOrder)?.length === 0 ? (
						<div id="helo" className="custom-kitchen-center">
							<div
								className="smTitle"
								style={{ width: '100%', margin: 'auto' }}
							>
								There are currently no confirmed order
							</div>

							<div className="desc" style={{ width: '100%', margin: 'auto' }}>
								Awaiting new confirm order
							</div>
						</div>
					) : (
						<Slider
							{...settings}
							slidesToShow={isDesktop && !globalState.drawerOpen ? 5 : 4}
							verticalSwiping={false}
							className="carousel-left-align"
						>
							{filterOrder(getKitchenOrder)?.map((v, i) => (
								<div
									// className="custom-btn-container"
									style={{
										marginTop: '-70px',
										width: isDesktop ? '110%' : '145%',
									}}
								>
									<div className="div-width">
										<Grid style={{ marginLeft: '5px' }} container>
											<Grid
												item
												xs={1}
												style={{
													background: '#FFB11F',
													boxShadow: 'none',
													borderRadius: '5px',
													textAlign: 'center',
													alignSelf: 'center',
													paddingTop: '4px',
													height: '22px',
												}}
											>
												<div className="xsTitle text-noflow">
													{v?.table ? (
														`${v?.table.prefix}${v?.table?.tableNumber}`
													) : (
														<LocalMallIcon
															style={{ marginLeft: '1px', height: '15px' }}
														/>
													)}
												</div>
											</Grid>
											<Grid
												item
												xs={6}
												className="mdDesc"
												style={{
													alignSelf: 'center',
													paddingLeft: '4px',
													paddingTop: '4px',
												}}
											>
												{v?.docNo}
											</Grid>
											<Grid
												item
												xs={5}
												className="desc text-noflow"
												style={{
													alignSelf: 'center',
													paddingTop: '4px',
													paddingRight: '12px',
													textAlign: 'right',
												}}
											>
												{
													(checkStatus = v?.kitchenOrderItems?.every(
														e => e?.kitchenStatus === KitchenStatus.Prepared,
													))
												}
												{/* {checkStatus ? (
													<>
														<IconButton style={{ padding: '0px' }}>
															<CancelIcon
																style={{ color: 'red' }}
																onClick={() => {
																	setOpenDialog({
																		dialogState: true,
																		data: v,
																	});
																}}
															/>
														</IconButton>
													</>
												) : ( */}
												{changeTime(v?.kitchenOrderItems[0]?.modTs)}
												{/* )} */}
											</Grid>
										</Grid>
									</div>

									<ContentWrapper
										singleDynamicInfo
										style={{ paddingBottom: '0px' }}
									>
										<CardContentsKitchen
											style={{
												marginRight: '3px',
												marginLeft: '3px',
												height: '-webkit-fill-available',
												minHeight: '600px',
												maxHeight: '-webkit-fill-available',
												// marginTop: '32px',
												// marginBottom: '100px',
												boxShadow:
													'0 3px 1px -2px rgba(0, 0,0,0), 0 1px 10px 2px rgba(0,0,0,0.05), 0px 0px 5px 0 rgba (0,0,0,0.1) ',
											}}
											contentStyle={{
												display: 'contents',
											}}
										>
											<>
												<div className="custom-wrapper">
													<div className="custom-card-form">
														{/* <div className="div-width">
															 <span className="desc custom-header"> 
															<Grid container>
																<Grid
																	item
																	xs={1}
																	style={{
																		background: '#FFB11F',
																		boxShadow: 'none',
																		borderRadius: '5px',
																		textAlign: 'center',
																		alignSelf: 'center',
																		paddingTop: '4px',
																	}}
																>
																	<div className="xsTitle">
																		{`${v?.table.prefix}${v?.table?.tableNumber}`}
																	</div>
																</Grid>
																<Grid
																	item
																	xs={8}
																	className="mdDesc"
																	style={{
																		alignSelf: 'center',
																		paddingLeft: '4px',
																		paddingTop: '4px',
																	}}
																>
																	{v?.docNo}
																</Grid>
																<Grid
																	item
																	xs={3}
																	className="desc text-noflow"
																	style={{
																		alignSelf: 'center',
																		paddingTop: '4px',
																		textAlign: 'end',
																	}}
																>
																	{
																		(checkStatus = v?.orderItem?.every(
																			(e) =>
																				e?.kitchenStatus ===
																				KitchenStatus.Prepared,
																		))
																	}
																	{checkStatus ? (
																		<>
																			<IconButton style={{ padding: '0px' }}>
																				<CancelIcon
																					style={{ color: 'red' }}
																					onClick={() => {
																						setOpenDialog({
																							dialogState: true,
																							data: v,
																						});
																					}}
																				/>
																			</IconButton>
																		</>
																	) : (
																		`${changeTime(v?.createdTs)} mins ago...`
																	)}
																</Grid>
															</Grid>
															 </span> 
														</div> */}

														{grey_divider(
															pendingItem(filterItem(v?.kitchenOrderItems, v))
																?.length,
															'Pending',
														)}
														{pendingItem(
															filterItem(v?.kitchenOrderItems, v),
														)?.map((e, i) => (
															<>
																<List
																	className="custom-list"
																	key={i}
																	style={{
																		background:
																			e?.kitchenStatus === 'PREPARING'
																				? 'blanchedalmond'
																				: null,

																		// borderStyle: 'inset',
																		// borderWidth: 'thin',
																	}}
																>
																	<ListItem
																		className="remove-padding"
																		key={i}
																		onClick={() => {
																			updateKitchenStatus({
																				variables: {
																					InputItemID: e?.ID,
																				},
																				refetchQueries: [
																					{
																						query: GetKitchenOrderDocument,
																						variables: {
																							outletID: outletID,
																							kitchenPrinterID: kitchenPrinterID,
																						},
																					},
																				],
																			});
																		}}
																		disabled={handlePermDisabled({
																			outletID: outletID,
																			permEnum:
																				PosPermission.KitchenUpdateOrderItemUpdate,
																		})}
																	>
																		<img
																			src={e?.menuItem?.attachments?.fileURL}
																			className="kitchen-img-owned"
																			height="80px"
																			width="80px"
																		/>
																		<ListItemText
																			className="custom-text-list"
																			primary={
																				<>
																					<span
																						className="xsTitle content-wrap text-noflow"
																						style={{
																							marginBottom: '5px',
																							marginTop: '10px',
																						}}
																					>
																						{`${
																							e?.openItemName
																								? e?.openItemName
																								: e?.menuItem?.name
																						}  `}
																					</span>
																					<span
																						className="xsTitle content-wrap text-noflow"
																						style={{ color: '#ff9800' }}
																					>
																						{e?.quantity === 1 &&
																						e?.weight !== 1
																							? `${e?.weight}g`
																							: e?.quantity > 1 &&
																							  e?.weight === 1
																							? `x  ${e?.quantity}`
																							: e?.quantity > 1 && e?.weight > 1
																							? `quantity ${e?.quantity} .. weight ${e?.weight}`
																							: ''}
																					</span>
																				</>
																			}
																			secondary={
																				<>
																					{e?.orderItemSetMenuOption?.length !==
																					0
																						? e?.orderItemSetMenuOption?.map(
																								(getitems, index) =>
																									getitems?.orderItemSetMenuOptionItem?.map(
																										optionitem => (
																											<Grid
																												style={{
																													display: 'flex',
																													flexWrap: 'wrap',
																													flexDirection: 'row',
																												}}
																												container
																												item
																												xs={7}
																											>
																												<div className="mdDesc text-noflow ">
																													{
																														optionitem
																															?.setMenuOptionItem
																															?.menuItem?.name
																													}
																												</div>
																											</Grid>
																										),
																									),
																						  )
																						: e?.orderItemOption?.map(
																								(getitems, index) =>
																									getitems?.orderItemOptionItem?.map(
																										optionitem => (
																											<Grid
																												style={{
																													display: 'flex',
																													flexWrap: 'wrap',
																													flexDirection: 'row',
																												}}
																												container
																												item
																												xs={7}
																											>
																												<div className="mdDesc text-noflow">
																													{
																														optionitem
																															?.optionItem?.name
																													}
																												</div>
																											</Grid>
																										),
																									),
																						  )}
																					<div className="mdDesc  flex-space text-noflow">
																						{e?.specialRequest
																							? `Remarks : ${e?.specialRequest}`
																							: 'No Remarks'}
																					</div>
																				</>
																			}
																		/>
																	</ListItem>
																	{e?.isTakeaway === true &&
																	e?.kitchenStatus === KitchenStatus.Pending ? (
																		<ListItemSecondaryAction
																			className="revert-icon"
																			style={{ margin: '10px 0px' }}
																		>
																			<Button
																				key={i}
																				style={{
																					backgroundColor: '#ff9800',
																					color: 'white',
																					minWidth: '0px',
																					paddingRight: '0px',
																					height: '23px',
																					width: '23px',
																				}}
																				disabled
																				size="small"
																				startIcon={
																					<LocalMallIcon
																						style={{ marginLeft: '5px' }}
																					/>
																				}
																			/>
																		</ListItemSecondaryAction>
																	) : (
																		
																			<ListItemSecondaryAction
																				className="revert-icon"
																				style={{ margin: '10px 0px' }}
																			>
																				<Button
																					key={i}
																					onClick={() => {
																						updateKitchenStatus({
																							variables: {
																								InputItemID: e?.ID,
																							},
																							refetchQueries: [
																								{
																									query: GetKitchenOrderDocument,
																									variables: {
																										outletID: outletID,
																										kitchenPrinterID: kitchenPrinterID,
																									},
																								},
																							],
																						});
																					}}
																					disabled={handlePermDisabled({
																						outletID: outletID,
																						permEnum:
																							PosPermission.KitchenUpdateOrderItemUpdate,
																					})}
																					style={{
																						backgroundColor: '#ff9800',
																						color: 'white',
																						minWidth: '0px',
																						paddingRight: '0px',
																						paddingLeft: '8px',
																						height: '30px',
																						width: '30px',
																					}}
																					size="small"
																					startIcon={<ReplayIcon />}
																				/>
																			</ListItemSecondaryAction>
																		// )
																	)}
																</List>
																{pendingItem(
																	filterItem(v?.kitchenOrderItems, v),
																)?.length > 1 &&
																i !==
																	pendingItem(
																		filterItem(v?.kitchenOrderItems, v),
																	)?.length -
																		1 ? (
																	<Divider
																		style={{
																			display: 'flex',
																			width: '100%',
																			backgroundColor: 'white',
																		}}
																	/>
																) : null}
															</>
														))}

														{grey_divider(
															confirmedItem(filterItem(v?.kitchenOrderItems, v))
																?.length,
															'Prepared',
														)}
														{confirmedItem(
															filterItem(v?.kitchenOrderItems, v),
														)?.map((e, i) => (
															<>
																<List
																	className="custom-list"
																	key={i}
																	style={{
																		background:
																			e?.kitchenStatus === 'PREPARED'
																				? 'honeydew'
																				: null,
																		// borderStyle: 'inset',
																		// borderWidth: 'thin',
																	}}
																>
																	<ListItem className="remove-padding" key={i}>
																		<img
																			src={e?.menuItem?.attachments?.fileURL}
																			className="kitchen-img-owned"
																			height="80px"
																			width="80px"
																		/>
																		<ListItemText
																			className="custom-text-list"
																			primary={
																				<>
																					<span
																						className="xsTitle content-wrap text-noflow"
																						// style={{
																						// 	marginBottom: '5px',
																						// 	marginTop: '10px',
																						// }}
																					>
																						{`${
																							e?.openItemName
																								? e?.openItemName
																								: e?.menuItem?.name
																						}  `}
																					</span>

																					<span
																						className="mdDesc content-wrap text-noflow"
																						style={{ color: '#ff9800' }}
																					>
																						{e?.quantity === 1 &&
																						e?.weight !== 1
																							? `${e?.weight}g`
																							: e?.quantity > 1 &&
																							  e?.weight === 1
																							? `x  ${e?.quantity}`
																							: e?.quantity > 1 && e?.weight > 1
																							? `quantity ${e?.quantity} .. weight ${e?.weight}`
																							: ''}
																					</span>
																				</>
																			}
																			secondary={
																				<>
																					{e?.orderItemSetMenuOption?.length !==
																					0
																						? e?.orderItemSetMenuOption?.map(
																								(getitems, index) =>
																									getitems?.orderItemSetMenuOptionItem?.map(
																										optionitem => (
																											<Grid
																												style={{
																													display: 'flex',
																													flexWrap: 'wrap',
																													flexDirection: 'row',
																												}}
																												container
																												item
																												xs={5}
																											>
																												<div className="mdDesc text-noflow">
																													{
																														optionitem
																															?.setMenuOptionItem
																															?.menuItem?.name
																													}
																												</div>
																											</Grid>
																										),
																									),
																						  )
																						: e?.orderItemOption?.map(
																								(getitems, index) =>
																									getitems?.orderItemOptionItem?.map(
																										optionitem => (
																											<Grid
																												style={{
																													display: 'flex',
																													flexWrap: 'wrap',
																													flexDirection: 'row',
																												}}
																												container
																												item
																												xs={5}
																											>
																												<div className="mdDesc text-noflow">
																													{
																														optionitem
																															?.optionItem?.name
																													}
																												</div>
																											</Grid>
																										),
																									),
																						  )}
																					<div className="mdDesc  flex-space text-noflow">
																						{e?.specialRequest
																							? e?.specialRequest
																							: 'No Remarks'}
																					</div>
																				</>
																			}
																		/>
																	</ListItem>
																	<ListItemSecondaryAction className="revert-icon">
																		<Button
																			key={i}
																			onClick={() => {
																				revertKitchenStatus({
																					variables: {
																						InputItemID: e?.ID,
																					},
																					refetchQueries: [
																						{
																							query: GetKitchenOrderDocument,
																							variables: {
																								outletID: outletID,
																								kitchenPrinterID: kitchenPrinterID,
																							},
																						},
																					],
																				});
																			}}
																			style={{
																				backgroundColor: '#ff9800',
																				color: 'white',
																				minWidth: '0px',
																				paddingRight: '0px',
																				paddingLeft: '8px',
																				height: '30px',
																				width: '30px',
																			}}
																			size="small"
																			startIcon={<ReplayIcon />}
																		/>
																	</ListItemSecondaryAction>
																</List>
																{confirmedItem(
																	filterItem(v?.kitchenOrderItems, v),
																)?.length > 1 &&
																i !==
																	confirmedItem(
																		filterItem(v?.kitchenOrderItems, v),
																	)?.length -
																		1 ? (
																	<Divider
																		style={{
																			display: 'flex',
																			width: '100%',
																			backgroundColor: 'white',
																		}}
																	/>
																) : null}
															</>
														))}
													</div>
												</div>
											</>
										</CardContentsKitchen>
									</ContentWrapper>

									<Box className="cardContent-footer">
										<Button
											color={checkStatus ? 'primary' : 'default'}
											variant="contained"
											disabled={
												handlePermDisabled({
													outletID: outletID,
													permEnum: PosPermission.KitchenCloseOrderUpdate,
												}) || checkStatus
													? false
													: true
											}
											onClick={() => {
												setOpenDialog({
													dialogState: true,
													data: v,
												});
											}}
										>
											Close Order
										</Button>
									</Box>
								</div>
							))}
						</Slider>
					)}
				</div>
			)}
			<CommonDialog
				fullWidth={true}
				open={dataState.dialogState}
				onClose={() =>
					setOpenDialog({ dialogState: false, data: dataState.data })
				}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title">Kitchen Status</div>
									</div>
								</div>
							</div>
						),
					},
					// {
					// 	component: () => (
					// 		<span className="title text-noFlow">Kitchen Status</span>
					// 	),
					// },

					body: () => (
						<>
							<div className="mdDesc">Confirm complete order?</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenDialog({
											dialogState: false,
											data: dataState.data,
										});
									},
									color: 'secondary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setConfirm(true);
									},
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
