import theme from '@ifca-root/react-component/src/assets/theme';
import GLJournalSubHeader from '@ifca-root/react-component/src/components/Header/GLJournalSubheader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import { Box, Divider, Grid, useMediaQuery } from '@material-ui/core';
import Accordion from '@material-ui/core/ExpansionPanel';
import { AccordionDetails } from '@material-ui/core';
import AccordionSummary from '@material-ui/core/ExpansionPanelSummary';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import ShareIcon from '@material-ui/icons/Share';
import { Auditor } from 'assets';
import ShareDialog from 'components/Dialog/ShareDialog';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import {
	useGetExportedCbDetailsQuery,
	useGetOutletQuery,
	useGetShareAttachmentMutation,
	useMenuAccountXListQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import * as htmlToImage from 'html-to-image';
import React, { Reducer, useEffect, useReducer, useRef, useState } from 'react';
import { CSVLink } from 'react-csv';
import { useHistory, useLocation } from 'react-router';
import '../../AccountJournalsModule/GeneralLedgerJournal/generalLedger.scss';
import * as _ from 'underscore';
import { IAction } from 'helpers/model';
import { amtStr } from 'helpers/numFormatter';

interface Props {
  Dialog?: boolean
  ImageUrl?: string
  CBDetailsData?: any
}


export const CashBookTransactionDetail = () => {
	useEffect(() => {
		console.log('CashBookTransactionDetail');
	}, []);

	const history = useHistory();
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const outletID = localStorage.getItem('latestOutlet');
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const { state: detailState }: any = useLocation();
	const [cbExportList, setCBExportList] = useState(null);

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);

	var dateFormat = require('dateformat');
	const initialState: Props = {
		Dialog: false,
		ImageUrl: '',
		CBDetailsData: [],
	}

	const reducer: Reducer<Props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState
			default:
				return { ...state, [action.type]: action.payload }
		}
	}
  const [state, dispatch] = useReducer(reducer, initialState)	
	
	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	
		const {
		loading: MenuAccountXListLoading,
		data: { MenuAccountXList } = {
			MenuAccountXList: {
			ID: '',
			outletID: '',
			vendorName: '',
			isActive: true,
		  }
		},
	  } = useMenuAccountXListQuery({
		variables: {
		  outletID: JSON.parse(localStorage.getItem('outlet'))?.ID,
		},
		fetchPolicy: 'no-cache',
	  })

	const {
		loading: cbExportDetailLoading,
		data: {getExportedCBDetails} = { getExportedCBDetails: [] },
	} = useGetExportedCbDetailsQuery({
		fetchPolicy: 'no-cache',
		variables: {
			batchNo: Number(detailState.batchNo),
			outletID: outletID,
		},

	});

	useEffect(() => {
    if (getExportedCBDetails?.length > 0)
      dispatch({
        type: 'CBDetailsData',
        payload: getExportedCBDetails,
      })
  }, [getExportedCBDetails])
	const CBDetailsGroupBy = _.groupBy(state.CBDetailsData, x => x.cbType)
	const ExportCSVData =
		MenuAccountXList?.VendorName === 'AccountX'
			? getExportedCBDetails?.map(x => ({
					DocumentDate: `${dateFormat(x.cbDate, 'dd/mm/yyyy')}`,
					TransactionDate: `${dateFormat(x.trxDate, 'dd/mm/yyyy')}`,
					BankCode: x.bankCode,
					BankAccountNo: x.accountNo,
					LedgerType: 'MX',
					DocumentNo: x.billNo,
					RefNo: x.referenceNo,
					PayeeReceiverName: x.guestName,
					Description: x.description,
					DocumentAmt: Number(x.amount).toFixed(2),						
			  }))
			: getExportedCBDetails.map(x => ({
					DocDate: `${dateFormat(x.trxDate, 'dd/mm/yyyy')}`,
					CBDate: `${dateFormat(x.cbDate, 'dd/mm/yyyy')}`,
					Submenu: 'MX',
					CBType: x.cbType,
					BankCode: x.bankCode,
					AccountCode: x.accountCode,
					Description: x.description,
					ReferenceNo: x.referenceNo,
					Amount: Number(x.amount).toFixed(2),
					RoomNo: '',
					GuestName: '',
					DocNo: x.billNo,
					BookingNo: '',
					Module: 'MX',
			  }));

	const CBDetailsList = array => {
    let keys = Object.keys(array)
    let values = Object.values(array)
    let map = new Map()
    for (var i = 0; i < keys.length; i++) {
      map.set(keys[i], values[i])
    }
    return [...map].map(([cbType, data]) => ({ cbType, data }))
  }

	const totalAmount = getExportedCBDetails?.reduce(
		(prev, curr) => {
			return prev + Number(curr.amount);
		},
		0,
	);

	const csvRef = useRef(null);

	const handleGlExport = async () => {
		csvRef.current.link.click();
	};

	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const handleShare = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: outletID,
			},
		});
	};

	const Display = (hidden?: boolean) => {
		return (
			<div
				style={
					hidden
						? {
								zIndex: -40,
								top: 0,
								left: 0,
								position: 'fixed',
								width: '100%',
						  }
						: {
								marginTop: '0px',
								zIndex: 40,
								position: 'relative',
						  }
				}
				className={hidden ? 'hidden-content' : null}
			>
				<div
					id={!hidden ? 'gl ' : 'hiddenCB'}
					style={
						hidden
							? { marginTop: '-85px', width: '100%', height: '100%' }
							: { width: '100%', height: '100%' }
					}
				>
					<ContentWrapper
						style={{
							marginTop: isDesktop ? '152px' : '86px',
						}}
					>
						{CBDetailsList(CBDetailsGroupBy)?.map((cb, index) => (
							<Accordion
								className="accordion-card"
								defaultExpanded={hidden ? true : false}
								key={index}
							>
								<AccordionSummary
									expandIcon={<KeyboardArrowDown />}
									aria-controls="panel1a-content"
									id="panel1a-header"
								>
									<Grid container justify="flex-start">
										<Grid
											item
											xs={6}
											className="xsTitle"
											style={{
												whiteSpace: 'normal',
												padding: '0px 6px',
											}}
										>
											{cb.cbType}
										</Grid>

										<Grid
											item
											xs={3}
											className="desc"
											style={{ display: 'flex', justifyContent: 'center' }}
										></Grid>
										<Grid
											item
											xs={3}
											className="desc"
											style={{ display: 'flex', justifyContent: 'center' }}
										>
											{amtStr(totalAmount)}
										</Grid>
									</Grid>
								</AccordionSummary>
								<AccordionDetails>
									{cb?.data?.sort(
										(a,b) => Number(new Date(a.trxDate)) - Number(new Date(b.trxDate))
									)?.map((trx, trxIndex) => (
										<>
											<Grid
												container
												justify="flex-start"
												className="desc"
												style={{ width: '90%', padding: '4px' }}
											>
												<Grid item xs={3} className="desc">
													{formatDate(trx?.trxDate)}
												</Grid>

												<Grid item xs={5} className="desc">
													{trx?.bankCode}
												</Grid>
												<Grid
													item
													xs={2}
													className="desc "
													style={{
														display: 'flex',
														justifyContent: 'center',
													}}
												>
													{/* {y?.debit?.toFixed(2)} */}
												</Grid>
												<Grid
													item
													xs={2}
													className="desc "
													style={{
														display: 'flex',
														justifyContent: 'center',
													}}
												>
													{amtStr(trx.amount)}
												</Grid>
												<Grid item xs={3} style={{ paddingTop: '6px' }}></Grid>
												<Grid
													item
													xs={9}
													className="desc "
													style={{
														paddingTop: '6px',
													}}
												>
													{trx?.cbType}
												</Grid>
											</Grid>
											<Box
												width="10%"
												display="flex"
												justifyContent="center"
												margin="auto"
											>
												<KeyboardArrowRight
													style={{ width: '18px', height: '18px' }}
													onClick={() => {
														history.push({
															pathname:
																'/menu/outlet-app/account-journals/cb-transaction/detail/individual',
															state: trx,
														});
													}}
												/>
											</Box>
											<Divider variant="fullWidth" className="flex" />
										</>
									))}
								</AccordionDetails>
							</Accordion>
						))}
					</ContentWrapper>
				</div>
			</div>
		);
	};

	return (
		<>
			{outletLoading || MenuAccountXListLoading && <Loading />}
			{cbExportDetailLoading && <Loading />}

			<MainHeader
				mainBtn="back"
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/account-journals/cb-transaction`,
					});
				}}
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Account Journals' },
					{ name: 'CB Transaction' },
					{ name: 'CB Transaction', current: true },
				]}
			/>
			<GLJournalSubHeader
				style={{ top: '63px' }}
				title={
					<span className="xsTitle" style={{ color: 'orange' }}>
						{formatDate(detailState.startDate)} -{' '}
						{formatDate(detailState.endDate)}
					</span>
				}
				infoLine={
					<>
						<div>
							<span className="desc icon-text">
								<span style={{ color: '#ff9800' }}>
									{detailState.transactionCount}
								</span>
								&nbsp; transactions <TextSeparator />
								<img
									src={Auditor}
									alt="ifca"
									width={12}
									loading="lazy"
									style={{ paddingRight: '4px' }}
								/>
								<span style={{ color: '#ff9800' }}>
									{formatDate(detailState.cbDate)}{' '}
								</span>
							</span>
						</div>
						<div style={{ marginTop: '20px' }}>
							<Grid
								container
								direction="row"
								justifyContent="space-between"
								spacing={2}
							>
								<Grid
									container
									direction="row"
									justifyContent="space-between"
									alignItems="center"
									style={{
										paddingRight: '12px',
										paddingLeft: '12px',
									}}
								>
									<span className="xsTitle" style={{ paddingTop: '5px' }}>
										<span style={{ color: 'black' }}>
											{' '}
											Σ {amtStr(totalAmount)}
										</span>
									</span>
								</Grid>
							</Grid>
						</div>
					</>
				}
			/>

			{Display()}
			{Display(true)}
			<div className="cover-layer" />


			<CSVLink
				filename={`cb_Export_${formatDate(detailState.createdTs)}.csv`}
				data={ExportCSVData}
				ref={csvRef}
			/>


			{ShareDialog({
				shareTitle: `CB Export - ${formatDate(detailState.createdTs)}`,
				// shareTitle: `GL Export -`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}

			<DynamicFooter
				options={[
					// {
					// 	name: 'Share',
					// 	onClick: () => {
					// 		let temp = document.getElementById('hiddenCB');
					// 		htmlToImage
					// 			.toBlob(temp, { style: { background: '#fff' } })
					// 			.then(value => {
					// 				handleShare(value);
					// 			})
					// 			.catch(error => console.error(error, 'Something Went Wrong'));
					// 	},
					// 	color: 'primary',
					// 	startIcon: <ShareIcon />,
					// },
					{
						name: 'Download',
						onClick: () => handleGlExport(),
						color: 'primary',
						startIcon: <ShareIcon />,
					},
				]}
			/>
		</>
	);
};
