import { yupResolver } from '@hookform/resolvers';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { Switch } from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetStaffDocument,
	useCreateStaffMutation,
	useUpdateStaffMutation,
} from 'generated/graphql';
import { IAction } from 'helpers/model';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { Reducer, useContext, useReducer, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { PersonDetailForm } from '../GenSetGenericComp/PersonDetailForm';

interface AuthorisedStaffFormProps {
	name: string;
	designation: string;
	mobileNo: string;
	email: string;
}

interface AuthorisedStaffProps {
	isActive: boolean;
}

export const AuthorisedStaffForm = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	// const [user] = useAtom(userAtom);
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const [openExitDialog, setOpenExitDialog] = useState(false);
	const { mode } = props;
	const { staffID, mode: pageMode }: any = useParams();

	let location = useLocation();
	let editData: any = location?.state;

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const yupSchema = yup.object().shape({
		name: CommonYupValidation.requireField(SystemMsgs.name()),
		designation: CommonYupValidation.requireField(SystemMsgs.designation()),
		mobileNo: CommonYupValidation.requireField(SystemMsgs.mobileNo()),
		email: CommonYupValidation.requireField(SystemMsgs.email()),
	});

	const { control, register, handleSubmit, errors } = useForm<
		AuthorisedStaffFormProps
	>({
		defaultValues: {
			name: editData?.name,
			designation: editData?.designation,
			mobileNo: editData?.mobileNo,
			email: editData?.email,
		},
		mode: 'all',
		resolver: yupResolver(yupSchema),
	});

	const [
		createStaff,
		{ loading, error, called: createCalled },
	] = useCreateStaffMutation({
		onError: error => {
			console.log('ERROR', error);
			dispatch({
				type: 'message',
				payload:
					// error?.graphQLErrors[0]?.extensions?.exception?.validationErrors[0]
					//   ?.constraints?.IsEmailAlreadyExistConstraint,
					'Something went wrong. Please try again',
			});
			dispatch({
				type: 'alert',
				payload: true,
			});
		},
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			history.push({
				pathname: `/menu/outlet-app/general-setting/employee`,
				state: {
					success: true,
					msgMode: 'create',
				},
			});
		},
	});

	const [
		updateStaff,
		{
			loading: mutationUpdateLoading,
			error: updateError,
			called: updateCalled,
		},
	] = useUpdateStaffMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			// history.push(`/${pageMode}/general-setting/access-security/user`);

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/general-setting/employee`,
					state: {
						success: true,
						msgMode: 'update',
					},
				});
			}, 500);
		},
	});

	const handleMutationType = mode === 'add' ? createStaff : updateStaff;

	const initialState: AuthorisedStaffProps = {
		isActive: mode === 'add' ? false : editData?.isActive,
	};

	const reducer: Reducer<AuthorisedStaffProps, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const onSubmit = (data: any) => {
		const input: any = {
			name: data.name,
			designation: data.designation,
			mobileNo: data.mobileNo,
			email: data.email,
			isActive: state.isActive,
		};

		const inputFinal: any =
			mode === ' add' ? input : Object.assign({ ID: staffID }, input);

		//adding from graphql mutation
		handleMutationType({
			variables: {
				input: inputFinal,
			},
			refetchQueries: [
				{
					query: GetStaffDocument,
					variables: {
						name: data.name,
					},
				},
			],
		});
	};
	return (
		<>
			{/* {loading && <Loading />} */}
			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="back"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Settings' },
					{ name: 'Authorised Staff', current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>
			<PersonDetailForm control={control} register={register} errors={errors}>
				<div
					className="content-wrap full"
					style={{ justifyContent: 'space-between', display: 'flex' }}
				>
					<span className="flex-space">Active</span>
					<Switch
						checked={state.isActive}
						onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
							dispatch({
								type: 'isActive',
								payload: event.target.checked,
							});
						}}
						name="isActive"
						color="primary"
					/>
				</div>
			</PersonDetailForm>
			<DynamicFooter
				options={[
					{
						name: 'Save',
						onClick: () => {
							handleSubmit(onSubmit)();
							// if (mode === 'edit') {
							// 	history.push({
							// 		pathname: __OUTLET_APP__GENERAL_SETTING__EMPLOYEE,
							// 	});
							// }
						},

						color: 'primary',
						props: {
							type: 'submit',
						},
					},
				]}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/general-setting/employee`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push({
											pathname: `/menu/outlet-app/general-setting/employee`,
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
