import { useState } from 'react'

function useUploadAttachment() {
  const [previewFiles, setPreviewFiles] = useState([])
  const [files, setFiles] = useState([])

  const handleUploadChange = e => {
    const fileObj = []
    const fileArray = []
    const fileToPush = []

    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
      // fileToPush.push(e.target.files.item(i))
      fileToPush.push(fileObj[0][i])
    }

    const newPreview = [...previewFiles, ...fileArray]
    const newFiles = [...files, ...fileToPush]
    setPreviewFiles([...newPreview])
    setFiles([...newFiles])
  }

  const handleSingleUploadChange = e => {
    let fileObj = []
    let fileArray = []
    let fileToPush = []

    fileObj = [e.target.files]
    // for (let i = 0; i < fileObj[0].length; i++) {
    fileArray = [URL.createObjectURL(fileObj[0][0])]
    // fileToPush.push(e.target.files.item(i))
    fileToPush = [fileObj[0][0]]

    const newPreview = [...fileArray]
    const newFiles = [...fileToPush]
    setPreviewFiles([...newPreview])
    setFiles([...newFiles])
  }
  //Remove img preview
  const remove = index => {
    setPreviewFiles(prevIndexes => [
      ...prevIndexes.filter((item, i) => i !== index),
    ])
    const newFiles = [...Array.from(files)]
    newFiles.splice(index, 1)
    setFiles([...newFiles])
  }

  const handleEditUpload = async (file: any) => {
    const prevFile = []
    //start (new)
    const newFiles = await Promise.all(
      file.map(async (x, i) => {
        const data = await fetch(x.fileURL)
          .then(res => res.blob())
          .then(file => new File([file], x.fileName, { type: x?.mediaType }))
        prevFile.push(data)
      })
    )
    // await Promise.all(
    //   file.map(async (x, i) => {
    //     let checkAzureDoc = x?.fileURL?.includes('contract365documents')
    //     const data = await fetch(
    //       x.fileURL,
    //       checkAzureDoc ? {} : { mode: 'no-cors' }
    //     )
    //       .then(res => res.blob())
    //       .then(file => new File([file], x.fileName, { type: x?.mediaType }))
    //       .catch(err => {
    //         console.log('ERROR IN FILES: ', err)
    //       })
    //     prevFile.push(data)
    //   })
    // )
    //end
    setFiles([...prevFile])
  }

  //// one with mapping files to ID or anything (string)

  const [fileMap, setFileMap] = useState({})
  const [previewMap, setPreviewMap] = useState({})

  const handleUploadMapping = (e, dataToMap: any) => {
    const fileObj = []
    const fileArray = []
    const fileToPush = []

    fileObj.push(e.target.files)
    for (let i = 0; i < fileObj[0].length; i++) {
      fileArray.push(URL.createObjectURL(fileObj[0][i]))
      fileToPush.push(e.target.files.item(i))
    }
    const prevFile: any[] = fileMap[dataToMap] ? fileMap[dataToMap] : []
    const prevPreview: any[] = previewMap[dataToMap]
      ? previewMap[dataToMap]
      : []

    previewMap[dataToMap] = [...prevPreview, ...fileArray]
    fileMap[dataToMap] = [...prevFile, ...fileToPush]

    setPreviewMap({ ...previewMap })
    setFileMap({ ...fileMap })
    return { preview: previewMap, file: fileMap }
  }

  const handleSingleUploadMapping = (e, dataToMap: any) => {
    let fileObj = []
    let fileArray = []
    let fileToPush = []

    fileObj = [e.target.files]
    fileArray = [URL.createObjectURL(fileObj[0][0])]
    fileToPush = [e.target.files.item(0)]

    previewMap[dataToMap] = [...fileArray]
    fileMap[dataToMap] = [...fileToPush]

    setPreviewMap({ ...previewMap })
    setFileMap({ ...fileMap })
    return { preview: previewMap, file: fileMap }
  }

  const handleEditUploadMap = async (file: any, mappedData) => {
    let prevFile = []
    //start (new)
    const newFiles = await Promise.all(
      file.map(async (x, i) => {
        const data = await fetch(x.fileURL)
          .then(res => res.blob())
          .then(file => new File([file], x.fileName, { type: x?.mediaType }))
        prevFile.unshift(data) // using unshift to ensure the order of the file remains the same
      })
    )
    // await Promise.all(
    //   file.map(async (x, i) => {
    //     let checkAzureDoc = x?.fileURL?.includes('contract365documents')
    //     const data = await fetch(
    //       x.fileURL,
    //       checkAzureDoc ? {} : { mode: 'no-cors' }
    //     )
    //       .then(res => res.blob())
    //       .then(file => new File([file], x.fileName, { type: x?.mediaType }))
    //       .catch(err => {
    //         console.log('ERROR IN FILES: ', err)
    //       })
    //     prevFile.unshift(data) // using unshift to ensure the order of the file remains the same
    //   })
    // )
    //end
    fileMap[mappedData] = []
    fileMap[mappedData] = [...prevFile]

    setFileMap({ ...fileMap })
    return fileMap[mappedData]
  }

  const removeMappedFiles = (mappedData, index) => {
    previewMap[mappedData] = previewMap[mappedData]?.filter(
      (v, i) => i !== index
    )
    fileMap[mappedData] = fileMap[mappedData]?.filter((v, i) => i !== index)

    setFileMap({ ...fileMap })
    return { preview: previewMap, file: fileMap }
  }

  // UPLOAD ONE ATTACHMENT ONLY

  const handleSingleUpload = e => {
    let fileObj = [e.target.files[0]]
    let fileArray = null

    fileArray = [URL.createObjectURL(fileObj[0])]
    const newPreview = [fileArray[0]]
    const newFiles = [fileObj[0]]
    setPreviewFiles([...newPreview])
    setFiles([...newFiles])
  }
  //Remove img preview
  const removeSingleUpload = index => {
    setPreviewFiles([])
    setFiles([])
  }

  const handleEditSingleUpload = async (file: any) => {
    let prevFile = []
    // let checkAzureDoc = file[0]?.fileURL?.includes('contract365documents')
    //start (new)
    if (file[0]) {
      const newFiles = await fetch(file[0].fileURL)
        .then(res => res.blob())
        .then(x => new File([x], file[0].fileName, { type: file[0].mediaType }))
      prevFile = [newFiles]
    }
    // if (file[0]) {
    //   const newFiles = await fetch(
    //     file[0].fileURL,
    //     checkAzureDoc ? {} : { mode: 'no-cors' }
    //   )
    //     .then(res => res.blob())
    //     .then(x => new File([x], file[0].fileName, { type: file[0].mediaType }))
    //     .catch(err => {
    //       console.log('ERROR IN FILES: ', err)
    //     })
    //   prevFile = [newFiles]
    // }

    //end
    setFiles([...prevFile])
  }

  return {
    remove,
    files,
    setFiles,
    previewFiles,
    setPreviewFiles,
    handleUploadChange,
    handleEditUpload,

    // mapping part
    fileMap,
    previewMap,
    handleUploadMapping,
    handleSingleUploadMapping,
    removeMappedFiles,
    handleEditUploadMap,

    //single file upload
    handleSingleUpload,
    handleEditSingleUpload,
    removeSingleUpload,
  }
}

export default useUploadAttachment
