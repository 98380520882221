import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	TextField,
} from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetRoundingPolicyDocument,
	MethodType,
	PosPermission,
	TransactionType,
	useGetRoundingPolicyQuery,
	useSubmitRoundingPolicyMutation,
} from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { ChangeEvent, useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation } from 'react-router-dom';

export interface RoundingPolicyProps {
	transactionType: string;
	methodType: string;
	smallestDenomination: number;
	inUse: boolean;
}

export const RoundingPolicyForm = (props: any) => {
	const history = useHistory();
	const location = useLocation();

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const outletID = localStorage.getItem('latestOutlet');

	const { handlePermDisabled } = usePermissionChecker();
	const [openExitDialog, setOpenExitDialog] = useState(false);
	const [inUse, setInUse] = useState(true);

	const {
		data: { getRoundingPolicy } = { getRoundingPolicy: [] },
		loading: getRoundingPolicyLoading,
		called: getRoundingPolicyCalled,
	} = useGetRoundingPolicyQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
		onCompleted: () => {
			setInUse(getRoundingPolicy[0]?.inUse);
		},
	});

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [submitRoundingPolicy] = useSubmitRoundingPolicyMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			history.push({
				pathname: `/menu/outlet-app/general-setting`,
				state: { success: true, msgMode: 'create' },
			});
			setInUse(getRoundingPolicy[0]?.inUse);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const rememberme = JSON.parse(localStorage.getItem('rememberme'));

	const roundingPolicy: any = getRoundingPolicy;

	const { control, register, handleSubmit, errors, watch } = useForm<
		RoundingPolicyProps
	>({
		defaultValues: {
			transactionType:
				getRoundingPolicy && getRoundingPolicy[0]?.transactionType,
			methodType: getRoundingPolicy && getRoundingPolicy[0]?.methodType,
			smallestDenomination:
				getRoundingPolicy && getRoundingPolicy[0]?.smallestDenomination,
			inUse: getRoundingPolicy[0]?.inUse,
		},
	});

	const onSubmit = (data: any) => {
		submitRoundingPolicy({
			variables: {
				input: {
					// ID: menu?.ID,
					inUse: inUse,
					transactionType: TransactionType?.Round,
					methodType: MethodType?.Off,
					smallestDenomination: parseFloat(data?.smallestDenomination),
				},
			},
			refetchQueries: [
				{
					query: GetRoundingPolicyDocument,
					variables: { accountID: user?.accountID },
				},
			],
		});
	};

	const handleIsActiveCheckboxChange = (
		event: ChangeEvent<HTMLInputElement>,
	) => {
		setInUse(!inUse);
	};

	return (
		<>
			{getRoundingPolicyLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="back"
				// smTitle={`Izzat Rushaidhi (HARDCODE)`}
				// title={`IFCA Kitchen (HARDCODE)`}
				// smTitle={rememberme?.username}
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Setting' },
					{ name: 'Rounding Policy', current: true },
				]}
				rightRouteSegments={[{ name: 'Edit' }]}
			/>
			<ContentWrapper>
				{!getRoundingPolicyLoading && (
					<CardContents devFullWidth={true}>
						<Grid container>
							<Grid item xs={4} className="right">
								<FormControl component="fieldset">
									{/* <FormLabel component="legend"> Nominated Subcontract</FormLabel> */}
									<Controller
										as={
											<FormControlLabel
												control={
													<Checkbox
														checked={inUse}
														onChange={handleIsActiveCheckboxChange}
														name="inUse"
														color="primary"
													/>
												}
												label="In Use"
											/>
										}
										name="inUse"
										control={control}
									/>
								</FormControl>
							</Grid>
						</Grid>

						<Controller
							as={TextField}
							name="transactionType"
							defaultValue={TransactionType?.Round}
							disabled
							label="Transaction Type"
							autoComplete="off"
							control={control}
							multiline={true}
							fullWidth
							ref={register}
						/>
						<Controller
							as={TextField}
							name="methodType"
							label="Method"
							defaultValue={MethodType?.Off}
							disabled
							// required
							autoComplete="off"
							control={control}
							multiline={true}
							fullWidth
							ref={register}
							// className="left"
							// defaultValue={menu?.obj?.designation || ''}
						/>

						<Controller
							as={
								<NumberFormat
									customInput={TextField}
									allowNegative={false}
									fullWidth
									autoComplete="off"
									isNumericString
									decimalScale={2}
									fixedDecimalScale
									// ref={register}
									onValueChange={(value: any) => {
										const { floatValue } = value;
									}}
								/>
							}
							name="smallestDenomination"
							label="Smallest Denomination"
							control={control}
							ref={register}
							defaultValue={getRoundingPolicy[0]?.smallestDenomination}

							// helperText={errors?.claimRunNum?.message}
							// error={errors?.claimRunNum ? true : false}
						/>
					</CardContents>
				)}
			</ContentWrapper>
			<DynamicFooter
				options={[
					{
						name: 'Save',
						onClick: () => {
							handleSubmit(onSubmit)();
						},
						disabled: handlePermDisabled({
							outletID: outletID,
							permEnum: PosPermission.GeneralSettingRoundingPolicyUpdate,
						}),
						color: 'primary',
						props: {
							type: 'submit',
						},
					},
				]}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/general-setting`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push({
											pathname: `/menu/outlet-app/general-setting`,
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
