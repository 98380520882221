export const renderRevenueDefaultValue = (descriptionType, column, outlet) => {
	if (descriptionType === 'Major') { // || descriptionType === 'DiscountType'
		return `P&L ${column} - ${
			column === 'Division' ? 'F&B' : `${outlet?.name}`
		}`;
	} else {
		return `Balance Sheet ${
			column === 'Division' ? 'Division' : '- Department'
		}`;
	}
};

export const renderDebitCreditDefaultValue = description => {
	if (description === 'CASH') {
		return 'Cash';
	} else if (description === 'CREDIT' || description === 'ONLINE') {
		return `AR Clearing / ${description} Clearing`;
	} else {
		return 'Outlet Control Account';
	}
};

export const renderChargesTypeDefaultValue = description => {
	if (description === 'CASH' || description === 'ONLINE') {
		return `Bank Code`;
	} else if (description === 'CREDIT') {
		return 'City Ledger';
	} else {
		return 'GL';
	}
};

export const renderProperCase = (description, descriptionType) => {
	// if (descriptionType !== 'DiscountType') {
	// 	let wSpace = description?.replace(/_/g, ' ');
	// 	return wSpace?.replace(/\w\S*/g, function(txt) {
	// 		return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
	// 	});
	// } else {
	// 	return description;
	// }
	let wSpace = description?.replace(/_/g, ' ');
	return wSpace?.replace(/\w\S*/g, function(txt) {
		return txt?.charAt(0)?.toUpperCase() + txt?.substr(1)?.toLowerCase();
	});
};

export const renderTypePaymentDefaultValue = description => {
	let output;
	switch (description) {
		case 'Online Payment':
		case 'Cash':
		case 'Bank TT':
		case 'Cheque':
			output = 'Bank Code';
			break;
		default:
			return 'GL';
	}
	return output;
}