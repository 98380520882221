import React from 'react';

interface MultiLineProps {
	rightText: string;
	title: string;
	subtitle: string;
	rightSubtitle: string;
	style: React.CSSProperties;
}

export const MutliLineSubheader = (props: any) => {
	const { rightText, title, subtitle, rightSubtitle, style } = props;
	return (
		<div className="multiLine-subheader-container" style={{ ...style }}>
			<div className="multiline-subheader-content">
				<div className="session">
					<div className="flex-space">{title}</div>
					<div>{rightText}</div>
				</div>
			</div>
			<div>
				<div className="flex-space">{subtitle}</div>
				<div>{rightSubtitle}</div>
			</div>
		</div>
	);
};
