import { gql } from 'apollo-boost';

// export const updateAccount = gql`
// 	mutation updateAccount($input: AccountInput!) {
// 		updateAccount(input: $input) {
// 			ID
// 		}
// 	}
// `;

export const GET_ACCOUNT_DETAIL = gql`
	query getAccountDetail($accountID: String!) {
		getAccountDetail(accountID: $accountID) {
			name
			regNo
			code
			contactNo
			email
			currencyID
			currency {
				code
				name
			}
			address {
				address
				country
				city
				state
				postCode
				AddressType
			}
		}
	}
`;

export const GET_CURRENCY = gql`
	query getCurrency($ID: String, $orderByAsc: String, $orderByDesc: String) {
		getCurrency(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
			ID
			name
			code
			symbol
		}
	}
`;

export const COUNTRY = gql`
	query getCountry(
		$ID: String
		$name: String
		$orderByAsc: String
		$orderByDesc: String
	) {
		getCountry(
			ID: $ID
			name: $name
			orderByAsc: $orderByAsc
			orderByDesc: $orderByDesc
		) {
			ID
			name
			code
		}
	}
`;
export const STATE_BY_COUNTRY = gql`
	query getStatebyCountry($countryID: String!) {
		getStatebyCountry(countryID: $countryID) {
			name
			ID
		}
	}
`;
export const CITY_BY_STATE = gql`
	query getCitybyState($stateID: String!) {
		getCitybyState(stateID: $stateID) {
			ID
			name
		}
	}
`;
export const POSTCODE_BY_CITY = gql`
	query getPostcodebyCity($cityID: String!) {
		getPostcodebyCity(cityID: $cityID) {
			ID
			postcode
		}
	}
`;

export const GET_PACKAGE_BY_ACCOUNT = gql`
	query getPackageByAccount($softwareCode: String!, $accountID: String!) {
		getPackageByAccount(softwareCode: $softwareCode, accountID: $accountID)
	}
`;

export const GET_SUBSCRIPTION_ENTITY_CONTROL = gql`
	query subscribedUserAndEntityCount(
		$accountID: String!
		$softwareCode: SoftwareCode!
	) {
		subscribedUserAndEntityCount(
			accountID: $accountID
			softwareCode: $softwareCode
		) {
			ID
			noOfUser
			entityControl {
				noOfEntity
				noofExtraEntity
			}
		}
	}
`;
// export const GET_SUBSCRIPTION_ENTITY_CONTROL = gql`
// 	query subscribedUserAndEntityCount(
// 		$accountID: String!
// 		$softwareCode: SoftwareCode!
// 	) {
// 		subscribedUserAndEntityCount(
// 			accountID: $accountID
// 			softwareCode: $softwareCode
// 		) {
// 			ID
// 			entityControl
// 		}
// 	}
// `;

// export const HOME_POSX_ACCOUNT_FETCH = gql`
// 	query getAccountDetail($accountID: String!) {
// 		getAccountDetail(accountID: $accountID) {
// 			name
// 			regNo
// 			code
// 			contactNo
// 			email
// 			address {
// 				address
// 				country
// 				city
// 				state
// 				postCode
// 				AddressType
// 			}
// 		}
// 	}
// 	query getPackageByAccount($softwareCode: String!, $accountID: String!) {
// 		getPackageByAccount(softwareCode: $softwareCode, accountID: $accountID)
// 	}
// 	query getCurrency($ID: String, $orderByAsc: String, $orderByDesc: String) {
// 		getCurrency(ID: $ID, orderByAsc: $orderByAsc, orderByDesc: $orderByDesc) {
// 			ID
// 			name
// 			code
// 			symbol
// 		}
// 	}
// `;
