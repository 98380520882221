import React from 'react';
import { UOMConversionList } from './UOMConversionList';
import { UOMList } from './UOMList';

const uomRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/uom`,
		},
		component: <UOMList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/uom-exchange`,
		},
		component: <UOMConversionList />,
	},
];

export default uomRoutes;
