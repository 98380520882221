export const sortAscByKey = (array, key) => {
	return array?.sort((a, b) => {
		let x = a[key];
		let y = b[key];
		if (key == 'docDate') {
			x = new Date(x);
			y = new Date(y);
		}
		return x < y ? -1 : x > y ? 1 : 0;
	});
};

export const sortDescByKey = (array, key) => {
	return array?.sort((a, b) => {
		let x = a[key];
		let y = b[key];
		if (key == 'docDate') {
			x = new Date(x);
			y = new Date(y);
		}
		return x > y ? -1 : x < y ? 1 : 0;
	});
};
