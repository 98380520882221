import React from 'react';
import { QRCodeList } from './QRCodeList';

const qrCodeRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/qr-code`,
		},
		component: <QRCodeList />,
	},
];

export default qrCodeRoutes;
