import theme from '@ifca-root/react-component/src/assets/theme';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { StyledMenu } from '@ifca-root/react-component/src/components/others/Menu';
import {
	FormControl,
	IconButton,
	Input,
	InputAdornment,
	InputLabel,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import EditIcon from '@material-ui/icons/Edit';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetDigitalMenuCategoryDocument,
	GetDigitalMenuDocument,
	useCreateDigitalMenuCategoryMutation,
	useDeleteDigitalMenuCategoryMutation,
	useDeleteDigitalMenuMutation,
	useGetDigitalMenuCategoryQuery,
	useGetDigitalMenuQuery,
	useGetOutletDigitalMenuQuery,
	useGetOutletQuery,
	useIsDigitalMenuCategoryInUseLazyQuery,
	useIsDigitalMenuInUseLazyQuery,
	useUpdateDigitalMenuMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';

interface DigitalMenuCat {
	name: string;
}

interface DigitalMenuProps {
	name: string;
	description: string;
}

export const DigitalMenuForm = (props: any) => {
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const {
		anchorEl: editAnchorEl,
		menu: menuEdit,
		handleClick: editHandleClick,
		handleClose: editHandleClose,
	}: any = useMenuOption();

	const history = useHistory();
	const location = useLocation();
	const { outletID, digitalMenuID } = useParams<Record<string, any>>();
	const [simple, setSimple] = useState<boolean>(false);
	const [deleteDialog, setDeleteDialog] = useState<boolean>(false);
	const [editMenu, setEditMenu] = useState<boolean>(false);
	const [double, setDouble] = useState(false);

	const { register, control, handleSubmit } = useForm<DigitalMenuCat>({
		defaultValues: {},
	});

	const { globalState }: any = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const {
		data: { getDigitalMenu } = { getDigitalMenu: [] },
		loading: digitalMenuLoading,
	} = useGetDigitalMenuQuery({
		variables: {
			ID: digitalMenuID,
		},
		fetchPolicy: 'network-only',
	});

	const {
		data: outletDigitalMenu,
		loading: outletDigitalMenuLoading,
	} = useGetOutletDigitalMenuQuery({
		variables: {
			outletID: outletID,
		},
		fetchPolicy: 'network-only',
	});

	const {
		register: digRegister,
		control: digControl,
		handleSubmit: digHandleSubmit,
	} = useForm<DigitalMenuProps>({
		defaultValues: {
			name: getDigitalMenu[0]?.name,
			description: getDigitalMenu[0]?.description,
		},
	});

	const {
		data: { getDigitalMenuCategory } = { getDigitalMenuCategory: [] },
		loading: digitalMenuCategoryLoading,
	} = useGetDigitalMenuCategoryQuery({
		fetchPolicy: 'network-only',
		variables: {
			digitalMenuID: digitalMenuID,
		},
	});

	const [
		newMenuCategory,
		{ loading: createDigitalMenuCategoryLoading },
	] = useCreateDigitalMenuCategoryMutation({
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
		},
	});

	const [
		updateMenu,
		{ error: updateDigitalMenuError, loading: updateDigitalMenuLoading },
	] = useUpdateDigitalMenuMutation({
		onCompleted: () => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const [
		deleteMenuCategory,
		{ loading: deleteDigitalMenuCategoryLoading },
	] = useDeleteDigitalMenuCategoryMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
	});

	const [
		deleteDigitalMenu,
		{ loading: deleteDigitalMenuLoading },
	] = useDeleteDigitalMenuMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
			history.push(`/menu/outlet-app/outlet-setting/${outletID}/digital-menu`);
			setEditMenu(false);
		},
	});

	const [
		loadIsDigitalMenuCategoryInUse,
		{
			loading: isDigitalMenuCategoryInUseLoading,
			data: { isDigitalMenuCategoryInUse } = { isDigitalMenuCategoryInUse: [] },
		},
	] = useIsDigitalMenuCategoryInUseLazyQuery({
		fetchPolicy: 'network-only',
	});

	const onSubmit = (data: any) => {
		newMenuCategory({
			variables: {
				input: {
					digitalMenuID: digitalMenuID,
					name: data.name,
					priority: 0,
					outletID: outletID,
				},
			},
			refetchQueries: [
				{
					query: GetDigitalMenuCategoryDocument,
					variables: {
						digitalMenuID: digitalMenuID,
					},
				},
				{
					query: GetDigitalMenuDocument,
					variables: {
						ID: digitalMenuID,
					},
				},
			],
		});
	};

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const [
		loadIsDigitalMenuInUse,
		{
			loading: isDigitalMenuInUseLoading,
			data: { isDigitalMenuInUse } = { isDigitalMenuInUse: [] },
		},
	] = useIsDigitalMenuInUseLazyQuery({
		fetchPolicy: 'network-only',
	});

	return (
		<>
			{digitalMenuLoading && <Loading />}
			{digitalMenuCategoryLoading && <Loading />}
			{outletLoading && <Loading />}
			{createDigitalMenuCategoryLoading && <Loading />}
			{updateDigitalMenuLoading && <Loading />}
			{deleteDigitalMenuCategoryLoading && <Loading />}
			{deleteDigitalMenuLoading && <Loading />}
			{isDigitalMenuCategoryInUseLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push(
						`/menu/outlet-app/outlet-setting/${outletID}/digital-menu`,
					)
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Digital Menu & Pricing', current: true },
				]}
				rightRouteSegments={[{ name: 'Edit' }]}
			/>
			<DynamicSubHeader
				// style={{
				// 	width:
				// 		isDesktop && globalState.drawerOpen
				// 			? 'calc(100% - 288px)'
				// 			: isDesktop && !globalState.drawerOpen
				// 			? `calc(100% - 48px)`
				// 			: null,
				// }}
				title={getDigitalMenu[0]?.name}
				handleClick={e => handleClick(e, digitalMenuID, 0)}
				dropDownOptions={
					<StyledMenu
						id="customized-menu"
						anchorEl={anchorEl}
						keepMounted
						open={Boolean(anchorEl)}
						onClose={handleClose}
						onClick={handleClose}
					>
						{outletDigitalMenu?.getDigitalMenu?.map((x, i) => (
							<MenuItem
								key={i}
								value={x.ID}
								defaultValue={x.ID}
								onClick={e => {
									history.push({
										pathname: `/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${x.ID}/edit`,
									});
								}}
							>
								{x.name}
							</MenuItem>
						))}
					</StyledMenu>
				}
			/>
			<ContentWrapper float style={{ marginTop: isDesktop ? '91px' : '28px' }}>
				<CardContents devFullWidth={true}>
					{!digitalMenuLoading && (
						<>
							<FormControl>
								<InputLabel>Digital Menu</InputLabel>
								<Input
									value={getDigitalMenu[0]?.name}
									disabled={true}
									endAdornment={
										<InputAdornment position="end">
											<EditIcon
												style={{
													fontSize: '15px',
													color: 'white',
													backgroundColor: '#ff9800',
													borderRadius: '3px',
												}}
												onClick={() => {
													setEditMenu(true);
													loadIsDigitalMenuInUse({
														variables: {
															outletID: outletID,
															digitalMenuID: getDigitalMenu[0]?.ID,
														},
													});
												}}
											/>
										</InputAdornment>
									}
								/>
							</FormControl>
						</>
					)}
				</CardContents>
				{/** ADD LOADING SO THAT IT WILL NOT APPEAR AT FIRST (APPLY TO ALL OF THEM) */}
				{!digitalMenuLoading &&
				!digitalMenuCategoryLoading &&
				!outletLoading &&
				(getDigitalMenuCategory === undefined ||
					getDigitalMenuCategory?.length === 0) ? (
					<EmptyList
						title="No Digital Menu Category Record(s) found"
						subtitle="Add Digital Menu Category(s) to proceed."
					/>
				) : (
					<List className="core-list">
						{[...getDigitalMenuCategory]
							?.sort(function(a, b) {
								return a.priority - b.priority;
							})
							.map((el: any, index) => {
								return (
									<ListItem
										key={index}
										onClick={() =>
											history.push({
												pathname: `/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${digitalMenuID}/edit/digital-menu-pricing/${el?.ID}`,
												state: el,
											})
										}
									>
										{/* <IconItem
									image={el.icon}
									imageType="svg"
									// badgeType="default"
								/> */}
										<ListItemText
											primary={
												<>
													<span className="desc">
														<span className="xsTitle">
															{index + 1 + '. ' + el.name}
														</span>
													</span>
												</>
											}
											secondary={
												<span className="desc" style={{ paddingLeft: '11px' }}>
													<span className="desc">{'No. of Menu: '}</span>
													<span className="fw-medium">
														{' '}
														{
															el?.digitalMenuItem?.filter(
																x => x?.menuItem?.stopSales === null,
															)?.length
														}
													</span>
													<span
														className="desc"
														style={{ paddingLeft: '11px' }}
													>
														<span className="desc">{'No. of Seq: '}</span>
														<span className="fw-medium"> {el?.priority}</span>
													</span>
												</span>
											}
										/>
										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="delete"
												aria-controls="menu-list"
												aria-haspopup="true"
												onClick={e => {
													editHandleClick(e, el.ID, index, el);
													loadIsDigitalMenuCategoryInUse({
														variables: {
															outletID: outletID,
															digitalMenuCategoryID: el?.ID,
														},
													});
												}}
											>
												<MoreVert />
											</IconButton>
										</ListItemSecondaryAction>
									</ListItem>
								);
							})}
					</List>
				)}

				<Menu
					disableScrollLock
					id="menu-list"
					anchorEl={editAnchorEl}
					keepMounted
					open={Boolean(editAnchorEl)}
					onClose={editHandleClose}
					onClick={editHandleClose}
				>
					<MenuItem
						onClick={() => {
							history.push({
								pathname: `/menu/outlet-app/outlet-setting/${outletID}/digital-menu/${digitalMenuID}/edit/digital-menu-pricing/${menuEdit?.ID}`,
								state: menuEdit?.obj,
							});
						}}
					>
						<span>Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() => setDeleteDialog(true)}
						disabled={
							isDigitalMenuCategoryInUse === true ||
							isDigitalMenuCategoryInUseLoading === true
						}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<FloatButton
				// footer
				onClick={() => {
					setSimple(true);
					setDouble(false);
				}}
			/>

			<CommonDialog
				style={{ padding: 'none' }}
				fullWidth={true}
				open={simple}
				onClose={() => setSimple(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div className="title flex-space">New Menu Category</div>
										</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<Controller
								as={TextField}
								name="name"
								label="Menu Category"
								autoComplete="off"
								multiline={true}
								fullWidth
								margin="normal"
								//   helperText={errors?.docRef?.message}
								//   error={errors?.docRef ? true : false}
								ref={register}
								control={control}
							/>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => setSimple(false),
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Save',
								props: {
									// onClick: () => {
									// 	handleSubmit(onSubmit)();
									// 	setSimple(false);
									// },
									variant: 'contained',
									color: 'primary',
									//disabled: isExceed,
									disabled: double,

									onClick: () => {
										handleSubmit(onSubmit)();
										setSimple(false);
										setDouble(true);
									},
								},
							},
						],
					},
				}}
			/>

			<CommonDialog
				fullWidth={true}
				open={deleteDialog}
				onClose={() => setDeleteDialog(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div className="title flex-space color-primary-orange">
												{`${`Delete ${menuEdit?.obj?.name}`}`}
											</div>
										</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<span className="title">{`Confirm deletion`}</span>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => setDeleteDialog(false),
									variant: 'contained',
									color: 'secondary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										deleteMenuCategory({
											variables: {
												ID: menuEdit?.ID,
											},
											refetchQueries: [
												{
													query: GetDigitalMenuCategoryDocument,
													variables: { digitalMenuID },
												},
												{
													query: GetDigitalMenuDocument,
													variables: { ID: digitalMenuID },
												},
											],
										});
										setDeleteDialog(false);
									},
									variant: 'contained',
									color: 'primary',
									//disabled: isExceed,
								},
							},
						],
					},
				}}
			/>

			<CommonDialog
				fullWidth={true}
				open={editMenu}
				onClose={() => setEditMenu(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div className="title">
												{'Edit Digital Menu & Pricing'}
											</div>
										</div>
									</div>
								</div>
								<div className="infoline-content ">
									<div className="xsTitle flex-space infoline">
										{getDigitalMenu[0]?.name}
									</div>
									<div className=" xsTitle rightText infoline">Edit</div>
								</div>
								{/* <div className="infoline-content">
									<div
										className="xsTitle flex-space"
										style={{ color: 'black' }}
									>
										{getDigitalMenu[0]?.name}
									</div>
									<div
										className=" xsTitle rightText"
										style={{ color: 'black' }}
									>
										Edit
									</div>
								</div> */}
							</div>
						),
					},
					body: () => (
						<>
							<CardContents>
								<Controller
									as={TextField}
									name="name"
									label="Digital Menu"
									autoComplete="off"
									multiline={true}
									fullWidth
									margin="normal"
									//   helperText={errors?.docRef?.message}
									//   error={errors?.docRef ? true : false}
									ref={digRegister}
									control={digControl}
									defaultValue={getDigitalMenu[0]?.name}
								/>
								<Controller
									as={TextField}
									name="description"
									label="Description"
									autoComplete="off"
									multiline={true}
									fullWidth
									//   helperText={errors?.docRef?.message}
									//   error={errors?.docRef ? true : false}
									ref={digRegister}
									control={digControl}
									defaultValue={getDigitalMenu[0]?.description}
								/>
							</CardContents>
						</>
					),

					footer: {
						actions: [
							{
								displayText: 'Delete',
								props: {
									onClick: () => {
										deleteDigitalMenu({
											variables: {
												ID: digitalMenuID,
											},
											refetchQueries: [
												{
													query: GetDigitalMenuDocument,
													variables: {
														outletID: outletID,
													},
												},
											],
										});
									},
									disabled:
										isDigitalMenuInUse === true ||
										isDigitalMenuInUseLoading === true,
									variant: 'contained',
									color:
										isDigitalMenuInUse === true ||
										isDigitalMenuInUseLoading === true
											? 'inherit'
											: 'secondary',
								},
							},
							{
								displayText: 'Save',
								props: {
									onClick: () => {
										digHandleSubmit((data: any) => {
											updateMenu({
												variables: {
													input: {
														ID: digitalMenuID,
														name: data?.name,
														description: data?.description,
														outletID: outletID,
													},
												},
												refetchQueries: [
													{
														query: GetDigitalMenuDocument,
														variables: {
															ID: digitalMenuID,
														},
													},
													{
														query: GetDigitalMenuDocument,
														variables: {
															outletID: outletID,
														},
													},
												],
											});
										})();
										setEditMenu(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
