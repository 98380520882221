import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	Switch,
	TextField,
} from '@material-ui/core';
import { KeyboardArrowRight, MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetOutletDocument,
	PosPermission,
	useGetOutletPolicySummaryLazyQuery,
	useGetOutletQuery,
	useGetPatronTypeQuery,
	useGetTaxSchemeListingQuery,
	useUpdateOutletGovTaxPolicyMutation,
	useUpdateOutletTaxSetupByTaxSchemeMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
	GeneralSettingsIcon,
	OutletPolicyIcon,
	OutletProfileIcon,
} from '../../../../assets';
import { Controller, useForm } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';

interface Props {
	discountCode: string;
	discountID: string;
}

export const OutletPolicyList = (props: any) => {
	const history = useHistory();
	const { state }: any = useLocation();
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	// const { outletID } = props;

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();

	const { handlePermHidden, handlePermDisabled } = usePermissionChecker();

	const { outletID } = useParams<Record<string, any>>();

	const [openDialog, setOpenDialog] = useState(false);

	const { watch, register, control, handleSubmit } = useForm<Props>({
		defaultValues: {},
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: () => {
			loadOutletPolicySummary({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const {
		data: { getTaxSchemeListing } = { getTaxSchemeListing: [] },
		loading: taxSchemeListingLoading,
		refetch,
	} = useGetTaxSchemeListingQuery({
		fetchPolicy: 'network-only',
		variables: {
			accountID: user?.accountID,
		},
	});

	const {
		data: { getPatronType } = { getPatronType: [] },
		loading: patronTypeLoading,
	} = useGetPatronTypeQuery();

	const [viewTax, setViewTax] = useState(false);

	const [
		loadOutletPolicySummary,
		{
			called: outletPolicySummaryCalled,
			loading: outletPolicySummaryLoading,
			data: outletPolicySummaryData,
		},
	] = useGetOutletPolicySummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [taxPolicyInput, setTaxPolicyInput] = useState({
		isInclusive: getOutlet[0]?.isInclusive,
	});

	const onChangeTaxPolicyInput = (
		event: React.ChangeEvent<HTMLInputElement>,
	) => {
		setTaxPolicyInput({
			...taxPolicyInput,
			[event.target.name]: event.target.checked,
		});
	};

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [
		updateOutletGovTaxPolicy,
		{
			called: outletGovTaxPolicyCalled,
			loading: outletGovTaxPolicyLoading,
			data: outletGovTaxPolicyData,
		},
	] = useUpdateOutletGovTaxPolicyMutation({
		onCompleted: () => {
			setViewTax(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const [
		updateOutletTaxSetupByTaxScheme,
		{
			called: outletTaxSetupByTaxSchemeCalled,
			loading: outletTaxSetupByTaxSchemeLoading,
			data: outletTaxSetupByTaxScheme,
		},
	] = useUpdateOutletTaxSetupByTaxSchemeMutation({
		onCompleted: () => {
			setViewTax(false);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const patronSetupDate = outletPolicySummaryData?.patronSetupDate;
	const taxSetupDate = outletPolicySummaryData?.taxSetupDate;
	const reasonSetupDate = outletPolicySummaryData?.reasonSetupDate;
	const customerSetupDate = outletPolicySummaryData?.customerSetupDate;

	const onTaxSubmit = () => {
		updateOutletTaxSetupByTaxScheme({
			variables: {
				outletID: outletID,
				taxSchemeID: selectedOption ? selectedOption?.id : display?.id,
			},
			refetchQueries: [
				{
					query: GetOutletDocument,
					variables: {
						ID: outletID,
					},
				},
			],
		});
	};

	const policyList = [
		{
			icon: OutletProfileIcon,
			title: 'Customer Setup',
			label: 'Last updated on ',
			data: formatDate(patronSetupDate),
			action: () =>
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/outlet-policy/patron-setup`,
				}),
			permission: PosPermission.OutletSettingOutletPolicyPatronSetupShow,
		},
		{
			icon: OutletPolicyIcon,
			title: 'Tax Setup',
			label: 'Last updated on ',
			data: formatDate(taxSetupDate),
			action: () => handleClick(),
			permission: PosPermission.OutletSettingOutletPolicyTaxSetupShow,
		},
		{
			icon: GeneralSettingsIcon,
			title: 'Reason Setup',
			label: 'Last updated on ',
			data: formatDate(reasonSetupDate),
			action: () =>
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/outlet-policy/reason-setup`,
				}),
			permission: PosPermission.OutletSettingOutletPolicyReasonSetupShow,
		},
		{
			icon: GeneralSettingsIcon,
			title: 'Customer Policy Setup',
			label: 'Last updated on ',
			data: formatDate(customerSetupDate),
			action: () =>
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/outlet-policy/customer-policy-setup`,
				}),
			permission: PosPermission.OutletSettingOutletPolicyPatronSetupShow,
		},
	];

	useEffect(() => {
		if (state?.success) {
			setOpenDialog(true);
		}
	}, [state]);

	const [selectedOption, setSelectedOption] = useState(null);
	const [isTouch, setIsTouch] = useState(false);
	const [display, setDisplay] = useState(null);

	useEffect(() => {
		setDisplay(
			getTaxSchemeListing.find(x => x?.id === getOutlet[0]?.taxSchemeID),
		);
	}, [getOutlet, getTaxSchemeListing]);

	return (
		<>
			{outletLoading && <Loading />}
			{patronTypeLoading && <Loading />}
			{outletPolicySummaryLoading && <Loading />}
			{outletGovTaxPolicyLoading && <Loading />}
			{taxSchemeListingLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={getOutlet[0]?.name}
				routeSegments={[{ name: 'Outlet Settings', current: true }]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{policyList.map((el, index) => {
						if (
							handlePermHidden({
								outletID: outletID,
								permEnum: el.permission,
							})
						)
							return (
								<>
									<ListItem
										key={index}
										onClick={() => {
											el?.action();
										}}
									>
										<ListItemIcon>
											<img
												className="icon-svg"
												style={{ paddingRight: '10px' }}
												src={el.icon}
												alt="drawer-icon"
											/>
										</ListItemIcon>

										<ListItemText
											primary={
												<>
													<span className="desc">
														<span className="xsTitle">{el.title}</span>
													</span>
												</>
											}
											secondary={
												<span className="desc text-noflow">
													<>
														<span className="desc">{el.label} </span>
														<span className="highlight-text">{el.data}</span>
													</>
												</span>
											}
										/>

										{el?.title === 'Tax Setup' ? (
											<ListItemSecondaryAction>
												<IconButton
													edge="end"
													aria-label="delete"
													aria-controls="menu-list"
													aria-haspopup="true"
													onClick={e => handleClick(e)}
												>
													<MoreVert />
												</IconButton>
											</ListItemSecondaryAction>
										) : (
											<ListItemSecondaryAction>
												<IconButton edge="end" aria-label="delete">
													<KeyboardArrowRight onClick={() => el?.action()} />
												</IconButton>
											</ListItemSecondaryAction>
										)}
									</ListItem>
								</>
							);
					})}
				</List>

				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() => {
							setViewTax(true);
						}}
						disabled={handlePermDisabled({
							outletID: outletID,
							permEnum: PosPermission.OutletSettingOutletPolicyTaxSetupTaxShow,
						})}
					>
						<span>{`Tax`}</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<CommonDialog
				fullWidth={true}
				open={viewTax}
				onClose={() => {
					setViewTax(false);
					setIsTouch(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="flex session">
											<div
												className="title flex-space"
												style={{ alignSelf: 'center' }}
											>
												Tax
											</div>
										</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							<div className="content-container">
								<Autocomplete
									options={getTaxSchemeListing}
									fullWidth
									getOptionLabel={option => option?.name}
									value={isTouch === false ? display : selectedOption}
									onChange={(event, value) => {
										setIsTouch(true);
										setSelectedOption(value);
									}}
									renderInput={params => (
										<TextField
											{...params}
											label="Tax Scheme"
											variant="standard"
											name="taxScheme"
											helperText={
												!selectedOption?.id && isTouch === true
													? 'Tax Scheme is required'
													: null
											}
											error={
												!selectedOption?.id && isTouch === true ? true : false
											}
										/>
									)}
									renderOption={option => (
										<div>
											<div className="fw-normal" style={{ fontSize: '13px' }}>
												<b>{option.name}</b>
											</div>
											<div className="fw-normal" style={{ fontSize: '13px' }}>
												{option.description ? option.description : '-'}{' '}
												{option?.isExclusive === true ? '(E)' : '(I)'}
											</div>
										</div>
									)}
								/>
							</div>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => {
										setViewTax(false);
										setIsTouch(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									//onClick: () => handleSubmit(onSubmit)(),
									onClick: () => {
										setViewTax(true);
										onTaxSubmit();
									},

									variant: 'contained',
									form: 'open-form',
									color:
										selectedOption?.id && isTouch === true
											? 'primary'
											: 'inherit',
									disabled:
										selectedOption?.id && isTouch === true ? false : true,
									type: 'submit',
								},
							},
						],
					},
				}}
			/>

			<CommonDialog
				fullWidth
				open={openDialog}
				onClose={() => setOpenDialog(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div className="title color-primary-orange">{`Update Digital Menu`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<div className="content-wrapp full">
							<div>
								<div className="desc">
									<span className="mdDesc">
										Proceed to update digital menu pricing?
									</span>
								</div>
							</div>
						</div>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenDialog(false);
										history.push({
											pathname: `/menu/outlet-app/outlet-setting/${outletID}/digital-menu`,
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
