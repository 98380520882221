import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import AppContext from 'containers/App/Store/AppContext';
import {
	PosPermission,
	useGetAccountJournalSummaryQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};

const details = (description: string, highlighted: any) => {
	return (
		<span className="desc">
			{' '}
			{description} <span className="highlight-text">{highlighted}</span>
		</span>
	);
};

export const AccountsMappingListing = () => {
	useEffect(() => {
		console.log('AccountMappingListing');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	const { globalState, dispatch } = useContext(AppContext as any);

	const { handlePermHidden } = usePermissionChecker();
	const outletID = localStorage.getItem('latestOutlet');

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const {
		loading: accountMappingLoading,
		data: accountMappingData,
	} = useGetAccountJournalSummaryQuery({
		fetchPolicy: 'network-only',
	});
	// const outletGLDate = accountMappingData?.outletGLDate;
	// const bankAccountDate = accountMappingData?.bankAccountDate;
	const menuList = [
		{
			name: 'Outlet GL Interface',
			path: `/menu/outlet-app/account-journals/account-mapping/outlet-gl-interface`,
			label1: `Last updated on `,
			data1: formatDate(accountMappingData?.bankAccountDate),
			permission:
				PosPermission?.AccountJournalsAccountsMappingOutletGlInterfaceShow,
		},
		{
			name: 'Bank Account',
			path: `/menu/outlet-app/account-journals/account-mapping/bank-account`,
			label1: `Last updated on `,
			data1: formatDate(accountMappingData?.bankAccountDate),
			permission: PosPermission?.AccountJournalsAccountsMappingBankAccountShow,
		},
		{
			name: 'Tax GL Interface',
			path: `/menu/outlet-app/account-journals/account-mapping/tax`,
			label1: `Last updated on `,
			data1: formatDate(accountMappingData?.bankAccountDate),
			permission: PosPermission?.AccountJournalsAccountsMappingBankAccountShow,
		},
	];
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	// Drawer Toggler
	const handleDrawer = () => {
		dispatch({
			type: 'drawerOpen',
			payload: !globalState.drawerOpen,
		});
	};

	return (
		<>
			<MainHeader
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/account-journals`,
					});
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: '../' },
					{ name: 'Accounts Mapping', current: true },
				]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{menuList?.map((v, i) => {
						if (
							handlePermHidden({
								outletID: outletID,
								permEnum: v.permission,
							})
						)
							return (
								<ListItem onClick={() => history.push(v.path)}>
									{/* <SettingsIcon color="primary" style={{ paddingRight: '12px' }} /> */}
									{/* <ListItemIcon>
								<img
									className="icon-svg"
									style={{ paddingRight: '10px' }}
									src={v.icon}
									alt="drawer-icon"
								/>
							</ListItemIcon> */}
									<ListItemText
										primary={
											<>
												<span className="xsTitle flex-space">{v?.name}</span>
											</>
										}
										secondary={
											<span className="desc">
												<span className="desc">{v.label1}</span>
												<span className="highlight-text">{v.data1}</span>
											</span>
										}
									/>
									<ListItemSecondaryAction>
										<IconButton edge="end" aria-label="delete">
											<KeyboardArrowRight
												onClick={() => history.push(v.path)}
											/>
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							);
					})}
				</List>
			</ContentWrapper>
		</>
	);
};
function useGetAccountJournalMapping(arg0: {
	fetch: string;
}): [any, { loading: any; data: any }] {
	throw new Error('Function not implemented.');
}
