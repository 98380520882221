import AppContext from 'containers/App/Store/AppContext'
import React, { useContext } from 'react'
interface ContentWrapperProps {
  children?: any
  footer?: boolean
  page?: string
  float?: boolean
  style?: React.CSSProperties
  floatTotalAmt?: boolean
  search?: boolean
  multiDynamicInfo?: boolean
  singleDynamicInfo?: boolean
  dynamicInfoSearch?: boolean
  multiDynamicInfoSearch?: boolean
  customerDynamicInfo?: boolean
  multiDynamicSubInfoSearch?: boolean
  tabs?: boolean
  bigSubHeaderNSearchBar?: boolean //new changes 5/6/2021 by syafiq ali
  onlyContentScrollable?: boolean //new changes 5/6/2021 by syafiq ali
  longFooter?: boolean
  multiLineSubheader?: boolean
  tableHeader?: boolean
  datePickerSearchBar?: boolean
  searchBarDropdown?: boolean
  auditLogSubheaderSearch?: boolean
}
export const ContentWrapper = (props: ContentWrapperProps) => {
  const {
    children,
    footer,
    search,
    float,
    style,
    floatTotalAmt,
    multiDynamicInfo,
    customerDynamicInfo,
    singleDynamicInfo,
    dynamicInfoSearch,
    multiDynamicInfoSearch,
    tabs,
    multiDynamicSubInfoSearch,
    bigSubHeaderNSearchBar,
    longFooter,
    multiLineSubheader,
    tableHeader,
    datePickerSearchBar,
    searchBarDropdown,
    auditLogSubheaderSearch,
  } = props
  var footing = ''

  if (footer == true && float == true) {
    footing = 'with-footer-n-float'
  } else if (footer == true && float == null) {
    footing = 'with-footer'
  } else if (footer == null && float == true) {
    footing = 'with-float'
  } else if (footer == true && floatTotalAmt == true) {
    footing = 'with-float-amt'
  } else if (longFooter == true) {
    footing = 'with-longFooter'
  }

  const { globalState } = useContext(AppContext as any)
  //  else if (footer == null && search == true) {
  //   footing = 'with-search'
  // }
  return (
    <div
      className={`content-wrapper onlyContentScrollable 
      ${footing} 
      ${tabs && 'with-tab'}
      ${search && 'with-search'} 
      ${multiDynamicInfo && `multiple-dynamicInfo`}
      ${customerDynamicInfo && `customer-dynamicInfo`}
      ${singleDynamicInfo && `single-dynamicInfo`}
      ${dynamicInfoSearch && `dynamicinfo-search`}
      ${bigSubHeaderNSearchBar && `with-bigSubHeaderNSearchBar`}
      ${multiDynamicInfoSearch && `multi-dynamicinfo-search`}
      ${multiDynamicSubInfoSearch && `multi-dynamicSubinfo-search`}
      ${multiLineSubheader && 'multi-line-subheader'}
      ${tableHeader &&
        (globalState.drawerOpen ? `table-header-fw` : `table-header`)}
      ${datePickerSearchBar && 'datePicker-search'}
      ${searchBarDropdown && 'searchBar-dropdownFilter'}
      ${auditLogSubheaderSearch && `auditLog-subheader-search`}
      `}
      // ${onlyContentScrollable && `onlyContentScrollable`}
      // style={{ marginTop: search ? '38px' : null }}
      style={style}
    >
      {children}
    </div>
  )
}
