import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import PaymentFail from 'assets/icons/cancelled_failed.svg';
import { useGetOutletQuery } from 'generated/graphql';
import React from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import '../../../BusinessInsights/BusinessAnalytics/bi.scss';

export const OnlinePaymentError = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { [`tableID`]: tableID, outletID, orderID, billID } = useParams<
		Record<string, any>
	>();

	const paymentInfo = JSON.parse(localStorage.getItem('paymentInfo'));

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	return (
		<>
			<MainHeader
				mainBtn="close"
				onClick={() =>
					history.push(
						`/menu/outlet-app/${outletID}/table/grid/${tableID}/bill-settlement`,
					)
				}
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Table Layout' },
					{ name: 'Table 03 (HARDCODE)' },
					{ name: 'Bill Settlement', current: true },
				]}
				rightRouteSegments={[
					{
						name: 'Error',
						current: true,
					},
				]}
			/>
			<ContentWrapper>
				<div className="content-wrap full p-10" style={{ textAlign: 'center' }}>
					<img src={PaymentFail} alt="ifca" style={{ width: '100' }} />

					<div className="xsTitle m-t-10">Payment Cancelled.</div>
				</div>
			</ContentWrapper>
		</>
	);
};
