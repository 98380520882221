import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Divider,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { MoreVert, Check } from '@material-ui/icons';
import { GeneralDeleteDialog } from 'components/Dialog/GeneralDeleteDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetBankAccountDocument,
	PosPermission,
	useDeleteBankAccountMutation,
	useGetAssignedOutletByUserQuery,
	useGetBankAccountQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { OutletIcon } from '../../../../../assets';
interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};

export const BankAccountList = () => {
	useEffect(() => {
		console.log('BankAccountList');
	}, []);
	const history = useHistory();
	const { pathname } = useLocation();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const { IDK } = useParams<Record<string, any>>();
	const [openDeleteDialog, setDeleteDialog] = useState(false);

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const { handlePermDisabled } = usePermissionChecker();
	const outletID = localStorage.getItem('latestOutlet');

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);
	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const bank = JSON.parse(localStorage.getItem('searchFilter'));

	const [rerender, setRender] = useState(false);

	/////////////////////////////////////
	/////// QUERIES AND MUTATIONS ///////
	/////////////////////////////////////

	const {
		called: bankAccountCalled,
		loading: bankAccountLoading,
		error,
		data: { getBankAccount } = { getBankAccount: [] },
	} = useGetBankAccountQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	useEffect(() => {
		if (getBankAccount?.length > 0 && getBankAccount) {
			setOriginalListing(getBankAccount);
		}
	}, [getBankAccount, !bankAccountLoading]);

	useEffect(() => {
		if (!bankAccountLoading && !!bank?.value) {
			handleSearch(`'"${bank?.value ?? ''}"`, ['bankName']);
			setRender(true);
		}
	}, [bankAccountLoading, rerender]);

	const moreVertList = [
		{
			name: 'View',
			action: () => {
				handleClose();
			},
		},
		{
			name: 'Edit',
			action: () => {
				handleClose();
			},
		},
		{
			name: 'Delete',
			action: () => {
				setDeleteDialog(true);
				handleClose();
			},
		},
	];

	let location: any = useLocation();

	const [success, setSuccess] = useState<boolean>(
		location.state ? location.state['success'] : false,
	);

	const [deleteBankAccount] = useDeleteBankAccountMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	setTimeout(() => {
		setSuccess(false);
	}, 3000);

	const [openSwitch, setOpenSwitch] = useState(false);

	const {
		filteredList: filteredList1,
		handleSearch: handleSearch1,
		setOriginalListing: setOriginalListing1,
	} = useFuseSearch();
	const {
		data: { getAssignedOutletByUser } = { getAssignedOutletByUser: [] },
		loading: getAssignedOutletByUserLoading,
	} = useGetAssignedOutletByUserQuery({
		fetchPolicy: 'network-only',
	});
	useEffect(() => {
		if (getAssignedOutletByUser && getAssignedOutletByUser?.length > 0) {
			setOriginalListing1(getAssignedOutletByUser);
		}
	}, [getAssignedOutletByUser]);

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	return (
		<>
			{bankAccountLoading && <Loading />}
			{getAssignedOutletByUserLoading && <Loading />}
			<MainHeader
				onClick={() => {
					localStorage.removeItem('searchFilter');
					history.push({
						pathname: `/menu/outlet-app/account-journals/account-mapping`,
					});
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				sideBtn="switch"
				onClickAction={() => setOpenSwitch(true)}
				routeSegments={[
					{ name: 'Account Journals' },
					{ name: 'Bank Account', current: true },
				]}
			/>

			<SearchHeader
				title="Bank Account Listing "
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['bankName']);
					localStorage.setItem(
						`searchFilter`,
						JSON.stringify({ value: e?.target?.value }),
					);
				}}
				fixed
				isDefaultValue={!!bank?.value ?? false}
				defaultValue={bank?.value ?? ''}
				search
				onCloseAction={() => handleSearch('', [])}
			/>
			<ContentWrapper search>
				<List className="core-list">
					{!outletLoading &&
					!bankAccountLoading &&
					(getBankAccount === undefined || getBankAccount?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Add a new record now."
						/>
					) : (
						filteredList?.map((el, index) => (
							<ListItem key={index}>
								<ListItemText
									primary={
										<>
											<span className="xsTitle flex-space">{el.bankName}</span>
											<span className="xxTitle highlight-text text-separator"></span>
											<span className="xxTitle highlight-text"></span>
										</>
									}
									secondary={
										<>
											<span className="desc" style={{ color: '' }}>
												{el?.branch}
											</span>{' '}
										</>
									}
								/>
								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										aria-label="delete"
										aria-controls="menu-list"
										aria-haspopup="true"
										onClick={e => handleClick(e, el.ID, index, el)}
									>
										<MoreVert />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))
					)}
				</List>
				<GeneralDeleteDialog
					ID={menu?.ID}
					openDialog={openDeleteDialog}
					setOpenDialog={setDeleteDialog}
					dialogName={menu?.obj?.bankName}
					deleteMutation={deleteBankAccount}
					refetchDocument={GetBankAccountDocument}
				/>
				<FloatButton
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum:
							PosPermission.AccountJournalsAccountsMappingBankAccountCreate,
					})}
					onClick={() =>
						history.push(
							`/menu/outlet-app/account-journals/account-mapping/bank-account/add`,
						)
					}
				/>
			</ContentWrapper>

			<Menu
				id="menu-list"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
				onClick={handleClose}
			>
				<MenuItem
					onClick={() =>
						history.push({
							pathname: `/menu/outlet-app/account-journals/account-mapping/bank-account/${menu?.ID}/detail`,
							state: menu.obj,
						})
					}
				>
					<span> View </span>
				</MenuItem>

				<MenuItem
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum:
							PosPermission.AccountJournalsAccountsMappingBankAccountUpdate,
					})}
					onClick={() =>
						history.push({
							pathname: `/menu/outlet-app/account-journals/account-mapping/bank-account/${menu?.ID}/edit`,
							state: menu.obj,
						})
					}
				>
					<span className="">Edit</span>
				</MenuItem>
				<MenuItem
					disabled={handlePermDisabled({
						outletID: outletID,
						permEnum:
							PosPermission.AccountJournalsAccountsMappingBankAccountDelete,
					})}
					onClick={() => setDeleteDialog(true)}
				>
					<span className="">Delete</span>
				</MenuItem>
			</Menu>
			<CommonDialog
				fullWidth={true}
				open={openSwitch}
				onClose={() => setOpenSwitch(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div className="session">
										<div
											className="title"
											style={{ color: '#ff9800' }}
										>{`Switch Outlet`}</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<>
							{getAssignedOutletByUser?.length > 2 ? (
								<div className="rm-padding table-wrap sample">
									<List
										className="core-list content-wrap full"
										style={{ paddingBottom: '0px' }}
									>
										<ListItem
											style={{
												boxShadow: 'none',
												padding: '8px 12px 0px 12px',
											}}
										>
											<TextField
												// className="m-b-20"
												id="standard-basic"
												label="Search here..."
												fullWidth
												// value={`${filteredList?.name ?? 0}`}
												onChange={e => {
													handleSearch1(`'"${e?.target?.value}"`, ['name']);
												}}
											/>
										</ListItem>
									</List>
								</div>
							) : null}
							<ContentWrapper
								style={{
									marginTop: '0px',
									width: 'inherit',
									position: 'initial',
									padding: '8px 0px 8px 0px',
								}}
							>
								<List>
									{filteredList1?.map((outlet, index) => (
										<>
											<ListItem
												style={{
													backgroundColor:
														outlet?.ID === outletID ? '#E5EDFF' : null,
												}}
											>
												<div style={{ paddingRight: '6px' }}>
													{outlet?.logo?.fileURL ? (
														<img
															src={outlet?.logo?.fileURL}
															style={{
																display: 'flex',
																marginTop: '2px',
																marginBottom: '2px',
																marginRight: '4px',
																width: '40px',
																height: '40px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													) : (
														<img
															src={OutletIcon}
															style={{
																display: 'flex',
																marginTop: '7px',
																marginBottom: '8px',
																marginRight: '4px',
																width: '40px',
																height: '25px',
																justifyItems: 'center',
																borderRadius: '30px',
															}}
															loading="lazy"
														/>
													)}
												</div>
												<ListItemText
													primary={
														<span className="xsTitle">{outlet?.name}</span>
													}
													onClick={() => {
														history.push(
															`/menu/outlet-app/account-journals/account-mapping/bank-account`,
														);

														setOpenSwitch(false);
														localStorage.setItem('latestOutlet', outlet?.ID);
														// loadCurrentMealPeriod({
														// 	variables: { outletID: outlet?.ID },
														// });
													}}
												/>
												{outlet?.ID === outletID ? (
													<ListItemSecondaryAction>
														<IconButton edge="end" aria-label="delete">
															<Check color="primary" />
														</IconButton>
													</ListItemSecondaryAction>
												) : null}
											</ListItem>
											<Divider
												// variant="fullWidth"
												style={{ display: 'flex', width: '100%' }}
											/>
										</>
									))}
								</List>
							</ContentWrapper>
						</>
					),
					footer: {
						actions: [
							{
								displayText: 'Close',
								props: {
									onClick: () => setOpenSwitch(false),
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);
};
