import React from 'react';
import { RoundingPolicyForm } from './RoundingPolicyForm';

const roundingPolicyRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/general-setting/rounding-policy`,
		},
		component: <RoundingPolicyForm />,
	},
];

export default roundingPolicyRoutes;
