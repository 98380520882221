import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	Box,
	Card,
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
} from '@material-ui/core';
import EventSeatIcon from '@material-ui/icons/EventSeat';
import Person from '@material-ui/icons/Person';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetReservationDocument,
	GetTableDocument,
	TableStatus,
	useAssignReservationTableMutation,
	useGetCurrentMealPeriodLazyQuery,
	useGetOutletQuery,
	useGetTableQuery,
	useRefreshTableSubscription,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatTime';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { WSclient } from 'POSClient';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { ChairSeatIcon, PersonIcon, ServedIcon } from '../../../assets';
import './grid.scss';

interface AssignTable {
	tableID?: String;
	reservationID?: String;
}

const iconMap: Record<TableStatus, string> = {
	[TableStatus.Available]: ChairSeatIcon,
	[TableStatus.Occupied]: ServedIcon,
	[TableStatus.Reserved]: PersonIcon,
};

const bgcolorMap: Record<TableStatus, string> = {
	[TableStatus.Available]: 'rgba(170,252,118,1)',
	[TableStatus.Occupied]: 'rgba(255,52,92,1)',
	[TableStatus.Reserved]: 'rgba(162,162,162,1)',
};

export interface TablesListingState {
	selectedTable: string;
}

export const ReservationTable = (props: any) => {
	useEffect(() => {
		console.log('ReservationTable');
	}, []);
	const history = useHistory();
	const { mode } = props;
	const location = useLocation();

	const { outletID, tableID, reservationID } = useParams<any>();
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const [currentMealPeriod, setCurrentMealPeriod] = useState(null);

	const editData = location?.state as any;

	// const currentMealPeriod = JSON.parse(
	// 	localStorage.getItem('currentMealPeriod'),
	// )[outletID][0];

	///////////////////////////////////////////////////////////
	///////////////////	QUERIES & MUTATIONS ///////////////////
	///////////////////////////////////////////////////////////

	const [
		loadCurrentMealPeriod,
		{
			loading: getCurrentMealPeriodLoading,
			called: getCurrentMealPeriodCalled,
			data: { getCurrentMealPeriod } = { getCurrentMealPeriod: {} as any },
		},
	] = useGetCurrentMealPeriodLazyQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getCurrentMealPeriod }) => {
			setCurrentMealPeriod(getCurrentMealPeriod);
		},
	});

	const {
		data: { getOutlet } = { getOutlet: [] },
		loading: getOutletLoading,
	} = useGetOutletQuery({
		onCompleted: () => {
			loadCurrentMealPeriod({
				variables: {
					outletID: outletID,
				},
			});
		},
	});

	const {
		data,
		loading: getTableLoading,
		called: getTableCalled,
		fetchMore,
	} = useGetTableQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'sequence', isActive: true },
	});

	//subscription //subscription //subscription
	const {
		loading: refreshTableLoading,
		data: { refreshTable } = { refreshTable: {} as any },
	} = useRefreshTableSubscription({
		variables: {
			outletID: outletID,
		},
		client: WSclient,
	});

	useEffect(() => {
		if (refreshTable?.ID) {
			fetchMore({
				query: GetTableDocument,
				variables: {
					outletID: outletID,
					orderByAsc: 'sequence',
					isActive: true,
				},
			});
		}
	}, [refreshTable?.ID]);

	const { handleSubmit, control, register } = useForm<AssignTable>({
		defaultValues: {
			tableID: mode === 'add' ? '' : editData?.tableID,
			reservationID: mode === 'add' ? '' : editData?.reservationID,
		},
	});

	const [state, setState] = useState('');
	// const [openSwitch, setOpenSwitch] = useState(null);
	const [openAssign, setOpenAssign] = useState(false);
	const [selectedTable, setSelectedTable] = useState(null);

	const [date, setDate] = useState(new Date());

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [
		assignTable,
		{ data: createOrder, loading },
	] = useAssignReservationTableMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			history.push({
				pathname: `/menu/outlet-app/${outletID}/reservation`,
				state: { success: true, msgMode: 'create' },
			});
			setOpenSnackBar(true);
			setSnackBarMsg('Record assigned successfully');
		},
	});

	const onSubmit = async (data: any) => {
		assignTable({
			variables: {
				tableID: menu?.obj?.ID,
				reservationID: reservationID,
			},
			refetchQueries: [
				{
					query: GetReservationDocument,
					variables: { tableID: tableID },
				},
				{
					query: GetTableDocument,
					variables: { outletID: outletID },
				},
			],
		});
		setOpenAssign(false);
	};

	return (
		<>
			{getOutletLoading && <Loading />}
			{getTableLoading && <Loading />}
			{getCurrentMealPeriodLoading && <Loading />}
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/${outletID}/reservation`,
					})
				}
				mainBtn="close"
				smTitle="Outlet App"
				title={getOutlet?.filter(outlet => outlet?.ID === outletID)[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Assign Table', current: true },
				]}
				rightRouteSegments={[
					{
						name: currentMealPeriod
							? `${currentMealPeriod?.name} ${formatTime(
									currentMealPeriod?.startTime,
							  )} - ${formatTime(currentMealPeriod?.endTime)}`
							: `No applicable meal period at the moment`,
						current: true,
					},
				]}
			/>

			<ContentWrapper>
				<MsgAlert
					icon={<TouchAppIcon style={{ color: '#FFB11F', fontSize: '20px' }} />}
					message={'Tap to select Table.'}
				/>
				<List className="core-list" style={{ paddingLeft: '2%' }}>
					{!getOutletLoading &&
						!getTableLoading &&
						!getCurrentMealPeriodLoading &&
						(data?.getTable?.length === 0 ? (
							<EmptyList title="No Records found" />
						) : (
							data?.getTable?.map((el, index) => (
								<ListItem
									onClick={e => {
										setOpenAssign(true);
										handleClick(e, el.ID, index, el);
										// setIsEdit(false);
									}}
									className="outlet-table"
									style={{
										backgroundColor:
											// el.status === null || TableStatus.Available
											// 	? '#B2FC74'
											// 	: '#FF4568',
											el.status === TableStatus.Occupied
												? '#FF4568'
												: el.status === null || TableStatus.Available
												? '#B2FC74'
												: '#ACACAC',
										paddingLeft: '4px',
										paddingTop: '0',
										paddingRight: '3px',
										width: '100%',
									}}
								>
									<ListItemText
										className="outlet-table-ul"
										primary={
											<>
												<span className="xsTitle flex-space">
													<Grid
														component={Card}
														style={{
															background: '#FFB11F',
															boxShadow: 'none',
															marginTop: '4px',
														}}
														className="outlet-table-label"
													>
														<Typography
															variant="caption"
															style={{
																fontSize: '10px',
																fontWeight: 800,
															}}
															className="outlet-table-typography"
														>
															{el?.prefix}
															{el?.tableNumber}
														</Typography>
													</Grid>
												</span>
												<span
													style={{
														fontWeight: 600,
														paddingRight: '2px',
														fontSize: '12px',
													}}
												>
													{el.status === TableStatus.Available ? (
														<EventSeatIcon
															style={{
																fontSize: '15px',
																color: '#ff9800',
																paddingTop: '4px',
																boxSizing: 'border-box',
															}}
														/>
													) : (
														<Person
															style={{
																fontSize: '16px',
																color: '#ff9800',
																paddingTop: '4px',
																boxSizing: 'border-box',
															}}
														/>
													)}
													{/* {el.isActive === true
														? el.availableSeats
														: el.noOfPax} */}
													{el.seatCount}
												</span>
											</>
										}
										secondary={
											<>
												<div
													className="desc"
													style={{
														color:
															el.status === TableStatus.Occupied
																? 'white'
																: 'black',
														textAlign: 'right',
														padding: 0,
														width: '100%',
													}}
												></div>
											</>
										}
									/>
								</ListItem>
							))
						))}
				</List>
			</ContentWrapper>

			<form onSubmit={handleSubmit(onSubmit)} id="close-form">
				<Box mt={1}>
					<CommonDialog
						fullWidth={true}
						open={openAssign}
						onClose={() => setOpenAssign(false)}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												<div className="title">Assign Table</div>
											</div>
										</div>
										<div className="infoline-content ">
											<div className="xsTitle infoline">
												{menu?.obj?.prefix}
												{menu?.obj?.tableNumber}
												{/* {selectedTable?.prefix}
													{selectedTable?.tableNumber} */}
											</div>
										</div>
									</div>
								),
							},
							body: () => (
								<>
									<div className="mdDesc">
										{'Confirm assign'}&nbsp;
										{menu?.obj?.prefix}
										{menu?.obj?.tableNumber}?
										{/* {selectedTable?.prefix}
										{selectedTable?.tableNumber}? */}
									</div>
								</>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => setOpenAssign(false),
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Confirm',
										props: {
											//onClick: () => handleSubmit(onSubmit)(),
											onClick: () => setOpenAssign(true),

											variant: 'contained',
											form: 'close-form',
											color: 'primary',
											type: 'submit',
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			{/* <AssignTableDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID }}
				openDialog={openAssign}
				setOpenDialog={setOpenAssign}
				dialogName={menu?.obj?.name}
				assignMutation={assignTable}
				refetchDocument={GetReservationDocument}
			/> */}
		</>
	);
};

export default ReservationTable;
