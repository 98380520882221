import React from 'react';
import { NewOrder } from './NewOrder';
import { NewOrderQR } from './NewOrderQR';

const newOrderRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/qr-code`,
		},
		component: <NewOrderQR mode="dineIn" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/table/grid/:tableID/qr-code/:qrID`,
		},
		component: <NewOrder mode="dineIn" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/qr-code/takeaway`,
		},
		component: <NewOrderQR mode="takeAway" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/order/takeaway/add`,
		},
		component: <NewOrder mode="takeAway" />,
	},
];

export default newOrderRoutes;
