import theme from '@ifca-root/react-component/src/assets/theme';
import CardExpansion from '@ifca-root/react-component/src/components/CardList/CardExpansion';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Checkbox,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import { Beenhere } from '@material-ui/icons';
import { Autocomplete } from '@material-ui/lab';
import AppContext from 'containers/App/Store/AppContext';
import {
	CommonStatus,
	GetEntityRoleUserAssignmentDocument,
	GetOutletRoleAssignmentDocument,
	useCreateListEntityRoleUserMutation,
	useGetEntityRoleUserAssignmentQuery,
	useGetOutletQuery,
	useGetRoleQuery,
	useGetUsersByAccountAndSoftwareQuery,
	UserSelection,
} from 'generated/graphql';
import React, { ChangeEvent, Fragment, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import './RoleAssignment.scss';

export const RoleAssignmentList = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();

	const { globalState, dispatch } = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	interface accessListForm {}

	const labelOffset = -6;

	// const { outletID }: any = useParams();

	// const { outletID, mode: pageMode }: any = useParams();

	const { [`outletID`]: outletID } = useParams<Record<string, any>>();
	// const { outletID }: any = useParams();

	const { loading, data: { getRole } = { getRole: [] } } = useGetRoleQuery({});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const [arrayOfSearch, setSearchArray] = useState([]);
	const [search, setSearch] = useState('');

	const [arrayOfCheckBox, setCheckBoxArray] = useState([]);
	const [keyVal, setKeyVal] = useState([]);

	const outletArray: any[] = getOutlet;

	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			superUserBool: UserSelection.Nonsuper,
			status: CommonStatus.Active,
		},
	});

	const [
		CreateListEntityRoleUser,
		{ loading: mutationLoading, error: mutationError },
	] = useCreateListEntityRoleUserMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			//window.location.reload(false);
			history.push(`/menu/outlet-app/outlet-setting/${outletID}`);
		},
	});

	const {
		data: { getEntityRoleUserAssignment } = {
			getEntityRoleUserAssignment: [],
		},
	} = useGetEntityRoleUserAssignmentQuery({
		variables: { entityID: outletID },
		onCompleted: ({ getEntityRoleUserAssignment }: any) => {
			let tempArr = getEntityRoleUserAssignment?.map(x => {
				return {
					roleID: x?.roleID,
					entityID: x?.entityID,
					userID: x?.userID,
				};
			});
			setCheckBoxArray(tempArr);
			let initialKeyVal = getEntityRoleUserAssignment?.map(x => {
				return { [x?.roleID]: x?.userID };
			});
			setKeyVal(initialKeyVal);
		},
	});

	const setOfID = array => {
		var o = {};

		for (var i = 0; i < array?.length; i++) {
			let key = Object.keys(array[i])[0];
			if (!o[key]) {
				o[key] = [];
			}
			o[key].push(keyVal[i][key]);
		}
		return o;
	};

	const onSubmit = data => {
		// var o = {};
		var formatList = [];

		// for (var i = 0; i < keyVal?.length; i++) {
		//   let key = Object.keys(keyVal[i])[0];
		//   if (!o[key]) {
		//     o[key] = [];
		//   }
		//   o[key].push(keyVal[i][key]);
		// }
		var o = setOfID(keyVal);

		formatList = Object.keys(o).map(x => {
			return { roleID: x, userID: o[x] };
		});

		CreateListEntityRoleUser({
			variables: {
				outletID: outletID,
				roleUserIDs: formatList,
			},
			refetchQueries: [
				{
					query: GetEntityRoleUserAssignmentDocument,
					variables: { entityID: outletID },
				},
				{
					query: GetOutletRoleAssignmentDocument,
				},
			],
		});
	};

	const pushIntoArray = (array: any, element: any) => {
		let tempArr = array;
		if (tempArr?.length === 0) {
			tempArr = [{ element }];
		} else {
			tempArr.push(element);
		}
		setCheckBoxArray(tempArr);
		// return array;
	};

	const removeFromArray = (array: any, element: any) => {
		let newArray = array.filter(function(obj: any) {
			return obj.userID !== element.userID;
		});
		setCheckBoxArray(newArray);
	};

	const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
		if (event.target.checked) {
			setKeyVal(prev => [...prev, { [event.target.name]: event.target.value }]);
		} else {
			setKeyVal(prev =>
				prev.filter(x => {
					let entry = Object.entries(x);
					return (
						entry[0][0] !== event.target.name ||
						entry[0][1] !== event.target.value
					);
				}),
			);
		}
		let element = {
			userID: event.target.value,
			roleID: event.target.name,
			entityID: outletID,
		};
		if (event.target.checked) {
			pushIntoArray(arrayOfCheckBox, element);
		} else if (!event.target.checked) {
			removeFromArray(arrayOfCheckBox, element);
		}
	};

	const { handleSubmit, register, errors, control, reset, getValues } = useForm<
		accessListForm
	>({});

	return (
		<>
			{/* ROLEASSIGNMETO */}
			{loading && <Loading />}
			{userLoading && <Loading />}
			{mutationLoading && <Loading />}
			<MainHeader
				onClick={() =>
					history.push(`/menu/outlet-app/outlet-setting/${outletID}`)
				}
				// onClick={() =>
				// 	history.push({ pathname: __OUTLET_APP__GENERAL_SETTING__USER_ACCESS })
				// }
				mainBtn="back"
				smTitle={`Outlet App`}
				// title={'HARDCODED'}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Outlet Role Authorisation', current: true },
				]}
			/>
			<ContentWrapper footer>
				<List className="core-list">
					<form onSubmit={handleSubmit(onSubmit)} id="createRole-form">
						{!loading &&
						!userLoading &&
						(getRole === undefined || getRole?.length === 0) ? (
							<EmptyList
								title="No Record found"
								subtitle="Please add roles to continue"
							/>
						) : (
							getRole?.map((role, index) => (
								<CardExpansion
									summary={
										<ListItem>
											<ListItemText
												primary={
													<>
														<span className="xsTitle flex-space">
															{role.name}
														</span>
													</>
												}
												secondary={
													<>
														<span className="desc flex-space text-noflow">
															Authorised User:{' '}
															<span className="fw-medium">
																{arrayOfCheckBox
																	?.filter(v => v?.roleID === role?.ID)
																	.map((el, index) => (
																		<span>
																			{' '}
																			{index > 0 && <span> , </span>}
																			{
																				getUsersByAccountAndSoftware?.filter(
																					x => x?.ID === el?.userID,
																				)[0]?.name
																			}{' '}
																		</span>
																	))}
																{arrayOfCheckBox?.filter(
																	v => v?.roleID === role?.ID,
																)?.length === 0 && <span> N/A </span>}
															</span>
														</span>
													</>
												}
											/>
										</ListItem>
										// </List>
									}
								>
									<ContentWrapper style={{ marginTop: '0px' }}>
										<Autocomplete
											multiple
											id="checkboxes-tags-demo"
											options={getUsersByAccountAndSoftware}
											disableCloseOnSelect
											renderTags={() => null}
											getOptionLabel={option => option.name}
											renderOption={(option, { selected }) => (
												<Fragment>
													<Checkbox
														onChange={e => {
															handleCheckboxChange(e);
														}}
														name={`${role.ID}`}
														value={option.ID}
														ref={register}
														color="primary"
														disabled={
															arrayOfCheckBox?.filter(
																o => o.userID === option.ID,
															).length < 1
																? false
																: arrayOfCheckBox?.filter(
																		o =>
																			o.userID === option.ID &&
																			o.roleID === role.ID,
																  ).length > 0
																? false
																: arrayOfCheckBox?.filter(
																		o => o.userID === option.ID,
																  ).length > 0
																? true
																: false
														}
														checked={
															arrayOfCheckBox?.filter(
																o => o.userID === option.ID,
															).length < 1
																? false
																: arrayOfCheckBox?.filter(
																		o => o.userID === option.ID,
																  ).length > 0
																? true
																: false
														}
														style={{
															display: search
																? option.name
																		.toUpperCase()
																		.includes(
																			arrayOfSearch[index]
																				?.toString()
																				.toUpperCase(),
																		)
																	? 'block'
																	: 'none'
																: null,
														}}
													/>
													{option.name}
												</Fragment>
											)}
											style={{
												width: '100%',
												marginTop: '-15px',
												paddingBottom: '10px',
											}}
											renderInput={params => (
												<TextField
													{...params}
													label="Select User"
													placeholder="Users"
													fullWidth
												/>
											)}
										/>

										{getUsersByAccountAndSoftware
											?.filter(x => {
												return setOfID(keyVal)[role?.ID]?.includes(x?.ID);
											})
											.map(v => (
												<List
													// className="rm-padding"
													style={{ padding: '0', margin: '2px' }}
												>
													<ListItem
													// divider
													// className="autocomplete-list"
													>
														<ListItemIcon style={{ minWidth: '46px' }}>
															<Beenhere style={{ color: 'light-gray' }} />
														</ListItemIcon>
														<ListItemText
															// className="listItemText-autocomplete"
															primary={
																<span className="smTitle">{v?.name}</span>
															}
														/>
													</ListItem>
												</List>
											))}
									</ContentWrapper>
								</CardExpansion>
							))
						)}
						<DynamicFooter
							style={{
								width:
									isDesktop && globalState.drawerOpen
										? 'calc(100% - 240px)'
										: '100%',
							}}
							options={[
								{
									name: 'Save',
									type: 'submit',
									color: 'primary',
									// onClick: () => {
									//   handleSubmit(onSubmit)();
									// },
								},
							]}
						/>
					</form>
				</List>
			</ContentWrapper>
			{/* <ContentWrapper
				footer
				// style={{ marginTop: '0' }} float
			>
				<DynamicFooter
					options={[
						{
							name: 'Save',
							//   onClick: handleSubmit(data => onSubmit(data, 'submit')),
							// onClick: () => {

							//   if (noError) {
							//     handleCLick();
							//   }
							// },
							color: 'primary',
						},
					]}
				/>
			</ContentWrapper> */}
		</>
	);
};
