import React from 'react';
import ReactDOM from 'react-dom';
import App from './containers/App/App';
import { ServiceWorkerProvider } from 'helpers/hooks/useServiceWorker';
import './index.scss';
import * as serviceWorker from './serviceWorker';
//Strict mode checks are run in development mode only; they do not impact the production build.
ReactDOM.render(
	// <React.StrictMode>
	<ServiceWorkerProvider>
		<App />
	</ServiceWorkerProvider>,
	// </React.StrictMode>,
	document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some import pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register({
	onUpdate: registration => {
		alert('New update(s) has been released. Click OK to proceed.');

		if (registration && registration.waiting) {
			registration.waiting.postMessage({ type: 'SKIP_WAITING' });
		}
		window.location.reload();
	},
});
