import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import {
	useGetAccessSecuritySummaryLazyQuery,
	useGetRoleQuery,
	useGetUsersByAccountAndSoftwareQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import React, { useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};

export const UserAccessSubmenu = () => {
	const history = useHistory();
	const { pathname } = useLocation();

	/////////////////////////////////////
	/////// QUERIES AND MUTATIONS ///////
	/////////////////////////////////////

	const {
		called,
		loading,
		error,
		data: { getRole } = { getRole: [] },
	} = useGetRoleQuery({
		fetchPolicy: 'network-only',
	});

	const {
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		onCompleted: ({ getUsersByAccountAndSoftware }) => {},
	});

	const [
		loadAccessSecuritySummary,
		{
			called: accessSecuritySummaryCalled,
			loading: accessSecuritySummaryLoading,
			data: accessSecuritySummaryData,
		},
	] = useGetAccessSecuritySummaryLazyQuery({
		fetchPolicy: 'network-only',
	});

	useEffect(() => {
		if (!accessSecuritySummaryCalled) {
			loadAccessSecuritySummary({});
		}
	}, [accessSecuritySummaryCalled]);

	const roleDate = accessSecuritySummaryData?.roleDate;
	const userDate = accessSecuritySummaryData?.userDate;

	const menuList = [
		{
			name: 'Users',
			count: getUsersByAccountAndSoftware?.length,
			date: formatDate(userDate),
			path: `/menu/outlet-app/general-setting/user-access/user`,
		},
		{
			name: 'Roles',
			count: getRole?.length,
			date: formatDate(roleDate),
			path: `/menu/outlet-app/general-setting/user-access/role`,
		},
	];

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	return (
		<>
			{/* {loading && <Loading />} */}
			<MainHeader
				onClick={() =>
					history.push({ pathname: `/menu/outlet-app/general-setting` })
				}
				mainBtn="back"
				smTitle={'Outlet App'}
				title={user?.companyName}
				routeSegments={[
					{ name: 'General Settings' },
					{ name: 'Access Security', current: true },
				]}
				// rightRouteSegments={[]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{menuList?.map((v, i) => (
						<ListItem onClick={() => history.push(v.path)}>
							{/* <SettingsIcon color="primary" style={{ paddingRight: '12px' }} /> */}
							<ListItemText
								primary={
									<>
										<span className="desc">
											<span className="xsTitle flex-space">{v?.name} (</span>
											<span className="xsTitle highlight-text">{v?.count}</span>
											<span className="xsTitle">)</span>
										</span>
									</>
								}
								secondary={
									<>
										<span className="desc">
											Last Updated on:{' '}
											<span className="highlight-text">{v.date}</span>
										</span>
									</>
								}
							/>
							<ListItemSecondaryAction>
								<IconButton edge="end" aria-label="delete">
									<KeyboardArrowRight onClick={() => history.push(v.path)} />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					))}
				</List>
			</ContentWrapper>
		</>
	);
};
