import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import { IAction } from 'helpers/model';
import React, { Reducer, useEffect, useReducer, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { addDays } from 'date-fns';
import { useForm } from 'react-hook-form';
import {
	ComputeMethod,
	useGetTaxListingDropdownQuery,
	useIsTaxSameNameLazyQuery,
	useTaxSchemeInsertMutation,
	useTaxSchemeUpdateMutation,
} from 'generated/graphql';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Footer } from '@ifca-root/react-component/src/components/Footer/Footer';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import { useSnackBar } from 'helpers/hooks/useSnackBar';
import { v4 as uuidv4 } from 'uuid';
import { TaxSchemeFormDetail } from './TaxSchemeFormDetail';

export enum ComputeMethodType {
	TaxOnGross = 'TaxOnGross',
	TaxOnTaxedPrice = 'TaxOnTaxedPrice',
}
interface props {
	name: string;
	description: string;
	isInclusive: boolean;
	addDialog: boolean;
	effectiveDate: Date;
	isSameName: boolean;
	taxArray: {
		taxCode: any;
		computeMethod: any;
		sequenceNo: string[];
		oriIndex: any;
	}[];
	taxListing: any;
	isConfirm: boolean;
	isTouched: boolean;
	type: string;
}

export const TaxSchemeForm = (props: any) => {
	let history = useHistory();
	const { mode } = props;
	let location = useLocation();

	const stateData: any = location.state;
	const editData = stateData?.state;

	// const NATime = new Date().setHours(NACutOffTime)
	const nextDate = addDays(new Date(), 1);

	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const { snackFunc, snackBarMessage, openSnackBar } = useSnackBar();

	const initialState: props = {
		name: '',
		description: '',
		isInclusive: false,
		addDialog: false,
		effectiveDate: nextDate,
		isSameName: false,
		taxArray: [
			{
				taxCode: '',
				computeMethod: '',
				sequenceNo: [],
				oriIndex: null,
			},
		],
		taxListing: [],
		isConfirm: false,
		isTouched: false,
		type: '',
	};

	const reducer: Reducer<props, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};
	const [state, dispatch] = useReducer(reducer, initialState);
	const [genDetailID, setDetailID] = useState(null);

	const { control } = useForm<props>({
		shouldUnregister: false,
	});

	useEffect(() => {
		const Detailuuid = uuidv4();
		setDetailID(Detailuuid);
	}, []);

	const {
		data: { getTaxListingDropdown } = { getTaxListingDropdown: [] },
		loading: TaxListingDropdownLoading,
	} = useGetTaxListingDropdownQuery({
		fetchPolicy: 'network-only',
		variables: {
			accountID: user?.accountID,
			softwareCode: 'HotelX',
		},
	});

	const [
		taxSchemeInsert,
		{ loading: loadingTaxSchemeInsert },
	] = useTaxSchemeInsertMutation({});

	const [
		taxSchemeUpdate,
		{ loading: loadingTaxSchemeUpdate },
	] = useTaxSchemeUpdateMutation({});

	useEffect(() => {
		if (mode === 'edit') {
			let taxView = editData?.taxSchemeDetails;

			let data = taxView?.map(x => {
				const taxDetail = {
					id: x?.id,
					effectiveDate: x?.effectiveDate,
					taxSchemeId: x?.taxSchemeId,
					taxInfo: x?.taxSchemeDetailItems?.map(v => {
						const taxDetailItem = {
							id: v?.id,
							taxId: v?.taxId,
							taxSchemeDetailId: v?.taxSchemeDetailId,
							computeMethod:
								v?.computeMethod == ComputeMethod.TaxOnGross
									? 'Tax On Gross'
									: 'Tax On Taxed Price',
							sequenceNo:
								x?.taxOnSeq?.map(z => {
									return z.seqNo?.toString();
								}) || [],
							taxCode: {
								code: getTaxListingDropdown?.find(y => y?.tax_id === v?.taxId)
									?.code,
								rate: getTaxListingDropdown?.find(y => y?.tax_id === v?.taxId)
									?.rate,
								tax_type: getTaxListingDropdown?.find(
									y => y?.tax_id === v?.taxId,
								)?.tax_type,
								class_type: getTaxListingDropdown?.find(
									y => y?.tax_id === v?.taxId,
								)?.class_type,
							},
						};
						return taxDetailItem;
					}),
				};
				return taxDetail;
			});

			data = data.sort(
				(a, b) =>
					Number(new Date(b?.effectiveDate)) -
					Number(new Date(a?.effectiveDate)),
			);

			dispatch({ type: 'taxListing', payload: data });
			dispatch({ type: 'name', payload: editData?.name });
			dispatch({ type: 'description', payload: editData?.description });
			dispatch({
				type: 'isInclusive',
				payload: editData?.isExclusive === false ? true : false,
			});
		}
	}, [mode, getTaxListingDropdown]);

	const onSubmit = () => {
		mode === 'add'
			? taxSchemeInsert({
					variables: {
						accountID: user?.accountID,
						taxSchemeInput: {
							createdBy: user?.ID,
							modBy: user?.ID,
							name: state?.name,
							description: state?.description,
							isExclusive: state?.isInclusive === true ? false : true,
							taxSchemeDetail: state?.taxListing?.map(x => {
								const taxDetail = {
									effectiveDate: new Date(x?.effectiveDate),
									taxSchemeDetailItem: x?.taxInfo?.map((v, index) => {
										const taxDetailItem = {
											taxId: v?.taxCode?.ID,
											classType:
												v?.taxCode?.class_type || v?.taxCode?.classType,
											computeMethod:
												v?.computeMethod == 'Tax On Gross'
													? ComputeMethod.TaxOnGross
													: ComputeMethod.TaxOnTaxedPrice,
											seqNo: index + 1,
											taxOnSeq:
												v?.sequenceNo?.map(el => {
													return {
														taxSchemeDetailItemId: uuidv4(),
														seqNo: Number(el),
													};
												}) || [],
										};
										console.log(v, v?.tax_id);

										return taxDetailItem;
									}),
								};
								console.log(x?.taxInfo, 'x?.taxInfo');
								return taxDetail;
							}),
						},
					},
			  }).then(i => {
					i.data.taxSchemeInsert === true
						? snackFunc('Insert Successfully', true)
						: snackFunc('Insert Failed', false);
			  })
			: taxSchemeUpdate({
					variables: {
						accountID: user?.accountID,
						editTaxSchemeInput: {
							id: editData?.id,
							createdBy: user?.ID,
							modBy: user?.ID,
							name: state?.name,
							description: state?.description,
							isExclusive: state?.isInclusive === true ? false : true,
							taxSchemeDetail: state?.taxListing?.map(x => {
								const taxDetail = {
									detailId: x?.id || genDetailID,
									effectiveDate: x?.effectiveDate,
									taxSchemeDetailItem: x?.taxInfo?.map((v, index) => {
										const taxDetailItem = {
											taxId: v?.taxId || v?.taxCode?.ID,
											detailItemId: v?.id || uuidv4(),
											detailId: v?.taxSchemeDetailId || genDetailID,
											classType:
												v?.taxCode?.class_type || v?.taxCode?.classType,
											computeMethod:
												v?.computeMethod == 'Tax On Gross'
													? ComputeMethod.TaxOnGross
													: ComputeMethod.TaxOnTaxedPrice,
											seqNo: index + 1,
											taxOnSeq:
												v?.sequenceNo?.map(el => {
													return {
														taxSchemeDetailItemId: uuidv4(),
														seqNo: Number(el),
													};
												}) || [],
										};
										return taxDetailItem;
									}),
								};
								return taxDetail;
							}),
						},
					},
			  }).then(i => {
					i.data.taxSchemeUpdate === true
						? snackFunc('Update Successfully', true)
						: snackFunc('Update Failed', false);
			  });
	};

	const [
		loadIsTaxSameName,
		{
			loading: isTaxSameNameLoading,
			data: { isTaxSameName } = { isTaxSameName: null },
		},
	] = useIsTaxSameNameLazyQuery({
		fetchPolicy: 'no-cache',
	});

	return (
		<>
			{TaxListingDropdownLoading && <Loading />}
			{loadingTaxSchemeInsert && <Loading />}
			{loadingTaxSchemeUpdate && <Loading />}

			<MainHeader
				onClick={() =>
					history.push(`/menu/outlet-app/common-setting/tax-scheme-policy`)
				}
				mainBtn="back"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: '' },
					{ name: '' },
					{ name: 'Tax Scheme Policy Form', current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : 'Edit', current: true },
				]}
			/>

			<ContentWrapper footer>
				<TaxSchemeFormDetail
					state={state}
					dispatch={dispatch}
					control={control}
					getTaxListingDropdown={getTaxListingDropdown}
					loadIsTaxSameName={loadIsTaxSameName}
					isTaxSameName={isTaxSameName}
				/>
			</ContentWrapper>

			<Footer
				options={[
					{
						name: 'Save',
						onClick: () => {
							onSubmit();
						},
						color:
							state?.name === '' ||
							state.taxListing?.length === 0 ||
							state?.isConfirm === true ||
							state?.isTouched === false ||
							isTaxSameName === true
								? 'inherit'
								: 'primary',
						disabled:
							state?.name === '' ||
							state.taxListing?.length === 0 ||
							state?.isConfirm === true ||
							state?.isTouched === false ||
							isTaxSameName === true,
					},
				]}
			/>
			<SnackBarMsg open={openSnackBar} message={snackBarMessage} />
		</>
	);
};
