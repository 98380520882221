import React from 'react'
import { Cell, Pie, PieChart, ResponsiveContainer } from 'recharts'

const COLORS = [
  '#5367ff',
  '#34bcd2',
  '#0d71a5',
  '#4c4b66',
  '#f8f0d7',
  '#ffc853',
]

export interface ChartProps {
  data?: any
  outlet?: any
  analysis?: any
  selectedStartDate?: any
  selectedEndDate?: any
  formatDate?: any
}

export const MultiPieChart = ({
  data,
  outlet,
  analysis,
  selectedStartDate,
  selectedEndDate,
  formatDate,
}: ChartProps) => {
  return (
    // <div style={{ width: '100%', height: 300 }}>
    <ResponsiveContainer width={'100%'} height={400}>
      <PieChart margin={{ top: 50, right: 50, left: 50, bottom: 50 }}>
        <Pie
          minAngle={20}
          data={data}
          dataKey="value"
          innerRadius={40}
          label={({
            cx,
            cy,
            midAngle,
            innerRadius,
            outerRadius,
            value,
            percent,
            index,
          }) => {
            const RADIAN = Math.PI / 180
            const radius = 60 + innerRadius + (outerRadius - innerRadius)
            const x = cx + radius * Math.cos(-midAngle * RADIAN)
            const y = cy + radius * Math.sin(-midAngle * RADIAN)

            return (
              <>
                <text
                  x={x}
                  y={y}
                  width={300}
                  fontSize={10}
                  fill={'#737373'}
                  textAnchor={x > cx ? 'start' : 'middle'}
                >
                  <tspan textAnchor="middle" x={x} y={y} fontWeight="700">
                    {data[index].name
                      ?.toLowerCase()
                      .replace(/\b(\w)/g, s => s.toUpperCase())}
                  </tspan>
                  <tspan textAnchor="middle" x={x} y={y} dy="30">
                    ({`${(percent * 100).toFixed(0)}%`})
                  </tspan>
                  <tspan textAnchor="middle" x={x} y={y} dy="15">
                    {`RM ${value}`}
                  </tspan>
                </text>

                {outlet && (
                  <>
                    <text
                      x={0}
                      y={0}
                      dx="50%"
                      dy="5%"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      fontSize={16}
                      fontWeight={500}
                      width={180}
                    >
                      {`${outlet?.name} `}
                    </text>
                    <text
                      x={0}
                      y={0}
                      dx="50%"
                      dy="10%"
                      textAnchor="middle"
                      dominantBaseline="middle"
                      fontSize={12}
                      fontWeight={300}
                      width={180}
                    >
                      {`${analysis} Analysis \n ${formatDate(
                        selectedStartDate.toISOString()
                      )} - ${formatDate(selectedEndDate.toISOString())} `}
                    </text>
                  </>
                )}
              </>
            )
          }}
          // label
          outerRadius={80}
          fill="#FFFFF"
        >
          {data?.map((entry, index) => (
            <Cell key={entry} fill={COLORS[index % COLORS.length]} />
          ))}
        </Pie>
        {/* <Tooltip /> */}
      </PieChart>
    </ResponsiveContainer>
    // </div>
  )
}
