import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import theme from '@ifca-root/react-component/src/assets/theme';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import {
	Grid,
	Paper,
	Table,
	TableBody,
	TableCell,
	TableContainer,
	TableRow,
	useMediaQuery,
} from '@material-ui/core';
import { MonetizationOn } from '@material-ui/icons';
import { CSVLink } from 'react-csv';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { useSnackBar } from 'helpers/hooks/useSnackBar';
import { IAction } from 'helpers/model';
import React, { Reducer, useEffect, useReducer, useRef, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import SnackBarMsg from '@ifca-root/react-component/src/components/SnackBar/SnackBarMsg';
import ShareDialog from 'components/Dialog/ShareDialog';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import ShareIcon from '@material-ui/icons/Share';
import { formatDate } from 'helpers/formatDate';
import {
	useGetGlExportDetailsByTrxTypeQuery,
	useGetOutletQuery,
	useGetShareAttachmentMutation,
} from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import * as htmlToImage from 'html-to-image';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';

interface Props {
	Dialog?: boolean;
	ImageURL?: string;
}

export const GeneralLedgerJournalIndividualDetail = () => {
	useEffect(() => {
		console.log('GeneralLedgerJournalIndividualDetail');
	}, []);

	let history = useHistory();
	let location: any = useLocation();

	let individualState = location.state;

	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const outletID = localStorage.getItem('latestOutlet');

	const csvRef = useRef(null);

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);

	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	const {
		data: { getGLExportDetailsByTrxType } = { getGLExportDetailsByTrxType: [] },
		loading: glExportDetailLoading,
	} = useGetGlExportDetailsByTrxTypeQuery({
		variables: {
			trxDate: individualState.date,
			trxDesc: individualState.description,
			trxType: individualState.glType,
			outletID: outletID,
			accountID: user.accountID,
		},
	});

	const handleShare = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: outletID,
			},
		});
	};

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const exportedGLData = getGLExportDetailsByTrxType?.map(x => ({
		billNo: x.billNo,
		name: x.name,
		amount: x.amount,
	}));

	const Display = (hidden?: boolean) => {
		return (
			<div
				style={
					hidden
						? {
								zIndex: -40,
								top: 0,
								left: 0,
								position: 'fixed',
								width: '73%',
						  }
						: {
								marginTop: '0px',
								position: 'relative',
						  }
				}
				className={hidden ? 'hidden-content' : null}
			>
				<div
					id={!hidden ? 'gl ' : 'hiddenGl'}
					style={
						hidden
							? { marginTop: '-85px', width: '100%', height: '100%' }
							: { width: '100%', height: '100%' }
					}
				>
					<DynamicSubHeader
						style={{
							marginTop: hidden ? '-155px' : null,
							width: hidden ? 'calc (100% - 0px)' : null,
						}}
						title={
							<div
								className="flex xsTitle"
								style={{ justifyContent: 'space-between' }}
							>
								<Grid container direction="row" justifyContent="space-between">
									<span className="color-primary-orange flex-space">
										{formatDate(individualState?.date)}
										<TextSeparator /> {individualState?.department}/
										{individualState?.division}/{individualState?.accountCode}
									</span>
									<span className="icon-text">
										<MonetizationOn />
										{Number(individualState.debit) != 0
											? amtStr(individualState.debit)
											: Number(individualState.debit) == 0
											? amtStr(individualState.credit)
											: null}
									</span>
								</Grid>
							</div>
						}
						infoLine={
							<div className="flex">
								<span className="desc icon-text">
									{individualState.description}
								</span>
							</div>
						}
					/>
					<ContentWrapper style={{ marginTop: hidden ? '76px' : null }}>
						<div className="gl-margin-detail" />
						<TableContainer component={Paper}>
							<Table aria-label="simple table">
								<TableBody className="core-table">
									<TableRow className="gl-export-row">
										<TableCell
											className="header-bg"
											align="center"
											style={{ borderRight: '1px solid #fff' }}
										>
											Bill No.
										</TableCell>
										<TableCell
											className="header-bg"
											align="center"
											style={{ borderRight: '1px solid #fff' }}
										>
											Name
										</TableCell>
										<TableCell className="header-bg" align="center">
											Amount
										</TableCell>
									</TableRow>

									{getGLExportDetailsByTrxType?.map((x, i) => (
										<>
											<TableRow
												className="gl-export-row"
												key={i}
												style={{
													background: '#fff',
												}}
											>
												<TableCell align="center" className="mdLabel">
													{x.billNo}
												</TableCell>
												<TableCell align="center" className="mdLabel">
													{x.name}
												</TableCell>
												<TableCell align="center" className="mdLabel">
													{amtStr(x.amount)}
												</TableCell>
											</TableRow>
										</>
									))}
								</TableBody>
							</Table>
						</TableContainer>
					</ContentWrapper>
				</div>
			</div>
		);
	};

	const handleGlExport = async () => {
		csvRef.current.link.click();
	};

	return (
		<>
			{outletLoading && <Loading />}
			{shareAttachmentLoading && <Loading />}
			{glExportDetailLoading && <Loading />}
			<MainHeader
				onClick={() => history.goBack()}
				mainBtn="back"
				smTitle="PMS"
				title={getOutlet[0]?.name}
				routeSegments={[
					{ name: 'Account Journals' },
					{ name: 'GL Journal' },
					{ name: 'GL Journal' },
					{ name: 'GL Journal', current: true },
				]}
			/>

			{Display()}
			{Display(true)}
			<div className="cover-layer" />
			<div style={{marginBottom: '40px'}}/>

			{!glExportDetailLoading && getGLExportDetailsByTrxType?.length > 0 && (
				<CSVLink
					filename={`GL_Export_${formatDate(individualState.date)}.csv`}
					data={exportedGLData}
					ref={csvRef}
				/>
			)}

			{ShareDialog({
				shareTitle: `GL Export - ${formatDate(individualState.date)}`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}

			<DynamicFooter
				options={[
					{
						name: 'Share ',
						onClick: () => {
							let temp = document.getElementById('hiddenGl');
							htmlToImage
								.toBlob(temp, { style: { background: '#fff' } })
								.then(value => {
									handleShare(value);
								})
								.catch(error => console.error(error, 'Something Went Wrong'));
						},
						color: 'primary',
						props: {
							startIcon: <ShareIcon />,
						},
					},
					{
						name: 'Download',
						onClick: () => {
							handleGlExport();
						},
						color: 'primary',
						props: {
							startIcon: <ShareIcon />,
						},
					},
				]}
			/>
		</>
	);
};
