import { getAccessToken } from 'AccessToken';
import { menuxSocketUrl } from 'POSClient';
import React, {  createContext, useState, useEffect } from 'react'
import io from 'socket.io-client';

export const SocketContext = createContext(null); // Use null as the initial value
export const SocketContextProvider = ({ children }) => {
  const [socket, setSocket] = useState(null);
  const token = getAccessToken();

  useEffect(() => {
    const newSocket = io(menuxSocketUrl);
    setSocket(newSocket);

    // Show the popup dialog when the user tries to leave the page
    const onBeforeUnload = () => {
      newSocket.emit('data', {
        Status: 'LOGOUT',
        OutletID: JSON.parse(localStorage.getItem('latestOutlet')),
        UserID: JSON.parse(localStorage.getItem('loggedInUser'))?.ID,
      });
    };

    window.addEventListener('beforeunload', onBeforeUnload);

    // Reconnect when the token is available
    // if (token) {
    //   newSocket.emit('data', {
    //     Status: 'LOGIN',
    //     OutletID: JSON.parse(localStorage.getItem('latestOutlet')),
    //     UserID: JSON.parse(localStorage.getItem('loggedInUser'))?.ID,
    //   });
    // }

    // Cleanup function
    return () => {
      newSocket.disconnect();
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  }, []); // Add token as a dependency

  return (
    <SocketContext.Provider
      value={{
        socket
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};

