import { createGlobalModuleVariableFunc } from '@devexpress/analytics-core/analytics-internal';
import { TextField } from '@material-ui/core';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import React from 'react';
import { CLIENT_RENEG_LIMIT } from 'tls';


export const Discount = ({ state, dispatch, outlet }) => {
	console.log(state, 'statehere')
	return (
		<>
<TableRow className="mapping-tr">
        <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
          <span className="xsTitle "> DIscount on Closed Bill / Officer Check Bill / Entertainment Bill</span>
        </TableCell>
        <TableCell align="left">
          <span className="xsTitle title-row">-</span>
        </TableCell>
        <TableCell align="left">
          <span className="xsTitle  title-row">-</span>{' '}
        </TableCell>
        <TableCell align="left">
          <span className="xsTitle title-row">-</span>{' '}
        </TableCell>
        <TableCell align="left">
          <span className="xsTitle title-row">-</span>
        </TableCell>
        <TableCell align="left">
          <span className="xsTitle title-row">-</span>
        </TableCell>
      </TableRow>
	
		  {/* GST */}
	
		  {state?.TaxGSTMappingCharges?.length > 0 ?
        <TableRow className="mapping-tr">
          <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
            <span className="xsTitle ">GST</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle  title-row">-</span>{' '}
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>{' '}
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
          <TableCell align="left">
            <span className="xsTitle title-row">-</span>
          </TableCell>
        </TableRow>
        : null}
	
		  {state?.TaxGSTMappingCharges?.map((y, i) => (
			<TableRow className="mapping-tr" key={i}>
			  <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
				 Discount on {y.Description} 
			  </TableCell>
	
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDiv || 'Balance Sheet Division'
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxGSTMappingCharges[i]?.reverseDiv ||
					  state?.TaxGSTMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDiv
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDiv || 'Balance Sheet Division'
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxGSTMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDiv = event.target.value
	
					  dispatch({
						type: 'TaxGSTMappingCharges',
						payload: newArr,
					  })
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDept || 'Balance Sheet Department'
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxGSTMappingCharges[i]?.reverseDept ||
					  state?.TaxGSTMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDept
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDept || 'Balance Sheet Department'
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxGSTMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDept = event.target.value
	
					  dispatch({
						type: 'TaxGSTMappingCharges',
						payload: newArr,
					  })
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">GL</TableCell>
	
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDebit
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxGSTMappingCharges[i]?.reverseDebit ||
					  state?.TaxGSTMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDebit
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDebit
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxGSTMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDebit = event.target.value
	
					  dispatch({
						type: 'TaxGSTMappingCharges',
						payload: newArr,
					  })
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			</TableRow>
		  ))}
	
		  {/* Service Charge */}
	
		  {state?.TaxSCMappingCharges?.length > 0 ?
			<TableRow className="mapping-tr">
			  <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
				<span className="xsTitle ">Service Charge</span>
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle  title-row">-</span>{' '}
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>{' '}
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			</TableRow>
			: null}
	
		  {state?.TaxSCMappingCharges?.map((y, i) => (
			<TableRow className="mapping-tr" key={i}>
			  <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
			     Discount on {y.Description} 
			  </TableCell>
	
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDiv || 'Balance Sheet Division'
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxSCMappingCharges[i]?.reverseDiv ||
					  state?.TaxSCMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDiv
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDiv || 'Balance Sheet Division'
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxSCMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDiv = event.target.value
	
					  dispatch({
						type: 'TaxSCMappingCharges',
						payload: newArr,
					  })
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDept || 'Balance Sheet Department'
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxSCMappingCharges[i]?.reverseDept ||
					  state?.TaxSCMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDept
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDept || 'Balance Sheet Department'
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxSCMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDept = event.target.value
	
					  dispatch({
						type: 'TaxSCMappingCharges',
						payload: newArr,
					  })
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">GL</TableCell>
	
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDebit
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxSCMappingCharges[i]?.reverseDebit ||
					  state?.TaxSCMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDebit
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDebit
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxSCMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDebit = event.target.value
	
					  dispatch({
						type: 'TaxSCMappingCharges',
						payload: newArr,
					  })
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			</TableRow>
		  ))}
	
		  {/* SST */}
	
		  {state?.TaxSSTMappingCharges?.length > 0 ?
			<TableRow className="mapping-tr">
			  <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
				<span className="xsTitle ">Service Tax</span>
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle  title-row">-</span>{' '}
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>{' '}
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			</TableRow>
			: null}
	
		  {state?.TaxSSTMappingCharges?.map((y, i) => (
			<TableRow className="mapping-tr" key={i}>
			  <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
			     Discount on {y.Description} 
			  </TableCell>
	
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDiv || 'Balance Sheet Division'
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxSSTMappingCharges[i]?.reverseDiv ||
					  state?.TaxSSTMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDiv
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDiv || 'Balance Sheet Division'
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxSSTMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDiv = event.target.value
	
					  dispatch({
						type: 'TaxSSTMappingCharges',
						payload: newArr,
					  })
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDept || 'Balance Sheet Department'
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxSSTMappingCharges[i]?.reverseDept ||
					  state?.TaxSSTMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDept
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDept || 'Balance Sheet Department'
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxSSTMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDept = event.target.value
	
					  dispatch({
						type: 'TaxSSTMappingCharges',
						payload: newArr,
					  })
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">GL</TableCell>
	
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDebit
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxSSTMappingCharges[i]?.reverseDebit ||
					  state?.TaxSSTMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDebit
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDebit
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxSSTMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDebit = event.target.value
	
					  dispatch({
						type: 'TaxSSTMappingCharges',
						payload: newArr,
					  })
					  
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			</TableRow>
		  ))}
	
		  {/* VAT */}
	
		  {state?.TaxVATMappingCharges?.length > 0 ?
			<TableRow className="mapping-tr">
			  <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
				<span className="xsTitle ">VAT</span>
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle  title-row">-</span>{' '}
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>{' '}
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			</TableRow>
			: null}
	
		  {state?.TaxVATMappingCharges?.map((y, i) => (
			<TableRow className="mapping-tr" key={i}>
			  <TableCell scope="row" style={{ boxShadow: '7px 0px 10px -8px rgba(138, 138, 138, 0.66)' }}>
			     Discount on {y.Description} 
			  </TableCell>
	
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDiv || 'Balance Sheet Division'
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxVATMappingCharges[i]?.reverseDiv ||
					  state?.TaxVATMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDiv
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDiv || 'Balance Sheet Division'
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxVATMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDiv = event.target.value
	
					  dispatch({
						type: 'TaxVATMappingCharges',
						payload: newArr,
					  })

					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDept || 'Balance Sheet Department'
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxVATMappingCharges[i]?.reverseDept ||
					  state?.TaxVATMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDept
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDept || 'Balance Sheet Department'
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxVATMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  console.log(selected, newArr, 'consolehere')
					  selected.reverseDept = event.target.value
	
					  dispatch({
						type: 'TaxVATMappingCharges',
						payload: newArr,
					  })
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">GL</TableCell>
	
			  <TableCell align="left">
				{state.mode === 'edit' ? (
				  y.outletTaxDiscountGLAcct?.reverseDebit
				) : (
				  <TextField
					id="outlined-basic"
					value={
					  state?.TaxVATMappingCharges[i]?.reverseDebit ||
					  state?.TaxVATMappingCharges[i]?.outletTaxDiscountGLAcct?.reverseDebit
					}
					placeholder={
					  y.outletTaxDiscountGLAcct?.reverseDebit
					}
					className="mapping-textfield"
					variant="outlined"
					onChange={event => {
					  const newArr: any = state.TaxVATMappingCharges
					  let selected = newArr[i]?.outletTaxDiscountGLAcct
					  selected.reverseDebit = event.target.value
	
					  dispatch({
						type: 'TaxVATMappingCharges',
						payload: newArr,
					  })
					}}
				  />
				)}
			  </TableCell>
			  <TableCell align="left">
				<span className="xsTitle title-row">-</span>
			  </TableCell>
			</TableRow>
		  ))}
		</>
	  )
};
