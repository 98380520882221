import { Grid, IconButton } from '@material-ui/core';
import AddOrderButtonIcon from 'assets/icons/add_order_button.svg';
import AddOrderButtonGreyIcon from 'assets/icons/add_order_button_grey.svg';
import CancelOrderButtonIcon from 'assets/icons/cancel_order_button.svg';
import CancelOrderButtonGreyIcon from 'assets/icons/cancel_order_button_grey.svg';
import MakePaymentButtonIcon from 'assets/icons/make_payment_button.svg';
import MakePaymentButtonGreyIcon from 'assets/icons/make_payment_button_grey.svg';
import PickupButtonIcon from 'assets/icons/pickup_button.svg';
import PickupButtonGreyIcon from 'assets/icons/pickup_button_grey.svg';
import { PosPermission } from 'generated/graphql';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import React from 'react';
import { useHistory } from 'react-router';
interface TakeawayProps {
	pickupDialog: any;
	cancelDialog: any;
	paymentDialog: any;
	addOrderPath: any;
	discountPath: any;
	outstandingAmount: any;
	orderStatus: any;
	outletID: any;
	style?: React.CSSProperties;
}

export const TakeawayFooter = (props: TakeawayProps) => {
	const { handlePermDisabled } = usePermissionChecker();
	const history = useHistory();
	const {
		pickupDialog,
		cancelDialog,
		paymentDialog,
		addOrderPath,
		discountPath,
		outstandingAmount,
		orderStatus,
		outletID,
		style,
	} = props;

	return (
		<div className="footer" style={{ ...style }}>
			<Grid container>
				<Grid item xs={2} style={{ textAlign: 'center' }}>
					<IconButton
						style={{ paddingBottom: '1px', paddingTop: '7px' }}
						onClick={() => {
							pickupDialog(true);
						}}
						disabled={
							handlePermDisabled({
								outletID: outletID,
								permEnum: PosPermission.TakeawayPickUpUpdate,
							}) ||
							(orderStatus === 'PICKED_UP' ||
							orderStatus === 'CANCELLED' ||
							orderStatus === 'OPEN'
								? true
								: false)
						}
					>
						<img
							style={{
								padding: '4px',
								width: '26px',
								height: '26px',
							}}
							color={'inherit'}
							src={
								handlePermDisabled({
									outletID: outletID,
									permEnum: PosPermission.TakeawayPickUpUpdate,
								}) ||
								orderStatus === 'PICKED_UP' ||
								orderStatus === 'CANCELLED' ||
								orderStatus === 'OPEN'
									? PickupButtonGreyIcon
									: PickupButtonIcon
							}
						/>
					</IconButton>
					<div className="desc">Pick Up</div>
				</Grid>

				<Grid item xs={3} style={{ textAlign: 'center' }}>
					<IconButton
						style={{ paddingBottom: '1px', paddingTop: '7px' }}
						onClick={() => {
							history.push(addOrderPath);
						}}
						disabled={
							handlePermDisabled({
								outletID: outletID,
								permEnum: PosPermission.TakeawayAddOrderCreate,
							}) ||
							(orderStatus === 'PICKED_UP' ||
							orderStatus === 'CANCELLED' ||
							orderStatus === 'CLOSED'
								? true
								: false)
						}
					>
						<img
							style={{
								padding: '4px',
								width: '26px',
								height: '26px',
							}}
							src={
								handlePermDisabled({
									outletID: outletID,
									permEnum: PosPermission.TakeawayAddOrderCreate,
								}) ||
								orderStatus === 'PICKED_UP' ||
								orderStatus === 'CANCELLED' ||
								orderStatus === 'CLOSED'
									? AddOrderButtonGreyIcon
									: AddOrderButtonIcon
							}
						/>
					</IconButton>
					<div className="desc">Add Order</div>
				</Grid>

				<Grid item xs={2} style={{ textAlign: 'center' }}>
					<IconButton
						style={{ paddingBottom: '1px', paddingTop: '7px' }}
						onClick={() => {
							cancelDialog(true);
						}}
						disabled={
							handlePermDisabled({
								outletID: outletID,
								permEnum: PosPermission.TakeawayCancelOrderUpdate,
							}) ||
							(orderStatus === 'PICKED_UP' ||
							orderStatus === 'CANCELLED' ||
							orderStatus === 'CLOSED'
								? true
								: false)
						}
					>
						<img
							style={{
								width: '36px',
								height: '36px',
							}}
							src={
								handlePermDisabled({
									outletID: outletID,
									permEnum: PosPermission.TakeawayCancelOrderUpdate,
								}) ||
								orderStatus === 'PICKED_UP' ||
								orderStatus === 'CANCELLED' ||
								orderStatus === 'CLOSED'
									? CancelOrderButtonGreyIcon
									: CancelOrderButtonIcon
							}
						/>
					</IconButton>
					<div className="desc">Cancel Order</div>
				</Grid>

				<Grid item xs={3} style={{ textAlign: 'center' }}>
					<IconButton
						style={{ paddingBottom: '1px', paddingTop: '7px' }}
						onClick={() => {
							history.push(discountPath);
						}}
						disabled={
							handlePermDisabled({
								outletID: outletID,
								permEnum: PosPermission.TakeawayAddOrderCreate,
							}) ||
							(orderStatus === 'PICKED_UP' ||
							orderStatus === 'CANCELLED' ||
							orderStatus === 'CLOSED'
								? true
								: false)
						}
					>
						<img
							style={{
								padding: '4px',
								width: '26px',
								height: '26px',
							}}
							src={
								handlePermDisabled({
									outletID: outletID,
									permEnum: PosPermission.TakeawayAddOrderCreate,
								}) ||
								orderStatus === 'PICKED_UP' ||
								orderStatus === 'CANCELLED' ||
								orderStatus === 'CLOSED'
									? AddOrderButtonGreyIcon
									: AddOrderButtonIcon
							}
						/>
					</IconButton>
					<div className="desc">Discount</div>
				</Grid>

				<Grid item xs={2} style={{ textAlign: 'center' }}>
					<IconButton
						style={{ paddingBottom: '1px', paddingTop: '7px' }}
						onClick={() => {
							if (outstandingAmount !== 0) {
								paymentDialog(true);
							}
						}}
						disabled={
							handlePermDisabled({
								outletID: outletID,
								permEnum: PosPermission.TakeawayMakePaymentCreate,
							}) ||
							(orderStatus === 'PICKED_UP' ||
							orderStatus === 'CANCELLED' ||
							orderStatus === 'CLOSED'
								? true
								: false)
						}
					>
						<img
							style={{
								padding: '4px',
								width: '26px',
								height: '26px',
							}}
							src={
								handlePermDisabled({
									outletID: outletID,
									permEnum: PosPermission.TakeawayMakePaymentCreate,
								}) ||
								orderStatus === 'PICKED_UP' ||
								orderStatus === 'CANCELLED' ||
								orderStatus === 'CLOSED'
									? MakePaymentButtonGreyIcon
									: MakePaymentButtonIcon
							}
						/>
					</IconButton>
					<div className="desc">Make Payment</div>
				</Grid>
			</Grid>
		</div>
	);
};
