import { differenceInDays, format, parseISO } from 'date-fns';

export const formatDate = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'dd MMM yyyy') : 'NA';
};

export const convertDate = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'dd MMM yyyy, hh:mm a') : 'NA';
};

export const convertDate24Hrs = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'dd/MM/yyyy, HH:mm') : 'NA';
};

export const dateFormat = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'dd/MM/yyyy') : 'NA';
};

export const formatTime = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'hh:mm a') : 'NA';
};

export const formatTimeSecond = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'HH:mm:ss') : 'NA';
};

export const formatTimeDate = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'hh:mm a, dd MMM') : 'NA';
};

export const formatDay = (day: string) => {
	const result = parseISO(day);
	return day ? format(result, 'EEEE') : 'NA';
};

// export const dateDifferences = date => {
//   var DateDifferences = formatDistance(new Date(), Date.parse(date), {
//     includeSeconds: true,
//   });
//   return DateDifferences;

//   // var today = new Date();

//   // var TimeDifference = today.getTime() - Date.parse(date);
//   // // One day/hour/min Time in ms (milliseconds)
//   // var inDay = 1000 * 60 * 60 * 24;
//   // var inHour = 1000 * 60 * 60;
//   // var inMin = 1000 * 60;
//   // var inSec = 1000;

//   // var DaysDifference = TimeDifference / inDay;
//   // var HoursDifference = TimeDifference / inHour;
//   // var MinsDifference = TimeDifference / inMin;
//   // let x, result;
//   // if (DaysDifference >= 1) {
//   //   x = DaysDifference.toFixed(0);
//   //   result = x + ' Day(s) ago';
//   //   return result;
//   // } else if (DaysDifference < 1 && HoursDifference >= 1) {
//   //   x = HoursDifference.toFixed(0);
//   //   result = x + ' Hr(s) ago';
//   //   return result;
//   // } else {
//   //   x = MinsDifference.toFixed(0);
//   //   result = x + ' Min(s) ago';
//   //   return result;
//   // }
// };

export const daysDifferences = (date: string) => {
	var DateDifferences = differenceInDays(new Date(date), new Date());
	return DateDifferences;
};

// export const checkDateMoreThan = date => {
//   const inputDate = new Date(date);
//   const currDate = new Date();

//   const yearEqual = inputDate.getFullYear() == currDate.getFullYear();
//   const monthEqual = inputDate?.getMonth() == currDate?.getMonth();
//   const dateEqual = inputDate?.getDate() == currDate?.getDate();
//   const hourEqual = inputDate?.getHours() == currDate?.getHours();
//   const minEqual = inputDate?.getMinutes() == currDate?.getMinutes();
//   // const secEqual = inputDate?.getSeconds() == currDate?.getSeconds()

//   if (inputDate.getFullYear() > currDate.getFullYear()) {
//     return true;
//   } else if (yearEqual && inputDate?.getMonth() > currDate?.getMonth()) {
//     return true;
//   } else if (
//     yearEqual &&
//     monthEqual &&
//     inputDate?.getDate() > currDate?.getDate()
//   ) {
//     return true;
//   } else if (
//     yearEqual &&
//     monthEqual &&
//     dateEqual &&
//     inputDate?.getHours() > currDate?.getHours()
//   ) {
//     return true;
//   }

//   // else if (
//   //   yearEqual &&
//   //   monthEqual &&
//   //   dateEqual &&
//   //   hourEqual &&
//   //   inputDate?.getMinutes() > currDate?.getMinutes()
//   // ) {
//   //   return true;
//   // } else if (
//   //   yearEqual &&
//   //   monthEqual &&
//   //   dateEqual &&
//   //   hourEqual &&
//   //   minEqual &&
//   //   inputDate?.getSeconds() > currDate?.getSeconds()
//   // ) {
//   //   return true;
//   // }
//   else {
//     return false;
//   }
// };
