import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import {
	GetTaxListingDocument,
	PosPermission,
	useGetTaxDetailsLazyQuery,
	useGetTaxListingQuery,
	useGetTaxTypeListingQuery,
	useIsTaxInUseLazyQuery,
	useTaxDeleteMutation,
	// useGetTaxSchemeListingQuery,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import { SystemMsgs } from 'helpers/SystemMsg';
import AppContext from 'containers/App/Store/AppContext';
import DropdownBar from '@ifca-root/react-component/src/components/SpecialBar/DropdownBar';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';

export enum SchemeType {
	GST = 'GST',
	ServiceCharge = 'Service Charge',
	ServiceTax = 'Service Tax',
	SST = 'SST',
	VAT = 'VAT',
}

export const TaxSetupListing = () => {
	const { handlePermDisabled }: any = usePermissionChecker();
	const { outletID, taxId } = useParams<any>();

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	let history = useHistory();
	const [rerender, setRender] = useState(false);

	const [anchor, setAnchor] = React.useState<null | HTMLElement>(null);
	const handleClickSearch = (event: React.MouseEvent<HTMLElement>) => {
		setAnchor(event.currentTarget);
	};

	const handleCloseSearch = () => {
		setAnchor(null);
	};

	const [selectedIndex, setSelectedIndex] = React.useState(0);

	const handleSearchClick = (
		// event: React.MouseEvent<HTMLElement>,
		event: any,
		index: number,
		option: any,
	) => {
		setSelectedIndex(index);
		setAnchor(null);
		console.log('option', option);
		setTaxType(
			option?.name === 'ServiceCharge' || option?.name === 'Service Charge'
				? 'Service Charge'
				: option?.name,
		);
	};

	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const taxSearch = JSON.parse(localStorage.getItem('searchFilter'));

	const [isDelete, setIsDelete] = useState(false);
	const user = JSON.parse(localStorage.getItem('loggedInUser'));
	const [taxListingData, setTaxListingData] = useState(null);

	const { globalState, dispatch }: any = useContext(AppContext as any);

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const {
		data: { getTaxTypeListing } = { getTaxTypeListing: [] },
		loading: taxTypeLoading,
		error: taxTypeError,
	} = useGetTaxTypeListingQuery({
		fetchPolicy: 'network-only',
		variables: { softwareCode: 'HotelX' },
	});

	const [taxTypeListing, setTaxTypeListing] = useState(null);

	useEffect(() => {
		if (getTaxTypeListing?.length > 0) {
			const tax = getTaxTypeListing
				?.filter(x => x.code === 'SC' || x.code === '02')
				?.map(y => {
					if (y.name === 'ServiceCharge') {
						return {
							...y,
							name: 'Service Charge',
						};
					} else {
						return y;
					}
				});

			setTaxTypeListing(tax); // Setting the state with the filtered result
		} else {
			// If there's no tax type listing, set the state to an empty array or handle it accordingly
			setTaxTypeListing([]);
		}
	}, [getTaxTypeListing]);

	const {
		data: { getTaxListing } = { getTaxSchemeListing: [] },
		loading: taxLoading,
		error: taxError,
	} = useGetTaxListingQuery({
		fetchPolicy: 'network-only',
		variables: { accountID: user?.accountID },
	});

	const [latestTax, setLatestTax] = useState<any>(null);

	useEffect(() => {
		if (getTaxListing?.length > 0) {
			setLatestTax(
				getTaxListing?.reduce((a, b) => (a.createdTs > b.createdTs ? a : b)),
			);
		}
	}, [getTaxListing]);

	useEffect(() => {
		if (latestTax) {
			setTaxType(
				latestTax?.name === 'ServiceCharge'
					? 'Service Charge'
					: latestTax?.name,
			);
		}
	}, [latestTax]);

	const [taxType, setTaxType] = useState<any>(SchemeType.ServiceCharge);

	const [
		getTaxDetailsQuery,
		{ data: { getTaxDetails } = { getTaxDetails: null } },
	] = useGetTaxDetailsLazyQuery();

	const [
		IsTaxInUseQuery,
		{ data: { isTaxInUse } = { isTaxInUse: [] } },
	] = useIsTaxInUseLazyQuery();

	const taxByID = getTaxTypeListing?.filter(x => x?.id === taxId)[0];

	const onClickAdd = () => {
		history.push(`/menu/outlet-app/common-setting/tax-policy/add`);
	};

	useEffect(() => {
		if (getTaxListing && getTaxListing?.length > 0 && !taxLoading) {
			setOriginalListing(getTaxListing);
		}
	}, [getTaxListing, taxLoading]);

	useEffect(() => {
		if (!!taxSearch?.taxList && getTaxListing?.length > 0 && !taxLoading) {
			setRender(true);
			handleSearch(`'"${taxSearch?.taxList ?? ''}"`, [
				'code',
				'description',
				'rate',
			]);
		}
	}, [taxLoading, rerender, getTaxListing]);

	const [taxDelete, { loading: TaxDeleteLoading }] = useTaxDeleteMutation({
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	const onDelete = () => {
		const taxDeleteInput = getTaxDetails?.taxDetail?.map(el => {
			const input = {
				id: el?.id,
				taxId: menu?.obj?.tax_id,
				effectiveDate: new Date(el?.effectiveDate),
				rate: Number(el?.rate),
			};
			return input;
		});

		taxDelete({
			variables: {
				taxID: menu?.obj?.tax_id,
				taxDeleteInput: taxDeleteInput,
			},
			refetchQueries: [
				{
					query: GetTaxListingDocument,
					variables: { accountID: user?.accountID, taxTypeID: taxId },
				},
			],
		});
	};

	console.log('taxType', taxType);

	const deleteDialog = (
		<>
			<CommonDialog
				fullWidth={true}
				open={isDelete}
				onClose={() => setIsDelete(false)}
				sections={{
					header: {
						dynamic: (
							<div className="">
								<div className="dialog-dynamic-content">
									<div
										className="session"
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
										}}
									>
										<div className="title flex-space">Tax</div>
										<div className="title">Delete</div>
									</div>
								</div>
							</div>
						),
					},
					body: () => (
						<div className="fs-14 fw-medium flex-space">
							Code : {menu?.obj?.code}
						</div>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setIsDelete(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										onDelete();
										setIsDelete(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/>
		</>
	);

	return (
		<>
			<MainHeader
				onClick={() => history.push(`/menu/outlet-app/common-setting`)}
				mainBtn="back"
				smTitle="Outlet App"
				title={user?.companyName}
				routeSegments={[
					{ name: '' },
					{ name: '' },
					{
						name: 'Tax Policy Listing',
						current: true,
					},
				]}
			/>
			<div
				className={
					globalState.drawerOpen
						? 'search-filter with-dropdown-filter-general-settings'
						: 'search-filter with-dropdown-filter-general-settings-fw'
				}
			>
				<SearchHeader
					title={'Tax Policy Listing'}
					value={`${
						filteredList?.filter(e => {
							if (taxType === 'Service Charge') {
								return e?.name === 'ServiceCharge';
							} else if (taxType === SchemeType.ServiceTax) {
								return e?.name === 'Service Tax';
							} else if (taxType === SchemeType.GST) {
								return e?.name === 'GST';
							} else if (taxType === SchemeType.VAT) {
								return e?.name === 'VAT';
							}
						})?.length
					}`}
					onChangeAction={e => {
						handleSearch(`'"${e?.target?.value}"`, [
							'code',
							'description',
							'rate',
						]);
						localStorage.setItem(
							`searchFilter`,
							JSON.stringify({
								taxList: e.target.value ?? '',
							}),
						);
					}}
					search
					isDefaultValue={!!taxSearch?.taxList ?? false}
					defaultValue={taxSearch?.taxList ?? ''}
					onCloseAction={() => {
						localStorage.setItem(
							`searchFilter`,
							JSON.stringify({
								taxList: '',
							}),
						);
						handleSearch('', []);
					}}
				/>
				<div className="filter-dropdown">
					<List
						disablePadding
						className="search-header"
						// style={{ borderRadius: borderRadius }}
					>
						<ListItem
							button
							aria-haspopup="true"
							aria-controls="lock-menu"
							onClick={handleClickSearch}
						>
							<ListItemText
								disableTypography
								primary={
									<span className="xsTitle">
										{taxTypeListing?.filter(x => x.name === taxType)[0]?.name}
									</span>
								}
							/>
							<ListItemSecondaryAction>
								<IconButton
									edge="end"
									aria-label="arrow"
									onClick={handleClickSearch}
								>
									<KeyboardArrowDownIcon />
								</IconButton>
							</ListItemSecondaryAction>
						</ListItem>
					</List>
					<Menu
						id="lock-menu"
						anchorEl={anchor}
						keepMounted
						open={Boolean(anchor)}
						onClose={handleCloseSearch}
					>
						{taxTypeListing?.map((option, index) => (
							<MenuItem
								key={index}
								// selected={index === selectedIndex}
								value={option?.id}
								onClick={event =>
									option?.unclickable
										? () => {}
										: handleSearchClick(event, index, option)
								}
							>
								{option?.name}
							</MenuItem>
						))}
					</Menu>
				</div>
			</div>
			<ContentWrapper float search>
				<List className="core-list">
					{!taxLoading &&
					!taxTypeLoading &&
					(getTaxListing === undefined || getTaxListing?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add tax to continue"
						/>
					) : (
						filteredList
							?.filter(e => {
								if (taxType === 'Service Charge') {
									return e?.name === 'ServiceCharge';
								} else if (taxType === SchemeType.ServiceTax) {
									return e?.name === 'Service Tax';
								} else if (taxType === SchemeType.GST) {
									return e?.name === 'GST';
								} else if (taxType === SchemeType.VAT) {
									return e?.name === 'VAT';
								}
							})
							?.map((el, index) => (
								<ListItem>
									<ListItemText
										primary={
											<>
												<span className="xsTitle flex-space">{el?.code}</span>
												<span className="fs-12 fw-normal">
													<span style={{ paddingRight: '7px' }}>
														{formatDate(el?.effective_date)}
													</span>
													<span>{el?.rate}%</span>
												</span>
											</>
										}
										secondary={
											<>
												<span className="desc flex-space">
													{el?.description}
												</span>
												<span className="desc">Output</span>
											</>
										}
									/>

									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="more"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => {
												handleClick(e, el.Code, index, el);
												IsTaxInUseQuery({
													variables: {
														taxID: el?.tax_id,
														accountID: user?.accountID,
													},
												});
												getTaxDetailsQuery({
													variables: {
														taxID: el?.tax_id,
														accountID: user?.accountID,
													},
												});
											}}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() => {
							history.push(
								`/menu/outlet-app/common-setting/tax-policy/view/${menu?.obj?.tax_id}`,
								{
									TaxInfo: menu?.obj,
								},
							);
						}}
					>
						<span className="">View</span>
					</MenuItem>
					<MenuItem
						disabled={handlePermDisabled({
							outletID: outletID,
							permEnum: PosPermission.CommonSettingTaxPolicyUpdate,
						})}
						onClick={() => {
							history.push(
								`/menu/outlet-app/common-setting/tax-policy/${menu?.obj?.tax_id}/edit`,
							);
						}}
					>
						<span className="">Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setIsDelete(true);
						}}
						disabled={
							handlePermDisabled({
								outletID: outletID,
								permEnum: PosPermission.CommonSettingTaxPolicyDelete,
							}) ||
							isTaxInUse === true ||
							TaxDeleteLoading === true
						}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>
			{deleteDialog}
			<FloatButton
				onClick={() => onClickAdd()}
				disabled={handlePermDisabled({
					outletID: outletID,
					permEnum: PosPermission.CommonSettingTaxPolicyCreate,
				})}
			/>
		</>
	);
};
