import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { List } from '@material-ui/core';
import { useGetUserQuery } from 'generated/graphql';
import React from 'react';
import { useHistory, useParams } from 'react-router-dom';
import SuperUserIcon from '../../../../../assets/icons/superuser-detail.svg';

export const UserDetail = () => {
	let history = useHistory();
	const { userID, mode: pageMode }: any = useParams();
	const {
		loading,
		error,
		data: { getUser } = { getUser: [] },
	}: any = useGetUserQuery({
		fetchPolicy: 'network-only',
		// client: ContractClient,
		variables: {
			ID: userID,
		},
	});

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	return (
		<>
			{loading && <Loading />}
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/general-setting/user-access/user`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={user?.companyName}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Outlet Settings' },
					{ name: 'User', current: true },
				]}
				rightRouteSegments={[{ name: 'Details', current: true }]}
			/>
			<List className="core-list subheader single">
				{/* <ListItem>
              <ListItemText
                primary={
                  <>
                    <span className="xsTitle c-darkblue">User Details</span>
                  </>
                }
              />
            </ListItem> */}

				<ContentWrapper>
					<CardContents>
						<div className="content-wrap full">
							<div className="desc">Username</div>
							<div className="xsTitle">{getUser?.userName}</div>
						</div>

						<div className="content-wrap full">
							<div className="desc">Full Name</div>
							<div className="xsTitle">{getUser?.name ?? '-'}</div>
						</div>
						{/* <div className="content-wrap full">
							<div className="desc">Department</div>
							<div className="xsTitle">{getUser?.department ?? '-'}</div>
						</div>
						<div className="content-wrap full">
							<div className="desc">Designation</div>
							<div className="xsTitle">{getUser?.jobTitle ?? '-'}</div>
						</div> */}
						<div className="content-wrap full">
							<div className="desc">Email</div>
							<div className="xsTitle">{getUser?.email}</div>
						</div>
						<div className="content-wrap full">
							<div className="desc">Mobile No.</div>
							<div className="xsTitle">{getUser?.contactNo}</div>
						</div>
						<div className="content-wrap full">
							<div className="desc">Status</div>
							<div className="xsTitle" style={{ display: 'flex' }}>
								{getUser?.superUser && (
									<div
										className="orange-status"
										style={{
											width: '85px',
											borderRadius: '2px',
											margin: 0,
										}}
									>
										<div style={{ display: 'inline-flex', paddingTop: '5px' }}>
											<img src={SuperUserIcon} alt="" />
											Super User
										</div>
									</div>
								)}
								{getUser?.commonStatus === 'ACTIVE' && (
									<div
										className="blue-status"
										style={{ paddingTop: '5px', borderRadius: '2px' }}
									>
										Active
									</div>
								)}
							</div>
						</div>
					</CardContents>
				</ContentWrapper>
			</List>
		</>
	);
};
