import {
	Grid,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	TextField,
} from '@material-ui/core';
import { ProfileIcon } from 'assets';
import { PaymentClass } from 'generated/graphql';
import { amtStr } from 'helpers/numFormatter';
import React from 'react';
import {
	DefaultListProps,
	ListFieldProps,
	SearchFieldProps,
} from './DialogInterface';

const listField = paymentEnum => {
	let header, showCaption;
	switch (paymentEnum) {
		case PaymentClass.Room:
			header = 'Hotel Guest Info';
			showCaption = true;
			break;
		case PaymentClass.NonGuest:
			header = 'Guest Info';
			showCaption = true;
			break;
		case PaymentClass.SuspendFolio:
			header = 'Suspend Folio Info';
			showCaption = true;
			break;
		case PaymentClass.Debtor:
			header = 'Debtor Info';
			showCaption = true;
			break;
		case PaymentClass.Officer:
			header = 'Officer Info';
			showCaption = false;
			break;
		case PaymentClass.Staff:
			header = 'Staff Info';
			showCaption = false;
			break;
	}

	return { header: header, showCaption: showCaption };
};

const listFieldPatron = patronEnum => {
	let header, showCaption;
	switch (patronEnum) {
		case 'Hotel Guest':
			header = 'Hotel Guest Info';
			showCaption = true;
			break;
		case 'Officer':
			header = 'Officer Info';
			showCaption = false;
			break;
		case 'Staff':
			header = 'Staff Info';
			showCaption = false;
			break;
	}
	return { header: header, showCaption: showCaption };
};

export const SearchField = (props: SearchFieldProps) => {
	const {
		defaultValue,
		label,
		data,
		paymentEnum,
		patronEnum,
		reducerState,
		tempState,
		defaultField,
		onChange,
		selectedField,
		total,
		defaultListField,
		listField,
	} = props;

	return (
		<>
			{/* Search field */}
			{/* Search field */}

			{data === paymentEnum && reducerState ? (
				<DialogTextField
					onChange={onChange}
					label={label}
					defaultValue={defaultValue}
					helperText={
						// paymentEnum !== PaymentClass.NonGuest &&
						(defaultField?.CreditBalance < total ||
							selectedField?.CreditBalance < total) &&
						listField?.fieldKey[0] != 'SuspendFolio'
							? 'Not Enough Credit Balance!'
							: null
					}
					error={
						// paymentEnum !== PaymentClass.NonGuest &&
						(defaultField?.CreditBalance < total ||
							selectedField?.CreditBalance < total) &&
						listField?.fieldKey[0] != 'SuspendFolio'
					}
				/>
			) : null}

			{data === patronEnum ? (
				<DialogTextField
					onChange={onChange}
					label={label}
					defaultValue={defaultValue}
					helperText={
						tempState !== '' &&
						(defaultField?.CreditBalance < total ||
							selectedField?.CreditBalance < total)
							? 'Not enough Credit Balance!'
							: null
					}
					error={
						tempState !== '' &&
						(defaultField?.CreditBalance < total ||
							selectedField?.CreditBalance < total)
					}
				/>
			) : null}

			{/* default display */}
			{data === paymentEnum &&
			reducerState &&
			tempState === '' &&
			defaultField !== undefined &&
			defaultField !== null ? (
				<DefaultField
					defaultField={defaultField}
					paymentEnum={paymentEnum}
					type={listField?.type}
					data={listField?.data}
					balance={listField?.balance}
				/>
			) : null}

			{data === paymentEnum && reducerState && tempState ? (
				<ListField
					balance={listField?.balance}
					arg={listField?.arg}
					data={listField?.data}
					fieldKey={listField?.fieldKey}
					filterCondition={listField?.filterCondition}
					filteredList={listField?.filteredList}
					selectedListItem={listField?.selectedListItem}
					setSelectedListItem={listField?.setSelectedListItem}
					type={listField?.type}
					paymentEnum={paymentEnum}
					register={listField?.register}
					setValue={listField?.setValue}
					defaultPayment={listField?.defaultPayment}
				/>
			) : null}

			{data === patronEnum &&
			tempState === '' &&
			defaultField !== undefined &&
			defaultField !== null ? (
				<DefaultFieldPatron
					defaultField={defaultField}
					patronEnum={patronEnum}
					type={listField?.type}
					data={listField?.data}
					balance={listField?.balance}
				/>
			) : null}

			{data === patronEnum && tempState ? (
				<ListFieldPatron
					balance={listField?.balance}
					arg={listField?.arg}
					data={listField?.data}
					fieldKey={listField?.fieldKey}
					filterCondition={listField?.filterCondition}
					filteredList={listField?.filteredList}
					selectedListItem={listField?.selectedListItem}
					setSelectedListItem={listField?.setSelectedListItem}
					type={listField?.type}
					patronEnum={patronEnum}
				/>
			) : null}
		</>
	);
};

export const DialogTextField = props => {
	const { onChange, label, defaultValue, helperText, error } = props;

	return (
		<TextField
			style={{ marginTop: '5px', marginBottom: '10px' }}
			onChange={onChange}
			label={label}
			variant="standard"
			defaultValue={defaultValue}
			// value={defaultValue}
			helperText={helperText}
			error={error}
		/>
	);
};

export const DefaultField = (props: DefaultListProps) => {
	const { defaultField, paymentEnum, type, data, balance } = props;

	return (
		<ListItem>
			<Grid container>
				<ListItemIcon>
					<Grid item xs={2} style={{ paddingTop: '5px' }}>
						<div>
							<img
								style={{
									height: '40px',
									width: '40px',
									borderRadius: '30px',
									marginRight: '10px',
								}}
								src={ProfileIcon}
							/>
						</div>
						{listField(paymentEnum).showCaption && (
							<div>
								<span
									className="desc"
									style={{
										color: '#000',
										textAlign: 'center',
										paddingLeft: '10px',
									}}
								>
									{defaultField?.[type]}
								</span>
							</div>
						)}
					</Grid>
				</ListItemIcon>

				<ListItemText
					primary={
						<Grid
							item
							xs={10}
							style={{
								paddingLeft: '10px',
								width: '150px',
							}}
						>
							<div>
								<span className="desc" style={{ color: '#0000008A' }}>
									{listField(paymentEnum).header}
								</span>
							</div>
							<div>
								<span>{defaultField?.[data]}</span>
							</div>
							{paymentEnum === PaymentClass.Room ||
							paymentEnum === PaymentClass.Debtor ||
							paymentEnum === PaymentClass.NonGuest ? (
								<div>
									<span className="desc">
										Credit Balance:
										<span className="highlight-text">
											&nbsp;{amtStr(defaultField?.[balance])}
										</span>
									</span>
								</div>
							) : (
								<div>
									<span className="desc">{defaultField?.[type]}</span>
								</div>
							)}
						</Grid>
					}
				/>
			</Grid>
		</ListItem>
	);
};

export const DefaultFieldPatron = (props: DefaultListProps) => {
	const { defaultField, patronEnum, type, data, balance } = props;

	return (
		<ListItem>
			<Grid container>
				<ListItemIcon>
					<Grid item xs={2} style={{ paddingTop: '5px' }}>
						<div>
							<img
								style={{
									height: '40px',
									width: '40px',
									borderRadius: '30px',
									marginRight: '10px',
									paddingTop: '10px',
								}}
								src={ProfileIcon}
							/>
						</div>
						{listFieldPatron(patronEnum).showCaption && (
							<div>
								<span
									className="desc"
									style={{
										color: '#000',
										textAlign: 'center',
										paddingLeft: '10px',
									}}
								>
									{defaultField?.[type]}
								</span>
							</div>
						)}
					</Grid>
				</ListItemIcon>

				<ListItemText
					primary={
						<Grid
							item
							xs={10}
							style={{
								paddingLeft: '10px',
								width: '150px',
							}}
						>
							<div>
								<span className="desc" style={{ color: '#0000008A' }}>
									{listFieldPatron(patronEnum).header}
								</span>
							</div>
							<div>
								<span>{defaultField?.[data]}</span>
							</div>
							{patronEnum === 'Hotel Guest' ? (
								<div>
									<span className="desc">
										Credit Balance:
										<span className="highlight-text">
											&nbsp;{amtStr(defaultField?.[balance])}
										</span>
									</span>
								</div>
							) : (
								<div>
									<span className="desc">{defaultField?.[type]}</span>
								</div>
							)}
						</Grid>
					}
				/>
			</Grid>
		</ListItem>
	);
};

export const ListField = (props: ListFieldProps) => {
	const {
		filteredList,
		data,
		filterCondition,
		selectedListItem,
		setSelectedListItem,
		type,
		arg,
		fieldKey,
		balance,
		paymentEnum,
		register,
		setValue,
		defaultPayment,
	} = props;

	const mapCondition = () => {
		if (fieldKey.includes('OFFICER') || fieldKey.includes('STAFF')) {
			return filteredList;
		} else {
			return filteredList?.filter(x => fieldKey.includes(x?.[filterCondition]));
		}
	};

	return (
		<>
			<List>
				{mapCondition()?.map((x, index) => {
					return (
						<ListItem
							key={index}
							alignItems="flex-start"
							button
							onClick={() => {
								setSelectedListItem(x);

								register(`${defaultPayment}.registrationID`);
								setValue(`${defaultPayment}.registrationID`, x?.RegistrationID);
							}}
							selected={selectedListItem?.[arg] === x?.[arg]}
						>
							<Grid container>
								<ListItemIcon>
									<Grid item xs={2} style={{ paddingTop: '5px' }}>
										<div>
											<img
												className="icon-svg"
												style={{
													height: '40px',
													width: '40px',
													borderRadius: '30px',
													marginRight: '10px',
												}}
												src={ProfileIcon}
											/>
										</div>
										{listField(paymentEnum).showCaption && (
											<div>
												<span
													className="desc"
													style={{
														color: '#000',
														textAlign: 'center',
														paddingLeft: '10px',
													}}
												>
													{x?.[type]}
												</span>
											</div>
										)}
									</Grid>
								</ListItemIcon>

								<ListItemText
									primary={
										<Grid
											item
											xs={10}
											style={{
												paddingLeft: '10px',
												width: '150px',
											}}
										>
											<div>
												<span className="desc" style={{ color: '#0000008A' }}>
													{listField(paymentEnum).header}
												</span>
											</div>
											<div>
												<span>{x?.[data]}</span>
											</div>
											{paymentEnum === PaymentClass.Room ||
											paymentEnum === PaymentClass.Debtor ||
											paymentEnum === PaymentClass.NonGuest ? (
												<div>
													<span className="desc">
														Credit Balance:
														<span className="highlight-text">
															&nbsp;{amtStr(x?.[balance])}
														</span>
													</span>
												</div>
											) : (
												<div>
													<span className="desc">{x?.[type]}</span>
												</div>
											)}
										</Grid>
									}
								/>
							</Grid>
						</ListItem>
					);
				})}
			</List>
		</>
	);
};

export const ListFieldPatron = (props: ListFieldProps) => {
	const {
		filteredList,
		data,
		filterCondition,
		selectedListItem,
		setSelectedListItem,
		type,
		arg,
		fieldKey,
		balance,
		patronEnum,
	} = props;

	const mapCondition = () => {
		if (fieldKey.includes('OFFICER') || fieldKey.includes('STAFF')) {
			return filteredList;
		} else {
			return filteredList?.filter(x => fieldKey.includes(x?.[filterCondition]));
		}
	};

	return (
		<>
			<List>
				{mapCondition()?.map((x, index) => {
					return (
						<ListItem
							key={index}
							alignItems="flex-start"
							button
							onClick={() => {
								setSelectedListItem(x);
							}}
							selected={selectedListItem?.[arg] === x?.[arg]}
						>
							<Grid container>
								<ListItemIcon>
									<Grid item xs={2} style={{ paddingTop: '5px' }}>
										<div>
											<img
												className="icon-svg"
												style={{
													height: '40px',
													width: '40px',
													borderRadius: '30px',
													marginRight: '10px',
												}}
												src={ProfileIcon}
											/>
										</div>
										{listFieldPatron(patronEnum).showCaption && (
											<div>
												<span
													className="desc"
													style={{
														color: '#000',
														textAlign: 'center',
														paddingLeft: '10px',
													}}
												>
													{x?.[type]}
												</span>
											</div>
										)}
									</Grid>
								</ListItemIcon>

								<ListItemText
									primary={
										<Grid
											item
											xs={10}
											style={{
												paddingLeft: '10px',
												width: '150px',
											}}
										>
											<div>
												<span className="desc" style={{ color: '#0000008A' }}>
													{listFieldPatron(patronEnum).header}
												</span>
											</div>
											<div>
												<span>{x?.[data]}</span>
											</div>
											{patronEnum === 'Hotel Guest' ? (
												<div>
													<span className="desc">
														Credit Balance:
														<span className="highlight-text">
															&nbsp;{amtStr(x?.[balance])}
														</span>
													</span>
												</div>
											) : (
												<div>
													<span className="desc">{x?.[type]}</span>
												</div>
											)}
										</Grid>
									}
								/>
							</Grid>
						</ListItem>
					);
				})}
			</List>
		</>
	);
};
