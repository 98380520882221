import React from 'react';
import { PrinterServerHome } from './PrinterServerHome';
import { PrinterServerLogin } from './PrinterServerLogin';

const printerServerRoutes = [
	{
		props: {
			exact: true,
			path: `/printer-server/home/:outletID`,
		},
		component: <PrinterServerHome />,
	},
	{
		props: {
			exact: true,
			path: `/printer-server/login`,
		},
		component: <PrinterServerLogin />,
	},
];

export default printerServerRoutes;
