import { useState } from 'react';
import { useHistory } from 'react-router';
/**
 * @param SnackBar
 * handle moreoption icon on listing page
 */
export const useSnackBar = () => {
  let history = useHistory();
  const [openSnackBar, setOpenSnackBar] = useState(false);
  const [snackBarMessage, setSnackBarMessage] = useState('');

  const snackFunc = (showText: string, goBack: Boolean, error?: Boolean) => {
    setSnackBarMessage(showText);
    setOpenSnackBar(true);
    !error &&
      setTimeout(() => {
        setOpenSnackBar(false);
        goBack && history.goBack();
      }, 2000);
  };
  const close = () => {
    setOpenSnackBar(false);
  };
  return {
    openSnackBar,
    snackBarMessage,
    snackFunc,
    close,
  };
};
