import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import { MainHeader } from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import { ListItemText, useMediaQuery } from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import AppContext from 'containers/App/Store/AppContext';
import {
	useGetAverageMealTimeAnalysisLazyQuery,
	useGetBizInsightOutletQuery,
	useGetShareAttachmentMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import * as htmlToImage from 'html-to-image';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
	Bar,
	BarChart,
	CartesianGrid,
	Cell,
	LabelList,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import ShareDialog from '../../../../../components/Dialog/ShareDialog';
import '../bi.scss';

const COLORS = ['#25D0CF', '#FFB10A', '#446FF7', '#ffc853', '#f8f0d7'];
export const AverageMealTimeAnalysis = () => {
	const history = useHistory();
	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();
	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));
	const latestOutlet = localStorage.getItem('latestOutlet');
	const dateFormat = require('dateformat');

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const {
		data: { getOutlet } = { getOutlet: [] },
		called: getOutletCalled,
		loading: getOutletLoading,
	} = useGetBizInsightOutletQuery({
		fetchPolicy: 'network-only',
	});

	const [selectedOutlet, setSelectedOutlet] = useState(null);
	useEffect(() => {
		setSelectedOutlet(
			getOutlet?.filter(outlet => outlet?.ID === latestOutlet)[0],
		);
	}, [getOutlet]);

	const [
		loadAverageMealTimeAnalysis,
		{
			data: { averageMealTimeAnalysis } = { averageMealTimeAnalysis: [] },
			called: averageMealTimeAnalysisCalled,
			loading: averageMealTimeAnalysisLoading,
		},
	] = useGetAverageMealTimeAnalysisLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);

	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	useEffect(() => {
		if (selectedOutlet) {
			loadAverageMealTimeAnalysis({
				variables: {
					outletID: selectedOutlet?.ID,
				},
			});
		}
	}, [selectedOutlet]);

	const renderCustomizedLabel = (props: any) => {
		const { x, y, width, height, value } = props;
		const radius = 10;
		return (
			<g>
				<text
					x={width + radius - y - height}
					y={x + width / 2 + 2}
					transform={`rotate(-90)`}
					fill="#fff"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize="12px"
				>
					{`${value} mins`}
				</text>
			</g>
		);
	};

	const CustomizedXAxisLabel = () => {
		return (
			<>
				<text
					x={0}
					y={0}
					dx="50%"
					dy="5%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={16}
					fontWeight={500}
					width={180}
				>
					{`${selectedOutlet?.name} `}
				</text>
				<text
					x={0}
					y={0}
					dx="50%"
					dy="10%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={12}
					fontWeight={350}
					width={180}
				>
					{'Average Meal Time Analysis'}
				</text>
			</>
		);
	};
	const CustomizedYAxisLabel = () => {
		return (
			<text
				x={0}
				y={0}
				dx="-150px"
				dy="5%"
				textAnchor="middle"
				dominantBaseline="middle"
				fontSize={12}
				fontWeight={500}
				width={180}
				transform="rotate(-90)"
			>
				Meal Duration (mins)
			</text>
		);
	};

	const onSubmit = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: selectedOutlet?.ID,
			},
		});
	};

	return (
		<>
			<>
				<MainHeader
					onClick={() =>
						history.push({
							pathname: `/menu/outlet-app/business-insight/business-analytic`,
						})
					}
					mainBtn="back"
					smTitle="Business Insights"
					// HC
					title={loggedInUser?.companyName}
					routeSegments={[
						{ name: 'Business Insights' },
						{ name: 'Average Meal Time Analysis', current: true },
					]}
				/>

				<DynamicSubHeader
					style={
						{
							// width:
							// 	isDesktop && globalState.drawerOpen
							// 		? 'calc(100% - 288px)'
							// 		: isDesktop && !globalState.drawerOpen
							// 		? `calc(100% - 48px)`
							// 		: null,
						}
					}
					title={
						getOutlet === undefined || getOutlet?.length === 0
							? ``
							: `${selectedOutlet?.name}`
					} // title text
					handleClick={e => handleClick(e, null, 0)} // onClick function for dropdown
					dropDownOptions={
						// dropdown component place here
						<StyledMenu
							id="customized-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
							onClick={handleClose}
						>
							{getOutlet.map((outlet, index) => (
								<StyledMenuItem
									key={index}
									value={outlet.ID}
									defaultValue={outlet.ID}
									onClick={e => {
										setSelectedOutlet(outlet);
									}}
								>
									<ListItemText primary={outlet?.name} />
								</StyledMenuItem>
							))}
						</StyledMenu>
					}
				/>
				<ContentWrapper search float>
					{!averageMealTimeAnalysisLoading &&
					(averageMealTimeAnalysis === undefined ||
						averageMealTimeAnalysis?.length === 0) ? (
						<EmptyList
							title={`No order item created for ${selectedOutlet?.name}`}
							subtitle="Please create new order to continue"
						/>
					) : (
						!averageMealTimeAnalysisLoading && (
							<>
								{avgMealTimeBarChart(
									averageMealTimeAnalysis,
									CustomizedXAxisLabel,
									CustomizedYAxisLabel,
									renderCustomizedLabel,
								)}
								{avgMealTimeBarChart(
									averageMealTimeAnalysis,
									CustomizedXAxisLabel,
									CustomizedYAxisLabel,
									renderCustomizedLabel,
									true,
								)}
								<div className="cover-layer">
									<CardContents>
										<></>
									</CardContents>
								</div>
							</>
						)
					)}
					{/* </div> */}
				</ContentWrapper>
				{ShareDialog({
					shareTitle: `Average Meal Time Analysis - ${selectedOutlet?.name}`,
					title: `Share`,
					URL: shareAttachment?.fileURL,
					setSimple: setOpenShareDialog,
					simple: openShareDialog,
					emailShare: openSendEmail,
					setSimpleEmail: setOpenSendEmail,
				})}
				<DynamicFooter
					overlap
					isHidden={openShareDialog}
					options={[
						{
							name: 'Share',
							onClick: async () => {
								let temp = await document.getElementById('hiddenBarChart');
								htmlToImage
									.toBlob(temp, { style: { background: '#fff' } })
									.then(value => {
										onSubmit(value);
									});
							},
							disabled:
								averageMealTimeAnalysis == undefined ||
								averageMealTimeAnalysis.length == 0,
							color: 'primary',
							props: {
								type: 'submit',
							},
							startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
						},
					]}
				/>
			</>
		</>
	);
};

const heightConst = 300;
const avgMealTimeBarChart = (
	averageMealTimeAnalysis,
	CustomizedXAxisLabel,
	CustomizedYAxisLabel,
	renderCustomizedLabel,
	hidden?: Boolean,
) => {
	return (
		<div
			style={
				hidden
					? {
							zIndex: -40,
							top: 0,
							left: 0,
							position: 'fixed',
							width: '100%',
					  }
					: {
							marginTop: '0px',
							zIndex: 40,
							position: 'relative',
					  }
			}
			className={hidden ? 'hidden-content' : null}
		>
			<CardContents
				section={
					!hidden
						? {
								header: {
									title: 'Average Meal Time Analysis',
								},
						  }
						: null
				}
			>
				<div
					style={{
						width: '100%',
						height: !hidden ? heightConst : heightConst + 44,
					}}
					id={!hidden ? 'barChart' : 'hiddenBarChart'}
				>
					<ResponsiveContainer>
						<BarChart
							id="barChart"
							data={averageMealTimeAnalysis}
							margin={{
								top: hidden ? 50 : 10,
								right: 10,
								left: 10,
								bottom: 10,
							}}
						>
							<CartesianGrid strokeDasharray="2 3" />
							<XAxis
								dataKey="name"
								fontSize={12}
								axisLine={false}
								tickLine={false}
								label={hidden ? CustomizedXAxisLabel : null}
							/>
							<YAxis
								label={<CustomizedYAxisLabel />}
								fontSize={10}
								axisLine={false}
								tickLine={false}
							/>
							<Tooltip />
							<Bar
								dataKey="average"
								name="Mins"
								fill="#4ba0ea"
								barSize={39}
								values="average"
							>
								<LabelList
									dataKey="average"
									fill="#fff"
									textAnchor="end"
									angle={-90}
									dominantBaseline="end"
									content={renderCustomizedLabel}
								/>
								{averageMealTimeAnalysis.map((entry, index) => (
									<Cell key={`cell-${index}`} fill={COLORS[index % 20]} />
								))}
							</Bar>
						</BarChart>
					</ResponsiveContainer>
				</div>
			</CardContents>
		</div>
	);
};
