import React from 'react';
import businessAnalyticsRoutes from './BusinessAnalytics/BusinessAnalyticsRoutes';
import { BusinessInsightsSubmenu } from './BusinessInsightsSubmenu';
import digitalBillRoutes from '../DigitalDocument/DigitalDocumentRoutes';
import digitalReportingRoutes from '../DigitalReporting/DigitalReportingRoutes';

const businessInsightRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/business-insight`,
		},
		component: <BusinessInsightsSubmenu />,
	},
	...digitalBillRoutes,
	...digitalReportingRoutes,
	...businessAnalyticsRoutes,
];

export default businessInsightRoutes;
