import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { SearchHeader } from '@ifca-root/react-component/src/components/Header/SearchHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	TextField,
} from '@material-ui/core';
import { KeyboardArrowRight } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import download from 'downloadjs';
import {
	GetQrDocument,
	useCreateQrMutation,
	useDeleteQrMutation,
	useGetOutletQuery,
	useGetQrQuery,
} from 'generated/graphql';
import { formatDate, formatTime } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { useFuseSearch } from 'helpers/hooks/useSearch';
import { SystemMsgs } from 'helpers/SystemMsg';
import * as htmlToImage from 'html-to-image';
import QRCode from 'qrcode.react';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

interface DialogFormFields {
	name: string;
}

const dialogFormFieldsInitialState: DialogFormFields = {
	name: '',
};
interface QRProps {
	code: string;
}

export const QRCodeList = () => {
	const history = useHistory();

	const { pathname } = useLocation();
	const { outletID }: any = useParams();

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const [simple, setSimple] = React.useState(false);
	const [openQR, setOpenQR] = React.useState(false);
	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [double, setDouble] = useState(false);
	const { filteredList, handleSearch, setOriginalListing } = useFuseSearch();

	const [currData, setCurrData] = useState<any>('');

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	let qrUrl;
	switch (process.env.REACT_APP_ENV) {
		case 'swan':
			qrUrl = `https://customer-swan.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'swanuat':
			qrUrl = `https://customer-swan-uat.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'promenade':
			qrUrl = `https://customer-promenade.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'promenadeuat':
			qrUrl = `https://customer-promenade-uat.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'imperial':
			qrUrl = `https://customer-imperial.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'imperialuat':
			qrUrl = `https://customer-imperial-uat.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'local':
			qrUrl = `http://localhost:3500/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'dev':
			qrUrl = `https://posx-dev-customer.ifca.io/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		case 'uat':
			qrUrl = `https://menux-uat-customer.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
		default:
			qrUrl = `https://customer.menux.asia/menu/patron-app/outlet/${outletID}/qr-code`;
			break;
	}

	const {
		data: { getQR } = { getQR: [] },
		called: qrCalled,
		loading: qrLoading,
	} = useGetQrQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'code' },
	});

	const [
		submitQR,
		{ data: createdQRData, loading: submitQRLoading },
	] = useCreateQrMutation({
		// fetchPolicy: 'network-only',
		onCompleted: ({ createQR }) => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setOriginalListing([createQR, ...filteredList]);
		},
	});

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	const [deleteQR, { loading: deleteQRLoading }] = useDeleteQrMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
			setOriginalListing(filteredList?.filter(qr => qr?.ID !== menu?.ID));
		},
	});

	const existingQRCodeNames = getQR?.map(m => m?.code);

	const yupSchema = yup.object().shape({
		code: yup
			.string()
			.required(SystemMsgs.code())
			.trim()
			.test('name', 'QR code name already exist', value => {
				if (watch('code') !== menu?.obj?.code) {
					return !existingQRCodeNames?.includes(value);
				}
				return true;
			}),
	});
	const {
		register,
		control,
		handleSubmit,
		watch,
		errors,
		clearErrors,
		formState,
	} = useForm<QRProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const onSubmit = (data: any) => {
		submitQR({
			variables: {
				input: {
					outletID: outletID,
					code: data.code,
				},
			},
			// refetchQueries: [
			// 	{
			// 		query: GetQrDocument,
			// 		variables: { outletID: outletID, orderByAsc: 'code' },
			// 	},
			// ],
		});
		setSimple(false);
		clearErrors();
	};

	const handleCloseDialog = () => {
		setSimple(false);
		clearErrors();
	};

	const [baseImage, setBaseImage] = useState(null);

	const generateQrCode = data => {
		const qrID = data?.ID;
		const accountID = data?.accountID;

		var xhr = new XMLHttpRequest();
		xhr.open('GET', `${getOutlet[0]?.logo?.fileURL}`);
		xhr.responseType = 'blob';
		xhr.send();

		var reader = new FileReader();
		xhr.addEventListener('load', function() {
			reader.readAsDataURL(xhr.response);
			reader.addEventListener('loadend', function() {
				setBaseImage(reader.result);
			});
		});

		return (
			<div
				style={{
					display: 'flex',
					justifyContent: 'center',
					padding: '10px',
					background: '#fff',
				}}
				id="qrCodeBody"
			>
				<div>
					<QRCode
						value={`${qrUrl}/${qrID}/${accountID}`}
						// value={data}
						size={128}
						bgColor={'#ffffff'}
						fgColor={'#000000'}
						level={'L'}
						includeMargin={false}
						imageSettings={{
							src: baseImage,
							x: null,
							y: null,
							height: 26,
							width: 26,
						}}
					/>
					<div className="title" style={{ textAlign: 'center' }}>
						{currData?.code}
					</div>
				</div>
			</div>
		);
	};

	const downloadQrCode = () => {
		htmlToImage.toPng(document.getElementById('qrCodeBody')).then(dataUrl => {
			download(dataUrl, `${currData?.code}`);
		});
	};

	useEffect(() => {
		if (getQR && getQR?.length > 0 && !qrLoading) {
			setOriginalListing(getQR);
		}
	}, [getQR, qrLoading]);

	const [rerender, setRender] = useState(false);
	const qrSearch = JSON.parse(localStorage.getItem('searchFilter'));

	useEffect(() => {
		if (!!qrSearch?.qrList && getQR?.length > 0 && !qrLoading) {
			setRender(true);
			handleSearch(`'"${qrSearch?.qrList ?? ''}"`, ['code']);
		}
	}, [qrLoading, rerender, getQR]);

	return (
		<>
			{qrLoading && <Loading />}
			{outletLoading && <Loading />}
			{submitQRLoading && <Loading />}
			{deleteQRLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'QR Code', current: true },
				]}
			/>

			<SearchHeader
				title="QR Code List"
				value={`${filteredList?.length ?? 0}`}
				onChangeAction={e => {
					handleSearch(`'"${e?.target?.value}"`, ['code']);
					// localStorage.setItem(
					// 	`searchFilter`,
					// 	JSON.stringify({
					// 		qrList: e.target.value ?? '',
					// 		qrSubmenu: qrSearch?.qrSubmenu ?? '',
					// 		menuItemSearch: qrSearch?.menuItemSearch ?? '',
					// 	}),
					// );
				}}
				fixed
				search
				isDefaultValue={!!qrSearch?.qrList ?? false}
				defaultValue={qrSearch?.outletList ?? ''}
				// onCloseAction={() => {
				// 	localStorage.setItem(
				// 		`searchFilter`,
				// 		JSON.stringify({
				// 			qrList: '',
				// 			qrSubmenu: qrSearch?.qrSubmenu ?? '',
				// 			menuItemSearch: qrSearch?.menuItemSearch ?? '',
				// 		}),
				// 	);
				// 	handleSearch('', []);
				// }}
				clickText
			/>
			<ContentWrapper float search>
				<List className="core-list">
					{!qrLoading &&
					!outletLoading &&
					(getQR === undefined || getQR?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add QR code to continue"
						/>
					) : (
						filteredList?.map((el, index) => (
							<ListItem
								onClick={e => {
									setOpenQR(true);
									setCurrData(el);

									handleClick(e, el.ID, index, el);
								}}
							>
								<ListItemText
									primary={
										<>
											<span className="xsTitle">{el?.code}</span>
										</>
									}
									secondary={
										<>
											<span className="desc">
												<span className="desc">Created on </span>
												<span className="xxTitle fw-medium highlight-text">
													{formatDate(el?.createdTs)},
													{' ' + formatTime(el?.createdTs)}
												</span>
											</span>
										</>
									}
								/>

								<ListItemSecondaryAction>
									<IconButton
										edge="end"
										//aria-label="delete"
										key={0}
										onClick={e => {
											setOpenQR(true);
											setCurrData(el);
											//setData(menu?.obj);
											// dispatch({
											//   type: 'status',
											//   payload: VoStatus.Rejected,
											// });
											handleClick(e, el.ID, index, el);
										}}
									>
										<KeyboardArrowRight />
									</IconButton>
								</ListItemSecondaryAction>
							</ListItem>
						))
					)}
				</List>
			</ContentWrapper>
			<FloatButton
				onClick={() => {
					setSimple(true);
					setDouble(false);

					//setData(menu?.obj);
					// dispatch({
					//   type: 'status',
					//   payload: VoStatus.Rejected,
					// });
				}}
			/>

			<form id="submit-form">
				<CommonDialog
					fullWidth={true}
					open={simple}
					onClose={() => {
						handleCloseDialog();
					}}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title color-primary-orange">
												New QR Code
											</div>
										</div>
									</div>
								</div>
							),
						},
						body: () => (
							<>
								<div className="content-container">
									<Controller
										as={TextField}
										name="code"
										label="QR Code Name"
										autoComplete="off"
										multiline={true}
										required
										fullWidth
										ref={register}
										control={control}
										helperText={errors?.code?.message}
										error={errors?.code ? true : false}
										onInput={() => {
											setDouble(false);
										}}
										// helperText={
										// 	existingQRCodeNames?.includes(watch('code'))
										// 		? 'QR Code already exist'
										// 		: null
										// }
										// error={
										// 	existingQRCodeNames?.includes(watch('code'))
										// 		? true
										// 		: false
										// }
									></Controller>
								</div>
							</>
						),
						footer: {
							actions: [
								{
									displayText: 'Cancel',
									props: {
										onClick: () => {
											handleCloseDialog();
										},
										variant: 'contained',
										color: 'primary',
									},
								},
								{
									displayText: 'Confirm',
									props: {
										// onClick: () => handleSubmit(onSubmit)(),
										color: 'primary',
										variant: 'contained',
										// color: existingQRCodeNames?.includes(watch('code'))
										// 	? 'inherit'
										// 	: 'primary',
										// onClick: () => {
										// 	if (!existingQRCodeNames?.includes(watch('code'))) {
										// 		handleSubmit(onSubmit)();
										// 	}
										// },
										disabled: double,

										onClick: () => {
											if (errors?.code ? false : true) {
												handleSubmit(onSubmit)();
												setDouble(true);
											}
										},
									},
								},
							],
						},
					}}
				/>
			</form>

			{openQR && (
				<CommonDialog
					id="qrDialog"
					fullWidth={true}
					open={openQR}
					onClose={() => setOpenQR(false)}
					sections={{
						header: {
							dynamic: (
								<div className="">
									<div className="dialog-dynamic-content">
										<div className="session">
											<div className="title color-primary-orange">
												{menu?.obj?.code}
											</div>
										</div>
									</div>
								</div>
							),
						},
						body: () => <>{generateQrCode(currData)}</>,
						footer: {
							actions: [
								{
									displayText: 'Delete',
									props: {
										onClick: () => {
											setDeleteDialog(true);
											setOpenQR(false);
											// isConfirm(true);
										},
										// variant: 'contained',
										color: 'secondary',
									},
								},
								{
									displayText: 'Download',
									props: {
										onClick: () => {
											// setOpenQR(false);
											downloadQrCode();
										},

										// variant: 'contained',
										color: 'primary',
										//disabled: isExceed,
									},
								},
							],
						},
					}}
				/>
			)}

			<GeneralDeleteDialog
				ID={menu?.ID}
				// refetchID={{ outletID: outletID, orderByAsc: 'code' }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.code}
				deleteMutation={deleteQR}
				// refetchDocument={GetQrDocument}
			/>
		</>
	);
};
