import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialogV2 } from '@ifca-root/react-component/src/components/Dialog/CommonDialogV2';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetMajorDocument,
	useCreateMajorWithGlMutation,
	useDeleteMajorWithGlMutation,
	useDragMajorMutation,
	useGetMajorQuery,
	useGetOutletQuery,
	useUpdateMajorWithGlMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import {
	DraggableItem,
	DragNDropList,
	DropableItem,
} from '@ifca-root/react-component/src/components/CardList/DragAndDropList';

interface MajorProps {
	name: string;
	description: string;
}

export const MajorList = (props: any) => {
	const history = useHistory();
	const location = useLocation();

	// const { [`${OUTLET}ID`]: outletID } = useParams<Record<string, any>>();
	const { outletID }: any = useParams();

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();

	const [openDialog, setOpenDialog] = useState(false);

	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [double, setDouble] = useState(false);

	const editData = location?.state as any;

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	/// <<< QUERY >>> ///

	const {
		data: { getMajor } = { getMajor: [] },
		called: majorCalled,
		loading: majorLoading,
	} = useGetMajorQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'priority' },
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	/// <<< SUBMIT MUTATION >>> ///

	const [
		createMajorWithGL,
		{ loading: createMajorLoading },
	] = useCreateMajorWithGlMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/major`,
					state: { success: true, msgMode: 'create' },
				});
				//history.goBack();
			}, 500);
			setOpenDialog(false);
		},
	});

	/// <<< SUBMIT MUTATION >>> ///

	/// <<< UPDATE MUTATION >>> ///

	const [
		updateMajorWithGL,
		{ loading: updateMajorLoading },
	] = useUpdateMajorWithGlMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			// history.push(`/subcontract/${consubconId}/vo`);

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/major`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
			setOpenDialog(false);
		},
	});

	/// <<< UPDATE MUTATION >>> ///

	/// <<< DELETE MUTATION >>> ///

	const [
		deleteMajorWithGL,
		{ loading: deleteMajorWithGLLoading },
	] = useDeleteMajorWithGlMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	/// <<< DELETE MUTATION >>> ///

	let [initialData, setInitialData] = useState<any>();

	useEffect(() => {
		setInitialData(getMajor);
	}, [getMajor]);

	useEffect(() => {}, [initialData]);

	const onDragEnd = ({ source, destination }) => {
		if (destination === undefined || destination === null) return null;

		if (
			source.droppableId === destination.droppableId &&
			destination.index === source.index
		)
			return null;

		const start = source.droppableId;
		const end = destination.droppableId;

		if (start === end) {
			let testArray = initialData.filter((_, idx) => idx !== source.index);

			testArray.splice(destination.index, 0, initialData[source.index]);

			setInitialData([...testArray]);
			dragMajor({
				variables: {
					input: testArray?.map((x, i) => {
						return {
							ID: x?.ID,
							name: x?.name,
							priority: i + 1,
							outletID: outletID,
						};
					}),
					outletID: outletID,
				},
			});

			return null;
		}
	};
	/// <<< DND MUTATION >>> ///
	const [
		dragMajor,
		{ loading: dragMajorLoading, error: dragMajorError },
	] = useDragMajorMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			console.log('data', data);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setOpenDialog(false);
		},
	});

	const existingMajorNames = getMajor?.map(m => m?.name);

	const yupSchema = yup.object().shape({
		name: yup
			.string()
			.required(SystemMsgs.name())
			.trim()
			.test('name', 'Major name already exist', value => {
				if (
					(watch('name') !== menu?.obj?.name && isEdit === true) ||
					isEdit === false
				) {
					return !existingMajorNames?.includes(value);
				}
				return true;
			}),
	});

	const {
		register,
		control,
		handleSubmit,
		watch,
		errors,
		clearErrors,
		formState,
	} = useForm<MajorProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const onSubmit = (data: any) => {
		isEdit === false
			? createMajorWithGL({
					variables: {
						input: {
							outletID: outletID,
							name: data?.name,
							description: '',
						},
					},
					refetchQueries: [
						{
							query: GetMajorDocument,
							variables: { outletID: outletID, orderByAsc: 'priority' },
						},
					],
			  })
			: updateMajorWithGL({
					variables: {
						input: {
							ID: menu?.ID,
							outletID: outletID,
							name: data?.name,
							description: '',
						},
					},
					refetchQueries: [
						{
							query: GetMajorDocument,
							variables: { outletID: outletID, orderByAsc: 'priority' },
						},
					],
			  });
		clearErrors();
	};

	const handleCloseDialog = () => {
		setOpenDialog(false);
		clearErrors();
	};

	return (
		<>
			{majorLoading && <Loading />}
			{outletLoading && <Loading />}
			{/* {submitMajorLoading && <Loading />} */}

			{updateMajorLoading && <Loading />}
			{deleteMajorWithGLLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Major & Family', current: true },
				]}
			/>
			<ContentWrapper>
				<MsgAlert
					//   icon={<InfoOutlined />}
					message={'Drag & drop to arrange sequence. '}
				/>
				<DragNDropList onDragEnd={onDragEnd}>
					<DropableItem droppableId={'major_list'}>
						<List className="core-list">
							{!majorLoading &&
							!outletLoading &&
							(getMajor === undefined || getMajor?.length === 0) ? (
								<EmptyList
									title="No Record found"
									subtitle="Please add major to continue"
								/>
							) : (
								initialData?.map((el: any, index: number) => (
									// <ListItem>
									<DraggableItem
										// style={{ padding: 0 }}
										id={el.ID}
										index={index}
										draggableId={el.ID}
									>
										<div style={{ paddingRight: '6px' }}></div>
										<ListItemText
											primary={
												<>
													<span className="xsTitle flex-space">{el?.name}</span>
													<span className="xxTitle highlight-text text-separator"></span>
												</>
											}
											secondary={
												<>
													<span className="desc">
														<span className="desc">
															Total Menu Items: {el?.family?.length}
														</span>
													</span>
												</>
											}
										/>

										<ListItemSecondaryAction>
											<IconButton
												edge="end"
												aria-label="more"
												aria-controls="menu-list"
												aria-haspopup="true"
												onClick={e => handleClick(e, el.ID, index, el)}
											>
												<MoreVert />
											</IconButton>
										</ListItemSecondaryAction>
									</DraggableItem>
									// </ListItem>
								))
							)}
						</List>
					</DropableItem>
				</DragNDropList>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() => {
							setOpenDialog(true);
							setIsEdit(true);
							setDouble(false);
						}}
					>
						<span className="">Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							history.push(
								`/menu/outlet-app/outlet-setting/${outletID}/major/${menu.ID}/family`,
							);
						}}
					>
						<span className="">Family</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<Box mt={1}>
					<CommonDialogV2
						fullWidth={true}
						open={openDialog}
						onClose={() => {
							handleCloseDialog();
						}}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												<div className="flex session">
													{isEdit ? (
														<div className="title flex-space color-primary-orange">
															Edit Major
														</div>
													) : (
														<div className="title flex-space color-primary-orange">
															New Major
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								),
							},
							body: () => (
								<>
									{isEdit === false ? (
										<div className="content-container">
											<Controller
												as={TextField}
												name="name"
												label="Name"
												autoComplete="off"
												multiline={true}
												required
												fullWidth
												ref={register}
												control={control}
												helperText={errors?.name?.message}
												error={errors?.name ? true : false}
												onInput={() => {
													setDouble(false);
												}}

												// helperText={
												// 	existingMajorNames?.includes(watch('name'))
												// 		? 'Major already exist'
												// 		: null
												// }
												// error={
												// 	existingMajorNames?.includes(watch('name'))
												// 		? true
												// 		: false
												// }
											/>
										</div>
									) : (
										<div className="content-container">
											<Controller
												as={TextField}
												defaultValue={menu?.obj?.name}
												name="name"
												label="Title"
												autoComplete="off"
												multiline={true}
												required
												fullWidth
												ref={register}
												control={control}
												helperText={errors?.name?.message}
												error={errors?.name ? true : false}
												onInput={() => {
													setDouble(false);
												}}
												// helperText={
												// 	existingMajorNames?.includes(watch('name'))
												// 		? 'Major already exist'
												// 		: null
												// }
												// error={
												// 	existingMajorNames?.includes(watch('name'))
												// 		? true
												// 		: false
												// }
											/>
										</div>
									)}
								</>
							),

							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => {
												handleCloseDialog();
											},
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Save',
										props: {
											variant: 'contained',
											// color: existingMajorNames?.includes(watch('name'))
											// 	? 'inherit'
											// 	: 'primary',
											// form: 'submit-form',
											// onClick: () => {
											// 	if (!existingMajorNames?.includes(watch('name'))) {
											// 		handleSubmit(onSubmit)();
											// 	}
											// },
											disabled: double,

											onClick: () => {
												if (errors?.name ? false : true) {
													handleSubmit(onSubmit)();
													setDouble(true);
												}
											},

											color: 'primary',
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID, orderByAsc: 'priority' }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteMajorWithGL}
				refetchDocument={GetMajorDocument}
			/>

			<FloatButton
				onClick={() => {
					setOpenDialog(true);
					setIsEdit(false);
					setDouble(false);
				}}
			/>
		</>
	);
};
