import { yupResolver } from '@hookform/resolvers';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { PhoneNumInput } from '@ifca-root/react-component/src/components/Input/PhoneNumInput';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	IconButton,
	InputAdornment,
	Switch,
	TextField,
} from '@material-ui/core';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CommonStatus,
	GetUsersByAccountAndSoftwareDocument,
	useCreateUserMutation,
	useGetUsersByAccountQuery,
	UserSelection,
	useUpdateUserMutation,
} from 'generated/graphql';
import { IAction } from 'helpers/model';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { Reducer, useContext, useReducer, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import '../../../../../assets/styles/phoneinput.scss';
import { useSnackBar } from 'helpers/hooks/useSnackBar';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { encryptMessage } from 'helpers/Crypto/JSEncrypt';

interface UserFormProps {
	name: string;
	userName: string;
	// department: string;
	// designation: string;
	email: string;
	contactNo: string;
	password: string;
	confirmPassword: string;
}
interface UserProps {
	isSameUsername: boolean;
	isSameEmail: Boolean;
	isSuperUser: boolean;
	active: boolean;
	message: string;
	alert: boolean;
	showNewPassword: boolean;
	showConfirmPassword: boolean;
}

export const UsersForm = (props: any) => {
	const { mode } = props;
	const history = useHistory();
	const { pathname } = useLocation();
	const { userID, mode: pageMode }: any = useParams();
	const { [`outletID`]: outletID } = useParams<Record<string, any>>();
	const { snackFunc, snackBarMessage, openSnackBar, close } = useSnackBar();
	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [dupError, setDupError] = useState(false);

	let location = useLocation();

	const editData: any = location?.state;

	// const initialState: UserProps = {
	// 	message: '',
	// 	alert: false,
	// 	isSuperUser: mode === 'add' ? false : true,
	// 	active: mode === 'add' ? true : false,
	// };

	const userSchema = yup.object().shape({
		name: CommonYupValidation.requireField(SystemMsgs.name()),
		userName: CommonYupValidation.requireField(SystemMsgs.userName()),
		// department: CommonYupValidation.requireField(SystemMsgs.department()),
		// designation: CommonYupValidation.requireField(SystemMsgs.designation()),
		email: CommonYupValidation.requireField(SystemMsgs.email()),
		contactNo: CommonYupValidation.requireField(SystemMsgs.contactNo()),
		password: mode === 'add' ? CommonYupValidation.password() : null,
		confirmPassword:
			mode === 'add'
				? yup
						.string()
						.required()
						.oneOf([yup.ref('password'), null], 'Passwords must match')
				: null,
	});

	const [
		createUser,
		{ loading: mutationLoading, error: mutationError },
	] = useCreateUserMutation({
		onError: error => {
			console.log('ERROR', error);
			dispatch({
				type: 'message',
				payload:
					// error?.graphQLErrors[0]?.extensions?.exception?.validationErrors[0]
					//   ?.constraints?.IsEmailAlreadyExistConstraint,
					'Something went wrong. Please try again',
			});
			dispatch({
				type: 'alert',
				payload: true,
			});
			setDupError(true);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/general-setting/user-access/user`,
					state: {
						success: true,
						msgMode: 'create',
						email: data?.createUser?.email,
					},
				});
			}, 500);
		},
	});

	const [
		updateUser,
		{ loading: mutationUpdateLoading, error: updateError },
	] = useUpdateUserMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());

			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/general-setting/user-access/user`,
					state: {
						success: true,
						msgMode: 'update',
					},
				});
			}, 500);
		},
	});

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		data: { getUsersByAccount } = { getUsersByAccount: [] },
	} = useGetUsersByAccountQuery({
		variables: {
			accountID: user?.accountID,
		},
		fetchPolicy: 'network-only',
	});

	const handleUsername = (name: any) => {
		console.log(
			'GETUSER',
			getUsersByAccount?.find(x => x?.userName === name),
		);
		if (getUsersByAccount?.find(x => x?.userName === name)) {
			dispatch({
				type: 'isSameUsername',
				payload: true,
			});
		} else {
			dispatch({
				type: 'isSameUsername',
				payload: false,
			});
		}
	};
	const handleEmail = (email: any) => {
		console.log(
			'GETUSER',
			getUsersByAccount?.find(x => x?.email === email),
		);
		if (getUsersByAccount?.find(x => x?.email === email)) {
			dispatch({
				type: 'isSameEmail',
				payload: true,
			});
		} else {
			dispatch({
				type: 'isSameEmail',
				payload: false,
			});
		}
	};
	const handleMutationType = mode === 'add' ? createUser : updateUser;

	const initialState: UserProps = {
		isSameUsername: false,
		isSameEmail: false,
		message: '',
		alert: false,
		isSuperUser: mode === 'add' ? false : editData?.superUser,
		active:
			mode === 'add'
				? true
				: editData?.commonStatus === CommonStatus.Active
				? true
				: false,
		showNewPassword: false,
		showConfirmPassword: false,
	};

	const reducer: Reducer<UserProps, IAction> = (state, action) => {
		switch (action.type) {
			case 'reset':
				return initialState;
			default:
				return { ...state, [action.type]: action.payload };
		}
	};

	const [state, dispatch] = useReducer(reducer, initialState);

	const { handleSubmit, register, errors, control, getValues } = useForm<
		UserFormProps
	>({
		defaultValues: {
			name: editData?.name,
			userName: editData?.userName,
			// department: editData?.department,
			// designation: editData?.jobTitle,
			email: editData?.email,
			contactNo: editData?.contactNo,
			password: '',
			confirmPassword: '',
		},
		mode: 'all',
		resolver: yupResolver(userSchema),
	});

	const [openExitDialog, setOpenExitDialog] = useState(false);
	const onSubmit = data => {
		const getName =
			getUsersByAccount?.find(x => x?.userName === data?.userName)?.userName ||
			null;

		const getEmail =
			getUsersByAccount?.find(x => x?.email === data?.email)?.email || null;

		if (getEmail !== null && mode === 'add') {
			snackFunc('Sorry, that Email already exist!', false);
		} else if (getName !== null && mode === 'add') {
			snackFunc('Sorry, that Login Username already exist!', false);
		} else {
			const input: any =
				mode === 'edit'
					? {
							ID: editData?.ID,
							name: data.name,
							userName: data.userName,
							email: data.email,
							// department: data.department,
							// jobTitle: data.designation,
							contactNo: data.contactNo,
							superUser: state.isSuperUser,
							commonStatus: state.active
								? CommonStatus.Active
								: CommonStatus.Inactive,
					  }
					: {
							name: data.name,
							userName: data.userName,
							email: data.email,
							// department: data.department,
							// jobTitle: data.designation,
							contactNo: data.contactNo,
							superUser: state.isSuperUser,
							commonStatus: state.active
								? CommonStatus.Active
								: CommonStatus.Inactive,
							password: encryptMessage(data?.password),
					  };
			console.log(encryptMessage(data?.password));

			const inputFinal: any =
				mode === 'add' ? input : Object.assign({ ID: userID }, input);

			handleMutationType({
				variables: {
					input: inputFinal,
					OutletID: localStorage.getItem('latestOutlet'),
				},
				refetchQueries: [
					{
						query: GetUsersByAccountAndSoftwareDocument,
						variables: {
							superUserBool: UserSelection.All,
						},
					},
				],
			});
		}
	};

	// const reducer: Reducer<UserProps, IAction> = (state, action) => {
	// 	switch (action.type) {
	// 		case 'reset':
	// 			return initialState;
	// 		default:
	// 			return { ...state, [action.type]: action.payload };
	// 	}
	// };

	// const [state, dispatch] = useReducer(reducer, initialState);

	// const { handleSubmit, register, errors, control } = useForm<UserFormProps>({
	// 	defaultValues: {},
	// 	mode: 'all',
	// 	// resolver: yupResolver(UserSchema),
	// });

	// const onSubmit = (data: any) => {
	// };

	return (
		<>
			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={user?.companyName}
				// title={'HARDCODED'}
				// title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Outlet Settings' },
					{ name: 'Users', current: true },
				]}
				rightRouteSegments={[
					{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
				]}
			/>

			<ContentWrapper footer>
				<form id="submit-form" onSubmit={handleSubmit(onSubmit)}>
					<CardContents devFullWidth={true}>
						<div className="content-wrap full">
							<div className="desc text-noflow">
								{' '}
								User will be invited to login via email and is required to
								create the first time login password.
							</div>
						</div>
						<Controller
							as={TextField}
							name="name"
							fullWidth
							margin="normal"
							label="Name"
							required
							helperText={errors?.name?.message}
							error={errors?.name ? true : false}
							control={control}
							ref={register}
							autoComplete="nope"
						/>
						<Controller
							as={
								<TextField
									inputProps={{
										value: editData?.userName,
										onChange: e => handleUsername(e.currentTarget.value),
									}}
									autoComplete="off"
								/>
							}
							name="userName"
							label="Login Username"
							margin="normal"
							disabled={mode === 'edit'}
							fullWidth
							required
							helperText={
								errors?.userName?.message
									? errors?.userName?.message
									: state.isSameUsername === true
									? 'Sorry, that Login Username already exist!'
									: false
							}
							error={
								errors?.userName
									? true
									: state.isSameUsername === true
									? true
									: false
							}
							control={control}
							ref={register}
						/>
						{/* <Controller
							as={TextField}
							fullWidth
							name="department"
							margin="normal"
							label="Department"
							control={control}
							ref={register}
							required
							helperText={errors?.department?.message}
							error={errors?.department ? true : false}
						/>
						<Controller
							as={TextField}
							fullWidth
							name="designation"
							margin="normal"
							label="Designation"
							control={control}
							required
							helperText={errors?.designation?.message}
							error={errors?.designation ? true : false}
							ref={register}
						/> */}
						<Controller
							as={
								<TextField
									inputProps={{
										value: editData?.email,
										onChange: e => handleEmail(e.currentTarget.value),
									}}
								/>
							}
							margin="normal"
							name="email"
							label="Email"
							fullWidth
							disabled={mode === 'edit'}
							required
							helperText={
								errors?.email?.message
									? errors?.email?.message
									: state.isSameEmail === true
									? 'Sorry, that Email already exist!'
									: false
							}
							error={
								errors?.email ? true : state.isSameEmail === true ? true : false
							}
							control={control}
							ref={register}
							autoComplete="nope"
						/>
						{mode === 'add' && (
							<>
								<Controller
									as={TextField}
									name="password"
									label="New Password *"
									autoComplete="off"
									fullWidth
									ref={register}
									control={control}
									helperText={errors?.password?.message}
									error={errors?.password ? true : false}
									type={state.showNewPassword ? 'text' : 'password'}
									value={getValues('password')}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Password"
													onClick={() =>
														dispatch({
															type: 'showNewPassword',
															payload: !state.showNewPassword,
														})
													}
												>
													{state.showNewPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>

								<Controller
									as={TextField}
									name="confirmPassword"
									label="Confirm Password *"
									autoComplete="off"
									fullWidth
									ref={register}
									control={control}
									helperText={errors?.confirmPassword?.message}
									error={errors?.confirmPassword ? true : false}
									type={state.showNewPassword ? 'text' : 'password'}
									value={getValues('password')}
									InputProps={{
										endAdornment: (
											<InputAdornment position="end">
												<IconButton
													aria-label="Password"
													onClick={() =>
														dispatch({
															type: 'showNewPassword',
															payload: !state.showNewPassword,
														})
													}
												>
													{state.showNewPassword ? (
														<Visibility />
													) : (
														<VisibilityOff />
													)}
												</IconButton>
											</InputAdornment>
										),
									}}
								/>
							</>
						)}
						<div style={{ margin: '10px' }}></div>
						<Controller
							as={
								<PhoneNumInput
									country={'my'}
									specialLabel="Contact No."
									fullWidth
									// inputProps={{
									// 	style: {
									// 		borderColor: errors?.contactNo ? '#E53935' : null,
									// 	},
									// }}
								/>
							}
							// customInput={TextField}
							name="contactNo"
							type="tel"
							marginTop
							marginBottom
							// required
							margin="normal"
							control={control}
							ref={register}
							helperText={errors?.contactNo?.message}
							error={errors?.contactNo ? true : false}
							autoComplete="off"
						/>
						{/* {errors?.contactNo && (
							<div
								style={{
									color: '#E53935',
									fontWeight: 400,
									margin: '5px 0px 20px',
								}}
								className="mdDesc"
							>
								{errors?.contactNo?.message}
							</div>
						)} */}
						{/* <div style={{ margin: '10px' }}></div> */}

						<div
							className="content-wrap full"
							style={{ justifyContent: 'space-between', display: 'flex' }}
						>
							<span className="flex-space">Super User</span>
							<Switch
								checked={state.isSuperUser}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									dispatch({
										type: 'isSuperUser',
										payload: event.target.checked,
									});
								}}
								name="superUser"
								color="primary"
							/>
						</div>
						<div
							className="content-wrap full"
							style={{ justifyContent: 'space-between', display: 'flex' }}
						>
							<span className="flex-space">Active</span>

							<Switch
								checked={state.active}
								onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
									dispatch({
										type: 'active',
										payload: event.target.checked,
									});
								}}
								name="superUser"
								color="primary"
							/>
						</div>
					</CardContents>
				</form>
			</ContentWrapper>

			<DynamicFooter
				options={[
					{
						name: 'Save',
						onClick: () => handleSubmit(onSubmit)(),
						color: 'primary',
						props: {
							type: 'submit',
						},
						disabled: state?.isSameUsername === true ? true : false,
					},
				]}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/general-setting/user-access/user`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										if (mode === 'edit') {
											history.push({
												pathname: `/menu/outlet-app/general-setting/user-access/user`,
											});
										} else {
											history.push({
												pathname: `/menu/outlet-app/general-setting/user-access/user`,
											});
										}
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
		</>
	);
};
