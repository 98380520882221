import theme from '@ifca-root/react-component/src/assets/theme';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import {
	DraggableItem,
	DragNDropList,
	DropableItem,
} from '@ifca-root/react-component/src/components/CardList/DragAndDropList';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { MsgAlert } from '@ifca-root/react-component/src/components/others/MsgAlert';
import { Grid, useMediaQuery } from '@material-ui/core';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	useDragTableMutation,
	useGetOutletQuery,
	useGetTableQuery,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

export const TableList = () => {
	const { outletID } = useParams<any>();
	const history = useHistory();

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [openDialog, setOpenDialog] = useState(false);

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const {
		data: { getTable } = { getTable: [] },
		loading: tableLoading,
	} = useGetTableQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'sequence', isActive: true },
	});

	const [
		dragTable,
		{ loading: dragTableLoading, error: dragTableError },
	] = useDragTableMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setOpenDialog(false);
		},
	});

	let [initialData, setInitialData] = useState<any>();

	useEffect(() => {
		setInitialData(getTable);
	}, [getTable]);

	const onDragEnd = ({ source, destination }) => {
		if (destination === undefined || destination === null) return null;

		if (
			source.droppableId === destination.droppableId &&
			destination.index === source.index
		)
			return null;

		const start = source.droppableId;
		const end = destination.droppableId;

		if (start === end) {
			let testArray = initialData.filter((_, idx) => idx !== source.index);

			testArray.splice(destination.index, 0, initialData[source.index]);

			setInitialData([...testArray]);

			dragTable({
				variables: {
					input: testArray?.map((el, index) => {
						return {
							ID: el?.ID,
							prefix: el?.prefix,
							tableNumber: el?.tableNumber,
							seatCount: el?.seatCount,
							sequence: index + 1,
							outletID: outletID,
						};
					}),
				},
			});

			return null;
		}
	};

	return (
		<>
			{outletLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Table', current: true },
				]}
			/>

			<ContentWrapper
				tableHeader
				style={{
					marginTop: isDesktop ? '84px' : '-3px',
					padding: '8px 8px 0px',
					top: isDesktop ? '65px' : '65px',
				}}
			>
				<MsgAlert message={'Drag & drop to arrange sequence. '} />
				<CardContents>
					<Grid container>
						<Grid xs={3} className="left">
							<div className="content-wrap full">
								<div style={{ padding: '3px' }} className="xsTitle">
									Prefix
								</div>
							</div>
						</Grid>
						<Grid xs={3} className="left">
							<div className="content-wrap full">
								<div
									style={{ padding: '3px', textAlign: 'right' }}
									className="xsTitle"
								>
									Table No.
								</div>
							</div>
						</Grid>
						<Grid xs={6} className="left">
							<div className="content-wrap full">
								<div
									style={{ padding: '3px', textAlign: 'right' }}
									className="xsTitle"
								>
									Max Seating
								</div>
							</div>
						</Grid>
					</Grid>
				</CardContents>
			</ContentWrapper>

			<ContentWrapper float style={{ marginTop: isDesktop ? '130px' : '67px' }}>
				{!tableLoading &&
				!outletLoading &&
				(getTable === undefined || getTable?.length === 0) ? (
					<EmptyList
						title="No Record found"
						subtitle="Please add table to continue"
					/>
				) : (
					<div className="table-form">
						<CardContents devFullWidth={true}>
							<DragNDropList onDragEnd={onDragEnd}>
								<DropableItem droppableId={'table_list'}>
									{initialData?.map((el, index) => {
										return (
											<>
												<div className="table-list">
													<DraggableItem
														id={el?.ID}
														index={index}
														draggableId={el?.ID}
													>
														<Grid container>
															<Grid item xs={3} className="left">
																<div className="content-wrap full">
																	<div className="xsTitle">{el?.prefix}</div>
																</div>
															</Grid>
															<Grid item xs={3} className="left">
																<div className="content-wrap full">
																	<div
																		className="xsTitle"
																		style={{
																			padding: '3px',
																			textAlign: 'right',
																		}}
																	>
																		<span
																			className="xxTitle fw-medium"
																			style={{ color: '#ff9800' }}
																		>
																			{el?.tableNumber}
																		</span>
																	</div>
																</div>
															</Grid>
															<Grid item xs={6} className="right">
																<div className="content-wrap full">
																	<div
																		className="xsTitle"
																		style={{
																			padding: '3px',
																			textAlign: 'right',
																		}}
																	>
																		<span
																			className="xxTitle fw-medium"
																			style={{ color: '#ff9800' }}
																		>
																			{el?.seatCount}
																		</span>
																	</div>
																</div>
															</Grid>
														</Grid>
													</DraggableItem>
												</div>
											</>
										);
									})}
								</DropableItem>
							</DragNDropList>
						</CardContents>
					</div>
				)}
			</ContentWrapper>

			<FloatButton
				isCreate={true}
				onClick={() =>
					history.push(`/menu/outlet-app/outlet-setting/${outletID}/table/add`)
				}
			/>
		</>
	);
};
