import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import IconText from '@ifca-root/react-component/src/components/Typography/IconText';
import TextSeparator from '@ifca-root/react-component/src/components/Typography/TextSeparator';
import {
	Grid,
	List,
	ListItem,
	ListItemText,
	Typography,
	useMediaQuery,
} from '@material-ui/core';
import AccessTimeFilledIcon from '@material-ui/icons/AccessTime';
import {
	PaymentDialogComponent,
	ReasonDialog,
	SignatureDialog,
} from 'components/Dialog/PaymentDialogComponent';
import { BillFooter } from 'components/Footer/BillFooter';
import {
	BillListingPaymentFooter,
	ChangePaymentFooter,
} from 'components/Footer/BillListingPaymentFooter';
import { MutliLineSubheader } from 'components/Layout/MultiLineSubHeader';
import { OrderItemList } from 'components/Order/OrderItemList';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CommonStatus,
	CreditCardType,
	GetBillListingDocument,
	OrderItemDiscountStatus,
	PaymentClass,
	PaymentStatus,
	PosPermission,
	useGetBillListingQuery,
	useGetDefaultPaymentQuery,
	useGetMenuXDebtorListLazyQuery,
	useGetMenuXInhouseListLazyQuery,
	useGetMenuXInhouseStatusLazyQuery,
	useGetOfficerLazyQuery,
	useGetOutletQuery,
	useGetReasonLazyQuery,
	useGetStaffLazyQuery,
	useGetUsersByAccountAndSoftwareQuery,
	useVoidBillMutation,
	useVoidPaymentMutation,
} from 'generated/graphql';
import { formatTime } from 'helpers/hooks/formatDate';
import { sortDescByKey } from 'helpers/hooks/stringNumberFunction/sortByKey';
import { usePermissionChecker } from 'helpers/hooks/usePermissionChecker';
import { amtNumStr } from 'helpers/numFormatter';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { AuditDialog } from '../Home/TableSubmenu/AuditLog/AuditDialog';
import AuditIcon from 'assets/icons/audit.svg';
import TimeBlackIcon from 'assets/icons/time_black.svg';

export interface BillListingProps {
	defaultPaymentID: string;
	patronAccountID: string;
	receivedAmount: any;
	creditCardType: any;
}

export const BillListingDetail = () => {
	useEffect(() => {
		console.log('BillListingDetail Outlet App');
	}, []);
	const history = useHistory();
	const location = useLocation();
	const { outletID, billID } = useParams<any>();
	const { globalState, dispatch }: any = useContext(AppContext as any);

	const user = JSON.parse(localStorage.getItem('loggedInUser'));

	const [negOrderItem, setNegOrderItem] = useState({});
	const [openPayment, setOpenPayment] = useState(false);
	const [isBill, setIsBill] = useState(false);
	const [isChangePayment, setIsChangePayment] = useState(false);
	const [checkedID, setCheckedID] = useState([]);
	const [voidAmount, setVoidAmount] = useState({});

	const [selectedMember, setSelectedMember] = useState({
		selectedAccountNo: null,
	});

	const [minVal, setMinVal] = useState(0);
	const [openSignature, setOpenSignature] = useState(false);
	const [openReason, setOpenReason] = useState(false);
	const [changeAmount, setChangeAmount] = useState(0); //change amount
	const [receivedAmount, setReceivedAmount] = useState(0);
	const [mode, setMode] = useState('payment');

	const [billInput, setBillInput] = useState({} as any);
	const [paymentInput, setPaymentInput] = useState({} as any);
	const [radioError, setRadioError] = useState(false);

	const [selectedRoom, setSelectedRoom] = useState({} as any);
	const [selectedNonGuest, setSelectedNonGuest] = useState({} as any);
	const [selectedFolio, setSelectedFolio] = useState({} as any);
	const [selectedOfficer, setSelectedOfficer] = useState({} as any);
	const [selectedStaff, setSelectedStaff] = useState({} as any);
	const [selectedDebtor, setSelectedDebtor] = useState({} as any);
	const [defaultRoom, setDefaultRoom] = useState({} as any);
	const [defaultNonGuest, setDefaultNonGuest] = useState({} as any);
	const [defaultFolio, setDefaultFolio] = useState({} as any);
	const [defaultDebtor, setDefaultDebtor] = useState({} as any);
	const [defaultOfficer, setDefaultOfficer] = useState({} as any);
	const [defaultStaff, setDefaultStaff] = useState({} as any);
	const [selectedCredit, setSelectedCredit] = useState({} as any);

	const [paymentClassArr, setPaymentClassArr] = useState([]);

	const { handlePermDisabled } = usePermissionChecker();

	const { setOpenSnackBar, setSnackBarMsg }: any = useContext(
		SnackBarContext as any,
	);

	const [itemArray, setItemArray] = useState([]);
	const {
		loading: billLoading,
		error: billError,
		data: { getBill } = { getBill: [] },
	} = useGetBillListingQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
			ID: billID,
		},
		onCompleted: ({ getBill }) => {
			setPaymentClassArr(getBill[0]?.payments?.map(pay => pay?.paymentClass));
			let temp = {};
			let orderItem = getBill[0]?.orderItem;
			orderItem?.map(el => {
				let totalTax = 0;
				el?.orderItemTax?.map(x => {
					totalTax += x?.taxAmount;
				});

				const activeOID = el?.orderItemDiscount?.filter(
					i => i?.status === OrderItemDiscountStatus?.Active,
				)[0];

				let totalOIDTax = 0;
				activeOID?.orderItemDiscountTax?.map(v => {
					totalOIDTax += v?.taxAmount;
				});

				let totalODITax = 0;
				el?.orderDiscountItem?.orderDiscountItemTax?.map(v => {
					totalODITax += v?.taxAmount;
				});

				if (el?.refID && el?.quantity < 0) {
					if (el?.refID in temp) {
						temp[el?.refID].quantity += el?.quantity * -1;
						temp[el?.refID].amount += el?.amount;
						temp[el?.refID].taxAmount += totalTax;

						temp[el?.refID].orderItemDiscBaseAmount += activeOID?.baseAmount;
						temp[el?.refID].orderItemDiscTaxAmount += totalOIDTax;

						temp[el?.refID].orderDiscountItemBaseAmount +=
							el?.orderDiscountItem?.baseAmount;
						temp[el?.refID].orderDiscountItemTaxAmount += totalODITax;
					} else {
						temp[el?.refID] = {
							quantity: el?.quantity * -1,
							amount: el?.amount * -1,
							taxAmount: totalTax * -1,

							orderItemDiscBaseAmount:
								isNaN(activeOID?.baseAmount * -1) === true
									? 0
									: activeOID?.baseAmount * -1,
							orderItemDiscTaxAmount:
								isNaN(totalOIDTax * -1) === true ? 0 : totalOIDTax * -1,

							orderDiscountItemBaseAmount:
								isNaN(el?.orderDiscountItem?.baseAmount * -1) === true
									? 0
									: el?.orderDiscountItem?.baseAmount * -1,
							orderDiscountItemTaxAmount:
								isNaN(totalODITax * -1) === true ? 0 : totalODITax * -1,
							orderItemOptions: [],
						};

						// Loop through the orderItemOptionArray
						for (let i = 0; i < el?.orderItemOption?.length; i++) {
							const option = el?.orderItemOption[i];
							const optionItem = el?.orderItemOption[i]?.orderItemOptionItem;
							let optionData;

							if (optionItem) {
								optionItem?.map(x => {
									let totalTax = 0;
									x?.orderItemOptionItemTax?.map(v => {
										totalTax += v?.taxAmount;
									});

									let totalOptItemOIDTax = 0;
									x?.orderItemOptionItemDiscountTax?.map(v => {
										totalOptItemOIDTax += v?.taxAmount;
									});

									let totalOptItemODITax = 0;
									x?.orderDiscountItemOptionTax?.map(v => {
										totalOptItemODITax += v?.taxAmount;
									});

									optionData = {
										optionID: option?.optionID,
										orderItemOptionItem: {
											basePrice: x?.basePrice * -1,
											taxAmount: totalTax * -1,
											discountBaseAmount: x?.discountBaseAmount * -1,
											itemDiscountTaxAmount: totalOptItemOIDTax * -1,
											discountItemTaxAmount: totalOptItemODITax * -1,
										},
									};
								});
								temp[el?.refID].orderItemOptions.push(optionData);
							}
						}
					}
				}
			});
			setNegOrderItem({ ...temp });
			setItemArray(
				orderItem
					?.map(el => {
						if (
							el?.quantity - temp[el?.ID]?.quantity === 0 ||
							el?.quantity <= 0
						) {
							return { ...el, void: true };
						} else {
							return { ...el, void: false };
						}
					})
					?.filter(list => list.void === false),
				// ?.filter(list2 => list2.quantity > 0), // CAUSE HERE WE WANNA SEE THE SLASH2 ONE
			);
			// setItemArray(orderItem);
		},
	});

	const registrationID = getBill[0]?.payments?.filter(
		el => el?.status === PaymentStatus.Open && el?.registrationID !== null,
	)[0]?.registrationID;

	const [
		loadMenuXInhouseStatus,
		{
			loading: MenuXInhouseStatusLoading,
			data: { getMenuXInhouseStatus } = { getMenuXInhouseStatus: false },
		},
	] = useGetMenuXInhouseStatusLazyQuery({
		fetchPolicy: 'no-cache',
	});

	useEffect(() => {
		if (registrationID !== undefined) {
			loadMenuXInhouseStatus({
				variables: {
					registrationID: registrationID,
					outletID: outletID,
				},
			});
		}
	}, [registrationID]);

	const inhouseStatus =
		!MenuXInhouseStatusLoading && getMenuXInhouseStatus !== false
			? getMenuXInhouseStatus?.data?.MenuXInhouseStatus
			: false;

	const [voidPayment, { loading: voidPaymentLoading }] = useVoidPaymentMutation(
		{
			onError: error => {
				console.log(error);
			},
			onCompleted: ({ voidPayment }) => {
				setOpenReason(false);
				setOpenSignature(false);
				if (voidPayment) {
					setOpenSnackBar(true);
					setSnackBarMsg(SystemMsgs.voidRecord());
					history.push({
						pathname: `/menu/outlet-app/${outletID}/bill-listing/${billID}/bill-receipt/void-payment`,
					});
				} else {
					setOpenSnackBar(true);
					setSnackBarMsg(SystemMsgs.voidRecordFail());
				}
			},
		},
	);

	const [voidBill, { loading: voidBillLoading }] = useVoidBillMutation({
		onError: error => {
			console.log(error);
		},
		onCompleted: ({ voidBill }) => {
			setOpenReason(false);
			setIsBill(false);
			if (voidBill) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.voidRecord());
				history.push({
					pathname: `/menu/outlet-app/${outletID}/bill-listing/${billID}/bill-receipt/void-bill`,
				});
			} else {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.voidRecordFail());
			}
		},
	});

	const {
		loading: paymentLoading,
		error: paymentError,
		called: paymentCalled,
		data: { getDefaultPayment } = { getDefaultPayment: [] },
	} = useGetDefaultPaymentQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID },
	});

	const selectedPaymentClass = () => {
		const name = getDefaultPayment?.filter(
			x => x?.ID === watch('defaultPaymentID'),
		)[0]?.paymentClass;
		return name;
	};

	const [
		loadReason,
		{
			loading: reasonLoading,
			error: reasonError,
			called: reasonCalled,
			data: { getReason } = { getReason: [] },
		},
	] = useGetReasonLazyQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
		},
	});

	const handleSort = () => {
		if (!billLoading) {
			let temp = getBill[0]?.payments.slice();
			return sortDescByKey(temp, 'createdTs')?.filter(x => x?.amount > 0);
		}
	};

	const {
		called: ouletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
		onCompleted: ({ getOutlet }) => {
			loadMenuXInHouseList({
				variables: {
					hotelID: getOutlet[0]?.outletChannel?.hotelID,
					outletID: outletID,
				},
			});
			loadMenuXDebtorList({
				variables: {
					hotelID: getOutlet[0]?.outletChannel?.hotelID,
					outletID: outletID,
				},
			});
			loadOfficer({
				variables: {
					isActive: true,
				},
			});
			loadStaff({
				variables: {
					isActive: true,
				},
			});
		},
	});
	const outletArray: any[] = getOutlet;

	const {
		loading: userLoading,
		error,
		data: { getUsersByAccountAndSoftware } = {
			getUsersByAccountAndSoftware: [],
		},
	} = useGetUsersByAccountAndSoftwareQuery({
		fetchPolicy: 'network-only',
		variables: {
			status: CommonStatus.Active,
		},
	});

	useEffect(() => {
		if (openReason) {
			loadReason({
				variables: {
					outletID: outletID,
				},
			});
		}
	}, [openReason]);

	const billOrderItems = getBill[0]?.orderItem?.filter(x => x?.quantity > 0);

	//CALCULATION
	const oiArray = getBill[0]?.orderItem;

	let totalOptItm = 0,
		discOptItm = 0,
		optTaxAmount = 0,
		optServiceCharge = 0,
		discOptTaxAmount = 0,
		discOptServiceCharge = 0;

	oiArray?.map(v => {
		//overall option item important field to sum up
		const sumOptionItemOverallField = (
			items,
		): {
			basePrice: number;
			taxAmount: number;
			discountBaseAmount: number;
			itemDiscountTaxAmount: number;
			discountItemTaxAmount: number;
		} => {
			let totalBasePriceOpt = 0;
			let totalTaxAmountOpt = 0;
			let totalDiscBaseAmountOpt = 0;
			let totalItemDiscTaxAmountOpt = 0;
			let totalDiscItemTaxAmountOpt = 0;
			for (const item of items) {
				if (item.orderItemOption) {
					for (const option of item.orderItemOption) {
						if (option.orderItemOptionItem) {
							for (const optionItem of option.orderItemOptionItem) {
								let totalTax = 0;
								optionItem?.orderItemOptionItemTax?.map(x => {
									totalTax += x?.taxAmount;
								});

								let totalOptItemOIDTax = 0;
								optionItem?.orderItemOptionItemDiscountTax?.map(x => {
									totalOptItemOIDTax += x?.taxAmount;
								});

								let totalOptItemODITax = 0;
								optionItem?.orderDiscountItemOptionTax?.map(v => {
									totalOptItemODITax += v?.taxAmount;
								});

								totalBasePriceOpt += optionItem.basePrice;
								totalTaxAmountOpt += totalTax;
								totalDiscBaseAmountOpt += optionItem.discountBaseAmount;
								totalItemDiscTaxAmountOpt += totalOptItemOIDTax;
								totalDiscItemTaxAmountOpt += totalOptItemODITax;
							}
						}
					}
				}
			}
			return {
				basePrice: totalBasePriceOpt,
				taxAmount: parseFloat(totalTaxAmountOpt.toFixed(2)),
				discountBaseAmount: totalDiscBaseAmountOpt,
				itemDiscountTaxAmount: parseFloat(totalItemDiscTaxAmountOpt.toFixed(2)),
				discountItemTaxAmount: parseFloat(totalDiscItemTaxAmountOpt.toFixed(2)),
			};
		};

		const totalOptItmOverallAmount = sumOptionItemOverallField(
			getBill[0]?.orderItem,
		);

		optTaxAmount = totalOptItmOverallAmount?.taxAmount;

		discOptTaxAmount =
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;

		totalOptItm =
			totalOptItmOverallAmount?.basePrice + totalOptItmOverallAmount?.taxAmount;

		discOptItm =
			totalOptItmOverallAmount?.discountBaseAmount +
			totalOptItmOverallAmount?.itemDiscountTaxAmount +
			totalOptItmOverallAmount?.discountItemTaxAmount;
	});

	//discount
	// const orderItemDiscount = creduce((a: any, b: any) => {
	//   let totalOIDTax = 0;
	// 			v?.orderItemDiscountTax?.map(x => {
	// 				totalOIDTax += x?.taxAmount;
	// 			});
	// 	return (
	// 		a +
	// 		((b?.orderItemDiscount?.filter(
	// 			x => x?.status === OrderItemDiscountStatus.Active,
	// 		)[0]?.baseAmount ?? 0) +
	// 			(b?.orderItemDiscount?.filter(
	// 				x => x?.status === OrderItemDiscountStatus.Active,
	// 			)[0]?.taxAmount ?? 0))
	// 	);
	// }, 0);

	let orderItemDiscount,
		oidAmount = 0,
		oidTax = 0,
		orderDiscountItem,
		odiAmount = 0,
		odiTax = 0;
	getBill[0]?.orderItem?.map(el => {
		const activeOID = el?.orderItemDiscount?.filter(
			x => x?.status === OrderItemDiscountStatus?.Active,
		)[0];

		const OIDamount = activeOID?.baseAmount ?? 0;
		const ODIamount = el?.orderDiscountItem?.baseAmount ?? 0;

		let totalOIDTax = 0;
		activeOID?.orderItemDiscountTax?.map(x => {
			totalOIDTax += x?.taxAmount;
		});

		let totalODITax = 0;
		el?.orderDiscountItem?.orderDiscountItemTax?.map(x => {
			totalODITax += x?.taxAmount;
		});

		oidAmount += OIDamount;
		oidTax += totalOIDTax;

		odiAmount += ODIamount;
		odiTax += totalODITax;
	});

	orderItemDiscount = oidAmount + oidTax;
	orderDiscountItem = odiAmount + odiTax;

	const discount =
		orderItemDiscount > 0
			? orderItemDiscount + discOptItm ?? 0
			: orderDiscountItem + discOptItm ?? 0;

	//subtotal
	const baseAmount = getBill[0]?.orderItem?.reduce(
		(accumulator: any, currentValue: any) => {
			return accumulator + currentValue?.amount;
		},
		0,
	);

	const orderItem = getBill[0]?.orderItem;

	let totalTax = 0;
	orderItem?.map(x => {
		x?.orderItemTax?.map(v => {
			totalTax += v?.taxAmount;
		});
		return totalTax;
	});

	const subtotal = baseAmount + totalTax + totalOptItm;

	const billLedgerSubtotal = getBill[0]?.orderItem?.reduce(
		(accumulator: any, currentValue: any) => {
			return accumulator + currentValue?.totalInfo?.totalAmount;
		},
		0,
	);

	//service charge
	const serviceCharge = getBill[0]?.orderItem?.reduce(
		(accumulator: any, currentValue: any) => {
			return accumulator + currentValue?.serviceCharge;
		},
		0,
	);

	const oidSC = getBill[0]?.orderItem?.reduce((a: any, b: any) => {
		return (
			a +
			(b?.orderItemDiscount?.filter(
				x => x?.status === OrderItemDiscountStatus.Active,
			)[0]?.serviceCharge ?? 0)
		);
	}, 0);

	const odiSC = getBill[0]?.orderItem?.reduce((a: any, b: any) => {
		return a + (b?.orderDiscountItem?.serviceCharge ?? 0);
	}, 0);

	const finalServiceCharge =
		serviceCharge + optServiceCharge - oidSC - odiSC - discOptServiceCharge;

	//tax
	const tax = getBill[0]?.orderItem?.reduce(
		(accumulator: any, currentValue: any) => {
			return accumulator + currentValue?.taxAmount;
		},
		0,
	);

	const oidTx = getBill[0]?.orderItem?.reduce((a: any, b: any) => {
		return (
			a +
			(b?.orderItemDiscount?.filter(
				x => x?.status === OrderItemDiscountStatus.Active,
			)[0]?.taxAmount ?? 0)
		);
	}, 0);

	const odiTx = getBill[0]?.orderItem?.reduce((a: any, b: any) => {
		return a + (b?.orderDiscountItem?.taxAmount ?? 0);
	}, 0);

	const finalTax = tax + optTaxAmount - oidTx - odiTx - discOptTaxAmount;

	//rounding
	let rounding = 0;
	getBill[0]?.payments
		?.filter(x => x?.status === 'OPEN')
		?.map(v => {
			rounding += Number(v?.roundingAmount);
		});

	//total
	const total = subtotal - discount + rounding;
	const amount = subtotal - discount;

	const [fs, setFS] = useState(null);
	const [ta, setTa] = useState(null);
	const [fd, setFD] = useState(null);
	const [to, setTo] = useState(null);
	const [ro, setRo] = useState(null);
	const [amt, setAmt] = useState(null);

	useEffect(() => {
		setFS(subtotal);
		setTa(finalTax);
		setFD(discount);
		setTo(total);
		setRo(rounding);
		setAmt(amount);
	}, [subtotal, discount, finalTax, total, rounding, amount]);

	const [radioSubInput, setRadioSubInput] = useState({
		cardReference: null,
		receivedAmount: to,
	});

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const yupSchema = yup.object().shape({
		// receivedAmount: yup
		// 	.number()
		// 	.min(to, `Amount cannot be less than bill amount`)
		// 	.typeError('Amount cannot be empty')
		// 	.nullable(),
	});

	const {
		register,
		control,
		handleSubmit,
		errors,
		clearErrors,
		watch,
		setValue,
		getValues,
		reset,
	} = useForm<BillListingProps>({
		defaultValues: {},
		resolver: yupResolver(yupSchema),
		mode: 'onSubmit',
	});

	const handleSelectedMember = (id, index) => {
		setSelectedMember({
			selectedAccountNo: id,
		});
	};

	const memberRoomRadioID = watch => {
		//Member Radio ID
		if (
			watch ===
			getDefaultPayment?.filter(x => x?.paymentClass === PaymentClass.Member)[0]
				?.ID
		) {
			return true;
		}
		//Room Radio ID
		else if (
			watch ===
			getDefaultPayment?.filter(x => x?.paymentClass === PaymentClass.Room)[0]
				?.ID
		) {
			return true;
		}
		//NonGuest Radio ID
		else if (
			watch ===
			getDefaultPayment?.filter(
				x => x?.paymentClass === PaymentClass.NonGuest,
			)[0]?.ID
		) {
			return true;
		}
		//Card Radio ID
		else {
			return false;
		}
	};

	const cardRadioID = watch => {
		if (
			watch ===
			getDefaultPayment?.filter(
				x => x?.paymentClass === PaymentClass?.Credit,
			)[0]?.ID
		) {
			return true;
		} else {
			return false;
		}
	};

	const [creditRadioID, setCreditRadioID] = useState(null);

	const [forPatronAcc, setForPatronAcc] = useState({
		room: null,
		nonGuest: null,
		member: null,
		folio: null,
	});

	useEffect(() => {
		setCreditRadioID(
			getDefaultPayment?.filter(x => x?.paymentClass == PaymentClass.Credit)[0]
				?.ID,
		);

		setForPatronAcc({
			room: getDefaultPayment?.filter(
				x => x?.paymentClass == PaymentClass.Room,
			)[0]?.ID,
			nonGuest: getDefaultPayment?.filter(
				x => x?.paymentClass == PaymentClass.NonGuest,
			)[0]?.ID,
			member: getDefaultPayment?.filter(
				x => x?.paymentClass == PaymentClass.Member,
			)[0]?.ID,
			folio: getDefaultPayment?.filter(
				x => x?.paymentClass == PaymentClass.SuspendFolio,
			)[0]?.ID,
		});
	}, [openPayment]);

	const latestPaymentIndex = getBill[0]?.payments?.length - 1;

	// const latestPaymentID =
	// 	getBill[0]?.payments[getBill[0]?.payments?.length - 1].ID;

	let cardType: any = watch('cardType');
	let cardNo: any = watch('cardReference');

	const creditCardEnum = data => {
		let credClass;
		if (data === 'American Express Card') {
			credClass = CreditCardType.AmericanExpressCard;
		} else if (data === 'Diners Card') {
			credClass = CreditCardType.DinersCard;
		} else if (data === 'JCB Card') {
			credClass = CreditCardType.JcbCard;
		} else if (data === 'Master Card') {
			credClass = CreditCardType.Mastercard;
		} else if (data === 'UNION PAY') {
			credClass = CreditCardType.UnionpayCard;
		} else if (data === 'Visa Card') {
			credClass = CreditCardType.VisaCard;
		} else if (data === 'Debit Card') {
			credClass = CreditCardType.DebitCard;
		} else if (data === 'E-Wallet') {
			credClass = CreditCardType.Ewallet;
		}
		return credClass;
	};

	const [hotelGuestInfo, setHotelGuestInfo] = useState({} as any);

	const HotelGuestInfo = (paymentClass, registrationID) => {
		if (
			paymentClass === PaymentClass?.Room ||
			paymentClass === PaymentClass?.NonGuest ||
			paymentClass === PaymentClass?.SuspendFolio
		) {
			return getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
				x => x?.RegistrationID === registrationID,
			)[0];
		} else if (paymentClass === PaymentClass?.Debtor) {
			return getMenuXDebtorList?.data?.MenuXDebtorList?.filter(
				x => x?.RegistrationID === registrationID,
			)[0];
		}
	};

	useEffect(() => {
		if (selectedRoom?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedRoom);
		} else if (selectedNonGuest?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedNonGuest);
		} else if (selectedFolio?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedFolio);
		} else if (selectedDebtor?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedDebtor);
		} else if (defaultRoom?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultRoom);
		} else if (defaultNonGuest?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultNonGuest);
		} else if (defaultFolio?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultFolio);
		} else if (defaultDebtor?.CreditBalance !== undefined) {
			setHotelGuestInfo(defaultDebtor);
		} else if (selectedCredit?.CreditBalance !== undefined) {
			setHotelGuestInfo(selectedCredit);
		}
	}, [
		selectedRoom,
		defaultRoom,
		selectedNonGuest,
		defaultNonGuest,
		selectedFolio,
		defaultFolio,
		defaultDebtor,
		selectedDebtor,
		selectedCredit,
	]);

	const saveRounding = (defaultPayment, v, index, lastIndex) => {
		let roundingAmount;
		const gotCash =
			defaultPayment?.filter(x => x?.paymentClass === PaymentClass?.Cash)
				?.length > 0;

		if (gotCash && v?.paymentClass === PaymentClass.Cash) {
			roundingAmount = ro;
		}

		if (gotCash === false && index === lastIndex) {
			roundingAmount = ro;
		}

		return roundingAmount;
	};

	const [lastArray, setLastArray] = useState([]);

	let paymentInputArr = [];
	const onSubmit = (data, files?) => {
		const ref = getBill[0]?.order?.docNo + 'V';
		let billInput;
		let totalReceiveAmount = 0;

		const lastIndex =
			data?.defaultPayment?.filter(x => x?.selected === true)?.length - 1;

		console.log('data', data);

		data?.defaultPayment
			?.filter(x => x?.selected === true)
			?.map((v, index) => {
				billInput = {
					orderID: getBill[0]?.orderID,
					outletID: outletID,
					ID: billID,
					...(v?.paymentClass === PaymentClass.Room ||
					v?.paymentClass === PaymentClass.NonGuest ||
					v?.paymentClass === PaymentClass.SuspendFolio ||
					v?.paymentClass === PaymentClass.Member
						? { file: files }
						: null),
				};

				let paymentInput = {
					outletID: outletID,
					amount:
						v?.paymentClass === PaymentClass?.Cash
							? receivedAmount
							: v?.amountPayment,
					creditCardType:
						cardType?.GuestType !== null && cardType?.GuestType !== undefined
							? creditCardEnum(cardType?.GuestType)
							: cardType,
					cardNo: v?.cardRef,
					changeAmount:
						v?.paymentClass === PaymentClass?.Cash
							? changeAmount >= 0
								? Math.round((Math.abs(changeAmount) + Number.EPSILON) * 100) /
								  100
								: 0
							: 0,
					receivedAmount:
						v?.paymentClass === PaymentClass?.Cash
							? receivedAmount !== 0
								? receivedAmount
								: v?.amountPayment
							: 0,
					roundingAmount:
						saveRounding(data?.defaultPayment, v, index, lastIndex) ?? 0,
					defaultPaymentID: v?.defaultPaymentID,
					reasonCode: data?.reasonCode,
					voidRemark: data?.remark,
					remark: v?.remark,
					paymentClass: v?.paymentClass,
					prevPaymentClass: handleSort()?.defaultPayment?.paymentClass,
					patronAccountID:
						selectedOfficer?.ID !== (null || undefined) ||
						selectedStaff?.ID !== (null || undefined)
							? selectedOfficer?.ID ?? selectedStaff?.ID
							: defaultOfficer?.ID ?? defaultStaff?.ID,
					registrationID:
						v?.paymentClass === PaymentClass?.Credit
							? cardType?.RegistrationID
							: v?.registrationID,
					hotelGuestInfo: {
						BookingNo: HotelGuestInfo(v?.paymentClass, v?.registrationID)
							?.BookingNo,
						FullName: HotelGuestInfo(v?.paymentClass, v?.registrationID)
							?.GuestName,
						RoomNo: HotelGuestInfo(v?.paymentClass, v?.registrationID)?.RoomNo,
						GuestType: HotelGuestInfo(v?.paymentClass, v?.registrationID)
							?.GuestType,
						ArrivalDate: HotelGuestInfo(v?.paymentClass, v?.registrationID)
							?.ArrivalDate,
						DepartureDate: HotelGuestInfo(v?.paymentClass, v?.registrationID)
							?.DepartureDate,
						DebtorAccount: HotelGuestInfo(v?.paymentClass, v?.registrationID)
							?.DebtorAccount,
						CompanyName: HotelGuestInfo(v?.paymentClass, v?.registrationID)
							?.CompanyName,
					},
					billLedgerInput: {
						HotelID: getOutlet[0]?.outletChannel?.hotelID,
						RegistrationID: v?.registrationID,
						OutletCode: getOutlet[0]?.outletCode,
						ID: '',
						IncidentalChargeID: '',
						Reason: 'VOID Bill',
						Description: `VOID - ${getOutlet[0]?.outletCode} -  ${ref.substring(
							0,
							ref.length - 1,
						)}`,
						TrxDate: new Date(),
						Qty: 1,
						UnitPrice: parseFloat(amtNumStr(v?.amountPayment)),
						TrxAmount: parseFloat(amtNumStr(v?.amountPayment)),
						BaseAmount: parseFloat(amtNumStr(v?.amountPayment)),
						TaxAmount: parseFloat(amtNumStr(0)),
						ServiceCharge: parseFloat(amtNumStr(0)),
						ReferenceNo: ref,
						CreatedBy: user?.ID,
					},
					scheduleBillingInput: {
						HotelID: getOutlet[0]?.outletChannel?.hotelID,
						BillNo: ref,
						CardNo: '',
						BillDate: new Date(),
						BillAmount: v?.amountPayment,
						DebtorID:
							v?.paymentClass === PaymentClass?.Credit
								? cardType?.RegistrationID
								: v?.registrationID,
						CreatedBy: user?.ID,
						// RegistrationID:
						// 	defaultDebtor?.RegistrationID ??
						// 	selectedDebtor?.RegistrationID ??
						// 	cardType?.RegistrationID,
					},
				};
				paymentInputArr?.push(paymentInput);
			});

		paymentInputArr?.map(x => {
			totalReceiveAmount += Number(
				x?.paymentClass === PaymentClass.Cash ? x?.receivedAmount : x?.amount,
			);
		});

		setLastArray(paymentInputArr);

		console.log('paymentInputArr', paymentInputArr);

		const billLedgerInput = {
			HotelID: getOutlet[0]?.outletChannel?.hotelID,
			OutletCode: getOutlet[0]?.outletCode,
			ID: '',
			IncidentalChargeID: '',
			Reason: 'VOID Bill',
			Description: `VOID - ${getOutlet[0]?.outletCode} -  ${ref.substring(
				0,
				ref.length - 1,
			)}`,
			TrxDate: new Date(),
			Qty: 1,
			UnitPrice: parseFloat(amtNumStr(to)),
			TrxAmount: parseFloat(amtNumStr(to)),
			BaseAmount: parseFloat(amtNumStr(to)),
			TaxAmount: parseFloat(amtNumStr(0)),
			ServiceCharge: parseFloat(amtNumStr(0)),
			ReferenceNo: ref,
			CreatedBy: user?.ID,
		};

		const scheduleBillingInput = {
			HotelID: getOutlet[0]?.outletChannel?.hotelID,
			BillNo: ref,
			CardNo: '',
			BillDate: new Date(),
			BillAmount: to,
			CreatedBy: user?.ID,
			// RegistrationID:
			// 	defaultDebtor?.RegistrationID ??
			// 	selectedDebtor?.RegistrationID ??
			// 	cardType?.RegistrationID,
		};
		setBillInput(billInput);
		setPaymentInput(paymentInput);

		const paymentBillInput = {
			outletID: outletID,
			reasonCode: data?.reasonCode,
			voidRemark: data?.remark,
			amount: 0,
		};

		const billBillInput = {
			outletID: outletID,
			ID: billID,
		};

		if (isBill) {
			voidBill({
				variables: {
					paymentInput: paymentBillInput,
					billInput: billBillInput,
					billLedgerInput: billLedgerInput,
					scheduleBillingInput: scheduleBillingInput,
				},
				refetchQueries: [
					{
						query: GetBillListingDocument,
						variables: {
							outletID: outletID,
							ID: billID,
						},
					},
				],
			});
		} else {
			voidPayment({
				variables: {
					paymentInput: paymentInputArr,
					billInput: billInput,
					voidPaymentIDs: checkedID?.map(x => x?.paymentID),
					billLedgerInput: billLedgerInput,
					scheduleBillingInput: scheduleBillingInput,
				},
				refetchQueries: [
					{
						query: GetBillListingDocument,
						variables: {
							outletID: outletID,
							ID: billID,
						},
					},
				],
			});
		}
	};

	//Void Stuff
	//Void Stuff
	//Void Stuff
	const isVoidedBill = getBill?.filter(x => x?.status === 'VOID')?.length > 0;

	const isSinglePayment = getBill[0]?.payments?.length === 1;
	const isVoidedPayment = getBill[0]?.payments?.length > 1;

	const [
		loadStaff,
		{ loading: getStaffLoading, data: { getStaff } = { getStaff: [] } },
	] = useGetStaffLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadOfficer,
		{ loading: getOfficerLoading, data: { getOfficer } = { getOfficer: [] } },
	] = useGetOfficerLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadMenuXInHouseList,
		{
			loading: MenuXInhouseListLoading,
			data: { getMenuXInhouseList } = { getMenuXInhouseList: [] },
		},
	] = useGetMenuXInhouseListLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [
		loadMenuXDebtorList,
		{
			loading: MenuXDebtorListLoading,
			data: { getMenuXDebtorList } = { getMenuXDebtorList: [] },
		},
	] = useGetMenuXDebtorListLazyQuery({
		fetchPolicy: 'network-only',
	});

	const customerTypeInfoLine = (patronClass, order) => {
		if (patronClass === 'Visitor') {
			return order?.patronName ?? 'Visitor';
		} else if (patronClass === 'Hotel Guest') {
			const selectedGuest = getMenuXInhouseList?.data?.MenuXInhouseList?.filter(
				x => x?.RegistrationID === order?.patronAccountID,
			)[0];
			return selectedGuest
				? `${selectedGuest?.RoomNo ? `${selectedGuest?.RoomNo} |` : ''} ${
						selectedGuest?.GuestName
				  }`
				: 'Hotel Guest';
		} else if (patronClass === 'Staff') {
			return getStaff?.filter(x => x?.ID === order?.patronAccountID)[0]?.name;
		} else if (patronClass === 'Officer') {
			return getOfficer?.filter(x => x?.ID === order?.patronAccountID)[0]?.name;
		} else if (patronClass === 'Member') {
			return 'Member';
		}
	};

	////// AUDIT DIALOG FOR VOID PAYMENT ///////
	const [openAuditDialog, setOpenAuditDialog] = useState(false);
	const [auditArray, setAuditArray] = useState([]);

	const outletBusinessDate = getOutlet[0]?.businessDate;
	const billBusinessDate = getBill[0]?.businessDate;

	useEffect(() => {
		setAuditArray(
			getBill[0]?.payments?.filter(x => x?.status === PaymentStatus?.Void),
		);
	}, [getBill[0]]);

	const optionsLs = [
		{
			visible:
				billBusinessDate === outletBusinessDate
					? getBill[0]?.billPaymentType !== 'ENT' &&
					  getBill[0]?.billPaymentType !== 'OC'
						? inhouseStatus === true
							? false
							: true
						: false
					: false,
			name: 'Change Payment',
			onClick: () => {
				setIsChangePayment(true);
				// setMode('voidPayment');
				// setOpenPayment(true);
			},
			color:
				handlePermDisabled({
					outletID: outletID,
					permEnum: PosPermission.BillListingVoidPayment,
				}) ||
				isVoidedBill ||
				getBill[0]?.payments?.length === 0 ||
				billBusinessDate !== outletBusinessDate
					? 'secondary'
					: 'primary',
			props: {
				type: 'submit',
			},
			disabled:
				handlePermDisabled({
					outletID: outletID,
					permEnum: PosPermission.BillListingVoidPayment,
				}) ||
				isVoidedBill ||
				getBill[0]?.payments?.length === 0 ||
				billBusinessDate !== outletBusinessDate,
		},
		{
			visible:
				billBusinessDate === outletBusinessDate
					? inhouseStatus === true
						? false
						: true
					: false,
			name: 'Void Bill',
			onClick: () => {
				setMode('voidBill');
				setOpenReason(true);
				setIsBill(true);
			},
			color:
				handlePermDisabled({
					outletID: outletID,
					permEnum: PosPermission.BillListingVoidPayment,
				}) ||
				isVoidedBill ||
				// getBill[0]?.payments?.length === 0 ||
				billBusinessDate !== outletBusinessDate
					? 'secondary'
					: 'primary',
			props: {
				type: 'submit',
			},
			disabled:
				handlePermDisabled({
					outletID: outletID,
					permEnum: PosPermission.BillListingVoidBill,
				}) ||
				isVoidedBill ||
				// getBill[0]?.payments?.length === 0 ||
				billBusinessDate !== outletBusinessDate,
		},
		{
			visible: true,
			name: 'Print',
			onClick: () => {
				history.push({
					pathname:
						// Void Bill Path
						isVoidedBill == true
							? `/menu/outlet-app/${outletID}/bill-listing/${billID}/bill-receipt/void-bill`
							: // Void Payment Path
							isVoidedPayment
							? `/menu/outlet-app/${outletID}/bill-listing/${billID}/bill-receipt/void-payment`
							: // Normal Receipt Path
							  `/menu/outlet-app/${outletID}/bill-listing/${billID}/bill-receipt`,
				});
			},
			color: 'primary',
			props: {
				type: 'submit',
			},
			disabled: handlePermDisabled({
				outletID: outletID,
				permEnum: PosPermission.BillListingPrint,
			}),
		},
	];

	const listItemDisplayFn = el => {
		return (
			<ListItem>
				<ListItemText
					primary={
						<>
							<span className="xsTitle flex-space">
								{el?.defaultPayment?.paymentTypeInfo?.description}
							</span>

							<span className="xsTitle right-info m-t-6">{el?.status}</span>
						</>
					}
					secondary={
						<>
							<Grid container>
								<Grid item xs={8}>
									<span className="extra-row">
										<span
											className="icon-text desc text-overflow flex-space"
											// style={{ marginLeft: '20px' }}
										>
											{el?.reasonCode}
										</span>
									</span>

									<span className="extra-row">
										<span
											className="icon-text desc text-overflow flex-space"
											// style={{ marginLeft: '20px' }}
										>
											{el?.remark}
										</span>
									</span>
								</Grid>
								<Grid item xs={4}>
									<span
										className="desc right-info"
										style={{
											padding: '0px 0px 0px 4px',
										}}
									>
										{
											getUsersByAccountAndSoftware?.filter(
												x => x?.ID === el?.modBy || x?.ID === el?.createdBy,
											)[0]?.name
										}
									</span>
								</Grid>
							</Grid>
						</>
					}
				/>
			</ListItem>
		);
	};

	const voidedAmount = checkedID => {
		let voidedTotal = 0;
		const voidArray = getBill[0]?.payments
			?.map(x => {
				if (checkedID?.some(y => y?.paymentID === x?.ID)) {
					return x;
				}
			})
			?.filter(v => v !== undefined);

		voidArray?.map(a => {
			if (a?.paymentClass === 'CASH') {
				voidedTotal += a?.amount - a?.changeAmount;
			} else {
				voidedTotal += a?.amount;
			}
		});

		return voidedTotal;
	};

	return (
		<>
			{billLoading && <Loading />}
			{reasonLoading && <Loading />}
			{outletLoading && <Loading />}
			{paymentLoading && <Loading />}
			{voidPaymentLoading && <Loading />}
			{voidBillLoading && <Loading />}
			{MenuXInhouseStatusLoading && <Loading />}
			{/* {isLoading && <Loading />} */}

			<MainHeader
				onClick={() => {
					history.push({
						pathname: `/menu/outlet-app/${outletID}/bill-listing`,
					});
				}}
				mainBtn="back"
				smTitle="Outlet App"
				title={getOutlet[0]?.name}
				routeSegments={[{ name: 'Bill' }, { name: 'Details', current: true }]}
			/>
			<MutliLineSubheader
				style={{
					width:
						isDesktop && globalState.drawerOpen
							? 'calc(100% - 288px)'
							: isDesktop && !globalState.drawerOpen
							? 'calc(100% - 48px)'
							: '100%',
					marginLeft: isDesktop ? '3px' : null,
					top: isDesktop ? '155px' : '67px',
				}}
				title={
					getBill === undefined || getBill.length === 0 ? (
						''
					) : (
						<>
							<div>
								<span
									style={
										isVoidedBill ? { textDecoration: 'line-through' } : null
									}
								>
									{`${getBill[0]?.billNo} | ${getBill[0]?.order?.patron?.patronType?.name}`}
								</span>
								<Typography
									variant="caption"
									style={{
										background: '#FFB11F',
										boxShadow: 'none',
										marginLeft: '5px',
										textDecoration: 'none',
										padding: '2px',
									}}
									className="outlet-table-typography"
								>
									{getBill[0]?.order?.table?.prefix}
									{getBill[0]?.order?.table?.tableNumber}
								</Typography>
							</div>
						</>
					)
				}
				rightText={
					<span
						style={isVoidedBill ? { textDecoration: 'line-through' } : null}
					>{`${getBill[0]?.order?.patron?.patronType?.name}`}</span>
				}
				subtitle={
					<>
						<div>
							<img
								src={TimeBlackIcon}
								alt="ifca"
								width={12}
								loading="lazy"
								style={{ paddingRight: '4px' }}
							/>
							<span
								style={
									isVoidedBill
										? {
												textDecoration: 'line-through',
												fontWeight: 500,
												color: '#454545',
										  }
										: {
												fontWeight: 500,
												color: '#454545',
										  }
								}
								className="flex-space"
							>
								{`${formatTime(getBill[0]?.createdTs)}`}
								<TextSeparator />
								{customerTypeInfoLine(
									getBill[0]?.order?.patron?.patronClass,
									getBill[0]?.order,
								)}
							</span>
							<span
								className="desc"
								style={{ float: 'right' }}
								onClick={() =>
									history.push(`${location?.pathname}/audit-log`, {
										orderID: getBill[0]?.order?.ID,
										billID: getBill[0]?.ID,
										paymentID: getBill[0]?.payments?.filter(
											x => x?.status === PaymentStatus.Open,
										)[0]?.ID,
										getMenuXInhouseList: getMenuXInhouseList,
										getStaff: getStaff,
										getOfficer: getOfficer,
									})
								}
							>
								<img
									src={AuditIcon}
									alt="ifca"
									width={12}
									loading="lazy"
									style={{ paddingRight: '4px', paddingLeft: '4px' }}
								/>
							</span>
						</div>
					</>
				}
			/>

			<ContentWrapper singleDynamicInfo longFooter onlyContentScrollable>
				{!billLoading &&
				!reasonLoading &&
				!outletLoading &&
				(billOrderItems === undefined || billOrderItems?.length === 0) ? (
					<EmptyList
						title="There are no order items found"
						subtitle="Please add order items to the order"
					/>
				) : (
					<>
						<List
							style={{
								backgroundColor: 'white',
								color: 'black',
								width: '100%',
							}}
						>
							<OrderItemList
								outletID={outletID}
								itemArray={itemArray}
								negOrderItem={negOrderItem}
								isInclusive={getOutlet[0]?.isInclusive}
								page="bill-listing-detail"
								currencyCode={getOutlet[0]?.currencyCode ?? ''}
							/>
							<ListItem>
								<BillFooter
									style={{
										width:
											isDesktop && globalState.drawerOpen
												? 'calc(100% - 8px)'
												: isDesktop && !globalState.drawerOpen
												? 'calc(100% - 5px)'
												: '98%',
										left: !isDesktop && !globalState.drawerOpen ? 0 : null,
									}}
									page="bill-listing-detail"
									isConfirmOrder
									subtotal={fs}
									tax={ta}
									discount={fd}
									rounding={ro}
									total={to}
									highlightText={true}
									currencyCode={getOutlet[0]?.currencyCode ?? ''}
									outletID={outletID}
									taxSchemeInfo={getBill[0]?.order?.taxSchemeInfo}
									taxSchemeFooterInfo={getBill[0]?.order?.taxSchemeFooterInfo}
									orderID={getBill[0]?.order?.ID}
								/>
							</ListItem>
						</List>
					</>
				)}
			</ContentWrapper>

			<BillListingPaymentFooter
				style={{
					width:
						isDesktop && globalState.drawerOpen
							? 'calc(100% - 284px)'
							: isDesktop && !globalState.drawerOpen
							? 'calc(100% - 44px)'
							: '100%',
					left:
						isDesktop && globalState.drawerOpen
							? null
							: isDesktop && !globalState.drawerOpen
							? null
							: 0,
					bottom: isDesktop ? 51 : 49,
					height: `${
						isSinglePayment ? '130' : `${120 + (handleSort()?.length - 1) * 65}`
					}px`,
				}}
				title={'Payment'}
				data={handleSort()} // for some reason wanna see 2 only ugh
				hotelID={getOutlet[0]?.outletChannel?.hotelID}
				paymentClassArr={paymentClassArr}
				currencyCode={getOutlet[0]?.currencyCode ?? ''}
				isAuditDialog={true}
				setOpenAuditDialog={setOpenAuditDialog}
				isChangePayment={isChangePayment}
				setIsChangePayment={setIsChangePayment}
				checkedID={checkedID}
				setCheckedID={setCheckedID}
			/>

			{getBill[0] && isChangePayment == true ? (
				<ChangePaymentFooter
					label={'Change Payment'}
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					option={{
						name: 'Proceed',
						onClick: e => {
							setMode('voidPayment');
							setOpenPayment(true);
							voidedAmount(checkedID);
						},
						color: 'primary',
						// disabled: checkedID?.length === 0 ? true : false,
					}}
					// label={'Change Payment'}
					// button={{
					// 	// disabled: checkedID?.length === 0 ? true : false,
					// 	color: 'primary',
					// 	onClick: () => {
					// 		setMode('voidPayment');
					// 		setOpenPayment(true);
					// 		voidedAmount(checkedID);
					// 	},
					// }}
				/>
			) : (
				<DynamicFooter options={optionsLs?.filter(x => x?.visible === true)} />
			)}

			<AuditDialog
				openAuditDialog={openAuditDialog}
				setOpenAuditDialog={setOpenAuditDialog}
				auditArray={auditArray}
				listItemDisplayFn={listItemDisplayFn}
			/>
			<PaymentDialogComponent
				paymentType="voidPayment"
				openDialog={openPayment}
				setOpenDialog={setOpenPayment}
				register={register}
				watch={watch}
				getDefaultPayment={getDefaultPayment}
				getOutlet={getOutlet}
				total={voidedAmount(checkedID)}
				setValue={setValue}
				control={control}
				errors={errors}
				reset={reset}
				handleSubmit={handleSubmit}
				openReason={openReason}
				setOpenReason={setOpenReason}
				defaultNonGuest={defaultNonGuest}
				setDefaultNonGuest={setDefaultNonGuest}
				defaultRoom={defaultRoom}
				setDefaultRoom={setDefaultRoom}
				defaultFolio={defaultFolio}
				setDefaultFolio={setDefaultFolio}
				defaultOfficer={defaultOfficer}
				defaultStaff={defaultStaff}
				setDefaultOfficer={setDefaultOfficer}
				setDefaultStaff={setDefaultStaff}
				setDefaultDebtor={setDefaultDebtor}
				defaultDebtor={defaultDebtor}
				selectedRoom={selectedRoom}
				setSelectedRoom={setSelectedRoom}
				selectedNonGuest={selectedNonGuest}
				setSelectedNonGuest={setSelectedNonGuest}
				selectedFolio={selectedFolio}
				setSelectedFolio={setSelectedFolio}
				selectedOfficer={selectedOfficer}
				setSelectedOfficer={setSelectedOfficer}
				setSelectedDebtor={setSelectedDebtor}
				selectedDebtor={selectedDebtor}
				selectedStaff={selectedStaff}
				setSelectedStaff={setSelectedStaff}
				setHotelGuestInfo={setHotelGuestInfo}
				hotelID={getOutlet[0]?.outletChannel?.hotelID}
				integrateCityLedger={getOutlet[0]?.integrateCityLedger}
				outletID={outletID}
				selectedOrder={getBill[0]}
				radioError={radioError}
				setRadioError={setRadioError}
				setOpenSignature={setOpenSignature}
				selectedPaymentClass={selectedPaymentClass}
				onSubmit={onSubmit}
				setChangeAmount={setChangeAmount}
				setReceivedAmount={setReceivedAmount}
				minVal={minVal}
				setMinVal={setMinVal}
				receivedAmount={receivedAmount}
				mode={mode}
				loading1={voidPaymentLoading}
				loading2={voidBillLoading}
				lastArray={lastArray}
				setSelectedCredit={setSelectedCredit}
				selectedCredit={selectedCredit}
			/>

			{/* Reason / Remark Dialog  */}

			<ReasonDialog
				control={control}
				handleSubmit={handleSubmit}
				isBill={isBill}
				setIsBill={setIsBill}
				onSubmit={onSubmit}
				watch={watch}
				register={register}
				paymentID={memberRoomRadioID}
				setSignatureDialog={setOpenSignature}
				getReason={getReason}
				openReason={openReason}
				setOpenReason={setOpenReason}
				setOpenDialog={setOpenPayment}
				loading1={voidPaymentLoading}
				loading2={voidBillLoading}
			/>

			{/* Signature Dialog */}
			<SignatureDialog
				handleSubmit={handleSubmit}
				onSubmit={onSubmit}
				openSignature={openSignature}
				setOpenDialog={setOpenPayment}
				setOpenSignature={setOpenSignature}
				total={to}
				selectedOrder={getBill[0]?.order}
				loading1={voidPaymentLoading}
				loading2={voidBillLoading}
			/>
		</>
	);
};
