import React from 'react';
import { DailyMenuItemAvailabilityForm } from './DailyMenuItemAvailabilityForm';

const dailyMenuItemAvailabilityRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/daily-menu-item-availability`,
		},
		component: <DailyMenuItemAvailabilityForm />,
	},
];

export default dailyMenuItemAvailabilityRoutes;
