import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetOptionDocument,
	useCreateOptionMutation,
	useDeleteOptionMutation,
	useGetOptionQuery,
	useGetOutletQuery,
	useUpdateOptionMutation,
} from 'generated/graphql';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

interface OptionProps {
	name: string;
	maxSelections: string;
}

export const StandardMenuOptionList = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { optionTypeID } = useParams<Record<string, string>>();
	const { outletID }: any = useParams();

	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();
	const [count, setCount] = useState(0);
	const [openDialog, setOpenDialog] = useState(false);
	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [double, setDouble] = useState(false);

	const { mode } = props;
	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);
	// const editData = location?.state as any;

	const {
		data: { getOption } = { getOption: [] },
		called: optionCalled,
		loading: optionLoading,
	} = useGetOptionQuery({
		fetchPolicy: 'network-only',
		variables: { outletID: outletID, orderByAsc: 'name' },
	});
	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	/// <<< SUBMIT MUTATION >>> ///

	const [
		submitOption,
		{ data: createdOption, loading: submitOptionLoading },
	] = useCreateOptionMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setOpenDialog(false);
		},
	});

	/// <<< SUBMIT MUTATION >>> ///

	/// <<< UPDATE MUTATION >>> ///

	const [
		updateOption,
		{ loading: subUpdateLoading, error: subUpdateError },
	] = useUpdateOptionMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			// history.push(`/subcontract/${consubconId}/vo`);

			// setTimeout(() => {
			// 	history.push({
			// 		pathname: `/menu/outlet-app/outlet-setting/${outletID}/option`,
			// 		state: { success: true, msgMode: 'update' },
			// 	});
			// }, 500);
			setOpenDialog(false);
		},
	});

	/// <<< UPDATE MUTATION >>> ///

	const [
		deleteOption,
		{ loading: deleteOptionLoading },
	] = useDeleteOptionMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	/// <<< DELETE MUTATION >>> ///

	const onSubmit = (data: any) => {
		isEdit === false
			? submitOption({
					variables: {
						input: {
							outletID: outletID,
							name: data?.name,
						},
					},
					refetchQueries: [
						{
							query: GetOptionDocument,
							variables: { outletID: outletID, orderByAsc: 'name' },
						},
					],
			  })
			: updateOption({
					variables: {
						input: {
							ID: menu?.ID,
							outletID: outletID,
							name: data?.name,
						},
					},
					refetchQueries: [
						{
							query: GetOptionDocument,
							variables: { outletID: outletID, orderByAsc: 'name' },
						},
					],
			  });
		clearErrors();
	};

	const existingOptionNames = getOption?.map(o => o?.name);

	const yupSchema = yup.object().shape({
		name: yup
			.string()
			.required(SystemMsgs.name())
			.trim()
			.test('name', 'Option name already exist', value => {
				if (
					(watch('name') !== menu?.obj?.name && isEdit === true) ||
					isEdit === false
				) {
					return !existingOptionNames?.includes(value);
				}
				return true;
			}),
	});
	const {
		register,
		control,
		handleSubmit,
		watch,
		errors,
		clearErrors,
		formState,
	} = useForm<OptionProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	// useEffect(() => {
	// 	if (
	// 		(!optionCalled && getOption === undefined) ||
	// 		getOption?.length === 0
	// 	) {
	// 		setOpenDialog(true);
	// 		setIsEdit(false);
	// 	} else {
	// 		setOpenDialog(false);
	// 	}
	// }, getOption);

	const handleCloseDialog = () => {
		setOpenDialog(false);
		clearErrors();
	};

	return (
		<>
			{optionLoading && <Loading />}
			{outletLoading && <Loading />}
			{submitOptionLoading && <Loading />}
			{subUpdateLoading && <Loading />}
			{deleteOptionLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}`,
						//pathname: `/menu/outlet-app/outlet-setting/${outletID}/standard-menu-option`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Standard Menu Option', current: true },
				]}
			/>
			<ContentWrapper>
				<List className="core-list">
					{!optionLoading &&
					outletLoading &&
					(getOption === undefined || getOption?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add option to continue"
						/>
					) : (
						getOption
							// ?.sort((a, b) => {
							// 	return a?.name?.localeCompare(b?.name);
							// })
							?.map((el, index) => (
								<ListItem>
									<ListItemText
										primary={
											<>
												<span className="xsTitle flex-space">{el?.name}</span>
												<span className="xxTitle highlight-text text-separator"></span>
											</>
										}
										secondary={
											<>
												<span className="desc">
													<span className="desc">Total Options: </span>
													<span className="desc">{el?.optionItem?.length}</span>
												</span>
											</>
										}
									/>

									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="more"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, el.ID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() => {
							setOpenDialog(true);
							setIsEdit(true);
							setDouble(false);
						}}
					>
						<span className="">Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() =>
							history.push(
								`/menu/outlet-app/outlet-setting/${outletID}/standard-menu-option/${menu?.ID}/standard-menu-option-item`,
							)
						}
					>
						<span className="">{`Options (${menu?.obj?.optionItem?.length})`}</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<Box mt={1}>
					<CommonDialog
						fullWidth={true}
						open={openDialog}
						onClose={() => {
							handleCloseDialog();
						}}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												<div className="flex session">
													{isEdit ? (
														<div className="title flex-space">
															Edit Standard Menu Option
														</div>
													) : (
														<div className="title flex-space">
															New Standard Menu Option
														</div>
													)}
												</div>
											</div>
										</div>
									</div>
								),
							},
							body: () => (
								<>
									{isEdit === false ? (
										<div className="content-container">
											<Controller
												as={TextField}
												name="name"
												label="Option Name"
												autoComplete="off"
												multiline={true}
												required
												fullWidth
												ref={register}
												control={control}
												helperText={errors?.name?.message}
												error={errors?.name ? true : false}
												onInput={() => {
													setDouble(false);
												}}
												// helperText={
												// 	// errors?.name?.message ||
												// 	existingOptionNames?.includes(watch('name'))
												// 		? 'Option already exist'
												// 		: null
												// }
												// error={
												// 	// errors?.name ||
												// 	existingOptionNames?.includes(watch('name'))
												// 		? true
												// 		: false
												// }
											/>
										</div>
									) : (
										<div className="content-container">
											<Controller
												as={TextField}
												defaultValue={menu?.obj?.name}
												name="name"
												label="Option Name"
												autoComplete="off"
												multiline={true}
												required
												fullWidth
												ref={register}
												control={control}
												helperText={errors?.name?.message}
												error={errors?.name ? true : false}
												onInput={() => {
													setDouble(false);
												}}
												// helperText={
												// 	// errors?.name?.message &&
												// 	existingOptionNames?.includes(watch('name'))
												// 		? 'Option already exist'
												// 		: null
												// }
												// error={
												// 	// errors?.name &&
												// 	existingOptionNames?.includes(watch('name'))
												// 		? true
												// 		: false
												// }
											/>
										</div>
									)}
								</>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => {
												handleCloseDialog();
											},
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Save',

										props: {
											// onClick: () => handleSubmit(onSubmit)(),
											variant: 'contained',
											// color: existingOptionNames?.includes(watch('name'))
											// 	? 'inherit'
											// 	: 'primary',
											// form: 'submit-form',
											// type: 'submit',
											// onClick: () => {
											// 	if (!existingOptionNames?.includes(watch('name'))) {
											// 		handleSubmit(onSubmit)();
											// 	}
											// },
											// onClick: () => {
											// 	handleSubmit(onSubmit)();
											// },
											color: 'primary',
											disabled: double,

											onClick: () => {
												if (errors?.name ? false : true) {
													handleSubmit(onSubmit)();
													setDouble(true);
												}
											},
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID, orderByAsc: 'name' }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteOption}
				refetchDocument={GetOptionDocument}
			/>

			<FloatButton
				onClick={() => {
					setOpenDialog(true);
					setIsEdit(false);
					setDouble(false);
				}}
			/>
		</>
	);
};
