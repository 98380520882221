import { format, parseISO } from 'date-fns';

// export const formatTime = (time: string) => {
// 	const result = parseISO(time);
// 	return time ? format(result, 'hh:mm aa') : 'NA';
// };

export const formatTime = (time: any) => {
	// Check correct time format and split into components
	time = time.substr(0, 5);
	time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [
		time,
	];

	if (time.length > 1) {
		// If time format correct
		time = time.slice(1); // Remove full string match value
		time[5] = +time[0] < 12 ? 'AM' : 'PM'; // Set AM/PM
		time[0] = +time[0] % 12 || 12; // Adjust hours
	}
	return time.join(''); // return adjusted time or original string
};

export const formatDateTime = (date: string) => {
	const result = parseISO(date);
	return date ? format(result, 'hh:mm a') : 'NA';
};
