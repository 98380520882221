import React from 'react';
import moment from 'moment';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import { Box, Button, MenuItem, TextField } from '@material-ui/core';
import { Loop } from '@material-ui/icons';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
	useGetDefaultPaymentQuery,
	useGetPatronQuery,
} from 'generated/graphql';
import { useParams } from 'react-router';

interface Props {
	setFilterValue: any;
	filterValue: any;
	initialValue: any;
	refetch: any;
	open: boolean;
	close: () => void;
	// paymentTypeList?: any;
	// customerTypeList?: any;
}

export const FilterDialog = ({
	setFilterValue,
	filterValue,
	initialValue,
	refetch,
	open,
	close,
}: // paymentTypeList,
// customerTypeList,
Props) => {
	const { outletID } = useParams<any>();

	const {
		loading: getDefaultPaymentLoading,
		data: { getDefaultPayment } = { getDefaultPayment: [] },
	} = useGetDefaultPaymentQuery({
		variables: {
			outletID: outletID,
			// isActive: true;
		},
	});

	const {
		loading: getPatronLoading,
		data: { getPatron } = { getPatron: [] },
	} = useGetPatronQuery({
		variables: {
			outletID: outletID,
			// isActive: true;
		},
	});

	const onSubmit = () => {
		window.scrollTo(0, 0);
		let filter = {};

		filter['startDate'] = moment(filterValue?.startDate)?.isValid()
			? filterValue?.startDate
			: null;

		filter['endDate'] = moment(filterValue?.endDate)?.isValid()
			? filterValue?.endDate
			: null;

		filter['billNo'] = filterValue?.billNo !== '' ? filterValue?.billNo : null;

		filter['paymentType'] =
			filterValue?.paymentType !== '' ? filterValue?.paymentType : null;

		filter['customerType'] =
			filterValue?.customerType !== '' ? filterValue?.customerType : null;

		refetch(filter);
		localStorage.setItem('filterArgs', JSON.stringify(filter));
	};

	return (
		<CommonDialog
			fullWidth={true}
			open={open}
			onClose={close}
			sections={{
				header: {
					dynamic: (
						<div className="">
							<div className="dialog-dynamic-content">
								<div
									className="session"
									style={{
										display: 'flex',
										justifyContent: 'center',
										alignItems: 'center',
									}}
								>
									<div className="title flex-space">Advance Search</div>
									<div>
										<Button
											variant="outlined"
											size="small"
											disableElevation
											onClick={() => {
												window.scrollTo(0, 0);
												localStorage.removeItem('filterArgs');
												setFilterValue({ ...initialValue });
											}}
											startIcon={<Loop />}
											style={{
												color: '#ff9800',
												backgroundColor: 'white',
												borderColor: '#ff9800',
												borderStyle: '10px solid',
											}}
										>
											Reset
										</Button>
									</div>
								</div>
							</div>
						</div>
					),
				},
				body: () => (
					//
					// <CardContents
					// 	devFullWidth={true}
					// 	style={{ boxShadow: 'none', padding: '0px 0px 0px 0px' }}
					// >
					<form onSubmit={() => onSubmit()}>
						<Box display="flex" width="100%">
							<Box pr="6px" width="50%">
								<KeyboardDatePicker
									value={filterValue?.startDate}
									margin="dense"
									autoOk
									label="Start Date"
									className="left date-icon"
									name="startDate"
									onChange={e =>
										setFilterValue({
											...filterValue,
											startDate: moment(e)
												.startOf('day')
												.toISOString(),
										})
									}
									format="dd/MM/yyyy"
									fullWidth
								/>
							</Box>

							<Box pr="6px" width="50%">
								<KeyboardDatePicker
									value={filterValue?.endDate}
									margin="dense"
									autoOk
									label="End Date"
									className="right date-icon"
									name="endDate"
									onChange={e => {
										setFilterValue({
											...filterValue,
											endDate: moment(e)
												.endOf('day')
												.toISOString(),
										});
									}}
									// minDate={filterValue.endDate}
									format="dd/MM/yyyy"
									fullWidth
								/>
							</Box>
						</Box>

						<TextField
							label="Bill No"
							fullWidth
							value={filterValue?.billNo || ''}
							margin="dense"
							name="billNo"
							onChange={e => {
								setFilterValue({
									...filterValue,
									billNo: e.target.value,
								});
							}}
						/>

						<TextField
							select
							label="Payment Type"
							fullWidth
							value={filterValue?.paymentType || ''}
							margin="dense"
							name="paymentType"
							onChange={e => {
								setFilterValue({
									...filterValue,
									paymentType: e.target.value,
								});
							}}
						>
							{getDefaultPayment?.map((i, index) => {
								return (
									<MenuItem key={index} value={i.paymentClass}>
										{i.paymentTypeInfo?.description}
									</MenuItem>
								);
							})}
						</TextField>

						<TextField
							select
							label="Customer Type"
							fullWidth
							value={filterValue?.customerType || ''}
							margin="dense"
							name="customerType"
							onChange={e =>
								setFilterValue({
									...filterValue,
									customerType: e.target.value,
								})
							}
						>
							{getPatron?.map((i, index) => {
								return (
									<MenuItem key={index} value={i?.patronType?.patronClass}>
										{i.patronClass}
									</MenuItem>
								);
							})}
						</TextField>
					</form>
					// </CardContents>
				),
				footer: {
					actions: [
						{
							displayText: 'Cancel',
							props: {
								onClick: () => {
									close();
								},
								variant: 'contained',
								color: 'primary',
							},
						},
						{
							displayText: 'Confirm',
							props: {
								onClick: () => {
									onSubmit();
									close();
								},
								variant: 'contained',
								color: 'primary',
							},
						},
					],
				},
			}}
		/>
	);
};
