import DateFnsUtils from '@date-io/date-fns';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { MultiPieChart } from '@ifca-root/react-component/src/components/Charts/MultiPieChart';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import DynamicSubHeader from '@ifca-root/react-component/src/components/Header/DynamicSubHeader';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import {
	StyledMenu,
	StyledMenuItem,
} from '@ifca-root/react-component/src/components/others/Menu';
import {
	Grid,
	ListItemText,
	MenuItem,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import ShareIcon from '@material-ui/icons/Share';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import AppContext from 'containers/App/Store/AppContext';
import {
	FrequencyType,
	useGetBizInsightOutletQuery,
	useGetOutletSalesCollectionAnalysisLazyQuery,
	useGetShareAttachmentMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import * as htmlToImage from 'html-to-image';
// import { useStyles } from '@material-ui/pickers/views/Month/MonthView';
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory } from 'react-router-dom';
import {
	CartesianGrid,
	Cell,
	Label,
	Line,
	LineChart,
	Pie,
	PieChart,
	ResponsiveContainer,
	Tooltip,
	XAxis,
	YAxis,
} from 'recharts';
import ShareDialog from '../../../../../components/Dialog/ShareDialog';
import '../bi.scss';
import './outletsales.scss';

export interface OutletSalesProps {
	key?: typeof String;
	endDate: Date;
	startDate: Date;
	orderID?: string;
}

const COLORS = [
	'#5367ff',
	'#34bcd2',
	'#0d71a5',
	'#4c4b66',
	'#f8f0d7',
	'#ffc853',
];

export const OutletSalesAnalysis = ({}: any) => {
	const history = useHistory();
	const latestOutlet = localStorage.getItem('latestOutlet');
	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

	const date = new Date();
	const firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
	const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);

	const { globalState } = useContext(AppContext as any);
	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	//selectedStartDate
	const [selectedStartDate, setSelectedStartDate] = React.useState<Date | null>(
		firstDay,
	);
	const handleStartDateChange = (date: Date | null) => {
		setSelectedStartDate(date);
	};

	//selectedEndDate
	const [selectedEndDate, setSelectedEndDate] = React.useState<Date | null>(
		lastDay,
	);
	const handleEndDateChange = (date: Date | null) => {
		setSelectedEndDate(date);
	};

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const {
		data: { getOutlet } = { getOutlet: [] },
		called: getOutletCalled,
		loading: getOutletLoading,
	} = useGetBizInsightOutletQuery({
		fetchPolicy: 'network-only',
	});

	const [selectedOutlet, setSelectedOutlet] = useState(null);
	useEffect(() => {
		setSelectedOutlet(
			getOutlet?.filter(outlet => outlet?.ID === latestOutlet)[0],
		);
	}, [getOutlet]);

	const { register, control, handleSubmit, errors, watch, setValue } = useForm<
		OutletSalesProps
	>({
		defaultValues: {},
		// mode: 'onSubmit',
	});

	const [
		loadOutletSalesCollectionAnalysis,
		{
			called: outletSalesCollectionAnalysisCalled,
			loading: outletSalesCollectionAnalysisLoading,
			data: { outletSalesCollectionAnalysis } = {
				outletSalesCollectionAnalysis: [],
			},
		},
	] = useGetOutletSalesCollectionAnalysisLazyQuery({
		fetchPolicy: 'network-only',
	});

	const [openShareDialog, setOpenShareDialog] = useState(false);
	const [openSendEmail, setOpenSendEmail] = useState(false);
	const [
		getShareAttachment,
		{
			data: { shareAttachment } = { shareAttachment: {} as any },
			called: shareAttachmentCalled,
			loading: shareAttachmentLoading,
		},
	] = useGetShareAttachmentMutation({
		onCompleted: () => {
			setOpenShareDialog(true);
		},
	});

	useEffect(() => {
		if (selectedOutlet && selectedStartDate && selectedEndDate) {
			loadOutletSalesCollectionAnalysis({
				variables: {
					endDate: selectedEndDate,
					startDate: selectedStartDate,
					outletID: selectedOutlet?.ID,
					frequencyType:
						watch('frequencyType') === undefined
							? FrequencyType.Daily
							: watch('frequencyType'),
				},
			});
		}
	}, [
		selectedOutlet,
		selectedStartDate,
		selectedEndDate,
		watch('frequencyType'),
	]);
	const frequencyTypeEnum = Object.values(FrequencyType);

	const [selectedFrequencyType, setSelectedFrequencyType] = useState(null);
	useEffect(() => {
		if (selectedFrequencyType === null || undefined) {
			setSelectedFrequencyType(watch('frequencyType'));
		} else {
			setSelectedFrequencyType(watch('frequencyType'));
		}
	});

	let xLabel;

	const onSubmit = blob => {
		getShareAttachment({
			variables: {
				file: blob,
				outletID: selectedOutlet?.ID,
			},
		});
	};

	switch (watch('frequencyType')) {
		case 'MONTHLY':
			xLabel = 'Months';
			break;
		case 'DAILY':
			xLabel = 'Days';
			break;
		case 'YEARLY':
			xLabel = 'Years';
			break;
		default:
			xLabel = '';
	}

	const CustomizedXAxisLabel = () => {
		let temp: string = watch('frequencyType');
		return (
			<>
				<text
					x={0}
					y={0}
					dx="50%"
					dy="5%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={16}
					fontWeight={500}
					width={180}
				>
					{`${selectedOutlet?.name} `}
				</text>
				<text
					x={0}
					y={0}
					dx="50%"
					dy="10%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={12}
					fontWeight={400}
					width={180}
				>
					{`Outlet Sales Analysis (${temp
						?.toLowerCase()
						?.replace(/\b(\w)/g, s => s.toUpperCase())}) `}
				</text>
				<text
					x={0}
					y={0}
					dx="50%"
					dy="15%"
					textAnchor="middle"
					dominantBaseline="middle"
					fontSize={12}
					fontWeight={400}
					width={180}
				>
					{`${formatDate(selectedStartDate.toISOString())} - ${formatDate(
						selectedEndDate.toISOString(),
					)}`}
				</text>
			</>
		);
	};

	return (
		<>
			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/business-insight/business-analytic`,
					})
				}
				mainBtn="back"
				smTitle="Business Insights"
				// HC
				title={loggedInUser?.companyName}
				routeSegments={[
					{ name: 'Business Insights' },
					{ name: 'Outlet Sales & Collection Analysis', current: true },
				]}
			/>

			{!getOutletLoading && (
				<DynamicSubHeader
					title={
						getOutlet === undefined || getOutlet?.length === 0
							? ``
							: `${selectedOutlet?.name}`
					} // title text
					handleClick={e => handleClick(e, null, 0)} // onClick function for dropdown
					dropDownOptions={
						// dropdown component place here
						<StyledMenu
							id="customized-menu"
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleClose}
							onClick={handleClose}
						>
							{getOutlet.map((outlet, index) => (
								<StyledMenuItem
									key={index}
									value={outlet.ID}
									defaultValue={outlet.ID}
									onClick={e => {
										setSelectedOutlet(outlet);
									}}
								>
									<ListItemText primary={outlet?.name} />
								</StyledMenuItem>
							))}
						</StyledMenu>
					}
				/>
			)}

			<ContentWrapper search footer>
				<form className={''} noValidate>
					<Grid container justify="space-around">
						<div
							className="content-wrap full"
							style={{
								width: `33%`,
							}}
						>
							<div className="">
								<span className="xxTitle fw-medium">
									<Controller
										as={
											<TextField
												select
												variant="outlined"
												size="small"
												defaultValue={FrequencyType.Daily}
											>
												{frequencyTypeEnum
													?.filter(
														type =>
															type !== FrequencyType.Weekly &&
															type !== FrequencyType.Once,
													)
													?.map((el: any, index: number) => (
														<MenuItem key={index} value={el}>
															{el
																?.toLowerCase()
																.replace(/\b(\w)/g, s => s.toUpperCase())}
														</MenuItem>
													))}
											</TextField>
										}
										className="freq-input"
										name={'frequencyType'}
										ref={register}
										style={{
											marginTop: '0px',
											marginBottom: '0px',
											background: 'white',
											zIndex: 20,
										}}
										autoComplete="off"
										control={control}
										multiline
										fullWidth
										margin="normal"
										required
									/>
								</span>
							</div>
						</div>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<div
								className="content-wrap full"
								style={{
									width: `33%`,
								}}
							>
								<div className="">
									<span className="">
										<KeyboardDatePicker
											id="date-picker-dialog"
											name="startDate"
											// label="Date picker dialog"
											style={{
												background: 'white',
												zIndex: 20,
												// width: '33%',
											}}
											className="custom-date-business-insight"
											format="dd MMM yyyy"
											value={selectedStartDate}
											onChange={handleStartDateChange}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											fullWidth
											inputVariant="outlined"
											size="small"
										/>
									</span>
								</div>
							</div>
							<div
								className="content-wrap full"
								style={{
									width: `33%`,
								}}
							>
								<div className="">
									<span className="">
										<KeyboardDatePicker
											id="date-picker-dialog"
											name="selectedEndDate"
											format="dd MMM yyyy"
											value={selectedEndDate}
											style={{
												background: 'white',
												zIndex: 20,
											}}
											onChange={handleEndDateChange}
											KeyboardButtonProps={{
												'aria-label': 'change date',
											}}
											className="custom-date-business-insight"
											fullWidth
											inputVariant="outlined"
											size="small"
										/>
									</span>
								</div>
							</div>
						</MuiPickersUtilsProvider>
					</Grid>
				</form>

				{!outletSalesCollectionAnalysisLoading && (
					<>
						<div
							id="display-content"
							style={{
								marginBottom: '50px',
								zIndex: 40,
							}}
						>
							{!outletSalesCollectionAnalysisLoading &&
							outletSalesCollectionAnalysis?.pie?.length === 0 ? (
								<EmptyList
									title={`No Outlet Sales & Collection created for ${selectedOutlet?.name}`}
									subtitle="Please add a outlet sales and create new order to continue"
								/>
							) : (
								!outletSalesCollectionAnalysisLoading && (
									<>
										{outletSalesCollectionAnalysis?.line &&
											outletSalesAnalysisFrequencyGraph(
												outletSalesCollectionAnalysis,
												CustomizedXAxisLabel,
												xLabel,
												selectedFrequencyType,
											)}
										{outletSalesAnalysisPieChart(
											outletSalesCollectionAnalysis,
											selectedOutlet,
										)}
										<div className="cover-layer">
											<CardContents>
												<></>
											</CardContents>
										</div>
									</>
								)
							)}
						</div>

						{/* {HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN } */}

						{!outletSalesCollectionAnalysisLoading &&
						outletSalesCollectionAnalysis?.pie?.length === 0 ? (
							<div></div>
						) : (
							<div
								style={{
									zIndex: -40,
									top: 0,
									left: 0,
									position: 'fixed',
									width: '100%',
								}}
							>
								<CardContents>
									<div
										style={{
											width: '100%',
											height: '800px',
											textAlign: 'center',
										}}
										id={'share-content'}
									>
										<ResponsiveContainer height={400}>
											<LineChart
												data={outletSalesCollectionAnalysis?.line
													?.slice()
													?.sort((a, b) => (a?.stat > b?.stat ? 1 : -1))}
												margin={{
													top: 70,
													right: 20,
													left: 5,
													bottom: 10,
												}}
											>
												<YAxis
													axisLine={false}
													dataKey="value"
													fontSize={10}
													label={{
														value: 'Sales',
														angle: -90,
														position: 'insideLeft',
														fontSize: 12,
													}}
												/>
												<XAxis
													dataKey="stat"
													fontSize={10}
													tickLine={false}
													axisLine={false}
													label={<CustomizedXAxisLabel />}
												>
													<Label
														value={`${xLabel}`}
														offset={0}
														position="insideBottom"
														fontSize={12}
													/>
												</XAxis>
												<CartesianGrid strokeDasharray="2 3" />
												<Tooltip />
												<Line
													type="monotone"
													dataKey="value"
													stroke="#5367FF"
												/>
											</LineChart>
										</ResponsiveContainer>
										<ResponsiveContainer height={400}>
											<PieChart
												margin={{ top: 50, right: 50, left: 50, bottom: 50 }}
											>
												<Pie
													minAngle={20}
													data={outletSalesCollectionAnalysis.pie}
													dataKey="value"
													innerRadius={40}
													label={({
														cx,
														cy,
														midAngle,
														innerRadius,
														outerRadius,
														value,
														percent,
														index,
													}) => {
														const RADIAN = Math.PI / 180;
														const radius =
															60 + innerRadius + (outerRadius - innerRadius);
														const x =
															cx + radius * Math.cos(-midAngle * RADIAN);
														const y =
															cy + radius * Math.sin(-midAngle * RADIAN);

														return (
															<>
																<text
																	x={x}
																	y={y}
																	width={300}
																	fontSize={10}
																	fill={'#737373'}
																	textAnchor={x > cx ? 'start' : 'middle'}
																>
																	<tspan
																		textAnchor="middle"
																		x={x}
																		y={y}
																		fontWeight="700"
																	>
																		{outletSalesCollectionAnalysis.pie[
																			index
																		].name
																			?.toLowerCase()
																			.replace(/\b(\w)/g, s => s.toUpperCase())}
																	</tspan>
																	<tspan
																		textAnchor="middle"
																		x={x}
																		y={y}
																		dy="30"
																	>
																		({`${(percent * 100).toFixed(0)}%`})
																	</tspan>
																	<tspan
																		textAnchor="middle"
																		x={x}
																		y={y}
																		dy="15"
																	>
																		{value}
																	</tspan>
																</text>

																{!outletSalesCollectionAnalysis.pie && (
																	<>
																		<text
																			x={0}
																			y={0}
																			dx="50%"
																			dy="5%"
																			textAnchor="middle"
																			dominantBaseline="middle"
																			fontSize={16}
																			fontWeight={500}
																			width={180}
																		>
																			{`${selectedOutlet?.name} `}
																		</text>
																		<text
																			x={0}
																			y={0}
																			dx="50%"
																			dy="10%"
																			textAnchor="middle"
																			dominantBaseline="middle"
																			fontSize={12}
																			fontWeight={300}
																			width={180}
																		>
																			{`Outlet Sales Analysis \n ${formatDate(
																				selectedStartDate.toISOString(),
																			)} - ${formatDate(
																				selectedEndDate.toISOString(),
																			)} `}
																		</text>
																	</>
																)}
															</>
														);
													}}
													// label
													outerRadius={80}
													fill="#FFFFF"
												>
													{outletSalesCollectionAnalysis.pie?.map(
														(entry, index) => (
															<Cell
																key={entry}
																fill={COLORS[index % COLORS.length]}
															/>
														),
													)}
												</Pie>
												{/* <Tooltip /> */}
											</PieChart>
										</ResponsiveContainer>
									</div>
								</CardContents>
							</div>
						)}

						{/* {HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN HIDDEN } */}

						{/* <div className="cover-layer">
							<CardContents>
								<></>
							</CardContents>
						</div> */}
					</>
				)}
			</ContentWrapper>

			{ShareDialog({
				shareTitle: `Outlet Sales & Collection Analysis - ${selectedOutlet?.name}`,
				title: `Share`,
				URL: shareAttachment?.fileURL,
				setSimple: setOpenShareDialog,
				simple: openShareDialog,
				emailShare: openSendEmail,
				setSimpleEmail: setOpenSendEmail,
			})}

			<div style={{ zIndex: 100 }}>
				<DynamicFooter
					overlap
					isHidden={openShareDialog}
					options={[
						{
							name: 'Share',
							onClick: async () => {
								let temp = await document.getElementById('share-content');
								htmlToImage
									.toBlob(temp, { style: { background: '#fff' } })
									.then(value => {
										onSubmit(value);
									});
							},
							color: 'primary',
							disabled:
								outletSalesCollectionAnalysis?.pie == undefined ||
								outletSalesCollectionAnalysis?.pie.length == 0 ||
								watch('frequencyType') == undefined,
							props: {
								type: 'submit',
							},
							startIcon: <ShareIcon style={{ fontSize: '18px' }} />,
						},
					]}
				/>
			</div>
		</>
	);
};

////////////////// SORT BY DAILY/MONTH/YEAR /////////////////
const months = [
	'January  ',
	'February ',
	'March    ',
	'April    ',
	'May      ',
	'June     ',
	'July     ',
	'August   ',
	'September',
	'October  ',
	'November ',
	'December ',
];
const sortBy = (a, b) => {
	if (FrequencyType.Daily) {
		return (
			new Date(null, null, ...a.stat.split('-').reverse()).getTime() -
			new Date(null, null, ...b.stat.split('-').reverse()).getTime()
		);
	} else if (FrequencyType.Monthly) {
		return months.indexOf(a.stat) - months.indexOf(b.stat);
	} else if (FrequencyType.Yearly) {
		return a?.stat > b?.stat ? 1 : -1;
	}
};

////////////////// SORT BY DAILY/MONTH/YEAR /////////////////
const heightConst = 400;
const outletSalesAnalysisFrequencyGraph = (
	outletSalesCollectionAnalysis,
	CustomizedXAxisLabel,
	xLabel,
	selectedFrequencyType,
	hidden?: boolean,
) => {
	return (
		<div
			style={{
				marginTop: '0px',
				zIndex: 40,
				position: 'relative',
			}}
		>
			<CardContents
				section={{
					header: {
						title: 'Sales',
					},
				}}
			>
				<div
					style={{
						width: '100%',
						height: heightConst,
						textAlign: 'center',
					}}
				>
					<ResponsiveContainer>
						<LineChart
							data={outletSalesCollectionAnalysis?.line
								?.slice()
								?.sort((a, b) => {
									if (selectedFrequencyType === undefined) {
										return (
											new Date(
												null,
												null,
												...a.stat.split('-').reverse(),
											).getTime() -
											new Date(
												null,
												null,
												...b.stat.split('-').reverse(),
											).getTime()
										);
									} else if (selectedFrequencyType === FrequencyType.Daily) {
										return (
											new Date(
												null,
												null,
												...a.stat.split('-').reverse(),
											).getTime() -
											new Date(
												null,
												null,
												...b.stat.split('-').reverse(),
											).getTime()
										);
									} else if (selectedFrequencyType === FrequencyType.Monthly) {
										return months.indexOf(a.stat) - months.indexOf(b.stat);
									} else if (selectedFrequencyType === FrequencyType.Yearly) {
										return a?.stat > b?.stat ? 1 : -1;
									}
								})}
							margin={{
								top: 10,
								right: 10,
								left: 0,
								bottom: 20,
							}}
						>
							<YAxis
								axisLine={false}
								dataKey="value"
								fontSize={10}
								label={{
									value: 'Sales',
									angle: -90,
									position: 'insideLeft',
									fontSize: 12,
								}}
							/>
							<XAxis
								dataKey="stat"
								fontSize={10}
								tickLine={false}
								axisLine={false}
							>
								<Label
									value={`${xLabel}`}
									offset={0}
									position="insideBottom"
									fontSize={12}
								/>
							</XAxis>
							<CartesianGrid strokeDasharray="2 3" />
							<Tooltip />
							<Line type="monotone" dataKey="value" stroke="#5367FF" />
						</LineChart>
					</ResponsiveContainer>
				</div>
			</CardContents>
		</div>
	);
};

const outletSalesAnalysisPieChart = (
	outletSalesCollectionAnalysis,
	selectedOutlet,
	selectedStartDate?: any,
	selectedEndDate?: any,
	formatDate?: any,
	hidden?: Boolean,
) => {
	return (
		<div
			style={{
				marginTop: '0px',
				zIndex: 40,
				position: 'relative',
			}}
		>
			<CardContents
				section={{
					header: {
						title: 'Collection',
					},
				}}
			>
				<div
					style={{
						width: '100%',
						height: heightConst,
					}}
				>
					<MultiPieChart data={outletSalesCollectionAnalysis.pie} />
				</div>
			</CardContents>
		</div>
	);
};
