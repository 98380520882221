import { yupResolver } from '@hookform/resolvers';
import FloatButton from '@ifca-root/react-component/src/components/Button/FloatButton';
import EmptyList from '@ifca-root/react-component/src/components/CardList/EmptyList';
import { CommonDialog } from '@ifca-root/react-component/src/components/Dialog/CommonDialog';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	Box,
	IconButton,
	List,
	ListItem,
	ListItemSecondaryAction,
	ListItemText,
	Menu,
	MenuItem,
	TextField,
} from '@material-ui/core';
import { MoreVert } from '@material-ui/icons';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetFamilyDocument,
	useCreateFamilyMutation,
	useDeleteFamilyMutation,
	useGetFamilyQuery,
	useGetMajorQuery,
	useGetOutletQuery,
	useUpdateFamilyMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';
import { GeneralDeleteDialog } from '../../../../components/Dialog/GeneralDeleteDialog';

interface FamilyProps {
	name: string;
	description: string;
}

export const FamilyList = () => {
	const history = useHistory();

	const { pathname } = useLocation();
	const { majorID }: any = useParams();

	const { outletID }: any = useParams();
	const { anchorEl, menu, handleClick, handleClose }: any = useMenuOption();

	const [openDialog, setOpenDialog] = useState(false);
	const [openDeleteDialog, setDeleteDialog] = useState(false);
	const [isEdit, setIsEdit] = useState(false);
	const [double, setDouble] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const {
		data: { getMajor } = { getMajor: [] },
		loading: majorLoading,
	} = useGetMajorQuery({
		fetchPolicy: 'network-only',
		variables: { ID: majorID },
	});
	const majorArray: any[] = getMajor;

	const {
		data,
		called: familyCalled,
		loading: familyLoading,
	} = useGetFamilyQuery({
		fetchPolicy: 'network-only',
		variables: { majorID: majorID, orderByAsc: 'name' },
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	/// <<< SUBMIT MUTATION >>> ///

	const [
		submitFamily,
		{
			data: createdFamilyData,
			loading: createFamilyLoading,
			called: createFamilyCalled,
			error: createFamilyError,
		},
	] = useCreateFamilyMutation({
		//fetchPolicy: 'no-cache',
		onError: error => {
			console.log('Suberror', error);
		},

		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setOpenDialog(false);
		},
	});

	/// <<< SUBMIT MUTATION >>> ///

	/// <<< UPDATE MUTATION >>> ///

	const [
		updateFamily,
		{ loading: subUpdateLoading, error: subUpdateError },
	] = useUpdateFamilyMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			// history.push(`/subcontract/${consubconId}/vo`);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/major/${majorID}/family`,
					state: { success: true, msgMode: 'update' },
				});
			}, 500);
			setOpenDialog(false);
		},
	});

	/// <<< UPDATE MUTATION >>> ///

	/// <<< DELETE MUTATION >>> ///

	const [
		deleteFamily,
		{ loading: deleteFamilyLoading },
	] = useDeleteFamilyMutation({
		onError: error => {
			// console.log(error, 'error');
			if (error.message.includes('FK_')) {
				setOpenSnackBar(true);
				setSnackBarMsg(SystemMsgs.deleteRecordInUse());
			}
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.deleteRecord());
		},
	});

	/// <<< DELETE MUTATION >>> ///

	const existingFamilyNames = data?.getFamily?.map(f => f?.name);

	const yupSchema = yup.object().shape({
		name: yup
			.string()
			.required(SystemMsgs.name())
			.trim() //trim
			.test('name', 'Family name already exist', value => {
				if (
					(watch('name') !== menu?.obj?.name && isEdit === true) ||
					isEdit === false
				) {
					return !existingFamilyNames?.includes(value);
				}
				return true;
			}),
	});

	const {
		register,
		control,
		handleSubmit,
		watch,
		errors,
		clearErrors,
		formState,
		setValue,
	} = useForm<FamilyProps>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const onSubmit = (data: any) => {
		isEdit === false
			? submitFamily({
					variables: {
						input: {
							majorID: majorID,
							outletID: outletID,
							name: data?.name,
							description: '',
						},
					},
					refetchQueries: [
						{
							query: GetFamilyDocument,
							variables: {
								outletID: outletID,
								majorID: majorID,
								orderByAsc: 'name',
							},
						},
					],
			  })
			: updateFamily({
					variables: {
						input: {
							ID: menu?.ID,
							majorID: majorID,
							outletID: outletID,
							name: data?.name,
							description: '',
						},
					},
					refetchQueries: [
						{
							query: GetFamilyDocument,
							variables: {
								outletID: outletID,
								majorID: majorID,
								orderByAsc: 'name',
							},
						},
					],
			  });
		clearErrors();
	};

	// useEffect(() => {
	// 	if (
	// 		(!familyCalled && data?.getFamily === undefined) ||
	// 		data?.getFamily?.length === 0
	// 	) {
	// 		setOpenDialog(true);
	// 		setIsEdit(false);
	// 	} else {
	// 		setOpenDialog(false);
	// 	}
	// }, data?.getFamily);

	const handleCloseDialog = () => {
		setOpenDialog(false);
		clearErrors();
	};

	return (
		<>
			{familyLoading && <Loading />}
			{outletLoading && <Loading />}
			{majorLoading && <Loading />}
			{createFamilyLoading && <Loading />}
			{subUpdateLoading && <Loading />}
			{deleteFamilyLoading && <Loading />}

			<MainHeader
				onClick={() =>
					history.push({
						pathname: `/menu/outlet-app/outlet-setting/${outletID}/major`,
					})
				}
				mainBtn="back"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Major & Family' },
					{ name: majorArray[0]?.name, current: true },
				]}
			/>
			<ContentWrapper float>
				<List className="core-list">
					{!familyLoading &&
					!outletLoading &&
					!majorLoading &&
					(data?.getFamily === undefined || data?.getFamily?.length === 0) ? (
						<EmptyList
							title="No Record found"
							subtitle="Please add family to continue"
						/>
					) : (
						data?.getFamily
							// ?.sort((a, b) => {
							// 	return a.name.localeCompare(b.name);
							// })
							.map((el, index) => (
								<ListItem>
									<ListItemText
										primary={
											<>
												<span className="xsTitle flex-space">{el?.name}</span>
												<span className="xxTitle highlight-text text-separator"></span>
											</>
										}
										secondary={
											<>
												<span className="desc">
													<span className="desc">
														Last Updated on: {formatDate(el?.createdTs)}
													</span>
												</span>
											</>
										}
									/>

									<ListItemSecondaryAction>
										<IconButton
											edge="end"
											aria-label="more"
											aria-controls="menu-list"
											aria-haspopup="true"
											onClick={e => handleClick(e, el.ID, index, el)}
										>
											<MoreVert />
										</IconButton>
									</ListItemSecondaryAction>
								</ListItem>
							))
					)}
				</List>
				<Menu
					id="menu-list"
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					onClick={handleClose}
				>
					<MenuItem
						onClick={() => {
							setOpenDialog(true);
							setIsEdit(true);
							setDouble(false);
						}}
					>
						<span className="">Edit</span>
					</MenuItem>
					<MenuItem
						onClick={() => {
							setDeleteDialog(true);
						}}
					>
						<span className="">Delete</span>
					</MenuItem>
				</Menu>
			</ContentWrapper>

			<form onSubmit={handleSubmit(onSubmit)} id="submit-family-form">
				<Box mt={1}>
					<CommonDialog
						fullWidth={true}
						open={openDialog}
						onClose={() => {
							handleCloseDialog();
						}}
						sections={{
							header: {
								dynamic: (
									<div className="">
										<div className="dialog-dynamic-content">
											<div className="session">
												<div className="flex session">
													<div className="title flex-space">Family</div>
												</div>
											</div>
										</div>
										<div className="infoline-content ">
											<div className="xsTitle infoline flex-space">
												{majorArray[0]?.name}{' '}
											</div>
											<div className="xsTitle infoline rightText">
												{isEdit ? 'Edit' : 'Add'}
											</div>
										</div>
									</div>
								),
							},
							body: () => (
								<>
									<div className="content-container">
										{isEdit === false ? (
											<Controller
												as={TextField}
												name="name"
												label="Family Name"
												autoComplete="off"
												multiline={true}
												required
												fullWidth
												ref={register}
												control={control}
												helperText={errors?.name?.message}
												error={errors?.name ? true : false}
												onInput={() => {
													setDouble(false);
												}}
												// helperText={
												// 	existingFamilyNames?.includes(watch('name'))
												// 		? 'Family already exist'
												// 		: null
												// }
												// error={
												// 	existingFamilyNames?.includes(watch('name'))
												// 		? true
												// 		: false
												// }
											/>
										) : (
											<Controller
												as={TextField}
												defaultValue={menu?.obj?.name}
												name="name"
												label="Family Name"
												autoComplete="off"
												multiline={true}
												required
												fullWidth
												ref={register}
												control={control}
												helperText={errors?.name?.message}
												error={errors?.name ? true : false}
												onInput={() => {
													setDouble(false);
												}}
												// helperText={
												// 	existingFamilyNames?.includes(watch('name'))
												// 		? 'Family already exist'
												// 		: null
												// }
												// error={
												// 	existingFamilyNames?.includes(watch('name'))
												// 		? true
												// 		: false
												// }
											/>
										)}
									</div>
								</>
							),
							footer: {
								actions: [
									{
										displayText: 'Cancel',
										props: {
											onClick: () => {
												handleCloseDialog();
											},
											variant: 'contained',
											color: 'primary',
										},
									},
									{
										displayText: 'Save',
										props: {
											// onClick: () => handleSubmit(onSubmit)(),
											variant: 'contained',
											// color: existingFamilyNames?.includes(watch('name'))
											// 	? 'inherit'
											// 	: 'primary',
											// form: 'submit-family-form',
											// type: 'submit',
											// onClick: () => {
											// 	if (!existingFamilyNames?.includes(watch('name'))) {
											// 		handleSubmit(onSubmit)();
											// 	}
											// },
											// onClick: () => {
											// 	!createFamilyCalled && handleSubmit(onSubmit)();
											// },
											// disabled: createFamilyCalled,

											color: 'primary',
											disabled: double,

											onClick: () => {
												if (errors?.name ? false : true) {
													handleSubmit(onSubmit)();
													setDouble(true);
												}
											},
										},
									},
								],
							},
						}}
					/>
				</Box>
			</form>

			<GeneralDeleteDialog
				ID={menu?.ID}
				refetchID={{ outletID: outletID, majorID: majorID, orderByAsc: 'name' }}
				openDialog={openDeleteDialog}
				setOpenDialog={setDeleteDialog}
				dialogName={menu?.obj?.name}
				deleteMutation={deleteFamily}
				refetchDocument={GetFamilyDocument}
			/>

			<FloatButton
				onClick={() => {
					setOpenDialog(true);
					setIsEdit(false);
					setDouble(false);
				}}
			/>
		</>
	);
};
