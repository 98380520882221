import React from 'react';
import { UtilityList } from './UtilityList';

const utilityRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/utility`,
		},
		component: <UtilityList />,
	},
];

export default utilityRoutes;
