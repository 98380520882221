import React from 'react';
import { PromotionCodeForm } from './PromotionCodeForm';
import { PromotionCodeList } from './PromotionCodeList';

const promoCodeRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/promo-code`,
		},
		component: <PromotionCodeList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/promo-code/add`,
		},
		component: <PromotionCodeForm mode="add" />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/outlet-setting/:outletID/promo-code/:promotionCodeID/edit`,
		},
		component: <PromotionCodeForm mode="edit" />,
	},
];

export default promoCodeRoutes;
