import { yupResolver } from '@hookform/resolvers';
import { AuthLayout } from '@ifca-root/react-component/src/components/AuthLayout/AuthLayout';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Button, MenuItem, TextField } from '@material-ui/core';
import {
	useGetKitchenPrinterQuery,
	useGetOutletQuery,
} from 'generated/graphql';
import { CommonYupValidation } from 'helpers/yup';
import React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router';
import Background from '../../../src/assets/images/HR-Background.jpg';
import Logo from '../../../src/assets/images/MenuX-Logo.svg';
import * as yup from 'yup';

interface IKitchenLoginForm {
	outletID: string;
	kitchenPrinterID: string;
}

export const KitchenLogin = () => {
	let history = useHistory();
	let location = useLocation();

	const appCode: any = localStorage.getItem('appCode');
	const loggedInUser = JSON.parse(localStorage.getItem('loggedInUser'));

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		variables: { accountID: loggedInUser?.accountID },
		fetchPolicy: 'network-only',
		onCompleted: ({ getOutlet }) => {},
	});

	const yupSchema = yup.object().shape({
		outletID: CommonYupValidation.requireField('Outlet is required'),
		kitchenPrinterID: CommonYupValidation.requireField(
			'Kitchen printer is required',
		),
	});

	const { handleSubmit, errors, control, getValues, register, watch } = useForm<
		IKitchenLoginForm
	>({
		defaultValues: {},
		mode: 'onSubmit',
		resolver: yupResolver(yupSchema),
	});

	const {
		called: kitchenPrinterCalled,
		loading: kitchenPrinterLoading,
		error: kitchenPrinterError,
		data: { getKitchenPrinter } = { getKitchenPrinter: [] },
	} = useGetKitchenPrinterQuery({
		variables: { outletID: watch('outletID') },
		fetchPolicy: 'network-only',
	});

	const onSubmit = () => {
		history.push(
			`/kitchen/home/${watch('outletID')}/printer/${watch('kitchenPrinterID')}`,
		);
	};

	return (
		<>
			{outletLoading && <Loading />}
			{/* {allMealPeriodLoading && <Loading />} */}
			<AuthLayout logo={Logo} image={Background} className="margin-override">
				<div className="form-box">
					<Controller
						as={
							<TextField select>
								{getOutlet?.map((el: any, index: number) => (
									<MenuItem key={index} value={el?.ID}>
										{el?.name}
									</MenuItem>
								))}
							</TextField>
						}
						label="Outlet"
						name="outletID"
						autoComplete="off"
						control={control}
						multiline={true}
						fullWidth
						margin="normal"
						required={true}
						ref={register}
						helperText={errors?.outletID?.message}
						error={errors?.outletID ? true : false}
					/>
					<Controller
						as={
							<TextField select>
								{getKitchenPrinter?.map((el: any, index: number) => (
									<MenuItem key={index} value={el?.ID}>
										{el?.name}
									</MenuItem>
								))}
							</TextField>
						}
						label="Kitchen"
						name="kitchenPrinterID"
						autoComplete="off"
						control={control}
						multiline={true}
						fullWidth
						margin="normal"
						required={true}
						ref={register}
						helperText={errors?.kitchenPrinterID?.message}
						error={errors?.kitchenPrinterID ? true : false}
					/>

					<Button
						type="submit"
						variant="contained"
						color="primary"
						className="login-btn"
						onClick={() => {
							handleSubmit(onSubmit)();
						}}
						data-qa="loginButton"
					>
						Confirm
					</Button>
				</div>
			</AuthLayout>
		</>
	);
};
