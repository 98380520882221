import React from 'react';
import { BillReceipt } from '../Home/TableSubmenu/BillSettlement/BillReceipt/BillReceipt';
import { DigitalBillList } from './DigitalBillList';

const digitalBillRoutes = [
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/digital-bill`,
		},
		component: <DigitalBillList />,
	},
	{
		props: {
			exact: true,
			path: `/menu/outlet-app/:outletID/digital-bill/:billID`,
		},
		component: <BillReceipt mode="digital-document" />,
	},
];

export default digitalBillRoutes;
