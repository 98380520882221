import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import { Grid, List, Switch, useMediaQuery } from '@material-ui/core';
import PersonIcon from '@material-ui/icons/Person';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	GetPatronDocument,
	useGetOutletQuery,
	useGetPatronQuery,
	useSubmitOutletPatronSetupMutation,
} from 'generated/graphql';
import { SystemMsgs } from 'helpers/SystemMsg';
import React, { useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useHistory, useParams } from 'react-router-dom';

interface PatronSetupProps {
	patron: any;
}

export const PatronSetupForm = () => {
	const history = useHistory();

	const { outletID } = useParams<any>();

	const { globalState, dispatch } = useContext(AppContext as any);
	const [openExitDialog, setOpenExitDialog] = useState(false);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	//QUERY
	//QUERY
	//QUERY

	const {
		called: getPatronCalled,
		loading: getPatronLoading,
		data: { getPatron } = { getPatron: [] },
	} = useGetPatronQuery({
		fetchPolicy: 'network-only',
		variables: {
			outletID: outletID,
			// orderByAsc: 'patronClass',
		},
	});

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const [
		submitPatronSetup,
		{ loading: submitPatronSetupLoading },
	] = useSubmitOutletPatronSetupMutation({
		onError: error => {
			console.log('Suberror', error);
		},
		onCompleted: data => {
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/outlet-policy`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
		},
	});

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});
	const outletArray: any[] = getOutlet;

	//USEFORM
	//USEFORM
	//USEFORM

	const { register, control, errors, handleSubmit, watch, setValue } = useForm<
		PatronSetupProps
	>({
		defaultValues: {},
		mode: 'onSubmit',
	});

	//SWITCH
	//SWITCH
	//SWITCH

	useEffect(() => {
		getPatron?.map((patron, index) => {
			register(`patron[${patron?.ID}]`);
			setValue(`patron[${patron?.ID}]`, patron?.isActive ? true : false);
		});
	}, [getPatron]);

	const handleSwitch = (e: any, index: number, patron) => {
		if (e.target.checked) {
			setValue(`patron[${patron?.ID}]`, true);
		} else {
			setValue(`patron[${patron?.ID}]`, false);
		}
	};

	const onSubmit = async (data: any) => {
		let patronIDs = Object.keys(data?.patron);
		submitPatronSetup({
			variables: {
				patronIDs: patronIDs?.filter(id => watch(`patron[${id}]`)),
				outletID: outletID,
			},
			refetchQueries: [{ query: GetPatronDocument, variables: { outletID } }],
		});
	};

	const patronTypeIcons = description => {
		switch (description) {
			case 'Standard':
				return <PersonIcon style={{ color: '#ff9800' }} />;
			case 'Member':
				return <PersonIcon style={{ color: '#ff9800' }} />;
			case 'Hotel':
				return <PersonIcon style={{ color: '#ff9800' }} />;
			case 'Guest':
				return <SupervisorAccountIcon style={{ color: '#ff9800' }} />;
			case 'Staff':
				return <PersonIcon style={{ color: '#ff9800' }} />;
			default:
				return <PersonIcon style={{ color: '#ff9800' }} />;
		}
	};

	return (
		<>
			{getPatronLoading && <Loading />}
			{outletLoading && <Loading />}
			{submitPatronSetupLoading && <Loading />}

			<MainHeader
				onClick={() => setOpenExitDialog(true)}
				mainBtn="close"
				smTitle={`Outlet App`}
				title={outletArray[0]?.name}
				routeSegments={[
					{ name: 'Outlet Settings' },
					{ name: 'Outlet Policy', current: true },
				]}
			/>
			{getPatronLoading && <Loading />}
			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<ContentWrapper>
					<CardContents>
						<List>
							{!getPatronLoading &&
								watch('patron')?.length !== 0 &&
								watch('patron') !== undefined && (
									<>
										<div className="smTitle" style={{ padding: '5px 10px' }}>
											Customer Type
										</div>
										<Grid container>
											<Grid
												item
												xs={1}
												style={{
													alignSelf: 'center',
													textAlign: 'center',
													paddingTop: '4px',
												}}
											>
												<PersonIcon style={{ color: '#ff9800' }} />
											</Grid>
											<Grid
												item
												xs={8}
												className="mdDesc"
												style={{ alignSelf: 'center', paddingLeft: '8px' }}
											>
												{'Visitor'}
											</Grid>
											<Grid item xs={3} style={{ textAlign: 'right' }}>
												<Switch
													checked={true}
													onChange={() => {}}
													name="visitor"
													color="secondary"
												/>
											</Grid>

											{getPatron
												// ?.sort((a, b) => {
												// 	return a.patronType?.description.localeCompare(
												// 		b.patronType?.description,
												// 	);

												?.slice()
												?.sort((a, b) =>
													a?.patronClass > b?.patronClass ? 1 : -1,
												)

												?.filter(patron => patron?.patronClass !== 'Visitor')
												?.map((el, index) => {
													return (
														<>
															<Grid
																item
																xs={1}
																style={{
																	alignSelf: 'center',
																	textAlign: 'center',
																	paddingTop: '4px',
																}}
															>
																{patronTypeIcons(el?.patronType?.description)}
															</Grid>
															<Grid
																item
																xs={8}
																className="mdDesc"
																style={{
																	alignSelf: 'center',
																	paddingLeft: '8px',
																}}
															>
																{el?.patronType?.description}
															</Grid>
															<Grid item xs={3} style={{ textAlign: 'right' }}>
																<Switch
																	checked={watch(`patron[${el?.ID}]`)}
																	onChange={event => {
																		handleSwitch(event, index, el);
																	}}
																	name="isActive"
																	color="primary"
																	disabled={
																		el?.patronClass === 'Hotel Guest' &&
																		!getOutlet[0]?.outletChannel?.hotelID
																	}
																/>
															</Grid>
														</>
													);
												})}
										</Grid>
									</>
								)}
						</List>
					</CardContents>
				</ContentWrapper>

				<DynamicFooter
					style={{
						width:
							isDesktop && globalState.drawerOpen
								? 'calc(100% - 240px)'
								: '100%',
					}}
					options={[
						{
							name: 'Save',
							color: submitPatronSetupLoading ? 'inherit' : 'primary',
							disbaled: submitPatronSetupLoading ? true : false,
							props: {
								type: 'submit',
							},
						},
					]}
				/>

				<ExitConfirmationDialog
					openExitDialog={openExitDialog}
					setOpenExitDialog={setOpenExitDialog}
					backPath={`/menu/outlet-app/outlet-setting/${outletID}/outlet-policy`}
				/>
			</form>
		</>
	);
};
