import DateFnsUtils from '@date-io/date-fns';
import { yupResolver } from '@hookform/resolvers';
import theme from '@ifca-root/react-component/src/assets/theme';
import CardContents from '@ifca-root/react-component/src/components/CardList/CardContents';
import { DynamicFooter } from 'components/Footer/DynamicFooter';
import MainHeader from '@ifca-root/react-component/src/components/Header/MainHeader';
import { ContentWrapper } from '@ifca-root/react-component/src/components/Layout/ContentWrapper';
import Loading from '@ifca-root/react-component/src/components/Loading/Loading';
import {
	FormControl,
	FormControlLabel,
	FormHelperText,
	Grid,
	Radio,
	RadioGroup,
	TextField,
	useMediaQuery,
} from '@material-ui/core';
import {
	KeyboardDatePicker,
	MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { ExitConfirmationDialog } from 'components/Dialog/ExitConfirmationDialog';
import AppContext from 'containers/App/Store/AppContext';
import SnackBarContext from 'containers/App/Store/SnackBarContext';
import {
	CalcType,
	GetPromoCodeDocument,
	useCreatePromoCodeMutation,
	useGetOutletQuery,
	useGetPromoCodeQuery,
	useUpdatePromoCodeMutation,
} from 'generated/graphql';
import { formatDate } from 'helpers/hooks/formatDate';
import { useMenuOption } from 'helpers/hooks/useMenuOption';
import { SystemMsgs } from 'helpers/SystemMsg';
import { CommonYupValidation } from 'helpers/yup';
import React, { useContext, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import NumberFormat from 'react-number-format';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import * as yup from 'yup';

interface PromoThing {
	code: string;
	description: string;
	validTill: Date;
	validFrom: Date;
	amount: number;
	// percentage: number;
	calcType: CalcType;
}

export const PromotionCodeForm = (props: any) => {
	const history = useHistory();
	const { pathname } = useLocation();
	const { mode } = props;
	const { outletID }: any = useParams();
	const location = useLocation();
	const [openExitDialog, setOpenExitDialog] = useState(false);

	const { setOpenSnackBar, setSnackBarMsg } = useContext(
		SnackBarContext as any,
	);

	const { globalState, dispatch } = useContext(AppContext as any);

	const isDesktop = useMediaQuery(theme.breakpoints.up('sm'), {
		defaultMatches: true,
	});

	const { anchorEl, menu, handleClick, handleClose } = useMenuOption();

	const [openHeader, setHeader] = useState(false);

	let formMode = mode === 'add' ? 'New' : 'Edit';

	const {
		data,
		called: promoCalled,
		loading: promoLoading,
	} = useGetPromoCodeQuery({ variables: { outletID } });
	const [
		submitPromoCode,
		{ data: createdPromoCodeData },
	] = useCreatePromoCodeMutation({
		fetchPolicy: 'no-cache',
	});

	const [OutletPromo, setOutletPromo] = useState({
		code: '',
		description: '',
		validTill: '',
		validFrom: '',
		amount: 0,
	});

	const withValueLimit = inputObj => {
		const maxAllowed = watch('calcType') === CalcType.Percentage ? 100 : 10000;
		const { value } = inputObj;
		if (value < maxAllowed) return inputObj;
	};

	const editData: any = location?.state as any;

	const yupSchema = yup.object().shape({
		code: CommonYupValidation?.requireField(SystemMsgs.title()),
		description: CommonYupValidation?.requireField(SystemMsgs.description()),
		amount: yup
			.string()
			.when('calcType', {
				is: 'PERCENTAGE',
				then: yup.string().required('Percentage is required'),
			})
			.when('calcType', {
				is: 'AMOUNT',
				then: yup.string().required('Amount is required'),
			})
			.nullable(),
	});
	const { handleSubmit, register, setValue, watch, errors, control } = useForm<
		PromoThing
	>({
		defaultValues: {
			code: editData ? editData?.code : '',
			description: editData ? editData?.description : '',
			validTill: editData ? editData?.validTill : new Date(),
			validFrom: editData ? editData?.validFrom : new Date(),
			amount: editData ? editData?.amount : '',
			calcType: editData ? editData?.calcType : '',
		},
		resolver: yupResolver(yupSchema),
		mode: 'all',
	});

	const todayDate = new Date();

	const handleChange = (e: any) => {
		const name = e.target.name;
		const value = e.target.value;
		setOutletPromo({ ...OutletPromo, [name]: value });
	};

	const [amount, setAmount] = useState(0);
	const handleAmountChange = (e: any) => {
		setAmount(e.target.value);
	};

	const [
		createPromoCode,
		{ loading: createPromoCodeLoading, error, called: createPromoCodeCalled },
	] = useCreatePromoCodeMutation({
		onError: error => {
			console.log('ERROR', error);
		},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.createNewRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/promo-code`,
					state: { success: true, msgMode: 'create' },
				});
			}, 500);
		},
	});

	const [
		updatePromoCode,
		{ called: updatePromoCodeCalled, loading: updatePromoCodeLoading },
	] = useUpdatePromoCodeMutation({
		onError: error => {},
		onCompleted: data => {
			setOpenSnackBar(true);
			setSnackBarMsg(SystemMsgs.updateRecord());
			setTimeout(() => {
				history.push({
					pathname: `/menu/outlet-app/outlet-setting/${outletID}/promo-code`,

					state: { success: true, msgMode: 'update' },
				});
			}, 500);
		},
	});

	const createUpdateCalled = editData
		? updatePromoCodeCalled
		: createPromoCodeCalled;

	const onSubmit = (data: any) => {
		if (mode == 'add') {
			//add the data with GraphQL mutation
			createPromoCode({
				variables: {
					input: {
						outletID: outletID,
						code: data.code,
						description: data.description,
						validTill: data.validTill,
						validFrom: data.validFrom,
						amount: parseFloat(data.amount),
						calcType: data?.calcType,
					},
				},
				refetchQueries: [
					{ query: GetPromoCodeDocument, variables: { outletID } },
				],
			});
		} else if (mode === 'edit') {
			//update the data

			updatePromoCode({
				variables: {
					input: {
						ID: editData?.ID,
						outletID: outletID,
						code: data.code,
						description: data.description,
						validTill: data.validTill,
						validFrom: data.validFrom,
						amount: parseFloat(data.amount),
						calcType: data?.calcType,
					},
				},
				refetchQueries: [
					{ query: GetPromoCodeDocument, variables: { outletID } },
				],
			});
		}
	};

	const [amtID, setAmtID] = useState(mode === 'edit' ? editData?.amtID : null);

	const [perID, setPerID] = useState(mode === 'edit' ? editData?.perID : null);

	const watchValue = watch('amount') != 0 ? watch('amount') : 0;

	const {
		called: outletCalled,
		loading: outletLoading,
		error: outletError,
		data: { getOutlet } = { getOutlet: [] },
	} = useGetOutletQuery({
		fetchPolicy: 'network-only',
		variables: { ID: outletID },
	});

	const outletArray: any[] = getOutlet;

	const handleDisableFrom = () => {
		return editData?.validFrom < todayDate.toISOString();
	};

	const handleDisableTill = () => {
		return editData?.validTill < todayDate.toISOString();
	};

	return (
		<>
			{outletLoading && <Loading />}
			{createPromoCodeLoading && <Loading />}
			{updatePromoCodeLoading && <Loading />}

			{
				<MainHeader
					onClick={() => setOpenExitDialog(true)}
					mainBtn="close"
					smTitle={`Outlet App`}
					title={outletArray[0]?.name}
					routeSegments={[
						{ name: 'Outlet Settings' },
						{ name: 'Promo Code', current: true },
					]}
					rightRouteSegments={[
						{ name: mode === 'add' ? 'New' : mode === 'edit' ? 'Edit' : null },
					]}
				/>
			}

			<form onSubmit={handleSubmit(onSubmit)} id="submit-form">
				<ContentWrapper footer>
					<CardContents devFullWidth={true}>
						<Controller
							as={TextField}
							required
							name="code"
							label="Promo Code"
							autoComplete="off"
							multiline={true}
							margin="normal"
							value={OutletPromo.code}
							onChange={handleChange}
							control={control}
							ref={register}
							disabled={handleDisableTill()}
							helperText={errors?.code?.message}
							error={!!errors?.code ? true : false}
						/>

						<Controller
							as={TextField}
							required
							name="description"
							label="Description"
							autoComplete="off"
							multiline={true}
							margin="normal"
							value={OutletPromo.description}
							onChange={handleChange}
							control={control}
							ref={register}
							disabled={handleDisableTill()}
							helperText={errors?.description?.message}
							error={!!errors?.description ? true : false}
						/>
						<MuiPickersUtilsProvider utils={DateFnsUtils}>
							<Controller
								as={KeyboardDatePicker}
								required
								name="validFrom"
								margin="normal"
								id="Tender_date"
								label="Start Date"
								format="dd MMM yyyy"
								minDate={new Date()}
								minDateMessage={null}
								//disablePast
								disabled={handleDisableFrom()}
								value={new Date()}
								onChange={(date: Date | null) => {}}
								control={control}
								ref={register}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								className="left"
							/>

							<Controller
								as={KeyboardDatePicker}
								required
								name="validTill"
								margin="normal"
								id="Tender_close_date"
								label="End Date"
								minDate={new Date()}
								minDateMessage={null}
								//disablePast
								disabled={handleDisableTill()}
								format="dd MMM yyyy"
								value={new Date()}
								ref={register}
								onChange={(date: Date | null) => {}}
								control={control}
								KeyboardButtonProps={{
									'aria-label': 'change date',
								}}
								className="right"
							/>
						</MuiPickersUtilsProvider>

						<FormControl component="fieldset" margin="dense" fullWidth required>
							<Controller
								as={
									<RadioGroup aria-label="VOType">
										<Grid container>
											<Grid item xs={6} className="right">
												<FormControlLabel
													value={CalcType.Percentage}
													name="calcType"
													disabled={
														mode === 'approve-reject' || handleDisableTill()
													}
													control={<Radio color="primary" />}
													label="Percentage"
													onChange={() => setValue('amount', 0)}
												/>
											</Grid>
											<Grid item xs={6} className="left">
												<FormControlLabel
													value={CalcType.Amount}
													name="calcType"
													disabled={
														mode === 'approve-reject' || handleDisableTill()
													}
													control={<Radio color="primary" />}
													label="Amount"
													onChange={() => setValue('amount', 0)}
												/>
											</Grid>
										</Grid>
									</RadioGroup>
								}
								name="calcType"
								control={control}
								ref={register()}
								helperText={errors?.calcType?.message}
								error={errors?.calcType ? true : false}
							/>
							<FormHelperText error={errors?.calcType ? true : false}>
								{errors?.calcType?.message}
							</FormHelperText>
						</FormControl>

						{watch('calcType') && (
							<Controller
								as={<NumberFormat allowNegative={false} />}
								thousandSeparator
								customInput={TextField}
								id="standard-basic"
								name="amount"
								label={
									watch('calcType') === CalcType.Amount
										? 'Amount'
										: 'Percentage (%)'
								}
								helperText={errors?.amount?.message}
								error={errors?.amount ? true : false}
								autoComplete="off"
								control={control}
								decimalScale={watch('calcType') === CalcType.Amount ? 2 : ''}
								fixedDecimalScale
								margin="dense"
								required
								ref={register}
								disabled={
									formMode === 'approve-reject' || handleDisableTill()
										? true
										: false
								}
							/>
						)}
					</CardContents>
				</ContentWrapper>
			</form>
			<DynamicFooter
				style={{
					width:
						isDesktop && globalState.drawerOpen ? 'calc(100% - 240px)' : '100%',
				}}
				options={[
					{
						name: 'Save',
						color: 'primary',
						props: {
							color:
								watchValue === 0 ||
								watchValue === undefined ||
								formatDate(editData?.validFrom) <
									formatDate(`${todayDate.toISOString()}`)
									? 'inherit'
									: 'primary',
							type: 'submit',
							disabled:
								watchValue === 0 ||
								formatDate(editData?.validFrom) <
									formatDate(`${todayDate.toISOString()}`)
									? true
									: false,
						},
						onClick: () => {
							handleSubmit(data => !createUpdateCalled && onSubmit(data))();
						},
					},
				]}
			/>
			<ExitConfirmationDialog
				openExitDialog={openExitDialog}
				setOpenExitDialog={setOpenExitDialog}
				backPath={`/menu/outlet-app/outlet-setting/${outletID}/promo-code`}
			/>
			{/* <CommonDialog
				fullWidth={true}
				open={openExitDialog}
				onClose={() => {
					setOpenExitDialog(false);
				}}
				sections={{
					header: {
						dynamic: (
							<div>
								<div className="dialogo-dynamic-content">
									<span
										className="title c-orange flex-space"
										style={{
											fontSize: '13px',
											fontWeight: 'bold',
										}}
									>
										Exit Confirmation
									</span>
								</div>
							</div>
						),
					},
					body: () => (
						<CardContents>
							<span className="">
								Are you sure you want to exit? Your changes will not be saved.
							</span>
						</CardContents>
					),
					footer: {
						actions: [
							{
								displayText: 'Cancel',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
									},
									variant: 'contained',
									color: 'primary',
								},
							},
							{
								displayText: 'Confirm',
								props: {
									onClick: () => {
										setOpenExitDialog(false);
										history.push({
											pathname: `/menu/outlet-app/outlet-setting/${outletID}/promo-code`,
										});
									},
									variant: 'contained',
									color: 'primary',
								},
							},
						],
					},
				}}
			/> */}
			{/* )}
				</Formik> */}
		</>
	);
};
